import { makeStringRegexSafe } from "../make-string-regex-safe/MakeStringRegexSafe.function";
import { ISearchField } from "./interfaces/ISearchField";

export function getBestTextMatches<T>(searchString: string, collection: ReadonlyArray<T>, searchFields: ReadonlyArray<ISearchField<T>>): ReadonlyArray<T> {
    const toMatchLower = searchString.toLowerCase();
    const findMatchRegex = new RegExp(`(^|[-"'\\s])${makeStringRegexSafe(toMatchLower)}([-"'\\s]|$)`);

    const matchingItems = [];
    const scores = new Map();

    for (const item of collection) {
        let score = 0;
        for (const searchField of searchFields) {
            const propertyValue = searchField.getProperty(item);
            if (typeof propertyValue === "string") {
                score = score + scoreString(propertyValue, toMatchLower, findMatchRegex) * searchField.weight;
                continue;
            }
            for (const arrayItem of propertyValue) {
                score = score + scoreString(arrayItem, toMatchLower, findMatchRegex) * searchField.weight;
            }
        }

        if (score > 0) {
            scores.set(item, score);
            matchingItems.push(item);
        }
    }

    return matchingItems.sort((a, b) => scores.get(b) - scores.get(a));
}

function scoreString(content: string, toMatchLower: string, findMatchRegex: RegExp): number {
    const toCompare = content.toLowerCase();
    if (!toCompare.includes(toMatchLower)) {
        return 0;
    }

    const exactMatchIndex = toCompare.search(findMatchRegex);
    const startsWithSearchText = toCompare.startsWith(toMatchLower);

    let exactMatchWeight = 0;
    if (exactMatchIndex !== -1) {
        exactMatchWeight = exactMatchIndex + 1 <= toMatchLower.length ? 1 : toMatchLower.length / exactMatchIndex;
    }

    const normalWeight = toMatchLower.length / toCompare.length;
    const initialMatchWeight = startsWithSearchText ? normalWeight : 0;
    return normalWeight + exactMatchWeight + initialMatchWeight;
}
