import { angularAMD } from "@pebblepad/amd";
import "../controllers/spaActionMenuCtrl";
import "../../../utilities/baseUrlsFactory";
import "../services/spaActionMenuService";
import "./spaActionItem";
import "../../../utilities/iconHelper.service";
import "../../../userAgent/userAgent.service";
import "../../../utilities/urlService";
import template from "../views/spa-action-menu.html";
import { tracker } from "@pjs/analytics";

angularAMD.directive("spaActionMenu", [
    "$sce",
    "$window",
    "$rootScope",
    "$document",
    "baseUrlsFactory",
    "$route",
    "spaActionMenuService",
    "$timeout",
    "IconHelper",
    "UserAgentService",
    "urlService",
    function ($sce, $window, $rootScope, $document, baseUrlsFactory, $route, spaActionMenuService, $timeout, IconHelper, UserAgentService, urlService) {
        return {
            template: template,
            restrict: "E",
            scope: false,
            link: function (scope, element, attrs) {
                scope.tracker = tracker;
                scope.multiLanguageService = $rootScope.multiLanguageService;
                scope.termsAndConditionsURL = urlService.getForwardingUrl([
                    {
                        key: "location",
                        value: "PebblePadWebsite"
                    },
                    {
                        key: "action",
                        value: "Terms"
                    }
                ]);
                scope.gdprURL = urlService.getForwardingUrl([
                    {
                        key: "location",
                        value: "PebblePadWebsite"
                    },
                    {
                        key: "action",
                        value: "GDPR"
                    }
                ]);
                scope.iconFolderPath = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "spaMenu/styles/images/");
                var imgIcons = {
                    assetStore: scope.iconFolderPath + "assetStore.svg",
                    resourceStore: scope.iconFolderPath + "resourceStore.svg"
                };

                var specialIconTypes = {
                    "workbook-reversed": "icon-workbook-reversed",
                    "form-reversed": "icon-form-reversed"
                };
                if (UserAgentService.touch) {
                    // require(['bouncefix'], function(bouncefix) { //FIXME:
                    //     bouncefix.add('main-menu');
                    // });
                }

                /**
                 * Removes spaces and dots from string to construct a data hook friendly string for e2e tests.
                 * (This is because the burger menu is constructed from the backend) >:(
                 * @param item
                 * @returns {string}
                 */
                scope.getFriendlyDataHook = function (item) {
                    return item.replace(/\s+/g, "-").replace(/\./g, "").toLowerCase();
                };

                scope.onItemClick = function (item) {
                    if (item.Action.startsWith("http")) {
                        const params = new URL(item.Action).searchParams;
                        tracker.trackEvent("Burger Menu", `${params.get("mainType")} Button Clicked`);
                    } else {
                        tracker.trackEvent("Burger Menu", `${item.Action} Button Clicked`);
                    }

                    if (item.Target === "_blank") {
                        return;
                    }

                    scope.closePanels();
                    if (item.IsFunction) {
                        scope.openUrl(item.Action, item.Target, item.IsFunction, item.RelativePathForPlus);
                    }
                };

                scope.openUrl = function (action, target, isFunction, relativePath) {
                    // tslint:disable-next-line:triple-equals
                    if (scope.currentProduct === "plus" && relativePath != null) {
                        scope.usePath(isFunction, relativePath, target);
                    } else {
                        scope.usePath(isFunction, action, target);
                    }
                };

                scope.usePath = function (isFunction, path, target) {
                    if (isFunction) {
                        scope.$emit(path, true);
                    } else {
                        // timeout to fix spa menu overlay when link in new window is opened
                        $timeout(function () {
                            $window.open(path, target);
                        }, 250);
                    }
                };

                scope.processMenuData = function (menuData) {
                    var menuDataItem = null;
                    var dataHook = null;
                    for (var i = 0; i < menuData.length; i++) {
                        menuDataItem = menuData[i];
                        dataHook = scope.getFriendlyDataHook(menuDataItem.DisplayText);
                        menuDataItem.buttonDataHook = dataHook + "-menu-button";
                        menuDataItem.panelDataHook = dataHook + "-menu-panel";

                        scope.mapMenuItemGraphics(menuDataItem);
                        scope.processMenuData(menuDataItem.Children);
                    }
                };

                scope.mapMenuItemGraphics = function (menuItem) {
                    if (imgIcons[menuItem.Icon]) {
                        menuItem.Image = imgIcons[menuItem.Icon];
                        menuItem.Icon = "";
                        return;
                    }

                    menuItem.Icon = specialIconTypes[menuItem.Icon] || IconHelper.getIconFromType(menuItem.Icon);
                };

                var deregisterSpaGetData = $rootScope.$on("spaGetData", function (event, data) {
                    if (scope.menuData === undefined) {
                        spaActionMenuService.getMenu().then(function (response) {
                            scope.processMenuData(response.data);
                            scope.menuData = response.data;
                            scope.action_menu_data_ready = true;

                            $timeout(function () {
                                scope.termsBtn = angular.element(document.getElementsByClassName("terms-btn")[0]);
                                scope.burgerBtn = angular.element(document.getElementsByClassName("btn-spa-burger")[0]);
                            });
                        });
                    }
                });
                scope.$on("$destroy", function () {
                    deregisterSpaGetData();
                });
            }
        };
    }
]);
