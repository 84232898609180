import { ISelectionMarkerRange } from "../pp-marker-range/interfaces/ISelectionMarkerRange";

export function insertMarkerAroundRange(marker: ISelectionMarkerRange, range: Range): void {
    range.insertNode(marker.start);
    range.setStartAfter(marker.start);

    if (!range.collapsed && marker.end !== null) {
        range.collapse(false);
        range.insertNode(marker.end);
        range.setEndBefore(marker.end);
    }
}
