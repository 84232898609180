import { AsyncResolver } from "../../interfaces/AsyncResolver";
import { INamespaceAsyncResource } from "./interfaces/INamespaceAsyncResource";

export class ResourceLoader<T> {
    private readonly _resources: Map<string, Array<INamespaceAsyncResource<T>>> = new Map();
    private readonly _resourceResolvedCallback: (languageCode: string, namespace: string, resourceData: T) => void;

    constructor(resourceResolved: (languageCode: string, namespace: string, resourceData: T) => void) {
        this._resourceResolvedCallback = resourceResolved;
    }

    public add(namespace: string, languageCode: string, resolver: AsyncResolver<T>): void {
        const resourceLoaderItem = this._getResourceLoaderItem(languageCode);
        resourceLoaderItem.push({
            namespace: namespace,
            resolver: resolver
        });
    }

    public load(languageCode: string): Promise<void> {
        const resourceItem = this._resources.get(languageCode);
        if (resourceItem === undefined) {
            return Promise.resolve();
        }

        return this._loadAsyncResources(languageCode, resourceItem);
    }

    private _getResourceLoaderItem(languageCode: string): Array<INamespaceAsyncResource<T>> {
        let resourceItem = this._resources.get(languageCode);

        if (resourceItem === undefined) {
            resourceItem = [];
            this._resources.set(languageCode, resourceItem);
        }

        return resourceItem;
    }

    private async _loadAsyncResources(languageCode: string, resourceItems: Array<INamespaceAsyncResource<T>>): Promise<void> {
        const promises = resourceItems.map((resource) => {
            return resource
                .resolver()
                .then((resourceData) => this._resourceResolvedCallback(languageCode, resource.namespace, resourceData))
                .catch((error) => {
                    resourceItems.push(resource);
                    throw error;
                });
        });

        resourceItems.length = 0;
        await Promise.all(promises);
    }
}
