import { DecoupledEditor, ClipboardInputTransformationData, ClipboardPipeline } from "@pebblepad/ckeditor";
import { IHtmlStringParser } from "@pjs/security";
import { noop } from "@pjs/utilities";
import { concatMap, filter, from, map, Observable, pairwise, share, startWith, Subject, Subscription, takeUntil } from "@pjs/observables";
import { RefObject } from "react";
import { IEditorConfig } from "../editor-factories/interfaces/IEditorConfig";
import { CKEditorAdapter } from "../editor-adapter/CKEditorAdapter";
import { IEditorViewState } from "../editor-adapter/interfaces/IEditorViewState";
import { convertPlainTextToSemanticHtml } from "../../utils/convert-plain-text-to-html/ConvertPlainTextToSemanticHtml.function";
import { IEditor } from "./interfaces/IEditor";
import { EditorEnabledState } from "./types/EditorEnabledState";
import { CKEditorPlaceholder } from "./CKEditorPlaceholder";
import { DefaultEditorPlaceholder } from "./DefaultEditorPlaceholder";
import { IEditorPlaceholder } from "./interfaces/IEditorPlaceholder";
import { ToolbarDisplay } from "./ToolbarDisplay";
import { IToolbarDisplay } from "./interfaces/IToolbarDisplay";
import { IEditorDataHandler } from "./interfaces/IEditorDataHandler";
import { ElementDataHandler } from "./ElementDataHandler";
import { CKEditorDataHandler } from "./CKEditorDataHandler";
import { ToolbarDisplayState } from "./enums/ToolbarDisplayState";

export class Editor implements IEditor {
    public readonly sourceElement: HTMLElement;
    public readonly enabled: Observable<EditorEnabledState>;
    public readonly destruction: Observable<void>;
    public readonly parser: IHtmlStringParser;
    public toolbar: IToolbarDisplay | null = null;

    private readonly _editorPlaceholderContainer: HTMLElement;
    private readonly _enabledStateSubject: Subject<boolean>;
    private readonly _destruction: Subject<void>;
    private readonly _onChange: (value: string) => void;
    private readonly _onToolbarDisplayChange: (toolbarState: ToolbarDisplayState, toolbarContainer: HTMLElement) => void;
    private readonly _editorConfig: IEditorConfig;
    private readonly _enabledSubscription: Subscription;
    private readonly _toolbarContainerRef: RefObject<HTMLElement>;
    private readonly _onEditorEnabledBound: (state: IEditorViewState | null) => void;
    private readonly _ckeAdapter: CKEditorAdapter;
    private readonly _placeholderText: string;
    private _placeholder: IEditorPlaceholder;
    private _ckeInstance: DecoupledEditor | null = null;
    private _dataHandler: IEditorDataHandler;

    constructor(
        config: IEditorConfig,
        editorContainer: RefObject<HTMLElement>,
        toolbarContainer: RefObject<HTMLElement>,
        editorPlaceholderContainer: RefObject<HTMLElement>,
        parser: IHtmlStringParser,
        onChange: (value: string) => void,
        onToolbarDisplayChange: (toolbarState: ToolbarDisplayState, toolbarContainer: HTMLElement) => void,
        placeholder: string
    ) {
        this.sourceElement = editorContainer.current as HTMLElement;
        this._toolbarContainerRef = toolbarContainer;
        this._editorPlaceholderContainer = editorPlaceholderContainer.current as HTMLElement;
        this.parser = parser;
        this._editorConfig = config;
        this._placeholderText = placeholder;

        this._enabledStateSubject = new Subject<boolean>();
        this._destruction = new Subject<void>();
        this.destruction = this._destruction.asObservable();
        this._ckeAdapter = new CKEditorAdapter(this.sourceElement);
        this._ckeAdapter.startEventTracking();

        this._onChange = onChange;
        this._onToolbarDisplayChange = onToolbarDisplayChange;
        this._onEditorEnabledBound = this._onEditorStateChange.bind(this);

        const enabled = this._getEnabledStateObservable();
        this.enabled = enabled.pipe(map(Editor._adapterStateToEnabledState));
        this._enabledSubscription = enabled.subscribe(this._onEditorEnabledBound);

        this._placeholder = new DefaultEditorPlaceholder(this.sourceElement, this._editorPlaceholderContainer, this._placeholderText);
        this._dataHandler = new ElementDataHandler("", this.sourceElement);

        this._setData(config.initialData);
    }

    private static _adapterStateToEnabledState(state: IEditorViewState | null): EditorEnabledState {
        return state === null ? { editor: null, isEnabled: false } : { editor: state.ckeInstance, isEnabled: true };
    }

    public enable(): void {
        this._enabledStateSubject.next(true);
    }

    public disable(): void {
        this._ckeAdapter.startEventTracking();
        this._enabledStateSubject.next(false);
    }

    public destroy(): void {
        if (this._ckeInstance !== null) {
            this._cleanupEditor(this._ckeInstance).catch(noop);
        }

        this._destruction.next();
        this._destruction.complete();
        this._enabledSubscription.unsubscribe();
        this._enabledStateSubject.complete();
        this._ckeAdapter.destroy();
    }

    public getInstance(): DecoupledEditor | null {
        return this._ckeInstance;
    }

    public getData(): string {
        return this._dataHandler.getData();
    }

    public setData(data: string): void {
        if (data !== this.getData()) {
            this._setData(data);
        }
    }

    private _setData(data: string): void {
        const parsedData = data === "" ? data : this.parser.parseToString(data);
        this._dataHandler.setData(parsedData);
        this._placeholder.update(parsedData);
    }

    private _onEditorStateChange(state: IEditorViewState | null): void {
        let currentData = this._dataHandler.getData();

        if (state === null) {
            this._ckeInstance = null;
            this._dataHandler = new ElementDataHandler(currentData, this.sourceElement);
            this._placeholder = new DefaultEditorPlaceholder(this.sourceElement, this._editorPlaceholderContainer, this._placeholderText);
            return;
        }

        this._ckeInstance = state.ckeInstance;
        if (state.hasUserUpdatedContent) {
            currentData = this._ckeInstance.getData({ trim: "none" });
            this._onChange(currentData);
        }

        this._placeholder = new CKEditorPlaceholder(this._editorPlaceholderContainer, this._placeholderText);
        this._dataHandler = new CKEditorDataHandler(currentData, this._ckeInstance, (newData) => {
            this._placeholder.update(newData);
            this._onChange(newData);
        });

        this._ckeInstance.plugins.get(ClipboardPipeline).on("inputTransformation", (_: unknown, clipboard: ClipboardInputTransformationData) => this._onPaste(clipboard));

        const toolbarElement = this._ckeInstance.ui.view.toolbar.element as HTMLElement;
        this.toolbar = new ToolbarDisplay(toolbarElement);
        const toolbarContainer = this._toolbarContainerRef.current as HTMLElement;
        this.toolbar.displayChange.subscribe((isDisplayed) => this._onToolbarDisplayChange(isDisplayed, toolbarContainer));
        (this._toolbarContainerRef.current as HTMLElement).appendChild(toolbarElement);
    }

    private _onPaste(clipboard: ClipboardInputTransformationData): void {
        const viewFragmentProcessor = (this._ckeInstance as DecoupledEditor).data.htmlProcessor;
        let htmlString = "";

        if (clipboard.dataTransfer.types.includes("text/html")) {
            htmlString = viewFragmentProcessor.toData(clipboard.content);
        }

        if (htmlString === "" && clipboard.dataTransfer.types.includes("text/plain")) {
            htmlString = convertPlainTextToSemanticHtml(clipboard.dataTransfer.getData("text/plain"));
        }

        if (htmlString !== "") {
            clipboard.content = viewFragmentProcessor.toView(this.parser.parseToString(htmlString));
        }
    }

    private _getEnabledStateObservable(): Observable<IEditorViewState | null> {
        return this._enabledStateSubject.pipe(
            startWith(false),
            pairwise(),
            filter(([previousValue, currentValue]) => previousValue !== currentValue),
            concatMap(([_, newEnabledState]: [boolean, boolean]) => this._toggleEditor(newEnabledState)),
            takeUntil(this._destruction),
            share()
        );
    }

    private _toggleEditor(shouldEnable: boolean): Observable<IEditorViewState | null> {
        if (shouldEnable) {
            this._onToolbarDisplayChange(ToolbarDisplayState.Pending, this._toolbarContainerRef.current as HTMLElement);

            return this._ckeAdapter.load(this._editorConfig, this._dataHandler);
        }

        return from(this._cleanupEditor(this._ckeInstance as DecoupledEditor).then(() => null));
    }

    private _cleanupEditor(editor: DecoupledEditor): Promise<unknown> {
        this._dataHandler.destroy();

        (editor.ui.view.toolbar.element as HTMLElement).remove();
        (this.toolbar as ToolbarDisplay).destroy();
        this.toolbar = null;
        this._ckeInstance = null;

        return editor.destroy();
    }
}
