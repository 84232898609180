import { angularAMD } from "@pebblepad/amd";
import "./eventHelper.service";

angularAMD.factory("TouchMoveHandler", TouchHandler);
TouchHandler.$inject = ["EventHelper"];

function TouchHandler(EventHelper) {
    /**
     * @param {Element} element - HTML element (not Angular.element).
     * @param {Number} threshold - Minimum amount of pixels to move to be valid
     * @param {Number} timespan - Max amount of time (ms) which the move must occur in
     * @constructor
     */
    function TouchMoveHandler(element, thresholdX, thresholdY, timespan) {
        this.element = element;
        this.thresholdX = thresholdX || 70;
        this.thresholdY = thresholdY || 70;
        this.timespan = timespan || 500;
        this.startTime = 0;
        this.isMoving = false;
        this.startVector = new Vector(0, 0);
        this.currentVector = new Vector(0, 0);
        this.events = {
            start: null,
            move: null,
            end: null
        };
    }

    /**
     * @param {Function} [onStart] - Params returned: event, startVector
     * @param {Function} [onMove] - Params returned: event, currentVector
     * @param {Function} [onEnd] - Params returned: event
     * @param {Boolean} [autoCleanup]
     * @param {Number} [throttleSpeed] -Throttle moveevent logic
     * @returns Object
     */
    TouchMoveHandler.prototype.registerEvents = function (onStart, onMove, onEnd, autoCleanup, throttleSpeed) {
        autoCleanup = autoCleanup === undefined ? true : autoCleanup;

        //Hold references to event functions to allow them to be unregistered.
        this.events.start = this.createEventResponse(this.onStart.bind(this), onStart);
        this.events.end = this.createEventResponse(this.onEnd.bind(this), onEnd);
        var moveHandler = this.createEventResponse(this.onMove.bind(this), onMove);
        if (throttleSpeed) {
            var throttler = new EventHelper.EventThrottler(moveHandler, throttleSpeed);
            this.events.move = throttler.handler().bind(throttler);
        } else {
            this.events.move = moveHandler;
        }

        //Bind events
        this.element.addEventListener("touchstart", this.events.start, null);
        this.element.addEventListener("touchmove", this.events.move, null);
        this.element.addEventListener("touchend", this.events.end, null);

        if (autoCleanup) {
            angular.element(this.element).on("destroy", this.deregisterEvents.bind(this));
        }

        return this.events;
    };

    //Deregister grammatically correct! :O
    TouchMoveHandler.prototype.deregisterEvents = function () {
        this.element.removeEventListener("touchstart", this.events.start, null);
        this.element.removeEventListener("touchmove", this.events.move, null);
        this.element.removeEventListener("touchend", this.events.end, null);

        //Clear references
        this.events.start = this.events.move = this.events.end = null;

        return this;
    };

    TouchMoveHandler.prototype.createEventResponse = function (responder, callback) {
        return function (e) {
            var value = responder(e);
            if (callback) {
                callback(e, value);
            }
        };
    };

    TouchMoveHandler.prototype.onStart = function (e) {
        //For now we only care about single touch. Could extend this later on.
        var touch = e.touches[0];
        this.startTime = Date.now();
        return this.startVector.replace(touch.clientX, touch.clientY);
    };

    TouchMoveHandler.prototype.onMove = function (e) {
        var touch = e.touches[0];
        this.isMoving = true;
        return this.currentVector.replace(touch.clientX, touch.clientY);
    };

    TouchMoveHandler.prototype.onEnd = function (e) {
        var min = new Vector(this.startVector.x - this.thresholdX, this.startVector.y - this.thresholdX),
            max = new Vector(this.startVector.x + this.thresholdY, this.startVector.y + this.thresholdY),
            result = {
                validX: false,
                validY: false,
                xAxis: "",
                yAxis: "",
                diff: this.startVector.copy().subtract(this.currentVector),
                start: this.startVector.copy(),
                end: this.currentVector.copy(),
                dragAngle: Math.atan2(this.currentVector.y - this.startVector.y, this.currentVector.x - this.startVector.x)
            };

        if (this.isMoving && Date.now() - this.startTime <= this.timespan) {
            result.validX = this.testAxis(this.currentVector.x, min.x, max.x);
            result.validY = this.testAxis(this.currentVector.y, min.y, max.y);

            if (result.validX) {
                result.xAxis = result.diff.x > 0 ? "right" : "left";
            }

            if (result.validY) {
                result.yAxis = result.diff.y > 0 ? "down" : "up";
            }
        }

        this.reset();
        return result;
    };

    TouchMoveHandler.prototype.testAxis = function (current, min, max) {
        return current <= min || current >= max;
    };

    TouchMoveHandler.prototype.reset = function () {
        this.startTime = 0;
        this.isMoving = false;
        this.startVector.replace(0, 0);
        this.currentVector.replace(0, 0);

        return this;
    };

    return TouchMoveHandler;
}

/**
 * @param {Number} x
 * @param {Number} y
 * @constructor
 */
function Vector(x, y) {
    this.x = x;
    this.y = y;
}

Vector.prototype.replace = function (x, y) {
    this.x = x;
    this.y = y;

    return this;
};

Vector.prototype.subtract = function (vector) {
    this.x -= vector.x;
    this.y -= vector.y;

    return this;
};

Vector.prototype.copy = function () {
    return new Vector(this.x, this.y);
};
