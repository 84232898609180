import DOMPurify from "dompurify";
import { moveElementChildren } from "@pjs/utilities";
import { HtmlSanitiserEvent } from "../../enums/HtmlSanitiserEvent";
import { DomPurifyConfig } from "../../types/DomPurifyConfig";
import { SanitiserEventCallback } from "../../types/SanitiserEventCallback";
import { convertElementToFragment } from "../../utilities/ConvertElementToFragment.function";
import { IWhitelistHtmlSanitiserConfig } from "./types/IWhitelistHtmlSanitiserConfig";
import { IExtendedHtmlSanitiserConfig } from "./types/IExtendedHtmlSanitiserConfig";
import { IHtmlStringSanitiser } from "./interfaces/IHtmlStringSanitiser";

export class HtmlStringSanitiser implements IHtmlStringSanitiser {
    private readonly _config: DomPurifyConfig;
    private readonly _hooks: Array<{ callback: SanitiserEventCallback; name: DOMPurify.HookName }>;

    private constructor(config: DomPurifyConfig) {
        this._config = config;
        this._hooks = [];
    }

    public static createFromExtendedDefaults(config: IExtendedHtmlSanitiserConfig): HtmlStringSanitiser {
        return new HtmlStringSanitiser({
            /* eslint-disable @typescript-eslint/naming-convention */
            ADD_ATTR: (config.additionalAttributes as Array<string>) ?? [],
            FORBID_TAGS: (config.forbiddenTags as Array<string>) ?? [],
            RETURN_DOM: true
            /* eslint-enable @typescript-eslint/naming-convention */
        });
    }

    public static createFromWhitelist(config: IWhitelistHtmlSanitiserConfig): HtmlStringSanitiser {
        return new HtmlStringSanitiser({
            /* eslint-disable @typescript-eslint/naming-convention */
            ALLOWED_ATTR: (config.attributes as Array<string>) ?? [],
            ALLOWED_TAGS: (config.tags as Array<string>) ?? [],
            RETURN_DOM: true
            /* eslint-enable @typescript-eslint/naming-convention */
        });
    }

    public append(dirtyContent: string, parent: Element): void {
        moveElementChildren(this.convert(dirtyContent), parent);
    }

    public convert(dirtyContent: string): HTMLBodyElement {
        for (const hook of this._hooks) {
            DOMPurify.addHook(hook.name, hook.callback);
        }

        const sanitisedHtml = DOMPurify.sanitize(dirtyContent, this._config);
        DOMPurify.removeAllHooks();
        DOMPurify.removed.length = 0;

        return sanitisedHtml as HTMLBodyElement;
    }

    public convertToFragment(dirtyContent: string): DocumentFragment {
        const sanitisedHtml = this.convert(dirtyContent);
        return convertElementToFragment(sanitisedHtml);
    }

    public clean(dirtyContent: string): string {
        return this.convert(dirtyContent).innerHTML;
    }

    public on(eventName: HtmlSanitiserEvent, callback: SanitiserEventCallback): void {
        this._hooks.push({ callback: callback, name: eventName });
    }
}
