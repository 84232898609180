import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../services/shareService";
import "../../utilities/helpers";
import "../../datePicker/datePickerHelper";
import "../../externalFromWorkspace/externalFromWorkspaceShare";
import "../../clickAndDisable/directives/clickAndDisable.directive";
import template from "../templates/share-select-externals.html";
import { ButtonClass } from "@pjs/core-ui";

angularAMD.directive("shareSelectExternals", ShareSelectExternals);
ShareSelectExternals.$inject = [];

angularAMD.controller("ShareSelectExternalsController", ShareSelectExternalsController);
ShareSelectExternalsController.$inject = ["$scope", "$element", "$rootScope", "shareService", "helpers", "multiLanguageService", "datePickerHelper"];

//Injects
var $rootScope = null,
    ShareService = null,
    DatePickerHelper = null;

//Help Classes
function ExternalAssessorShareCreateRequest(submissionId, workspaceId, userIds, expiry, message) {
    this.SubmissionId = submissionId;
    this.WorkspaceId = workspaceId;
    this.UserIds = userIds;
    this.Expiry = expiry;
    this.Message = message;
}

//Directive
//------------------------------------------------------------------------------------------------------------------
function ShareSelectExternals() {
    return {
        restrict: "E",
        template: template,
        scope: {
            assetId: "@", //String
            submissionId: "@", //String
            workspaceId: "@", //String
            onSubmit: "&", //Function - Expects PromisedPost to be returned
            onComplete: "&", //Function
            allowCustomExternals: "=", //Boolean
            expiryDate: "=?", //Date | null
            updatingExisting: "=?", //Boolean
            externals: "=?" //Array of External Users - Optional
        },
        bindToController: true,
        controller: "ShareSelectExternalsController",
        controllerAs: "vm"
    };
}

//Controller
//------------------------------------------------------------------------------------------------------------------
function ShareSelectExternalsController($scope, $element, _$rootScope_, _ShareService_, Helpers, multiLanguageService, _DatePickerHelper_) {
    $rootScope = _$rootScope_;
    ShareService = _ShareService_;
    DatePickerHelper = _DatePickerHelper_;

    this.shareMessage = "";
    this.originalExpiryDate = DatePickerHelper.setToStartOfDay(this.expiryDate);
    this.shareFailed = false;
    this.isValid = false;
    this.activeRequest = null;
    this.multiLanguageService = multiLanguageService;
    this.buttonClass = ButtonClass;

    if (isNaN(Date.parse(this.expiryDate))) {
        this.expiryDate = null;
    }

    if (!Helpers.isNotEmptyArray(this.externals)) {
        this.externals = [];
    }

    $scope.$watchCollection("vm.externals", this.onExternalListChange.bind(this));
    $scope.$watch("vm.expiryDate", this.validate.bind(this));

    this.handleSubmit = this._submit.bind(this);
    this.handleCancel = this._cancel.bind(this);
}

//Instance Methods
//--------------------------------------------------------------------------------------------------------------
ShareSelectExternalsController.prototype._submit = function () {
    var userIds = this.externals.map(function (external) {
        return external.UserId;
    });

    var externalAssessorShareDto = new ExternalAssessorShareCreateRequest(this.submissionId, this.workspaceId, userIds, this.expiryDate, this.shareMessage);
    this.activeRequest = this.onSubmit({ shareModel: externalAssessorShareDto });
    return this.activeRequest.then(this.onSubmitSuccess.bind(this), this.onSubmitFailure.bind(this));
};

ShareSelectExternalsController.prototype.onSubmitSuccess = function (response) {
    if (response.status !== 200) {
        this.onSubmitFailure(response);
        return;
    }

    this.activeRequest = null;
    this.shareFailed = false;
    $rootScope.$broadcast("shareSuccessful", [this.assetId]);
    return this.onComplete({ externals: this.externals, expiryDate: this.expiryDate, cancelled: false });
};

ShareSelectExternalsController.prototype.onSubmitFailure = function (rejectionResponse) {
    this.shareFailed = true;
};

ShareSelectExternalsController.prototype._cancel = function () {
    if (this.activeRequest !== null) {
        this.activeRequest._httpTimeout.resolve();
        this.activeRequest = null;
    }

    return this.onComplete({ externals: null, expiryDate: null, cancelled: true });
};

ShareSelectExternalsController.prototype.onExternalListChange = function (newValue, oldValue) {
    if (newValue && oldValue && newValue.length === oldValue.length) {
        return;
    }

    this.validate();
};

ShareSelectExternalsController.prototype.validate = function () {
    var isValid = true;
    if (this.externals.length === 0) {
        isValid = false;
    } else {
        var expiryDate = DatePickerHelper.setToStartOfDay(this.expiryDate);
        if (this.expiryDate === this.originalExpiryDate || DatePickerHelper.compareDates(expiryDate, this.originalExpiryDate)) {
            isValid = false;
        }
    }

    this.isValid = isValid;
};
