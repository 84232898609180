import { angularAMD } from "@pebblepad/amd";
import { tracker } from "@pjs/analytics";
import "../slidingBanner/slidingBanner";
import "../userAgent/userAgent.service";
import "../multiLanguageService/multiLanguageService";
import template from "./templates/help-banner-wrapper.html";

angularAMD.directive("helpBannerWrapper", [
    "$compile",
    "$rootScope",
    "$interval",
    "$timeout",
    "$window",
    "$sce",
    "$templateRequest",
    "baseUrlsFactory",
    "UserAgentService",
    "multiLanguageService",
    function ($compile, $rootScope, $interval, $timeout, $window, $sce, $templateRequest, baseUrlsFactory, UserAgentService, multiLanguageService) {
        return {
            restrict: "E",
            template: template,
            controller: [
                "$scope",
                "$element",
                function ($scope, $element) {
                    var populateTemplateHtmlStrings = function () {
                        if ($scope.helpData) {
                            angular.forEach($scope.helpData.content, function (item, $index) {
                                // possible for data to come through with or without the shared component url at the start, so check
                                if (item.Text && (item.Text.toLowerCase().indexOf("http:") > -1 || item.Text.toLowerCase().indexOf("https:") > -1)) {
                                    $scope.helpData.content[$index].Text = item.Text;
                                } else {
                                    $scope.helpData.content[$index].Text = baseUrlsFactory.shared_component_base_url + item.Text;
                                }
                            });
                            $scope.slides = $scope.helpData.content;
                        }
                    };

                    var processHelpData = function () {
                        if ($scope.helpData) {
                            // tslint:disable-next-line:triple-equals
                            if ($scope.helpData.content.length == 1) {
                                $scope.bannerStatic = true;
                            } else {
                                $scope.bannerStatic = false;
                            }
                            populateTemplateHtmlStrings();
                        }
                    };
                    // Make sure help data has time to be set before building the pages
                    $timeout(function () {
                        processHelpData();
                    }, 0);

                    // Cache elements need by banner
                    var help_banner_wrapper_el = angular.element($element[0].querySelector("#help-banner-wrapper"));
                    var help_banner_toggle_btn_el = $element[0].querySelector(".help-banner-toggle-btn");

                    $scope.helpMinimised = true;
                    if ($scope.helpMinimised) {
                        help_banner_wrapper_el.addClass("minimised");
                    }

                    //Make help banner stretch full width of page when toolbar is open
                    if (
                        $scope.$parent.title === multiLanguageService.getString("page_titles.workbook_builder") ||
                        $scope.$parent.title === multiLanguageService.getString("page_titles.template_builder")
                    ) {
                        help_banner_toggle_btn_el.classList.add("help-banner-toggle-btn-full-width");
                    }

                    $scope.cacheElems = function () {
                        $scope.prev_slide_btn_el = $element[0].getElementsByClassName("btn-prev")[0];
                        $scope.slides_elems = $element[0].getElementsByClassName("banner-slide");
                        $scope.first_slide_el = $scope.slides_elems[0];
                        $scope.banner_slides_wrapper_el = document.getElementsByClassName("banner-slides-wrapper")[0];
                        $scope.sliding_banner_holder_el = document.querySelector(".sliding-banner-holder");
                    };

                    $scope.lastOpened = Date.now();
                    $scope.showMeHelp = function () {
                        if (Date.now() - $scope.lastOpened < 550) {
                            return;
                        }

                        $scope.cacheElems();

                        if ($scope.helpMinimised === false) {
                            tracker.trackEvent("Builder", "Click", "Close Help banner");
                            // shrink
                            // get rid of the tab indexes

                            $scope.helpMinimised = true;
                            help_banner_wrapper_el.addClass("minimised");
                            help_banner_wrapper_el.removeClass("maximised");
                            $scope.hide_help_timeout = setTimeout(function () {
                                $scope.banner_slides_wrapper_el.style.display = "none";
                            }, 500);
                            $scope.$emit("helpBannerClosed", $scope.helpData);
                        } else {
                            tracker.trackEvent("Builder", "Click", "Open Help banner");
                            // grow
                            clearTimeout($scope.hide_help_timeout);
                            // add the tab indexes
                            $scope.banner_slides_wrapper_el.style.display = "block";

                            $scope.helpMinimised = false;
                            help_banner_wrapper_el.removeClass("minimised");
                            help_banner_wrapper_el.addClass("maximised");
                            if (!UserAgentService.touch) {
                                setTimeout(function () {
                                    $scope.sliding_banner_holder_el.querySelector(".current").focus();
                                }, 500);
                            }
                        }

                        $scope.lastOpened = Date.now();
                    };

                    $scope.setTabIndexes = function (tabIndexString) {
                        // dirty but angular method wouldn't work for some reason
                        document.getElementById("slidingBannerPreviousBtn").tabIndex = tabIndexString;
                        document.getElementById("slidingBannerNextBtn").tabIndex = tabIndexString;
                    };

                    $scope.tabToNextButton = function (e) {
                        // tslint:disable-next-line:triple-equals
                        if (e.keyCode === 9 && e.shiftKey === true && $scope.helpMinimised == false) {
                            e.preventDefault();
                            document.getElementById("slidingBannerNextBtn").focus();
                        }
                    };

                    $scope.$on("openHelpBanner", function (event) {
                        $scope.showMeHelp();
                    });

                    $scope.openHelp = function () {
                        help_banner_wrapper_el.removeClass("collapsed");
                        help_banner_wrapper_el.removeClass("minimised");
                        help_banner_wrapper_el.addClass("maximised");
                        $scope.helpMinimised = false;
                    };

                    $scope.closeHelpBtn = function ($event) {
                        $event.stopPropagation(); //added to prevent it reopening on the banner click event under the close button.
                        $scope.closeHelp();
                    };

                    $scope.closeHelp = function () {
                        help_banner_wrapper_el.removeClass("maximised");
                        help_banner_wrapper_el.removeClass("minimised");
                        help_banner_wrapper_el.addClass("collapsed");
                    };

                    // Hide automatically after X seconds
                    $scope.autoHide = function (seconds) {
                        $timeout(function () {
                            if ($scope.helpMinimised) {
                                $scope.closeHelp();
                            }
                        }, seconds * 1000);
                    };

                    $scope.$on("closeHelpBanner", function () {
                        $scope.closeHelp();
                    });
                }
            ],

            link: function (scope, element, attrs) {
                if (attrs["autoHideDelay"] !== undefined) {
                    scope.autoHide(attrs["autoHideDelay"]);
                }
            }
        };
    }
]);
