import { angularAMD } from "@pebblepad/amd";

ClickAndDisable.$inject = ["$compile"];
function ClickAndDisable() {
    return {
        restrict: "A",
        scope: {
            clickAndDisable: "&"
        },
        link: function (scope, element) {
            element.addClass("click-and-disable");
            element.on("click", function () {
                scope.$apply(function () {
                    var prm = scope.clickAndDisable();
                    if (prm) {
                        element.prop("disabled", true);
                        prm.then(function () {
                            _unblock(element);
                        }).catch(function () {
                            _unblock(element);
                        });
                    } else if (console && console.error) {
                        console.error("clickAndDisable directive expects callback to return a promise");
                    }
                });
            });
        }
    };
}

function _unblock(element) {
    element.prop("disabled", false);
}

angularAMD.directive("clickAndDisable", ClickAndDisable);
export { ClickAndDisable };
