import { angularAMD } from "@pebblepad/amd";
import { MigratorRunner } from "./migratorRunner";
import "../../assetEndpointService/assetEndpoint.service";

class MigratorRunnerProvider {
    constructor() {
        this._migrators = [];
        this._runner = new MigratorRunner();
        this.$get = (...migrators) => {
            migrators.forEach((migrator) => this._runner.registerMigrator(migrator));
            return this._runner;
        };
        this.$get.$inject = [];
    }

    /**
     * Sets the list of migrators to register with the runner.
     * @param {string} migratorToken - The AngularJS token used to inject the migrator service.
     * @returns {void}
     */
    registerMigrators(migratorToken) {
        this.$get.$inject.push(migratorToken);
    }
}

angularAMD.provider("migratorRunner", MigratorRunnerProvider);
