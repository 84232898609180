import { ApprovalStatus } from "@pjs/feedback";
import { angularAMD } from "@pebblepad/amd";
import "../assetEndpointService/assetEndpoint.service";

export class ApprovalOptionsService {
    constructor($q, assetEndpointService) {
        this._$q = $q;
        this._assetEndpointService = assetEndpointService;
        this._approvalOptions = {};
    }

    getApprovalOptions(level) {
        if (this._approvalOptions[level] !== undefined) {
            return this._$q.resolve(this._approvalOptions[level]);
        }

        return this._assetEndpointService
            .getApprovalOptions({
                level: level
            })
            .then((response) => {
                this._approvalOptions[level] = [ApprovalStatus.Positive, ApprovalStatus.Neutral, ApprovalStatus.Negative].map((currentApprovalStatus) => {
                    if (response.data[currentApprovalStatus] === undefined) {
                        throw new Error(`Approval Status mismatch. The response did not contain a key of: ${currentApprovalStatus}`);
                    }

                    return {
                        approvalStatus: currentApprovalStatus,
                        value: response.data[currentApprovalStatus]
                    };
                });
                return this._approvalOptions[level];
            });
    }

    getApprovalLevelSettings() {
        return this._assetEndpointService.getApprovalLevelSettings().then((response) => {
            return response.data;
        });
    }
}

ApprovalOptionsService.$inject = ["$q", "AssetEndpointService"];
angularAMD.service("approvalOptionsService", ApprovalOptionsService);
