import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";

angularAMD.factory("searchService", [
    "$http",
    "baseUrlsFactory",
    "$q",
    function ($http, baseUrlsFactory, $q) {
        function promisedGet(url, params) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.get(url, {
                timeout: httpTimeout.promise,
                params: params
            });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        function promisedPost(url, data) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.post(url, data, { timeout: httpTimeout.promise });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        return {
            getContacts: function () {
                return promisedGet(baseUrlsFactory.api_base_url + "FindUser/GetContactsForUser");
            },

            searchUsers: function (requestObj) {
                return promisedPost(baseUrlsFactory.api_base_url + "FindUser/ExtendSearch", requestObj);
            },

            createContactFromSearchResult: function (requestObj) {
                return promisedPost(baseUrlsFactory.api_base_url + "FindUser/AddNewContactByUsername", requestObj);
            },

            getExternalsFromATLAS: function (searchText, workspaceId) {
                return $http.post(baseUrlsFactory.api_base_url + "FindUser/GetExternalsFromATLAS", { Email: searchText, WorkspaceId: workspaceId }).then(function (response) {
                    if (response.data) {
                        return response.data.length ? response.data : $q.reject("noResults");
                    } else {
                        return $q.reject();
                    }
                });
            }
        };
    }
]);
