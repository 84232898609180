import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../assetViews/sidebar/submissionInfoView/submissionInfoView.directive";
import template from "../templates/share-select-submission.html";

angularAMD.directive("shareSelectSubmission", ShareSelectSubmission);
ShareSelectSubmission.$inject = [];

angularAMD.controller("ShareSelectSubmissionController", ShareSelectSubmissionController);
ShareSelectSubmissionController.$inject = ["$scope", "$element", "multiLanguageService"];

//Directive
//------------------------------------------------------------------------------------------------------------------
function ShareSelectSubmission() {
    return {
        restrict: "E",
        template: template,
        scope: {
            submissions: "=", //Object | null
            onSelect: "&"
        },
        bindToController: true,
        controller: "ShareSelectSubmissionController",
        controllerAs: "vm"
    };
}

//Controller
//------------------------------------------------------------------------------------------------------------------
function ShareSelectSubmissionController($scope, $element, multiLanguageService) {
    $element.on("$destroy", function () {
        $scope.$destroy();
    });

    this.multiLanguageService = multiLanguageService;
}

//Instance Methods
//--------------------------------------------------------------------------------------------------------------
ShareSelectSubmissionController.prototype.select = function (submission) {
    this.onSelect({ selected: submission });
};
