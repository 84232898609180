import { FocusEvent, KeyboardEvent, MouseEvent, RefObject } from "react";
import { Observable, Subject } from "@pjs/observables";
import { Immutable } from "@pjs/utilities";
import { IMenuModelAdapterConfig } from "./interfaces/IMenuModelAdapterConfig";
import { IMenuEventModelHandlers } from "./interfaces/IMenuEventModelHandlers";
import { MenuAriaModel } from "./types/MenuAriaModel";
import { IAriaEventAdapter } from "./interfaces/IAriaEventAdapter";
import { IMenuTriggerKeyEvents } from "./interfaces/IMenuTriggerKeyEvents";
import { IMenuTargetKeyEvents } from "./interfaces/IMenuTargetKeyEvents";

export class MenuEventAdapter<TData> implements IAriaEventAdapter<MenuAriaModel, IMenuEventModelHandlers<TData>> {
    public modelChanges: Observable<Immutable<MenuAriaModel>>;
    public events: IMenuEventModelHandlers<TData>;

    private readonly _config: IMenuModelAdapterConfig<TData>;
    private readonly _modelSubject: Subject<Immutable<MenuAriaModel>>;
    private readonly _targetRef: RefObject<HTMLElement>;
    private readonly _triggerRef: RefObject<HTMLElement>;

    // This resolves issues with Mac Safari not always correctly closing the dropdown when a blur event occurs, due to not correctly tracking the relatedTarget on blur events
    private _temporaryRelatedTarget: Element | null = null;
    private _currentModel: Immutable<MenuAriaModel>;

    constructor(config: IMenuModelAdapterConfig<TData>, triggerRef: RefObject<HTMLElement>, targetRef: RefObject<HTMLElement>, initialModel: MenuAriaModel) {
        this._targetRef = targetRef;
        this._triggerRef = triggerRef;
        this._config = config;
        this._currentModel = initialModel;
        this._modelSubject = new Subject<MenuAriaModel>();
        this.modelChanges = this._modelSubject.asObservable();

        this.events = {
            item: {
                onClick: (e: MouseEvent, itemIndex: number) => this._generateNewModel(this._config.item.onClick(e, itemIndex))
            },
            target: {
                onFocusLoss: this._handleTargetFocusLoss.bind(this),
                onKeyDown: this._handleTargetKeyDown.bind(this)
            },
            trigger: {
                onClick: this._handleTriggerClick.bind(this),
                onKeyDown: this._handleTriggerKeyDown.bind(this),
                onMouseDown: this._handleTriggerMouseDown.bind(this)
            }
        };
    }

    public updateModel(newModel: MenuAriaModel): void {
        this._currentModel = newModel;
    }

    private _handleTargetFocusLoss(e: FocusEvent): void {
        this._generateNewModel(this._config.target.onFocusLoss(e, this._triggerRef, this._targetRef, this._temporaryRelatedTarget));
    }

    private _handleTargetKeyDown(e: KeyboardEvent, items: Array<TData>): void {
        const onKey = this._config.target[e.key as keyof IMenuTargetKeyEvents<TData>] ?? this._config.target.default;

        if (onKey === undefined) {
            return;
        }

        e.stopPropagation();

        if (this._config.target[e.key as keyof IMenuTargetKeyEvents<TData>] !== undefined) {
            e.preventDefault();
        }

        this._generateNewModel(onKey(e, this._currentModel, items));
    }

    private _handleTriggerKeyDown(e: KeyboardEvent, items: Array<TData>): void {
        const onKey = this._config.trigger[e.key as keyof IMenuTriggerKeyEvents<TData>];

        if (onKey !== undefined) {
            e.preventDefault();
            e.stopPropagation();

            this._generateNewModel(onKey(e, this._currentModel, items));
        }
    }

    private _handleTriggerMouseDown(e: MouseEvent): void {
        this._temporaryRelatedTarget = e.target as Element;
    }

    private _handleTriggerClick(e: MouseEvent): void {
        e.stopPropagation();

        this._generateNewModel(this._config.trigger.onClick(e, this._currentModel));
    }

    private _generateNewModel(newBaseModel: Partial<Immutable<MenuAriaModel>> | null): void {
        this._temporaryRelatedTarget = null;

        if (newBaseModel === null) {
            return;
        }

        const newModel = { ...this._currentModel, ...newBaseModel } as unknown as Immutable<MenuAriaModel>;

        this._modelSubject.next(newModel);
    }
}
