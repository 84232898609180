export enum IndentBlockClass {
    Indent2 = "rt-i2",
    Indent4 = "rt-i4",
    Indent6 = "rt-i6",
    Indent8 = "rt-i8",
    Indent10 = "rt-i10",
    Indent12 = "rt-i12",
    Indent14 = "rt-i14",
    Indent16 = "rt-i16",
    Indent18 = "rt-i18",
    Indent20 = "rt-i20"
}
