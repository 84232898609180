import { generateCsrfToken } from "@pjs/security";
import { cookieStore, ICookieOptions, SameSite } from "@pjs/storage";
import { CookieSameSiteHelper } from "../cookie-same-site-helper/CookieSameSiteHelper";

export class CsrfCookie {
    public static readonly cookieName: string = "CSRF_Token";
    public static readonly cookieExpiryName: string = "CSRF_Token_Expiry";

    public static get(): string {
        const cookie = cookieStore.get(CsrfCookie.cookieName);
        const expiryCookie = cookieStore.get(CsrfCookie.cookieExpiryName);

        if (cookie === null) {
            return CsrfCookie._create();
        }

        if (expiryCookie === null || new Date(expiryCookie.value).getTime() - 600000 <= Date.now()) {
            return CsrfCookie._create();
        }

        return cookie.value;
    }

    private static _create(): string {
        const token = generateCsrfToken();
        CsrfCookie._set(token);
        return token;
    }

    private static _set(token: string): void {
        const hour = 60 * 60 * 1000;
        const expiry = new Date(Date.now() + hour);

        const cookieOptions: ICookieOptions = {
            expiry: expiry,
            path: "/",
            secure: true
        };

        if (CookieSameSiteHelper.shouldSendSameSiteNone(window.navigator.userAgent)) {
            cookieOptions.sameSite = SameSite.None;
        }

        const expiryToken = expiry.toISOString();
        cookieStore.set(CsrfCookie.cookieName, token, cookieOptions);
        cookieStore.set(CsrfCookie.cookieExpiryName, expiryToken, cookieOptions);
    }
}
