import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import "../../../multiLanguageService/multiLanguageService";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import template from "./grade.html";

class GradeComponent extends NgComponent {
    constructor(multiLanguageService, feedbackHelper) {
        super();

        this.multiLanguageService = multiLanguageService;
        this._feedbackHelper = feedbackHelper;
        this.ACTION_CONSTANTS = ACTION_CONSTANTS;

        this.gradeInput = "";
        this.isShortGrade = false;
    }

    $onChanges() {
        this._setIsShortGrade();
        this.gradeInput = this.grade;
    }

    editGrade() {
        const validation = this._feedbackHelper.getGradeValidation(this.gradeInput);

        this.onEdit({
            validation: validation,
            changes: { Grade: this.gradeInput }
        });
    }

    _setIsShortGrade() {
        this.isShortGrade = this.grade.length < 15;
    }
}

const gradeDefinition = {
    bindings: {
        grade: "<",
        mode: "<",
        onEdit: "&"
    },
    template: template,
    controller: GradeComponent
};

GradeComponent.$inject = ["multiLanguageService", "feedbackHelper"];
angularAMD.component("grade", gradeDefinition);
export { gradeDefinition as grade };
