import { ASSET_CONSTANTS } from "../constants/asset.constants";

/**
 * @param {object} dataManagerService
 * @param {object=} defaultAssetProperties
 * @returns {PromiseLike<{ assetId: string, manager: object }>}
 */

export function createCollection(dataManagerService, defaultAssetProperties = {}) {
    return dataManagerService
        .init({
            AssetId: null,
            Asset: {
                MainType: ASSET_CONSTANTS.TYPES.COLLECTION_WRAPPER,
                ...defaultAssetProperties
            }
        })
        .then(() => {
            const manager = dataManagerService.collectionWrapperManagerObject;
            return { assetId: manager.AssetId, manager: manager };
        });
}
