import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../multiLanguageService/multiLanguageService";
import "../dataManager/dataManagerService";
import "./services/pageReuseFactory.factory";
import "../../multiLanguageService/multiLanguageService";
import template from "./templates/page-reuse.html";

angularAMD.directive("pageReuse", PageReuse);
PageReuse.$inject = [];

angularAMD.controller("PageReuseController", PageReuseController);
PageReuseController.$inject = ["$scope", "$q", "PageReuseFactory", "multiLanguageService"];

function PageReuse() {
    return {
        template: template,
        restrict: "E",
        scope: {
            rootAssetId: "@",
            label: "@",
            page: "=",
            onUpdate: "&"
        },
        controller: PageReuseController,
        controllerAs: "vm",
        bindToController: true
    };
}

function PageReuseController($scope, $q, pageReuseFactory, multiLanguageService) {
    this.services = {
        $q: $q,
        pageReuseFactory: pageReuseFactory
    };

    this.pageReuseHandler = null;
    this.visibleInStore = false;
    this.updating = false;
    this.multiLanguageService = multiLanguageService;
    this.updatingMessage = this.multiLanguageService.getString("builder.toolbar.page_reuse.updating");
    this.error = "";

    $scope.$watch("vm.page", this.onPageUpdate.bind(this));
}

PageReuseController.prototype.onPageUpdate = function (newPage, oldPage) {
    if (newPage) {
        this.pageReuseHandler = this.services.pageReuseFactory.createHandler(this.rootAssetId, this.page);
        this.visibleInStore = this.pageReuseHandler.isPageVisible();
    }
};

PageReuseController.prototype.onChange = function () {
    this.error = "";
    this.updating = true;

    return this.updatePageVisibility(this.visibleInStore).finally(
        function () {
            this.updating = false;
        }.bind(this)
    );
};

PageReuseController.prototype.updatePageVisibility = function (visibleInStore) {
    var newFolder = this.pageReuseHandler.getFolderFromState(visibleInStore);
    var errorHandler = this.onUpdateError.bind(this, visibleInStore);
    return this.pageReuseHandler.checkIfMoveIsPossible(newFolder).then(
        function (valid) {
            if (valid) {
                var params = { assetId: this.pageReuseHandler.getAssetIdForPage(), folder: newFolder, rootAssetId: this.rootAssetId };
                this.pageReuseHandler.updateFolder(newFolder);
                return this.onUpdate(params).then(this.onUpdateSuccess.bind(this, visibleInStore), errorHandler);
            } else {
                return this.onUpdateError(visibleInStore, { deleted: true });
            }
        }.bind(this),
        errorHandler
    );
};

PageReuseController.prototype.onUpdateSuccess = function (visibleInStore) {
    this.visibleInStore = visibleInStore;
};

PageReuseController.prototype.onUpdateError = function (visibleInStore, rejection) {
    //Reset back to old folder!
    var visibilityState = !visibleInStore;
    this.pageReuseHandler.updateFolder(this.pageReuseHandler.getFolderFromState(visibilityState));
    this.visibleInStore = visibilityState;
    this.error =
        rejection && rejection.deleted ? this.multiLanguageService.getString("builder.toolbar.page_reuse.error_deleted") : this.multiLanguageService.getString("builder.toolbar.page_reuse.error");
};
