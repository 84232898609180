import { AbortablePromiseChain } from "./AbortablePromiseChain";
import { PromiseAbortController } from "./PromiseAbortController";

/**
 * @param {Promise<T>>} promise
 * @return {AbortablePromiseChain<T>}
 */
export function makeAbortable(promise) {
    return new AbortablePromiseChain(promise, new PromiseAbortController());
}
