import { SandboxedTagSwap } from "@pjs/security";
import { moveElementChildren } from "@pjs/utilities";

export class BasicTagSwap extends SandboxedTagSwap {
    protected _swap(sandboxedElement: Element, newElement: Element): void {
        const attributes = sandboxedElement.getAttributeNames();

        for (const attribute of attributes) {
            newElement.setAttribute(attribute, sandboxedElement.getAttribute(attribute) as string);
        }

        moveElementChildren(sandboxedElement, newElement);
    }
}
