import { Component, ReactElement } from "react";
import { OverflowMenu, IButtonMenuOption, editIcon } from "@pjs/core-ui";
import { RichTextView } from "@pjs/rich-text";
import { ParseHtml } from "@pjs/security";
import { formResponseElementI18n } from "../i18n/FormResponseElementI18n.const";
import { ICapabilityHistoryItemProps } from "./interfaces/ICapabilityHistoryItemProps";
import "./styles/capability-history.css";

export class CapabilityHistoryItem extends Component<ICapabilityHistoryItemProps> {
    private static readonly _editIconColour: string = "#0077BB";
    private readonly _optionsLabel: string = formResponseElementI18n.getString("capability_history.history_item.options");
    private readonly _menuOptions: Array<IButtonMenuOption>;

    constructor(props: ICapabilityHistoryItemProps) {
        super(props);

        this._menuOptions = [
            {
                icon: editIcon,
                iconColour: CapabilityHistoryItem._editIconColour,
                onSelect: () => this.props.onEditJustification(this.props.historyItem),
                text: formResponseElementI18n.getString("capability_history.history_item.edit_justification")
            }
        ];
    }

    public render(): ReactElement {
        const entry = this._getEntryText(this.props.historyItem.previousAnswer !== undefined);
        const timeStamp = this._getTimeStampText();

        return (
            <div className="fre-capability-history-item" data-hook="capability-history">
                <div className="fre-capability-history-item__data-wrapper">
                    <div className="fre-capability-history-item__data">
                        <ParseHtml className="fre-capability-history-item__data-entry" dataHook="capability-history-entry" htmlString={entry} />
                        <div className="fre-capability-history-item__data-timestamp" data-hook="capability-history-timestamp">
                            {timeStamp}
                        </div>
                    </div>

                    {this.props.isEditable && (
                        <div className="fre-capability-history-item__data-wrapper-controls">
                            <OverflowMenu ariaLabel={this._optionsLabel} data-hook="capability-history-overflow-menu" selectOptions={this._menuOptions} />
                        </div>
                    )}
                </div>
                {this.props.historyItem.justification !== null && (
                    <RichTextView className="fre-capability-history-item__justification" dataHook="capability-history-justification" content={this.props.historyItem.justification} />
                )}
            </div>
        );
    }

    private _getEntryText(isChangeItem: boolean): string {
        const values = {
            answer: this.props.historyItem.answer,
            name: this.props.historyItem.lastUpdatedByName,
            previousAnswer: this.props.historyItem.previousAnswer
        };

        if (isChangeItem) {
            return this.props.answeredByCurrentUser
                ? formResponseElementI18n.getString("capability_history.history_item.you_changed", values)
                : formResponseElementI18n.getString("capability_history.history_item.person_changed", values);
        }

        return this.props.answeredByCurrentUser
            ? formResponseElementI18n.getString("capability_history.history_item.you_chose", values)
            : formResponseElementI18n.getString("capability_history.history_item.person_chose", values);
    }

    private _getTimeStampText(): string {
        return formResponseElementI18n.getString("capability_history.history_item.answer_timestamp", {
            date: this.props.historyItem.lastUpdatedOnDate,
            time: this.props.historyItem.lastUpdatedOnTime
        });
    }
}
