import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../../constants/asset.constants";
import "../../dataManager/dataManagerService";
import "../../../assetStore/services/assetStoreService";

angularAMD.service("PageReuseFactory", PageReuseFactory);
PageReuseFactory.$inject = ["dataManagerService", "assetStoreService"];

function PageReuseFactory(dataManagerService, assetStoreService) {
    this.isSupportedFolder = function (pageFolder) {
        return PageReuseHandler.supportedFolders.contains(pageFolder);
    };

    this.createHandler = function (rootAssetId, page) {
        return new PageReuseHandler(rootAssetId, page, dataManagerService, assetStoreService.getFolderLocationForAsset);
    };
}

/**
 * @param {String} rootAssetId
 * @param {Object} page
 * @param {Object} dataManager
 * @param {Function} getFolderLocationForAsset
 * @constructor
 */
function PageReuseHandler(rootAssetId, page, dataManager, getFolderLocationForAsset) {
    this.rootAssetId = rootAssetId;
    this.page = page;
    this.dataManager = dataManager;
    this.getFolderLocationForAsset = getFolderLocationForAsset;
    this.manager = this.dataManager.getDto(rootAssetId);

    var pageKeys = ASSET_CONSTANTS.PAGE_KEYS;
    this.pageAssetIdKeys = [pageKeys.USER_RESPONSE_ID, pageKeys.CONTENT_ID, pageKeys.PAGE_ASSET_ID]; //Order is important!
}

PageReuseHandler.supportedFolders = [ASSET_CONSTANTS.FOLDERS.MAIN, ASSET_CONSTANTS.FOLDERS.INVISIBLE];

/**
 * @param {Boolean} visible
 * @returns {String}
 */
PageReuseHandler.prototype.getFolderFromState = function (visible) {
    return visible ? ASSET_CONSTANTS.FOLDERS.MAIN : ASSET_CONSTANTS.FOLDERS.INVISIBLE;
};

/**
 * @returns {Object|null}
 */
PageReuseHandler.prototype.getManagerForPage = function () {
    var pageAssetId = this.getAssetIdForPage();

    var manager = this.dataManager.getDto(pageAssetId);
    return manager || null;
};

PageReuseHandler.prototype.getAssetIdForPage = function () {
    var page = this.page;
    var assetIdKey = this.pageAssetIdKeys.find(function (key) {
        return !!page[key];
    });

    return page[assetIdKey];
};

/**
 * @param {String} newFolder
 */
PageReuseHandler.prototype.updateFolder = function (newFolder) {
    this.updateFolderForPageDto(newFolder);
    this.updateFolderForPageAsset(newFolder);
};

/**
 * @param {String} newFolder
 */
PageReuseHandler.prototype.updateFolderForPageDto = function (newFolder) {
    this.page.PageFolder = newFolder;

    //If the manager for the root parent exists has unsaved changes, we need commit the PageDto Folder change into localstorage!
    if (!this.dataManager.checkIfSaved(this.manager.AssetId)) {
        this.manager.reviseData();
        this.manager.save();
    }
};

/**
 * @param {String} newFolder
 */
PageReuseHandler.prototype.updateFolderForPageAsset = function (newFolder) {
    //If assetDto exists in memory for page, update it to keep it in sync.
    var managerForPage = this.getManagerForPage();
    //If the manager for the page exists in active Memory, we need commit the PageDto Folder change into localstorage!
    if (managerForPage) {
        managerForPage.data.Title = this.page.PageTitle;
        managerForPage.data.AssetFolder = newFolder;

        managerForPage.reviseData();
        managerForPage.save();
    }
};

/**
 * @param {Boolean} visibleInStore
 * @returns {Boolean}
 */
PageReuseHandler.prototype.inFolderForVisibility = function (visibleInStore) {
    return this.page.PageFolder === this.getFolderFromState(visibleInStore);
};

/**
 * @param {String} newFolder
 * @returns {Promise} - Resolves with Boolean
 */
PageReuseHandler.prototype.checkIfMoveIsPossible = function (newFolder) {
    return this.getFolderLocationForAsset(this.getAssetIdForPage()).then(
        function (currentPageFolder) {
            return currentPageFolder !== ASSET_CONSTANTS.FOLDERS.DELETED && PageReuseHandler.supportedFolders.contains(newFolder);
        }.bind(this)
    );
};

/**
 * @returns {Boolean}
 */
PageReuseHandler.prototype.isPageVisible = function () {
    return this.page.PageFolder === ASSET_CONSTANTS.FOLDERS.MAIN;
};
