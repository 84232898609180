import { angularAMD } from "@pebblepad/amd";
import "../userAgent/userAgent.service";

angularAMD.service("shameOnBrowser", shameOnBrowser);

shameOnBrowser.$inject = ["$timeout", "UserAgentService"];

function shameOnBrowser($timeout, UserAgentService) {
    // Public API
    this.forceElementRepaint = forceElementRepaint;
    this.forceWidthRepaint = forceWidthRepaint;
    this.forceElementRepaintLight = forceElementRepaintLight;

    // Local methods
    function forceElementRepaint(element, callback, timeout) {
        if (!element) {
            return;
        }

        // apply this to trigger browser repaint (http://csstriggers.com/)
        // this needed to make browsers recalculate width on max-width element
        // this is due to buggy webkit renderer
        element.style.boxShadow = "0 0 0 0 transparent";

        if (timeout === void 0) {
            if (UserAgentService.safari) {
                timeout = 350;
            } else if (UserAgentService.ios) {
                timeout = 1000;
            } else {
                timeout = 250;
            }
        }

        return $timeout(function () {
            // set back to default to trigger browser repaint
            element.style.boxShadow = "";
            if (callback) {
                callback();
            }
        }, timeout);
    }

    function forceWidthRepaint(element) {
        element.style.width = "auto";
        return $timeout(function () {
            requestAnimationFrame(function () {
                element.style.width = "";
            });
        }, 50);
    }

    function forceElementRepaintLight(element) {
        element.style.bottom = "auto";
        requestAnimationFrame(function () {
            element.style.bottom = "";
        });
    }
}
