import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/accessibilityKeyEventHelper.service";
import template from "./templates/status-toolbar.html";

angularAMD.directive("statusToolbar", StatusToolbar);
StatusToolbar.$inject = [];

angularAMD.controller("StatusToolbarController", StatusToolbarController);
StatusToolbarController.$inject = ["multiLanguageService", "accessibilityKeyEventHelper"];

function StatusToolbar() {
    return {
        template: template,
        restrict: "E",
        scope: {
            handler: "="
        },
        controller: "StatusToolbarController",
        controllerAs: "vm",
        bindToController: true
    };
}

function StatusToolbarController(multiLanguageService, accessibilityKeyEventHelper) {
    this.multiLanguageService = multiLanguageService;
    this.accessibilityKeyEventHelper = accessibilityKeyEventHelper;
    this.statusToolbarAriaLabel = this.setAriaLabel(this.handler.statusCollection.activeList.length);

    this.handler.events.on(
        "statusListChange",
        function () {
            this.statusToolbarAriaLabel = this.setAriaLabel(this.handler.statusCollection.activeList.length);
        }.bind(this)
    );
}

StatusToolbarController.prototype.setAriaLabel = function (count) {
    if (count > 0) {
        return this.multiLanguageService.getString("status_toolbar.toolbar_aria_label", {
            count: count
        });
    }

    return this.multiLanguageService.getString("status_toolbar.toolbar_aria_label_none", {
        count: count
    });
};

StatusToolbarController.prototype.onEscape = function (event, status) {
    if (event.keyCode === this.accessibilityKeyEventHelper.keyCodeMap.escape) {
        this.handler.onComplete(status);
    }
};
