import { noop } from "@pjs/utilities";
import { tracker } from "@pjs/analytics";
import { ButtonView, DropdownView, SplitButtonView } from "@pebblepad/ckeditor";
import { IEditor } from "../../editor/interfaces/IEditor";
import { IEditorFactoryConfig } from "../../editor-factories/interfaces/IEditorFactoryConfig";
import { IEditorConfig } from "../../editor-factories/interfaces/IEditorConfig";

const trackButton = (itemName: string, itemView: ButtonView | DropdownView): void => {
    const triggerElement = itemView.element as HTMLElement;
    triggerElement.addEventListener("click", () => tracker.trackEvent("Ckeditor 5 toolbar", "click", itemName));
};

const trackDropdown = (itemName: string, itemView: ButtonView | DropdownView): void => {
    if (!(itemView instanceof DropdownView)) {
        return;
    }

    const panelElement = itemView.panelView.element as HTMLElement;
    panelElement.addEventListener("click", (e) => {
        if (e.target !== null && (e.target as Element).closest("button") !== null) {
            tracker.trackEvent("Ckeditor 5 toolbar", "click", itemName);
        }
    });
};

const trackSplitDropdown = (itemName: string, itemView: ButtonView | DropdownView): void => {
    trackDropdown(itemName, itemView);

    if (itemView instanceof DropdownView && itemView.buttonView instanceof SplitButtonView) {
        (itemView.buttonView.actionView.element as HTMLElement).addEventListener("click", () => tracker.trackEvent("Ckeditor 5 toolbar", "click", itemName));
    }
};

const toolbarItemTrackers: Readonly<Record<string, (itemName: string, itemView: ButtonView | DropdownView) => void>> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ParagraphIndent: trackDropdown,
    alignment: trackSplitDropdown,
    bold: trackButton,
    bulletedList: trackSplitDropdown,
    fontBackgroundColor: trackDropdown,
    fontColor: trackDropdown,
    fontFamily: trackDropdown,
    fontSize: trackDropdown,
    heading: trackDropdown,
    indent: trackButton,
    italic: trackButton,
    linker: trackButton,
    numberedList: trackSplitDropdown,
    outdent: trackButton,
    redo: trackButton,
    removeFormat: trackButton,
    specialCharacters: (itemName, itemView) => {
        if (!(itemView instanceof DropdownView)) {
            return;
        }

        (itemView.panelView.element as HTMLElement).addEventListener("click", (e) => {
            const element = e.target as Element | null;
            if (element !== null && element.closest("button") !== null && element.closest(".ck-special-characters-navigation") === null) {
                tracker.trackEvent("Ckeditor 5 toolbar", "click", itemName);
            }
        });
    },
    strikethrough: trackButton,
    subscript: trackButton,
    superscript: trackButton,
    underline: trackButton,
    undo: trackButton
};

export function trackToolbarItems(editor: IEditor, _: IEditorFactoryConfig): () => void {
    editor.enabled.subscribe((state) => {
        if (!state.isEnabled) {
            return;
        }

        const toolbarItemNames = (state.editor.config.get("toolbar") as IEditorConfig["toolbar"]).items;
        const toolbarButtonViews = state.editor.ui.view.toolbar.items;

        for (let i = 0; i < toolbarItemNames.length; i++) {
            const toolbarItem = toolbarButtonViews.get(i);

            if (toolbarItem instanceof DropdownView || toolbarItem instanceof ButtonView) {
                const toolbarItemName = toolbarItemNames[i];

                const toolbarItemTracker = toolbarItemTrackers[toolbarItemName];
                if (toolbarItemTracker !== undefined) {
                    toolbarItemTracker(toolbarItemName, toolbarItem);
                }
            }
        }
    });

    return noop;
}
