import { ICookieOptions } from "../interfaces/ICookieOptions";

export function cookieOptionsToString(options: ICookieOptions): string {
    const cookieOptions: Array<string> = [];

    if (options.expiry !== undefined) {
        cookieOptions.push(`Expires=${options.expiry.toUTCString()};`);
    }

    if (options.path !== undefined) {
        cookieOptions.push(`Path=${options.path};`);
    }

    if (options.sameSite !== undefined) {
        cookieOptions.push(`SameSite=${options.sameSite};`);
    }

    if (options.secure !== undefined) {
        cookieOptions.push(`Secure;`);
    }

    return cookieOptions.join(" ");
}
