const _moduleExport = {
    NotLoggedIn: "Not logged in",
    NotPublic: "Asset is not publicly accessible",
    NotEnabledSystem: "System not enabled",
    ExternalUser: "External User",
    MarkedForDeletion: "Account marked for deletion",
    NotApplicantOrReviewer: "Not an applicant or reviewer",
    NotApplicant: "Not an applicant",
    NotReviewer: "Not a reviewer"
};
export { _moduleExport as authenticated401Reasons };
