import { DecoupledEditor, LinkCommand, UnlinkCommand } from "@pebblepad/ckeditor";
import { LinkerSelection } from "./LinkerSelection";
import { FocusTrackerProxy } from "./FocusTrackerProxy";
import { ILinkSelectionContent } from "./interfaces/ILinkSelectionContent";
import { linkModelAttributes } from "./constants/LinkModelAttributes.const";

export class LinkerProcessor {
    public readonly focusTrackerProxy: FocusTrackerProxy;

    private readonly _editor: DecoupledEditor;
    private readonly _linkerSelection: LinkerSelection;
    private readonly _linkCommand: LinkCommand;
    private readonly _unlinkCommand: UnlinkCommand;

    constructor(editor: DecoupledEditor, linkCommand: LinkCommand, unlinkCommand: UnlinkCommand) {
        this._editor = editor;
        this._linkCommand = linkCommand;
        this._unlinkCommand = unlinkCommand;

        this._linkerSelection = new LinkerSelection(editor);
        this.focusTrackerProxy = new FocusTrackerProxy(editor);
    }

    public getSelectedContent(): ILinkSelectionContent {
        return this._linkerSelection.getContent();
    }

    public addLink(link: string, isExternal: boolean): void {
        this._linkCommand.execute(link, { [linkModelAttributes.isExternal]: isExternal });
        this._linkerSelection.moveCaretToEnd();
    }

    public removeLink(): void {
        this._unlinkCommand.execute();
        this._editor.editing.view.focus();
    }

    public insertText(text: string): void {
        this._editor.model.change((writer) => {
            const node = writer.createText(text);
            const range = this._editor.model.insertContent(node);
            writer.setSelection(range);
        });
    }
}
