import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../assetStore/directives/assetStore";
import template from "../views/spa-asset-store.html";

angularAMD.directive("spaAssetStore", function () {
    return {
        restrict: "E",
        scope: false,
        template: template
    };
});
