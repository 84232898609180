import { HtmlSanitiserEvent, globalAnchorTransformConfig, globalAnchorSanitiseEventHandler } from "@pjs/security";
import { ngHtmlStringSanitiser } from "../security/NgHtmlStringSanitiser.const";

var pebbleLinksModule = angular.module("pebbleLinks", ["ng"]);
pebbleLinksModule.run([
    "assetLinkService",
    "$routeParams",
    function (assetLinkService, $routeParams) {
        const convertAssetLink = (href, element) => {
            if (element.dataset.route === undefined) {
                return href;
            }

            const linkData = {
                route: element.dataset.route,
                assetId: element.dataset.assetId,
                timeslice: element.dataset.timeslice,
                submissionId: element.dataset.submissionId,
                historyId: $routeParams.historyId,
                currentTarget: element.target
            };

            const linkAttribs = assetLinkService.getLinkAttribs(linkData);
            element.href = linkAttribs.href;

            if (linkAttribs.target !== undefined) {
                element.target = linkAttribs.target;
            }

            element.removeAttribute("data-route");
            element.removeAttribute("data-asset-id");
            element.removeAttribute("data-timeslice");
            element.removeAttribute("data-submission-id");

            return linkAttribs.href;
        };

        globalAnchorTransformConfig.preprocessHref = convertAssetLink;
        ngHtmlStringSanitiser.on(HtmlSanitiserEvent.BeforeElement, globalAnchorSanitiseEventHandler);
    }
]);
