const dumpsterName = "domPurifyDumpster";
const dumpsterDocument = document.implementation.createDocument(dumpsterName, null);

export const domPurifyDumpster = {
    _element: dumpsterDocument.createElementNS(dumpsterName, dumpsterName),
    empty: (): void => {
        domPurifyDumpster._element.innerHTML = "";
    },
    trash: (node: Node): void => {
        domPurifyDumpster._element.appendChild(node);
    }
} as const;
