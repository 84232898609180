import { FocusEvent, KeyboardEvent, MouseEvent } from "react";
import { Observable, Subject } from "@pjs/observables";
import { Immutable } from "@pjs/utilities";
import { IAriaEventAdapter } from "./interfaces/IAriaEventAdapter";
import { IComboboxEventAdapterConfig } from "./interfaces/IComboboxEventAdapterConfig";
import { IComboboxEventModelHandlers } from "./interfaces/IComboboxEventModelHandlers";
import { IComboboxTriggerEvents } from "./interfaces/IComboboxTriggerEvents";
import { ComboboxAriaModel } from "./types/ComboboxAriaModel";

export class ComboboxEventAdapter<TData> implements IAriaEventAdapter<ComboboxAriaModel, IComboboxEventModelHandlers<TData>> {
    public modelChanges: Observable<Immutable<ComboboxAriaModel>>;
    public events: IComboboxEventModelHandlers<TData>;

    private readonly _config: IComboboxEventAdapterConfig<TData>;
    private readonly _modelSubject: Subject<Immutable<ComboboxAriaModel>>;

    private _currentModel: Immutable<ComboboxAriaModel>;

    constructor(config: IComboboxEventAdapterConfig<TData>, initialModel: ComboboxAriaModel) {
        this._config = config;
        this._currentModel = initialModel;
        this._modelSubject = new Subject<ComboboxAriaModel>();
        this.modelChanges = this._modelSubject.asObservable();

        this.events = {
            item: {
                onClick: (e: MouseEvent, itemIndex: number, item: TData) => this._generateNewModel(this._config.item.onClick(e, itemIndex, item))
            },
            trigger: {
                onBlur: this._handleTriggerFocusLoss.bind(this),
                onFocus: this._handleTriggerFocus.bind(this),
                onKeyDown: this._handleTriggerKeyDown.bind(this)
            }
        };
    }

    public updateModel(newModel: ComboboxAriaModel): void {
        this._currentModel = newModel;
    }

    private _handleTriggerFocusLoss(e: FocusEvent): void {
        this._generateNewModel(this._config.trigger.onFocusLoss(e));
    }

    private _handleTriggerFocus(e: FocusEvent): void {
        this._generateNewModel(this._config.trigger.onFocus(e, this._currentModel));
    }

    private _handleTriggerKeyDown(e: KeyboardEvent, items: Array<TData>): void {
        const onKey = this._config.trigger[e.key as keyof IComboboxTriggerEvents<TData>];

        if (onKey !== undefined) {
            this._generateNewModel(onKey(e, this._currentModel, items));
        }
    }

    private _generateNewModel(newBaseModel: Partial<Immutable<ComboboxAriaModel>> | null): void {
        if (newBaseModel === null) {
            return;
        }

        const newModel = { ...this._currentModel, ...newBaseModel } as unknown as Immutable<ComboboxAriaModel>;

        this._modelSubject.next(newModel);
    }
}
