import { Observable, Subject } from "@pjs/observables";
import { EventType } from "./enums/EventType";
import { IActiveInteractionState } from "./interfaces/IActiveInteractionState";
import { IInteractionTracker } from "./interfaces/IInteractionTracker";

export class InteractionTracker implements IInteractionTracker {
    private static readonly _passiveOption: { passive: boolean } = { passive: true };
    private readonly _editorElement: HTMLElement;
    private readonly _onContentChangeBound: () => void = this._onContentChange.bind(this);
    private readonly _onKeyDownBound: (event: KeyboardEvent) => void = this._onKeyDown.bind(this);
    private readonly _onMouseDownBound: () => void = this._onMouseDown.bind(this);
    private readonly _onTouchStartBound: () => void = this._onTouchStart.bind(this);
    private readonly _onResetEventFiredBound: () => void = this._onResetEventFired.bind(this);
    private readonly _onKeyUpBound: () => void = this._onKeyUp.bind(this);
    private readonly _onMouseUpBound: () => void = this._onMouseUp.bind(this);
    private readonly _onTouchEndBound: () => void = this._onTouchEnd.bind(this);
    private _hasInsertedContent: boolean = false;

    private readonly _activeEventState: IActiveInteractionState = {
        keyDown: false,
        mouseDown: false,
        touchStart: false
    };

    private _interactionsEnded: Subject<void> = new Subject();

    constructor(editorElement: HTMLElement) {
        this._editorElement = editorElement;
    }

    public startTracking(): void {
        this._removeAllListeners();
        this._resetAllState();
        this._addInteractionListeners();
    }

    public stopTracking(): void {
        this._removeAllListeners();
        this._resetAllState();

        this._interactionsEnded.complete();
        this._interactionsEnded = new Subject();
    }

    public onInteractionsEnd(): Observable<void> {
        return this._interactionsEnded;
    }

    public hasInsertedContent(): boolean {
        return this._hasInsertedContent;
    }

    public hasEventsInProgress(): boolean {
        return this._activeEventState.keyDown || this._activeEventState.mouseDown || this._activeEventState.touchStart;
    }

    private _onResetEventFired(): void {
        this._resetActiveEventState();
        this._removeAllListeners();
        this._addInteractionListeners();

        this._interactionsEnded.next();
    }

    private _onContentChange(): void {
        this._hasInsertedContent = true;
    }

    private _onKeyDown(event: KeyboardEvent): void {
        if (!event.getModifierState(event.key)) {
            document.addEventListener(EventType.KeyUp, this._onKeyUpBound, InteractionTracker._passiveOption);
            this._editorElement.removeEventListener(EventType.KeyDown, this._onKeyDownBound);
            this._activeEventState.keyDown = true;
            this._addResetListeners();
        }
    }

    private _onKeyUp(): void {
        document.removeEventListener(EventType.KeyUp, this._onKeyUpBound);
        this._editorElement.addEventListener(EventType.KeyDown, this._onKeyDownBound, InteractionTracker._passiveOption);
        this._activeEventState.keyDown = false;
        this._onEventEnd();
    }

    private _onMouseDown(): void {
        document.addEventListener(EventType.MouseUp, this._onMouseUpBound, InteractionTracker._passiveOption);
        this._editorElement.removeEventListener(EventType.MouseDown, this._onMouseDownBound);
        this._activeEventState.mouseDown = true;
        this._addResetListeners();
    }

    private _onMouseUp(): void {
        document.removeEventListener(EventType.MouseUp, this._onMouseUpBound);
        this._editorElement.addEventListener(EventType.MouseDown, this._onMouseDownBound, InteractionTracker._passiveOption);
        this._activeEventState.mouseDown = false;
        this._onEventEnd();
    }

    private _onTouchStart(): void {
        document.addEventListener(EventType.TouchEnd, this._onTouchEndBound, InteractionTracker._passiveOption);
        this._editorElement.removeEventListener(EventType.TouchStart, this._onTouchStartBound);
        this._activeEventState.touchStart = true;
        this._addResetListeners();
    }

    private _onTouchEnd(): void {
        document.removeEventListener(EventType.TouchEnd, this._onTouchEndBound);
        this._editorElement.addEventListener(EventType.TouchStart, this._onTouchStartBound, InteractionTracker._passiveOption);
        this._activeEventState.touchStart = false;
        this._onEventEnd();
    }

    private _addInteractionListeners(): void {
        this._editorElement.addEventListener(EventType.KeyDown, this._onKeyDownBound, InteractionTracker._passiveOption);
        this._editorElement.addEventListener(EventType.MouseDown, this._onMouseDownBound, InteractionTracker._passiveOption);
        this._editorElement.addEventListener(EventType.TouchStart, this._onTouchStartBound, InteractionTracker._passiveOption);
        this._editorElement.addEventListener(EventType.Input, this._onContentChangeBound, InteractionTracker._passiveOption);
    }

    private _addResetListeners(): void {
        this._editorElement.addEventListener(EventType.Blur, this._onResetEventFiredBound, true);
        document.addEventListener(EventType.DragStart, this._onResetEventFiredBound, true);
        document.addEventListener(EventType.ContextMenu, this._onResetEventFiredBound, true);
    }

    private _removeResetListeners(): void {
        this._editorElement.removeEventListener(EventType.Blur, this._onResetEventFiredBound, true);
        document.removeEventListener(EventType.DragStart, this._onResetEventFiredBound, true);
        document.removeEventListener(EventType.ContextMenu, this._onResetEventFiredBound, true);
    }

    private _removeAllListeners(): void {
        this._editorElement.removeEventListener(EventType.KeyDown, this._onKeyDownBound);
        this._editorElement.removeEventListener(EventType.MouseDown, this._onMouseDownBound);
        this._editorElement.removeEventListener(EventType.TouchStart, this._onTouchStartBound);
        this._editorElement.removeEventListener(EventType.Input, this._onContentChangeBound);

        document.removeEventListener(EventType.KeyUp, this._onKeyUpBound, true);
        document.removeEventListener(EventType.MouseUp, this._onMouseUpBound, true);
        document.removeEventListener(EventType.TouchEnd, this._onTouchEndBound, true);

        this._removeResetListeners();
    }

    private _onEventEnd(): void {
        if (!this.hasEventsInProgress()) {
            this._removeAllListeners();
            this._addInteractionListeners();

            this._interactionsEnded.next();
        }
    }

    private _resetActiveEventState(): void {
        this._activeEventState.keyDown = false;
        this._activeEventState.mouseDown = false;
        this._activeEventState.touchStart = false;
    }

    private _resetAllState(): void {
        this._resetActiveEventState();
        this._hasInsertedContent = false;
    }
}
