import { createGuid } from "@pjs/utilities";
import { HttpHeader } from "../../enums/HttpHeader";
import { IHttpInterceptor } from "../../interfaces/IHttpInterceptor";
import { CsrfCookie } from "../csrf-cookie/CsrfCookie";
import { IHttpOptions } from "../http-service/interfaces/IHttpOptions";

export const csrfInterceptor: IHttpInterceptor = {
    id: createGuid(),
    request: (httpOptions: IHttpOptions) => {
        httpOptions.headers.append(HttpHeader.Csrf, CsrfCookie.get());
        return httpOptions;
    }
};
