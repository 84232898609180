import { react2angular } from "@pebblepad/react2angular";
import { angularAMD } from "@pebblepad/amd";
import { ValidatedInput } from "@pjs/core-ui";

angularAMD.component(
    "validatedInput",
    react2angular(ValidatedInput, [
        "isValid",
        "onInvalidInput",
        "onValidInput",
        "ariaDescribedBy",
        "ariaLabel",
        "ariaLabelledBy",
        "className",
        "disabled",
        "id",
        "inputMode",
        "onBlur",
        "onEnter",
        "title",
        "value"
    ])
);
