import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./filter-checkbox.html";

class filterCheckboxComponent extends NgComponent {
    constructor() {
        super();

        this.filterCheckbox = false;
    }

    $onChanges(changes) {
        if (changes.selected !== undefined) {
            this.filterCheckbox = changes.selected.currentValue;
        }
    }

    setCheckbox(checked) {
        this.onChange({
            checked: checked
        });
    }
}

export const FilterCheckbox = {
    bindings: {
        label: "<",
        selected: "<",
        idHook: "<",
        onChange: "&"
    },
    template: template,
    controller: filterCheckboxComponent
};

FilterCheckbox.$inject = [];
angularAMD.component("filterCheckbox", FilterCheckbox);
