import { angularAMD } from "@pebblepad/amd";

function ErrorHighlighter($timeout) {
    this.$timeout = $timeout;
    this.declineShakeTimer = null;
}

ErrorHighlighter.prototype.highlightInvalidInput = function (elementToHighlight, className, persistStyles) {
    if (elementToHighlight && elementToHighlight.length === undefined) {
        elementToHighlight = angular.element(elementToHighlight);
    }
    if (this.declineShakeTimer === null) {
        this.setElementInvalid(elementToHighlight, className);
        if (!persistStyles) {
            this.removeInvalidStyling(elementToHighlight, className, 400);
        }
    }
};

ErrorHighlighter.prototype.removeInvalidStyling = function (element, className, timer) {
    if (element && element.length === undefined) {
        element = angular.element(element);
    }
    this.declineShakeTimer = this.$timeout(
        function () {
            element.removeClass(className);
            element.attr("aria-invalid", false);
            this.declineShakeTimer = null;
        }.bind(this),
        timer
    );
};

ErrorHighlighter.prototype.setElementInvalid = function (elementToHighlight, className) {
    elementToHighlight.removeClass(className).addClass(className);
    elementToHighlight.attr("aria-invalid", true);
};

ErrorHighlighter.$inject = ["$timeout"];
angularAMD.service("errorHighlighter", ErrorHighlighter);

export { ErrorHighlighter as errorHighlighter };
