const constants = {
    RESULT_TYPE: {
        RESOURCE: "Resource",
        ASSET: "Asset"
    },
    STORE_TYPE: {
        ASSET: "asset",
        RESOURCE: "resource",
        FAVOURITE: "favourite",
        DELETED: "deleted",
        EXTERNAL: "external"
    },
    SEARCH_TYPE: {
        DEFAULT: "asset-store",
        ASSET: "asset",
        RESOURCE: "resource",
        FAVOURITE: "favourite",
        FILE: "file",
        DELETED: "deleted",
        EXTERNAL: "external"
    }
};

export { constants as STORE_CONSTANTS };
