import { angularAMD } from "@pebblepad/amd";
import { STORE_CONSTANTS } from "../../constants/store.constants";
import "../../featureSettings/FeatureSettings";
import "../../utilities/baseUrlsFactory";
import "./assetStoreService";
import "../../extensions/arrayExtensions";
import "../../multiLanguageService/multiLanguageService";

var AssetFilter = function (name, filter, type, header, icon) {
    this.DisplayName = name;
    this.Filter = filter || null;
    this.FilterType = type;
    this.Header = header || null;
    this.Icon = icon || null;
};

angularAMD.factory("filterFactory", [
    "$http",
    "baseUrlsFactory",
    "$q",
    "assetStoreService",
    "multiLanguageService",
    "featureSettings",
    function ($http, baseUrlsFactory, $q, assetStoreService, multiLanguageService, featureSettings) {
        return function () {
            var AssetStoreLoggedInUser;
            var ExternalServices;
            var AssetTypesList;
            var ResourceTypesList;
            var CombinedTypesList;
            var UseHiddenFolder;
            var AvailableSearches = [];
            var AppliedFilters = [];
            var Stages = ["default", "all", "standard-filtering", "advanced-filtering"];
            var CurrentStage = Stages[0];
            var EmptySearchData = {
                SearchType: "Everything",
                SearchTerms: "",
                Location: "Default",
                Ownership: "",
                TypeFilters: [],
                Tags: [],
                DateFrom: null,
                DateTo: null
            };
            var excludeFilters = [STORE_CONSTANTS.SEARCH_TYPE.DEFAULT];
            var didYouWant;

            // Internal functions
            var createAvailableSearch = function (id, displayName, visible) {
                var availableSearch = {
                    id: id,
                    displayName: displayName,
                    visible: visible,
                    filters: {
                        global: {
                            keyword: true
                        }
                    }
                };
                switch (id) {
                    case STORE_CONSTANTS.SEARCH_TYPE.ASSET:
                        availableSearch.filters.primary = {
                            assetTypes: AssetTypesList
                        };
                        availableSearch.filters.secondary = {
                            ownership: [
                                { name: "All", value: "" },
                                { name: "Created by me", value: "ByMe" },
                                { name: "Shared with me", value: "ForMe" },
                                { name: "Collaboration", value: "WithMe" }
                            ],
                            dates: [],
                            tags: true,
                            includeHidden: true,
                            excludeKeywords: true
                        };
                        break;
                    case STORE_CONSTANTS.SEARCH_TYPE.RESOURCE:
                        availableSearch.filters.primary = {
                            assetTypes: ResourceTypesList
                        };
                        availableSearch.filters.secondary = {
                            ownership: [
                                { name: "All", value: "" },
                                { name: "Created by me", value: "ByMe" },
                                { name: "Shared with me", value: "ForMe" },
                                { name: "Collaboration", value: "WithMe" }
                            ],
                            dates: [],
                            tags: true,
                            includeHidden: true,
                            excludeKeywords: true
                        };
                        break;
                    case STORE_CONSTANTS.SEARCH_TYPE.FAVOURITE:
                        availableSearch.filters.primary = {
                            assetTypes: CombinedTypesList
                        };
                        availableSearch.filters.secondary = {
                            ownership: [
                                { name: "All", value: "" },
                                { name: "Created by me", value: "ByMe" },
                                { name: "Shared with me", value: "ForMe" },
                                { name: "Collaboration", value: "WithMe" }
                            ],
                            dates: [],
                            tags: true,
                            includeHidden: true,
                            excludeKeywords: true
                        };
                        break;
                    case STORE_CONSTANTS.SEARCH_TYPE.FILE:
                        availableSearch.filters.primary = {
                            assetTypes: AssetTypesList
                        };
                        availableSearch.filters.secondary = {
                            ownership: [
                                { name: "All", value: "" },
                                { name: "Created by me", value: "ByMe" },
                                { name: "Shared with me", value: "ForMe" },
                                { name: "Collaboration", value: "WithMe" }
                            ],
                            dates: [],
                            tags: true,
                            includeHidden: true,
                            excludeKeywords: true
                        };
                        break;
                    case STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL:
                        availableSearch.filters.primary = {
                            dates: []
                        };
                        break;
                    case "contact":
                        availableSearch.filters.primary = {
                            rolodex: ["a", "b", "c", "..."]
                        };
                        break;
                    case STORE_CONSTANTS.SEARCH_TYPE.DELETED:
                        availableSearch.filters.primary = {
                            assetTypes: CombinedTypesList
                        };
                        availableSearch.filters.secondary = {
                            ownership: [
                                { name: "All", value: "" },
                                { name: "Created by me", value: "ByMe" },
                                { name: "Shared with me", value: "ForMe" },
                                { name: "Collaboration", value: "WithMe" }
                            ],
                            dates: [],
                            tags: true,
                            includeHidden: true,
                            excludeKeywords: true
                        };
                        break;
                }
                AvailableSearches.push(availableSearch);
            };

            function isDefaultSearchData(searchData) {
                if (searchData.SearchTerms !== undefined && searchData.SearchTerms !== "") {
                    return false;
                }

                const keys = Object.keys(searchData);
                if (keys.length > 2) {
                    return false;
                }

                return keys.length === 2 ? Object.values(STORE_CONSTANTS.SEARCH_TYPE).includes(searchData.SearchType) : true;
            }

            this.init = function () {
                var self = this;
                var initPromise = assetStoreService.initSearch();
                initPromise.then(function (response) {
                    // Get available external services such as Google Drive, OneDrive etc
                    ExternalServices = response.data.ExternalServices;

                    // Remove default PebblePad store
                    for (var i = 0; i < ExternalServices.length; i++) {
                        if (ExternalServices[i].ServiceId === "Default") {
                            ExternalServices.splice(i, 1);
                            break;
                        }
                    }

                    // Populate list of asset main/sub types
                    AssetTypesList = response.data.AssetMenu;
                    ResourceTypesList = response.data.ResourceMenu;
                    CombinedTypesList = response.data.CombinedMenu;

                    // Create all search types
                    AvailableSearches.splice(0, AvailableSearches.length);
                    createAvailableSearch(STORE_CONSTANTS.SEARCH_TYPE.DEFAULT, "Everything", false);
                    createAvailableSearch(STORE_CONSTANTS.SEARCH_TYPE.ASSET, "Assets", true);
                    createAvailableSearch(STORE_CONSTANTS.SEARCH_TYPE.RESOURCE, "Resources", true);

                    if (featureSettings.isEnabled("Favourites")) {
                        createAvailableSearch(STORE_CONSTANTS.SEARCH_TYPE.FAVOURITE, "Favourites", true);
                    }

                    createAvailableSearch(STORE_CONSTANTS.SEARCH_TYPE.FILE, "Files", true);
                    createAvailableSearch(STORE_CONSTANTS.SEARCH_TYPE.DELETED, "Deleted", false);
                    self.removeFilterTypes(AssetTypesList);

                    if (ExternalServices && ExternalServices.length) {
                        for (var i = 0; i < ExternalServices.length; i++) {
                            var service = ExternalServices[i];
                            createAvailableSearch(`${STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL}_${service.ServiceId}`, service.Name, true);
                        }
                    }
                    AssetStoreLoggedInUser = response.data.LoggedInUser.ShowAssetStoreHelp;
                    UseHiddenFolder = response.data.UseHiddenFolder;
                });

                return initPromise;
            };

            this.removeFilterTypes = function (list) {
                for (var i = 0, l = list.length, filter; i < l; i++) {
                    filter = list[i];
                    if (excludeFilters.indexOf(filter.Filter) !== -1) {
                        filter.Filter = null;
                    }
                }
            };

            this.initSearchData = function () {
                return angular.copy(EmptySearchData);
            };

            this.getCurrentStage = function () {
                return CurrentStage;
            };

            this.setStage = function (stage) {
                var stageIndex = Stages.indexOf(stage);
                if (stageIndex === -1) {
                    return CurrentStage;
                }
                CurrentStage = Stages[stageIndex];

                return CurrentStage;
            };

            this.nextStage = function () {
                var stageIndex = Stages.indexOf(CurrentStage);
                if (stageIndex > -1 && Stages.length > stageIndex + 1) {
                    CurrentStage = Stages[stageIndex + 1];
                }
                return CurrentStage;
            };

            this.previousStage = function () {
                var stageIndex = Stages.indexOf(CurrentStage);
                if (stageIndex > 0) {
                    CurrentStage = Stages[stageIndex - 1];
                }
                return CurrentStage;
            };

            this.checkReservedWordList = function (searchTerms, searchId) {
                var typesList;
                switch (searchId) {
                    case STORE_CONSTANTS.SEARCH_TYPE.ASSET:
                        typesList = AssetTypesList;
                        break;
                    case STORE_CONSTANTS.SEARCH_TYPE.RESOURCE:
                        typesList = ResourceTypesList;
                        break;
                    default:
                        typesList = CombinedTypesList;
                        break;
                }
                // Clear existing reserved word match from available search types
                for (var i = 0; i < AvailableSearches.length; i++) {
                    if (AvailableSearches[i].id.toLowerCase().indexOf("assettype") > -1) {
                        // assettype search found so remove
                        AvailableSearches.splice(i, 1);
                    }
                }

                // If empty keyword then clear existing reserved word search and return
                if (!searchTerms) {
                    return;
                }

                // Only checking on first word
                var wordToCheck = searchTerms.split(" ")[0].toLowerCase();

                // Check for match against the available Maintypes and Subtypes
                for (i = 0; i < typesList.length; i++) {
                    var assetTypeFilter = typesList[i];
                    // tslint:disable-next-line:triple-equals
                    if (assetTypeFilter.Header == true || assetTypeFilter.Filter === null) {
                        continue;
                    }

                    var strCheck = assetTypeFilter.Filter.toLowerCase();
                    strCheck = strCheck.replace("document/", "");
                    // if keyword matches a reserved word
                    if (strCheck.indexOf(wordToCheck) > -1 || assetTypeFilter.DisplayName.toLowerCase().indexOf(wordToCheck) > -1) {
                        // Check if already one search level deep
                        if (Stages.indexOf(CurrentStage) > 1) {
                            didYouWant = angular.copy(assetTypeFilter);
                            return;
                        } else {
                            // We are doing an everything search so add the new search results group
                            createAvailableSearch("assettype_" + assetTypeFilter.Filter, assetTypeFilter.Filter, false);
                            return;
                        }
                    }
                }
            };

            this.convertFiltersToSearchData = function (availableSearch, searchTerms, isSidebar) {
                var viewData = {
                    SearchTerms: searchTerms
                };

                if (availableSearch.id !== STORE_CONSTANTS.SEARCH_TYPE.DEFAULT && availableSearch.id.indexOf(STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) === -1) {
                    this.addFilter({
                        DisplayName: availableSearch.displayName,
                        Filter: availableSearch.id,
                        FilterType: "searchType",
                        Visible: isSidebar
                    });
                }

                angular.forEach(AppliedFilters, function (filter) {
                    switch (filter.type) {
                        case "searchType":
                            viewData.SearchType = filter.value;
                            break;
                        case "location":
                            // todo: override search type when searching external
                            //searchToRun = 'asset';
                            viewData.Location = filter.value;
                            break;
                        case "primary.assetTypes":
                            viewData.TypeFilters = [filter.value];
                            viewData.SelectedMainType = filter.name;
                            break;
                        case "secondary.ownership":
                            viewData.Ownership = [filter.value];
                            viewData.SelectedOwnership = filter.name;
                            break;
                        case "secondary.tags":
                            if (!viewData.Tags) {
                                viewData.Tags = [];
                            }
                            if (viewData.Tags.indexOf(filter.value) === -1) {
                                viewData.Tags.push(filter.value);
                            }
                            break;
                        case "secondary.dateFrom":
                            viewData.LastModifiedAfter = filter.value;
                            break;
                        case "secondary.dateTo":
                        case "secondary.dateTo":
                            viewData.LastModifiedBefore = filter.value;
                            break;
                        case "secondary.includeHidden":
                            viewData.ShowHidden = filter.value;
                            break;
                        case "secondary.excludeKeywords":
                            viewData.ExcludeKeywords = filter.value;
                            break;
                    }
                });

                viewData.isDefaultSearch = isDefaultSearchData(viewData);

                return viewData;
            };

            this.getAppliedFilters = function (checkVisibility) {
                if (!checkVisibility) {
                    return AppliedFilters.slice(0);
                }
                var visibleFilters = [],
                    filter;
                for (var i = 0; i < AppliedFilters.length; i++) {
                    filter = AppliedFilters[i];
                    if (filter.visible) {
                        visibleFilters.push(filter);
                    }
                }
                return visibleFilters;
            };

            this.addFilter = function (filter, overrideType, isArray) {
                if (overrideType) {
                    filter.FilterType = overrideType;
                }

                var filters = isArray ? this.createFiltersFromFilter(filter) : [filter];

                var safeToPush = true,
                    j = 0,
                    subFilter,
                    temp;

                for (var i = 0, l = filters.length; i < l; i++) {
                    subFilter = filters[i];

                    for (j = 0; j < AppliedFilters.length; j++) {
                        temp = AppliedFilters[j];

                        // If the filter cannot be an array and it already exists then replace it
                        if (!isArray) {
                            // tslint:disable-next-line:triple-equals
                            if (temp.type == subFilter.FilterType && temp.value != subFilter.Filter) {
                                AppliedFilters.splice(j, 1);
                            }
                        }

                        // If filter value already exists then leave it
                        // tslint:disable-next-line:triple-equals
                        if (temp.value == subFilter.Filter) {
                            safeToPush = false;
                            break;
                        }
                    }
                    if (safeToPush) {
                        AppliedFilters.push({
                            name: subFilter.DisplayName,
                            value: subFilter.Filter,
                            type: filter.FilterType,
                            // tslint:disable-next-line:triple-equals
                            visible: filter.Visible == undefined || filter.Visible == true
                        });
                    }
                }

                return AppliedFilters;
            };

            this.removeFilter = function (index) {
                var filterRemoved = AppliedFilters[index];
                // Clear all child filters if global level is removed
                if (filterRemoved.type === "searchType") {
                    AppliedFilters = [];
                } else {
                    AppliedFilters.splice(index, 1);
                }
                // If primary filter is removed return to primary search stage
                /*if (filterRemoved.type.indexOf("primary") != -1) {
             this.previousStage();
             }*/
                return AppliedFilters;
            };

            this.getAvailableSearches = function () {
                return AvailableSearches;
            };

            this.getExternalServices = function () {
                return ExternalServices;
            };

            this.getAssetTypesList = function () {
                return AssetTypesList;
            };

            this.getDidYouWant = function () {
                return didYouWant;
            };

            this.resetDidYouWant = function () {
                didYouWant = {};
                return didYouWant;
            };

            this.getAssetStoreLoggedInUser = function () {
                return AssetStoreLoggedInUser;
            };

            this.getUseHiddenFolder = function () {
                return UseHiddenFolder;
            };

            this.createFiltersFromFilter = function (filter) {
                var filters = [],
                    item;
                for (var i = 0, l = filter.Filter.length; i < l; i++) {
                    item = filter.Filter[i];
                    filters.push({
                        DisplayName: filter.DisplayName + " " + item,
                        Filter: item,
                        FilterType: filter.FilterType
                    });
                }

                return filters;
            };

            this.getAllFilters = function () {
                return {
                    global: {
                        keyword: true
                    },
                    primary: {
                        responsesTo: null,
                        assetTypes: [
                            {
                                DisplayName: multiLanguageService.getString("asset_store.filters.everything"),
                                Filter: null,
                                FilterType: "",
                                Icon: "asset-store",
                                Header: true
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.activitylog", { count: 1 }),
                                Filter: "ActivityLog",
                                FilterType: "MainType",
                                Icon: "activitylog",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.blog", { count: 1 }),
                                Filter: "Blog",
                                FilterType: "MainType",
                                Icon: "blog",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.collection", { count: 1 }),
                                Filter: "AssetCollectionWrapper",
                                FilterType: "MainType",
                                Icon: "assetcollectionwrapper",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.webfoliopage", { count: 1 }),
                                Filter: "WebfolioPage",
                                FilterType: "MainType",
                                Icon: "webfoliopage",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.webfolio", { count: 1 }),
                                Filter: "Webfolio",
                                FilterType: "MainType",
                                Icon: "webfolio",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.post", { count: 1 }),
                                Filter: "Post",
                                FilterType: "MainType",
                                Icon: "post",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.workbookresponse", { count: 1 }),
                                Filter: "WorkBookResponse",
                                FilterType: "MainType",
                                Icon: "workbookresponse",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.worksheet", { count: 1 }),
                                Filter: "Worksheet",
                                FilterType: "SubType",
                                Icon: "worksheet",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.experience", { count: 1 }),
                                Filter: "Experience",
                                FilterType: "SubType",
                                Icon: "experience",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.plan", { count: 1 }),
                                Filter: "Plan",
                                FilterType: "SubType",
                                Icon: "plan",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.reflection", { count: 1 }),
                                Filter: "Reflection",
                                FilterType: "SubType",
                                Icon: "reflection",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.talent", { count: 1 }),
                                Filter: "Talent",
                                FilterType: "SubType",
                                Icon: "talent",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.file", { count: 1 }),
                                Filter: "File",
                                FilterType: "MainType",
                                Icon: "file",
                                Header: true
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.audio", { count: 1 }),
                                Filter: "Audio",
                                FilterType: "Category",
                                Icon: "audio",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.image", { count: 1 }),
                                Filter: "Image",
                                FilterType: "Category",
                                Icon: "image",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.documentpdf", { count: 1 }),
                                Filter: "Document/PDF",
                                FilterType: "SubType",
                                Icon: "pdf",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.video", { count: 1 }),
                                Filter: "Video",
                                FilterType: "Category",
                                Icon: "video",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_types.written"),
                                Filter: "Documents",
                                FilterType: "Category",
                                Icon: "documents",
                                Header: false
                            },
                            {
                                DisplayName: multiLanguageService.getString("pebble_terms.asset_categories.other_files"),
                                Filter: "OtherFiles",
                                FilterType: "Category",
                                Icon: "other",
                                Header: false
                            }
                        ]
                    },
                    secondary: {
                        dates: [],
                        excludeKeywords: true,
                        includeHidden: true,
                        ownership: null,
                        tags: true
                    }
                };
            };
        };
    }
]);
