import { FunctionComponent } from "react";
import { IStaticLabelProps } from "./interfaces/IStaticLabelProps";
import "./styles/static-label.css";

export const StaticLabel: FunctionComponent<IStaticLabelProps> = (props: IStaticLabelProps) => {
    return (
        <div className="cui-static-label" data-hook={props.dataHook}>
            {props.text}
        </div>
    );
};
