import { angularAMD } from "@pebblepad/amd";
import "../datePicker/datePickerHelper";
import "../multiLanguageService/multiLanguageService";

angularAMD.filter("pebbleDate", PebbleDateWrapper);
PebbleDateWrapper.$inject = ["$filter", "datePickerHelper", "multiLanguageService"];

function PebbleDateWrapper($filter, datePickerHelper, multiLanguageService) {
    var lang = multiLanguageService.lang_attr;
    var standardDateFilterFn = $filter("date");

    //If using API pre-converted localised time pass true for usePreConvertedTime
    function pebbleDate(date, layout, usePreConvertedTime) {
        var formatter = PebbleDateFormats[layout] || PebbleDateFormats.default;
        return formatter(date, usePreConvertedTime);
    }

    function updateLanguage(dateAsString, normalizedDate, replaceList) {
        // only translate if not english and have stuff to replace
        if (dateAsString && normalizedDate && replaceList && replaceList.length > 0 && lang !== "en-GB" && lang !== "en-US" && lang !== "en-NZ" && lang !== "en-AU") {
            replaceList.forEach(function (replace) {
                switch (replace) {
                    case "EEEE":
                        dateAsString = longDayLanguage(normalizedDate, dateAsString);
                        break;
                    case "EEE":
                        dateAsString = shortDayLanguage(normalizedDate, dateAsString);
                        break;
                    case "MMMM":
                        dateAsString = longMonthLanguage(normalizedDate, dateAsString);
                        break;
                    case "MMM":
                        dateAsString = shortMonthLanguage(normalizedDate, dateAsString);
                        break;
                }
            });
        }

        return dateAsString;
    }

    function findAndReplace(findIn, find, replaceWith) {
        return findIn ? findIn.replace(find, replaceWith) : findIn;
    }

    function longDayLanguage(normalizedDate, dateAsString) {
        switch (standardDateFilterFn(normalizedDate, "EEEE")) {
            case "Monday":
                return findAndReplace(dateAsString, "Monday", multiLanguageService.getString("builder.datePicker.weekdays.mon.full"));
            case "Tuesday":
                return findAndReplace(dateAsString, "Tuesday", multiLanguageService.getString("builder.datePicker.weekdays.tue.full"));
            case "Wednesday":
                return findAndReplace(dateAsString, "Wednesday", multiLanguageService.getString("builder.datePicker.weekdays.wed.full"));
            case "Thursday":
                return findAndReplace(dateAsString, "Thursday", multiLanguageService.getString("builder.datePicker.weekdays.thu.full"));
            case "Friday":
                return findAndReplace(dateAsString, "Friday", multiLanguageService.getString("builder.datePicker.weekdays.fri.full"));
            case "Saturday":
                return findAndReplace(dateAsString, "Saturday", multiLanguageService.getString("builder.datePicker.weekdays.sat.full"));
            case "Sunday":
                return findAndReplace(dateAsString, "Sunday", multiLanguageService.getString("builder.datePicker.weekdays.sun.full"));
        }
        return dateAsString;
    }

    function shortDayLanguage(normalizedDate, dateAsString) {
        switch (standardDateFilterFn(normalizedDate, "EEE")) {
            case "Mon":
                return findAndReplace(dateAsString, "Mon", multiLanguageService.getString("builder.datePicker.weekdays.mon.short"));
            case "Tue":
                return findAndReplace(dateAsString, "Tue", multiLanguageService.getString("builder.datePicker.weekdays.tue.short"));
            case "Wed":
                return findAndReplace(dateAsString, "Wed", multiLanguageService.getString("builder.datePicker.weekdays.wed.short"));
            case "Thu":
                return findAndReplace(dateAsString, "Thu", multiLanguageService.getString("builder.datePicker.weekdays.thu.short"));
            case "Fri":
                return findAndReplace(dateAsString, "Fri", multiLanguageService.getString("builder.datePicker.weekdays.fri.short"));
            case "Sat":
                return findAndReplace(dateAsString, "Sat", multiLanguageService.getString("builder.datePicker.weekdays.sat.short"));
            case "Sun":
                return findAndReplace(dateAsString, "Sun", multiLanguageService.getString("builder.datePicker.weekdays.sun.short"));
        }
        return dateAsString;
    }

    function longMonthLanguage(normalizedDate, dateAsString) {
        switch (standardDateFilterFn(normalizedDate, "MMMM")) {
            case "January":
                return findAndReplace(dateAsString, "January", multiLanguageService.getString("builder.datePicker.months.jan.full"));
            case "February":
                return findAndReplace(dateAsString, "February", multiLanguageService.getString("builder.datePicker.months.feb.full"));
            case "March":
                return findAndReplace(dateAsString, "March", multiLanguageService.getString("builder.datePicker.months.mar.full"));
            case "April":
                return findAndReplace(dateAsString, "April", multiLanguageService.getString("builder.datePicker.months.apr.full"));
            case "May":
                return findAndReplace(dateAsString, "May", multiLanguageService.getString("builder.datePicker.months.may.full"));
            case "June":
                return findAndReplace(dateAsString, "June", multiLanguageService.getString("builder.datePicker.months.jun.full"));
            case "July":
                return findAndReplace(dateAsString, "July", multiLanguageService.getString("builder.datePicker.months.jul.full"));
            case "August":
                return findAndReplace(dateAsString, "August", multiLanguageService.getString("builder.datePicker.months.aug.full"));
            case "September":
                return findAndReplace(dateAsString, "September", multiLanguageService.getString("builder.datePicker.months.sep.full"));
            case "October":
                return findAndReplace(dateAsString, "October", multiLanguageService.getString("builder.datePicker.months.oct.full"));
            case "November":
                return findAndReplace(dateAsString, "November", multiLanguageService.getString("builder.datePicker.months.nov.full"));
            case "December":
                return findAndReplace(dateAsString, "December", multiLanguageService.getString("builder.datePicker.months.dec.full"));
        }
        return dateAsString;
    }

    function shortMonthLanguage(normalizedDate, dateAsString) {
        switch (standardDateFilterFn(normalizedDate, "MMM")) {
            case "Jan":
                return findAndReplace(dateAsString, "Jan", multiLanguageService.getString("builder.datePicker.months.jan.short"));
            case "Feb":
                return findAndReplace(dateAsString, "Feb", multiLanguageService.getString("builder.datePicker.months.feb.short"));
            case "Mar":
                return findAndReplace(dateAsString, "Mar", multiLanguageService.getString("builder.datePicker.months.mar.short"));
            case "Apr":
                return findAndReplace(dateAsString, "Apr", multiLanguageService.getString("builder.datePicker.months.apr.short"));
            case "May":
                return findAndReplace(dateAsString, "May", multiLanguageService.getString("builder.datePicker.months.may.short"));
            case "Jun":
                return findAndReplace(dateAsString, "Jun", multiLanguageService.getString("builder.datePicker.months.jun.short"));
            case "Jul":
                return findAndReplace(dateAsString, "Jul", multiLanguageService.getString("builder.datePicker.months.jul.short"));
            case "Aug":
                return findAndReplace(dateAsString, "Aug", multiLanguageService.getString("builder.datePicker.months.aug.short"));
            case "Sep":
                return findAndReplace(dateAsString, "Sep", multiLanguageService.getString("builder.datePicker.months.sep.short"));
            case "Oct":
                return findAndReplace(dateAsString, "Oct", multiLanguageService.getString("builder.datePicker.months.oct.short"));
            case "Nov":
                return findAndReplace(dateAsString, "Nov", multiLanguageService.getString("builder.datePicker.months.nov.short"));
            case "Dec":
                return findAndReplace(dateAsString, "Dec", multiLanguageService.getString("builder.datePicker.months.dec.short"));
        }
        return dateAsString;
    }

    function timeDifference(dateTime) {
        var current = new Date();
        var previous = Date.parse(dateTime);

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return multiLanguageService.getString("pebble_date.number_second_ago", {
                count: Math.round(elapsed / 1000)
            });
        }

        if (elapsed < msPerHour) {
            return multiLanguageService.getString("pebble_date.number_minute_ago", {
                count: Math.round(elapsed / msPerMinute)
            });
        }

        if (elapsed < msPerDay) {
            return multiLanguageService.getString("pebble_date.number_hour_ago", {
                count: Math.round(elapsed / msPerHour)
            });
        }

        if (elapsed < msPerMonth) {
            return multiLanguageService.getString("pebble_date.number_day_ago", {
                count: Math.round(elapsed / msPerDay)
            });
        }

        if (elapsed < msPerYear) {
            return multiLanguageService.getString("pebble_date.number_month_ago", {
                count: Math.round(elapsed / msPerMonth)
            });
        }

        return multiLanguageService.getString("pebble_date.number_year_ago", {
            count: Math.round(elapsed / msPerYear)
        });
    }

    //Formats - Access via literal string notation (string key).
    var PebbleDateFormats = {
        relativeTime: function (date) {
            return timeDifference(date);
        },
        timeOnly: function (date, usePreConvertedTime) {
            var time = standardDateFilterFn(datePickerHelper.normalizeDate(date, usePreConvertedTime), "HH:mm");
            if (time === "23:59") {
                return multiLanguageService.getString("labels.midnight");
            } else if (time === "12:00") {
                return multiLanguageService.getString("labels.midday");
            } else {
                return time;
            }
        },

        timeWithSecondsOnly: function (date, usePreConvertedTime) {
            var time = standardDateFilterFn(datePickerHelper.normalizeDate(date, usePreConvertedTime), "HH:mm:ss");
            if (time === "23:59:59") {
                return multiLanguageService.getString("labels.midnight");
            } else if (time === "12:00:00") {
                return multiLanguageService.getString("labels.midday");
            } else {
                return time;
            }
        },

        yearOnly: function (date, usePreConvertedTime) {
            return standardDateFilterFn(datePickerHelper.normalizeDate(date, usePreConvertedTime), "yyyy");
        },

        withDay: function (date, usePreConvertedTime) {
            var normalizedDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            return updateLanguage(standardDateFilterFn(normalizedDate, "EEEE dd-MMM-yyyy"), normalizedDate, ["EEEE", "MMM"]);
        },

        contextual: function (date, usePreConvertedTime) {
            var myDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            var today = datePickerHelper.normalizeDate(new Date(), usePreConvertedTime);

            if (myDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
                return standardDateFilterFn(datePickerHelper.normalizeDate(date, usePreConvertedTime), "HH:mm");
            }

            var normalizedDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            return updateLanguage(standardDateFilterFn(normalizedDate, "dd-MMM"), normalizedDate, ["MMM"]);
        },

        dateAndMonth: function (date, usePreConvertedTime) {
            var normalizedDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            return updateLanguage(standardDateFilterFn(normalizedDate, "dd MMMM yyyy"), normalizedDate, ["MMMM"]);
        },

        monthAndYear: function (date, usePreConvertedTime) {
            var normalizedDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            return updateLanguage(standardDateFilterFn(normalizedDate, "MMMM yyyy"), normalizedDate, ["MMMM"]);
        },

        fullDateAndTime: function (date, usePreConvertedTime) {
            var normalizedDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            return updateLanguage(standardDateFilterFn(normalizedDate, "EEEE, dd MMMM yyyy HH:mm:ss"), normalizedDate, ["EEEE", "MMMM"]);
        },

        fullDateAndTimeWithoutSeconds: function (date, usePreConvertedTime) {
            var normalizedDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            return updateLanguage(standardDateFilterFn(normalizedDate, "EEEE, dd MMMM yyyy HH:mm"), normalizedDate, ["EEEE", "MMMM"]);
        },

        default: function (date, usePreConvertedTime) {
            var normalizedDate = datePickerHelper.normalizeDate(date, usePreConvertedTime);
            return updateLanguage(standardDateFilterFn(normalizedDate, "dd-MMM-yyyy"), normalizedDate, ["MMM"]);
        }
    };

    return pebbleDate;
}
