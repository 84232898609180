import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("focusStyleProvider", function () {
    return {
        document_el: null,

        getDocumentEl: function () {
            if (!this.document_el) {
                this.document_el = angular.element(document.documentElement);
            }

            return this.document_el;
        },

        addFocusStyleClass: function () {
            this.getDocumentEl().addClass("focus-style");
        },

        bindFocusApplier: function () {
            var self = this;

            /**
             * Show focus styles only when keyboard being used
             */
            document.addEventListener("mousedown", function () {
                self.getDocumentEl().removeClass("focus-style"); // remove all focus styles from page when user interact with mouse
            });

            document.addEventListener("keydown", function (e) {
                // add focus styles on a page when user interact with keyboard
                if (e.keyCode === 9 || e.altKey) {
                    self.getDocumentEl().addClass("focus-style");
                }
            });
        }
    };
});
