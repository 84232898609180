import { KeyboardEvent, MouseEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { toggleIsOpen } from "./ToggleIsOpen.function";

export function toggleIsOpenAndResetIndex(e: KeyboardEvent | MouseEvent, currentModel: Immutable<MenuAriaModel>): Partial<Immutable<MenuAriaModel>> {
    const newModel = { activeItemIndex: 0, ...toggleIsOpen(e, currentModel) };

    return newModel as Partial<Immutable<MenuAriaModel>>;
}
