import { Fragment, useId, useRef, useState } from "react";
import { AsyncModelStatus, Immutable } from "@pjs/utilities";
import { useClassRef, useOnMount, useOnValueChange } from "@pjs/react-utilities";
import { IMenuItem } from "../aria-roles/menus/types/IMenuItem";
import { AriaMenuStatus } from "../aria-roles/menus/menu-event-adapter/enums/AriaMenuStatus";
import { MenuAriaModel } from "../aria-roles/menus/menu-event-adapter/types/MenuAriaModel";
import { StyledLoadingSpinner } from "../loading-spinner/StyledLoadingSpinner.component";
import { TriggerEventAdapter } from "../aria-roles/menus/menu-event-adapter/TriggerEventAdapter";
import { initialMenuAriaModel } from "../aria-roles/menus/floating-menu/consts/InitialMenuAriaModel.const";
import { menuRoleTriggerEvents } from "../aria-roles/menus/menu-event-adapter/consts/MenuRoleTriggerEvents.const";
import { IGroupedItemPickerProps } from "./interfaces/IGroupedItemPickerProps";
import { EmptyGroupedItemsMenu } from "./menus/EmptyGroupedItemsMenu.component";
import { GroupedItemsMenu } from "./menus/GroupedItemsMenu.component";
import "./styles/grouped-item-picker.css";

export const GroupedItemPicker = <T extends IMenuItem>(props: IGroupedItemPickerProps<T>): JSX.Element => {
    const buttonReference = useRef<HTMLButtonElement>(null);
    const adapterDataset = useRef<Array<T>>([]);
    const tempTargetRef = useRef<HTMLElement | null>(null);
    const labelledById = useId();
    const dropdownId = useId();

    const [menuModel, setMenuModel] = useState<Immutable<MenuAriaModel>>(initialMenuAriaModel);
    const triggerEventController = useClassRef(TriggerEventAdapter<T>, menuRoleTriggerEvents, menuModel);

    const updateMenuModel = (newMenuModel: Immutable<MenuAriaModel>): void => {
        triggerEventController.updateModel(newMenuModel);
        setMenuModel(newMenuModel);
    };

    useOnMount(() => {
        const eventSubscription = triggerEventController.modelChanges.subscribe(updateMenuModel);
        return () => eventSubscription.unsubscribe();
    });

    useOnValueChange(
        () => {
            adapterDataset.current = props.model.result !== null ? props.model.result.flatMap((itemGroup) => itemGroup.items) : [];

            updateMenuModel({
                ...menuModel,
                activeItemIndex: 0
            });
        },
        [props.model.result],
        true
    );

    useOnValueChange(() => {
        if (menuModel.type === AriaMenuStatus.ClosedByKeyboardEvent && buttonReference.current !== null) {
            buttonReference.current.focus();
        }
    }, [menuModel.type]);

    return (
        <Fragment>
            <div data-hook="grouped-item-picker-trigger-container">
                <button
                    className={`${props.buttonClassName} ${menuModel.isOpen ? `${props.buttonClassName}--active` : ""}`}
                    ref={buttonReference}
                    aria-label={props.ariaLabel}
                    id={labelledById}
                    aria-expanded={menuModel.isOpen}
                    aria-controls={menuModel.isOpen ? dropdownId : undefined}
                    aria-haspopup="true"
                    onClick={(e) => {
                        tempTargetRef.current = null;
                        triggerEventController.events.onClick(e);
                    }}
                    onMouseDown={(e) => (tempTargetRef.current = e.target as HTMLElement)}
                    onKeyDown={(e) => triggerEventController.events.onKeyDown(e, adapterDataset.current)}>
                    {props.buttonContent}
                </button>
            </div>

            {menuModel.isOpen && (
                <div className="cui-grouped-item-picker__list-box-container" data-hook="grouped-item-picker-dropdown-container">
                    {props.model.status === AsyncModelStatus.Pending && (
                        <EmptyGroupedItemsMenu
                            dataHook="grouped-item-picker-pending-container"
                            dropdownId={dropdownId}
                            buttonRef={buttonReference}
                            menuModel={menuModel}
                            onMenuModelChange={updateMenuModel}
                            tempTargetRef={tempTargetRef}>
                            <Fragment>
                                <StyledLoadingSpinner className="cui-grouped-item-picker__spinner" />
                                <span data-hook="grouped-item-picker-pending-text" className="cui-grouped-item-picker__pending-text">
                                    {props.model.pending}
                                </span>
                            </Fragment>
                        </EmptyGroupedItemsMenu>
                    )}

                    {props.model.status === AsyncModelStatus.Error && (
                        <EmptyGroupedItemsMenu
                            dataHook="grouped-item-picker-error-container"
                            dropdownId={dropdownId}
                            buttonRef={buttonReference}
                            menuModel={menuModel}
                            onMenuModelChange={updateMenuModel}
                            tempTargetRef={tempTargetRef}>
                            {props.model.error}
                        </EmptyGroupedItemsMenu>
                    )}

                    {props.model.status === AsyncModelStatus.Resolved && (
                        <GroupedItemsMenu
                            buttonRef={buttonReference}
                            dropdownId={dropdownId}
                            adapterDataset={adapterDataset.current}
                            labelledById={labelledById}
                            menuModel={menuModel}
                            onItemSelect={props.onItemSelect}
                            onMenuModelChange={updateMenuModel}
                            renderOption={props.renderOption}
                            result={props.model.result}
                            tempTargetRef={tempTargetRef}
                        />
                    )}
                </div>
            )}
        </Fragment>
    );
};
