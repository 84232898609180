import { FunctionComponent, PropsWithChildren } from "react";
import { Icon } from "../icon/Icon.component";
import { accordionIndicatorIcon } from "../icon/icons/AccordionIndicator.icon";
import { CollapsibleManual } from "../collapsible/CollapsibleManual.component";
import { IStyledCollapsibleProps } from "./interfaces/IStyledCollapsibleProps";
import "./styles/styled-collapsible.css";

export const StyledCollapsible: FunctionComponent<PropsWithChildren<IStyledCollapsibleProps>> = (props) => {
    const className = props.isOpen ? "cui-styled-collapsible__header-arrow" : "cui-styled-collapsible__header-arrow cui-styled-collapsible__header-arrow--rotate";
    const styledClassName = props.isContained ? "cui-styled-collapsible--contained" : "";

    return (
        <CollapsibleManual className={`cui-styled-collapsible ${styledClassName}`} onToggle={props.onToggle} isOpen={props.isOpen}>
            <CollapsibleManual.Header className="cui-styled-collapsible__header">
                <Icon className={className} source={accordionIndicatorIcon} />
                <span className="cui-styled-collapsible__header-text">{props.header}</span>
            </CollapsibleManual.Header>
            <CollapsibleManual.Content className="cui-styled-collapsible__content">{props.children}</CollapsibleManual.Content>
        </CollapsibleManual>
    );
};
