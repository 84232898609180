import { FunctionComponent } from "react";
import { feedbackI18n } from "../i18n/FeedbackI18n.const";
import { FeedbackNotice } from "../feedback-notice/FeedbackNotice.component";
import { ApprovalStatus } from "../../features/approvals/enums/ApprovalStatus";
import { verifiedNotice } from "./consts/VerifiedNotice.const";

export const VerificationView: FunctionComponent = () => {
    const noticeLabel = feedbackI18n.getString("accessibility.notice.labels.page_verification");
    const statusNotice = {
        noticeType: verifiedNotice,
        status: ApprovalStatus.Positive,
        statusText: feedbackI18n.getString("notice.labels.page_verified")
    };
    return <FeedbackNotice statusNotice={statusNotice} noticeAriaLabel={noticeLabel} />;
};
