import { angularAMD } from "@pebblepad/amd";
import "./modalDialog";

angularAMD.directive("modalFacade", [
    "$rootScope",
    "$compile",
    function ($rootScope, $compile) {
        return {
            restrict: "E",
            scope: true,

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.presetData = function () {
                        $scope.multiLanguageService = $rootScope.multiLanguageService;
                    };

                    // append compiled modal to the DOM
                    $scope.appendCompiledModal = function () {
                        const html = '<ng-include id="' + $scope.modalId + '" src="\'' + $scope.templateUrl + "'\"></ng-include>";

                        const compiled_elem = $compile(html)($scope);
                        // tslint:disable-next-line:no-unsafe-jq-lite
                        $scope.compile_target.append(compiled_elem);
                    };

                    $scope.init = function () {
                        $scope.presetData();
                        $scope.appendCompiledModal();
                    };

                    $scope.init();
                }
            ],

            link: function (scope, element, attrs) {
                element.on("$destroy", function () {
                    scope.$destroy();
                });
            }
        };
    }
]);
