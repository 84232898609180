import { i18nManager } from "@pjs/i18n";
import { ckeI18nResources } from "@pebblepad/ckeditor";
import { CkeI18nSet } from "./CkeI18nSet";

const ckeI18nSet = new CkeI18nSet("en-GB", ckeI18nResources.mandatory);

for (const resource of ckeI18nResources.translations) {
    ckeI18nSet.add(resource.languageCode, resource.load);
}

i18nManager.addSet("ckeditor", ckeI18nSet);
