//Directive Factory - Create directives from a list of key values.
//=============================================================================================================
/**
 * @constructor
 */
// tslint:disable-next-line:no-empty
function DirectiveAttributeFactory(directiveMap) {}

/**
 * @param {NgModule} ngModule
 * @param {Array<{key: string, value: string}>} directives
 */
DirectiveAttributeFactory.prototype.createDirectives = function (ngModule, directives) {
    directives.forEach(
        function (keyValue) {
            ngModule.directive(keyValue.key, this.createDirective.bind(this, keyValue.key, keyValue.value));
        }.bind(this)
    );
};

DirectiveAttributeFactory.prototype.createDirective = function (directiveName, ariaAttributeName) {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            attrs.$observe(directiveName, function (newValue) {
                if (newValue) {
                    element[0].setAttribute(ariaAttributeName, newValue);
                } else {
                    element[0].removeAttribute(ariaAttributeName);
                }
            });
        }
    };
};

const _moduleExport = {
    /**
     * @param {NgModule} ngModule
     * @param {Array<{key: string, value: string}>} directives
     * @return {DirectiveAttributeFactory}
     */
    create: function (ngModule, directives) {
        var factory = new DirectiveAttributeFactory();
        factory.createDirectives(ngModule, directives);
        return factory;
    }
};
export { _moduleExport as directiveAttributeFactory };
