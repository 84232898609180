import { angularAMD } from "@pebblepad/amd";
import { STATUS_CONSTANTS } from "../../constants/status.constants";
import "../../dropdown/dropdown";
import "../services/iWantToService";
import "../services/iWantToHandler";
import "../../extensions/arrayExtensions";
import "../../utilities/helpers";
import "../../user/user.service";
import "../../react2angular/icon";
import template from "../templates/i-want-to.html";

/**
 * Example usage:
 * <i-want-to fetch-options for="[main_asset_id]" filter-options="['share', 'move']" extend-options="i_want_to_actions" inverted></i-want-to>
 */
angularAMD.directive("iWantTo", [
    "$rootScope",
    "$timeout",
    "iWantToService",
    "iWantToHandler",
    "helpers",
    "User",
    function ($rootScope, $timeout, iWantToService, iWantToHandler, helpers, User) {
        return {
            template: template,
            restrict: "E",
            transclude: true,
            scope: {
                fetchOptions: "@",
                filterOptions: "=",
                excludeOptions: "=",
                extendOptions: "=",
                for: "=",
                disabled: "<",
                fetchFrom: "=",
                inverted: "@",
                searchType: "@",
                assetType: "@"
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.dropdownAttachMenuEvent = "attachA11yMenu-" + $scope.$id;
                    $scope.isOpen = false;
                    $scope.iWantToOptions = [];

                    $scope.selectOption = function (option) {
                        var promise = iWantToService.doAnyAssetsHaveLinksToTheseAssets($scope.for);

                        promise.then(function (response) {
                            $scope.hasLinkedAssets = response.data;
                        });

                        promise.finally(function () {
                            $scope.$emit("executeIWantToOption", {
                                for_ids: $scope.for,
                                broadcast_name: option.broadcastName,
                                has_linked_assets: $scope.hasLinkedAssets,
                                option: option
                            });
                        });
                    };

                    $scope.postDropdownClose = function () {
                        $scope.isOpen = false;
                        $scope.resetWorkingState();
                    };

                    $scope.resetWorkingState = function () {
                        $scope.working = false;
                        $timeout.cancel($scope.workingTimeout);
                    };
                }
            ],
            link: function (scope, element) {
                scope.multiLanguageService = $rootScope.multiLanguageService;
                scope.iWantToMapStatuses = ["", STATUS_CONSTANTS.STATUS_EIGHT, STATUS_CONSTANTS.STATUS_NINE];

                scope.getOptions = function () {
                    var options = undefined;

                    if (scope.isOpen) {
                        scope.isOpen = false;
                        return;
                    }

                    scope.workingTimeout = $timeout(function () {
                        scope.working = true;
                    }, 500);
                    scope.isOpen = true;

                    //Need this after the return above to stop it saving everytime the iwantto button is opened or closed.
                    $rootScope.$broadcast("checkIsSavedFromIWantTo", {});

                    if (scope.fetchFrom !== undefined) {
                        options = scope.fetchFrom();
                    }

                    if (scope.fetchOptions === undefined || !scope.for.length) {
                        options = scope.extendOptions;
                    }

                    if (options !== undefined) {
                        scope.iWantToOptions = options;
                        scope.resetWorkingState();

                        $timeout(function () {
                            scope.$broadcast(scope.dropdownAttachMenuEvent);
                        });

                        return options;
                    } else if (helpers.isNotEmptyArray(scope.for)) {
                        scope.iWantToOptions = null;
                        // Get for/as asset
                        var deferred = iWantToService.getAvailableActions(scope.for, scope.searchType);

                        return deferred.then(function (response) {
                            if (response.data) {
                                var responseOptions = response.data;
                                var filteredOptions;

                                if (scope.filterOptions && scope.filterOptions.length) {
                                    filteredOptions = responseOptions.where(function (o, k) {
                                        return scope.filterOptions.contains(o);
                                    });
                                } else {
                                    filteredOptions = responseOptions;
                                }

                                if (scope.excludeOptions) {
                                    filteredOptions.removeItems(scope.excludeOptions);
                                }

                                scope.resetWorkingState();
                                scope.iWantToOptions = [scope.extendOptions, iWantToService.getAssetMenuItems(filteredOptions, scope.for.length, scope.assetType).filter(scope.optionAvailable)]
                                    .selectMany()
                                    .sort((a, b) => a.order - b.order);

                                if (!scope.iWantToOptions || scope.iWantToOptions.length === 0) {
                                    scope.iWantToOptions = [iWantToHandler.getEmptyOption()];
                                }
                            }

                            $timeout(function () {
                                scope.$broadcast(scope.dropdownAttachMenuEvent);
                            });
                        });
                    }
                };

                scope.optionAvailable = function (option) {
                    if (option.name.toLowerCase() === "map") {
                        const user = User.getDto();
                        return typeof user.UserStatus === "undefined" || scope.iWantToMapStatuses.includes(user.UserStatus);
                    }

                    return true;
                };
            }
        };
    }
]);
