import { FunctionComponent, memo } from "react";
import { IIconProps } from "./interfaces/IIconProps";
import "./styles/icon.css";

export const Icon: FunctionComponent<IIconProps> = memo<IIconProps>(function Icon(props: IIconProps) {
    const iconStyle = {
        fill: props.fill
    };

    return (
        // eslint-disable-next-line react/no-danger
        <span aria-hidden="true" dangerouslySetInnerHTML={{ __html: props.source.toString() }} className={`${props.className !== undefined ? props.className : ""} cui-icon-svg`} style={iconStyle} />
    );
});
