import { i18n } from "i18next";
import { II18nResourceSet } from "../i18n-set/interfaces/II18nResourceSet";
import { I18nSet } from "../i18n-set/I18nSet";
import { II18nSet } from "../i18n-set/interfaces/II18nSet";
import { II18nManager } from "./interfaces/II18nManager";

export class I18nManager implements II18nManager {
    public readonly i18nextFactory: i18n;
    private _defaultLanguage: string = "";
    private _language: string = "";
    private readonly _i18nSetMap: Map<string, II18nResourceSet<Record<any, unknown>>> = new Map();

    constructor(i18nextFactory: i18n) {
        this.i18nextFactory = i18nextFactory;
    }

    public addSet(id: string, set: II18nResourceSet<Record<any, unknown>>): this {
        this._i18nSetMap.set(id, set);
        return this;
    }

    public createSet<T extends Record<string, unknown>>(id: string, escapeValue?: boolean): II18nSet<T> {
        const i18nSet = new I18nSet<T>(this.i18nextFactory, escapeValue);

        i18nSet.setDefaultLanguage(this._defaultLanguage).setLanguage(this._language);

        this._i18nSetMap.set(id, i18nSet);
        return i18nSet;
    }

    public getLanguage(): string {
        return this._language;
    }

    public getDefaultLanguage(): string {
        return this._defaultLanguage;
    }

    public setDefaultLanguage(languageCode: string): this {
        this._defaultLanguage = languageCode;
        this._i18nSetMap.forEach((i18nSet) => i18nSet.setDefaultLanguage(languageCode));

        return this;
    }

    public setLanguage(languageCode: string): this {
        this._language = languageCode;
        this._i18nSetMap.forEach((i18nSet) => i18nSet.setLanguage(languageCode));

        return this;
    }

    public async load(languageCode?: string): Promise<void> {
        const promises: Array<Promise<void>> = [];
        this._i18nSetMap.forEach((i18nSet) => promises.push(i18nSet.load(languageCode)));
        await Promise.all(promises);
    }

    public changeLanguage(languageCode: string): Promise<void> {
        const promises: Array<Promise<void>> = [];
        this._i18nSetMap.forEach((i18nSet) => promises.push(i18nSet.changeLanguage(languageCode)));
        return Promise.all(promises)
            .then(() => {
                this._language = languageCode;
            })
            .catch((error) => {
                this.setLanguage(this._language);
                throw error;
            });
    }
}
