const smallScreenSize = 490;
let smallScreenMediaQuery: null | MediaQueryList = null;

export function getSmallScreenMediaQuery(): MediaQueryList {
    if (smallScreenMediaQuery !== null) {
        return smallScreenMediaQuery;
    }
    smallScreenMediaQuery = window.matchMedia(`(max-width: ${smallScreenSize}px), (max-height:${smallScreenSize}px)`);
    return smallScreenMediaQuery;
}
