import { MouseEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";

export function selectClickedItemAndSetClosed(_e: MouseEvent, itemIndex: number): Partial<Immutable<MenuAriaModel>> {
    return {
        activeItemIndex: itemIndex,
        isOpen: false,
        type: AriaMenuStatus.Closed
    };
}
