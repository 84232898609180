import { FunctionComponent, MutableRefObject, useId, useRef } from "react";
import { useOnMount, useOnUnmount, useOnValueChange } from "@pjs/react-utilities";
import { richTextI18n } from "../i18n/RichTextI18n.const";
import { CKEditorPlaceholder } from "../editor/CKEditorPlaceholder";
import { IEditor } from "../editor/interfaces/IEditor";
import { blockHyperlinkClick } from "../../utils/block-hyperlink-click/BlockHyperlinkClick.function";
import { IBaseEditorProps } from "./interfaces/IBaseEditorProps";
import "./styles/base-editor.css";
import "./styles/ckeditor-overrides.css";

export const BaseEditor: FunctionComponent<IBaseEditorProps> = (props) => {
    const editorContainerRef = useRef<HTMLDivElement>(null);
    const editorPlaceholderContainerRef = useRef<HTMLDivElement>(null);
    const editor: MutableRefObject<IEditor | null> = useRef(null);
    const toolbarShortcutId = useId();
    const shortcutText = richTextI18n.getString("editor.shortcutText");

    useOnMount(() => {
        if (editorContainerRef.current === null || editorPlaceholderContainerRef.current === null) {
            return;
        }

        editor.current = props.factory(editorContainerRef, props.toolbarRef, editorPlaceholderContainerRef, props.content, props.onChange, props.config, props.onToolbarDisplayChange);

        const placeholder = CKEditorPlaceholder.doesEditorHaveContent(props.content) ? "" : props.config.placeholder;
        editorPlaceholderContainerRef.current.style.setProperty("--placeholder-text", `"${placeholder}"`);
        editorContainerRef.current.addEventListener("click", (e) => blockHyperlinkClick(e, editorContainerRef));

        if (props.autoFocus) {
            editorContainerRef.current.focus();
        }
    });

    useOnUnmount(() => {
        // eslint-disable-next-line no-unused-expressions
        editor.current?.destroy();
    });

    useOnValueChange(() => {
        // eslint-disable-next-line no-unused-expressions
        editor.current?.setData(props.content);
    }, [props.content]);

    const label = props.ariaLabel !== undefined ? `${props.ariaLabel} ${shortcutText}` : undefined;
    const labelledBy = props.ariaLabelledBy !== undefined ? `${props.ariaLabelledBy} ${toolbarShortcutId}` : undefined;
    const className = `rt-base-editor__text-area util-rich-text-font-styles${props.className !== undefined ? ` ${props.className}` : ""}`;
    return (
        <div className="rt-base-editor__editor-placeholder-container" ref={editorPlaceholderContainerRef}>
            <div
                aria-label={label}
                role="textbox"
                aria-multiline={true}
                aria-labelledby={labelledBy}
                className={className}
                contentEditable={true}
                data-hook={props.dataHook}
                ref={editorContainerRef}
            />
            {labelledBy !== undefined && (
                <span className="rt-base-editor__toolbar-shortcut-text" id={toolbarShortcutId}>
                    {shortcutText}
                </span>
            )}
        </div>
    );
};
