import { Observable } from "@pjs/observables";
import { RefObject } from "react";
import { IEditor } from "../../editor/interfaces/IEditor";
import { BehaviourCleanup } from "../types/BehaviourCleanup";
import { ContentInsertionHandler } from "../insert-at-caret/ContentInsertionHandler";
import { Behaviour } from "../types/Behaviour";

export const insertAtCaretBehaviourFactory = (contentObservable: Observable<string>, trackingElements: Array<RefObject<HTMLElement>>): Behaviour => {
    return insertAtCaret.bind(null, contentObservable, trackingElements);
};

function insertAtCaret(contentObservable: Observable<string>, trackingElements: Array<RefObject<HTMLElement>>, editor: IEditor): BehaviourCleanup {
    const contentInsertionHandler = new ContentInsertionHandler(editor, contentObservable, trackingElements);
    return () => {
        contentInsertionHandler.destroy();
    };
}
