import { AttributeConverter } from "../../types/AttributeConverter";
import { isFontWeightBold } from "../../../../utils/is-font-weight-bold/IsFontWeightBold.function";
import { semanticInlineSwap } from "../../../../utils/semantic-inline-tag-swap/SemanticInlineSwap.function";

export const inlineFontWeightConverter: AttributeConverter = (sandboxedElement, sandboxedDocument) => {
    const fontWeight = sandboxedElement.style.fontWeight;
    if (fontWeight === "") {
        return;
    }

    sandboxedElement.style.fontWeight = "";
    if (!isFontWeightBold(fontWeight)) {
        return;
    }

    semanticInlineSwap("STRONG", sandboxedElement, sandboxedDocument, "B");
};
