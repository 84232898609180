import { angularAMD } from "@pebblepad/amd";
import "../../ckEditorModule/pebbleCkEditor";
import "../../utilities/baseUrlsFactory";
import "../../multiLanguageService/multiLanguageService";
import "./components/feedbackStatements.component";
import "../../modal/services/modal";
import "../../feedbackStatements/statementCollectionCache.service";

CkEditorFeedbackStatementsPlugin.$inject = [
    "$routeParams",
    "$timeout",
    "$rootScope",
    "$compile",
    "CkEditorService",
    "$rootScope",
    "baseUrlsFactory",
    "$q",
    "multiLanguageService",
    "modal",
    "statementCollectionCache"
];

function CkEditorFeedbackStatementsPlugin($routeParams, $timeout, $rootScope, $compile, ckEditorService, rootScope, baseUrlsFactory, $q, multiLanguageService, modal, statementCollectionCache) {
    this.services = {
        $routeParams: $routeParams,
        $timeout: $timeout,
        $rootScope: $rootScope,
        $compile: $compile,
        rootScope: rootScope,
        baseUrlsFactory: baseUrlsFactory,
        multiLanguageService: multiLanguageService,
        ckEditor: ckEditorService,
        $q: $q,
        modal: modal,
        statementCollectionCache: statementCollectionCache
    };

    this.services.ckEditor.plugins.add("feedbackStatements", {
        ricon: "feedbackstatements",
        hidpi: true,
        init: this._initFeedbackStatementsPlugin.bind(this)
    });
}
CkEditorFeedbackStatementsPlugin.prototype._commandName = "feedbackStatement";
CkEditorFeedbackStatementsPlugin.prototype._initFeedbackStatementsPlugin = function (editor) {
    editor.addCommand(this._commandName, new FeedbackStatementsCommand(editor, this.services));
    editor.setKeystroke(this.services.ckEditor.ALT + 70 /*F*/, this._commandName);
    editor.ui.addButton("FeedbackStatement", {
        label: this.services.multiLanguageService.getString("atlas_sidebar.feedback_statements"),
        command: this._commandName
    });
};

//Link Class
//=============================================================================================================================================
function FeedbackStatementsCommand(editor, services) {
    this._editor = editor;
    this._services = services;
    this._open = false;
    this._element = null;
    this._elementScope = null;
}

FeedbackStatementsCommand.prototype.exec = function () {
    if (!this._open) {
        this._open = true;
        this.setState(CKEDITOR.TRISTATE_ON);
        this._elementScope = this._services.$rootScope.$new(true);
        this._elementScope.collections = [];
        this._elementScope.ready = false;
        this._elementScope.handleSelect = this._handleFeedbackSelect.bind(this);
        this._element = this._services.$compile('<feedback-statements handle-select="handleSelect(event)" collections="collections" ready="ready"></feedback-statements>')(this._elementScope);
        // tslint:disable-next-line:no-unsafe-jq-lite
        angular.element(this._editor.container.$.parentElement).prepend(this._element);
        const submissionId = Number(this._services.$routeParams.submissionId);

        const subscriptionResponseHandlers = {
            next: (statements) => this._processStatements(statements),
            error: () => this._processStatements([])
        };

        this._services.statementCollectionCache.getStatements(submissionId).subscribe(subscriptionResponseHandlers);
    } else {
        this.setState(CKEDITOR.TRISTATE_OFF);
        this._open = false;
        this._destroyStatements();
    }
};

FeedbackStatementsCommand.prototype._processStatements = function (statements) {
    this._services.$rootScope.$applyAsync(() => {
        if (statements.length === 0) {
            this._destroyStatements();
            this._showFailureModal();
        } else {
            this._elementScope.collections = statements;
            this._elementScope.ready = true;
        }
    });
};

FeedbackStatementsCommand.prototype._showFailureModal = function () {
    var modalScope = this._services.$rootScope.$new(true);
    modalScope.title = this._services.multiLanguageService.getString("atlas_sidebar.feedback_statements");
    modalScope.message = this._services.multiLanguageService.getString("atlas_sidebar.no_statements_available");
    this._services.modal.newModal({
        templateUrl: this._services.baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up.lazy.html",
        scope: modalScope
    });
    modalScope.onClose = function () {
        modalScope.$destroy();
    };
};
FeedbackStatementsCommand.prototype._destroyStatements = function () {
    this._element.remove();
    this._elementScope.$destroy();
};
FeedbackStatementsCommand.prototype._handleFeedbackSelect = function (event) {
    this._editor.insertHtml(event.target.innerHTML + "<br>");
    event.target.focus();
};
angularAMD.service("ckEditorFeedbackStatementPluginService", CkEditorFeedbackStatementsPlugin);

export { CkEditorFeedbackStatementsPlugin as CkEditorFeedbackStatementPluginService };
