import { angularAMD } from "@pebblepad/amd";
import { informationNotice, successNotice } from "@pjs/core-ui";
import "../featureSettings/FeatureSettings";

export class CapabilityCriteriaService {
    constructor(featureSettings, multiLanguageService) {
        this._featureEnabled = featureSettings.isEnabled("CapabilityEvidenceCriteria");
        this._multiLanguageService = multiLanguageService;
    }

    isCriteriaAvailable(evidenceMinimum) {
        return this._featureEnabled && evidenceMinimum > 0;
    }

    getRenderData(evidenceAmount, evidenceMinimum) {
        if (!this.isCriteriaAvailable(evidenceMinimum)) {
            return {
                ariaLabel: "",
                notice: null,
                message: ""
            };
        }

        const meetsCriteria = evidenceAmount >= evidenceMinimum;
        const message = this._multiLanguageService.getString(meetsCriteria ? "sidebar.capability_criteria.meets_criteria" : "sidebar.capability_criteria.does_not_meet_criteria", {
            count: evidenceMinimum
        });

        const evidenceText = this._multiLanguageService.getString("accessibility.notice.type.evidence");
        const ariaLabel = this._multiLanguageService.getString(`accessibility.notice.labels.${meetsCriteria ? "success" : "information"}`, { type: evidenceText });

        return {
            ariaLabel: ariaLabel,
            notice: meetsCriteria ? successNotice : informationNotice,
            message: message
        };
    }

    getBlockIsComplete(markedAsComplete, evidenceAmount, evidenceMinimum) {
        if (!this.isCriteriaAvailable(evidenceMinimum)) {
            return markedAsComplete;
        }

        return evidenceAmount >= evidenceMinimum;
    }
}

CapabilityCriteriaService.$inject = ["featureSettings", "multiLanguageService"];
angularAMD.service("capabilityCriteriaService", CapabilityCriteriaService);
