import { createRef, PureComponent, ReactNode, RefObject } from "react";
import { ParseHtmlSanitiser } from "../parse-html/ParseHtmlSanitiser";
import { IParseHtmlSanitiser } from "../parse-html/interfaces/IParseHtmlSanitiser";
import { IParseInlineHtmlProps } from "./interfaces/IParseInlineHtmlProps";

export class ParseInlineHtml extends PureComponent<IParseInlineHtmlProps> {
    private readonly _parseHtmlSanitiser: IParseHtmlSanitiser = new ParseHtmlSanitiser(this.props.debounceTime ?? 0);
    private readonly _elementRef: RefObject<HTMLSpanElement> = createRef<HTMLSpanElement>();

    public render(): ReactNode {
        return <span className={this.props.className} ref={this._elementRef} data-hook={this.props.dataHook} />;
    }

    public componentDidMount(): void {
        this._parseHtmlSanitiser.sanitise(this.props.htmlString, this._elementRef, true);
    }

    public componentDidUpdate(): void {
        this._parseHtmlSanitiser.sanitise(this.props.htmlString, this._elementRef, false);
    }

    public componentWillUnmount(): void {
        this._parseHtmlSanitiser.destroy();
    }
}
