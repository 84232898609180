import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../../constants/assessment.constants";
import "../../multiLanguageService/multiLanguageService";
import "../../spaMenu/assetInfoPanel/assetFeedback/assetFeedbackService";
import "../../feedbackInput/directives/FeedbackInputHelper";
import "../../spaMenu/assetInfoPanel/assetComments/commentsService";
import "../../reply/reply";
import template from "./reply-wrapper.html";

angularAMD.directive("replyWrapper", ReplyWrapper);
ReplyWrapper.$inject = [];

angularAMD.controller("ReplyWrapperController", ReplyWrapperController);
ReplyWrapperController.$inject = ["$q", "$timeout", "$filter", "multiLanguageService", "FeedbackInputHelper", "assetFeedbackService", "commentsService"];

function ReplyWrapper() {
    return {
        restrict: "E",
        template: template,
        scope: {
            data: "<",
            convertDates: "@",
            anchorId: "=anchorId",
            submissionId: "<",
            onReply: "&", //Expects to be called with {body: String, author: String}
            onCommentUpdate: "&", //Expects to be called with {dto: Object}
            onCommentDelete: "&", //Expects to be called with {dto: Object}
            onStateChange: "&", //{isEditing: Boolean}
            summaryAsset: "=",
            itemIndex: "<",
            itemCount: "<",
            itemType: "<",
            itemGroup: "<?",
            refreshCommentData: "&?",
            isDisabled: "<?",
            disabledToolTip: "<?",
            expandReplies: "<?"
        },
        bindToController: true, //binds directive attributes ($scope values above) directly to the Controller (this.)
        controller: "ReplyWrapperController",
        controllerAs: "vm",

        link: function (scope, element, attrs) {
            scope.vm.replyBtnId = "reply-btn-" + scope.$id;
        }
    };
}

function ReplyWrapperController($q, $timeout, $filter, multiLanguageService, FeedbackInputHelper, assetFeedbackService, commentsService) {
    this.services = {
        $q: $q,
        $timeout: $timeout,
        $filter: $filter,
        multiLanguageService: multiLanguageService,
        FeedbackInputHelper: FeedbackInputHelper,
        assetFeedbackService: assetFeedbackService,
        commentsService: commentsService
    };

    this.editorLabel = "";
    this.addingComment = false;
    this.hideReplyOption = false;

    this.hideRepliesAfter = ASSESSMENT_CONSTANTS.FEEDBACK_REPLIES.DEFAULT_SHOW_AMOUNT;
    this.hideReplies = this.data.Replies.length > this.hideRepliesAfter;
    this.beginRepliesFrom = this.data.Replies.length - this.hideRepliesAfter;
    this.showEarlierRepliesBtn = this.data.Replies.length > this.hideRepliesAfter;
    this.viewEarlierRepliesBtnText = this.services.multiLanguageService.getString("sidebar.asset_feedback.buttons.view_earlier_replies", {
        count: this.beginRepliesFrom
    });
    this.viewEarlierRepliesBtnAria = this.services.multiLanguageService.getString("sidebar.asset_feedback.aria.view_earlier_replies_aria", {
        count: this.beginRepliesFrom
    });

    this.disabledReplyToolTip = this.services.multiLanguageService.getString("sidebar.asset_feedback.messages.edit_in_progress_reply");
    this.isFeedback = !!this.data.FeedbackType;
    this.setLabels();
}

ReplyWrapperController.prototype.$onChanges = function (changes) {
    if (changes.expandReplies !== undefined && changes.expandReplies.currentValue) {
        this.updateCounts();
    }

    if (changes.itemIndex !== undefined || changes.itemCount !== undefined || changes.itemGroup !== undefined) {
        this.setLabels();
    }
};

ReplyWrapperController.prototype.setLabels = function () {
    this.editorLabel = this.services.multiLanguageService.getString(this.isFeedback ? "sidebar.asset_feedback.aria.feedback_reply_editor" : "labels.form_element_toolbar.a11y.comment_reply_editor");

    this.a11yReplyButtonText = this.isFeedback
        ? this.services.multiLanguageService.getString("sidebar.asset_comments.reply_to_feedback_n_of_n_in_the_group", {
              number: this.itemIndex,
              total: this.itemCount,
              groupValue: this.itemGroup
          })
        : this.services.multiLanguageService.getString("sidebar.asset_comments.post_a_reply_to_n", { number: this.itemIndex });

    this.a11yCancelText = this.isFeedback
        ? this.services.multiLanguageService.getString("sidebar.asset_feedback.aria.cancel_reply_to")
        : this.services.multiLanguageService.getString("sidebar.asset_comments.cancel_reply_to", { number: this.itemIndex });

    this.a11ySubmitText = this.isFeedback
        ? this.services.multiLanguageService.getString("sidebar.asset_feedback.aria.post_a_reply_to")
        : this.services.multiLanguageService.getString("sidebar.asset_comments.post_a_reply_to_n", { number: this.itemIndex });
};

ReplyWrapperController.prototype.updateCounts = function () {
    this.hideRepliesAfter = this.expandReplies === true ? this.data.Replies.length : ASSESSMENT_CONSTANTS.FEEDBACK_REPLIES.DEFAULT_SHOW_AMOUNT;
    this.hideReplies = this.data.Replies.length > this.hideRepliesAfter;
    this.beginRepliesFrom = this.data.Replies.length - this.hideRepliesAfter;
    this.showEarlierRepliesBtn = this.data.Replies.length > this.hideRepliesAfter;

    this.viewEarlierRepliesBtnText = this.services.multiLanguageService.getString("sidebar.asset_feedback.buttons.view_earlier_replies", {
        count: this.beginRepliesFrom
    });
    this.viewEarlierRepliesBtnAria = this.services.multiLanguageService.getString("sidebar.asset_feedback.aria.view_earlier_replies_aria", {
        count: this.beginRepliesFrom
    });
};

ReplyWrapperController.prototype.$onDestroy = function () {
    this.services.$timeout.cancel(this.focusTimer);
    if (this.addingComment) {
        this.onStateChange({ isEditing: false });
    }
};

ReplyWrapperController.prototype.getRegionAriaLabel = function (number, total, mainCommentNumber, name, dateTime) {
    const time = this.services.$filter("pebbleDate")(dateTime, "timeOnly");
    const date = this.services.$filter("pebbleDate")(dateTime);

    number = this.getReplyIndex(number, total);

    if (this.isFeedback) {
        return this.services.multiLanguageService.getString("sidebar.asset_feedback.aria.reply_n_of_total_on_feedback_made_by_name_at_on", {
            number: number,
            total: total,
            name: name,
            time: time,
            date: date
        });
    } else {
        return this.services.multiLanguageService.getString("sidebar.asset_comments.reply_n_of_total_on_comment_made_by_name_at_on", {
            number: number,
            total: total,
            mainCommentNumber: mainCommentNumber,
            name: name,
            time: time,
            date: date
        });
    }
};

ReplyWrapperController.prototype.getReplyIndex = function (number, total) {
    let returnNumber = number;

    if (this.hideReplies) {
        returnNumber = total - this.hideRepliesAfter + number;
    }

    return returnNumber + 1;
};

ReplyWrapperController.prototype.onCommentAdd = function (data) {
    return this.onReply({
        body: data.body,
        author: data.author
    })
        .then((response) => {
            let dto; // multiple return types depending on from plus/atlas and whether feedback/comments
            if (response.data !== undefined && response.data.Data !== undefined) {
                dto = response.data.Data[0];
            } else {
                dto = response.data !== undefined ? response.data : response;
            }
            this.addNewChild(dto);
            this.updateCounts();
            this.refocus();
        })
        .catch(() => {
            this.services.FeedbackInputHelper.launchInvalidModal(this.services.multiLanguageService.getString("sidebar.asset_comments.unable_to_save_reply"));
            this.toggleReply(false);
        });
};

ReplyWrapperController.prototype.onCommentEdit = function (dto) {
    return this.onCommentUpdate({
        dto: dto
    });
};

ReplyWrapperController.prototype.onCommentRemove = function (dto) {
    return this.onCommentDelete({
        dto: dto
    })
        .then((response) => {
            // if response.data is null it means it was properly deleted, pass the dto so the next method has the id to delete with
            return this.updateDeletedReply(response.data ? response.data : dto).then(() => {
                this.updateCounts();
            });
        })
        .catch(() => {
            this.services.FeedbackInputHelper.launchInvalidModal(this.services.multiLanguageService.getString("sidebar.asset_comments.unable_to_delete_reply"));
        });
};

ReplyWrapperController.prototype.updateDeletedReply = function (deletedDto) {
    // if it is the last item in the array when remove it completely, otherwise update it text to say it has been deleted...
    if (!this.data.Replies || this.data.Replies.length === 0) {
        return this.services.$q.when();
    }

    const lastIndex = this.data.Replies.length - 1;
    if (this.data.Replies[lastIndex].Id === deletedDto.Id) {
        const previousIndex = lastIndex - 1;

        // update the item before the deleted item (if there is one)
        if (previousIndex >= 0) {
            let promise = null;
            if (this.data.CommentType === "Normal") {
                // could either be a feedback comment or a normal comment
                promise = this.services.commentsService.getComment(this.data.Replies[previousIndex].AssetId, this.data.Replies[previousIndex].Id, this.submissionId);
            } else {
                promise = this.services.assetFeedbackService.getReplyToFeedback(this.data.AssetId, this.data.Replies[previousIndex].Id, this.data.Id);
            }

            return promise.then((response) => {
                if (response.data) {
                    this.data.Replies[previousIndex].Permissions = response.data.Permissions;
                }

                this.data.Replies.splice(lastIndex, 1);
            });
        } else {
            this.data.Replies.splice(lastIndex, 1);
            this._updateMainComment();
        }
    } else {
        const commentToUpdate = this.data.Replies.find(function (comment) {
            return comment.Id === deletedDto.Id;
        });
        if (commentToUpdate !== undefined) {
            commentToUpdate.Permissions.AllowDelete = false;
            commentToUpdate.Comment = this.services.multiLanguageService.getString("sidebar.asset_feedback.this_has_been_deleted");
        }
    }

    return this.services.$q.when();
};

ReplyWrapperController.prototype._updateMainComment = function () {
    if (typeof this.refreshCommentData !== "undefined") {
        this.services.commentsService.getComment(this.data.AssetId, this.data.Id, this.submissionId).then((response) => {
            if (response.data !== undefined) {
                this.refreshCommentData({ data: response.data });
            }
        });
    }
};

ReplyWrapperController.prototype.addNewChild = function (dto) {
    //Only allowed to edit the last comment / feedback. When adding a new one, we have to change the state of the previous item.
    const lastItem = this.data.Replies[this.data.Replies.length - 1];

    if (lastItem !== void 0) {
        const updatedLastItem = angular.copy(lastItem);
        updatedLastItem.Permissions.AllowEdit = false;
        this.data.Replies[this.data.Replies.length - 1] = updatedLastItem;
    }

    if (!this.showEarlierRepliesBtn) {
        this.beginRepliesFrom = 0;
    }

    //Add newly created Dto to the Data.
    this.data.Replies.push(dto);
    if (this.data.Replies.length === 1) {
        this._updateMainComment();
    }
};

ReplyWrapperController.prototype.toggleReplyOption = function (anotherEditorActive) {
    //If a reply component indicates it is currently active, then hide the reply option.
    this.hideReplyOption = anotherEditorActive;
    this.onStateChange({
        isEditing: anotherEditorActive
    });
};

ReplyWrapperController.prototype.toggleReply = function (active) {
    //Set the addingComment state. addingComment is used in the view to control a CSS class which hides edit buttons for reply components (performance solution / hack)
    this.addingComment = active;
    this.onStateChange({
        isEditing: active
    });

    this.refocus();
};

ReplyWrapperController.prototype.refocus = function () {
    this.focusTimer = this.services.$timeout(() => {
        document.getElementById(this.replyBtnId).focus();
    });
};

ReplyWrapperController.prototype.viewEarlierReplies = function () {
    this.hideReplies = !this.hideReplies;
    if (this.hideReplies) {
        this.beginRepliesFrom = this.data.Replies.length - this.hideRepliesAfter;
    } else {
        this.beginRepliesFrom = 0;
    }
};
