export const itemSelectorFilters = {
    all: {
        label: "search.filters.all",
        fullLabel: "search.filters.all_full_label",
        criteria: null,
        resultsDescription: "search.filters.all_results_description"
    },
    title: {
        criteria: [{ weight: 1, getProperty: (item) => item.Title }],
        fullLabel: "search.filters.title_full_label",
        label: "search.filters.titles",
        resultsDescription: "search.filters.title_results_description"
    },
    tag: {
        criteria: [{ weight: 1, getProperty: (item) => item.Tags ?? [] }],
        fullLabel: "search.filters.tag_full_label",
        label: "search.filters.tags",
        resultsDescription: "search.filters.tag_results_description"
    }
};

itemSelectorFilters.all.criteria = [itemSelectorFilters.title.criteria[0], itemSelectorFilters.tag.criteria[0]];
