//Module / Service / Factory definitions
//=============================================================================================================
const ckEditor = window.CKEDITOR;
var ckEditorModule = angular.module("pebbleCkEditor", ["ng"]);
//Replacement for CKEDITOR Global
ckEditorModule.provider("CkEditorService", CkEditorServiceProvider);
//Helper for creating editor instances
ckEditorModule.service("CkEditorBuilder", CkEditorBuilder);
CkEditorBuilder.$inject = ["CkEditorService"];

ckEditorModule.service("CkEditorSelectionHelper", CkEditorSelectionHelper);
CkEditorSelectionHelper.$inject = ["CkEditorService"];

ckEditorModule.constant("CKEDITOR_EVENTS", {
    BLUR: "blur",
    CHANGE: "change",
    DESTROY: "destroy",
    FOCUS: "focus",
    LOADED: "loaded",
    PASTE: "paste",
    READY: "instanceReady"
});

//Module Config
//=============================================================================================================
ckEditorModule.config([
    "CkEditorServiceProvider",
    function (CkEditorServiceProvider) {
        CkEditorServiceProvider.setDefaults();
    }
]);

//CkEditor Service
//=============================================================================================================
function CkEditorServiceProvider() {
    this.ckEditor = ckEditor;
    this.$get = function () {
        return this.ckEditor;
    };
}

CkEditorServiceProvider.prototype.setDefaults = function () {
    this.ckEditor.config.customConfig = false; //no config.js
    this.ckEditor.config.stylesSet = false; //no styles.js
    this.ckEditor.disableAutoInline = true;
    this.ckEditor.config.autoParagraph = false; // DEPRECATED OPTION - but it fixes the extra line breaks being added
    this.ckEditor.config.indentOffset = 20;
    this.ckEditor.config.indentUnit = "px";

    //Language
    this.ckEditor.config.defaultLanguage = "en-gb";
    this.ckEditor.config.language = "en-gb";

    //Toolbar options
    this.ckEditor.config.toolbarGroups = [
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "linker", groups: ["linker"] },
        { name: "insert", groups: ["insert"] },
        { name: "tools", groups: ["tools"] },
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "others", groups: ["others"] },
        { name: "styles", groups: ["styles"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "colors", "bidi", "paragraph"] },
        { name: "colors", groups: ["colors"] }
    ];
    this.ckEditor.config.removeButtons = "Format,Subscript,Superscript,Table,Image,Maximize,Source,About,Blockquote,Anchor,SpecialChar,HorizontalRule,PasteFromWord,PasteText,Paste";
    this.ckEditor.config.disableNativeSpellChecker = false;

    //Fonts
    this.ckEditor.config.font_names =
        "Open Sans/Open Sans;" +
        "Arial/Arial, Helvetica, sans-serif;" +
        "Arial Black/Arial Black, Gadget, sans-serif;" +
        "Comic Sans MS/Comic Sans MS, cursive;" +
        "Courier New/Courier New, Courier, monospace;" +
        "Georgia/Georgia, serif;" +
        "Impact/Impact, Charcoal, sans-serif;" +
        "Lucida/Lucida Sans Unicode, Lucida Grande, sans-serif;" +
        "Palatino/Palatino Linotype, Book Antiqua, Palatino, URW Palladio L, serif;" +
        "PT Serif/PT Serif, PT Serif, sans-serif;" +
        "Tahoma/Tahoma, Geneva, sans-serif;" +
        "Times New Roman/Times New Roman, Times, serif;" +
        "Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;" +
        "Verdana/Verdana, DejaVu Sans, Geneva, sans-serif;";
};

//CkEditor Builder
//=============================================================================================================
function CkEditorBuilder(ckEditorService) {
    this.ckEditor = ckEditorService;
}

CkEditorBuilder.prototype.createInlineEditor = function (element, config) {
    var inlineEditor = this.ckEditor.inline(element, config);
    return inlineEditor;
};

function CkEditorSelectionHelper(ckEditorService) {
    this.ckEditor = ckEditorService;
}

/**
 * @param {Object} editor - CkEditor instance
 * @param {{start: Object|null, end: Object|null}} bookmark
 */
CkEditorSelectionHelper.prototype.restoreSelectionFromNativeBookmarks = function (editor, bookmark) {
    var startElement = editor.element.findOne(bookmark.start.selector);
    var selection = editor.getSelection();

    selection.removeAllRanges();

    if (bookmark.start === bookmark.end) {
        selection.selectElement(editor.element.findOne(bookmark.start.selector));
        startElement.remove(false);
        selection.getRanges()[0].collapse(true);
    } else {
        var endElement = editor.element.findOne(bookmark.end.selector);
        var range = editor.createRange();
        range.setStartBefore(startElement);
        range.setEndBefore(endElement);
        selection.selectRanges([range]);
        startElement.remove(false);
        endElement.remove(false);
    }

    editor.selectionChange(true);
};

export { ckEditorModule };
