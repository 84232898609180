import { ChangeEvent, Component, ReactElement } from "react";
import { createGuid } from "@pjs/utilities";
import { tickIcon } from "../icon/icons/Tick.icon";
import { Icon } from "../icon/Icon.component";

import { ISwitchProps } from "./interfaces/ISwitchProps";

import "./styles/switch.css";

export class Switch extends Component<ISwitchProps> {
    public readonly onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
    private readonly _id: string = createGuid();

    constructor(props: ISwitchProps) {
        super(props);
        this.onChangeHandler = this._handleCheckedChange.bind(this);
    }

    public render(): ReactElement {
        return (
            <label className="cui-switch__label" htmlFor={this._id} data-hook="switch-label">
                <input
                    className="cui-switch__input"
                    type="checkbox"
                    id={this._id}
                    data-hook="switch-input"
                    checked={this.props.isChecked}
                    disabled={this.props.isDisabled}
                    onChange={this.onChangeHandler}
                />
                <div className="cui-switch">
                    <div className="cui-switch__rail">
                        <div className="cui-switch_thumb">
                            <Icon source={tickIcon} className="cui-switch_thumb-icon" />
                        </div>
                    </div>
                </div>
                <span className="cui-switch__label-text" data-hook="switch-label-text">
                    {this.props.label}
                </span>
            </label>
        );
    }

    private _handleCheckedChange(event: ChangeEvent<HTMLInputElement>): void {
        this.props.onChange(event.target.checked);
    }
}
