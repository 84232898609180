import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./standards-rating-info.html";
import "../../../formComponents/ElementInfoButton/elementInfoButton";

class StandardsRatingInfo extends NgComponent {
    constructor() {
        super();
    }
}

export const standardsRatingInfo = {
    template: template,
    controller: StandardsRatingInfo,
    bindings: {
        standard: "<",
        showText: "<?",
        text: "@?"
    }
};

StandardsRatingInfo.$inject = [];
angularAMD.component("standardsRatingInfo", standardsRatingInfo);
