import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../constants/asset.constants";
import template from "./feedback-filters.html";
import "./filterCheckbox/filterCheckbox.component";
import "../multiLanguageService/multiLanguageService";

class FeedbackFiltersComponent extends NgComponent {
    constructor($routeParams, $rootScope, multiLanguageService) {
        super();

        this._$routeParams = $routeParams;
        this._$rootScope = $rootScope;
        this.multiLanguageService = multiLanguageService;
        this._currentPageAssetId = null;
        this._currentPageTitle = "";
        this._resetDefaultCheckboxFilters();

        this._routeFilters = {
            pageId: {
                set: () => {
                    this._getPageInfo();
                    const isPageChecked = this.checkboxFilters.some((checkboxFilter) => checkboxFilter.filter === "leftOn" && checkboxFilter.checked);
                    if (isPageChecked) {
                        this.filterer.updateActiveFilter({ leftOn: this._currentPageAssetId });
                    }
                }
            }
        };

        this._pageChangeEvent = null;
    }

    $onInit() {
        this._pageChangeEvent = this._$rootScope.$on("$locationChangeSuccess", () => {
            for (const key in this._$routeParams) {
                const filter = this._routeFilters[key];

                if (filter !== undefined) {
                    filter.set();
                }
            }
        });
        this._getPageInfo();
        this._onFilterUpdate();
    }

    $onChanges(changes) {
        if (changes.activeFiltersList !== undefined && !changes.activeFiltersList.isFirstChange()) {
            this._onFilterUpdate();
        }
    }

    $onDestroy() {
        if (this._pageChangeEvent !== null) {
            this._pageChangeEvent();
        }
    }

    updateFilter(newFilter, active) {
        if (newFilter === "leftOn") {
            this.filterer.updateActiveFilter({ leftOn: active ? this._currentPageAssetId : undefined });
        } else if (newFilter === "showMostRecentPerAssessorField") {
            this.filterer.updateActiveFilter({
                showMostRecentPerAssessorField: active
            });
        }
    }

    _onFilterUpdate() {
        for (const checkboxFilter of this.checkboxFilters) {
            checkboxFilter.checked = this.activeFiltersList.includes(checkboxFilter.filter);
        }

        this._setActiveFilterText();
    }

    reset() {
        this.filterer.resetFilter();
    }

    _resetDefaultCheckboxFilters() {
        this.checkboxFilters = [
            {
                label: this._currentPageTitle,
                filter: "leftOn",
                checked: false,
                dataHook: "feedback-filter-left-on-page"
            },
            {
                label: this.multiLanguageService.getString("sidebar.asset_feedback.filter_options.include_assessor_fields"),
                filter: "showMostRecentPerAssessorField",
                checked: false,
                dataHook: "feedback-filter-include-assessor-fields"
            }
        ];
    }

    _setActiveFilterText() {
        const activeFilterCount = this.activeFiltersList.length;
        if (activeFilterCount === 0) {
            this.activeFilterText = this.multiLanguageService.getString("sidebar.asset_feedback.filter_options.filter_by");
        } else {
            this.activeFilterText = this.multiLanguageService.getString("sidebar.asset_feedback.filter_options.filters_applied", { count: activeFilterCount });
        }
    }

    _getPageInfo() {
        if (this.assetManager !== undefined && this.assetManager !== null) {
            const pageId = this._$routeParams.pageId !== undefined ? this._$routeParams.pageId : null;
            let title = "";

            const assetTypesWithPages = [ASSET_CONSTANTS.TYPES.WEBFOLIO, ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE, ASSET_CONSTANTS.TYPES.WORKBOOK];
            if (assetTypesWithPages.includes(this.assetManager.data.MainType)) {
                if (pageId !== null) {
                    const currentPage = this.assetManager.get(pageId, false);
                    if (currentPage !== null) {
                        this._currentPageAssetId = currentPage.UserResponseId !== undefined ? currentPage.UserResponseId : currentPage.PageAssetId;
                        title = currentPage.PageTitle;
                    }
                }
            } else {
                this._currentPageAssetId = this.assetManager.AssetId;
                title = this.assetManager.data.Title;
            }

            this._currentPageTitle = title;
            const pageCheckBoxFilter = this.checkboxFilters.find((checkboxFilters) => checkboxFilters.filter === "leftOn");
            pageCheckBoxFilter.label = this.multiLanguageService.getString("sidebar.asset_feedback.filter_options.left_on_page", { title: title });
        }
    }
}

const FeedbackFilters = {
    bindings: {
        assetManager: "<",
        activeFiltersList: "<",
        filterer: "<"
    },
    template: template,
    controller: FeedbackFiltersComponent
};

FeedbackFilters.$inject = ["$routeParams", "$rootScope", "multiLanguageService"];
angularAMD.component("feedbackFilters", FeedbackFilters);
export { FeedbackFilters as feedbackFilters };
