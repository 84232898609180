import { Context, ChangeEvent, Component, RefObject, KeyboardEvent, createRef } from "react";
import { createGuid, isAndroid, isIphoneLike } from "@pjs/utilities";
import { hideWhenClosed } from "../../utils/hide-when-closed/hideWhenClosed";
import { Icon } from "../icon/Icon.component";
import { chevronIcon } from "../icon/icons/Chevron.icon";
import { AriaMenuStatus } from "../aria-roles/menus/menu-event-adapter/enums/AriaMenuStatus";
import { IMenuEventModelHandlers } from "../aria-roles/menus/menu-event-adapter/interfaces/IMenuEventModelHandlers";
import { IBoundaryContext } from "../boundary/interfaces/IBoundaryContext";
import { BoundaryContext } from "../boundary/Boundary.context";
import { FloatingMenu } from "../aria-roles/menus/floating-menu/FloatingMenu";
import { floatingListBoxFactory } from "../aria-roles/menus/floating-menu/factories/FloatingListBoxFactory";
import { IMenuItem } from "../aria-roles/menus/types/IMenuItem";
import { MenuAriaModel } from "../aria-roles/menus/menu-event-adapter/types/MenuAriaModel";
import { getSmallScreenMediaQuery } from "./getSmallScreenMediaQuery.function";
import { ISelectProps } from "./interfaces/ISelectProps.interface";
import "./styles/select.css";

export class Select<TData extends IMenuItem> extends Component<ISelectProps<TData>> {
    public static contextType: Context<IBoundaryContext> = BoundaryContext;

    private readonly _buttonReference: RefObject<HTMLButtonElement> = createRef<HTMLButtonElement>();
    private readonly _listReference: RefObject<HTMLUListElement> = createRef<HTMLUListElement>();
    private readonly _selectDefaultId: string = createGuid();
    private readonly _listId: string = createGuid();
    private readonly _onNativeSelectChange: OmitThisParameter<(event: ChangeEvent<HTMLSelectElement>) => void>;
    private readonly _floatingMenu: FloatingMenu<MenuAriaModel, IMenuEventModelHandlers<TData>>;

    constructor(props: ISelectProps<TData>, context: IBoundaryContext) {
        super(props);

        this._onNativeSelectChange = this._handleNativeSelectChange.bind(this);

        this._floatingMenu = floatingListBoxFactory({
            boundaryRef: context.element,
            floatingRef: this._listReference,
            initialModel: this.props.model,
            matchItemOnKeys: (item: TData, currentKeys: string) => item.value.toLowerCase().startsWith(currentKeys),
            onModelChange: this.props.onModelChange,
            triggerRef: this._buttonReference
        });

        this._floatingMenu.addReaction(AriaMenuStatus.ClosedByKeyboardEvent, () => {
            if (this._buttonReference.current !== null) {
                this._buttonReference.current.focus();
            }
        });
    }

    public componentDidMount(): void {
        this._floatingMenu.updateModel(this.props.model);
    }

    public componentDidUpdate(prevProps: ISelectProps<TData>): void {
        if (prevProps.model !== this.props.model) {
            this._floatingMenu.updateModel(this.props.model);
        }
    }

    public componentWillUnmount(): void {
        this._floatingMenu.destroy();
    }

    public render(): JSX.Element {
        const selectId = this.props.id !== undefined ? this.props.id : this._selectDefaultId;
        if ((isAndroid && getSmallScreenMediaQuery().matches) || isIphoneLike) {
            return (
                <div className="cui-select">
                    <div className="cui-select__button-wrapper">
                        <select
                            id={selectId}
                            className="cui-select__native-select"
                            autoFocus={this.props.autoFocus}
                            aria-labelledby={this.props.menuTriggerLabelId ?? `${this.props.labelId} ${selectId}`}
                            value={this.props.dataSet[this.props.model.activeItemIndex].value}
                            onChange={this._onNativeSelectChange}>
                            {this.props.dataSet.map((item) => {
                                return (
                                    <option value={item.value} key={item.value}>
                                        {item.value}
                                    </option>
                                );
                            })}
                        </select>
                        <button className="cui-select__button" aria-hidden={true} tabIndex={-1}>
                            <span className="cui-select__button-item">{this.props.renderOption(this.props.dataSet[this.props.model.activeItemIndex])}</span>
                            <Icon className={`cui-select__button-chevron ${this.props.model.isOpen && "cui-select__button-chevron--open"}`} source={chevronIcon} />
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="cui-select">
                <button
                    autoFocus={this.props.autoFocus}
                    className="cui-select__button"
                    ref={this._buttonReference}
                    aria-labelledby={this.props.menuTriggerLabelId ?? `${this.props.labelId} ${selectId}`}
                    id={selectId}
                    aria-expanded={this.props.model.isOpen}
                    aria-controls={this._listId}
                    aria-haspopup="listbox"
                    data-hook={this.props.dataHook ?? "select-trigger"}
                    onClick={this._floatingMenu.events.trigger.onClick}
                    onKeyDown={(e: KeyboardEvent) => this._floatingMenu.events.trigger.onKeyDown(e, this.props.dataSet)}
                    onMouseDown={this._floatingMenu.events.trigger.onMouseDown}
                    type="button">
                    <span className="cui-select__button-item">{this.props.renderOption(this.props.dataSet[this.props.model.activeItemIndex])}</span>
                    <Icon className={`cui-select__button-chevron ${this.props.model.isOpen && "cui-select__button-chevron--open"}`} source={chevronIcon} />
                </button>
                <div className="cui-select__list-container" style={hideWhenClosed(this.props.model.isOpen)} data-hook="select-list-container">
                    <ul
                        className="cui-select__list"
                        data-hook="select-list"
                        ref={this._listReference}
                        id={this._listId}
                        aria-activedescendant={`${this._listId}-${this.props.model.activeItemIndex}`}
                        aria-labelledby={this.props.labelId}
                        tabIndex={-1}
                        role="listbox"
                        onBlur={this._floatingMenu.events.target.onFocusLoss}
                        onKeyDown={(e) => {
                            this._floatingMenu.events.target.onKeyDown(e, this.props.dataSet);
                        }}>
                        {this._renderList()}
                    </ul>
                </div>
            </div>
        );
    }

    private _renderList(): Array<JSX.Element> {
        return this.props.dataSet.map((item, index) => {
            const isActive = index === this.props.model.activeItemIndex;

            return (
                <li
                    className="cui-select__list-item"
                    key={item.value}
                    id={`${this._listId}-${index}`}
                    aria-selected={isActive}
                    data-hook="select-item"
                    role="option"
                    onClick={(e) => this._floatingMenu.events.item.onClick(e, index)}>
                    {this.props.renderOption(item)}
                </li>
            );
        });
    }

    private _handleNativeSelectChange(event: ChangeEvent<HTMLSelectElement>): void {
        const itemIndex = this.props.dataSet.findIndex((item) => item.value === event.target.value);

        if (itemIndex > -1) {
            this.props.onModelChange({
                ...this.props.model,
                activeItemIndex: itemIndex
            });
        }
    }
}
