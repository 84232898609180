"use strict";
import { angularAMD } from "@pebblepad/amd";
var puiAccordionPanelComponentDefinition = {
    require: {
        _accordion: "^puiAccordion"
    },
    transclude: true,
    template: "<ng-transclude/>",
    controller: PuiAccordionPanel
};

PuiAccordionPanel.$inject = ["$scope"];

function PuiAccordionPanel($scope) {
    this._contentManager = null;
    this._headerManager = null;
    this._expanded = false;
    //TODO remove $scope.$id usage when PUI is moved to its own project repo and we can cleanly handle helpers
    this._id = $scope.$id.toString();
}

PuiAccordionPanel.prototype.$postLink = function () {
    var accordionPanelManager = new AccordionPanelManager(this.setExpanded.bind(this), this.getExpanded.bind(this), this.getButton.bind(this));
    this._accordion.setPanelManager(this._id, accordionPanelManager);
};

PuiAccordionPanel.prototype.setExpanded = function (value) {
    this._expanded = value;
    this._contentManager.setExpanded(this._expanded);
    this._headerManager.setExpanded(this._expanded);
};

PuiAccordionPanel.prototype.toggleExpanded = function () {
    return this._accordion.toggleExpanded(this._id);
};

PuiAccordionPanel.prototype.getExpanded = function () {
    return this._expanded;
};

PuiAccordionPanel.prototype.getButton = function () {
    return this._headerManager.buttonElement;
};

PuiAccordionPanel.prototype.setHeaderManager = function (headerManager) {
    this._headerManager = headerManager;
    return this._id;
};

PuiAccordionPanel.prototype.setContentManager = function (contentManager) {
    this._contentManager = contentManager;
    return this._id;
};

PuiAccordionPanel.prototype.handleClick = function () {
    this._accordion.handleClick(this._id);
};

PuiAccordionPanel.prototype.$onDestroy = function () {
    this._accordion.removePanelManager(this._id);
};

function AccordionPanelManager(setExpanded, getExpanded, getButton) {
    this.setExpanded = setExpanded;
    this.getExpanded = getExpanded;
    this.getButton = getButton;
}

if (angularAMD.default !== null) {
    angularAMD.component("puiAccordionPanel", puiAccordionPanelComponentDefinition);
}

export { puiAccordionPanelComponentDefinition };
