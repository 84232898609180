import { angularAMD } from "@pebblepad/amd";
import template from "./compact-floating-rich-text-editor.html";
import "../react2angular/compactMultiLineEditor";
import { reactEditorAdapterBindings } from "../reactEditorAdapter/ReactEditorAdapterBindings.const";
import { FloatingToolbarReactEditorAdapterController } from "../reactEditorAdapter/FloatingToolbarReactEditorAdapterController";

export const compactFloatingRichTextEditorDefinition = {
    bindings: {
        autoFocus: "<",
        ...reactEditorAdapterBindings
    },
    controller: FloatingToolbarReactEditorAdapterController,
    name: "compactFloatingRichTextEditor",
    template: template
};

angularAMD.component(compactFloatingRichTextEditorDefinition.name, compactFloatingRichTextEditorDefinition);
