import { Indent, IndentBlock } from "@pebblepad/ckeditor";
import { IndentBlockClass } from "./enums/IndentBlockClass";
import { IToolbarItem } from "./interfaces/IToolbarItem";

export const indent: IToolbarItem = {
    name: "indent",
    pluginConfig: {
        indentBlock: {
            classes: Object.values(IndentBlockClass)
        }
    },
    plugins: [Indent, IndentBlock]
};
