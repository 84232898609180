import { AsyncResolver, I18nSetNamespace, II18nResourceSet, ResourceLoader } from "@pjs/i18n";
import { ICkeI18nResource } from "@pebblepad/ckeditor";
import { noop } from "@pjs/utilities";

export class CkeI18nSet implements II18nResourceSet<Record<any, unknown>> {
    private readonly _mandatoryResource: ICkeI18nResource;
    private readonly _resourceLoader: ResourceLoader<unknown>;
    private _defaultLanguage: string;
    private _language: string = "";

    constructor(defaultLanguage: string, mandatoryResource: ICkeI18nResource) {
        this._mandatoryResource = mandatoryResource;
        this._defaultLanguage = defaultLanguage;
        this._resourceLoader = new ResourceLoader(noop);
        this._resourceLoader.add(I18nSetNamespace.String, this._mandatoryResource.languageCode, this._mandatoryResource.load);
    }

    public add(languageCode: string, resolver: AsyncResolver<unknown>): this {
        this._resourceLoader.add(I18nSetNamespace.String, languageCode, resolver);
        return this;
    }

    public getLanguage(): string {
        return this._language;
    }

    public getDefaultLanguage(): string {
        return this._defaultLanguage;
    }

    public setDefaultLanguage(languageCode: string): this {
        this._defaultLanguage = languageCode;
        return this;
    }

    public setLanguage(languageCode: string): this {
        this._language = languageCode;
        return this;
    }

    public changeLanguage(languageCode: string): Promise<void> {
        const currentLanguage = this.getLanguage();
        return this.setLanguage(languageCode)
            .load()
            .catch((error) => {
                this.setLanguage(currentLanguage);
                throw error;
            });
    }

    public async load(languageCode: string = this._language): Promise<void> {
        const loadPromises: Array<Promise<void>> = [this._resourceLoader.load(this._mandatoryResource.languageCode)];

        if (this._defaultLanguage !== languageCode) {
            loadPromises.push(this._resourceLoader.load(this._defaultLanguage));
        }

        loadPromises.push(this._resourceLoader.load(languageCode));
        await Promise.all(loadPromises);
    }
}
