import { AttributeConverter } from "../../types/AttributeConverter";
import { semanticInlineSwap } from "../../../../utils/semantic-inline-tag-swap/SemanticInlineSwap.function";

export const inlineVerticalAlignmentConverter: AttributeConverter = (sandboxedElement, sandboxedDocument) => {
    const verticalAlignment = sandboxedElement.style.verticalAlign;
    if (verticalAlignment === "") {
        return;
    }

    sandboxedElement.style.verticalAlign = "";
    if (verticalAlignment === "super") {
        semanticInlineSwap("SUP", sandboxedElement, sandboxedDocument);
        return;
    }

    if (verticalAlignment === "sub") {
        semanticInlineSwap("SUB", sandboxedElement, sandboxedDocument);
        return;
    }
};
