import { ComponentType, FunctionComponent } from "react";
import { BoundaryBridge } from "@pjs/core-ui";
import { WithLabelAndLabelledBy } from "../../types/WithLabelAndLabelledBy";
import { CompactMultiLineEditorProps } from "./types/CompactMultiLineEditorProps";
import { CompactMultiLineEditor } from "./CompactMultiLineEditor.component";

// eslint-disable-next-line @typescript-eslint/naming-convention
const EditorWithLabelAndLabelledBy = CompactMultiLineEditor as ComponentType<WithLabelAndLabelledBy<CompactMultiLineEditorProps>>;

export const CompactMultiLineEditorWithBoundary: FunctionComponent<CompactMultiLineEditorProps> = (props) => {
    return (
        <BoundaryBridge>
            <EditorWithLabelAndLabelledBy
                ariaLabel={props.ariaLabel}
                ariaLabelledBy={props.ariaLabelledBy}
                autoFocus={props.autoFocus}
                className={props.className}
                content={props.content}
                dataHook={props.dataHook}
                onChange={props.onChange}
                onToolbarDisplayChange={props.onToolbarDisplayChange}
                placeholder={props.placeholder}
                onLink={props.onLink}
            />
        </BoundaryBridge>
    );
};
