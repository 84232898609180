import { createGuid } from "@pjs/utilities";

export class ExportOptionItem {
    constructor(scope, key, label, value) {
        this._scope = scope;
        this.id = createGuid();
        this.key = key;
        this.label = label;
        this.value = value ?? false;
        this.toggle = (checked) => {
            this.value = checked;
            this._scope.$applyAsync();
        };
    }
}
