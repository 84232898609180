import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../constants/mapToStandards.constants";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import "../../../multiLanguageService/multiLanguageService";
import "../feedbackText/feedbackText.component";
import "../approvalCollectionItem/approvalCollectionItem.component";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import template from "./approval-collection.html";

class ApprovalCollectionComponent extends NgComponent {
    constructor(multiLanguageService, feedbackHelper) {
        super();

        this.multiLanguageService = multiLanguageService;
        this.feedbackHelper = feedbackHelper;
        this.ACTION_CONSTANTS = ACTION_CONSTANTS;
        this.comment = this.feedback.Comment;

        this.iconName = this._getIconName();
        this.statusText = this._getStatusText();
    }

    $onChanges(changes) {
        this.iconName = this._getIconName();
        this.statusText = this._getStatusText();
        this.comment = this.feedback.Comment;

        if (this._isEditMode(changes)) {
            this.onEdit({
                validation: this.feedbackHelper.getApprovalCollectionValidation(this.status, this.comment),
                changes: { ApprovalCollectionStatus: this.feedback.ApprovalCollectionStatus, Comment: this.comment }
            });
        }
    }

    _getIconName() {
        return this.feedback.ApprovalCollectionStatus === MAP_TO_STANDARDS_CONSTANTS.STATUS.APPROVED ? `icon-mapping-approved` : `icon-mapping-not-approved`;
    }

    _getStatusText() {
        return this.feedback.ApprovalCollectionStatus === MAP_TO_STANDARDS_CONSTANTS.STATUS.APPROVED
            ? this.multiLanguageService.getString("atlas_sidebar.tabs.mapping_approval.mapping_approved")
            : this.multiLanguageService.getString("atlas_sidebar.tabs.mapping_approval.mapping_not_approved");
    }

    _isEditMode(changes) {
        return changes.mode !== undefined && changes.mode.currentValue === this.ACTION_CONSTANTS.EDIT;
    }

    changeAnswer(validation, changes) {
        this.onEdit({
            validation: validation,
            changes: changes
        });
    }
}

export const approvalCollection = {
    bindings: {
        feedback: "<",
        mode: "<",
        onEdit: "&",
        feedbackStatements: "<"
    },
    template: template,
    controller: ApprovalCollectionComponent
};

ApprovalCollectionComponent.$inject = ["multiLanguageService", "feedbackHelper"];
angularAMD.component("approvalCollection", approvalCollection);
