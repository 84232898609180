import { angularAMD } from "@pebblepad/amd";

angularAMD.service("UserAPI", UserAPI);
UserAPI.$inject = ["$http", "$q", "baseUrlsFactory", "AssetEndpointService"];

function UserAPI($http, $q, baseUrlsFactory, AssetEndpointService) {
    this.getLoggedInUserData = getLoggedInUserData;
    this.keepUserLoggedIn = keepUserLoggedIn;
    this.changeUserSetting = changeUserSetting;
    this.sendResetAndMerge = sendResetAndMerge;

    /**
     * @returns {Promise} - Resolves with either null or an Object
     */
    function getLoggedInUserData() {
        return AssetEndpointService.getLoggedInUserData();
    }

    function keepUserLoggedIn() {
        return $http.get(baseUrlsFactory.api_base_url + "SharedMenu/KeepMeLoggedIn").then(function (response) {
            //If they response returns false, user is not logged in - reject!
            return response.data ? true : $q.reject();
        });
    }

    function changeUserSetting(settingName, value) {
        return $http.get(baseUrlsFactory.api_base_url + "Home/ChangeUserSetting?settingName=" + settingName + "&value=" + value);
    }

    function sendResetAndMerge() {
        return $http.get(baseUrlsFactory.api_base_url + "Home/MergeExternalAccounts");
    }
}
