import { angularAMD } from "@pebblepad/amd";

angularAMD.service("A11yGridFactory", A11yGridFactory);
A11yGridFactory.$inject = ["domSearchHelper", "accessibilityKeyEventHelper"];

function A11yGridFactory(domSearchHelper, accessibilityKeyEventHelper) {
    this.domSearchHelper = domSearchHelper;
    this.accessibilityKeyEventHelper = accessibilityKeyEventHelper;
}

A11yGridFactory.prototype.createGrid = function (rowContainer, firstRow, gridSelector) {
    return new RoleGrid(rowContainer, firstRow, gridSelector, this.domSearchHelper, this.accessibilityKeyEventHelper);
};

function RoleGrid(rowContainer, firstRow, gridSelector, domSearchHelper, accessibilityKeyHelper) {
    this.domSearchHelper = domSearchHelper;
    this.accessibilityKeyHelper = accessibilityKeyHelper;
    this.gridSelector = gridSelector;
    this.gridCellSelector = this.gridSelector ? this.gridSelector : '[role="gridcell"]';
    this.rowSelector = '[role="row"]';

    this.row = firstRow;
    this.rowContainer = rowContainer;

    this.focusKeyMap = {};
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.leftArrow] = this.focusItemLeft.bind(this);
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.rightArrow] = this.focusItemRight.bind(this);
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.upArrow] = this.focusItemUp.bind(this);
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.downArrow] = this.focusItemDown.bind(this);
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.pageUp] = this.focusFiveRowsUp.bind(this);
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.pageDown] = this.focusFiveRowsDown.bind(this);
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.home] = this.focusFirst.bind(this);
    this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.end] = this.focusLast.bind(this);
}

RoleGrid.prototype.getSiblingRow = function (position) {
    var rows = Array.prototype.slice.call(this.rowContainer[0].querySelectorAll(this.rowSelector), 0);
    if (rows.length === 0) {
        return null;
    }

    var foundIndex = rows.indexOf(this.row[0]);

    if (foundIndex === -1) {
        return null;
    }

    var newIndex = foundIndex + position;

    if (newIndex >= rows.length) {
        return rows[rows.length - 1];
    } else if (newIndex < 0) {
        return rows[0];
    } else {
        return rows[newIndex];
    }
};

RoleGrid.prototype.addKeyEvents = function () {
    this.rowContainer.bind("keydown", this.onKeyDown.bind(this));
};

RoleGrid.prototype.removeKeyEvents = function () {
    this.rowContainer.unbind("keydown");
};

RoleGrid.prototype.updateRow = function () {
    this.updateRowByElement(this.rowContainer[0].querySelector(this.rowSelector));
};

RoleGrid.prototype.updateRowByElement = function (element) {
    this.row[0] = element;
};

RoleGrid.prototype.focusOnRow = function (rowIndex) {
    var rows = this.rowContainer[0].querySelectorAll(this.rowSelector);

    if (rowIndex >= rows.length) {
        return;
    }

    this.row[0] = rows[rowIndex];
    this.currentGridCells = this.getCells(this.row[0]);

    if (this.currentGridCells.length > 0) {
        this.currentGridCells[0].focus();
    }
};

RoleGrid.prototype.getCells = function (rowElement) {
    var cells = rowElement.querySelectorAll(this.gridCellSelector);
    var visibleCells = [];

    for (var i = 0; i < cells.length; i++) {
        if (this.domSearchHelper.elementIsVisible(cells[i])) {
            visibleCells.push(cells[i]);
        }
    }

    return visibleCells;
};

RoleGrid.prototype.onKeyDown = function (event) {
    this.currentRow = this.row[0];
    this.currentGridCells = this.getCells(this.currentRow);

    var currentIndex = Array.prototype.indexOf.call(this.currentGridCells, event.target);
    var action = this.focusKeyMap[event.keyCode];

    if (action) {
        event.preventDefault();
        var elements = this.currentGridCells;
        var ctrlPressed = event.ctrlKey;
        var focusedElement = action(event, elements, currentIndex, ctrlPressed);
        if (focusedElement) {
            event.target.tabIndex = -1;
            focusedElement.tabIndex = 0;
        }
    }
};

RoleGrid.prototype.focusItemRight = function (event, elements, currentIndex) {
    if (currentIndex === elements.length - 1) {
        var thisRow = this.row[0];
        this.setNextRow(1);

        if (thisRow !== this.nextRow) {
            return this.focusElement(this.getNextGridCells(), 0);
        }
    } else {
        return this.focusElement(elements, currentIndex + 1);
    }
};

RoleGrid.prototype.focusItemLeft = function (event, elements, currentIndex) {
    if (currentIndex === 0) {
        var thisRow = this.row[0];
        this.setNextRow(-1);

        if (thisRow !== this.nextRow) {
            var gridCells = this.getNextGridCells();
            return this.focusElement(gridCells, gridCells.length - 1);
        }
    } else {
        return this.focusElement(elements, currentIndex - 1);
    }
};

RoleGrid.prototype.focusItemDown = function (event) {
    this.setNextRow(1);

    var nextElements = this.getNextGridCells();
    return this.nextFocus(event, nextElements);
};

RoleGrid.prototype.focusItemUp = function (event) {
    this.setNextRow(-1);

    var nextElements = this.getNextGridCells();
    return this.nextFocus(event, nextElements);
};

RoleGrid.prototype.focusFiveRowsDown = function (event) {
    this.setNextRow(5);

    var nextElements = this.getNextGridCells();
    return this.nextFocus(event, nextElements);
};

RoleGrid.prototype.focusFiveRowsUp = function (event) {
    this.setNextRow(-5);

    var nextElements = this.getNextGridCells();
    return this.nextFocus(event, nextElements);
};

RoleGrid.prototype.focusFirstRowAndCell = function () {
    var rows = this.rowContainer[0].querySelectorAll(this.rowSelector);
    this.setNextRow(-rows.length);

    var nextElements = this.getNextGridCells();
    return this.focusElement(nextElements, 0);
};

RoleGrid.prototype.focusFirst = function (event, elements, currentIndex, ctrlPressed) {
    if (ctrlPressed) {
        var focusElement = this.focusFirstRowAndCell();
        return focusElement;
    }

    return this.focusElement(elements, 0);
};

RoleGrid.prototype.focusLastRowAndCell = function () {
    var rows = this.rowContainer[0].querySelectorAll(this.rowSelector);
    this.setNextRow(rows.length - 1);

    var nextElements = this.getNextGridCells();
    return this.focusElement(nextElements, nextElements.length - 1);
};

RoleGrid.prototype.focusLast = function (event, elements, currentIndex, ctrlPressed) {
    if (ctrlPressed) {
        var focusElement = this.focusLastRowAndCell();
        return focusElement;
    }

    return this.focusElement(elements, elements.length - 1);
};

RoleGrid.prototype.setNextRow = function (position) {
    this.nextRow = this.getSiblingRow(position);

    if (this.nextRow === null) {
        return;
    }

    this.row[0] = this.nextRow;
};

RoleGrid.prototype.getNextGridCells = function () {
    this.nextGridCells = this.getCells(this.nextRow);
    return this.nextGridCells;
};

RoleGrid.prototype.nextFocus = function (event, elements) {
    if (this.nextGridCells.length < this.currentGridCells.length || this.nextGridCells.length > this.currentGridCells.length) {
        return this.focusFirstOrAround(event);
    } else {
        var currentIndex = Array.prototype.indexOf.call(this.currentGridCells, event.target);
        return this.focusElement(elements, currentIndex);
    }
};

RoleGrid.prototype.focusFirstOrAround = function (event) {
    for (var i = 0; i < this.nextGridCells.length; i++) {
        if (this.nextGridCells[i].classList.value === event.target.classList.value) {
            this.nextGridCells[i].focus();
            return this.nextGridCells[i];
        }
    }

    return this.nextGridCells[0].focus();
};

RoleGrid.prototype.focusElement = function (elements, index) {
    elements[index].focus();
    return elements[index];
};
