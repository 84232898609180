import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import "../../assetStore/services/assetStoreService";
import "../../utilities/helpers";
import "../../utilities/urlService";

angularAMD.service("VersionHelper", VersionHelper);
VersionHelper.$inject = ["$routeParams", "assetStoreService", "helpers", "urlService"];

function VersionHelper($routeParams, assetStoreService, helpers, urlService) {
    //Public API
    //==============================================================================================================
    this.getAssetVersions = getAssetVersions;
    this.validateTimeslice = validateTimeslice;
    this.isTimesliceSupportedAPICall = isTimesliceSupportedAPICall;
    this.isSupportedByAssetType = isSupportedByAssetType;
    this.getUrlTimeslice = getUrlTimeslice;
    this.isUnsupportedByAssetTypeToDisplayMessage = isUnsupportedByAssetTypeToDisplayMessage;

    //Private Variables
    //==============================================================================================================
    //Temp whitelist of API calls to ensure to possible conflict.
    var timesliceSupportedAPIs = ["assetDto/GetAssetAndCheckPermission"];

    var supportedMainTypes = [
        ASSET_CONSTANTS.TYPES.ACTIVITY_LOG,
        ASSET_CONSTANTS.TYPES.BLOG,
        ASSET_CONSTANTS.TYPES.COLLECTION_WRAPPER,
        ASSET_CONSTANTS.TYPES.COLLECTION,
        ASSET_CONSTANTS.TYPES.FILE,
        ASSET_CONSTANTS.TYPES.FORM_RESPONSE,
        ASSET_CONSTANTS.TYPES.POST,
        ASSET_CONSTANTS.TYPES.WEBFOLIO_PAGE,
        ASSET_CONSTANTS.TYPES.WEBFOLIO,
        ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE
    ];

    var unsupportedSubTypes = [
        ASSET_CONSTANTS.SUB_TYPES.ACCOLADE,
        ASSET_CONSTANTS.SUB_TYPES.AFFILIATION,
        ASSET_CONSTANTS.SUB_TYPES.BELIEF,
        ASSET_CONSTANTS.SUB_TYPES.EMPLOYMENT,
        ASSET_CONSTANTS.SUB_TYPES.HOBBY,
        ASSET_CONSTANTS.SUB_TYPES.LANGUAGE,
        ASSET_CONSTANTS.SUB_TYPES.PERSONAL_STATEMENT,
        ASSET_CONSTANTS.SUB_TYPES.PUBLICATION,
        ASSET_CONSTANTS.SUB_TYPES.QUALIFICATION,
        ASSET_CONSTANTS.SUB_TYPES.REFEREE,
        ASSET_CONSTANTS.SUB_TYPES.ROLE
    ];

    var isUnsupportedByAssetTypeToDisplayMessage = [ASSET_CONSTANTS.TYPES.CONVERSATION, ASSET_CONSTANTS.TYPES.CALLING_CARD];

    //Private Methods
    //==============================================================================================================
    function getAssetVersions(id) {
        return assetStoreService.getAssetVersions(id).then(function (response) {
            // if there are any previous versions then map the data as needed
            if (response && response.data) {
                if (helpers.isNotEmptyArray(response.data.Versions)) {
                    response.data.Versions = response.data.Versions.map(mapVersionResponse);
                }
                return response.data;
            }
            return null;
        });
    }

    function mapVersionResponse(versionResponse) {
        return {
            Id: versionResponse.Id,
            Modified: versionResponse.Modified,
            ModifiedBy: versionResponse.ModifiedBy,
            ModifiedByName: versionResponse.ModifiedByName,
            MainType: versionResponse.MainType,
            Timeslice: versionResponse.Timeslice,
            Url: urlService.createHashUrl(versionResponse.Id, versionResponse.MainType, true, false, versionResponse.Timeslice)
        };
    }

    function isTimesliceSupportedAPICall(url) {
        var lowerUrl = url.toLowerCase();
        return timesliceSupportedAPIs.some(function (apiUrl) {
            return lowerUrl.indexOf(apiUrl.toLowerCase()) !== -1;
        });
    }

    function validateTimeslice(timeslice) {
        //If timeslice is null, undefined, or contains non-digit characters (0-9 only) return false!
        if (/^\d+$/.test(timeslice)) {
            var timesliceMS = getUrlTimeslice(timeslice);
            //If timeslice is 0 or in the future return false - CertainLy not 100% accurate, JS value may be a several microseconds out of sync as we cant get UNIX ticks!
            return !!(timesliceMS && timesliceMS <= Date.now());
        }

        return false;
    }

    /**
     * Convert backend ticks based timeslice to readable ms timeslice
     * @param {number=} timeslice
     * @returns {number}
     */
    function getUrlTimeslice(timeslice) {
        var urlTimeslice = timeslice !== undefined ? timeslice : $routeParams.timeslice;

        if (urlTimeslice === undefined) {
            return 0;
        }

        return timeslice <= 10000 ? timeslice : Math.floor(parseInt(urlTimeslice, 10) / 10000);
    }

    function isSupportedByAssetType(mainType, subType) {
        return supportedMainTypes.contains(mainType) && !unsupportedSubTypes.contains(subType);
    }

    function isUnsupportedByAssetTypeToDisplayMessage(mainType, subType) {
        if (isSupportedByAssetType(mainType, subType)) {
            return false;
        }
        return isUnsupportedByAssetTypeToDisplayMessage.contains(mainType);
    }
}
