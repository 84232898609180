import { IndentBlockClass } from "../../../toolbar-items/enums/IndentBlockClass";
import { getClosestNumericalMatch } from "../../../../utils/get-closest-numerical-match/GetClosestNumericalMatch.function";
import { RemConverter } from "../../../../utils/rem-converter/RemConverter";
import { AttributeConverter } from "../../types/AttributeConverter";

const marginToClassMap: ReadonlyMap<number, IndentBlockClass> = new Map([
    [2, IndentBlockClass.Indent2],
    [4, IndentBlockClass.Indent4],
    [6, IndentBlockClass.Indent6],
    [8, IndentBlockClass.Indent8],
    [10, IndentBlockClass.Indent10],
    [12, IndentBlockClass.Indent12],
    [14, IndentBlockClass.Indent14],
    [16, IndentBlockClass.Indent16],
    [18, IndentBlockClass.Indent18],
    [20, IndentBlockClass.Indent20]
]);

const marginValues: ReadonlyArray<number> = Array.from(marginToClassMap.keys());
const hasMarginClass = new RegExp(`${Object.values(IndentBlockClass).join("|")}`);

export const blockIndentConverter: AttributeConverter = (sandboxedElement) => {
    const marginLeft = sandboxedElement.style.marginLeft;
    if (marginLeft === "") {
        return;
    }

    sandboxedElement.style.marginLeft = "";
    const value = RemConverter.cssValueToRem(marginLeft);
    if (Number.isNaN(value) || value <= 0) {
        return;
    }

    const closestValue = getClosestNumericalMatch(value, marginValues);

    if (closestValue !== null && !hasMarginClass.test(sandboxedElement.className)) {
        sandboxedElement.classList.add(marginToClassMap.get(closestValue) as IndentBlockClass);
    }
};
