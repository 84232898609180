export function mapAssetToPortfolioPageModel(assetViewModel, assetId) {
    return {
        Children: Array.isArray(assetViewModel.Pages) ? assetViewModel.Pages : [],
        IsLockedOrParentLocked: assetViewModel.Locked,
        PageAssetId: assetId,
        PageIcon: assetViewModel.MainType.toLowerCase(),
        PageId: assetId,
        PageFolder: assetViewModel.AssetFolder,
        PageNavigationEditable: true,
        /**
         * @deprecated PagePosition should not used by any rendering or update logic
         */
        PagePosition: -1,
        PageTitle: assetViewModel.Title,
        PageType: assetViewModel.MainType,
        Revision: assetViewModel.Revision
    };
}
