import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";

angularAMD.service("PostService", PostService);
PostService.$inject = ["$q", "dataManagerService"];

function PostService($q, dataManagerService) {
    this.$q = $q;
    this.dataManagerService = dataManagerService;
}

/**
 * @param {String} assetId
 * @return {Promise.<Object>|null}
 */
PostService.prototype.updateLegacyPostFromId = function (assetId) {
    var manager = this.dataManagerService.getDto(assetId);
    return manager !== null ? this.updateLegacyPost(manager) : this.$q.reject();
};

/**
 * @param {Object} manager
 * @return {Promise.<Object>}
 */
PostService.prototype.updateLegacyPost = function (manager) {
    if (manager.data.is_saved) {
        return this.$q.when(manager);
    }

    var imageAssetOption = manager.data.AssetOptions.find(function (x) {
        return x.Key === "BackgroundImageAssetId";
    });
    var postBannerSection = this.getTitleBannerSection(manager.data);

    if (imageAssetOption !== void 0 && postBannerSection === null) {
        return manager.savePostToServer(manager.AssetId).then(function (data) {
            return data.promises.then(function (responses) {
                manager.data = responses[0].data.Asset;
                manager.removeFromStorage();
                return manager;
            });
        });
    }

    return this.$q.when(manager);
};

/**
 * @param {Object} assetDto
 * @return {Object || null}
 */
PostService.prototype.getTitleBannerSection = function (assetDto) {
    return assetDto.Sections.find(this._findPostBanner.bind(this)) || null;
};

/**
 * @param {Object} section
 * @return {Object}
 * @private
 */
PostService.prototype._findPostBanner = function (section) {
    if (
        section.SectionType === "ContentSectionHero" &&
        section.SectionOptions.some(function (x) {
            return x.Key === "titleBanner";
        })
    ) {
        return section;
    }

    if (section.Sections) {
        return section.Sections.find(this._findPostBanner.bind(this));
    }

    return null;
};

export { PostService };
