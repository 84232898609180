import { angularAMD } from "@pebblepad/amd";
import { htmlStringToPlainText } from "@pjs/security";
import { createGuidString } from "./createGuidString.function";

//Nice-ish way to wrap a Class
var ObjectCollection = (function () {
    function ObjectCollection() {
        this.items = [];
    }

    ObjectCollection.prototype.add = function (item) {
        this.items.push(item);
    };

    ObjectCollection.prototype.remove = function (property, value) {
        for (var i = 0, l = this.items.length, item; i < l; i++) {
            item = this.items[i];
            if (item[property] === value) {
                this.items[i] = null;
                this.items.splice(i, 1);
                break;
            }
        }
    };

    ObjectCollection.prototype.removeAll = function () {
        this.items.length = 0;
    };

    ObjectCollection.prototype.exists = function (property, value) {
        var index = this.find(property, value);
        return index !== -1;
    };

    ObjectCollection.prototype.find = function (property, value) {
        for (var i = 0, l = this.items.length, item; i < l; i++) {
            item = this.items[i];

            if (item[property] === value || (item[property] && value === undefined)) {
                return i;
            }
        }
        return -1;
    };

    ObjectCollection.prototype.getFromProperty = function (property, value) {
        var index = this.find(property, value);
        return index !== -1 ? this.items[index] : null;
    };

    return ObjectCollection;
})();

function GenericOption(title, key, value, hook) {
    this.title = title;
    this.key = key;
    this.value = value;
    this.hook = hook;
}

function IconOption(title, key, value, hook, icon) {
    GenericOption.apply(this, arguments);
    this.icon = icon;
}

function ActionOption(title, key, value, hook, action) {
    GenericOption.apply(this, arguments);
    this.action = action;
}

angularAMD.factory("helpers", [
    "$window",
    function ($window) {
        return {
            guid: createGuidString,
            isGuid: function (id) {
                return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(id);
            },

            /**
             * Here is used approach using ‘YIQ’ colour equation [https://en.wikipedia.org/wiki/YIQ]
             * Warning: This should not be used for anything which needs to meet WCAG AA colour contrast. YIQ contrasting checking != AA
             *          See https://www.w3.org/TR/WCAG20-TECHS/G17.html and https://webaim.org/resources/contrastchecker/ for more info!
             * @param hexColour
             * @param coefficient - The darker colour is, lesser yiq will be. If some of the colours doesn't give you more contrast, then increase 'coefficient'.
             * @returns {*}
             */
            getContrastYIQ: function (hexColour, coefficient) {
                var regex = /\w+/;
                coefficient = coefficient || 128;
                hexColour = hexColour.match(regex)[0];

                if (hexColour === "transparent") {
                    return "black";
                }

                var r = parseInt(hexColour.substr(0, 2), 16);
                var g = parseInt(hexColour.substr(2, 2), 16);
                var b = parseInt(hexColour.substr(4, 2), 16);
                var yiq = (r * 299 + g * 587 + b * 114) / 1000;

                return yiq >= coefficient ? "black" : "white";
            },

            fileExists: function (file_url) {
                var http = new XMLHttpRequest();
                http.open("HEAD", file_url, false);
                http.send();

                return http.status !== 404;
            },

            testIfClickedOutsideElement: function (e, el) {
                e = e || $window.event;
                var target = e.target;

                if (!el || !el.contains) {
                    return false;
                }

                return !el.contains(target);
            },

            isClickedOutsideElement: function (e) {
                var argument;

                // loop through each element
                for (var i = 1; i < arguments.length; i++) {
                    argument = arguments[i];
                    if (argument && !this.testIfClickedOutsideElement(e.originalEvent || e, argument.length ? argument[0] : argument)) {
                        return false;
                    }
                }

                return true;
            },

            randomString: function (length, chars) {
                chars = chars || "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
                var result = "";
                for (var i = length; i > 0; --i) {
                    result += chars[Math.floor(Math.random() * chars.length)];
                }
                return result;
            },

            /**
             * @param {string|null|undefined} htmlString
             */
            htmlToPlain: (htmlString) => {
                if (htmlString === null || htmlString === undefined) {
                    return "";
                }

                return htmlStringToPlainText(htmlString);
            },

            setToolbarClosed: function (open) {
                var documentEl = angular.element(document.documentElement);

                if (open) {
                    documentEl.removeClass("toolbar-closed");
                } else {
                    documentEl.addClass("toolbar-closed");
                }
            },

            //Thorough Class inheritance -  helpers.extendClass(myClass, parentClass); In constructor parentClass.call(this);
            extendClass: function (child, parent) {
                //Copy properties over from parent
                for (var property in parent) {
                    if (parent.hasOwnProperty(property)) {
                        child[property] = parent[property];
                    }
                }

                child.prototype = Object.create(parent.prototype);
            },

            isEmpty(value) {
                return !/\S/g.test(this.htmlToPlain(value));
            },

            isNotEmptyArray: function (value) {
                return value && value.length > 0;
            },

            //Checks to see if an Object and its values are not all undefined, null or ''
            isNotEmptyObject: function (obj) {
                if (!obj) {
                    return false;
                }

                var keys = Object.keys(obj),
                    isNotEmpty = false,
                    emptyValues = [undefined, null, ""];

                for (var i = 0, l = keys.length; i < l; i++) {
                    if (emptyValues.indexOf(obj[keys[i]]) === -1) {
                        isNotEmpty = true;
                        break;
                    }
                }
                return isNotEmpty;
            },

            createIconOption: function (title, key, value, icon, hook) {
                return new IconOption(title, key, value, hook, icon);
            },

            createActionOption: function (title, action, key, value, hook) {
                return new ActionOption(title, key, value, hook, action);
            },

            getValueFromObjectPath: function (obj, stringPath) {
                var path = stringPath.split("."),
                    value = obj;

                for (var i = 0, l = path.length; i < l; i++) {
                    value = value[path[i]];
                    if (!value) {
                        return null;
                    }
                }

                return value;
            },

            getScrollPositionForNestedElement: function (nestedElem, parentElem) {
                return nestedElem.getBoundingClientRect().bottom - parentElem.getBoundingClientRect().bottom;
            },

            convertStringBoolean: function (value) {
                return value === true || /^true$/i.test(value);
            },

            escapeRegexChars: function (value) {
                return value.replace(/[\*\+\?\.\-\/\{\}\(\)\[\]\\\^\$\|]/g, "\\$&");
            },

            addToQueryString: function (url, queryStringPartial) {
                var key = queryStringPartial.substring(0, queryStringPartial.indexOf("="));
                var match = new RegExp("(&|\\?)" + key, "i").exec(url);
                var delimiter = "";

                if (match) {
                    var newUrl = url;
                    var partial = newUrl.substring(match.index);
                    var endpoint = /&|$/.exec(partial).index;
                    delimiter = /\?/.test(partial) ? "?" : "&";

                    return newUrl.substring(0, match.index) + delimiter + queryStringPartial + newUrl.substring(match.index + endpoint);
                } else {
                    delimiter = /\?/.test(url) ? "&" : "?";
                    return url + delimiter + queryStringPartial;
                }
            },

            /**
             * @param {Number} num
             * @param {Array<Number>} values
             * @return {Number}
             */
            getNearestNumber: function (num, values) {
                if (values.length === 0) {
                    return Number.NaN;
                }

                var nearest = values[0];
                for (var i = 1, l = values.length; i < l; i++) {
                    if (Math.abs(num - values[i]) < Math.abs(num - nearest)) {
                        nearest = values[i];
                    }
                }

                return nearest;
            },

            /**s
             * @return {{width: Number, height: Number, dprWidth: Number, dprHeight: Number}}
             */
            getScreenSizes: function () {
                var width = $window.screen.availWidth;
                var height = $window.screen.availHeight;
                var dpr = $window.devicePixelRatio;

                return {
                    width: width,
                    height: height,
                    dprWidth: Math.floor(width * dpr),
                    dprHeight: Math.floor(height * dpr)
                };
            },

            /**
             *
             * @param {object} startingContext
             * @param {string} objPath
             * @return {null}
             */
            getCallbackFromPath: function (startingContext, objPath) {
                var path = objPath.split(".");
                var obj = startingContext;
                var context = null;
                for (var i = 0, l = path.length; i < l; i++) {
                    if (obj[path[i]] !== undefined) {
                        context = obj;
                        obj = obj[path[i]];
                    }
                }

                return typeof obj === "function" ? obj.bind(context) : null;
            },

            areObjectsEqual: function (ob1, ob2) {
                return JSON.stringify(ob1) === JSON.stringify(ob2);
            },

            zeroIfNaN: function (val) {
                val = val === null ? "0" : val;
                if (isNaN(val)) {
                    val = "0";
                }
                return val;
            },

            formatTime: function (totalMinutes) {
                var hours = null,
                    minutes = null;
                totalMinutes = parseFloat(totalMinutes);
                if (!isNaN(totalMinutes)) {
                    hours = Math.floor(totalMinutes / 60);
                    minutes = totalMinutes % 60;
                }
                return { hours: hours, minutes: minutes };
            },

            chunkArray: (array, chunkSize) => {
                const chunked = [];
                for (let i = 0; i < array.length; i += chunkSize) {
                    chunked.push(array.slice(i, i + chunkSize));
                }

                return chunked;
            },

            //Class rather the a create new function to allow it to be easily extended
            ObjectCollection: ObjectCollection
        };
    }
]);
