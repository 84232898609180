import { IExecutionQueue } from "./interfaces/IExecutionQueue";

export class ExecutionQueue implements IExecutionQueue {
    private readonly _queueItems: Set<() => void>;
    private _enabled: boolean;

    constructor() {
        this._queueItems = new Set<() => void>();
        this._enabled = false;
    }

    public add(queueItem: () => void): void {
        this._queueItems.add(queueItem);
        this._execute();
    }

    public enable(): void {
        this._enabled = true;
        this._execute();
    }

    private _execute(): void {
        if (!this._enabled) {
            return;
        }

        for (const event of this._queueItems) {
            event();
        }

        this._queueItems.clear();
    }
}
