import { FunctionComponent } from "react";
import { Icon } from "../../icon/Icon.component";
import { errorIcon } from "../../icon/icons/Error.icon";
import { IMenuWithText } from "../interfaces/IMenuWithText";
import "../styles/menu-with-text-and-icon.css";

export const ErrorMenu: FunctionComponent<IMenuWithText> = (props) => {
    return (
        <div className="cui-auto-complete__menu-container" id={props.id} data-hook="autocomplete-error-menu">
            <Icon source={errorIcon} className="cui-auto-complete__menu-icon" fill="var(--colour-icon-fill-danger)" />
            <span className="cui-auto-complete__menu-text">{props.message}</span>
        </div>
    );
};
