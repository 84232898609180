import { Fragment, MouseEvent, PropsWithChildren, FunctionComponent, useId, useRef, useState, useContext } from "react";
import { useFactoryRef, useOnUnmount, useOnValueChange } from "@pjs/react-utilities";
import { hideWhenClosed } from "../../utils/hide-when-closed/hideWhenClosed";
import { MenuAriaModel } from "../aria-roles/menus/menu-event-adapter/types/MenuAriaModel";
import { AriaMenuStatus } from "../aria-roles/menus/menu-event-adapter/enums/AriaMenuStatus";
import { BoundaryContext } from "../boundary/Boundary.context";
import { floatingButtonMenuFactory } from "../aria-roles/menus/floating-menu/factories/FloatingButtonMenuFactory";
import { IButtonMenuProps } from "./interfaces/IButtonMenuProps";
import { ButtonMenuRow } from "./ButtonMenuRow.component";
import { IButtonMenuOption } from "./interfaces/IButtonMenuOption";
import "./styles/button-menu.css";

const matchItemOnKeys = (item: IButtonMenuOption, currentKeys: string): boolean => item.text.toLowerCase().startsWith(currentKeys);

const initialModel: MenuAriaModel = {
    activeItemIndex: 0,
    isOpen: false,
    type: AriaMenuStatus.Closed
} as const;

export const ButtonMenu: FunctionComponent<PropsWithChildren<IButtonMenuProps>> = (props) => {
    const boundaryContext = useContext(BoundaryContext);
    const contentReference = useRef(null);
    const buttonReference = useRef(null);
    const menuId = useId();
    const defaultButtonId = useId();

    const [model, setModel] = useState<MenuAriaModel>(initialModel);

    const floatingMenu = useFactoryRef(floatingButtonMenuFactory, {
        boundaryRef: boundaryContext.element,
        floatingRef: contentReference,
        initialModel: model,
        matchItemOnKeys: matchItemOnKeys,
        onModelChange: setModel,
        placement: "bottom-start",
        triggerRef: buttonReference
    });

    useOnValueChange(() => floatingMenu.updateModel(model), [model]);

    useOnUnmount(() => floatingMenu.destroy());

    const isOpen = (props.disabled === false || props.disabled === undefined) && model.isOpen;
    const buttonId = props.id !== undefined ? props.id : defaultButtonId;
    const activeStyle = props.openStyle !== undefined ? props.openStyle : "cui-button-menu__button--menu-open";
    const buttonClass = `cui-button ${props.buttonStyle}${isOpen ? ` ${activeStyle}` : ""}`;

    return (
        <Fragment>
            <button
                aria-controls={menuId}
                aria-disabled={props.disabled}
                aria-expanded={isOpen}
                aria-haspopup={true}
                aria-label={props.ariaLabel}
                className={buttonClass}
                data-hook="button-menu-button"
                disabled={props.disabled}
                id={buttonId}
                ref={buttonReference}
                type="button"
                onClick={floatingMenu.events.trigger.onClick}
                onKeyDown={(e) => floatingMenu.events.trigger.onKeyDown(e, props.selectOptions)}
                onMouseDown={floatingMenu.events.trigger.onMouseDown}>
                {props.children}
            </button>

            <ul
                style={hideWhenClosed(isOpen)}
                aria-activedescendant={`${menuId}-${model.activeItemIndex}`}
                aria-labelledby={buttonId}
                className="cui-button-menu__content"
                data-hook="button-menu-content-wrapper"
                id={menuId}
                ref={contentReference}
                role="menu"
                tabIndex={-1}
                onBlur={floatingMenu.events.target.onFocusLoss}
                onKeyDown={(e) => floatingMenu.events.target.onKeyDown(e, props.selectOptions)}>
                {props.selectOptions.map((option, index) => {
                    const key = `${menuId}-${index}`;

                    return (
                        <ButtonMenuRow
                            activeRow={index === model.activeItemIndex}
                            id={`${menuId}-${index}`}
                            option={option}
                            key={key}
                            onClick={(e: MouseEvent) => {
                                floatingMenu.events.item.onClick(e, index);
                                option.onSelect();
                            }}
                        />
                    );
                })}
            </ul>
        </Fragment>
    );
};
