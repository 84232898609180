import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("PaginatedSearchFactory", PaginatedSearchFactory);
PaginatedSearchFactory.$inject = ["$q"];

function PaginatedSearchFactory($q) {
    return {
        createPaginatedSearch: function (results, index, pageLength) {
            return new PaginatedSearch($q, results, index, pageLength);
        }
    };
}

/**
 * @param {$q} q
 * @param {Array<Object>|null} results
 * @param {Number} index
 * @param {Number=} pageLength
 * @constructor
 */
function PaginatedSearch(q, results, index, pageLength) {
    this._q = q;
    this._results = results || [];
    this._index = !isNaN(index) && typeof index === "number" && index < this._results.length - 1 ? index : 0;
    this._pageLength = !isNaN(pageLength) && typeof index === "number" ? pageLength : 50;
}

/**
 * @param {Array<Object>} results
 * @param {=number>} preloadedIndex
 */
PaginatedSearch.prototype.setResults = function (results, preloadedIndex = 0) {
    this._results = results;
    this._index = preloadedIndex;
};

/**
 * @return {Array<Object>}
 */
PaginatedSearch.prototype.getAllStoredResults = function () {
    return this._results;
};

/**
 * @param {Function} comparator
 * @return {Number}
 */
PaginatedSearch.prototype.removeFromStoredResults = function (comparator) {
    for (var i = 0; i < this._results.length; i++) {
        if (comparator(this._results[i])) {
            this._results.splice(i, 1);

            if (this._index !== 0 && i <= this._index) {
                this._index--;
            }
            i--;
        }
    }

    return this;
};

/**
 * @return {Number}
 */
PaginatedSearch.prototype.getPageLength = function () {
    return this._pageLength;
};

/**
 * @return {Promise} - Resolves with {Array<Object>}
 */
PaginatedSearch.prototype.getResults = function () {
    const endIndex = this._index > 0 ? this._index : this._pageLength;

    return this._loadResults(0, endIndex).then((newResults) => {
        this._index = newResults.length;
        return newResults;
    });
};

/**
 * @return {Promise} - Resolves with {Array<Object>}
 */
PaginatedSearch.prototype.getNextSetOfResults = function () {
    return this._loadResults(this._index, this._index + this._pageLength).then((newResults) => {
        this._index += newResults.length;
        return newResults;
    });
};

/**
 * @param {Number} newPageLength
 */
PaginatedSearch.prototype.updatePageLength = function (newPageLength) {
    this._pageLength = newPageLength;
};

/**
 * @returns {boolean}
 */
PaginatedSearch.prototype.isAtEnd = function () {
    return this._index >= this._results.length;
};

/**
 * @returns {Promise}  - Resolves with {Array<Object>}
 * @private
 */
PaginatedSearch.prototype._loadResults = function (startIndex, endIndex) {
    if (endIndex <= this._results.length) {
        return this._q.when(this._results.slice(startIndex, endIndex));
    }

    if (startIndex > this._results.length - 1) {
        return this._q.when([]);
    }

    return this._q.when(this._results.slice(startIndex, this._results.length));
};
