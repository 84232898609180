import { react2angular } from "@pebblepad/react2angular";
import { angularAMD } from "@pebblepad/amd";
import { AutoComplete, WithBoundaryBridge } from "@pjs/core-ui";

angularAMD.component(
    "autoCompleteComponent",
    react2angular(WithBoundaryBridge(AutoComplete), [
        "ariaLabel",
        "emptyLabel",
        "loadingLabel",
        "onItemSelect",
        "onInput",
        "openLength",
        "renderItem",
        "search",
        "textContent",
        "placeholder",
        "debounceTime"
    ])
);
