import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";

angularAMD.directive("imgFallback", [
    "$sce",
    "baseUrlsFactory",
    function ($sce, baseUrlsFactory) {
        return {
            restrict: "A",
            link: function (scope, el, attrs) {
                el.bind("error", function () {
                    scope.$emit("imgFallback", el);
                });
            }
        };
    }
]);
