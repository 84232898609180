export function withoutDuplicateValues(getValue) {
    const trackedValues = new Set();

    return (item) => {
        const value = getValue(item);
        if (trackedValues.has(value)) {
            return false;
        }

        trackedValues.add(value);
        return true;
    };
}
