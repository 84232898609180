import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./help-expandable.html";
import "../pui/src/accordion/accordion.component";
import "../pui/src/accordion/accordion-content.component";
import "../pui/src/accordion/accordion-header.component";
import "../pui/src/accordion/accordion-panel.component";

class HelpExpandable extends NgComponent {
    constructor($scope) {
        super();

        this.ariaDescribedById = `help-expandable-${$scope.$id}`;
    }
}

export const helpExpandable = {
    bindings: {
        header: "<"
    },
    template: template,
    transclude: true,
    controller: HelpExpandable
};

HelpExpandable.$inject = ["$scope"];
angularAMD.component("helpExpandable", helpExpandable);
