import { angularAMD } from "@pebblepad/amd";
import "../services/spaActionMenuService";

angularAMD.controller("SpaActionMenuCtrl", [
    "$scope",
    "spaActionMenuService",
    "$rootScope",
    "$sce",
    "baseUrlsFactory",
    function ($scope, spaActionMenuService, $rootScope, $sce, baseUrlsFactory) {
        //functions moved to directive due to having to manipulate the dom once the data call was made
    }
]);
