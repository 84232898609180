import {
    activityLogIcon,
    archiveIcon,
    audioIcon,
    blogIcon,
    callingCardIcon,
    collectionIcon,
    conversationIcon,
    cvIcon,
    databaseIcon,
    documentIcon,
    experienceIcon,
    experienceResourceIcon,
    feedbackIcon,
    feedbackResourceIcon,
    fileIcon,
    imageIcon,
    pageIcon,
    pdfIcon,
    planIcon,
    planResourceIcon,
    portfolioIcon,
    postIcon,
    presentationIcon,
    reflectionIcon,
    reflectionResourceIcon,
    spreadsheetIcon,
    talentIcon,
    talentResourceIcon,
    videoIcon,
    workbookResourceIcon,
    workbookResponseIcon,
    worksheetIcon,
    worksheetResourceIcon
} from "@pjs/core-ui";
import { MainType } from "../../enums/MainType";
import { SubType } from "../../enums/SubType";
import { IAssetIconInfo } from "./interfaces/IAssetIconInfo";

const assetIconMap = new Map<MainType | SubType, IAssetIconInfo>([
    [MainType.ActivityLog, { fill: "#BA3231", source: activityLogIcon }],
    [SubType.Archive, { fill: "#5B7589", source: archiveIcon }],
    [SubType.Audio, { fill: "#5B7589", source: audioIcon }],
    [MainType.Blog, { fill: "#A3B73C", source: blogIcon }],
    [MainType.CallingCard, { fill: "#BA3231", source: callingCardIcon }],
    [MainType.Collection, { fill: "#52485B", source: collectionIcon }],
    [MainType.Conversation, { fill: "#5B7589", source: conversationIcon }],
    [MainType.Cv, { fill: "#5B8E3E", source: cvIcon }],
    [SubType.Database, { fill: "#5B7589", source: databaseIcon }],
    [SubType.Document, { fill: "#5B7589", source: documentIcon }],
    [SubType.Experience, { fill: "#B24F64", source: experienceIcon }],
    [SubType.Feedback, { fill: "#45A4A7", source: feedbackIcon }],
    [MainType.File, { fill: "#5B7589", source: fileIcon }],
    [SubType.Image, { fill: "#5B7589", source: imageIcon }],
    [MainType.Page, { fill: "#CC6CB3", source: pageIcon }],
    [SubType.Pdf, { fill: "#5B7589", source: pdfIcon }],
    [SubType.Plan, { fill: "#429176", source: planIcon }],
    [MainType.Portfolio, { fill: "#4AA7AA", source: portfolioIcon }],
    [MainType.Post, { fill: "#429176", source: postIcon }],
    [SubType.Presentation, { fill: "#5B7589", source: presentationIcon }],
    [SubType.Reflection, { fill: "#607768", source: reflectionIcon }],
    [SubType.Spreadsheet, { fill: "#5B7589", source: spreadsheetIcon }],
    [SubType.Talent, { fill: "#DD9A27", source: talentIcon }],
    [SubType.Video, { fill: "#5B7589", source: videoIcon }],
    [MainType.WorkBookResponse, { fill: "#C86127", source: workbookResponseIcon }],
    [SubType.Worksheet, { fill: "#881744", source: worksheetIcon }]
]);

const resourceIconMap = new Map<MainType | SubType, IAssetIconInfo>([
    [SubType.Experience, { fill: "#B24F64", source: experienceResourceIcon }],
    [SubType.Feedback, { fill: "#45A4A7", source: feedbackResourceIcon }],
    [SubType.Plan, { fill: "#429176", source: planResourceIcon }],
    [SubType.Reflection, { fill: "#607768", source: reflectionResourceIcon }],
    [SubType.Talent, { fill: "#DD9A27", source: talentResourceIcon }],
    [SubType.Worksheet, { fill: "#881744", source: worksheetResourceIcon }],
    [MainType.WorkBook, { fill: "#C86127", source: workbookResourceIcon }],
    [MainType.Form, { fill: "#C86127", source: talentResourceIcon }]
]);

export const assetIcons = {
    get: (mainType: MainType, subType?: SubType): IAssetIconInfo | null => {
        const iconMap = mainType === MainType.Form || mainType === MainType.WorkBook ? resourceIconMap : assetIconMap;

        if (subType !== undefined) {
            const iconInfoBySubType = iconMap.get(subType);
            if (iconInfoBySubType !== undefined) {
                return iconInfoBySubType;
            }
        }
        const iconInfoByMainType = iconMap.get(mainType);
        return iconInfoByMainType !== undefined ? iconInfoByMainType : null;
    }
};
