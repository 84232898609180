import { Component, ReactElement, MouseEvent } from "react";
import { AssetIcon } from "@pjs/asset-core";
import { RichTextView } from "@pjs/rich-text";
import { OverflowMenu, editIcon, removeAttachmentIcon, IButtonMenuOption, Icon, textEvidenceIcon, Hyperlink, openIcon, openNewTabIcon } from "@pjs/core-ui";
import { formResponseElementI18n } from "../i18n/FormResponseElementI18n.const";
import { IEvidenceItemProps } from "./interfaces/IEvidenceItemProps";
import "./styles/evidence-item.css";

export class EvidenceItem extends Component<IEvidenceItemProps> {
    private static readonly _editIconColour: string = "var(--colour-icon-fill-info)";
    public onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
    private readonly _optionsLabel: string = formResponseElementI18n.getString("evidence.overflow_menu.aria");
    private readonly _menuEvidenceOptions: Array<IButtonMenuOption>;
    private readonly _menuLinkOptions: Array<IButtonMenuOption>;
    private _previousJustification: string;

    constructor(props: IEvidenceItemProps) {
        super(props);
        this.onClick = this._onClick.bind(this);

        this._menuLinkOptions = [
            {
                icon: openIcon,
                iconColour: EvidenceItem._editIconColour,
                onSelect: () => this._openEvidence(),
                text: formResponseElementI18n.getString("evidence.overflow_menu.open")
            },
            {
                icon: openNewTabIcon,
                iconColour: EvidenceItem._editIconColour,
                onSelect: () => this._openEvidenceInNewTab(),
                text: formResponseElementI18n.getString("evidence.overflow_menu.open_new_tab")
            }
        ];

        this._menuEvidenceOptions = [
            {
                icon: editIcon,
                iconColour: EvidenceItem._editIconColour,
                onSelect: () => this._editJustification(),
                text: this._getAddEditOptionText()
            },
            {
                icon: removeAttachmentIcon,
                iconColour: EvidenceItem._editIconColour,
                onSelect: () => this._deleteEvidence(),
                text: formResponseElementI18n.getString("evidence.overflow_menu.remove")
            }
        ];

        this._previousJustification = this.props.evidence.justification;
    }

    public render(): ReactElement {
        const menuOptions = this._getMenuOptions();
        const disabled = menuOptions.length === 0;

        return (
            <div className="fre-evidence-item" id={`evidence-item-${this.props.evidence.aggregateId}`}>
                <div className="fre-evidence-item__header">
                    <div className="fre-evidence-item__evidence-info">
                        {this.props.evidence.mainType === null ? (
                            <Icon source={textEvidenceIcon} fill={EvidenceItem._editIconColour} className="fre-evidence-item__asset-icon" />
                        ) : (
                            <AssetIcon className="fre-evidence-item__asset-icon" mainType={this.props.evidence.mainType} subType={this.props.evidence.subType} />
                        )}
                        <div className="fre-evidence-item__mapping-info">
                            {this.props.evidence.assetLink === "" ? (
                                <div className="fre-evidence-item-mapping-info__evidence-asset-title" id={this.props.evidence.evidenceTitleId} data-hook="evidence-asset-title" tabIndex={-1}>
                                    {this.props.evidence.assetTitle}
                                </div>
                            ) : (
                                <Hyperlink
                                    className="fre-evidence-item-mapping-info__evidence-asset-title"
                                    id={this.props.evidence.evidenceTitleId}
                                    href={this.props.evidence.assetLink}
                                    onClick={this.onClick}>
                                    {this.props.evidence.assetTitle}
                                </Hyperlink>
                            )}
                            <span className="fre-evidence-item-mapping-info__mapping-dates" data-hook="evidence-item-mapping-dates">
                                {this.props.evidence.formattedDates}
                            </span>
                        </div>
                    </div>
                    <div className="fre-evidence-item-mapping-info__overflow-menu">
                        <OverflowMenu ariaLabel={this._optionsLabel} id={this.props.evidence.evidenceMenuId} selectOptions={menuOptions} disabled={disabled} />
                    </div>
                </div>
                {this.props.evidence.justification !== "" && (
                    <RichTextView content={this.props.evidence.justification} dataHook="evidence-item-justification" className="fre-evidence-item__justification" />
                )}
            </div>
        );
    }

    private _onClick(event: MouseEvent<HTMLAnchorElement>): void {
        this.props.onSelectEvidence(this.props.evidence, false, event);
    }

    private _openEvidence(): void {
        this.props.onSelectEvidence(this.props.evidence, false);
    }

    private _openEvidenceInNewTab(): void {
        this.props.onSelectEvidence(this.props.evidence, true);
    }

    private _editJustification(): void {
        this.props.onEditJustification(this.props.evidence);
    }

    private _deleteEvidence(): void {
        this.props.onDeleteEvidence(this.props.evidence);
    }

    private _getAddEditOptionText(): string {
        return formResponseElementI18n.getString(`evidence.overflow_menu.${this.props.evidence.justification !== "" ? "edit" : "add"}`);
    }

    private _getMenuOptions(): Array<IButtonMenuOption> {
        const hasAssetLink = this.props.evidence.assetLink !== "";
        let menuOptions: Array<IButtonMenuOption> = [];

        if (this.props.evidence.justification !== this._previousJustification) {
            this._menuEvidenceOptions[0].text = this._getAddEditOptionText();
            this._previousJustification = this.props.evidence.justification;
        }

        if (hasAssetLink) {
            menuOptions = menuOptions.concat(this._menuLinkOptions);
        }

        if (!this.props.isDisabled) {
            menuOptions = menuOptions.concat(this._menuEvidenceOptions);
        }

        return menuOptions;
    }
}
