import { AttributeElement, DecoupledEditor, DowncastConversionApi, Match, ViewElement } from "@pebblepad/ckeditor";
import { linkModelAttributes } from "./constants/LinkModelAttributes.const";

export class LinkerModelSchema {
    public static applyTo(editor: DecoupledEditor): void {
        editor.model.schema.extend("$text", {
            allowAttributes: [linkModelAttributes.href, linkModelAttributes.isExternal]
        });

        editor.conversion.for("downcast").attributeToElement({
            model: linkModelAttributes.href,
            view: LinkerModelSchema._hrefAttributeToElement
        });

        editor.conversion.for("downcast").attributeToElement({
            model: linkModelAttributes.isExternal,
            view: LinkerModelSchema._externalAttributeToElement
        });

        editor.conversion.for("upcast").elementToAttribute({
            model: {
                key: linkModelAttributes.href,
                value: (viewElement: ViewElement) => viewElement.getAttribute("href") ?? null
            },
            view: {
                attributes: "href",
                name: "a"
            }
        });

        editor.conversion.for("upcast").elementToAttribute({
            model: linkModelAttributes.isExternal,
            view: LinkerModelSchema._elementToExternalAttributeConverter
        });
    }

    private static _hrefAttributeToElement(value: string, api: DowncastConversionApi): AttributeElement {
        return api.writer.createAttributeElement("a", { href: value });
    }

    private static _externalAttributeToElement(_: string, api: DowncastConversionApi): AttributeElement {
        return api.writer.createAttributeElement("a", { rel: "noopener noreferrer", target: "_blank" });
    }

    private static _elementToExternalAttributeConverter(element: ViewElement): Match | null {
        if (element.name !== "a" || element.getAttribute("target") !== "_blank") {
            return null;
        }

        const attributes = ["target"];
        if (element.hasAttribute("rel")) {
            attributes.push("rel");
        }

        return { attributes: attributes, name: true };
    }
}
