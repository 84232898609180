/**
 * @description - isVisuallyHidden limitations are that

 * - it will return true for position:fixed elements
 * - it does not check if the element is attached to the page or that it's inside an inert/aria-hidden container
 */
export function isVisuallyHidden(element: HTMLElement): boolean {
    if (element.tagName !== "SUMMARY" && element.parentElement instanceof HTMLDetailsElement && !element.parentElement.open) {
        return true;
    }

    if (element.offsetParent === null) {
        return true;
    }

    const styles = getComputedStyle(element);

    if (styles.visibility === "hidden") {
        return true;
    }

    return element.offsetWidth === 0 && element.offsetHeight === 0;
}
