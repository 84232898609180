import { angularAMD } from "@pebblepad/amd";
import "../../../../assetEndpointService/assetEndpoint.service";

angularAMD.factory("assetProgressTrackingService", [
    "$q",
    "$http",
    "$location",
    "AssetEndpointService",
    function ($q, $http, $location, AssetEndpointService) {
        return {
            getProgressData: function (assetId, submissionId, timeslice) {
                var deferred = $q.defer();

                // reject if assetId is missing
                if (!assetId) {
                    deferred.reject();
                } else {
                    $http.defaults.withCredentials = true;
                    AssetEndpointService.getCurrentProgress(assetId, submissionId, timeslice).then(
                        function (success) {
                            if (success && success.data && !(typeof success.data === "string" || success.data instanceof String)) {
                                deferred.resolve(success.data);
                            } else {
                                deferred.reject(success);
                            }
                        },
                        function (fail) {
                            deferred.reject(fail);
                        }
                    );
                }

                return deferred.promise;
            },

            isPublic: function (ignoreViewer) {
                return $location.absUrl().indexOf("#/public/") > -1 || (!ignoreViewer && $location.absUrl().indexOf("#/viewer/") > -1);
            }
        };
    }
]);
