import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";
import template from "./templates/sliding-banner.html";

angularAMD.directive("findLastSlide", function () {
    return {
        scope: true,

        link: function (scope) {
            if (scope.$last) {
                scope.init();
            }
        }
    };
});
angularAMD.directive("lazyImgLoader", function () {
    return {
        scope: true,

        link: function (scope, element, attrs) {
            // watch lazy-img-loader attribute, when it's empty or when it's ready unwatch it
            var unbindAttrWatch = scope.$watch(attrs.lazyImgLoader, function (response) {
                // if response not null, then do further processing
                if (response) {
                    var unbindScopeWatch = scope.$watch("id", function (current_id) {
                        // tslint:disable-next-line:triple-equals
                        if (current_id == scope.$index) {
                            attrs.$set("src", response);
                            unbindScopeWatch();
                            unbindAttrWatch();
                        }
                    });
                }
                // unwatch this attribute because img already loaded at the start
                else {
                    unbindAttrWatch();
                }
            });
        }
    };
});
angularAMD.directive("slidingBanner", [
    "$compile",
    "$rootScope",
    "$interval",
    "$timeout",
    "$window",
    "$sce",
    "baseUrlsFactory",
    "multiLanguageService",
    function ($compile, $rootScope, $interval, $timeout, $window, $sce, baseUrlsFactory, multiLanguageService) {
        return {
            restrict: "E",
            template: template,
            controller: [
                "$scope",
                "$element",
                function ($scope, $element) {
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.shared_component_base_url = baseUrlsFactory.shared_component_base_url;
                    $scope.helpIconsFolder = $sce.getTrustedResourceUrl($scope.shared_component_base_url + "builder/helpBannerTemplates/workbook/images/");

                    // Delay between slides (default 20-30 seconds)
                    $scope.delay_between_slides = 20000;

                    //                $scope.bannerData = function () {
                    //                    helpBannerFactory.getBannerSlidesData().success(function (data) {
                    //                        $scope.slides = data;
                    //                        $rootScope.banner_ready = true;
                    //                    });
                    //                }();

                    $rootScope.banner_ready = true;

                    // Cache elements need by banner
                    var banner_slide_el = $element[0].getElementsByClassName("banner-slide");
                    $scope.help_banner_toggle_btn_el = document.getElementsByClassName("help-banner-toggle-btn")[0];
                    var sliding_banner_holder_el = $element[0].querySelector(".sliding-banner-holder");
                    var wrapped_sliding_banner_holder_el = angular.element(sliding_banner_holder_el);
                    var banner_video_container_el = angular.element(document.getElementById("banner-video-container"));

                    $scope.checkWidth = function () {
                        if (
                            $scope.$parent.title === multiLanguageService.getString("page_titles.workbook_builder") ||
                            $scope.$parent.title === multiLanguageService.getString("page_titles.template_builder")
                        ) {
                            var sliding_banner_full_width = wrapped_sliding_banner_holder_el.addClass("full-width");
                        }
                    };

                    $scope.checkWidth();

                    $scope.tabToCloseButton = function (e) {
                        if (e.keyCode === 9 && e.shiftKey === false) {
                            e.preventDefault();
                            document.getElementById("helpBannerToggleBtn").focus();
                        }
                    };

                    $scope.getVideoUrl = function (videoNumber) {
                        var helpVideo = videoNumber - 1;
                        return $scope.helpData.content[helpVideo].video_source;
                    };

                    $scope.startPlayVideo = function (e) {
                        var target = e.target;
                        var parentContainer = target.parentNode.parentNode;
                        var videoApi = parentContainer.getElementsByTagName("video")[0];
                        videoApi.play();
                        target.parentNode.removeChild(target);
                    };

                    /**
                     * Get base elements to trigger banner
                     */
                    $scope.getElements = function () {
                        // if initial run then make first element as current

                        if (!sliding_banner_holder_el.querySelector(".current")) {
                            $scope.cur_el = angular.element(banner_slide_el[0]);
                            $scope.cur_el.addClass("current");
                        } else {
                            $scope.cur_el = angular.element(sliding_banner_holder_el.querySelector(".current"));
                        }

                        // if initial run then make second element as next
                        if (!sliding_banner_holder_el.querySelector(".next")) {
                            if ($scope.len >= 2) {
                                $scope.next_el = angular.element(banner_slide_el[1]);
                                $scope.next_el.addClass("next");
                            }
                        } else {
                            // Specifically looking for next within the help, previously it was looking in the whole dom and picking up an element from a date field with a next class.
                            $scope.next_el = angular.element(sliding_banner_holder_el.querySelector(".next"));
                            angular.element(banner_slide_el[$scope.id + 1]).addClass("next");
                        }

                        // if initial run then make zero element as prev
                        if (!sliding_banner_holder_el.querySelector(".prev")) {
                            $scope.prev_el = angular.element(banner_slide_el[$scope.len - 1]);
                            $scope.prev_el.addClass("prev");
                        } else {
                            $scope.prev_el = angular.element(sliding_banner_holder_el.querySelector(".prev"));
                        }
                    };

                    /**
                     * Slides to exact slide by demand
                     * @param _id
                     */
                    $scope.slideToExact = function (_id) {
                        var local = {};
                        local.id = _id;
                        local.last_id = $scope.id;

                        // stop slider from sliding after click
                        $scope.pauseSlider();

                        // update elements' variables
                        $scope.getElements();

                        // clear classes from current "next" and "prev" elements
                        $scope.next_el.removeClass("next");
                        $scope.prev_el.removeClass("prev");

                        // if slider isn't last then add to next slide class "next"
                        local._next_el.addClass("next");
                        if (local.id + 1 < $scope.len) {
                            local._next_el = angular.element(banner_slide_el[local.id + 1]);
                        } else {
                            // add class "next" to first slide
                            local._next_el = angular.element(banner_slide_el[0]);
                        }

                        // if slider isn't first then add to previous slide class "prev"
                        if (local.id - 1 >= 0) {
                            local._prev_el = angular.element(banner_slide_el[local.id - 1]);
                            local._prev_el.addClass("prev");
                        } else {
                            // add class "next" to first slide
                            local._prev_el = angular.element(banner_slide_el[$scope.len - 1]);
                            local._prev_el.addClass("prev");
                        }

                        // save clicked item as current element
                        local._cur_el = angular.element(banner_slide_el[local.id]);

                        // make previously current element as "next" or "prev" depending on
                        // which bullet point user clicked
                        if (local.id > local.last_id) {
                            local._cur_el.addClass("next");
                        } else {
                            local._cur_el.addClass("prev");
                        }
                        setTimeout(function () {
                            // remove current class from previously
                            $scope.cur_el.removeClass("current");
                            if (local.id > local.last_id) {
                                $scope.cur_el.addClass("prev");
                            } else {
                                $scope.cur_el.addClass("next");
                            }

                            local._cur_el.removeClass("next");
                            local._cur_el.removeClass("prev");

                            local._cur_el.addClass("current");
                        }, 25);
                        setTimeout(function () {
                            if (local.id > local.last_id) {
                                if (local._prev_el[0] !== $scope.cur_el[0]) {
                                    $scope.cur_el.removeClass("prev");
                                }
                            } else {
                                if (local._next_el[0] !== $scope.cur_el[0]) {
                                    $scope.cur_el.removeClass("next");
                                }
                            }
                        }, 400);

                        // start slider again
                        //$scope.startSlider();
                    };

                    /**
                     * Slides to previous slide
                     */
                    $scope.prevSlide = function () {
                        $scope.getElements();

                        // if id become maximum, then drop it to 0
                        if ($scope.id > 0) {
                            $scope.id--;
                        } else {
                            $scope.id = $scope.len - 1;
                        }

                        // make current slide as next
                        $scope.cur_el.removeClass("current");
                        $scope.cur_el.addClass("next");

                        // make previous slide as current
                        $scope.prev_el.removeClass("prev");
                        $scope.prev_el.addClass("current");

                        // find previous slide and add class to it
                        $scope.next_el.removeClass("next");
                        if (banner_slide_el[$scope.id - 1]) {
                            angular.element(banner_slide_el[$scope.id - 1]).addClass("prev");
                        } else {
                            // if you reached first slide, then previous slide will be last slide in array
                            angular.element(banner_slide_el[$scope.len - 1]).addClass("prev");
                        }
                    };

                    /**
                     * Slides to previous slide
                     */
                    $scope.nextSlide = function () {
                        $scope.getElements();

                        // if id become maximum, then drop it to 0
                        if ($scope.id < $scope.len - 1) {
                            $scope.id++;
                        } else {
                            $scope.id = 0;
                        }

                        // make current slide as previous
                        $scope.cur_el.removeClass("current");
                        $scope.cur_el.addClass("prev");

                        // make next slide as current
                        $scope.next_el.removeClass("next");
                        $scope.next_el.addClass("current");

                        // make prev slide as next
                        $scope.prev_el.removeClass("prev");
                        if (banner_slide_el[$scope.id + 1]) {
                            angular.element(banner_slide_el[$scope.id + 1]).addClass("next");
                        } else {
                            // in case you reached last slide, then next will be first in array
                            angular.element(banner_slide_el[0]).addClass("next");
                        }
                    };

                    $scope.slideToIndex = function ($event, index) {
                        if ($event.keyCode === 9 && !$event.shiftKey) {
                            if (!$scope.helpMinimised) {
                                $event.preventDefault();
                            }
                            index += 1;
                        } else if ($event.keyCode === 9 && $event.shiftKey) {
                            if (!$scope.helpMinimised) {
                                $event.preventDefault();
                            }
                            index -= 1;
                        } else {
                            return;
                        }

                        $scope.getElements();

                        // if id become maximum, then drop it to 0
                        if (index <= $scope.len - 1 && index >= 0) {
                            $scope.id = index;
                        } else {
                            $scope.help_banner_toggle_btn_el.focus();
                            return;
                        }

                        // make current slide as previous
                        $scope.cur_el.removeClass("current");

                        // make next slide as current
                        $scope.next_el.removeClass("next");

                        // make prev slide as next
                        $scope.prev_el.removeClass("prev");

                        if (banner_slide_el[$scope.id - 1]) {
                            angular.element(banner_slide_el[$scope.id - 1]).addClass("prev");
                        } else {
                            // if you reached first slide, then previous slide will be last slide in array
                            angular.element(banner_slide_el[$scope.len - 1]).addClass("prev");
                        }

                        if (banner_slide_el[$scope.id]) {
                            angular.element(banner_slide_el[$scope.id]).addClass("current");
                        }

                        if (banner_slide_el[$scope.id + 1]) {
                            angular.element(banner_slide_el[$scope.id + 1]).addClass("next");
                        } else {
                            // in case you reached last slide, then next will be first in array
                            angular.element(banner_slide_el[0]).addClass("next");
                        }

                        setTimeout(function () {
                            banner_slide_el[$scope.id].focus();
                        }, 500);
                    };

                    /**
                     * Starts slider timer that repeat animation every N seconds

                     $scope.startSlider = function () {
                // start slider ONLY if it was paused/stopped
                if (!$scope.is_slider_started && !$scope.force_slider_stop) {
                    $scope.slider_interval = $interval(function () {
                        $scope.nextSlide();
                    }, $scope.delay_between_slides);
                }

                // change current slider state to running
                $scope.is_slider_started = true;
            };*/

                    /**
                     * Pauses slider
                     * Actually, it stops the timer completely and cleans the memory
                     */
                    $scope.pauseSlider = function () {
                        $interval.cancel($scope.slider_interval);

                        // change current slider state to paused/stopper
                        $scope.is_slider_started = false;
                    };

                    $scope.bindSliderEvents = function () {
                        document.getElementById("slidingBannerPreviousBtn").addEventListener("click", $scope.prevSlide);
                        document.getElementById("slidingBannerNextBtn").addEventListener("click", $scope.nextSlide);
                    };

                    /**
                     * Initialize banner after DOM and ng-repeat are ready
                     */
                    $scope.init = function () {
                        // total number of slides in a banner
                        $scope.len = banner_slide_el.length;
                        // get initial id for first slide
                        $scope.id = 0;

                        $scope.bindSliderEvents();

                        if ($scope.len > 0) {
                            $scope.getElements();

                            // $scope.startSlider();
                        }
                    };

                    /**
                     * Stops video, removes video iframe,show slides again
                     */
                    $scope.stopVideo = function () {
                        $element.removeClass("active");
                        var all_iframe_elems = angular.element(banner_video_container_el[0].getElementsByTagName("iframe"));
                        all_iframe_elems.remove();
                        wrapped_sliding_banner_holder_el.removeClass("inactive");

                        // resume banner
                        $scope.force_slider_stop = false;
                        //$scope.startSlider();
                    };
                }
            ],

            link: function (scope) {
                /**
                 * Stops banner and removes it when application view is changed
                 */
                scope.$on("$destroy", function () {
                    scope.pauseSlider();
                });
            }
        };
    }
]);
