import { useRef } from "react";

export const useFactoryRef = <T extends (...args: Array<any>) => any>(func: T, ...args: Parameters<T>): ReturnType<T> => {
    const classRef = useRef<ReturnType<T> | null>(null);

    if (classRef.current === null) {
        classRef.current = func(...args);
    }

    return classRef.current as ReturnType<T>;
};
