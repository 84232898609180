import { IEditorDataHandler } from "./interfaces/IEditorDataHandler";

export class ElementDataHandler implements IEditorDataHandler {
    private readonly _element: HTMLElement;
    private _data: string;

    constructor(existingData: string, element: HTMLElement) {
        this._data = existingData;
        this._element = element;
    }

    public getData(): string {
        return this._data;
    }

    public setData(data: string): void {
        this._data = data;
        // eslint-disable-next-line no-unsanitized/property
        this._element.innerHTML = data;
    }

    public destroy(): void {
        /**/
    }
}
