import { ASSET_CONSTANTS } from "../constants/asset.constants";

/**
 * @param {object} dataManagerService
 * @returns {PromiseLike<{ assetId: string, manager: object }>}
 */
export function createTemplateResource(dataManagerService) {
    return dataManagerService
        .init({
            AssetId: null,
            Asset: { MainType: ASSET_CONSTANTS.TYPES.TEMPLATE_BUILDER, AddDefaultBannerSection: true }
        })
        .then(() => {
            const manager = dataManagerService.templateBuilderManagerObject;
            return { assetId: manager.AssetId, manager: manager };
        });
}
