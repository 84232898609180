import { angularAMD } from "@pebblepad/amd";
import "../../../multiLanguageService/multiLanguageService";
import "../../../extensions/arrayExtensions";
import "../../../share/services/shareService";
import "../../../modal/services/modal";
import "../../../utilities/pebbleDate";
import template from "./templates/base.html";

angularAMD.directive("sidebarShares", [
    "$http",
    "$sce",
    "$rootScope",
    "$compile",
    "$filter",
    "baseUrlsFactory",
    "shareService",
    "modal",
    function ($http, $sce, $rootScope, $compile, $filter, baseUrlsFactory, shareService, modal) {
        return {
            template: template,
            restrict: "E",
            scope: {
                asset: "=",
                nonDeletableShares: "<",
                collaboratorIds: "<"
            },
            controller: [
                "$scope",
                function ($scope) {
                    // SVG Images
                    $scope.shareToWeb = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/shareToWeb.svg");
                    $scope.shareToPeople = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/shareToPeople.svg");

                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                    $scope.confirmMessage = $scope.multiLanguageService.getString("sidebar.shares.confirm_delete");
                    $scope.shareLimit = 5;
                    $scope.disableCopy = false;
                    var shareToDelete;

                    $scope.componentLang = {
                        takeACopy: $scope.multiLanguageService.getString("sidebar.shares.take_copy"),
                        sharedWithWeb: function (shareDate) {
                            var time = $filter("pebbleDate")(shareDate, "timeOnly");
                            var date = $filter("pebbleDate")(shareDate);

                            return $scope.multiLanguageService.getString("sidebar.shares.shared_with_web", {
                                time: time,
                                date: date
                            });
                        },
                        sharedWithPersons: function (shareDate, shareCount) {
                            var time = $filter("pebbleDate")(shareDate, "timeOnly");
                            var date = $filter("pebbleDate")(shareDate);

                            return $scope.multiLanguageService.getString("sidebar.shares.shared_with_n_person", {
                                count: shareCount,
                                time: time,
                                date: date
                            });
                        }
                    };

                    $scope.getHeader = function (shares) {
                        if (!shares.length) {
                            return $scope.multiLanguageService.getString("sidebar.shares.no_shares");
                        }

                        if (shares[0].IsMyShare) {
                            return $scope.multiLanguageService.getString("sidebar.shares.shares_count", {
                                count: shares.length
                            });
                        }

                        return $scope.multiLanguageService.getString("sidebar.shares.shared_by");
                    };

                    $scope.checkForPermission = function (permissions, perms) {
                        for (var i = 0; i < perms.length; i++) {
                            if (permissions.contains(perms[i])) {
                                return true;
                            }
                        }
                        return false;
                    };

                    $scope.checkForExpiryDate = function (date) {
                        var d = new Date(date);
                        return d.getUTCFullYear() < 2100;
                    };

                    $scope.showExpiryInfo = function (date) {
                        var days = $scope.getExpiryDateDays(date);
                        return days <= 30 && days > -1;
                    };

                    $scope.showExpiryWarning = function (date) {
                        var days = $scope.getExpiryDateDays(date);
                        return days <= 30 && days > 7;
                    };

                    $scope.showExpiryDanger = function (date) {
                        var days = $scope.getExpiryDateDays(date);
                        return days <= 7 && days > -1;
                    };

                    $scope.showExpired = function (date) {
                        var days = $scope.getExpiryDateDays(date);
                        return days < 0;
                    };

                    $scope.getExpiryDateDays = function (date) {
                        var d = new Date(date);
                        var now = new Date();
                        return mydiff(now, d, "days");
                    };

                    $scope.getExpiryMessage = function (date) {
                        var days = $scope.getExpiryDateDays(date);

                        if (days > 0) {
                            return $scope.multiLanguageService.getString("sidebar.shares.days", { count: days });
                        } else {
                            return $scope.multiLanguageService.getString("sidebar.shares.today");
                        }
                    };

                    $scope.getProfileImage = function (id) {
                        return baseUrlsFactory.api_base_url + "File/TransmitProfilePic?id=" + id + "&x=32&y=32";
                    };

                    $scope.showAllShares = function () {
                        $scope.shareLimit = $scope.asset.Shares.length;
                    };

                    $scope.deleteShare = function (share) {
                        shareToDelete = share;

                        modal.newModal(
                            {
                                scope: $scope,
                                templateUrl: baseUrlsFactory.shared_component_base_url + "assetViews/sidebar/shares/templates/confirmmessage.lazy.html"
                            },
                            true
                        );
                    };

                    $scope.doDeleteShare = function () {
                        // if it is a web share it uses the AssetPublishedDto id
                        shareService.deleteShare(shareToDelete.ShareInfo.Id, !shareToDelete.ShareInfo.AllSharees.length);
                        $scope.asset.Shares.removeOne($scope.asset.Shares.indexOf(shareToDelete));
                        $scope.asset.ShareCount = $scope.asset.Shares.length;
                        shareToDelete = null;
                    };

                    $scope.editShare = function (assetId, share, collaboratorIds) {
                        var sharingScope = $scope.$new(true);
                        sharingScope.assetIds = [assetId];
                        sharingScope.collaboratorIds = collaboratorIds;
                        sharingScope.changingExisting = angular.copy(share); // Any changes can be cancelled so make it a copy so the original isn't changed
                        sharingScope.modalTitle = $scope.multiLanguageService.getString("sharing.header.edit_share");
                        sharingScope.shareType = getShareType(share.AssetReference.MainType);
                        if (share.ShareInfo.AllSharees.length) {
                            sharingScope.shareMode = "users";
                            sharingScope.modalIcon = "share-to-people";
                        } else {
                            sharingScope.shareMode = "web";
                            sharingScope.modalTitle = $scope.multiLanguageService.getString("sharing.header.edit_web_share");
                            sharingScope.modalIcon = "share-to-web";
                        }

                        modal.newModal({
                            scope: sharingScope,
                            autofocus: false,
                            templateUrl: baseUrlsFactory.shared_component_base_url + "share/templates/modal-wrapper.lazy.html"
                        });
                    };

                    function getShareType(mainType) {
                        var typeDictionary = {
                            workbook: "workbook",
                            form: "template"
                        };

                        return typeDictionary[mainType.toLowerCase()] || "asset";
                    }

                    // I would like to see this available as a toolkit function somewhere
                    function mydiff(date1, date2, interval) {
                        var second = 1000,
                            minute = second * 60,
                            hour = minute * 60,
                            day = hour * 24,
                            week = day * 7;
                        date1 = new Date(date1);
                        date2 = new Date(date2);
                        var timediff = date2 - date1;
                        if (isNaN(timediff)) {
                            return NaN;
                        }
                        switch (interval) {
                            case "years":
                                return date2.getFullYear() - date1.getFullYear();
                            case "months":
                                return date2.getFullYear() * 12 + date2.getMonth() - (date1.getFullYear() * 12 + date1.getMonth());
                            case "weeks":
                                return Math.floor(timediff / week);
                            case "days":
                                return Math.floor(timediff / day);
                            case "hours":
                                return Math.floor(timediff / hour);
                            case "minutes":
                                return Math.floor(timediff / minute);
                            case "seconds":
                                return Math.floor(timediff / second);
                            default:
                                return undefined;
                        }
                    }

                    $scope.takeACopy = function (assetId) {
                        var copyScope = $scope.$new(true);
                        $scope.assetId = assetId; //Used to reenable the take a copy button once successfully copied.
                        copyScope.assetId = assetId;

                        modal.newModal({
                            scope: copyScope,
                            templateUrl: baseUrlsFactory.shared_component_base_url + "takeACopy/templates/modal-wrapper.lazy.html"
                        });
                    };

                    $scope.$on("copySuccessful", function (event, data) {
                        // tslint:disable-next-line:triple-equals
                        if (data == $scope.assetId) {
                            $scope.disableCopy = true;

                            $scope.componentLang.takeACopy = $scope.multiLanguageService.getString("sidebar.shares.copy_successful");
                        }
                    });
                }
            ]
        };
    }
]);
