import { RefObject } from "react";
import { Placement } from "@floating-ui/dom";
import { Immutable } from "@pjs/utilities";
import { FloatingMenu } from "../FloatingMenu";
import { MenuAriaModel } from "../../menu-event-adapter/types/MenuAriaModel";
import { IMenuEventModelHandlers } from "../../menu-event-adapter/interfaces/IMenuEventModelHandlers";
import { MenuEventAdapter } from "../../menu-event-adapter/MenuEventAdapter";
import { selectClickedItemAndSetClosed } from "../../reducers/SelectClickedItemAndSetClosed.function";
import { IMenuModelAdapterConfig } from "../../menu-event-adapter/interfaces/IMenuModelAdapterConfig";
import { dropdownPositioningFactory } from "../../../../floating-positioner/factories/DropdownPositioningFactory";
import { IButtonMenuOption } from "../../../../button-menu/interfaces/IButtonMenuOption";
import { AriaMenuStatus } from "../../menu-event-adapter/enums/AriaMenuStatus";
import { menuRoleTriggerEvents } from "../../menu-event-adapter/consts/MenuRoleTriggerEvents.const";
import { menuRoleTargetEventsFactory } from "../../menu-event-adapter/factories/MenuRoleTargetEventsFactory";
import { confirmButtonMenuActionAndSetClosed } from "../../reducers/ConfirmButtonMenuActionAndSetClosed.function";

export function floatingButtonMenuFactory(config: {
    matchItemOnKeys: (item: IButtonMenuOption, currentKeys: string) => boolean;
    initialModel: Immutable<MenuAriaModel>;
    onModelChange: (model: MenuAriaModel) => void;
    triggerRef: RefObject<HTMLElement>;
    floatingRef: RefObject<HTMLElement>;
    boundaryRef: RefObject<HTMLElement>;
    placement: Placement;
}): FloatingMenu<MenuAriaModel, IMenuEventModelHandlers<IButtonMenuOption>> {
    const eventAdapterPreset: IMenuModelAdapterConfig<IButtonMenuOption> = {
        item: {
            onClick: selectClickedItemAndSetClosed
        },
        target: menuRoleTargetEventsFactory(config.matchItemOnKeys, confirmButtonMenuActionAndSetClosed),
        trigger: menuRoleTriggerEvents
    };

    const floatingMenu = new FloatingMenu<MenuAriaModel, IMenuEventModelHandlers<IButtonMenuOption>>(config.triggerRef, config.floatingRef, config.boundaryRef, {
        ariaEventAdapter: new MenuEventAdapter(eventAdapterPreset, config.triggerRef, config.floatingRef, config.initialModel),
        floatingPositionerConfig: {
            middlewareFactory: dropdownPositioningFactory,
            placement: config.placement
        },
        onFirstPosition: () => {
            if (config.floatingRef.current !== null) {
                config.floatingRef.current.focus();
            }
        },
        onModelChange: config.onModelChange
    });

    floatingMenu.addReaction(AriaMenuStatus.ClosedByKeyboardEvent, () => {
        if (config.triggerRef.current !== null) {
            config.triggerRef.current.focus();
        }
    });

    return floatingMenu;
}
