import { angularAMD } from "@pebblepad/amd";
import "../../../autoFocus/autoFocus.directive";
import template from "../views/spa-action-item.html";

angularAMD.directive("spaActionItem", SpaActionItemDirective);
SpaActionItemDirective.$inject = [];

angularAMD.controller("SpaActionItemController", SpaActionItemController);
SpaActionItemController.$inject = ["baseUrlsFactory"];

function SpaActionItemDirective() {
    return {
        template: template,
        restrict: "E",
        scope: {
            menuItem: "=",
            onItemClick: "&",
            focus: "@"
        },
        controller: "SpaActionItemController",
        controllerAs: "vm",
        bindToController: true
    };
}

function SpaActionItemController(baseUrlsFactory) {
    this.innerContentTemplate = baseUrlsFactory.shared_component_base_url + "spaMenu/actionMenu/views/spa-action-inner-content.lazy.html";
}

SpaActionItemController.prototype.itemClick = function () {
    this.onItemClick({ item: this.menuItem });
};
