import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/urlService";
import template from "./templates/navigation-list.html";

angularAMD.directive("navigationList", [
    "$routeParams",
    "$location",
    "multiLanguageService",
    "urlService",
    function ($routeParams, $location, multiLanguageService, urlService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                list: "=?",
                assetId: "=?"
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.presetData = function () {
                        $scope.parentType = ["WorkBook", "WorkBookResponse"];
                        $scope.multiLanguageService = multiLanguageService;
                        $scope.assetId = $scope.assetId || $routeParams.assetId;
                    };

                    $scope.bindEvents = function () {
                        $scope.$watch("list", function () {
                            $scope.updateProcessedDataList();
                        });
                    };

                    $scope.removeAllItemsWithChildren = function (dataList, propName, propValue, expandChildren) {
                        const pagesArr = [];
                        const length = dataList.Children.length;

                        if (length > 0) {
                            for (let i = 0; i < length; i++) {
                                if (propValue.indexOf(dataList.Children[i][propName]) === -1) {
                                    dataList.Children[i]["isChild"] = true;
                                    pagesArr.push(dataList.Children[i]);
                                }
                            }
                        }

                        dataList.Children = pagesArr;

                        if (expandChildren) {
                            pagesArr.unshift(dataList);
                            return pagesArr;
                        }

                        return [dataList];
                    };

                    /**
                     * Gets navigation array and returns array ready for rendering in page release or page tracking.
                     * @param dataList
                     * @param opts
                     * @param opts.propName
                     * @param opts.propValue
                     * @param opts.initialLevel
                     * @param opts.expandChildren If true, then array will contain all children on the same level with it's parent.
                     * @returns {Array}
                     */
                    $scope.getProcessedDataList = function (dataList, opts) {
                        opts = opts || {};
                        const propName = opts.propName;
                        const propValue = opts.propValue;
                        const expandChildren = opts.expandChildren;
                        const initialLevel = opts.initialLevel ?? false;
                        const list = [];

                        for (let i = 0, len = dataList.length; i < len; i++) {
                            let arrayItem = dataList[i];

                            if (propValue.indexOf(arrayItem[propName]) > -1) {
                                const cachedArrayItem = angular.copy(arrayItem);
                                arrayItem = $scope.removeAllItemsWithChildren(arrayItem, propName, propValue, expandChildren);
                                Array.prototype.push.apply(list, arrayItem);
                                Array.prototype.push.apply(
                                    list,
                                    $scope.getProcessedDataList(cachedArrayItem.Children, {
                                        propName: propName,
                                        propValue: propValue,
                                        expandChildren: expandChildren
                                    })
                                );
                            } else {
                                if (initialLevel) {
                                    list.push(arrayItem);
                                }
                            }
                        }

                        return list;
                    };

                    $scope.isParentType = function (type) {
                        return $scope.parentType.indexOf(type) > -1;
                    };

                    $scope.getItemLink = function (item) {
                        if (!item.ProgressTrackingMainType) {
                            item.ProgressTrackingMainType = "WorkBookResponse";
                        }

                        const existingParams = $location.search();
                        const newQueryString = new URLSearchParams(existingParams);

                        let url;
                        switch (item.ProgressTrackingMainType) {
                            case "AssetCollectionWrapper":
                            case "FormResponse":
                                url = urlService.createBaseUrl(item.PageId, item.ProgressTrackingMainType, true, false, $routeParams.timeslice, $routeParams.submissionId);
                                break;
                            default:
                                url = urlService.createBaseUrl(
                                    $scope.assetId,
                                    item.ProgressTrackingMainType,
                                    $routeParams.submissionId !== undefined,
                                    false,
                                    $routeParams.timeslice,
                                    $routeParams.submissionId
                                );

                                newQueryString.set("pageId", item.PageId);
                                break;
                        }

                        url = `#${url}?${newQueryString.toString()}`;

                        return url || "#/";
                    };

                    $scope.updateProcessedDataList = function (dataList) {
                        dataList = angular.copy(dataList || $scope.list || []);
                        $scope.processedList = $scope.getProcessedDataList(dataList, {
                            propName: "PageType",
                            propValue: $scope.parentType,
                            initialLevel: true,
                            expandChildren: true
                        });
                    };

                    $scope.init = function () {
                        $scope.presetData();
                        $element.addClass("navigation-list--link");
                        $scope.bindEvents();
                    };

                    $scope.init();
                }
            ]
        };
    }
]);
