"use strict";
import { angularAMD } from "@pebblepad/amd";

var puiAccordionComponentDefinition = {
    bindings: {
        multiple: "<",
        defaultClosed: "<"
    },
    transclude: true,
    template: '<dl class="pui-accordion" role="presentation">\
                        <ng-transclude/>\
                    </dl>',
    controller: PuiAccordionComponent
};

PuiAccordionComponent.$inject = ["$element"];

function PuiAccordionComponent($element) {
    this._$element = $element;

    this._accordions = [];

    $element[0].addEventListener(
        "keydown",
        function (event) {
            if (event.target.classList.contains("pui-accordion__trigger")) {
                this._handleArrowKeys(event);
            }
            this._handleHomeEndKeys(event);
        }.bind(this)
    );
}

PuiAccordionComponent.prototype._handleArrowKeys = function (event) {
    var arrowPos = 0;
    if (event.key === "ArrowUp") {
        arrowPos = this._findButtonPosition(event.target);

        if (this._accordions[arrowPos - 1] !== undefined) {
            this._accordions[arrowPos - 1].panelManager.getButton().focus();
        }
        event.preventDefault();
        event.stopImmediatePropagation();
    } else if (event.key === "ArrowDown") {
        arrowPos = this._findButtonPosition(event.target);
        if (this._accordions[arrowPos + 1] !== undefined) {
            this._accordions[arrowPos + 1].panelManager.getButton().focus();
        }
        event.preventDefault();
        event.stopImmediatePropagation();
    }
};

PuiAccordionComponent.prototype._handleHomeEndKeys = function (event) {
    if (event.key === "Home") {
        this._accordions[0].panelManager.getButton().focus();
        event.preventDefault();
    }
    if (event.key === "End") {
        this._accordions[this._accordions.length - 1].panelManager.getButton().focus();
        event.preventDefault();
    }
};

PuiAccordionComponent.prototype._findButtonPosition = function (target) {
    var buttonElement = null;
    for (var i = 0, ii = this._accordions.length; i < ii; i++) {
        buttonElement = this._accordions[i].panelManager.getButton();
        if (buttonElement === target || target.contains(buttonElement)) {
            return i;
        }
    }
    throw new Error("Could not find Accordion button position");
};

PuiAccordionComponent.prototype._getTriggers = function () {
    return this._$element[0].querySelectorAll("dl > ng-transclude > pui-accordion-panel:not(> pui-accordion)");
};

PuiAccordionComponent.prototype.setPanelManager = function (id, panelManager) {
    this._accordions.push({
        id: id,
        panelManager: panelManager
    });

    if (!this.defaultClosed && this._accordions.length === 1) {
        panelManager.setExpanded(true);
    }
};

PuiAccordionComponent.prototype.toggleExpanded = function (id) {
    var newState = false;

    for (var i = 0, ii = this._accordions.length; i < ii; i++) {
        if (id !== this._accordions[i].id) {
            if (!this.multiple) {
                this._accordions[i].panelManager.setExpanded(false);
            }
        } else {
            newState = !this._accordions[i].panelManager.getExpanded();
            this._accordions[i].panelManager.setExpanded(newState);
        }
    }
    return newState;
};

PuiAccordionComponent.prototype.removePanelManager = function (id, panelManager) {
    for (var i = 0, ii = this._accordions.length; i < ii; i++) {
        if (id === this._accordions[i].id) {
            this._accordions.splice(i, 1);
            return;
        }
    }
};

if (angularAMD.default !== null) {
    angularAMD.component("puiAccordion", puiAccordionComponentDefinition);
}

export { puiAccordionComponentDefinition };
