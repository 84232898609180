import { angularAMD } from "@pebblepad/amd";
import { PANEL_CONSTANTS } from "../../constants/panel.constants";
import { IWantToOption } from "../../iWantTo/directives/iWantToOption";
import { STORE_CONSTANTS } from "../../constants/store.constants";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import { filterIcon } from "@pjs/core-ui";
import { tracker } from "@pjs/analytics";
import { favouritesObserver } from "../../observer/favourites/FavouritesObserver.const";
import { launchDeleteDialog } from "../../assetActionDialogs/launchDeleteDialog";
import { launchRestoreDialog } from "../../assetActionDialogs/launchRestoreDialog";
import { launchPurgeDialog } from "../../assetActionDialogs/launchPurgeDialog";
import "../../extensions/arrayExtensions";
import "./assetInfo/assetInfo";
import "../../assetStore/services/assetStoreService";
import "../../assetViews/sidebar/mapping/sidebarMappingWrapper.component";
import "../../assetViews/sidebar/mapping/sidebarMapping";
import "../../builder/progressTracking/assetProgressTracking/assetProgressTracking";
import "./assetComments/assetComments";
import "../../versioning/versionList/versionList.directive";
import "../../versioning/services/versionHelper.service";
import "../../utilities/assetActionService";
import "../../modal/services/modal";
import "../../utilities/helpers";
import "../../builder/dataManager/dataManagerService";
import "../../assetStore/services/assetDtoHelperFactory";
import "../../builder/progressTracking/assetProgressTracking/services/assetProgressTrackingService";
import "../services/pageReloadService";
import "../../utilities/urlService";
import "../../assetLocking/services/assetLockingHelper.service";
import "./assetLockedMessage/assetLockedMessage.directive";
import "../../builder/dataManager/dtoFactory";
import "../../user/user.service";
import "../../multiLanguageService/multiLanguageService";
import "../../recoveryLogger/recoveryLogger";
import "../../feedbackListWrapper/feedbackListWrapper.component";
import "../../workbook/service/standards.service";
import "../../stickyElementCoordinator/stickyContainer.directive";
import template from "./templates/asset-info-panel.html";

angularAMD.directive("assetInfoPanel", [
    "$sce",
    "$rootScope",
    "$compile",
    "$location",
    "$routeParams",
    "baseUrlsFactory",
    "$timeout",
    "$q",
    "assetStoreService",
    "assetActionService",
    "modal",
    "helpers",
    "VersionHelper",
    "dataManagerService",
    "StandardsService",
    "assetDtoHelperFactory",
    "assetProgressTrackingService",
    "pageReloadService",
    "urlService",
    "AssetLockingHelper",
    "dtoFactory",
    "User",
    "multiLanguageService",
    "recoveryLogger",
    function (
        $sce,
        $rootScope,
        $compile,
        $location,
        $routeParams,
        baseUrlsFactory,
        $timeout,
        $q,
        assetStoreService,
        assetActionService,
        modal,
        helpers,
        VersionHelper,
        dataManagerService,
        StandardsService,
        assetDtoHelperFactory,
        assetProgressTrackingService,
        pageReloadService,
        urlService,
        AssetLockingHelper,
        dtoFactory,
        User,
        multiLanguageService,
        recoveryLogger
    ) {
        return {
            template: template,
            restrict: "E",

            //Controller for more logic / data oriented things, link for more element stuff, events and post-compilation calls.
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.showIWantTo = false;
                    $scope.asset = {};
                    $scope.isAssetType = false;
                    $scope.hasLoaded = false;
                    $scope.isPublic = $location.$$url.indexOf("public") > -1; // need a better way to detect this
                    $scope.assetDtoHelper = new assetDtoHelperFactory();
                    $scope.isEditorPage = false;
                    $scope.excludeFunctionalityList = [];
                    $scope.extendedIWantToOptions = [];
                    $scope.viewerActions = $rootScope.viewerActions;
                    $scope.hasIWantToOptionsLoaded = false;

                    if ($scope.panelActionData && $scope.panelActionData.excludeAssetFunctionality) {
                        $scope.excludeFunctionalityList = $scope.excludeFunctionalityList.concat($scope.panelActionData.excludeAssetFunctionality);
                    }

                    if ($scope.viewerActions) {
                        if ($scope.viewerActions.infoPanel) {
                            for (var key in $scope.viewerActions.infoPanel) {
                                if ($scope.viewerActions.infoPanel.hasOwnProperty(key)) {
                                    if (!$scope.viewerActions.infoPanel[key]) {
                                        $scope.excludeFunctionalityList.push(key);
                                    }
                                }
                            }
                        }

                        if (!$scope.viewerActions.iWantTo) {
                            $scope.excludeFunctionalityList.push("i-want-to");
                        }
                    }

                    var assetActionHandler = new assetActionService();
                    $scope.getAssetDetails = function (callback) {
                        if (typeof $scope.assetId === "undefined") {
                            if ($routeParams.pageId) {
                                $scope.assetId = $routeParams.pageId;
                            } else {
                                $scope.assetId = $routeParams.assetId;
                            }
                        }

                        if (!$scope.isPublic) {
                            var assetPromise = assetStoreService.getAssetWithAvailableActions($scope.assetId);
                            assetPromise.then(
                                function (response) {
                                    if (response.data) {
                                        $scope.populateAssetData(response.data);

                                        if (isWorkflow(response.data.AssetOptions)) {
                                            $scope.excludeFunctionalityList.push("i-want-to");
                                        }

                                        if (callback) {
                                            callback();
                                        }
                                    }
                                },
                                function (error) {
                                    console.warn("Asset load error:");
                                }
                            );

                            return assetPromise;
                        } else {
                            // public asset
                            // exclude I want to functionality for public use
                            if (!$scope.panelActionData) {
                                $scope.panelActionData = {};
                            }
                            if (!$scope.panelActionData.excludeAssetFunctionality) {
                                $scope.panelActionData.excludeAssetFunctionality = [];
                            }
                            if (!$scope.panelActionData.excludeAssetFunctionality.contains("i-want-to")) {
                                $scope.panelActionData.excludeAssetFunctionality.push("i-want-to");
                            }

                            var publicPromise = assetStoreService.getPublicAssetReference($scope.assetId);
                            publicPromise.then(
                                function (response) {
                                    if (response.data) {
                                        $scope.populateAssetDataWithAssetRef(response.data);

                                        if (callback) {
                                            callback();
                                        }
                                    }
                                },
                                function (error) {
                                    console.warn("Asset load error:");
                                }
                            );
                            return publicPromise;
                        }
                    };

                    function isWorkflow(assetOptions) {
                        // is workflow?
                        if (assetOptions) {
                            for (var i = 0; i < assetOptions.length; i++) {
                                if (assetOptions[i].Key === "isWorkflow" && assetOptions[i].Value === "true") {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }

                    $scope.getSubmissionsWithAssessors = function (submissions, assessors) {
                        if (!helpers.isNotEmptyArray(submissions) && !helpers.isNotEmptyArray(assessors)) {
                            return;
                        }

                        var assessorSubmissionList = [];
                        var assessorList = assessors;
                        var externals = [];
                        var assessorSubmission = null;

                        //For each assessor, remove the assessor from the list where the Submission Id is a match for the current Submission
                        var filterFoundAssessors = function (assessor) {
                            var foundMatch = assessor.SubmissionId === assessorSubmission.Id;
                            if (foundMatch) {
                                externals.push(assessor);
                            }

                            return !foundMatch;
                        };

                        for (var i = 0; i < submissions.length; i++) {
                            externals = [];
                            assessorSubmission = new dtoFactory.AssessorSubmissionDto(submissions[i]);

                            assessorList = assessorList.filter(filterFoundAssessors);

                            assessorSubmission.ExternalAssessors = externals;
                            assessorSubmissionList.push(assessorSubmission);
                        }
                        return assessorSubmissionList;
                    };

                    $scope.populateAssetDataWithAssetRef = function (reference) {
                        var asset = $scope.asset;

                        asset.Title = reference.Title;
                        asset.Id = reference.Id;
                        asset.CommentCount = reference.CommentCount;
                        asset.Description = reference.Description;
                        asset.FeedbackCount = reference.FeedbackCount;
                        asset.PublishCount = reference.PublishCount;
                        asset.ShareCount = reference.ShareCount;
                        asset.SubmissionCount = reference.SubmissionCount;
                        asset.Shares = reference.Shares || [];
                        asset.IsComplete = reference.IsComplete;
                        asset.Tags = reference.Tags;
                        asset.ThumbnailUrl = reference.ThumbnailUrl + "&ticks=" + Math.random();
                        asset.FullyLoaded = true;
                        asset.MainType = reference.MainType;
                        asset.SubType = reference.SubType;

                        $scope.excludeOptions = ["Tag"];
                    };

                    $scope.populateAssetData = function (fullAsset) {
                        var asset = $scope.asset;

                        asset.Title = fullAsset.Title;
                        asset.Id = fullAsset.Id;
                        asset.CommentCount = fullAsset.CommentCount;
                        asset.Description = fullAsset.Description;
                        asset.FeedbackCount = fullAsset.FeedbackCount;
                        asset.PublishCount = fullAsset.PublishCount;
                        asset.ShareCount = fullAsset.ShareCount;
                        asset.SubmissionCount = fullAsset.SubmissionCount;
                        asset.Shares = fullAsset.Shares || [];
                        asset.Submissions = $scope.getSubmissionsWithAssessors(fullAsset.Submissions, fullAsset.ExternalAssessors);
                        asset.IsComplete = fullAsset.IsComplete;
                        asset.Tags = fullAsset.Tags;
                        asset.ThumbnailUrl = fullAsset.ThumbnailUrl + "&ticks=" + Math.random();
                        asset.FullyLoaded = true;
                        asset.MainType = fullAsset.MainType;
                        asset.SubType = fullAsset.SubType;
                        asset.AssetOptions = fullAsset.AssetOptions;
                        asset.AvailableActions = fullAsset.AvailableActions;
                        asset.LockedBy = fullAsset.LockedBy;
                        asset.IsStandardsWorkBook = fullAsset.IsStandardsWorkBook;
                        asset.CreatedBy = fullAsset.CreatedBy;

                        $scope.isLocked = helpers.isNotEmptyArray(asset.LockedBy);
                        $scope.excludeOptions = ["Tag"];

                        if ($routeParams.assetId === $scope.assetId) {
                            $scope.excludeOptions.push("View");
                            $scope.excludeOptions.push("Purge");
                            $scope.excludeOptions.push("Delete");
                        }

                        if (asset.MainType !== ASSET_CONSTANTS.TYPES.FORM && asset.MainType !== ASSET_CONSTANTS.TYPES.WORKBOOK) {
                            $scope.excludeOptions.push("Edit");
                        }

                        if (asset.MainType === ASSET_CONSTANTS.TYPES.FILE) {
                            $scope.excludeOptions.push("Export");
                        }
                    };

                    var confirmModal = function () {
                        const onDialogClose = function (instance) {
                            instance.destroyObservable.subscribe(() => {
                                $scope.confirmAssetAction();
                                $scope.$applyAsync();
                            });
                        };

                        const trigger = document.querySelector("asset-info-panel [data-hook='iWantTo-btn']");

                        if ($scope.assetActionName === "delete") {
                            launchDeleteDialog(multiLanguageService, onDialogClose, 1, $scope.has_linked_assets, trigger);
                        } else if ($scope.assetActionName === "restore") {
                            launchRestoreDialog(multiLanguageService, onDialogClose, 1, trigger);
                        } else {
                            launchPurgeDialog(multiLanguageService, onDialogClose, 1, $scope.has_linked_assets, trigger);
                        }
                    };

                    var postAction = function (ids) {
                        $rootScope.$broadcast("removeFromSearchResultsEvent", { removedArray: ids });
                        $scope.$emit("closeRightHandSidePanel");
                    };

                    assetActionHandler.addAction("deleteAssets", function (ids) {
                        $scope.assetActionName = "delete";
                        $scope.confirmAssetAction = function () {
                            assetStoreService.deleteAssets(ids).then((response) => postAction(response.config.data));
                        };
                        confirmModal();
                    });

                    assetActionHandler.addAction("purgeAssets", function (ids) {
                        $scope.assetActionName = "purge";
                        $scope.confirmAssetAction = function () {
                            assetStoreService.purgeAssets(ids).then((response) => postAction(response.config.data.assetIds));
                        };
                        confirmModal();
                    });

                    assetActionHandler.addAction("restoreAssets", function (ids) {
                        $scope.assetActionName = "restore";
                        $scope.confirmAssetAction = function () {
                            assetStoreService.restoreAssets(ids).then((response) => postAction(response.config.data));
                        };
                        confirmModal();
                    });

                    assetActionHandler.addAction("unfavouriteAssets", function (ids) {
                        assetStoreService
                            .favouriteAsset(ids, false)
                            .then(() => {
                                favouritesObserver.remove(ids);
                                tracker.trackEvent("Favourite", "Remove", "Unfavourite from info panel");
                            })
                            .catch(angular.noop);
                    });

                    assetActionHandler.addAction("favouriteAssets", function (ids) {
                        assetStoreService
                            .favouriteAsset(ids, true)
                            .then(() => {
                                favouritesObserver.add(ids);
                                tracker.trackEvent("Favourite", "Add", "Favourite from info panel");
                            })
                            .catch(angular.noop);
                    });

                    assetActionHandler.addAction("editAssets", function (ids) {
                        //Broadcast to searchresult so we can open
                        $rootScope.$broadcast("editResource", ids[0]);
                    });

                    assetActionHandler.addAction("evidenceFilter", () => {
                        $rootScope.$broadcast("openRightHandSidePanel", { panel: PANEL_CONSTANTS.PANEL.ASSET_FILTERING_PANEL, assetId: $scope.assetId });
                    });

                    $scope.addFilterButton = function (assetId) {
                        if ($scope.isPublic) {
                            return;
                        }
                        StandardsService.canFilterEvidence(assetId, false)
                            .then((canFilterEvidence) => {
                                if (canFilterEvidence) {
                                    $scope.extendedIWantToOptions.push(
                                        new IWantToOption(
                                            "EvidenceFilter",
                                            multiLanguageService.getString("buttons.iWantToHandler.evidence_filter"),
                                            multiLanguageService.getString("buttons.i_want_to_extended_labels.evidence_filter"),
                                            "evidenceFilter",
                                            86,
                                            filterIcon
                                        )
                                    );
                                }
                            })
                            .catch(angular.noop)
                            .finally(() => {
                                $scope.hasIWantToOptionsLoaded = true;
                            });
                    };

                    $scope.setupIWantTo = function () {
                        var type =
                            $scope.asset.MainType === ASSET_CONSTANTS.TYPES.FORM || $scope.asset.MainType === ASSET_CONSTANTS.TYPES.WORKBOOK
                                ? STORE_CONSTANTS.STORE_TYPE.RESOURCE
                                : STORE_CONSTANTS.STORE_TYPE.ASSET;
                        $scope.$on("executeIWantToOption", function (event, iWantTo_event) {
                            $scope.has_linked_assets = iWantTo_event.has_linked_assets;
                            assetActionHandler.invoke(iWantTo_event.broadcast_name, iWantTo_event.for_ids, {
                                type: type,
                                scope: $scope
                            });
                            event.stopPropagation();
                        });
                    };

                    var notifyOnUpdate = function () {
                        pageReloadService.notifyOnUpdate();
                    };

                    // Check if a lock is needed
                    var isLockRequired = function () {
                        return !$scope.isEditorPage && !urlService.isPublic($location.url()) && $scope.asset.AvailableActions.indexOf("Edit") !== -1 && $scope.viewerActions?.edit !== false;
                    };

                    $scope.setupLock = function () {
                        // Check if a lock is required
                        $scope.lockRequired = isLockRequired();
                        if ($scope.lockRequired) {
                            if (!recoveryLogger.isInProgress()) {
                                recoveryLogger.start($scope.asset.MainType, "Info panel init");
                            }
                            recoveryLogger.step("lock", `Locking via info panel`);
                            return AssetLockingHelper.setupLock($scope.asset.Id, $scope.asset.MainType, "").catch(function (lockedInfo) {
                                $scope.lockedInfo = lockedInfo;
                            });
                        } else {
                            return $q.when();
                        }
                    };

                    $scope.$on("tagRemoved", notifyOnUpdate);
                    $scope.$on("tagAdded", notifyOnUpdate);
                    $scope.$on("infoPanelLocalDataUpdate", notifyOnUpdate);
                }
            ],

            link: function (scope, element, attrs) {
                scope.type = attrs.type;
                scope.assetFor = [scope.assetId];
                scope.iWantToString = "i-want-to";
                scope.assetInfoString = PANEL_CONSTANTS.PANEL.ASSET_INFO;
                scope.assetCommentsString = PANEL_CONSTANTS.PANEL.ASSET_COMMENTS;
                scope.assetFeedbackString = PANEL_CONSTANTS.PANEL.ASSET_FEEDBACK;
                scope.assetMappingString = PANEL_CONSTANTS.PANEL.ASSET_MAPPING;
                scope.assetVersioningString = PANEL_CONSTANTS.PANEL.ASSET_VERSIONING;
                scope.assetProgressTrackingString = PANEL_CONSTANTS.PANEL.ASSET_PROGRESS_TRACKING;

                /**
                 * Cache all elements used by directive
                 */
                scope.directive_wrapper_el = angular.element(element[0].getElementsByClassName("directive-holder")[0]);
                scope.cacheElems = function () {
                    scope.asset_info_btn_el = angular.element(element[0].getElementsByClassName("asset-info-btn")[0]);
                    scope.asset_comments_btn_el = angular.element(element[0].getElementsByClassName("asset-comments-btn")[0]);
                    scope.asset_feedback_btn_el = angular.element(element[0].getElementsByClassName("asset-feedback-btn")[0]);
                    scope.asset_mapping_btn_el = angular.element(element[0].getElementsByClassName("asset-mapping-btn")[0]);
                    scope.asset_progress_tracking_btn_el = angular.element(element[0].getElementsByClassName("asset-progress-tracking-btn")[0]);
                    scope.asset_version_btn_el = angular.element(element[0].getElementsByClassName("asset-versioning-btn")[0]);
                };

                scope.setFocusOnCorrectTab = function () {
                    element.addClass("no-transition");
                    setTimeout(function () {
                        scope.cacheElems(); // after compile);
                        switch (scope.type) {
                            case PANEL_CONSTANTS.PANEL.ASSET_INFO:
                                scope.asset_info_btn_el[0].focus();
                                break;
                            case PANEL_CONSTANTS.PANEL.ASSET_COMMENTS:
                                scope.asset_comments_btn_el[0].focus();
                                break;
                            case PANEL_CONSTANTS.PANEL.ASSET_FEEDBACK:
                                scope.asset_feedback_btn_el[0].focus();
                                break;
                            case PANEL_CONSTANTS.PANEL.ASSET_PROGRESS_TRACKING:
                                scope.asset_progress_tracking_btn_el[0].focus();
                                break;
                            case PANEL_CONSTANTS.PANEL.ASSET_VERSIONING:
                                scope.asset_version_btn_el[0].focus();
                                break;
                            case PANEL_CONSTANTS.PANEL.ASSET_MAPPING:
                                scope.asset_mapping_btn_el[0].focus();
                                break;
                        }
                    }, 0);
                };

                scope.switchType = function (new_type) {
                    if (new_type !== scope.type) {
                        scope.type = new_type;
                        scope.compileBody();
                    }
                };

                scope.compileBody = function () {
                    var html = "";
                    var new_scope = scope.$new(true);
                    new_scope.asset = scope.asset;
                    new_scope.assetId = scope.assetId;
                    new_scope.anchorId = scope.anchorId;
                    new_scope.pageId = scope.pageId;
                    new_scope.isAssetType = scope.isAssetType;
                    new_scope.lockedInfo = scope.lockedInfo;
                    new_scope.isLocked = scope.isLocked;
                    new_scope.submissionId = scope.submissionId;

                    var panelActionData = scope.panelActionData;
                    new_scope.showPreview = true;
                    if (panelActionData) {
                        if (panelActionData.jumpToSection) {
                            new_scope.jumpTo = {
                                scroll_el: element[0].getElementsByClassName("scroll-wrapper")[0],
                                class: panelActionData.jumpToSection
                            };
                        }

                        if (panelActionData.elementToFocus) {
                            new_scope.elementToFocus = {
                                class: panelActionData.elementToFocus
                            };
                        }

                        if (typeof panelActionData.preview !== "undefined") {
                            new_scope.showPreview = panelActionData.preview;
                        }

                        new_scope.showTagger = true;
                        if (panelActionData.excludeAssetFunctionality) {
                            new_scope.showTagger = !panelActionData.excludeAssetFunctionality.contains("tagger");
                        }
                    }

                    switch (scope.type) {
                        case PANEL_CONSTANTS.PANEL.ASSET_INFO:
                            html = '<asset-info class="asset-info"></asset-info>';
                            break;
                        case PANEL_CONSTANTS.PANEL.ASSET_COMMENTS:
                            html = '<asset-comments anchor-id="anchorId" asset-id="assetId" asset="asset" submission-id="submissionId"></asset-comments>';
                            break;
                        case PANEL_CONSTANTS.PANEL.ASSET_FEEDBACK:
                            html = '<feedback-list-wrapper asset-id="assetId" anchor-id="anchorId"></feedback-list-wrapper>';
                            break;
                        case PANEL_CONSTANTS.PANEL.ASSET_PROGRESS_TRACKING:
                            html = '<asset-progress-tracking asset-id="' + scope.asset.Id + '"></asset-progress-tracking>';
                            break;
                        case PANEL_CONSTANTS.PANEL.ASSET_VERSIONING:
                            html = '<version-list asset-id="' + scope.asset.Id + '"></version-list>';
                            break;
                        case PANEL_CONSTANTS.PANEL.ASSET_MAPPING:
                            let userCanViewMappings = !scope.isPublic;

                            if (userCanViewMappings && scope.asset.ShareCount > 0) {
                                // IsMyShare is being checked to see if a collaborative share has been accepted.
                                userCanViewMappings = scope.asset.Shares.some((share) => scope.asset.CreatedBy === User.getId() || (share.ShareInfo.Collaborative && share.IsMyShare));
                            }

                            html = '<sidebar-mapping-wrapper asset-id="' + scope.asset.Id + '" user-can-view-mappings="' + userCanViewMappings + '"></sidebar-mapping-wrapper>';
                            break;
                    }

                    var compiledHtml = $compile(html)(new_scope);
                    compiledHtml.on("$destroy", function () {
                        new_scope.$destroy();
                        new_scope = null;
                    });

                    scope.directive_wrapper_el.empty();
                    // tslint:disable-next-line:no-unsafe-jq-lite
                    scope.directive_wrapper_el.append(compiledHtml);

                    if (scope.type === PANEL_CONSTANTS.PANEL.ASSET_FEEDBACK || scope.type === PANEL_CONSTANTS.PANEL.ASSET_MAPPING) {
                        scope.hasLoaded = true;
                        scope.setFocusOnCorrectTab();
                    }
                };

                scope.getShowProgressTracking = function () {
                    assetProgressTrackingService
                        .getProgressData(scope.assetId)
                        .then(
                            function () {
                                scope.showProgressTracking = true;
                            },
                            function () {
                                scope.showProgressTracking = false;
                            }
                        )
                        .finally(function () {
                            if (assetProgressTrackingService.isPublic(true)) {
                                scope.showProgressTracking = false;
                            }
                        });
                };
                scope.getShowProgressTracking();

                scope.showFunctionality = function (functionality) {
                    return !scope.excludeFunctionalityList.contains(functionality);
                };

                scope.$on("shareSuccessful", function (event, assetIds) {
                    if (assetIds && assetIds.contains(scope.assetId)) {
                        scope.hasLoaded = false;
                        scope.getAssetDetails().then(scope.compileBody);
                    }
                });

                scope.$on("infoPanelContentLoaded", function (event, hasLoaded, applyFocus) {
                    scope.hasLoaded = hasLoaded;
                    if (applyFocus) {
                        scope.setFocusOnCorrectTab();
                    }
                });

                scope.$on("infoPanelLocalDataUpdate", function (event, property, value) {
                    event.stopPropagation();

                    var localStore = dataManagerService.dtos[scope.assetId];
                    if (localStore && localStore.data) {
                        scope.assetDtoHelper.updateModel(property, value, localStore.data, false, scope.assetId);
                    }
                });

                element.on("$destroy", function () {
                    if (scope.lockRequired) {
                        var activeLock = AssetLockingHelper.getActiveLockById(scope.asset.Id);
                        if (activeLock) {
                            activeLock.cancelAndUnlock();
                        }
                    }

                    scope.$destroy();
                });

                /**
                 * Auto-run on start
                 */
                scope.init = (function () {
                    scope.addFilterButton(scope.assetId);
                    $timeout(scope.cacheElems, 0);
                    scope.getAssetDetails(function () {
                        //Determine if item is asset or resource.
                        assetStoreService
                            .isAsset(scope.assetId, scope.asset.MainType)
                            .then(function (response) {
                                scope.isAssetType = response.data;
                                if (!scope.isAssetType || scope.isPublic) {
                                    scope.excludeFunctionalityList.push(scope.assetFeedbackString);
                                }

                                const showVersioning = VersionHelper.isSupportedByAssetType(scope.asset.MainType, scope.asset.SubType) && User.isVersioningEnabled() && !scope.isPublic;
                                if (!showVersioning || scope.anchorId !== undefined) {
                                    scope.excludeFunctionalityList.push(scope.assetVersioningString);
                                }

                                const userDto = User.getDto();
                                if (
                                    !scope.isAssetType ||
                                    scope.asset.IsStandardsWorkBook ||
                                    userDto === null ||
                                    scope.anchorId !== undefined ||
                                    (!userDto.EnableMapMyLearning && !userDto.EnableStandardsWorkbooks)
                                ) {
                                    scope.excludeFunctionalityList.push(scope.assetMappingString);
                                }

                                if (scope.anchorId !== undefined) {
                                    scope.excludeFunctionalityList.push(scope.assetInfoString);
                                }

                                scope.isEditorPage = urlService.checkIfOnBuilderForAsset(scope.asset.Id, scope.asset.MainType);
                                scope.pageId = scope.isEditorPage ? $routeParams.pageId : "";
                            })
                            .then(scope.setupLock)
                            .then(scope.compileBody)
                            .then(scope.setupIWantTo);
                    });
                })();
            }
        };
    }
]);
