import { Fragment, FunctionComponent, MouseEvent, PropsWithChildren, ReactElement } from "react";
import { Button } from "../button/Button.component";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner.component";
import { IBusyButtonProps } from "./interfaces/IBusyButtonProps";
import "./styles/busy-button.css";

const preventClick = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
};

export const BusyButton: FunctionComponent<PropsWithChildren<IBusyButtonProps>> = (props) => {
    const isDisabled = props.disabled === true || props.isBusy;
    const busyClass = props.isBusy ? "cui-busy-button--busy" : "";
    const onClick = isDisabled ? preventClick : props.onClick;
    let buttonContent: ReactElement;

    if (props.isBusy) {
        buttonContent = (
            <Fragment>
                <span className="cui-busy-button__loading-spinner">
                    <LoadingSpinner size="100%" colour={props.spinnerColour} />
                </span>
                <span className="cui-busy-button__hidden-child" data-hook="busy-button-children-invisible">
                    {props.children}
                </span>
            </Fragment>
        );
    } else {
        buttonContent = <span data-hook="busy-button-children-visible">{props.children}</span>;
    }

    return (
        <div data-hook="busy-button-wrapper" className={busyClass}>
            <Button className={props.className} onClick={onClick} ariaLabel={props.ariaLabel} dataHook={props.dataHook} ariaDisabled={isDisabled} type={props.type}>
                {buttonContent}
            </Button>
        </div>
    );
};
