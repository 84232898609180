import { insertMarkerAroundRange } from "../insert-marker-around-range/InsertMarkerAroundRange";
import { isSelectionWithinContainer } from "../is-selection-within-container/IsSelectionWithinContainer.function";
import { PpMarkerRange } from "../pp-marker-range/PpMarkerRange";

export function insertMarkerWithinContainerSelection(container: HTMLElement): null | PpMarkerRange {
    const selection = window.getSelection();

    if (selection === null || selection.rangeCount === 0 || !isSelectionWithinContainer(selection, container)) {
        return null;
    }

    const range = selection.getRangeAt(0);

    const markerRange = new PpMarkerRange(!range.collapsed);

    insertMarkerAroundRange(markerRange, range);

    return markerRange;
}
