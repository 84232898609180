import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../clickAndDisable/directives/clickAndDisable.directive";
import template from "./templates/external-contact.html";

angularAMD.directive("externalContact", [
    "$sce",
    "$rootScope",
    "baseUrlsFactory",
    "$http",
    function ($sce, $rootScope, baseUrlsFactory, $http) {
        return {
            template: template,
            restrict: "E",
            scope: {
                externalUserData: "=",
                showOrg: "=",
                showRole: "="
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    //create-contact-or-external="external"
                    $scope.contact_or_external = $attrs.createContactOrExternal === "external" ? "external" : "contact";
                }
            ],
            link: function (scope, element, attrs) {
                scope.multiLanguageService = $rootScope.multiLanguageService;
                scope.helpLink = "#/learningCentre?type=privacyAndSharingInDepth";
                var email_confirmation_el = document.getElementById("externalContactConfirmationEmail");
                scope.externalUserData = {};
                scope.showEmailError = false;

                scope.saveContact = function () {
                    if (
                        !scope.externalUserData.firstName ||
                        !scope.externalUserData.secondName ||
                        (scope.showOrg && !scope.externalUserData.organisation) ||
                        (scope.showRole && !scope.externalUserData.role) ||
                        !scope.externalUserData.email ||
                        !scope.externalUserData.emailConfirmation
                    ) {
                        return;
                    }

                    // tslint:disable-next-line:triple-equals
                    if (scope.externalUserData.firstName != "" && scope.externalUserData.secondName != "" && scope.externalUserData.email != "") {
                        if (!/.+@.+\..+/.test(scope.externalUserData.email)) {
                            scope.showEmailInvalid = true;
                            return;
                        }
                        scope.showEmailInvalid = false;
                        // tslint:disable-next-line:triple-equals
                        if (scope.externalUserData.email == scope.externalUserData.emailConfirmation) {
                            if (scope.contact_or_external === "external") {
                                return $http.post(baseUrlsFactory.api_base_url + "FindUser/CreateExternalUser", scope.externalUserData).then(function (response) {
                                    response.data.IsExternal = true;
                                    scope.$emit("newContactAdded", response.data);
                                    scope.closeExternalForm();
                                });
                            } else {
                                return $http.post(baseUrlsFactory.api_base_url + "FindUser/CreateContactUser", scope.externalUserData).then(function (response) {
                                    response.data.IsExternal = false;
                                    scope.$emit("newContactAdded", response.data);
                                    scope.closeExternalForm();
                                });
                            }
                        } else {
                            if (scope.externalUserData.emailConfirmation) {
                                //if emailConfirmation is not undefined(is undefined when it doesn't meet requirements) leave 'email have to match' message and focus field the field for the user.
                                scope.showEmailError = true;
                                if (email_confirmation_el) {
                                    email_confirmation_el.focus();
                                }
                            }
                        }
                    }
                };

                scope.closeExternalForm = function () {
                    scope.$emit("closeExternalForm", {});
                    //clear out form
                    setupExternalUserData();
                };

                scope.$watchCollection("externalUserData", function (newVal, oldVal) {
                    scope.showEmailError = true;
                    scope.showEmailInvalid = false;

                    // tslint:disable-next-line:triple-equals
                    if (newVal.emailConfirmation == "" || newVal.emailConfirmation == undefined) {
                        scope.showEmailError = false;
                        // tslint:disable-next-line:triple-equals
                    } else if (newVal.email == undefined || newVal.email == "") {
                        scope.showEmailError = false;
                    } else {
                        // tslint:disable-next-line:triple-equals
                        if (scope.externalUserData.email != undefined) {
                            if (scope.externalUserData.email.indexOf(newVal.emailConfirmation) > -1) {
                                //check from start to end to make sure that the match is from index 0 and not just a string match within the email text
                                var validMatch = true;
                                for (var i = 0; i < newVal.emailConfirmation.length; i++) {
                                    if (newVal.emailConfirmation.substring(i, 1) !== scope.externalUserData.email.substring(i, 1)) {
                                        validMatch = false;
                                        break;
                                    }
                                }
                                if (validMatch) {
                                    scope.showEmailError = false;
                                }
                            }
                        }
                    }
                });

                var setupExternalUserData = function () {
                    scope.externalUserData.firstName = "";
                    scope.externalUserData.secondName = "";
                    scope.externalUserData.email = "";
                    scope.externalUserData.emailConfirmation = "";
                    scope.externalUserData.organisation = "";
                    scope.externalUserData.role = "";
                };

                setupExternalUserData();
            }
        };
    }
]);
