export class RouteRejectionReason {
    /**
     * @param {String} reason
     * @param {Object|null} user
     * @param {String=} assetId
     * @constructor
     */
    constructor(reason, user, assetId) {
        this.reason = reason;
        this.user = user || null;
        this.assetId = assetId || "";
    }
}
