import { BehaviorSubject, Observable } from "@pjs/observables";
import { noop } from "@pjs/utilities";
import { IShortcutManager } from "../../utils/shortcut-manager/interfaces/IShortcutManager";
import { DialogBackdrop } from "../dialog-backdrop/DialogBackdrop";
import { IDialogDirector } from "./interfaces/IDialogDirector";
import { IDialog } from "./interfaces/IDialog";
import { DialogState } from "./enums/DialogState";

export class DialogDirector implements IDialogDirector {
    public readonly applicationState: Observable<DialogState>;

    private readonly _queue: Array<IDialog<unknown>>;
    private readonly _stateChangeSubject: BehaviorSubject<DialogState> = new BehaviorSubject<DialogState>(DialogState.CLOSED);
    private _activeDialog: IDialog<unknown> | null = null;
    private _removeActiveShortcutStack: (() => void) | null = null;

    private readonly _backdrop: DialogBackdrop;
    private readonly _shortcutManager: IShortcutManager;

    constructor(backdrop: DialogBackdrop, shortcutManager: IShortcutManager) {
        this._shortcutManager = shortcutManager;
        this._queue = [];
        this.applicationState = this._stateChangeSubject.asObservable();
        this._backdrop = backdrop;
    }

    public requestStateChange(state: DialogState, dialogId: string): void {
        if (state === this._stateChangeSubject.value || dialogId !== this._activeDialog?.instance.id) {
            return;
        }

        switch (state) {
            case DialogState.CLOSING:
                this._handleClosing();
                break;
            case DialogState.CLOSED:
                this._handleClosed();
                break;
            case DialogState.OPEN:
                this._addDialogShortcutContext();
                this._stateChangeSubject.next(DialogState.OPEN);
                break;
            default:
                return;
        }
    }

    public enqueue(dialog: IDialog<any>): void {
        if (this._queue.length < 1 && this._activeDialog === null && this._stateChangeSubject.value === DialogState.CLOSED) {
            this._openDialog(dialog);
            return;
        }

        this._queue.push(dialog);
    }

    public dequeue(dialogInstanceId: string): IDialog<unknown> | null {
        const indexToRemove = this._queue.findIndex((dialog) => dialog.instance.id === dialogInstanceId);

        if (indexToRemove < 0) {
            return null;
        }

        return this._queue.splice(indexToRemove, 1)[0];
    }

    public flush(): void {
        if (this._activeDialog !== null) {
            this._activeDialog.instance.destroy();
            this._activeDialog = null;
        }

        this._queue.length = 0;
        this._backdrop.hide();

        this._removeDialogShortcutContext();

        if (this._stateChangeSubject.value !== DialogState.CLOSED) {
            this._stateChangeSubject.next(DialogState.CLOSED);
        }
    }

    private _handleClosing(): void {
        const previousState = this._stateChangeSubject.value;

        if (previousState !== DialogState.OPEN) {
            return;
        }

        if (this._queue.length < 1) {
            this._backdrop.hide();
        }

        this._stateChangeSubject.next(DialogState.CLOSING);
    }

    private _handleClosed(): void {
        if (this._queue.length > 0) {
            this._openDialog(this._queue.shift() as IDialog<any>);

            return;
        }

        const focusOnClose: () => void = this._activeDialog?.instance.focusOnClose.bind(this._activeDialog.instance) ?? noop;
        this._activeDialog = null;

        this._removeDialogShortcutContext();

        this._stateChangeSubject.next(DialogState.CLOSED);
        focusOnClose();
    }

    private _openDialog(dialog: IDialog<any>): void {
        this._activeDialog = dialog;
        this._backdrop.show();

        this.requestStateChange(DialogState.OPEN, dialog.instance.id);
        dialog.render(dialog.instance);
    }

    private _addDialogShortcutContext(): void {
        if (this._removeActiveShortcutStack === null) {
            this._removeActiveShortcutStack = this._shortcutManager.addStack();
        }
    }

    private _removeDialogShortcutContext(): void {
        if (this._removeActiveShortcutStack !== null) {
            this._removeActiveShortcutStack();
            this._removeActiveShortcutStack = null;
        }
    }
}
