import { angularAMD } from "@pebblepad/amd";
import "../slidingBannerNew/slidingBannerNew";
import "../utilities/baseUrlsFactory";
import "../screenReaderAnnouncer/screenReaderAnnouncer.directive";
import "../multiLanguageService/multiLanguageService";
import template from "./templates/help-banner.html";
import { tracker } from "@pjs/analytics";

angularAMD.directive("helpBanner", HelpBanner);
HelpBanner.$inject = ["$sce", "baseUrlsFactory", "multiLanguageService"];

angularAMD.controller("HelpBannerController", HelpBannerController);
HelpBannerController.$inject = ["$scope", "$timeout", "$element"];

function HelpBanner($sce, baseUrlsFactory, multiLanguageService) {
    return {
        template: template,
        restrict: "E",
        scope: {
            slides: "=",
            onCloseCallback: "="
        },
        controller: "HelpBannerController",
        controllerAs: "vm",
        bindToController: true,

        link: function (scope) {
            scope.multiLanguageService = multiLanguageService;
        }
    };
}

function HelpBannerController($scope, $timeout, $element) {
    this.timeout = $timeout;
    this.element = $element;
    this.bindRef = this.setCloseFocusOnEnterOrEsc.bind(this);
    this.helpMinimised = true;
    this.help_banner_toggle_btn_el = angular.element(document.querySelector(".help-banner-toggle-btn"));

    //New user
    $scope.$on("openHelpBanner", this.showHelp.bind(this));
}

HelpBannerController.prototype.bindEscKey = function () {
    this.element[0].addEventListener("keydown", this.bindRef, true);
};

HelpBannerController.prototype.unbindEscKey = function () {
    this.element[0].removeEventListener("keydown", this.bindRef);
};

HelpBannerController.prototype.showHelp = function () {
    if (this.helpMinimised === true) {
        this.openHelp();
    }
};

HelpBannerController.prototype.openHelp = function () {
    tracker.trackEvent("Asset/Resource Store", "Click", "Open Help banner");

    this.bindEscKey();
    this.openFocus = false;

    this.timeout(
        function () {
            var activeDot = document.querySelector(".progress-dots__dot--active");
            if (activeDot) {
                activeDot.focus();
            }
        }.bind(this),
        0
    );

    this.helpMinimised = false;
};

HelpBannerController.prototype.setCloseFocus = function () {
    this.closeHelp();
};

HelpBannerController.prototype.setCloseFocusOnEnterOrEsc = function (e) {
    if (e.keyCode === 13 || e.keyCode === 27) {
        this.closeHelp();
        if (e.keyCode === 27) {
            e.stopPropagation();
        }
    }
};

HelpBannerController.prototype.closeHelp = function () {
    tracker.trackEvent("Asset/Resource Store", "Click", "Close Help banner");
    this.helpMinimised = true;
    this.unbindEscKey();
    this.onCloseCallback();

    this.timeout(
        function () {
            this.openFocus = true;
        }.bind(this),
        0
    );
};
