import { DecoupledEditor, DocumentSelection, Node, Range } from "@pebblepad/ckeditor";
import { linkModelAttributes } from "./constants/LinkModelAttributes.const";
import { ILinkSelectionContent } from "./interfaces/ILinkSelectionContent";
import { ITypeCheckable } from "./interfaces/ITypeCheckable";
import { ILinkRangeBoundary } from "./interfaces/ILinkRangeBoundary";
import { ITextLike } from "./interfaces/ITextLike";
import { StringCollator } from "./types/StringCollator";

export class LinkerSelection {
    private readonly _editor: DecoupledEditor;

    constructor(editor: DecoupledEditor) {
        this._editor = editor;
    }

    private static _createDefaultSelectionResult(blockCount: number = 0): ILinkSelectionContent {
        return {
            blockCount: blockCount,
            isExternal: false,
            link: "",
            linkText: ""
        };
    }

    private static _appendText(currentText: string, newText: string): string {
        return currentText + newText;
    }
    private static _prependText(currentText: string, newText: string): string {
        return newText + currentText;
    }

    public getContent(): ILinkSelectionContent {
        const selectedBlocks = Array.from(this._editor.model.document.selection.getSelectedBlocks()).length;

        if (selectedBlocks > 1) {
            return LinkerSelection._createDefaultSelectionResult(selectedBlocks);
        }

        return this._extractTextFromSelection();
    }

    public moveCaretToEnd(): void {
        const selection = this._editor.model.document.selection;
        if (selection.rangeCount === 0 || !selection.hasAttribute(linkModelAttributes.href)) {
            return;
        }

        const currentRange = selection.getLastRange() as Range;
        const end = this._editor.model.createPositionAt(currentRange.end);
        const newRange = this._editor.model.createRange(end);
        this._editor.model.change((writer) => writer.setSelection(newRange));
    }

    private _isTextLike<T extends ITypeCheckable>(node: T): node is T & ITextLike {
        return node.is("$text") || node.is("$textProxy");
    }

    private _getAttributes(node: Node): [string, boolean] {
        return [(node.getAttribute(linkModelAttributes.href) as string) ?? "", node.getAttribute(linkModelAttributes.isExternal) === true];
    }

    private _getClosestLinkedNode(position: any): Node | null {
        const nodeBefore = position.nodeBefore;
        const nodeAfter = position.nodeAfter;

        let beforeLink = "";
        let beforeIsExternal = false;
        let afterLink = "";
        let afterIsExternal = false;

        if (nodeBefore !== null) {
            [beforeLink, beforeIsExternal] = this._getAttributes(nodeBefore);
        }

        if (nodeAfter !== null) {
            [afterLink, afterIsExternal] = this._getAttributes(nodeAfter);
        }

        if ((beforeLink === afterLink && beforeIsExternal === afterIsExternal) || afterLink !== "") {
            return nodeAfter;
        }

        if (beforeLink !== "") {
            return nodeBefore;
        }

        return null;
    }

    private _handleCollapsedSelection(selection: DocumentSelection): ILinkSelectionContent {
        const firstPosition = selection.getFirstPosition();
        if (firstPosition === null) {
            return LinkerSelection._createDefaultSelectionResult();
        }

        let caretNode: Node | null = firstPosition.textNode;
        if (caretNode === null) {
            caretNode = this._getClosestLinkedNode(firstPosition);

            if (caretNode === null) {
                return LinkerSelection._createDefaultSelectionResult();
            }
        }

        const [link, isExternal] = this._getAttributes(caretNode);
        if (link === "") {
            return LinkerSelection._createDefaultSelectionResult(1);
        }

        const startBoundary = this._getLinkBoundary(caretNode, link, isExternal, "previousSibling", LinkerSelection._prependText);
        const endBoundary = this._getLinkBoundary(caretNode, link, isExternal, "nextSibling", LinkerSelection._appendText);

        let text = this._isTextLike(caretNode) ? caretNode.data : "";
        let startingNode = caretNode;
        let endingNode = caretNode;

        if (startBoundary !== null) {
            text = startBoundary.collatedText + text;
            startingNode = startBoundary.node;
        }

        if (endBoundary !== null) {
            text += endBoundary.collatedText;
            endingNode = endBoundary.node;
        }

        this._selectNodes(startingNode, endingNode);

        return {
            blockCount: 1,
            isExternal: isExternal,
            link: link,
            linkText: text
        };
    }

    private _handleInlineSelection(selection: DocumentSelection): ILinkSelectionContent {
        const firstRange = selection.getFirstRange();
        if (firstRange === null) {
            return LinkerSelection._createDefaultSelectionResult();
        }

        const itemIterator = firstRange.getItems();
        let collatedText = "";
        let selectionLink = "";
        let selectionLinkIsExternal = false;
        let hasSingleLink = false;

        for (const item of itemIterator) {
            const [itemLink, itemIsExternal] = this._getAttributes(item as Node);

            if (this._isTextLike(item)) {
                collatedText += item.data;
            }

            if (itemLink !== "") {
                if (selectionLink !== "") {
                    hasSingleLink = itemLink === selectionLink && itemIsExternal === selectionLinkIsExternal;
                    continue;
                }

                hasSingleLink = true;
                selectionLink = itemLink;
                selectionLinkIsExternal = itemIsExternal;
            }
        }

        return {
            blockCount: 1,
            isExternal: hasSingleLink && selectionLinkIsExternal,
            link: hasSingleLink ? selectionLink : "",
            linkText: collatedText
        };
    }

    private _extractTextFromSelection(): ILinkSelectionContent {
        const selection = this._editor.model.document.selection;

        if (selection.isCollapsed) {
            return this._handleCollapsedSelection(selection);
        }

        return this._handleInlineSelection(selection);
    }

    private _getLinkBoundary(node: Node, linkValue: string, isExternal: boolean, traversalKey: "nextSibling" | "previousSibling", collator: StringCollator): ILinkRangeBoundary | null {
        let currentNode = node[traversalKey];
        let boundaryNode = null;
        let collatedText = "";

        while (currentNode !== null) {
            const [nodeLink, nodeIsExternal] = this._getAttributes(currentNode);
            if (nodeLink !== linkValue || nodeIsExternal !== isExternal) {
                break;
            }

            if (this._isTextLike(currentNode)) {
                collatedText = collator(collatedText, currentNode.data);
            }

            boundaryNode = currentNode;
            currentNode = currentNode[traversalKey];
        }

        if (boundaryNode === null) {
            return null;
        }

        return {
            collatedText: collatedText,
            node: boundaryNode
        };
    }

    private _selectNodes(startNode: Node, endNode: Node): void {
        const start = this._editor.model.createPositionAt(startNode, "before");
        const end = this._editor.model.createPositionAt(endNode, "after");
        const range = this._editor.model.createRange(start, end);

        this._editor.model.change((writer) => writer.setSelection(range));
    }
}
