var constants = {
    ADD: "add",
    DELETE: "delete",
    EDIT: "edit",
    CANCEL: "cancel",
    VIEW: "view",
    RECALL: "recall",
    RELEASE: "release"
};

export { constants as ACTION_CONSTANTS };
