import { angularAMD } from "@pebblepad/amd";

export class FormElementInfoSidebarProvider {
    constructor() {
        this.$get = () => {
            throw new Error("Missing implementation");
        };
    }

    setResolver(resolver) {
        this.$get = resolver;
    }
}

angularAMD.provider("formElementInfoSidebarService", FormElementInfoSidebarProvider);
