import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../assetStore/services/assetStoreService";

angularAMD.service("CollaborationLoaderAPI", CollaborationLoaderAPI);
CollaborationLoaderAPI.$inject = ["$http", "baseUrlsFactory", "assetStoreService"];

function CollaborationLoaderAPI($http, baseUrlsFactory, assetStoreService) {
    //Public API
    //=============================================================================================================
    this.collabStatus = collabStatus;
    this.acceptCollab = acceptCollab;

    //Private methods
    //=============================================================================================================

    function collabStatus(assetId) {
        return assetStoreService.getEditStatusForAsset(assetId);
    }

    function acceptCollab(assetId) {
        return $http.get(baseUrlsFactory.api_base_url + "Share/AcceptCollaboration?assetId=" + assetId);
    }
}
