const constants = {
    CompleteInductionInExternal: "1&2",
    CompleteInductionInPebblePad: "3",
    CompleteInductionInPebblePadReadOnly: "4",
    NoInduction: "5",
    CompleteStandardsInductionInPebblePad: "6",
    CompleteStandardsInductionInPebblePadReadOnly: "7",
    NoInductionExperienceAndStandardsWorkBook: "8",
    ReadOnlyInductionExperienceAndStandardsWorkBook: "9",
    CompleteStandardsInductionInPebblePad2019: "10",
    CompleteStandardsInductionInPebblePadReadOnly2019: "11",
    CompleteStandardsInductionInPebblePad2020: "12",
    CompleteStandardsInductionInPebblePadReadOnly2020: "13",
    CompleteStandardsInductionInPebblePad2022: "14",
    CompleteStandardsInductionInPebblePadReadOnly2022: "15"
};

export { constants as EWC_STATUSES_CONSTANTS };
