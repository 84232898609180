import { noop } from "@pjs/utilities";
import { IEditor } from "../../editor/interfaces/IEditor";
import { EditorEnabledState } from "../../editor/types/EditorEnabledState";
import { IEditorFactoryConfig } from "../../editor-factories/interfaces/IEditorFactoryConfig";
import { BehaviourCleanup } from "../types/BehaviourCleanup";
import { ITouchEventData } from "./interfaces/ITouchEventData";

const maxClickBlockTime = 1000;

function handleIosBlockingClicks(toolbarElement: HTMLElement): BehaviourCleanup {
    const touchDataCache = new WeakMap<HTMLElement, ITouchEventData>();

    function onTouchStart(event: TouchEvent): void {
        const touch = event.targetTouches[0];
        const element = event.target as HTMLElement;

        if (touchDataCache.has(element)) {
            const data = touchDataCache.get(element) as ITouchEventData;
            clearTimeout(data.clickTimer);
            clearTimeout(data.expiryTimer);
        }

        touchDataCache.set(element, {
            clickTimer: 0,
            expiryTimer: 0,
            isMoving: false,
            receivedClick: false,
            startTouchCoords: { x: touch.clientX, y: touch.clientY }
        });
    }

    const touchMoveTolerance = 10;

    function onTouchMove(event: TouchEvent): void {
        const touchData = touchDataCache.get(event.target as HTMLElement);
        if (touchData === undefined) {
            return;
        }

        const touch = event.targetTouches[0];
        if (Math.abs(touch.clientX - touchData.startTouchCoords.x) > touchMoveTolerance || Math.abs(touch.clientY - touchData.startTouchCoords.y) > touchMoveTolerance) {
            touchData.isMoving = true;
        }
    }

    function onTouchEnd(event: TouchEvent): void {
        const element = event.target as HTMLElement;
        const touchData = touchDataCache.get(element);
        if (touchData === undefined) {
            return;
        }

        clearTimeout(touchData.clickTimer);

        if (touchData.isMoving || element.closest("button") === null) {
            touchDataCache.delete(element);
            return;
        }

        touchData.clickTimer = window.setTimeout(() => {
            element.dispatchEvent(
                new PointerEvent("click", {
                    bubbles: true,
                    cancelable: true
                })
            );
        }, 100);
    }

    function onMouseDown(event: MouseEvent): void {
        const touchData = touchDataCache.get(event.target as HTMLElement);

        if (touchData === undefined) {
            return;
        }

        if (touchData.receivedClick) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    function onClick(event: MouseEvent): void {
        const element = event.target as HTMLElement;
        const touchData = touchDataCache.get(element);
        if (touchData === undefined) {
            return;
        }

        clearTimeout(touchData.clickTimer);

        if (touchData.receivedClick) {
            event.stopPropagation();
            touchDataCache.delete(element);
            return;
        }

        touchData.receivedClick = true;

        touchData.expiryTimer = window.setTimeout(() => {
            touchDataCache.delete(element);
        }, maxClickBlockTime);
    }

    toolbarElement.addEventListener("touchstart", onTouchStart, { passive: true });
    toolbarElement.addEventListener("touchmove", onTouchMove, { passive: true });
    toolbarElement.addEventListener("touchend", onTouchEnd, { passive: true });
    toolbarElement.addEventListener("mousedown", onMouseDown, true);
    toolbarElement.addEventListener("click", onClick, true);

    return () => {
        toolbarElement.removeEventListener("touchstart", onTouchStart);
        toolbarElement.removeEventListener("touchmove", onTouchMove);
        toolbarElement.removeEventListener("touchend", onTouchEnd);
        toolbarElement.removeEventListener("mousedown", onMouseDown, true);
        toolbarElement.removeEventListener("click", onClick, true);
    };
}

function onEditorInit(editor: IEditor, _config: IEditorFactoryConfig): BehaviourCleanup {
    let onCleanup = noop;
    editor.enabled.subscribe((editorState: EditorEnabledState) => {
        if (editorState.isEnabled) {
            onCleanup = handleIosBlockingClicks(editorState.editor.ui.view.toolbar.element as HTMLElement);
            return;
        }

        onCleanup();
    });

    return onCleanup;
}

export { onEditorInit as handleIosBlockingClicks };
