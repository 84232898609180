import { isSafeUrlProtocol } from "../is-safe-url-protocol/IsSafeUrlProtocol.function";
import { SandboxedTagMutation } from "../html-string-parser/SandboxedTagMutation";
import { IAnchorTransformConfig } from "./interfaces/IAnchorTransformConfig";

export class AnchorTransform extends SandboxedTagMutation<HTMLAnchorElement> {
    private readonly _config: IAnchorTransformConfig;

    constructor(config: IAnchorTransformConfig) {
        super();
        this._config = config;
    }

    protected _mutate(element: HTMLAnchorElement): boolean {
        let href = element.getAttribute("href") ?? "";
        href = this._config.preprocessHref(href, element);

        if (href === "" || !isSafeUrlProtocol(href)) {
            const parent = element.parentElement;
            if (parent !== null) {
                element.replaceWith(...element.childNodes);
            }

            return false;
        }

        this._makeTargetSafe(element);
        return true;
    }

    private _makeTargetSafe(element: HTMLAnchorElement): void {
        if (element.target === "") {
            return;
        }

        if (element.target === "_blank") {
            element.setAttribute("rel", "noreferrer noopener");
            return;
        }

        element.removeAttribute("target");
    }
}
