import { flip, shift, Placement } from "@floating-ui/dom";
import { IFloatingPositioningConfig } from "./interfaces/IFloatingPositioningConfig";
import { floatingElementStyler } from "./utilities/FloatingElementStyler";

export const dropdownPositioningFactory = (boundaryElement: HTMLElement, placement: Placement): IFloatingPositioningConfig => {
    const middleware = [flip({ boundary: boundaryElement, crossAxis: false }), shift({ boundary: boundaryElement, rootBoundary: "document" })];

    return {
        positionConfig: {
            middleware: middleware,
            placement: placement,
            strategy: "absolute"
        },
        styler: floatingElementStyler
    };
};
