import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../constants/asset.constants";
import "../spaMenu/assetInfoPanel/assetFeedback/helpers/SubmissionPermissionHelper";
import "../multiLanguageService/multiLanguageService";
import "../builder/dataManager/dataManagerService";

export class FeedbackLeftOnService {
    constructor(submissionPermissionHelper, multiLanguageService, dataManagerService) {
        this._submissionPermissionHelper = submissionPermissionHelper;
        this._multiLanguageService = multiLanguageService;
        this._dataManagerService = dataManagerService;
        this._containerAssetTypes = [ASSET_CONSTANTS.TYPES.WEBFOLIO, ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE, ASSET_CONSTANTS.TYPES.WORKBOOK];
    }

    getLeftOnAssetInfo(assetId, pageId = null, anchorId = null) {
        const manager = this._dataManagerService.getDto(assetId);
        const isSubmissionAsset = assetId === this._submissionPermissionHelper.getSubmissionsMainAssetId();

        if ((isSubmissionAsset && pageId === null) || manager === null || manager === undefined) {
            return {
                leaveFeedbackOnAssetId: "",
                pageName: "",
                isContainerAsset: false,
                isLinkedAsset: false
            };
        }

        const isStandaloneAsset = !this._containerAssetTypes.includes(manager.data.MainType);
        if (!isSubmissionAsset && pageId === null && (anchorId === null || isStandaloneAsset)) {
            return this._getInfoForLinkedAsset(assetId, manager);
        }

        const currentPage = manager.get(pageId, false);
        return this._getInfoForPage(currentPage, !isStandaloneAsset, !isSubmissionAsset);
    }

    _getInfoForLinkedAsset(assetId, manager) {
        const linkedTitle = manager && manager.data && manager.data.Title ? manager.data.Title : this._multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.linked_asset");
        return {
            leaveFeedbackOnAssetId: assetId,
            pageName: linkedTitle,
            isContainerAsset: false,
            isLinkedAsset: true
        };
    }

    _getInfoForPage(page, isContainerAsset, isLinkedAsset) {
        return {
            leaveFeedbackOnAssetId: page.UserResponseId || page.PageAssetId,
            pageName: page.PageTitle || this._multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.linked_asset"),
            isContainerAsset: isContainerAsset,
            isLinkedAsset: isLinkedAsset
        };
    }
}

FeedbackLeftOnService.$inject = ["SubmissionPermissionHelper", "multiLanguageService", "dataManagerService"];
angularAMD.service("feedbackLeftOnService", FeedbackLeftOnService);
