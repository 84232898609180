import { angularAMD } from "@pebblepad/amd";
import "../assetEndpointService/assetEndpoint.service";

export class FeatureSettings {
    constructor(assetEndpointService) {
        this._assetEndpointService = assetEndpointService;
        this._settings = new Map();
        this._activeRequest = null;
    }

    isEnabled(settingName) {
        const setting = this._settings.get(settingName);
        return setting !== undefined && setting;
    }

    load() {
        if (this._activeRequest !== null) {
            return this._activeRequest;
        }

        this._activeRequest = this._assetEndpointService.getFeaturesState().then((response) => {
            this._settings.clear();
            Object.entries(response.data).forEach(([k, v]) => this._settings.set(k, v));
            this._activeRequest = null;
        });

        return this._activeRequest;
    }
}

FeatureSettings.serviceName = "featureSettings";

FeatureSettings.$inject = ["AssetEndpointService"];
angularAMD.service(FeatureSettings.serviceName, FeatureSettings);
