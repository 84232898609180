import { ComponentType } from "react";
import { AnyProps } from "@pjs/react-utilities";
import { BoundaryBridge } from "../boundary-bridge/BoundaryBridge.component";

// eslint-disable-next-line @typescript-eslint/naming-convention
export function WithBoundaryBridge<T extends AnyProps>(Component: ComponentType<T>): ComponentType<T> {
    // eslint-disable-next-line react/display-name
    return (props: T) => {
        return (
            <BoundaryBridge>
                <Component {...props} />
            </BoundaryBridge>
        );
    };
}
