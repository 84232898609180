import { PANEL_CONSTANTS } from "@pjs/components/constants/panel.constants";

export class FormElementInfoSidebarService {
    constructor($rootScope) {
        this._$rootScope = $rootScope;
    }

    displayElementInfo(elementId, panelData, assetId, topLevelAssetId, onPanelClose = null) {
        return this._$rootScope.$broadcast("openRightHandSidePanel", {
            anchorId: elementId,
            assetId: assetId,
            panel: PANEL_CONSTANTS.PANEL.ELEMENT_INFO,
            panelData: panelData,
            topLevelAssetId: topLevelAssetId,
            onPanelClose: onPanelClose
        });
    }

    displayElementCommentsSidebar(assetId, anchorId, panelData, topLevelAssetId, onPanelClose = null) {
        return this._$rootScope.$broadcast("openRightHandSidePanel", {
            assetId: assetId,
            anchorId: anchorId,
            panel: PANEL_CONSTANTS.PANEL.ELEMENT_COMMENTS,
            panelData: panelData,
            topLevelAssetId: topLevelAssetId,
            onPanelClose: onPanelClose
        });
    }
}
