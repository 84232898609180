import { angularAMD } from "@pebblepad/amd";
import { StatementCollection } from "@pjs/feedback";

angularAMD.factory("statementCollectionCache", [
    "$rootScope",
    ($rootScope) => {
        const statementCollection = new StatementCollection();

        $rootScope.$on("$routeChangeSuccess", (event, currentRoute, previousRoute) => {
            if (previousRoute !== undefined) {
                const submissionId = Number(previousRoute.params.submissionId);
                statementCollection.removeStatements(submissionId);
            }
        });

        return statementCollection;
    }
]);
