import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";

angularAMD.factory("tagService", [
    "$http",
    "$q",
    "baseUrlsFactory",
    function ($http, $q, baseUrlsFactory) {
        function promisedGet(url, params) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.get(url, {
                timeout: httpTimeout.promise,
                params: params
            });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        function promisedPost(url, data) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.post(url, data, { timeout: httpTimeout.promise });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        return {
            getCourseTags: function (workbookId, assetId) {
                return promisedGet(`${baseUrlsFactory.api_base_url}Tag/GetCourseTags`, { mappingSourceId: workbookId, assetId: assetId });
            },

            getTags: function () {
                return promisedGet(baseUrlsFactory.api_base_url + "Tag/GetTags");
            },

            addTag: function (assetIds, tags) {
                var model = {
                    assetIds: assetIds,
                    tags: tags
                };
                return promisedPost(baseUrlsFactory.api_base_url + "Tag/AddTag", model);
            },

            removeTag: function (assetIds, tags) {
                var model = {
                    assetIds: assetIds,
                    tags: tags
                };
                return promisedPost(baseUrlsFactory.api_base_url + "Tag/RemoveTag", model);
            }
        };
    }
]);
