import { angularAMD } from "@pebblepad/amd";
import { ToolbarDisplayState } from "@pjs/rich-text";
import { isAndroid, isIphoneLike, isIpadLike } from "@pjs/utilities";
import "../utilities/debounce.service.js";
import "../statusToolbar/services/statusToolbar.service";
import "./ToolbarLayoutController";
import { getClosestDropdownContainer } from "../utilities/getClosestDropdownContainer.function";
import { getEditorToolbarHeight } from "./GetEditorToolbarHeight.function";

const isMobile = isAndroid || isIphoneLike || isIpadLike;

export class ActiveToolbarCoordinator {
    constructor(statusToolbar, debounce, toolbarLayoutController) {
        this.statusToolbar = statusToolbar;
        this._toolbarLayoutController = toolbarLayoutController;
        this._activeToolbarCount = 0;
        this.debouncedStatusToggle = debounce.event(this._toggleStatus, this, 100);
        this._hiddenDebounce = debounce.event(this._hideAnimatedToolbarContainer, this, 250);
        this._activeAnimatedToolbar = null;
    }

    _toggleStatus() {
        if (this._activeToolbarCount > 0) {
            this.statusToolbar.hide();
        } else {
            this.statusToolbar.show();
        }
    }

    _hideAnimatedToolbarContainer() {
        if (this._activeAnimatedToolbar === null) {
            return;
        }

        this._activeAnimatedToolbar.style.height = "0";
        this._activeAnimatedToolbar = null;
    }

    _willToolbarExceedBounds(toolbarContainer, toolbarHeight) {
        const closestDropdown = getClosestDropdownContainer(toolbarContainer);
        const previousToolbarPosition = toolbarContainer.style.position;

        toolbarContainer.style.position = "static";

        if (closestDropdown === null) {
            return false;
        }

        const dropdownContainerY = closestDropdown.getBoundingClientRect().y;
        const toolbarY = toolbarContainer.getBoundingClientRect().y;

        toolbarContainer.style.position = previousToolbarPosition;

        return toolbarY - dropdownContainerY + closestDropdown.scrollTop - toolbarHeight < 0;
    }

    _manageFloating(displayState, toolbarContainer) {
        if (toolbarContainer === null) {
            return;
        }

        if (isMobile) {
            this._toolbarLayoutController.removePositionSticky(toolbarContainer);
        }

        if (displayState === ToolbarDisplayState.Shown) {
            const toolbarHeight = getEditorToolbarHeight(toolbarContainer);

            if (this._willToolbarExceedBounds(toolbarContainer, toolbarHeight)) {
                this._hiddenDebounce.cancel();
                this._activeAnimatedToolbar = toolbarContainer;

                this._toolbarLayoutController.hideFloating(toolbarContainer);
                this._toolbarLayoutController.displayToolbarWithAnimation(toolbarContainer, toolbarHeight);

                return;
            }

            this._toolbarLayoutController.applyFloating(toolbarContainer);
        }

        if (displayState === ToolbarDisplayState.Hidden && this._activeAnimatedToolbar === toolbarContainer) {
            this._hiddenDebounce.run();
        }
    }

    _handleToolbarCount(displayState) {
        if (displayState !== ToolbarDisplayState.Pending) {
            this._activeToolbarCount += displayState === ToolbarDisplayState.Shown ? 1 : -1;
        }

        this.debouncedStatusToggle.run();
    }

    onToolbarToggle(displayState, toolbarContainer) {
        this._handleToolbarCount(displayState);

        if (toolbarContainer === null) {
            return;
        }

        if (isMobile) {
            this._toolbarLayoutController.removePositionSticky(toolbarContainer);
        }
    }

    onToolbarToggleWithFloating(displayState, toolbarContainer) {
        this._handleToolbarCount(displayState);
        this._manageFloating(displayState, toolbarContainer);
    }
}

ActiveToolbarCoordinator.$inject = ["StatusToolbar", "debounce", "ToolbarLayoutController"];
angularAMD.service("ActiveToolbarCoordinator", ActiveToolbarCoordinator);
