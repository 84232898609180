import { INonDocumentNode } from "./interfaces/INonDocumentNode";

const cachedSafeDocument = document.implementation.createHTMLDocument("");

/**
 * @description Caveats:
 * - If a string with invalid HTML is provided, an empty string will be returned.
 * - Whitespace unicode characters will be removed (e.g. `\u200B`).
 */
export function htmlStringToPlainText(htmlString: string): string {
    // eslint-disable-next-line no-unsanitized/property
    cachedSafeDocument.body.innerHTML = htmlString;
    const textContent = (cachedSafeDocument.body as INonDocumentNode).textContent.replace(/\u200B/g, "");
    cachedSafeDocument.body.textContent = "";

    return textContent;
}
