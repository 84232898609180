import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../constants/asset.constants";
import { makeAbortable } from "../abortablePromises/MakeAbortable.function";
import "../utilities/helpers";
import "../assetEndpointService/assetEndpoint.service";

AssetSelector.$inject = ["$rootScope", "$q", "helpers", "AssetEndpointService"];
function AssetSelector($rootScope, $q, helpers, AssetEndpointService) {
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.helpers = helpers;
    this.assetEndpointService = AssetEndpointService;
}

AssetSelector.prototype.selectAssets = function (options) {
    options.singleOrMultipleSelect = "Multiple";
    return this._select(options);
};

AssetSelector.prototype.selectAsset = function (options) {
    options.singleOrMultipleSelect = "Single";
    return this._select(options).then(function (data) {
        return data && data[0] ? data[0] : null;
    });
};

AssetSelector.prototype.selectMediaAsset = function (options) {
    return this.selectAsset(options).then((data) => {
        if (data === null || data.SubType !== ASSET_CONSTANTS.CATEGORIES.IMAGE) {
            return data;
        }

        return this.assetEndpointService
            .getImageAltText(data.Id)
            .then((response) => {
                data.AltText = response.data;
                return data;
            })
            .catch((error) => {
                data.AltText = null;
                return data;
            });
    });
};

AssetSelector.prototype._select = function (options) {
    //When you are hacky and you know it.
    const deferred = this.$q.defer();
    const eventId = this.helpers.guid();
    const selectorOptions = {
        panel: "selector",
        broadcastCommand: eventId,
        broadcastType: "onlyOnDone",
        singleOrMultipleSelect: "Single",
        selectType: "AssetStore",
        mainTypes: [],
        subTypes: [],
        ownerships: ["ByMe", "WithMe"],
        currentlySelectedIds: [],
        excludeIds: [],
        excludeChildrenOf: [],
        includeUpload: false,
        selectorViews: [],
        actionData: undefined
    };

    Object.assign(selectorOptions, options);
    this.$rootScope.$broadcast("openRightHandSidePanel", selectorOptions);

    const unregisterEvent = this.$rootScope.$on(
        eventId,
        function (event, data) {
            unregisterEvent();
            deferred.resolve(data || null);
        }.bind(this)
    );

    const abortablePromise = makeAbortable(deferred.promise);
    const selectPromise = {
        then(onResolve, onReject) {
            return abortablePromise.then(onResolve, onReject);
        },
        catch(onReject) {
            return abortablePromise.catch(onReject);
        },
        finally(onFinally) {
            return abortablePromise.finally(onFinally);
        },
        abort() {
            if (unregisterEvent !== null) {
                unregisterEvent();
            }
            abortablePromise.abort();
        }
    };

    return selectPromise;
};

angularAMD.service("assetSelector", AssetSelector);
export { AssetSelector as assetSelector };
