import { moveElementChildren } from "@pjs/utilities";
import { replaceCollectionItemsWithChildren } from "../replace-collection-with-children/ReplaceCollectionItemsWithChildren.function";

export function semanticInlineSwap(newTagName: string, sandboxedElement: HTMLElement, sandboxedDocument: Document, legacyTagName?: string): void {
    let parent = sandboxedElement.parentElement;
    while (parent !== null) {
        if (parent.tagName === newTagName) {
            return;
        }

        parent = parent.parentElement;
    }

    replaceCollectionItemsWithChildren(sandboxedElement.getElementsByTagName(newTagName));

    if (legacyTagName !== undefined) {
        replaceCollectionItemsWithChildren(sandboxedElement.getElementsByTagName(legacyTagName));
    }

    const semanticTag = sandboxedDocument.createElement(newTagName);
    moveElementChildren(sandboxedElement, semanticTag);
    sandboxedElement.append(semanticTag);
}
