import { Plugin } from "@pebblepad/ckeditor";
import { richTextI18n } from "../../i18n/RichTextI18n.const";

/**
 * A plugin which adds additional characters to the CKE5 "Mathematical" special character preset.
 * As per the docs, a key of "Mathematical" must be hardcoded in order to add more to CKE5's existing data set.
 * Internally CKE5 will make use of translations for this special key (no multi-lang issues).
 * Docs: https://ckeditor.com/docs/ckeditor5/latest/features/special-characters.html#adding-characters-to-an-existing-category
 */
export class SpecialCharactersMathExtended extends Plugin {
    public init(): void {
        const plugin = this.editor.plugins.get("SpecialCharacters");

        plugin.addItems("Mathematical", [
            { character: "‰", title: richTextI18n.getString("toolbar_items.special_characters.math.per_mille") },
            { character: "‱", title: richTextI18n.getString("toolbar_items.special_characters.math.per_ten_thousand") }
        ]);
    }
}
