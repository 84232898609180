import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";

export function selectNextItem<T>(_e: KeyboardEvent, currentModel: Immutable<IAriaModelWithType>, items: ReadonlyArray<T>): Partial<Immutable<IAriaModelWithType>> | null {
    let newItemIndex = currentModel.activeItemIndex;

    if (currentModel.activeItemIndex < items.length - 1) {
        newItemIndex += 1;
    } else {
        return null;
    }

    return {
        activeItemIndex: newItemIndex,
        type: AriaMenuStatus.Open
    };
}
