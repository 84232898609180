import { FunctionComponent } from "react";
import { IMenuWithText } from "../interfaces/IMenuWithText";
import { StyledLoadingSpinner } from "../../loading-spinner/StyledLoadingSpinner.component";
import "../styles/pending-menu.css";

export const PendingMenu: FunctionComponent<IMenuWithText> = (props) => {
    return (
        <div className="cui-auto-complete-pending__menu-container" id={props.id} data-hook="autocomplete-pending-menu">
            <StyledLoadingSpinner className="cui-auto-complete-pending__menu-loading-spinner" />
            <span className="cui-auto-complete-pending__menu-text">{props.message}</span>
        </div>
    );
};
