import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import "../../../../pui/src/accordion/accordion.component";
import "../../../../pui/src/accordion/accordion-content.component";
import "../../../../pui/src/accordion/accordion-header.component";
import "../../../../pui/src/accordion/accordion-panel.component";
import "../../../../multiLanguageService/multiLanguageService";
import "../../../../react2angular/capabilityHistoryItem";
import template from "./answer-history-accordion.html";

class AnswerHistoryAccordionComponent extends NgComponent {
    constructor(multiLanguageService) {
        super();

        this._multiLanguageService = multiLanguageService;
        this.accordionHeaderLevel = 3;
        this.accordionHeaderText = "";
        this.accordionLevel = 2;

        if (this.answerHistory.length > 0) {
            this._setAccordionHeader();
        }
    }

    _setAccordionHeader() {
        const topItem = this.answerHistory[0];
        const multiLangKeys = { name: topItem.lastUpdatedByName, value1: topItem.previousAnswer, value2: topItem.answer };

        const accordionHeaderKey = "labels.answer_history.history_accordion_header";
        const accordionHeaderAction = topItem.previousAnswer !== undefined ? "_changed" : "_chose";
        const accordionHeaderPerson = this.userId === topItem.lastUpdatedBy ? "_you" : "_name";

        const i18nKey = `${accordionHeaderKey}${accordionHeaderAction}${accordionHeaderPerson}`;

        this.accordionHeaderText = this._multiLanguageService.getString(i18nKey, multiLangKeys);
    }
}

const AnswerHistoryAccordionDefinition = {
    bindings: {
        canEditJustification: "<",
        answerHistory: "<",
        onEditJustification: "<",
        userId: "<"
    },
    template: template,
    controller: AnswerHistoryAccordionComponent
};

AnswerHistoryAccordionComponent.$inject = ["multiLanguageService"];

angularAMD.component("answerHistoryAccordion", AnswerHistoryAccordionDefinition);

export { AnswerHistoryAccordionDefinition as answerHistoryAccordion };
