import { ReactElement, RefObject, PureComponent, createRef } from "react";
import { createGuid } from "@pjs/utilities";
import { TimedAnimation } from "../timed-animation/TimedAnimation";
import { ITimedAnimation } from "../timed-animation/interfaces/ITimedAnimation";
import { easeInOutCubic } from "../timed-animation/easings/easeInOutCubic";
import { coreUiI18n } from "../i18n/CoreUiI18n.const";
import { IProgressVisualisationProps } from "./interfaces/IProgressVisualisationProps";
import { IProgressVisualisationState } from "./interfaces/IProgressVisualisationState";
import "./styles/progress-visualisation.css";

const placeholderCanvasRenderingContext2D = null as unknown as CanvasRenderingContext2D;

export class ProgressVisualisation extends PureComponent<IProgressVisualisationProps, IProgressVisualisationState> {
    private readonly _canvasRef: RefObject<HTMLCanvasElement> = createRef<HTMLCanvasElement>();
    private _timedAnimation: ITimedAnimation;
    private _ctx: CanvasRenderingContext2D = placeholderCanvasRenderingContext2D;
    private readonly _id: string = createGuid();

    private readonly _completeText: string = coreUiI18n.getString("progress_visualisation.complete");

    constructor(props: IProgressVisualisationProps) {
        super(props);
        this.state = {
            text: ""
        };

        this._timedAnimation = new TimedAnimation(this.props.duration, easeInOutCubic);
    }

    public componentDidMount(): void {
        if (this._canvasRef.current === null) {
            return;
        }

        const context = this._canvasRef.current.getContext("2d");

        if (context !== null) {
            this._ctx = context;
        }

        this._drawDiagramBase();
        this._timedAnimation.start(this._animate.bind(this));
    }

    public componentDidUpdate(prevProps: IProgressVisualisationProps): void {
        if (this._timedAnimation.isAnimating()) {
            if (this.props.size !== prevProps.size || this.props.count !== prevProps.count || this.props.duration !== prevProps.duration || this.props.total !== prevProps.total) {
                this._timedAnimation.stop();
                if (this.props.duration !== prevProps.duration) {
                    this._timedAnimation = new TimedAnimation(this.props.duration, easeInOutCubic);
                }
                this._timedAnimation.start(this._animate.bind(this));
            }
        } else {
            this._animate(1);
        }
    }

    public componentWillUnmount(): void {
        this._timedAnimation.stop();
    }

    public render(): ReactElement {
        return (
            <div data-hook="progress-visualisation" className="cui-progress-visualisation" style={{ height: this.props.size, width: this.props.size }}>
                <canvas
                    id={`progress-visualisation-${this._id}`}
                    className="cui-progress-visualisation__canvas"
                    ref={this._canvasRef}
                    aria-label={this.props.ariaLabel}
                    width={this.props.size}
                    height={this.props.size}
                    role="img"
                />

                <div className="cui-progress-visualisation__percentage-wrapper">
                    <p data-hook="progress-count-text" className="cui-progress-visualisation__count">
                        {this.state.text}
                    </p>
                    <p data-hook="progress-complete-text" className="cui-progress-visualisation__complete">
                        {this._completeText}
                    </p>
                </div>
            </div>
        );
    }

    private _animate(easedProgress: number): void {
        const calc = (this.props.count / this.props.total) * 100;
        this.setState((currentState) => {
            const newText = this.props.showPercentage ? `${Math.round(easedProgress * calc)}%` : `${Math.round(easedProgress * this.props.count)}`;
            if (currentState.text === newText) {
                return null;
            }
            return {
                text: newText
            };
        });

        this._drawDiagramBase();
        this._renderProgress(this.props.progressColour, easedProgress);
    }

    private _drawDiagramBase(): void {
        this._ctx.clearRect(0, 0, this.props.size, this.props.size);

        this._ctx.beginPath();
        this._ctx.arc(this.props.size / 2, this.props.size / 2, this.props.size / 2 - this.props.lineWidth, 0, Math.PI * 2);
        this._ctx.lineWidth = this.props.lineWidth;
        this._ctx.strokeStyle = this.props.strokeColour;
        this._ctx.stroke();
    }

    private _renderProgress(colour: string, easedProgress: number): void {
        const circleStart = 1.5 * Math.PI;
        const totalCalculation = this.props.count / this.props.total;
        const progress = easedProgress * totalCalculation;
        const circleProgress = progress * 360;
        const radianCalc = (circleProgress * Math.PI) / 180;
        const circleEnd = circleStart + radianCalc;

        this._ctx.beginPath();
        this._ctx.arc(this.props.size / 2, this.props.size / 2, this.props.size / 2 - this.props.lineWidth, circleStart, circleEnd, false);
        this._ctx.lineWidth = this.props.lineWidth;
        this._ctx.strokeStyle = colour;
        this._ctx.stroke();
    }
}
