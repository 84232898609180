import { FunctionComponent, PropsWithChildren } from "react";
import { Icon } from "../icon/Icon.component";
import { INoticeProps } from "./interfaces/INoticeProps";
import "./styles/notice.css";

export const Notice: FunctionComponent<PropsWithChildren<INoticeProps>> = (props) => {
    const noticeBorderStyle = {
        borderLeftColor: props.type.colour
    };

    return (
        <section aria-label={props.ariaLabel}>
            <div style={noticeBorderStyle} className="cui-notice" data-hook="notice">
                <Icon source={props.type.icon} className="cui-notice__icon" fill={props.type.colour} />
                <div className="cui-notice__content">{props.children}</div>
            </div>
        </section>
    );
};
