import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import { STYLE_OPTIONS } from "../../constants/styleOptions.constants";
import "./helperService";
import "../../multiLanguageService/multiLanguageService";
import "../tables/services/tableDataHelper.service";
import "../tables/services/tableConfig.factory";
import "../tables/services/tableDtoHelper.service";
import "../genericBlockInputOptionsList/services/blockInputOptionsHelper.service";

angularAMD.factory("dtoFactory", [
    "$sce",
    "helperService",
    "baseUrlsFactory",
    "multiLanguageService",
    "tableDataHelper",
    "tableConfig",
    "tableDtoHelper",
    "BlockInputOptionsHelper",
    function ($sce, helperService, baseUrlsFactory, multiLanguageService, tableDataHelper, tableConfig, tableDtoHelper, BlockInputOptionsHelper) {
        /**
         * List of DTO types
         * @type {{WEBFOLIO_PAGE: string, TXT_BLOCK: string, MEDIA_BLOCK: string}}
         */
        var DTO_TYPES = {
            ACTIVITY_LOG: "ActivityLog",
            BLOG: "Blog",
            COLLECTION: "AssetCollection",
            COLLECTION_WRAPPER: "AssetCollectionWrapper",
            CV: "CV",
            FORM: "Form",
            FORM_RESPONSE: "FormResponse",
            POST: "Post",
            WEBFOLIO: "Webfolio",
            WEBFOLIO_PAGE: "WebfolioPage",
            WORKBOOK_RESPONSE: "WorkBookResponse",
            WORKBOOK_PAGE: "WorkBookPageResponse",
            TEMPLATE_BUILDER: "TemplateBuilder",
            WORKBOOK_BUILDER: "WorkbookBuilder",
            WORKBOOK: "WorkBook",

            TXT_BLOCK: "block-text",
            IMAGE_BLOCK: "block-image",
            VIDEO_BLOCK: "block-video",
            AUDIO_BLOCK: "block-audio",
            HERO_BLOCK: "block-hero",
            EMBED_BLOCK: "block-embed",
            EMBED_USER_BLOCK: "block-embed-user",
            QUOTE_BLOCK: "block-quote",
            BADGE_BLOCK: "block-badge",

            CONTENT_SECTION: "block-content-section",
            SINGLE_LINE_BLOCK: "block-single-line",
            MULTI_LINE_BLOCK: "block-multi-line",
            TITLE_SINGLE_LINE_BLOCK: "block-title-single-line",
            RADIO_BUTTON_BLOCK: "block-radio-button",
            CHECKBOX_BLOCK: "block-checkbox",
            BINARY_BLOCK: "block-binary",
            DROP_DOWN_BLOCK: "block-drop-down",

            GENERIC_TABLE_BLOCK: "block-generic-table",

            DATE_PICKER_BLOCK: "block-date-picker",
            DATE_DIGITAL_SIGNATURE: "block-digital-signature",
            MEDIA_PICKER_BLOCK: "block-media-picker",
            DYNAMIC_ELEMENT: "block-dynamic-element",
            UNKNOWN_ELEMENT: "block-unknown",
            NUMERIC_BLOCK: "block-numeric",
            LIKERT_BLOCK: "block-likert",
            EVIDENCE_BLOCK: "block-capability-evidence",

            HINT_TEXT_BLOCK: "block-hint-text",
            HINT_IMAGE_BLOCK: "block-hint-image",
            HINT_VIDEO_BLOCK: "block-hint-video",
            HINT_AUDIO_BLOCK: "block-hint-audio",

            CAPABILITY_RATING: "block-rating-capability",
            HORIZONTAL_RULE_BLOCK: "block-horizontal-rule",
            CAPABILITY_RADIO_BUTTON_BLOCK: "block-capability-radio-button",
            CAPABILITY_BINARY: "block-binary-capability",

            STANDARDS_SELECTION: "block-standards-selection",
            STANDARD_BLOCK: "block-standard"
        };

        /**
         * List of section types
         * @type {{rowSection: string, blockText: string, blockMedia: string, blockQuote: string, blockHero: string}}
         */
        var SECTION_TYPES = {
            rowSection: "ContentSectionRow",
            BuilderFormElementRow: "BuilderFormElementRow",
            blockText: "ContentSectionText",
            blockMedia: "ContentSectionMedia",
            blockQuote: "ContentSectionQuote",
            blockHero: "ContentSectionHero",
            blockEmbed: "ContentSectionEmbed",
            "block-embed": "ContentSectionEmbed",
            BuilderHintEmbed: "BuilderHintEmbed",
            BuilderUserEmbed: "BuilderEmbed",
            blockBadge: "ContentSectionBadge",
            ContentSection: "BuilderContentSection",
            BuilderSingleLineTextBox: "BuilderSingleLineTextBox",
            BuilderMultiLineTextBox: "BuilderMultiLineTextBox",
            BuilderOptionsListRadioButtons: "BuilderOptionsList-RadioButtons",
            BuilderBinaryAnswerInput: "BuilderBinaryAnswerInput",
            BuilderTitleSingleLineTextBox: "BuilderTitleSingleLineTextBox",
            BuilderOptionsListCheckboxes: "BuilderOptionsList-Checkboxes",
            BuilderOptionsListDropDown: "BuilderOptionsList-DropDown",
            GenericBuilderTable: "GenericBuilderTable",
            BuilderDatePickerInput: "BuilderDatePickerInput",
            BuilderFormSignatureElement: "BuilderFormSignatureElement",
            BuilderFormUserMediaPicker: "BuilderFormUserMediaPicker",
            BuilderDynamicElement: "BuilderDynamicElement",
            BuilderRangeInputStandard: "BuilderRangeInput-Standard",
            BuilderRangeInputLikert: "BuilderRangeInput-Likert",
            BuilderCapabilityEvidenceOnly: "BuilderCapabilityEvidenceOnly",
            BuilderHintText: "BuilderHintText",
            BuilderHintImage: "BuilderHintImage",
            "BuilderCapabilityRangeInput-Standard": "BuilderCapabilityRangeInput-Standard",
            BuilderHintVideo: "BuilderHintVideo",
            BuilderHintAudio: "BuilderHintAudio",
            BuilderHorizontalRule: "BuilderHorizontalRule",
            "BuilderCapabilityOptionList-RadioButtons": "BuilderCapabilityOptionList-RadioButtons",
            BuilderCapabilityBinaryAnswerInput: "BuilderCapabilityBinaryAnswerInput",
            BuilderUnknown: "BuilderUnknown",
            BuilderFormBasicContainer: "BuilderFormBasicContainer",
            BuilderStandards: "BuilderStandards",
            BuilderStandardsSelection: "BuilderStandardsSelection"
        };

        var MEDIA_TYPES = {
            "block-image": "image",
            "block-video": "video",
            "block-audio": "audio"
        };

        /**
         * Base section DTO constructor
         * @param dto
         * @constructor
         */
        var SectionDtoClass = function (dto) {
            this.Id = helperService.guid();
            this.OrderNo = 0;
            this.SectionType = SECTION_TYPES[dto.SectionType];
            this.SectionOptions = [];
            this.StyleOptions = [];
            this.AllowSideBySide = dto.AllowSideBySide || false;
            this.EditAvailableFor = dto.EditAvailableFor || [];
        };

        var AssetDtoBase = function (dto) {
            this.Id = dto.Id || helperService.guid();
            this.Title = dto.Title || "";
            this.Description = dto.Description || "";
            this.MainType = dto.MainType || "";
            this.Created = helperService.normalizeDate(dto.Created) || new Date();
            this.CreatedBy = dto.CreatedBy || null;
            this.Authors = dto.Authors || [];
            this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
            this.LastModifiedBy = dto.LastModifiedBy || null;
            this.AssetOptions = dto.AssetOptions || [];
            this.StorageServiceId = dto.StorageServiceId || "Default";
            this.AssetFolder = dto.AssetFolder || "Invisible";
            this.Revision = dto.Revision || 0;
            this.Locked = dto.Locked || false;
        };

        var dtoFactory = {
            /**
             * @param dto
             * @returns {*}
             */
            create: function (dto) {
                // All block names, that we pass via dto are simple strings that match block directive name.
                // This doesn't work with table because we have 'block-generic-table' block, which has other
                // table types. That's why in blockListFactory we have something like this strings like:
                // 'block-generic-table__BuilderSingleCells'. So everything after '__' is considered as
                // block sub type.
                if (dto && dto.MainType) {
                    var subType,
                        mainTypeSplit = dto.MainType.split("__");
                    dto.MainType = mainTypeSplit[0];
                    subType = mainTypeSplit[1];
                }

                switch (dto.MainType) {
                    case DTO_TYPES.ACTIVITY_LOG:
                        return new this.ActivityLogDto(dto);
                    case DTO_TYPES.BLOG:
                        return new this.BlogDto(dto);
                    case DTO_TYPES.COLLECTION:
                        return new this.CollectionDto(dto);
                    case DTO_TYPES.COLLECTION_WRAPPER:
                        return new this.CollectionWrapperDto(dto);
                    case DTO_TYPES.CV:
                        return new this.CvDto(dto);
                    case DTO_TYPES.FORM:
                        return new this.FormDto(dto);
                    case DTO_TYPES.FORM_RESPONSE:
                        return new this.FormResponseDto(dto);
                    case DTO_TYPES.POST:
                        return new this.PostDto(dto);
                    case DTO_TYPES.WEBFOLIO:
                        return new this.WebfolioDto(dto);
                    case DTO_TYPES.WEBFOLIO_PAGE:
                        return new this.WebfolioPageDto(dto);
                    case DTO_TYPES.WORKBOOK_PAGE:
                        return new this.WorkbookPageDto(dto);
                    case DTO_TYPES.WORKBOOK_RESPONSE:
                        return new this.WorkbookResponseDto(dto);
                    case DTO_TYPES.TEMPLATE_BUILDER:
                        return new this.TemplateBuilderDto(dto);
                    case DTO_TYPES.WORKBOOK_BUILDER:
                    case DTO_TYPES.WORKBOOK:
                        return new this.WorkbookBuilderDto(dto);
                    case DTO_TYPES.TXT_BLOCK:
                        return new this.TxtBlockDto(dto);
                    case DTO_TYPES.IMAGE_BLOCK:
                        return new this.MediaBlockDto(dto);
                    case DTO_TYPES.VIDEO_BLOCK:
                        return new this.MediaBlockDto(dto);
                    case DTO_TYPES.AUDIO_BLOCK:
                        return new this.MediaBlockDto(dto);
                    case DTO_TYPES.HERO_BLOCK:
                        return new this.HeroBlockDto(dto);
                    case DTO_TYPES.EMBED_BLOCK:
                        return new this.EmbedBlockDto(dto, subType, true);
                    case DTO_TYPES.EMBED_USER_BLOCK:
                        return new this.EmbedBlockDto(dto, subType, false);
                    case DTO_TYPES.QUOTE_BLOCK:
                        return new this.QuoteBlockDto(dto);
                    case DTO_TYPES.BADGE_BLOCK:
                        return new this.BadgeBlockDto();
                    case DTO_TYPES.SINGLE_LINE_BLOCK:
                        return new this.SingleLineBlockDto(dto);
                    case DTO_TYPES.MULTI_LINE_BLOCK:
                        return new this.MultiLineBlockDto(dto);
                    case DTO_TYPES.RADIO_BUTTON_BLOCK:
                        return new this.RadioButtonBlockDto(dto);
                    case DTO_TYPES.CHECKBOX_BLOCK:
                        return new this.CheckboxBlockDto(dto);
                    case DTO_TYPES.DROP_DOWN_BLOCK:
                        return new this.DropDownBlockDto(dto);
                    case DTO_TYPES.DATE_PICKER_BLOCK:
                        return new this.DatePickerBlockDto(dto);
                    case DTO_TYPES.DATE_DIGITAL_SIGNATURE:
                        return new this.DigitalSignatureBlockDto(dto);
                    case DTO_TYPES.MEDIA_PICKER_BLOCK:
                        return new this.MediaPickerBlockDto(dto);
                    case DTO_TYPES.TITLE_SINGLE_LINE_BLOCK:
                        return new this.TitleSingleLineBlockDto(dto);
                    case DTO_TYPES.BINARY_BLOCK:
                        return new this.BinaryBlockDto(dto);
                    case DTO_TYPES.GENERIC_TABLE_BLOCK:
                        return this.GenericTableBlock(subType);
                    case DTO_TYPES.NUMERIC_BLOCK:
                        return new this.NumericBlockDto(dto);
                    case DTO_TYPES.LIKERT_BLOCK:
                        return new this.LikertBlockDto(dto);
                    case DTO_TYPES.EVIDENCE_BLOCK:
                        return new this.EvidenceBlockDto(dto);
                    case DTO_TYPES.HINT_IMAGE_BLOCK:
                        return new this.HintImageBlockDto(dto);
                    case DTO_TYPES.HINT_VIDEO_BLOCK:
                        return new this.HintVideoBlockDto(dto);
                    case DTO_TYPES.HINT_AUDIO_BLOCK:
                        return new this.HintAudioBlockDto(dto);
                    case DTO_TYPES.HINT_TEXT_BLOCK:
                        return new this.HintTextBlockDto(dto);
                    case DTO_TYPES.CAPABILITY_RATING:
                        return new this.CapabilityRatingBlockDto(dto);
                    case DTO_TYPES.HORIZONTAL_RULE_BLOCK:
                        return new this.HorizontalRuleBlockDto();
                    case DTO_TYPES.CAPABILITY_RADIO_BUTTON_BLOCK:
                        return new this.CapabilityRadioBlockDto(dto);
                    case DTO_TYPES.CAPABILITY_BINARY:
                        return new this.CapabilityBinaryBlockDto(dto);
                    case DTO_TYPES.DYNAMIC_ELEMENT:
                        return new this.DynamicElementBlockDto();
                    case DTO_TYPES.STANDARD_BLOCK:
                        return new this.StandardBlockDto();
                    case DTO_TYPES.STANDARDS_SELECTION:
                        return new this.StandardsSelectionElementDto(dto);
                    case DTO_TYPES.CONTENT_SECTION:
                        return new this.ContentSectionBlockDto(dto);
                    default:
                        if (dto.ContentId !== undefined) {
                            return new this.FormPageDto(dto);
                        } else if (dto.PageAssetId !== undefined) {
                            return new this.PageDto(dto);
                        } else {
                            throw new Error("Oh oh! I don't know [" + dto.MainType + "] type of DTO. You can check type that you pass against types in 'dtoFactory'.");
                        }
                }
            },

            templateCategories: [
                { Key: "", Label: multiLanguageService.getString("labels.toolbar.select_a_category") },
                { Key: "Plan", Label: multiLanguageService.getString("labels.toolbar.plan") },
                { Key: "Experience", Label: multiLanguageService.getString("labels.toolbar.experience") },
                { Key: "Reflection", Label: multiLanguageService.getString("labels.toolbar.reflection") },
                { Key: "Talent", Label: multiLanguageService.getString("labels.toolbar.talent") },
                { Key: "Worksheet", Label: multiLanguageService.getString("labels.toolbar.worksheet") },
                { Key: "Feedback", Label: multiLanguageService.getString("labels.toolbar.feedback") }
            ],

            getTemplateCategories: function () {
                return this.templateCategories.slice();
            },

            getWorkbookTemplateCategories: function () {
                var categories = this.templateCategories.slice(),
                    exclude = ["", "Feedback"];

                return categories.filter(function (item) {
                    return exclude.indexOf(item.Key) === -1;
                });
            },

            /**
             * workbooks and responses
             */
            WorkbookResponseDto: function (dto) {
                this.AssetOptions = dto.AssetOptions || [];
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.Id = dto.Id || helperService.guid();
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.MainType = "WorkBookResponse";
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.Title = dto.Title || "";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // if nested into webfolio then "Invisible"
                this.WorkbookId = dto.WorkbookId;
                this.Pages = dto.Pages || [];
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;
                this.SubType = dto.WorkbookSubType;
            },

            AssessorSubmissionDto: function (dto) {
                this.Id = dto.Id || "";
                this.AtlasId = dto.AtlasId || "";
                this.SubmittedBy = dto.SubmittedBy || "";
                this.SubmittedOn = dto.SubmittedOn ? new Date(dto.SubmittedOn) : null;
                this.AssetId = dto.AssetId || "";
                this.Location = dto.Location || "";
                this.SubLocation = dto.SubLocation || "";
                this.LocationName = dto.LocationName || "";
                this.SubLocationName = dto.SubLocationName || "";
                this.LocationDescription = dto.LocationDescription || "";
                this.Url = dto.Url || "";
                this.Status = dto.Status || "";
                this.SubmittedFrom = dto.SubmittedFrom || "";
                this.RevisionDeadline = dto.RevisionDeadline ? new Date(dto.RevisionDeadline) : null;
                this.ServiceId = dto.ServiceId || "";
                this.LocationStatus = dto.LocationStatus || "";
                this.SelectedAssessorsEnabled = dto.SelectedAssessorsEnabled === true;
                this.ExternalAssessors = [];
                this.PreventUserFromRequestingSubmissionRemoval = dto.PreventUserFromRequestingSubmissionRemoval === true;
                this.LinkToSubmission = dto.LinkToSubmission;
            },

            WorkbookPageDto: function (dto) {
                this.UserResponseId = dto.UserResponseId;
                this.PageAssetId = dto.PageAssetId;
                this.PageId = dto.PageId;
                this.PagePosition = dto.PagePosition;
                this.PageTitle = dto.PageTitle;
                this.PageType = dto.PageType;
                this.Children = dto.Children || [];
                this.Revision = dto.Revision || 0;
            },

            /**
             * forms and responses
             */
            FormDto: function (dto) {
                this.AssetOptions = dto.AssetOptions || [];
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.Id = dto.Id || helperService.guid();
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.MainType = "Form";
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.Title = dto.Title || "";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // if nested into webfolio then "Invisible"
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;
                this.TemplateId = dto.TemplateId;
                this.TemplateSubType = dto.TemplateSubType;
                this.Elements = dto.Elements || [];
            },

            FormResponseDto: function (dto) {
                this.AssetOptions = dto.AssetOptions || [];
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.Id = dto.Id || helperService.guid();
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.MainType = "FormResponse";
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.Title = dto.Title || "";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // if nested into webfolio then "Invisible"
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;
                this.TemplateId = dto.TemplateId;
                this.TemplateSubType = dto.TemplateSubType;
                this.Elements = dto.Elements || [];
                this.ProgressTrackingEnabled = dto.ProgressTrackingEnabled || false; // if progress tracking was enabled on the FormDto
                this.ProgressTrackingComplete = dto.ProgressTrackingComplete || false;

                this.user_selected_me = dto.user_selected_me || false; // only used during revision decisions
            },

            /**
             *
             * @param dto
             * @constructor
             */
            WebfolioDto: function (dto) {
                this.AssetOptions = dto.AssetOptions || [];
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.Id = dto.Id || helperService.guid();
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.MainType = "Webfolio";
                this.Pages = dto.Pages || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.Title = dto.Title || "";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // if nested into webfolio then "Invisible"
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;
            },

            PageDto: function (dto) {
                // TODO: remove GUID();
                var guid = helperService.guid();
                this.PageAssetId = dto.PageAssetId || guid;
                this.PageId = dto.PageId || guid;
                this.PagePosition = dto.PagePosition || 0;
                this.PageTitle = dto.PageTitle || "";
                this.PageType = dto.PageType || "WebfolioPage";
                this.PageIcon = this.PageType === "Webfolio" ? "webfolio" : dto.PageIcon || "webfoliopage";
                this.Children = dto.Children || [];
                this.PageFolder = dto.PageFolder || "Invisible";
                this.PageNavigationEditable = true;
                this.Revision = dto.Revision || 0;
                this.IsLockedOrParentLocked = dto.IsLockedOrParentLocked;
            },

            FormPageDto: function (dto) {
                var guid = helperService.guid();
                this.Children = dto.Children || [];
                this.CollectionCategoryOrAssetType = dto.CollectionCategoryOrAssetType || "";
                this.CollectionAssetType = dto.CollectionAssetType || "";
                this.CollectionResponsesTo = dto.CollectionResponsesTo || null;
                this.CollectionCreatedAfter = dto.CollectionCreatedAfter || null;
                this.CollectionCreatedBefore = dto.CollectionCreatedBefore || null;
                this.CollectionTags = dto.CollectionTags || [];
                this.CollectionUserCreated = dto.CollectionUserCreated || false;
                this.CollectionEnableSearchByDefault = dto.CollectionEnableSearchByDefault || false;
                this.ContentId = dto.ContentId || "";
                this.HintForUser = dto.HintForUser || "";
                this.InstructionForUser = dto.InstructionForUser || "";
                this.OrderNo = dto.OrderNo || 0;
                this.PageIcon = dto.PageIcon ? helperService.getWorkbookPageIcon(dto.PageIcon, true) : "worksheet-reversed";
                this.PageId = dto.PageId || guid;
                this.PageReleaseOnVerifiedPageId = dto.PageReleaseOnVerifiedPageId || "";
                this.PageReleaseShowFrom = dto.PageReleaseShowFrom || null;
                this.PageReleaseType = dto.PageReleaseType || "AlwaysShow";
                this.PageNavigationEditable = true;
                this.PageTitle = dto.PageTitle || "";
                this.PageType = dto.PageType || "Form";
                this.PageFolder = dto.PageFolder || "Invisible";
                this.PageDescription = dto.PageDescription || "";
                this.TargetHours = dto.TargetHours || 0;
                this.TargetPoints = dto.TargetPoints || 0;
                this.TrackingDisabledForThisPage = dto.TrackingDisabledForThisPage || false;
                this.MinsPtsContributionDisabled = dto.MinsPtsContributionDisabled || false;
                this.LockOnVerification = dto.LockOnVerification === true;
            },

            PostDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.MainType = "Post";
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // "Invisible" if PageDTO was created
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;

                // add a banner section
                var bannerImage = "Pebbles.jpg";
                var subTitle = "";

                // Add text block
                if (dto.hasOwnProperty("AddDefaultBannerSection") && dto.AddDefaultBannerSection === true) {
                    // change it straight to false so that if the dto gets past this method again nothing will be overwritten
                    dto.AddDefaultBannerSection = false;

                    this.Sections = [
                        {
                            Id: helperService.guid(),
                            OrderNo: 0,
                            SectionType: "ContentSectionRow",
                            SectionOptions: [{ Key: "editable", Value: false }],
                            SectionIsFull: true,
                            AllowSideBySide: false,
                            SectionUneditable: true,
                            StyleOptions: [
                                { Key: "background-color", Value: "transparent", Target: "section" },
                                { Key: "width", Value: "section-large", Target: "container" },
                                { Key: "block-width", Value: 100, Target: "container" }
                            ],
                            Sections: [
                                {
                                    Id: helperService.guid(),
                                    OrderNo: 0,
                                    SectionType: "ContentSectionHero",
                                    SectionOptions: [
                                        { Key: "titleBanner", Value: true },
                                        { Key: "editable", Value: false }
                                    ],
                                    StyleOptions: [
                                        { Key: "banner-block-height", Value: "full" },
                                        { Key: "image-position", Value: "center center" },
                                        { Key: "hero-template", Value: "text-below" },
                                        { Key: "horizontal-rule", Value: false },
                                        { Key: "include-subtitle", Value: false }
                                    ],
                                    HeroType: "",
                                    BackgroundImageAssetId: dto.BackgroundAssetId || bannerImage,
                                    BackgroundImageTitle: "",
                                    SectionUneditable: true,
                                    Title: dto.Title || "",
                                    SubTitle: subTitle,
                                    LineDivider: false,
                                    ShowButton: false,
                                    ButtonName: "",
                                    ButtonType: "",
                                    ButtonActionOrUrl: ""
                                }
                            ]
                        },
                        {
                            Id: helperService.guid(),
                            OrderNo: 1,
                            SectionType: "ContentSectionRow",
                            SectionOptions: [],
                            SectionIsFull: false,
                            StyleOptions: [
                                { Key: "background-color", Value: "transparent", Target: "section" },
                                { Key: "width", Value: "section-small", Target: "container" },
                                { Key: "block-width", Value: 100, Target: "container" }
                            ],
                            Sections: [
                                {
                                    ButtonActionOrUrl: "",
                                    ButtonName: "",
                                    ButtonPosition: "",
                                    ButtonType: "",
                                    Id: helperService.guid(),
                                    LineDivider: false,
                                    OrderNo: 1,
                                    SectionOptions: [{ Key: "FirstPostTextBlock", Value: true }],
                                    SectionType: "ContentSectionText",
                                    StyleOptions: [
                                        { Key: "include-title", Value: false },
                                        { Key: "text-columns", Value: 1 }
                                    ],
                                    Title: "",
                                    Text: ""
                                }
                            ]
                        }
                    ];
                } else {
                    this.Sections = dto.Sections || [];
                }
            },

            /**
             *
             * @param dto
             * @constructor
             */
            WebfolioPageDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.MainType = "WebfolioPage";
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // "Invisible" if PageDTO was created
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;

                // decide whether or not to add a default banner
                // tslint:disable-next-line:triple-equals
                if (dto.hasOwnProperty("AddDefaultBannerSection") && dto.AddDefaultBannerSection !== undefined && dto.AddDefaultBannerSection !== null && dto.AddDefaultBannerSection == true) {
                    // change it straight to false so that if the dto gets past this method again nothing will be overwritten
                    dto.AddDefaultBannerSection = false;

                    // add a default row with a default banner
                    this.Sections = [
                        {
                            Id: helperService.guid(),
                            OrderNo: 0,
                            SectionType: "ContentSectionRow",
                            SectionOptions: [],
                            SectionIsFull: true,
                            StyleOptions: [
                                { Key: "background-color", Value: "transparent", Target: "section" },
                                { Key: "width", Value: "section-large", Target: "container" },
                                { Key: "block-width", Value: 100, Target: "container" }
                            ],
                            Sections: [
                                {
                                    Id: helperService.guid(),
                                    OrderNo: 0,
                                    SectionType: "ContentSectionHero",
                                    SectionOptions: [],
                                    StyleOptions: [
                                        { Key: "banner-block-height", Value: "full" },
                                        { Key: "image-position", Value: "center center" },
                                        { Key: "hero-template", Value: "default" },
                                        { Key: "horizontal-rule", Value: false },
                                        { Key: "include-subtitle", Value: true }
                                    ],
                                    HeroType: "",
                                    BackgroundImageAssetId: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "builder/blockHero/defaultImages/Pebbles.jpg"),
                                    BackgroundImageTitle: "",
                                    Title: "",
                                    SubTitle: "",
                                    LineDivider: false,
                                    ShowButton: false,
                                    ButtonName: "",
                                    ButtonType: "",
                                    ButtonActionOrUrl: ""
                                }
                            ]
                        }
                    ];
                } else {
                    this.Sections = dto.Sections || [];
                }
            },

            // COLLECTIONS
            CollectionWrapperDto: function (dto) {
                //this.Items = dto.Items;
                //this.AssetCollections = dto.AssetCollections;

                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.Description = dto.Description || "";
                this.MainType = "AssetCollectionWrapper";
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // "Invisible" if PageDTO was created
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;

                this.ProgressTrackingTarget = dto.ProgressTrackingTarget || 0;
                this.AssetCollections = dto.AssetCollections || [];
                this.Items = dto.Items || [];

                // add a banner section
                var bannerImage = "Pebbles.jpg";
                var subTitle = "";
                if (this.AssetOptions.length) {
                    for (var i = 0; i < this.AssetOptions.length; i++) {
                        var option = this.AssetOptions[i];
                        if (option.Key === "BackgroundImageAssetId") {
                            bannerImage = option.Value;
                        }
                        if (option.Key === "SubTitle") {
                            subTitle = option.Value;
                        }
                    }
                } else {
                    this.AssetOptions.push({ Key: "BackgroundImageAssetId", Value: bannerImage });
                }
            },

            CollectionDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.Description = dto.Description || "";
                this.MainType = "AssetCollection";
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // "Invisible" for collectiondtos
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;

                this.StaticCollection = dto.StaticCollection || false;
                this.StaticCollectionAssetIds = dto.StaticCollectionAssetIds || [];
                this.Filter = dto.Filter || {};
                this.Search = dto.Search || {};
                this.NotActive = false; // Override old ones so they are always saved as active.
            },

            // ACTIVITY LOG
            ActivityLogDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.Description = dto.Description || "";
                this.MainType = "ActivityLog";
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // "Invisible" if PageDTO was created
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;

                this.TargetHours = dto.TargetHours;
                this.TargetPoints = dto.TargetPoints;
                this.AssetCollections = dto.AssetCollections || [];
                this.Items = dto.Items || [];

                // add a banner section
                var bannerImage = "Pebbles.jpg";
                var subTitle = "";
                if (this.AssetOptions.length) {
                    for (var i = 0; i < this.AssetOptions.length; i++) {
                        var option = this.AssetOptions[i];
                        if (option.Key === "BackgroundImageAssetId") {
                            bannerImage = option.Value;
                        }
                        if (option.Key === "SubTitle") {
                            subTitle = option.Value;
                        }
                    }
                } else {
                    this.AssetOptions.push({ Key: "BackgroundImageAssetId", Value: bannerImage });
                }
            },

            /**
             * Constructor for BlogDto
             * @param dto
             * @constructor
             */
            BlogDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.Description = dto.Description || "";
                this.MainType = "Blog";
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // "Invisible" for blog dtos
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;

                this.DefaultSortOrder = dto.DefaultSortOrder || "descending";
                this.DefaultOrderBy = dto.DefaultOrderBy || "posted";
                this.Posts = dto.Posts || [];
                this.DateFilters = dto.DateFilters || [];

                // add a banner section
                var bannerImage = "Pebbles.jpg";
                var subTitle = "";

                if (this.AssetOptions.length) {
                    for (var i = 0; i < this.AssetOptions.length; i++) {
                        var option = this.AssetOptions[i];
                        if (option.Key === "BackgroundImageAssetId") {
                            bannerImage = option.Value;
                        }
                        if (option.Key === "SubTitle") {
                            subTitle = option.Value;
                        }
                    }
                } else {
                    this.AssetOptions.push({ Key: "BackgroundImageAssetId", Value: bannerImage });
                }

                this.BannerSection = {
                    RowSection: {
                        Id: helperService.guid(),
                        OrderNo: 0,
                        SectionType: "ContentSectionRow",
                        SectionOptions: [],
                        SectionIsFull: true,
                        StyleOptions: [
                            { Key: "background-color", Value: "transparent", Target: "section" },
                            { Key: "width", Value: "section-large", Target: "container" },
                            { Key: "block-width", Value: 100, Target: "container" }
                        ]
                    },
                    Block: {
                        Id: helperService.guid(),
                        OrderNo: 0,
                        SectionType: "ContentSectionHero",
                        SectionOptions: [],
                        StyleOptions: [
                            { Key: "hero-template", Value: "default" },
                            { Key: "horizontal-rule", Value: false },
                            { Key: "include-subtitle", Value: true }
                        ],
                        HeroType: "",
                        BackgroundImageAssetId: bannerImage,
                        BackgroundImageTitle: "",
                        Title: dto.Title || "",
                        SubTitle: subTitle,
                        LineDivider: false,
                        ShowButton: false,
                        ButtonName: "",
                        ButtonType: "",
                        ButtonActionOrUrl: ""
                    }
                };
            },

            CvDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.Description = dto.Description || "";
                this.MainType = "CV";
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible"; // "Invisible" if PageDTO was created
                this.Revision = dto.Revision || 0;

                this.CallingCard = dto.CallingCard;
                this.AssetCollections = dto.AssetCollections || [];
                this.Items = dto.Items || [];

                // add a banner section
                var bannerImage = "Pebbles.jpg";
                var subTitle = "";
                if (this.AssetOptions.length) {
                    for (var i = 0; i < this.AssetOptions.length; i++) {
                        var option = this.AssetOptions[i];
                        if (option.Key === "BackgroundImageAssetId") {
                            bannerImage = option.Value;
                        }
                        if (option.Key === "SubTitle") {
                            subTitle = option.Value;
                        }
                    }
                }

                this.BannerSection = {
                    RowSection: {
                        Id: helperService.guid(),
                        OrderNo: 0,
                        SectionType: "ContentSectionRow",
                        SectionOptions: [],
                        SectionIsFull: true,
                        StyleOptions: [
                            { Key: "background-color", Value: "transparent", Target: "section" },
                            { Key: "width", Value: "section-large", Target: "container" },
                            { Key: "block-width", Value: 100, Target: "container" }
                        ]
                    },
                    Block: {
                        Id: helperService.guid(),
                        OrderNo: 0,
                        SectionType: "ContentSectionHero",
                        SectionOptions: [],
                        StyleOptions: [
                            { Key: "hero-template", Value: "no-text" },
                            { Key: "horizontal-rule", Value: false }
                        ],
                        HeroType: "",
                        BackgroundImageAssetId: bannerImage,
                        BackgroundImageTitle: "",
                        Title: dto.Title || "",
                        SubTitle: subTitle,
                        LineDivider: false,
                        ShowButton: false,
                        ButtonName: "",
                        ButtonType: "",
                        ButtonActionOrUrl: ""
                    }
                };
            },

            TemplateBuilderDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.MainType = "TemplateBuilder";
                this.SubType = dto.SubType || null;
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible";
                this.CascadeTags = dto.CascadeTags || false;
                this.Locked = dto.Locked || false;
                this.Usage = dto.Usage || "true";
                this.IsTask = dto.IsTask || false;
                this.PromptMax = dto.PromptMax || 0;
                this.PreferenceIncludeHintWhenAssetIsViewed =
                    dto.PreferenceIncludeHintWhenAssetIsViewed === undefined || dto.PreferenceIncludeHintWhenAssetIsViewed === null ? true : dto.PreferenceIncludeHintWhenAssetIsViewed;
                this.PreferenceShowEmptyFieldWhenAnAssetIsViewed =
                    dto.PreferenceShowEmptyFieldWhenAnAssetIsViewed === undefined || dto.PreferenceShowEmptyFieldWhenAnAssetIsViewed === null ? true : dto.PreferenceShowEmptyFieldWhenAnAssetIsViewed;
                this.PreferenceUseInFieldHintsWherePossible =
                    dto.PreferenceUseInFieldHintsWherePossible === undefined || dto.PreferenceUseInFieldHintsWherePossible === null ? true : dto.PreferenceUseInFieldHintsWherePossible;
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;
                this.AllowUserToChangeBanner = dto.AllowUserToChangeBanner === undefined || dto.AllowUserToChangeBanner === null ? true : dto.AllowUserToChangeBanner;
                this.EnableProgressTracking = dto.EnableProgressTracking || false;
                this.PebbleFormPurpose = dto.PebbleFormPurpose;
                this.FormLanguage = dto.FormLanguage;

                // decide whether or not to add a default banner
                if (dto.hasOwnProperty("AddDefaultBannerSection") && dto.AddDefaultBannerSection !== undefined && dto.AddDefaultBannerSection !== null && dto.AddDefaultBannerSection === true) {
                    // change it straight to false so that if the dto gets past this method again nothing will be overwritten
                    dto.AddDefaultBannerSection = false;

                    this.Sections = dto.Sections || [
                        {
                            Id: helperService.guid(),
                            OrderNo: 0,
                            SectionType: "BuilderFormElementRow",
                            StyleOptions: [
                                { Key: "background-color", Value: "transparent", Target: "section" },
                                { Key: "width", Value: "section-large", Target: "container" },
                                { Key: "block-width", Value: 100, Target: "container" }
                            ],
                            Sections: [
                                {
                                    Id: helperService.guid(),
                                    OrderNo: 0,
                                    SectionOptions: [],
                                    SectionType: "BuilderContentSection",
                                    ContentSectionType: "ContentSectionHero",
                                    FilledInByRespondant: true,
                                    SectionIsFull: true,
                                    ContentSection: {
                                        Id: helperService.guid(),
                                        SectionType: "ContentSectionHero",
                                        OrderNo: 0,
                                        ImageAssetId: "",
                                        BackgroundImageAssetId: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "builder/blockHero/defaultImages/Pebbles.jpg"),
                                        SectionOptions: [{ Key: "HeroBannerDefaultImage", Value: "Pebbles.jpg" }],
                                        StyleOptions: [
                                            { Key: "block-width", Value: 100, Target: "container" },
                                            { Key: "banner-block-height", Value: "thin" },
                                            { Key: "image-position", Value: "center center" },
                                            { Key: "hero-template", Value: "no-text" },
                                            { Key: "horizontal-rule", Value: false },
                                            { Key: "include-subtitle", Value: true }
                                        ]
                                    },
                                    StyleOptions: [
                                        { Key: "width", Value: "section-large", Target: "container" },
                                        { Key: "block-width", Value: 100, Target: "container" }
                                    ]
                                }
                            ]
                        },
                        {
                            Id: helperService.guid(),
                            OrderNo: 1,
                            SectionType: "BuilderFormElementRow",
                            StyleOptions: [
                                { Key: "background-color", Value: "transparent", Target: "section" },
                                { Key: "width", Value: "medium", Target: "container" },
                                { Key: "block-width", Value: 100, Target: "container" }
                            ],
                            Sections: [
                                {
                                    Id: helperService.guid(),
                                    OrderNo: 1,
                                    SectionOptions: [],
                                    SectionType: "BuilderTitleSingleLineTextBox",
                                    ElementLabel: multiLanguageService.getString("labels.builder.save_as"),
                                    ElementHint: "",
                                    StyleOptions: [
                                        { Key: "element-purpose-title-required", Value: true },
                                        { Key: "prescribed-title", Value: false }
                                    ]
                                }
                            ]
                        }
                    ];
                } else {
                    this.Sections = dto.Sections || [
                        {
                            Id: helperService.guid(),
                            OrderNo: 1,
                            SectionType: "BuilderFormElementRow",
                            StyleOptions: [
                                { Key: "background-color", Value: "transparent", Target: "section" },
                                { Key: "width", Value: "medium", Target: "container" },
                                { Key: "block-width", Value: 100, Target: "container" }
                            ],
                            Sections: [
                                {
                                    Id: helperService.guid(),
                                    OrderNo: 1,
                                    SectionOptions: [],
                                    SectionType: "BuilderTitleSingleLineTextBox",
                                    ElementLabel: multiLanguageService.getString("labels.builder.save_as"),
                                    ElementHint: "",
                                    StyleOptions: [
                                        { Key: "element-purpose-title-required", Value: true },
                                        { Key: "prescribed-title", Value: false }
                                    ]
                                }
                            ]
                        }
                    ];
                }
            },

            WorkbookBuilderDto: function (dto) {
                this.Id = dto.Id || helperService.guid();
                this.Title = dto.Title || "";
                this.Description = dto.Description || "";
                this.MainType = "WorkbookBuilder";
                this.SubType = dto.SubType || null;
                this.Created = helperService.normalizeDate(dto.Created) || new Date();
                this.CreatedBy = dto.CreatedBy || undefined;
                this.Authors = dto.Authors || [];
                this.LastModified = helperService.normalizeDate(dto.LastModified) || new Date();
                this.LastModifiedBy = dto.LastModifiedBy || undefined;
                this.AssetOptions = dto.AssetOptions || [];
                this.StorageServiceId = dto.StorageServiceId || "Default";
                this.AssetFolder = dto.AssetFolder || "Invisible";
                this.Pages = dto.Pages || [];
                this.CascadeTags = dto.CascadeTags || false;
                this.Locked = dto.Locked || false;
                this.Usage = dto.Usage || "true";
                this.IsTask = dto.IsTask || false;
                this.PromptMax = dto.PromptMax || 0;
                this.AddProgressTracking = dto.AddProgressTracking || false;
                this.Revision = dto.Revision || 0;
                this.BaseVersion = dto.BaseVersion || this.Revision;
                this.AutoSumWorkbookCpdValues = !!dto.AutoSumWorkbookCpdValues;
                this.WorkbookPurpose = dto.WorkbookPurpose;
                this.WorkbookLanguage = dto.WorkbookLanguage;
            },

            /**
             * Constructor for Text block object
             * @constructor
             */
            TxtBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "blockText" }]);

                // local
                this.Text = "";
                this.Title = "";
                this.ButtonPosition = "";
                this.ButtonName = "";
                this.ButtonType = "";
                this.ButtonActionOrUrl = "";
                //this.ShowSectionTitle = false;
                this.LineDivider = false;
                //this.ShowButton = false;

                this.StyleOptions = [
                    { Key: "include-title", Value: false },
                    { Key: "text-columns", Value: 1 }
                    //{Key: "line-divider", Value: false},
                    //{Key: "include-button", Value: false}
                ];
            },

            /**
             * Constructor for Media block object
             * @constructor
             */
            MediaBlockDto: function (dto) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "blockMedia" }]);

                // local
                this.MediaAssetId = "";
                this.MediaType = MEDIA_TYPES[dto.MainType];
                this.MediaTitle = "";
                this.Title = "";
                this.FitToBox = false;
                this.ShowCaption = false;
                this.Caption = "";
                this.ExternalMedia = false;
                this.AltText = null;

                this.StyleOptions = [
                    { Key: "include-title", Value: false },
                    { Key: "include-caption", Value: false }
                ];

                if (MEDIA_TYPES[dto.MainType] === "video") {
                    this.StyleOptions.push({ Key: "video-controls", Value: "" });
                } else if (MEDIA_TYPES[dto.MainType] === "audio") {
                    this.StyleOptions.push({ Key: "audio-controls", Value: "" });
                } else if (MEDIA_TYPES[dto.MainType] === "image") {
                    this.StyleOptions.push({ Key: "image-click-state", Value: "Empty," });
                }
            },

            /**
             * Constructor for Media block object
             * @constructor
             */
            HeroBlockDto: function (dto) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "blockHero" }]);

                // local
                this.HeroType = "";
                this.BackgroundImageAssetId = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "builder/blockHero/defaultImages/Pebbles.jpg");
                this.BackgroundImageTitle = "";
                this.Title = "";
                this.SubTitle = "";
                this.LineDivider = false;
                this.ShowButton = false;
                this.ButtonName = "";
                this.ButtonType = "";
                this.ButtonActionOrUrl = "";

                var defaultStyleOptions = [
                    { Key: "block-width", Value: 100, Target: "container" },
                    { Key: "banner-block-height", Value: "full" },
                    { Key: "image-position", Value: "center center" },
                    { Key: "hero-template", Value: "default" },
                    { Key: "horizontal-rule", Value: false },
                    { Key: "include-subtitle", Value: true }
                ];

                this.StyleOptions = dto && dto.StyleOptions ? dto.StyleOptions.uniquePropertyConcat(defaultStyleOptions, "Key") : defaultStyleOptions;
            },

            /**
             * Constructor for Embed block object
             * @constructor
             */
            EmbedBlockDto: function (dto, subType, allowSideBySide) {
                var sectionType = subType ? subType : dto.MainType;

                // extended
                SectionDtoClass.apply(this, [{ SectionType: sectionType, AllowSideBySide: allowSideBySide }]);

                // local
                this.EmbedUrl = "";
                this.ServiceName = "";
                this.CompletableInResponse = false;
                this.StyleOptions = [];
                this.DisplayProperties = {};

                switch (subType) {
                    case "BuilderUserEmbed":
                        this.ElementLabel = "";
                        this.ElementHint = "";
                        this.CompletableInResponse = true;
                        break;
                    case "BuilderHintEmbed":
                        this.StyleOptions = [{ Key: STYLE_OPTIONS.SHOW_HINT_BLOCK_WHEN_VIEWING, Value: true }];
                        this.ElementLabel = "";
                        this.ElementHint = "";
                        break;
                    default:
                        this.StyleOptions = [{ Key: STYLE_OPTIONS.INCLUDE_TITLE, Value: false }];
                        this.Title = "";
                        break;
                }
            },

            /**
             * Constructor for Quote block object
             * @constructor
             */
            QuoteBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "blockQuote" }]);

                // local
                this.Quote = "";
                this.Citation = "";

                this.StyleOptions = [{ Key: "include-citation", Value: true }];
            },

            /**
             * Constructor for Badge block object
             * @constructor
             */
            BadgeBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "blockBadge" }]);

                // local
                this.Title = "";
                this.BadgeAssetId = "";
                this.CriteriaUrl = "";
                this.Description = "";
                this.Created = "";
                this.ImageUrl = "";
                this.IssuerContact = "";
                this.IssuerName = "";
                this.IssuerOrg = "";
                this.IssuerUrl = "";
                this.StyleOptions = [];
            },

            ContentSectionBlockDto: function (dto) {
                SectionDtoClass.apply(this, [{ SectionType: "ContentSection" }]);
                this.SectionIsFull = true;
                this.FilledInByRespondant = true;

                var overrides = {
                    "block-hero": {
                        styleOptions: [
                            { Key: "banner-block-height", Value: "thin" },
                            { Key: "hero-template", Value: "no-text" }
                        ]
                    }
                };

                var blockOverrides = overrides[dto.ChildType];
                this.ContentSection = dtoFactory.create({
                    MainType: dto.ChildType,
                    StyleOptions: blockOverrides !== void 0 ? blockOverrides.styleOptions : []
                });
                this.ContentSectionType = this.ContentSection.SectionType;
                this.StyleOptions = [
                    { Key: "width", Value: "section-large", Target: "container" },
                    { Key: "block-width", Value: 100, Target: "container" }
                ];
            },

            /**
             * Constructor for Single Line Text block object
             * @constructor
             */
            SingleLineBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderSingleLineTextBox" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.StyleOptions = [
                    { Key: STYLE_OPTIONS.INFIELD_HINT, Value: false },
                    { Key: STYLE_OPTIONS.ADD_EVIDENCE_COMPONENT, Value: false },
                    { Key: STYLE_OPTIONS.SHOW_HINT_WHEN_VIEWING, Value: true },
                    { Key: STYLE_OPTIONS.SHOW_EMPTY_FIELD_WHEN_VIEWING, Value: true },
                    { Key: STYLE_OPTIONS.PRIVACY_STATE, Value: "Normal" },
                    { Key: STYLE_OPTIONS.MANDATORY_FOR_PROGRESS, Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for Multi Line Text block object
             * @constructor
             */
            MultiLineBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderMultiLineTextBox" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.MaxWords = 0;
                this.MinWords = 0;

                this.StyleOptions = [
                    { Key: STYLE_OPTIONS.WORD_COUNT_ENABLED, Value: false },
                    { Key: STYLE_OPTIONS.INFIELD_HINT, Value: false },
                    { Key: STYLE_OPTIONS.ADD_EVIDENCE_COMPONENT, Value: false },
                    { Key: STYLE_OPTIONS.SHOW_HINT_WHEN_VIEWING, Value: true },
                    { Key: STYLE_OPTIONS.SHOW_EMPTY_FIELD_WHEN_VIEWING, Value: true },
                    { Key: STYLE_OPTIONS.PRIVACY_STATE, Value: "Normal" },
                    { Key: STYLE_OPTIONS.MANDATORY_FOR_PROGRESS, Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for Radio button block object
             * @constructor
             */
            RadioButtonBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderOptionsListRadioButtons" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.WrapAt = BlockInputOptionsHelper.getInlineOptions().default;

                this.Radios = [
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    }
                ];

                this.StyleOptions = [
                    { Key: "display-horizontal", Value: false },
                    { Key: "hide-unselected-options", Value: false },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for Checkbox block object
             * @constructor
             */
            CheckboxBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderOptionsListCheckboxes" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.WrapAt = BlockInputOptionsHelper.getInlineOptions().default;
                this.Checkboxes = [
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    }
                ];

                this.StyleOptions = [
                    { Key: "display-horizontal", Value: false },
                    { Key: "hide-unselected-options", Value: false },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for Drop down block object
             * @constructor
             */
            DropDownBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderOptionsListDropDown" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.Dropdown = [
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    }
                ];

                this.StyleOptions = [
                    { Key: "hide-unselected-options", Value: false },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for Date picker block object
             * @constructor
             */
            DatePickerBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderDatePickerInput" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.StyleOptions = [
                    { Key: "include-time", Value: false },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for digital signature block object
             * @constructor
             */
            DigitalSignatureBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderFormSignatureElement" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.StyleOptions = [
                    { Key: "signatory-options", Value: "" },
                    { Key: "blank-or-warn-on-edit", Value: "warn" },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true }
                ];
            },

            /**
             * Constructor for media picker block object
             * @constructor
             */
            MediaPickerBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderFormUserMediaPicker" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.AltText = null;

                this.StyleOptions = [
                    { Key: "media-picker-types", Value: "image,video,audio,document" },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "mandatory-for-progress", Value: false }
                ];
            },

            /**
             * Constructor for dynamic element block object
             * @constructor
             */
            DynamicElementBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderDynamicElement", AllowSideBySide: true }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.StyleOptions = [];
            },

            /**
             * Constructor for standard element block object
             * @constructor
             */
            StandardBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderStandards" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.ElementInfo = "";

                this.StyleOptions = [];
            },

            /**
             * Constructor for TITLE Single Line Text block object
             * @constructor
             */
            TitleSingleLineBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderTitleSingleLineTextBox" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.StyleOptions = [
                    { Key: "element-purpose-title-required", Value: true },
                    { Key: "prescribed-title", Value: false }
                ];
            },

            HorizontalRuleBlockDto: function () {
                SectionDtoClass.apply(this, [{ SectionType: "BuilderHorizontalRule" }]);
                this.StyleOptions = [{ Key: "show-horizontal-line-on-preview", Value: true }];
            },

            /**
             * Constructor for Binary block object
             * @constructor
             */
            BinaryBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderBinaryAnswerInput" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.NegativeLabel = "";
                this.PositiveLabel = "";

                this.StyleOptions = [
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            GenericTableBlock: function (tableType) {
                switch (tableType) {
                    case tableConfig.type.builder.table.columnsOnly:
                        return new this.FixedRowBlockDto(tableType);
                    case tableConfig.type.builder.table.rowsOnly:
                        return new this.TableBasicRowBlockDto(tableType);
                    case tableConfig.type.builder.table.rowsAndColumns:
                        return new this.TableBasicColRowBlockDto(tableType);
                    case tableConfig.type.builder.table.singleCell:
                        return new this.SingleCellsBlockDto(tableType);
                    case tableConfig.type.builder.rubric.rowsAndColumns:
                        return new this.BuilderRubricContainer(tableType);
                    default:
                        throw new Error('Unknown table type "' + tableType + '"!');
                }
            },

            FixedRowBlockDto: function (tableType) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: SECTION_TYPES.GenericBuilderTable }]);

                var tableSize = tableConfig.configs.defaultTableSize[tableType],
                    builderToolbarValue = tableSize - 1,
                    newDto = tableDtoHelper.generateNewDto({
                        calculateColWidth: tableDataHelper.calculateColWidth,
                        guid: helperService.guid,
                        tableType: tableType,
                        tableSize: tableSize
                    });

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.TableType = tableType;
                this.Rows = newDto.Rows;
                this.Columns = newDto.Cols;

                this.StyleOptions = [
                    { Key: "number-of-cols-and-rows", Value: tableSize + "," + builderToolbarValue, Target: "section" },
                    { Key: "repeating-rows", Value: "FixedRows", Target: "section" },
                    { Key: "add-evidence-component", Value: "false", Target: "section" },
                    { Key: "show-hint-when-viewing", Value: "true", Target: "section" },
                    { Key: "show-empty-field-when-viewing", Value: "true", Target: "section" },
                    { Key: "privacy-state-without-assessor", Value: "Normal", Target: "section" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            SingleCellsBlockDto: function (tableType) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: SECTION_TYPES.GenericBuilderTable }]);

                var tableSize = tableConfig.configs.defaultTableSize[tableType],
                    newDto = tableDtoHelper.generateNewDto({
                        guid: helperService.guid,
                        tableType: tableType,
                        tableSize: tableSize
                    });

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.TableType = tableType;
                this.Cells = newDto;

                this.StyleOptions = [
                    { Key: "number-of-cells", Value: tableSize, Target: "section" },
                    { Key: "number-of-cells-per-row", Value: "2", Target: "section" },
                    { Key: "add-evidence-component", Value: "false", Target: "section" },
                    { Key: "show-hint-when-viewing", Value: "true", Target: "section" },
                    { Key: "show-empty-field-when-viewing", Value: "true", Target: "section" },
                    { Key: "privacy-state-without-assessor", Value: "Normal", Target: "section" },
                    { Key: "mandatory-for-progress", Value: false }
                ];
            },

            TableBasicRowBlockDto: function (tableType) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: SECTION_TYPES.GenericBuilderTable }]);

                var tableSize = tableConfig.configs.defaultTableSize[tableType],
                    builderToolbarValue = tableSize - 1,
                    newDto = tableDtoHelper.generateNewDto({
                        calculateColWidth: tableDataHelper.calculateColWidth,
                        guid: helperService.guid,
                        tableType: tableType,
                        tableSize: tableSize
                    });

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.TableType = tableType;
                this.Rows = newDto.Rows;
                this.Columns = newDto.Cols;

                this.StyleOptions = [
                    { Key: "number-of-cols-and-rows", Value: builderToolbarValue + "," + builderToolbarValue },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state-without-assessor", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false }
                ];
            },

            /**
             * Constructor for Table Col and Row headers block object
             * @constructor
             */
            TableBasicColRowBlockDto: function (tableType) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: SECTION_TYPES.GenericBuilderTable }]);

                var tableSize = tableConfig.configs.defaultTableSize[tableType],
                    builderToolbarValue = tableSize - 1,
                    newDto = tableDtoHelper.generateNewDto({
                        calculateColWidth: tableDataHelper.calculateColWidth,
                        guid: helperService.guid,
                        tableType: tableType,
                        tableSize: tableSize
                    });

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.TableType = tableType;
                this.Rows = newDto.Rows;
                this.Columns = newDto.Cols;

                this.StyleOptions = [
                    { Key: "number-of-cols-and-rows", Value: builderToolbarValue + "," + builderToolbarValue },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state-without-assessor", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false }
                ];
            },

            /**
             * Constructor for Table Col and Row headers block object
             * @constructor
             */
            BuilderRubricContainer: function (tableType) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: SECTION_TYPES.GenericBuilderTable }]);

                var tableSize = tableConfig.configs.defaultTableSize[tableType],
                    builderToolbarValue = tableSize - 1,
                    newDto = tableDtoHelper.generateNewDto({
                        calculateColWidth: tableDataHelper.calculateColWidth,
                        guid: helperService.guid,
                        tableType: tableType,
                        tableSize: tableSize
                    });

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.TableType = tableType;
                this.Rows = newDto.Rows;
                this.Columns = newDto.Cols;

                this.StyleOptions = [
                    { Key: "number-of-cols-and-rows", Value: builderToolbarValue + "," + builderToolbarValue },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for Numeric block object
             * @constructor
             */

            LikertBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderRangeInputLikert" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";

                this.NegativeLabel = "";
                this.PositiveLabel = "";

                this.StyleOptions = [
                    { Key: "likert-increment", Value: "4" },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            /**
             * Constructor for Numeric block object
             * @constructor
             */
            NumericBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderRangeInputStandard" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.NegativeLabel = "";
                this.PositiveLabel = "";

                this.StyleOptions = [
                    { Key: "numeric-range", Value: "1,4" },
                    { Key: "add-evidence-component", Value: false },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state", Value: "Normal" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CUSTOM_ASSESSOR_FIELD_LABEL, Value: "" }
                ];
            },

            EvidenceBlockDto: function () {
                SectionDtoClass.apply(this, [{ SectionType: "BuilderCapabilityEvidenceOnly" }]);
                this.ElementLabel = "";
                this.ElementHint = "";

                this.StyleOptions = [
                    { Key: "show-hint-when-viewing", Value: "true", Target: "section" },
                    { Key: "show-empty-field-when-viewing", Value: "true", Target: "section" },
                    { Key: "privacy-state-private-only", Value: false, Target: "section" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CAPABILITY_EVIDENCE_AMOUNT, Value: "0" }
                ];
            },

            /**
             * Constructor for Numeric block object
             * @constructor
             */
            CapabilityRatingBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderCapabilityRangeInput-Standard" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.NegativeLabel = "";
                this.PositiveLabel = "";

                this.StyleOptions = [
                    { Key: "numeric-range", Value: "1,5" },
                    { Key: "allow-answer-to-be-changed-and-justified", Value: true },
                    { Key: "prompt-to-add-justification-on-first-choice", Value: true },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state-private-only", Value: false },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CAPABILITY_EVIDENCE_AMOUNT, Value: "0" }
                ];
            },

            /**
             * Constructor for Hint Image block object
             * @constructor
             */
            HintImageBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderHintImage", AllowSideBySide: true }]);

                // local
                this.MediaAssetId = "";
                this.Caption = "";
                this.AltText = null;

                this.StyleOptions = [
                    { Key: "caption-as-rollover", Value: false },
                    { Key: "image-click-state", Value: "Empty," },
                    { Key: "show-hint-block-when-viewing", Value: true }
                ];
            },

            /**
             * Constructor for Hint Video block object
             * @constructor
             */
            HintVideoBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderHintVideo", AllowSideBySide: true }]);

                // local
                this.MediaAssetId = "";
                this.Caption = "";

                this.StyleOptions = [
                    { Key: "caption-as-rollover", Value: false },
                    { Key: "video-controls", Value: "" },
                    { Key: "show-hint-block-when-viewing", Value: true }
                ];
            },

            /**
             * Constructor for Hint Audio block object
             * @constructor
             */

            HintAudioBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderHintAudio", AllowSideBySide: true }]);

                // local
                this.MediaAssetId = "";
                this.Caption = "";

                this.StyleOptions = [
                    { Key: "caption-as-rollover", Value: false },
                    { Key: "audio-controls", Value: "" },
                    { Key: "show-hint-block-when-viewing", Value: true }
                ];
            },

            /**
             * Constructor for Hint Text block object
             * @constructor
             */
            HintTextBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderHintText", AllowSideBySide: true }]);

                // local
                this.Text = "";
                this.StyleOptions = [{ Key: "show-hint-block-when-viewing", Value: true }];
            },

            CapabilityRadioBlockDto: function () {
                SectionDtoClass.apply(this, [{ SectionType: "BuilderCapabilityOptionList-RadioButtons" }]);

                this.ElementLabel = "";
                this.ElementHint = "";
                this.WrapAt = BlockInputOptionsHelper.getInlineOptions().default;
                this.Radios = [
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    },
                    {
                        Key: helperService.guid(),
                        Value: ""
                    }
                ];

                this.StyleOptions = [
                    { Key: "display-horizontal", Value: false },
                    { Key: "hide-unselected-options", Value: false },
                    { Key: "allow-answer-to-be-changed-and-justified", Value: true },
                    { Key: "prompt-to-add-justification-on-first-choice", Value: true },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state-private-only", Value: false, Target: "section" },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CAPABILITY_EVIDENCE_AMOUNT, Value: "0" }
                ];
            },

            /**
             * Constructor for Numeric block object
             * @constructor
             */
            CapabilityBinaryBlockDto: function () {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderCapabilityBinaryAnswerInput" }]);

                // local
                this.ElementLabel = "";
                this.ElementHint = "";
                this.NegativeLabel = "";
                this.PositiveLabel = "";

                this.StyleOptions = [
                    { Key: "allow-answer-to-be-changed-and-justified", Value: true },
                    { Key: "prompt-to-add-justification-on-first-choice", Value: true },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true },
                    { Key: "privacy-state-private-only", Value: false },
                    { Key: "mandatory-for-progress", Value: false },
                    { Key: STYLE_OPTIONS.CAPABILITY_EVIDENCE_AMOUNT, Value: "0" }
                ];
            },

            /**
             * Constructor for Row section object
             * Row section always exists around block object or 2 block objects
             *
             * size param is either  "section-small" or "section-large"
             *
             * @constructor
             */
            RowSectionDto: function (size, row_background_colour, SectionIsFull, default_width) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "rowSection", blockProperties: false }]);

                this.SectionIsFull = SectionIsFull || false;
                this.Sections = [];
                this.StyleOptions = [
                    {
                        Key: "background-color",
                        // tslint:disable-next-line:triple-equals
                        Value: row_background_colour != undefined ? row_background_colour : "transparent",
                        Target: "section"
                    },
                    { Key: "width", Value: size, Target: "container" },
                    { Key: "block-width", Value: default_width || 75, Target: "container" }
                ];
            },

            /**
             * Constructor for BuilderFormElementRow section object
             * Row section always exists around block object or 2 block objects
             *
             * size param is either  "section-small" or "section-large"
             *
             * @constructor
             */
            BuilderFormElementRowDto: function (size, row_background_colour, SectionIsFull, default_width) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderFormElementRow", blockProperties: false }]);

                this.SectionIsFull = SectionIsFull || false;
                this.Sections = [];
                this.SectionUneditable = false;
                this.StyleOptions = [
                    { Key: "width", Value: size, Target: "container" },
                    { Key: "block-width", Value: default_width || 75, Target: "container" }
                ];
            },

            /**
             * Constructor for Standards Selection block object
             * @constructor
             */
            StandardsSelectionElementDto: function (dto) {
                // extended
                SectionDtoClass.apply(this, [{ SectionType: "BuilderStandardsSelection" }]);

                // local
                this.ElementLabel = dto.ElementLabel || "";
                this.ElementHint = dto.ElementHint || "";
                this.ButtonText = dto.ButtonText || "";
                this.WorkBookId = dto.WorkBookId || "";
                this.WorkBookTitle = dto.WorkBookTitle || "";

                this.StyleOptions = [
                    { Key: "standards-selection-button-text", Value: this.ButtonText },
                    { Key: "show-hint-when-viewing", Value: true },
                    { Key: "show-empty-field-when-viewing", Value: true }
                ];
            }
        };

        return dtoFactory;
    }
]);
