import { useRef, useContext, MouseEvent, RefObject } from "react";
import { useFactoryRef, useOnUnmount, useOnValueChange } from "@pjs/react-utilities";
import { IMenuItem } from "../../aria-roles/menus/types/IMenuItem";
import { BoundaryContext } from "../../boundary/Boundary.context";
import { scrollToElementInList } from "../../../utils/scroll-to-aria-active-descendant/scrollToElementInList.function";
import { floatingMenuPickerFactory } from "../../aria-roles/menus/floating-menu/factories/FloatingMenuPickerFactory";
import { IGroupedItemMenuProps } from "../interfaces/IGroupedItemMenuProps";
import { IItemGroup } from "../interfaces/IItemGroup";
import "../styles/grouped-item-picker-menu-dropdown.css";

const renderGroupList = <T extends IMenuItem>(
    dataset: ReadonlyArray<IItemGroup<T>>,
    activeItemIndex: number,
    activeItemRef: RefObject<HTMLLIElement>,
    dropdownId: string,
    onClick: (e: MouseEvent<HTMLLIElement>, index: number) => void,
    renderOption: (item: T) => JSX.Element
): ReadonlyArray<JSX.Element> => {
    let indexOffset = 0;
    return dataset.map((group, groupIndex) => {
        const groupId = `${dropdownId}-${groupIndex}`;
        const renderedGroup = (
            <li className="cui-grouped-item-picker__group-item" key={groupId}>
                <ul data-hook="group-picker-group" aria-labelledby={`${groupId}-title`} className="cui-grouped-item-picker__group" role="group">
                    <li role="presentation" key={`${groupId}-title`} id={`${groupId}-title`} data-hook="group-picker-title" className="cui-grouped-item-picker__title-item">
                        <span className="cui-grouped-item-picker__title-text">{group.title}</span>
                    </li>
                    {renderGroupItems(group.items, indexOffset, activeItemIndex, activeItemRef, dropdownId, onClick, renderOption)}
                </ul>
            </li>
        );
        indexOffset += group.items.length;
        return renderedGroup;
    });
};

const renderGroupItems = <T extends IMenuItem>(
    items: ReadonlyArray<T>,
    indexOffset: number,
    activeItemIndex: number,
    activeItemRef: RefObject<HTMLLIElement>,
    dropdownId: string,
    onClick: (e: MouseEvent<HTMLLIElement>, index: number) => void,
    renderOption: (item: T) => JSX.Element
): ReadonlyArray<JSX.Element> => {
    let index = 0;
    return items.map((item) => {
        const viewIndex = indexOffset + index;
        index++;
        return (
            <li
                className="cui-grouped-item-picker__list-item"
                key={`${dropdownId}-item-${viewIndex}`}
                id={`${dropdownId}-item-${viewIndex}`}
                data-element-index={viewIndex}
                aria-selected={viewIndex === activeItemIndex}
                ref={viewIndex === activeItemIndex ? activeItemRef : undefined}
                data-hook="group-picker-item"
                role="menuitem"
                onClick={(e) => onClick(e, viewIndex)}>
                {renderOption(item)}
            </li>
        );
    });
};

export const GroupedItemsMenu = <T extends IMenuItem>(props: IGroupedItemMenuProps<T>): JSX.Element => {
    const listRef = useRef<HTMLUListElement>(null);
    const activeItemRef = useRef<HTMLLIElement>(null);
    const boundary = useContext(BoundaryContext);

    const floatingMenu = useFactoryRef(floatingMenuPickerFactory<T>, {
        boundaryRef: boundary.element,
        floatingRef: listRef,
        initialModel: props.menuModel,
        matchItemOnKeys: (item: T, currentKeys: string): boolean => item.value.toLowerCase().startsWith(currentKeys),
        onItemSelect: (index: number) => props.onItemSelect(props.adapterDataset[index]),
        onModelChange: props.onMenuModelChange,
        tempTargetRef: props.tempTargetRef,
        triggerRef: props.buttonRef
    });

    useOnUnmount(() => {
        floatingMenu.destroy();
    });

    useOnValueChange(
        () => {
            if (listRef.current !== null && activeItemRef.current !== null) {
                scrollToElementInList(listRef.current, activeItemRef.current);
            }
        },
        [props.menuModel, props.adapterDataset, props.renderOption],
        true
    );

    useOnValueChange(
        () => {
            floatingMenu.updateModel(props.menuModel);
        },
        [props.menuModel],
        true
    );

    return (
        <ul
            className="cui-grouped-item-picker__list-box"
            data-hook="group-picker-list"
            ref={listRef}
            id={props.dropdownId}
            aria-activedescendant={`${props.dropdownId}-item-${props.menuModel.activeItemIndex}`}
            aria-labelledby={props.labelledById}
            tabIndex={-1}
            role="menu"
            onBlur={floatingMenu.events.target.onFocusLoss}
            onKeyDown={(e) => floatingMenu.events.target.onKeyDown(e, props.adapterDataset)}>
            {renderGroupList(props.result, props.menuModel.activeItemIndex, activeItemRef, props.dropdownId, floatingMenu.events.item.onClick, props.renderOption)}
        </ul>
    );
};
