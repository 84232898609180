import { FunctionComponent, PropsWithChildren } from "react";
import { IComponentWithClassNameProps } from "../../interfaces/IComponentWithClassNameProps";
import { CollapsibleContext } from "./Collapsible.context";

export const CollapsibleContent: FunctionComponent<PropsWithChildren<IComponentWithClassNameProps>> = (props) => {
    return (
        <CollapsibleContext.Consumer>
            {(context) => (
                <div className={context.isOpen ? props.className : `${props.className} cui-collapsible__hidden-content`} id={context.contentId} data-hook="collapsible-content">
                    {props.children}
                </div>
            )}
        </CollapsibleContext.Consumer>
    );
};
