/**
 * @param {String} eventName
 * @param {Function} callback
 * @param {Boolean=} useCapture
 * @param {Object=} context
 * @returns {{}}
 */
function bindEvent(eventName, callback, useCapture, context) {
    var element = this || document.documentElement,
        handler = {},
        eventNameList;

    // validate parameters
    useCapture = useCapture || false;
    if (eventName && (typeof eventName === "string" || eventName instanceof String)) {
        eventNameList = eventName.split(" ");
    } else {
        throw new Error("Please provide a valid event name!");
    }

    if (typeof callback !== "function") {
        throw new Error("Please provide a valid function to run!");
    }

    function handlerFn(event) {
        if (context) {
            callback.call(context, event);
        } else {
            callback.call(element, event);
        }
    }

    var i,
        eventNameListLength = eventNameList.length;

    // Add event listener to all events
    for (i = 0; i < eventNameListLength; i++) {
        element.addEventListener(eventNameList[i], handlerFn, useCapture);
    }

    // Public API
    handler.unbindEvent = function () {
        for (i = 0; i < eventNameListLength; i++) {
            element.removeEventListener(eventNameList[i], handlerFn, useCapture);
        }
    };
    handler.target = element;

    return handler;
}

Object.defineProperty(Element.prototype, "bindEvent", {
    get: function () {
        return bindEvent;
    },
    configurable: true,
    writeable: false
});
