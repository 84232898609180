import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { selectNextItem } from "./SelectNextItem.function";

export function selectNextItemWithWrapping<T>(e: KeyboardEvent, currentModel: Immutable<IAriaModelWithType>, items: Array<T>): Partial<Immutable<IAriaModelWithType>> {
    const model = selectNextItem(e, currentModel, items);

    if (model === null) {
        return {
            activeItemIndex: 0,
            type: AriaMenuStatus.Open
        };
    }

    return model;
}
