import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../dropdown/dropdown";
import "../loadingSpinner/loadingSpinner";
import "../builder/dataManager/helperService";
import "../userAgent/userAgent.service";
import template from "./auto-complete.html";

angularAMD.directive("autoComplete", [
    "$sce",
    "baseUrlsFactory",
    "$timeout",
    "$rootScope",
    "helperService",
    "UserAgentService",
    function ($sce, baseUrlsFactory, $timeout, $rootScope, helperService, UserAgentService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                startingItems: "=startingItems", // use if you have a static list of items
                autoCompleteActions: "=autoCompleteActions", // actions to be added to the end of the autocomplete list
                placeholderText: "@placeholderText", // when autocomplete field is empty display this text inside
                getItemsAction: "=getItemsAction", // broadcast id that will be used to request new items
                returnItemsAction: "=returnItemsAction", // when new items have been found this id is the broadcast which will catch them
                selectionAction: "=selectionAction", // where to send a selection result too, default too "autoCompleteItemSelected"
                inputTitle: "@inputTitle", // Title for the input field of the auto complete
                openInputLength: "@openInputLength", // Set length of search text used to trigger auto complete
                extraKeyUpFunction: "&extraKeyUpFunction", // extra keyup function, this will pass out $event and the current input
                noAutoCompleteItemsMessage: "@noAutoCompleteItemsMessage",
                autoFocus: "=",
                describedBy: "@"
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                    $scope.autoCompleteItems = [];
                    $scope.search_spinner = false;
                    $scope.currentAutoComplete = -1;
                    $scope.currentAction = -1;
                    $scope.searchText = "";
                    $scope.showAutoComplete = false;
                }
            ],
            link: function (scope, element, attrs) {
                // tslint:disable-next-line:triple-equals
                scope.open_input_length = scope.openInputLength != undefined ? scope.openInputLength : 3;

                scope.$watch("startingItems", function (items) {
                    // tslint:disable-next-line:triple-equals
                    if (items != undefined) {
                        scope.autoCompleteItems = angular.copy(scope.startingItems);
                    }
                });

                var results_holder_el = angular.element(element[0].getElementsByClassName("results-holder"))[0];
                var actionHolder = element[0].getElementsByClassName("actions-holder")[0];
                var autoCompleteSearch = element[0].getElementsByClassName("auto-complete-search")[0];

                if (scope.describedBy) {
                    autoCompleteSearch.setAttribute("aria-describedby", scope.describedBy);
                }

                var focusInput = function () {
                    if (autoCompleteSearch) {
                        autoCompleteSearch.focus();
                    }
                };

                if (scope.autoFocus) {
                    focusInput();
                }

                scope.$on("focusInput", function (event) {
                    focusInput();
                });

                scope.handleSelection = function (item) {
                    scope.searchText = "";
                    scope.showAutoComplete = false;
                    // tslint:disable-next-line:triple-equals
                    if (item != undefined) {
                        // if a broadcast id has been specified then use that, otherwise use the default
                        // tslint:disable-next-line:triple-equals
                        if (scope.selectionAction !== null && scope.selectionAction !== undefined && scope.selectionAction != "") {
                            scope.$emit(scope.selectionAction, item);
                        } else {
                            scope.$emit("autoCompleteItemSelected", item);
                        }
                    }
                };

                scope.handleActionSelection = function (item) {
                    const currentSearch = scope.searchText;
                    scope.searchText = "";
                    scope.showAutoComplete = false;
                    // tslint:disable-next-line:triple-equals
                    if (item != undefined) {
                        $timeout.cancel(scope.searchTimeout);
                        scope.$emit(item.ActionFunction, item, currentSearch);
                        focusInput();
                    }
                };

                scope.onAutoCompleteKeyDown = function (keyEvent) {
                    scope.hideAutoComplete = false;
                    var keyPressed = keyEvent.which;
                    switch (keyPressed) {
                        case 27: // cancel pressed
                            scope.showAutoComplete = false; // hide autocomplete
                            break;
                        case 9:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        default:
                            focusInput();
                            break;
                    }
                };

                scope.onAutoCompleteEnter = function (keyEvent, index) {
                    scope.hideAutoComplete = false;
                    var keyPressed = keyEvent.which;
                    switch (keyPressed) {
                        case 13: // return pressed
                            cancelDefaultAction(keyEvent);
                            scope.selectorSpinner = true;
                            scope.handleSelection(scope.filteredItems[index]); // do the same as mouse click
                            scope.showAutoComplete = false;
                            focusInput();
                            break;
                        case 9:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        default:
                            focusInput();
                            break;
                    }
                };

                scope.openAutoComplete = function () {
                    if (scope.searchText.length >= scope.open_input_length) {
                        scope.showAutoComplete = false;
                        $timeout(function () {
                            scope.showAutoComplete = true;
                        });
                    }
                };

                function cancelDefaultAction(e) {
                    // this function cancels the default behaviour of a normal keypress
                    var evt = e ? e : window.event;
                    if (evt.preventDefault) {
                        evt.preventDefault();
                    }
                    evt.returnValue = false;
                    return false;
                }

                scope.highlight = function (string, input) {
                    if (!input) {
                        return $sce.trustAsHtml(input);
                    }

                    return $sce.trustAsHtml(unescape(escape(string).replace(new RegExp(escape(input), "gi"), '<span class="highlightedText">$&</span>')));
                };

                scope.searchTextKeyUp = function ($event) {
                    var input = scope.searchText;
                    scope.bindModel = scope.searchText;
                    scope.showAutoComplete = input.length >= scope.open_input_length;
                    if (input.length >= scope.open_input_length) {
                        // tslint:disable-next-line:triple-equals
                        if (scope.getItemsAction != undefined) {
                            scope.search_spinner = true;
                            $timeout.cancel(scope.searchTimeout);
                            scope.searchTimeout = $timeout(function () {
                                $rootScope.$broadcast(scope.getItemsAction, { searchText: scope.searchText });
                            }, 1000);
                        } else {
                            scope.correctHeightAfterChange();
                        }
                    }
                    scope.lastInputLength = angular.copy(input.length);

                    if (attrs.extraKeyUpFunction) {
                        scope.extraKeyUpFunction()($event, input);
                    }
                };

                // if we can expect some new items via broadcast then set up the on
                // tslint:disable-next-line:triple-equals
                if (scope.returnItemsAction != undefined) {
                    scope.$on(scope.returnItemsAction, function (event, data) {
                        scope.autoCompleteItems = data;
                        scope.search_spinner = false;
                        scope.correctHeightAfterChange();
                    });
                }

                scope.correctHeightAfterChange = function () {
                    if (!scope.autoCompleteActions) {
                        return;
                    }

                    var actionHeight;
                    $timeout(function () {
                        if (actionHolder) {
                            actionHeight = actionHolder.getBoundingClientRect().height * scope.autoCompleteActions.length;
                            //365 to allow for the padding.
                            var height = 365;

                            if (UserAgentService.touch) {
                                height = height - element[0].querySelector(".auto-complete-holder").getBoundingClientRect().top;
                            }

                            var newHolderHeight = height - actionHeight;
                            results_holder_el.style.maxHeight = newHolderHeight + "px";
                        }
                    });
                };

                scope.stopCloseOnBlur = function () {
                    scope.preventClose = true;
                };

                scope.closeOnBlur = function (last) {
                    scope.preventClose = false;
                    if (last) {
                        $timeout(function () {
                            if (!scope.preventClose) {
                                scope.showAutoComplete = false;
                                scope.preventClose = false;
                            }
                        }, 200);
                    }
                };

                scope.$on("clearAutoCompleteInput", function () {
                    scope.showAutoComplete = false;
                    scope.searchText = "";
                });
            }
        };
    }
]);
angularAMD.filter("autoCompleteFilter", function () {
    return function (data, input) {
        if (!data) {
            return null;
        }

        if (!input) {
            return null;
        }

        var dataArray = [];
        input = input.toLowerCase();

        for (var i = 0; i < data.length; i++) {
            var item = data[i];

            // tslint:disable-next-line:triple-equals
            if ((item.Title != null && item.Title.toLowerCase().indexOf(input) !== -1) || (item.SubTitle != null && item.SubTitle.toLowerCase().indexOf(input) !== -1)) {
                dataArray.push(item);
            }
        }

        return dataArray;
    };
});
