import { angularAMD } from "@pebblepad/amd";
import { getEditorToolbarHeight } from "./GetEditorToolbarHeight.function";

export class ToolbarLayoutController {
    displayToolbarWithAnimation(toolbarContainer, toolbarHeight) {
        const toolbarAnimation = [{ height: 0 }, { height: `${toolbarHeight}px` }];

        toolbarContainer.style.overflow = "hidden";

        const animation = toolbarContainer.animate(toolbarAnimation, {
            duration: 125,
            easing: "ease-in"
        });

        animation.finished.finally(() => {
            toolbarContainer.style.overflow = "";
            toolbarContainer.style.height = `${toolbarHeight}px`;
        });
    }

    hideFloating(toolbarContainer) {
        toolbarContainer.style.top = "0";

        for (const toolbar of toolbarContainer.children) {
            toolbar.style.bottom = "";
        }
    }

    displayToolbar(toolbarContainer, toolbarHeight) {
        toolbarContainer.style.height = `${toolbarHeight}px`;
    }

    hideToolbar(toolbarContainer) {
        toolbarContainer.style.transition = "";
        toolbarContainer.style.overflow = "";
        toolbarContainer.style.height = "";
    }

    applyFloating(toolbarContainer) {
        const toolbarBorderWidth = getComputedStyle(toolbarContainer.firstElementChild).borderBottomWidth;
        const position = getComputedStyle(toolbarContainer).position;

        toolbarContainer.style.height = "0";

        if (position === "sticky") {
            const toolbarHeight = getEditorToolbarHeight(toolbarContainer);
            toolbarContainer.style.top = `calc(${toolbarHeight}px - ${toolbarBorderWidth})`;
        }

        for (const toolbar of toolbarContainer.children) {
            toolbar.style.position = "absolute";
            toolbar.style.bottom = `calc(100% - ${toolbarBorderWidth})`;
            toolbar.style.width = "100%";
        }
    }

    removePositionSticky(toolbarContainer) {
        toolbarContainer.style.position = "relative";
    }
}

ToolbarLayoutController.$inject = [];
angularAMD.service("ToolbarLayoutController", ToolbarLayoutController);
