import { angularAMD } from "@pebblepad/amd";
import { findFirstInteractiveElement } from "@pjs/core-ui";
import "./computedStyle.service";

angularAMD.service("domSearchHelper", DomSearchHelperService);
DomSearchHelperService.$inject = ["computedStyleService"];

function DomSearchHelperService(computedStyleService) {
    // Public API
    // =============================================================================================================
    this.getElementFromDomTree = getElementFromDomTree;
    this.getInputElement = getInputElement;
    this.elementContains = elementContains;
    this.getTabbableElements = getTabbableElements;
    this.getFirstTabbableElement = findFirstInteractiveElement;
    this.elementIsVisible = elementIsVisible;

    // Private methods
    // =============================================================================================================
    /**
     * @param {Element} element
     * @param {Function} matchFn
     * @param {Object} limits
     * @param {Boolean} checkParentNode
     * @return {Element|null}
     */
    function getElementFromDomTree(element, matchFn, limits, checkParentNode) {
        if (!element || !matchFn) {
            return null;
        }

        while (!matchFn(element)) {
            // stop looping, when reach HTML tag or match all limits
            if (notValidNode(element, limits) || notValidParentNode(element, limits, checkParentNode)) {
                return null;
            }

            // select next element in the DOM tree
            try {
                element = element.parentNode;
            } catch (e) {
                return null;
            }
        }

        return element;
    }

    /*
     *  Find check if a node is nested within a node
     *  Created due to Node.contains only matching elements on Internet Explorer ಠ_ಠ
     */
    function elementContains(element, child) {
        if (element === child) {
            return true;
        }
        if (element.hasChildNodes()) {
            for (var i = 0; i < element.childNodes.length; i++) {
                if (elementContains(element.childNodes[i], child)) {
                    return true;
                }
            }
        }
        return false;
    }

    function notValidNode(node, limits) {
        if (!node) {
            return false;
        }

        var isHTMLTag = node.nodeName === "HTML";
        if (limits && !isHTMLTag) {
            return stopByLimits(node, limits);
        } else {
            return isHTMLTag;
        }
    }

    function stopByLimits(node, limits) {
        var objectSize = 0;
        for (var limit in limits) {
            if (limits.hasOwnProperty(limit)) {
                objectSize++;

                // make tag name uppercase in case lowercase value been passed
                if (limit === "tagName") {
                    limits[limit] = (limits[limit] || "").toUpperCase();
                }
                if (limit.node) {
                    return node === limit.node;
                }
                if (node[limit] !== limits[limit]) {
                    return false;
                }
            }
        }

        return objectSize > 0;
    }

    function notValidParentNode(node, limits, checkParentNode) {
        if (!checkParentNode || !node || !node.parentNode) {
            return;
        }

        return notValidNode(node.parentNode, limits);
    }

    function elementIsInput(element) {
        if (element && element.getAttribute) {
            if (
                element.getAttribute("contenteditable") === "true" ||
                element.getAttribute("contenteditable") === "" ||
                element.tagName.toLowerCase() === "input" ||
                element.tagName.toLowerCase() === "textarea"
            ) {
                return true;
            }
        }

        return false;
    }

    function getInputElement(element, limits) {
        return getElementFromDomTree(element, elementIsInput, limits);
    }

    /**
     * @param {Element} container
     * @return {Array<Element>}
     */
    function getTabbableElements(container) {
        var selector =
            'a[href]:not([tabindex="-1"]), button:not([tabindex="-1"]), textarea:not([tabindex="-1"]), ' +
            '[tabindex]:not([tabindex="-1"]), [contentEditable=true]:not([tabindex="-1"]), ' +
            'select:not([tabindex="-1"]), input:not([type="hidden"])';
        var elements = container.querySelectorAll(selector);
        return Array.prototype.slice.call(elements).filter(function (element) {
            return elementIsVisible(element) && element.disabled !== true;
        });
    }

    function elementIsVisible(element) {
        //If the element is display none or dimensions are equal to 0, null will be returned via offsetParent.
        if (element.offsetParent === null) {
            return false;
        }

        var style = computedStyleService.getComputedStyles(element, "visibility", false);
        return style.visibility !== "hidden";
    }
}
