import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";
import "../assetListItem/assetListItem.directive";
import template from "./templates/example-recovered-list.html";

angularAMD.directive("exampleRecoveredList", [
    "multiLanguageService",
    function (multiLanguageService) {
        var AssetStoreItem = function (title, subText, name, icon, lastModified) {
            this.AssetTitle = title;
            this.SubText = subText;
            this.Author = name;
            this.Icon = icon;
            this.AssetMainType = icon;
            this.HistoryDateTime = lastModified;
        };

        return {
            template: template,
            scope: {
                iconType: "@",
                isResource: "@",
                hint: "@"
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.multiLanguageService = multiLanguageService;
                    var listItemText = {
                        recovered_title: $scope.multiLanguageService.getString("workbook.revision_modals.example_list.assets.recovered_title"),
                        title: $scope.multiLanguageService.getString("workbook.revision_modals.example_list.assets.title")
                    };
                    var defaultType = "webfoliopage";
                    var defaultRecoveredType = "blog";

                    if ($scope.isResource) {
                        listItemText = {
                            recovered_title: $scope.multiLanguageService.getString("workbook.revision_modals.example_list.resources.recovered_title"),
                            title: $scope.multiLanguageService.getString("workbook.revision_modals.example_list.resources.title")
                        };
                        defaultType = "reflection-reversed";
                        defaultRecoveredType = "talent-reversed";
                    }

                    var recoveredType = $scope.iconType || defaultRecoveredType;
                    var subText = $scope.multiLanguageService.getString("asset_summary.headings.modified");

                    $scope.dummyList = [
                        new AssetStoreItem(listItemText.recovered_title, subText, $scope.multiLanguageService.getString("workbook.revision_modals.example_list.name"), recoveredType, new Date()),
                        new AssetStoreItem(listItemText.title, subText, $scope.multiLanguageService.getString("workbook.revision_modals.example_list.name"), recoveredType, new Date())
                    ];
                }
            ]
        };
    }
]);
