import { angularAMD } from "@pebblepad/amd";
import "./typeCheck.service";

angularAMD.factory("animationHelperService", [
    "$timeout",
    "typeCheck",
    function ($timeout, typeCheck) {
        return {
            animate: function (options) {
                var self = this; // map "this" with "self" to solve scope issue
                var is_array = typeCheck.isArray(options.step); // check if "step" is an array

                // if step is an array, then add new property "fn_iterator" to perform sequential function run
                if (is_array && !options.hasOwnProperty("fn_iterator")) {
                    options["fn_iterator"] = 0;
                }

                var start_time = new Date(); // record time when animation was triggered

                // repeat run step_fn until animation is completed
                var timer = setInterval(function () {
                    var time_passed = new Date() - start_time; // get time passed since last run
                    var progress = time_passed / (options.duration === undefined ? 1000 : options.duration); // calculate progress. "duration" is 1000ms by default

                    // progress_condition if step is an array, then look for break_animation attribute, otherwise check the progress
                    var progress_condition = is_array ? progress > 1 || options.step[options.fn_iterator].break_animation : progress > 1;
                    if (progress_condition) {
                        progress = 1;
                    } // make progress equal 1 when break_animation is true or progress is greater than 1

                    var delta = options.timing_fn(progress); // calculate delta with passed timing function
                    is_array ? options.step[options.fn_iterator].step_fn(delta) : options.step(delta); // run step from array or from a function

                    // tslint:disable-next-line:triple-equals
                    if (progress == 1) {
                        clearInterval(timer); // clear interval when animation is completed

                        // if is an array, then increment fn_iterator, so next time code will run next in a row function
                        if (is_array) {
                            if (options.fn_iterator < options.step.length - 1) {
                                options.fn_iterator++;
                                self.animate(options);
                            } else {
                                options.fn_iterator = 0;
                            }
                        }
                    }
                }, options.delay || 10);

                return timer;
            },

            timing_functions: {
                easeIn: function (progress) {
                    return progress * progress;
                },
                easeOut: function (progress) {
                    return progress * (2 - progress);
                },
                easeInOut: function (progress) {
                    return progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress;
                },
                easeInCubic: function (progress) {
                    return progress * progress * progress;
                },
                easeOutCubic: function (progress) {
                    return --progress * progress * progress + 1;
                },
                easeInOutCubic: function (progress) {
                    return progress < 0.5 ? 4 * progress * progress * progress : (progress - 1) * (2 * progress - 2) * (2 * progress - 2) + 1;
                }
            },

            /**
             * @param {Element} element
             * @param {String} className
             * @param {=Number} timeoutDuration
             * @returns {Promise}
             */
            applyAnimationClass: function (element, className, timeoutDuration) {
                element.classList.add(className);
                element.setAttribute("aria-hidden", "true");
                var duration = !isNaN(timeoutDuration) ? timeoutDuration : 300;

                //Wait for animation to end, then focus in on the first available element
                return $timeout(function () {
                    element.setAttribute("aria-hidden", "false");
                    element.classList.remove(className);
                }, duration);
            }
        };
    }
]);
