import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("bounceFixTwo", [
    "$window",
    function ($window) {
        // Enable by default if the browser supports -webkit-overflow-scrolling
        // Test this by setting the property with JavaScript on an element that exists in the DOM
        // Then, see if the property is reflected in the computed style
        var testDiv = document.createElement("div");
        // tslint:disable-next-line:no-unsafe-dom-insert-calls
        document.documentElement.appendChild(testDiv);
        testDiv.style.WebkitOverflowScrolling = "touch";
        var scrollSupport = "getComputedStyle" in window && window.getComputedStyle(testDiv)["-webkit-overflow-scrolling"] === "touch";

        /**
         * Main functionality
         */
        var _bounceFix = {
            bounceFixClassName: "bounce-fix",
            target_el: null,
            start_handler: null,
            move_handler_target_el: null,
            end_handler: null,

            /**
             * Goes up in the DOM tree until finds right element.
             * @param elem
             * @returns {*}
             */
            findTargetEl: function (elem) {
                var has_class = false;

                // Loop through the DOM to find element with bounce fix class
                while (elem !== document.body) {
                    elem = elem.parentNode;
                    has_class = angular.element(elem).hasClass(this.bounceFixClassName);
                    this.target_el = elem;

                    // If element is found, then our job is done
                    if (has_class) {
                        break;
                    }
                }

                return has_class ? this.target_el : false;
            },

            /**
             * Determine if the element should scroll
             * @param el
             * @returns {boolean}
             */
            canScroll: function (el) {
                return el.scrollHeight > el.offsetHeight;
            },

            /**
             * Fixing bouncing by setting scroll position to 1px off the top/bottom edges
             * @param el
             * @param evt
             */
            preventBouncing: function (el, evt) {
                var curScrollPos = el.scrollTop,
                    scrollHeight = el.scrollHeight,
                    offsetHeight = el.offsetHeight,
                    is_at_top = curScrollPos < 1,
                    is_at_bottom = scrollHeight - offsetHeight < curScrollPos + 1;

                if (curScrollPos === 1) {
                    evt.preventDefault();
                    el.scrollTop = 2;

                    return;
                }

                if (is_at_top) {
                    el.scrollTop = 1;
                } else if (is_at_bottom) {
                    el.scrollTop = scrollHeight - offsetHeight - 1;
                }
            },

            /**
             * 1. Finds target
             * 2. Check target and scrolling availability
             * 3. Runs bouncing fix
             * @param evt
             */
            onTouchStart: function (evt) {
                // Find & cache target element.
                var target_el = this.findTargetEl(evt.target);

                // Prevent bouncing when there is scroll and target element exists
                if (target_el && this.canScroll(target_el)) {
                    this.preventBouncing(target_el, evt);
                    return;
                }

                // In case it doesn't have scrolling disable scrolling on touchmove
                if (target_el && !this.move_handler_target_el) {
                    this.move_handler_target_el = target_el;
                    this.move_handler_target_el.addEventListener("touchmove", this.onTouchMove, false);
                }
            },

            /**
             * Disable scrolling
             * @param evt
             */
            onTouchMove: function (evt) {
                evt.preventDefault();
            },

            /**
             * Clear after user finished scrolling
             */
            onTouchEnd: function () {
                if (this.move_handler_target_el) {
                    this.move_handler_target_el.removeEventListener("touchmove", this.onTouchMove, false);
                    this.move_handler_target_el = null;
                }
            },

            /**
             * Adds events to make bounce fix working
             */
            enable: function () {
                this.start_handler = this.onTouchStart.bind(this);
                this.end_handler = this.onTouchEnd.bind(this);
                $window.addEventListener("touchstart", this.start_handler, false);
                $window.addEventListener("touchend", this.end_handler, false);
            },

            /**
             * Removes events responsible for bounce fix
             */
            disable: function () {
                $window.removeEventListener("touchstart", this.start_handler, false);
                $window.removeEventListener("touchend", this.end_handler, false);
            }
        };

        // Public API
        return {
            enabled: false,

            enable: function () {
                if (scrollSupport && !this.enabled) {
                    _bounceFix.enable();
                    this.enabled = true;
                }
            },

            disable: function () {
                if (scrollSupport && !this.enabled) {
                    _bounceFix.disable();
                    this.enabled = false;
                }
            }
        };
    }
]);
