import React, { FunctionComponent, useState } from "react";
import { flushSync } from "react-dom";
import { useOnMount } from "@pjs/react-utilities";
import { switchMap, tap } from "@pjs/observables";
import { IMultiStepDialogInstance } from "../dialog-instance/interfaces/IMultiStepDialogInstance";
import { Dialog } from "../dialog/Dialog.component";
import { dialogViewSubject } from "./DialogViewSubject.const";

/**
 * This component is SINGLE USE ONLY - If you are unsure if you should be using it, you probably shouldn't be.
 * If more than one of this component is rendered on any given page, it will break all dialog implementations
 */
export const DialogView: FunctionComponent = () => {
    const [activeDialog, setActiveDialog] = useState<IMultiStepDialogInstance<any> | null>(null);

    useOnMount(() => {
        const subscription = dialogViewSubject
            .pipe(
                tap((dialog) => {
                    setActiveDialog(dialog);
                }),
                switchMap((dialog) => dialog.destroyObservable)
            )
            .subscribe(() => {
                flushSync(() => {
                    setActiveDialog(null);
                });
            });

        return () => subscription.unsubscribe();
    });

    return <React.Fragment>{activeDialog !== null && <Dialog key={activeDialog.id} instance={activeDialog} />}</React.Fragment>;
};
