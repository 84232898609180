import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../loadingSpinner/loadingSpinner";
import template from "../templates/spinner-overlay.html";

angularAMD.directive("spinnerOverlay", function () {
    return {
        restrict: "E",
        template: template,
        controller: [
            "$scope",
            function ($scope) {
                $scope.hideSpinner = false;
                $scope.displaySpinner = function (hideSpinner) {
                    $scope.hideSpinner = hideSpinner;
                };
            }
        ]
    };
});
