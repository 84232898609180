var constants = {
    FEEDBACK_TYPES: {
        FEEDBACKTEMPLATE: "FeedbackTemplate",
        GRADE: "Grade",
        COMMENT: "Comment",
        SCORECARD: "Scorecard",
        CERTIFICATE: "Certificate",
        APPROVAL: "Approval",
        APPROVALCOLLECTION: "ApprovalCollection",
        SIMILARITYSCORE: "SimilarityScore",
        PAGEVERIFICATION: "PageVerification",
        CAPABILITY: "Capability",
        ASSESSOR_FIELD: "AssessorField",
        APPROVAL_LEVEL_ONE: "ApprovalLevelOne",
        APPROVAL_LEVEL_TWO: "ApprovalLevelTwo",
        APPROVAL_LEVEL_THREE: "ApprovalLevelThree"
    },
    FEEDBACK_PROPERTIES: {
        SIMILARITYSCORE: {
            INTERNETSIMILARITY: "InternetSimilarity",
            PUBLICATIONSSIMILARITY: "PublicationsSimilarity",
            DOCUMENTSSIMILARITY: "DocumentsSimilarity",
            OVERALLSIMILARITY: "OverallSimilarity"
        },
        APPROVAL: {
            LEVELTEXT: "LevelText",
            STATUSTEXT: "StatusText"
        }
    },
    FEEDBACK_SUBTYPES: {
        STANDARD: "Standard",
        TURNITIN: "Turnitin"
    },
    FEEDBACK_FORMATS: {
        GRADE: {
            PERCENTAGE: "Percentage",
            STRING: "String"
        }
    },
    FEEDBACK_REPLIES: {
        DEFAULT_SHOW_AMOUNT: 2
    },
    RELEASE_STATES: {
        RELEASED: "Released",
        UNRELEASED: "Unreleased"
    },
    RELEASE_SETTINGS: {
        ALWAYS_RELEASE: "AlwaysRelease",
        DEFAULT_RELEASE: "DefaultRelease",
        DEFAULT_HOLD: "DefaultHold",
        ALWAYS_HOLD: "AlwaysHold"
    },
    APPROVAL_STATES: {
        POSITIVE: "Positive",
        NEUTRAL: "Neutral",
        NEGATIVE: "Negative"
    }
};

export { constants as ASSESSMENT_CONSTANTS };
