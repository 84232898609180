import { angularAMD } from "@pebblepad/amd";

angularAMD.service("customEvent", CustomEventClass);

function CustomEventClass() {
    // Public API
    // =============================================================================================================
    this.emit = emit;

    // Private methods
    // =============================================================================================================
    function emit(target, eventName, opts) {
        var customEvt = document.createEvent("Event");
        customEvt.initEvent(eventName, true, true);

        customEvt.preventDefault = function () {
            // https://www.w3.org/Bugs/Public/show_bug.cgi?id=14638 - if we don't cancel it, we'll snap back
        }.bind(this);

        target.dispatchEvent(customEvt);
    }
}
