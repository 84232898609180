"use strict";
import { angularAMD } from "@pebblepad/amd";

var puiAccordionContentDefinition = {
    require: {
        accordionPanel: "^puiAccordionPanel"
    },
    transclude: true,
    template:
        '<dd class="pui-accordion__content" \
                    ng-class="{\'pui-accordion__content--hidden\':!$ctrl.expanded}" \
                    id="pui-accordion-content-{{$ctrl.id}}" \
                    role="region" \
                    aria-labelledby="pui-accordion-header-{{$ctrl.id}}">\
                    <ng-transclude/>\
                   </dd>',
    controller: PuiAccordionContent
};

PuiAccordionContent.$inject = ["$element"];

function PuiAccordionContent($element) {
    this._$element = $element;
    this.expanded = false;
    this.id = null;
}

PuiAccordionContent.prototype.$onInit = function () {
    var accordionContentManager = new AccordionContentManager(this.setExpanded.bind(this));
    this.id = this.accordionPanel.setContentManager(accordionContentManager);
};

PuiAccordionContent.prototype.setExpanded = function (value) {
    this.expanded = value;
};

function AccordionContentManager(setExpanded) {
    this.setExpanded = setExpanded;
}

if (angularAMD.default !== null) {
    angularAMD.component("puiAccordionContent", puiAccordionContentDefinition);
}

export { puiAccordionContentDefinition };
