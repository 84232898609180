import { BehaviorSubject, distinctUntilChanged, Observable, skip } from "@pjs/observables";
import { IToolbarDisplay } from "./interfaces/IToolbarDisplay";
import { ToolbarDisplayState } from "./enums/ToolbarDisplayState";

export class ToolbarDisplay implements IToolbarDisplay {
    public readonly displayChange: Observable<ToolbarDisplayState>;

    private readonly _displaySubject: BehaviorSubject<ToolbarDisplayState>;
    private readonly _toolbarElement: HTMLElement;

    constructor(element: HTMLElement) {
        this._toolbarElement = element;
        this._toolbarElement.style.display = "none";

        this._displaySubject = new BehaviorSubject<ToolbarDisplayState>(ToolbarDisplayState.Hidden);

        this.displayChange = this._displaySubject.pipe(distinctUntilChanged(), skip(1));
    }

    public destroy(): void {
        this._displaySubject.next(ToolbarDisplayState.Hidden);
        this._displaySubject.complete();
    }

    public show(): void {
        this._displaySubject.next(ToolbarDisplayState.Shown);
        this._toolbarElement.style.removeProperty("display");
    }

    public hide(): void {
        this._displaySubject.next(ToolbarDisplayState.Hidden);
        this._toolbarElement.style.display = "none";
    }
}
