import { RefObject } from "react";

export const blockHyperlinkClick = (event: MouseEvent, elementRef: RefObject<HTMLElement>): void => {
    if (event.ctrlKey || event.metaKey) {
        return;
    }

    const target = event.target as Element;
    if (target.tagName === "A") {
        event.preventDefault();
        return;
    }

    let parent = target.parentElement;
    while (parent !== null && parent !== elementRef.current) {
        if (parent.tagName === "A") {
            event.preventDefault();
            return;
        }

        parent = parent.parentElement;
    }
};
