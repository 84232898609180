import { first } from "@pjs/observables";
import { angularAMD } from "@pebblepad/amd";
import { AbortablePromiseChain } from "../abortablePromises/AbortablePromiseChain";
/**
 * @callback to$AbortablePromise
 * @template T
 * @param {Observable<T>} observable
 * @return AbortablePromiseLike<T>
 */

/**
 * @param $q
 * @return to$AbortablePromise
 */
function to$AbortablePromiseFactory($q) {
    return (observable) => {
        const deferred = $q.defer();
        const subscription = observable.pipe(first()).subscribe({
            next: deferred.resolve,
            error: deferred.reject
        });

        return new AbortablePromiseChain(deferred.promise, {
            _isAborted: false,
            abort() {
                this._isAborted = true;
                subscription.unsubscribe();
            },
            isAborted() {
                return this._isAborted;
            }
        });
    };
}

to$AbortablePromiseFactory.$inject = ["$q"];
angularAMD.factory("to$AbortablePromise", to$AbortablePromiseFactory);
