import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";
import template from "./templates/feedback-left-on-choice.html";

var FeedbackLeftOnChoiceComponentDefinition = {
    bindings: {
        leftOnDetails: "="
    },
    controller: FeedbackLeftOnChoiceComponent,
    template: template
};

FeedbackLeftOnChoiceComponent.$inject = ["$rootScope", "$routeParams", "multiLanguageService", "feedbackLeftOnService"];

function FeedbackLeftOnChoiceComponent($rootScope, $routeParams, multiLanguageService, feedbackLeftOnService) {
    this.$rootScope = $rootScope;
    this.$routeParams = $routeParams;
    this.multiLanguageService = multiLanguageService;
    this.feedbackLeftOnService = feedbackLeftOnService;
    this.urlChange = null;
    this.originalDetailsText = "";
    this.originalDetails = angular.copy(this.leftOnDetails);
}

FeedbackLeftOnChoiceComponent.prototype.$onInit = function () {
    this._calculateLeftOnChoice();

    this.urlChange = this.$rootScope.$on(
        "$locationChangeSuccess",
        function (event, args) {
            this._calculateLeftOnChoice();
        }.bind(this)
    );
};

FeedbackLeftOnChoiceComponent.prototype.$onDestroy = function () {
    if (this.urlChange !== null) {
        this.urlChange();
    }
};

FeedbackLeftOnChoiceComponent.prototype._updateOriginalDetailsText = function () {
    this.originalDetailsText = "";

    // If viewing a workbook
    if (this.$routeParams.pageId !== undefined && this.originalDetails.mainAssetId === this.$routeParams.assetId) {
        // and the feedback was left on a page
        if (this.originalDetails.pageId !== null) {
            // and we are viewing any page other than the one that the feedback was left on
            if (this.$routeParams.pageId !== this.originalDetails.pageId) {
                this.originalDetailsText = this.multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.leave_feedback_on_warning");
            }
        }
    }

    // If we are looking at linked assets
    else if (this.originalDetails.mainAssetId !== this.$routeParams.assetId) {
        // And the feedback was not left on the main asset
        if (this.originalDetails.feedbackAssetId !== this.originalDetails.mainAssetId) {
            // and we are viewing a linked asset that the feedback was not left on
            if (this.$routeParams.assetId !== this.originalDetails.feedbackAssetId) {
                this.originalDetailsText = this.multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.leave_feedback_on_warning");
            }
        }
    }
};

FeedbackLeftOnChoiceComponent.prototype._calculateLeftOnChoice = function () {
    const leftOnAssetInfo = this.feedbackLeftOnService.getLeftOnAssetInfo(this.$routeParams.assetId, this.$routeParams.pageId);
    this.leftOnDetails.leaveFeedbackOnAssetId = leftOnAssetInfo.leaveFeedbackOnAssetId;
    this.pageName = leftOnAssetInfo.pageName;
    this.containerOrLinkedAsset = leftOnAssetInfo.isContainerAsset || leftOnAssetInfo.isLinkedAsset;
    this.hideCheckbox = this.containerOrLinkedAsset && leftOnAssetInfo.leaveFeedbackOnAssetId === "";

    if (this.hideCheckbox) {
        this.leaveOnMessage = this.multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.user_has_not_created");
    } else if (leftOnAssetInfo.isLinkedAsset) {
        this.leaveOnMessage = this.multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.leave_feedback_on_link");
    } else {
        this.leaveOnMessage = this.multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.leave_feedback_on_page");
    }
    this._updateOriginalDetailsText();
};

angularAMD.component("feedbackLeftOnChoice", FeedbackLeftOnChoiceComponentDefinition);

export { FeedbackLeftOnChoiceComponentDefinition as feedbackLeftOnChoice };
