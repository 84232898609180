import { angularAMD } from "@pebblepad/amd";
import enquire from "enquire.js/dist/enquire";
import "../utilities/animationHelperService";
import "../utilities/helpers";

angularAMD.directive("expandable", [
    "$timeout",
    "$rootScope",
    "animationHelperService",
    "helpers",
    function ($timeout, $rootScope, animationHelperService, helpers) {
        // Get spa menu height
        // get it done before outside return
        // because in this case it will be triggered only once. (performance reason)
        var spa_menu_el = document.getElementById("spa-menu-wrapper");
        var spa_menu_height = spa_menu_el ? spa_menu_el.offsetHeight : 0;
        enquire.register("screen and (max-width: 460px)", {
            match: function () {
                spa_menu_height = spa_menu_el ? spa_menu_el.offsetHeight : 0;
            },
            unmatch: function () {
                spa_menu_height = spa_menu_el ? spa_menu_el.offsetHeight : 0;
            }
        });

        var previous_elem = {};
        var current_elem = {};

        return {
            restrict: "A",
            scope: {
                closeOnDemand: "="
            },
            link: function (scope, element, attrs) {
                // isolated namespace
                var local = {};

                // decide if we need to force a tab index on the header or not (Default false)
                if (attrs.noheadertabindex === "true") {
                    scope.no_header_tab_index = true;
                } else {
                    scope.no_header_tab_index = false;
                }

                // array css attrs that need to listen for end of their transitions
                scope.cssPropertyNames = ["height", "min-height"];

                // find all needed elements in the DOM
                scope.cacheNeededData = function () {
                    local.header_el = angular.element(element[0].getElementsByClassName("header")[0]);
                    local.clickable_el = angular.element(element[0].getElementsByClassName("clickable")[0]);
                    local.content_el = angular.element(element[0].getElementsByClassName("content")[0]);
                    local.inner_el = angular.element(local.content_el[0].getElementsByClassName("inner")[0]);
                    if (attrs.inModal) {
                        scope.modal_dialog_wrapper = document.getElementsByClassName("modal-dialog__wrapper")[0];
                    }
                };

                // this removes event click from links and ng-click within "clickable" area,
                // so links behave as normal
                scope.setNormalLinksBehaviour = function () {
                    // find all <a> tags and make them clickable and selectable with the keyboard
                    var all_links = angular.element(local.clickable_el[0].getElementsByTagName("a"));
                    all_links.bind(local.user_event + " keydown", function (event) {
                        event.stopImmediatePropagation();
                    });

                    // find all [ng-click] and make them clickable and selectable with the keyboard
                    var all_ng_clicks = angular.element(local.clickable_el[0].querySelectorAll("[ng-click]"));
                    all_ng_clicks.bind(local.user_event + " keydown", function (event) {
                        event.stopImmediatePropagation();
                    });

                    // find all not no-expands and make them clickable and selectable with the keyboard
                    var no_expands = angular.element(local.clickable_el[0].getElementsByClassName("no-expand"));
                    no_expands.bind(local.user_event + " keydown", function (event) {
                        event.stopImmediatePropagation();
                    });

                    // For the navigation reorder modal
                    // stops the expandable toggle when clicking an ng-click element in the header element
                    if (attrs.reorderpages === "true") {
                        var all_ng_clicks_in_header = angular.element(local.header_el[0].querySelectorAll("[ng-click]"));
                        all_ng_clicks_in_header.bind(local.user_event + " keydown", function (event) {
                            event.stopImmediatePropagation();
                        });
                    }
                };

                // event listeners for all transitions end
                scope.listenForTheEndOfTransitions = function () {
                    local.content_el[0].addEventListener("transitionend", scope.detectTheEnd, false);
                    local.content_el[0].addEventListener("webkitTransitionEnd", scope.detectTheEnd, false);
                    local.content_el[0].addEventListener("mozTransitionEnd", scope.detectTheEnd, false);
                    local.content_el[0].addEventListener("msTransitionEnd", scope.detectTheEnd, false);
                    local.content_el[0].addEventListener("oTransitionEnd", scope.detectTheEnd, false);
                };

                // after element finished expanding, set height and min-height.
                scope.detectTheEnd = function (e) {
                    if (local.expanding === true && scope.cssPropertyNames.indexOf(e.propertyName) > -1) {
                        $timeout(function () {
                            scope.setElementHeight("auto");
                            element.removeClass("trigger-animation"); // trigger all animations within expandable
                            scope.scrollIntoView();
                        }, 25);
                    }
                };

                scope.scrollIntoView = function () {
                    var client_height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                    var element_height = element[0].offsetHeight;
                    var current_scroll_top = document.all ? document.scrollTop : window.pageYOffset;
                    if (attrs.inModal) {
                        current_scroll_top = scope.modal_dialog_wrapper.scrollTop;
                    }
                    var current_scroll_left = document.all ? document.scrollLeft : window.pageXOffset;
                    var elem_rect, elem_to_top;

                    // if inner fits the screen, then try to scroll into the view
                    if (element_height < client_height - spa_menu_height) {
                        elem_rect = element[0].getBoundingClientRect();
                        var elem_to_bottom = client_height - elem_rect.height - elem_rect.top - 10;

                        if (elem_rect.top < spa_menu_height) {
                            elem_to_top = spa_menu_height - elem_rect.top + 10;

                            animationHelperService.animate({
                                duration: 300,
                                timing_fn: animationHelperService.timing_functions.easeInOutCubic,
                                step: function (delta) {
                                    window.scrollTo(current_scroll_left, current_scroll_top - elem_to_top * delta);
                                }
                            });
                        }

                        if (elem_to_bottom < 0) {
                            animationHelperService.animate({
                                duration: 300,
                                timing_fn: animationHelperService.timing_functions.easeInOutCubic,
                                step: function (delta) {
                                    if (attrs.inModal) {
                                        scope.modal_dialog_wrapper.scrollTop = current_scroll_top + Math.abs(elem_to_bottom) * delta;
                                    } else {
                                        window.scrollTo(current_scroll_left, current_scroll_top + Math.abs(elem_to_bottom) * delta);
                                    }
                                }
                            });
                        }
                    }
                    // if inner doesn't fit the screen, then try to scroll into the view to the top of the element
                    else {
                        elem_rect = element[0].getBoundingClientRect();
                        // distance of element from top (minus spa menu, minus 10px to give more air)
                        elem_to_top = elem_rect.top - spa_menu_height - 10;

                        animationHelperService.animate({
                            duration: 300,
                            timing_fn: animationHelperService.timing_functions.easeInOutCubic,
                            step: function (delta) {
                                if (attrs.inModal) {
                                    scope.modal_dialog_wrapper.scrollTop = current_scroll_top + Math.abs(elem_to_bottom) * delta;
                                } else {
                                    window.scrollTo(current_scroll_left, current_scroll_top + elem_to_top * delta);
                                }
                            }
                        });
                    }
                };

                scope.completeTransition = function (action) {
                    // need to wait 25ms to make below code execute asynchronous
                    $timeout(function () {
                        // touch devices fix
                        // should toggle expanded after height was set
                        local.content_el.removeClass("disable-transitions");

                        if (action === "expand") {
                            // do this on expanding (if state changed through $watch)
                            element.addClass("expanded");
                        } else if (action === "collapse") {
                            // do this on collapsing (if state changed through $watch)
                            element.removeClass("expanded");
                        } else {
                            // when user toggle expandable manually, do this
                            element.toggleClass("expanded");
                        }
                    }, 25);
                };

                scope.setElementHeight = function (height_type) {
                    switch (height_type) {
                        case "auto":
                            local.content_el.addClass("disable-transitions"); // need this to prevent container "jumps" on some touch devices
                            local.content_el.css({ height: "auto" });
                            break;

                        case "as content":
                            var inner_el_height = local.inner_el[0].offsetHeight;

                            if (inner_el_height > 0) {
                                local.content_el.css({ height: inner_el_height + "px" });
                            } else {
                                scope.setElementHeight("auto");
                            }
                            break;

                        case "zero":
                            local.content_el.css({ height: "0px" });
                            break;

                        default:
                        //console.log("wrong height parameter!");
                    }
                };

                // run on collapse
                scope.collapse = function () {
                    local.expanding = false;

                    if (local.one_a_time) {
                        local.triggerExpandableListener();
                    }

                    // magic hack (i.e. asynchronous execution) for smooth transition
                    // honestly, it gives a moment for code to set height of container,
                    // be a fixed number, so transition will know from where to start
                    $timeout(function () {
                        // here code sets the ending point of transition
                        // so you get transition FROM["container current height"] -> TO["zero height"]
                        scope.setElementHeight("zero");
                    }, 50);
                };

                // run on expand
                scope.expand = function () {
                    local.expanding = true;

                    if (local.one_a_time) {
                        local.triggerExpandableListener = scope.$on("triggerExpandable", function () {
                            // tslint:disable-next-line:triple-equals
                            if (previous_elem[local.one_a_time] == element[0] && previous_elem[local.one_a_time] != current_elem[local.one_a_time] && local.expanding) {
                                scope.toggleExpandable();
                            }
                        });

                        // make current element as last entered
                        previous_elem[local.one_a_time] = element[0];
                    }
                };

                scope.toggleExpandable = function () {
                    // meet requirement of flag "animation = false" on the directive element
                    if (attrs.animation === "false") {
                        // make container equals to auto,
                        // so all transitions fails to appear
                        scope.setElementHeight("auto");
                        element.toggleClass("expanded");
                    }
                    // default behaviour
                    else {
                        // set min height equals to height of content
                        scope.setElementHeight("as content");
                        scope.completeTransition();
                    }

                    // do on collapse
                    if (local.expanding) {
                        scope.collapse();
                    }
                    // do on expand
                    else {
                        scope.expand();
                    }

                    scope.toggleAriaExpanded(local.expanding);
                };

                scope.collapseOnEvent = function () {
                    var event_name = attrs.collapseOnEvent;

                    if (event_name) {
                        scope.$on(event_name, function () {
                            element.removeClass("expanded");
                            scope.collapse();
                        });
                    }
                };

                scope.toggleAriaExpanded = function (expanded) {
                    local.clickable_el[0].setAttribute("aria-expanded", expanded.toString());
                };

                // initialise expandable element
                scope.init = function () {
                    scope.onload = true;

                    local.one_a_time = attrs.oneATime ? attrs.oneATime : false;

                    scope.collapseOnEvent();

                    // initialise all items required for expandable
                    scope.cacheNeededData();

                    if (!local.inner_el[0].id) {
                        local.inner_el[0].id = helpers.guid();
                    }

                    if (!local.clickable_el[0].getAttribute("aria-controls")) {
                        local.clickable_el[0].setAttribute("aria-controls", local.inner_el[0].id);
                    }

                    // force tab index on header unless requested not too by an attribute
                    // tslint:disable-next-line:triple-equals
                    if (scope.no_header_tab_index != true) {
                        local.header_el[0].setAttribute("tabindex", 0);
                    }

                    // *** This was causing problems when the expandable was used on a modal. Default to "click" instead.
                    /*// define event type for touch or not touch devices
                if (UserAgentService.touch) {
                    local.user_event = "tap";
                } else {
                    local.user_event = "click";
                }*/
                    local.user_event = "click";

                    // add cursor pointer to the clickable element
                    local.clickable_el.css("cursor", "pointer");

                    // stop immediate event propagation when user click on links within expandable clickable area
                    $timeout(function () {
                        scope.setNormalLinksBehaviour();
                    });

                    // start all listeners for transition end
                    scope.listenForTheEndOfTransitions();

                    // if attribute "start-open" exists then add $watcher on it
                    // this allow to have "start-open" attr as optional on html tags
                    if (attrs.startOpen !== undefined) {
                        // start watcher for "start-open"
                        scope.unbindStartOpenObserve = attrs.$observe("startOpen", function (current_state) {
                            if (!current_state) {
                                current_state = false;
                            } else {
                                current_state = JSON.parse(current_state);
                            }

                            // expand element if it is set to "start-open = true"
                            // tslint:disable-next-line:triple-equals
                            if (current_state == true) {
                                // indicator for animation action (expanding/collapsing)
                                scope.expand();

                                // if first time loaded, then make it open without transition (performance optimisation)
                                // tslint:disable-next-line:triple-equals
                                if (scope.onload == true) {
                                    element.addClass("expanded");
                                    scope.setElementHeight("auto");
                                } else {
                                    // set min height equals to height of content
                                    scope.setElementHeight("as content");
                                    scope.completeTransition("expand");
                                }
                            }
                            // when "start-open = false" collapse expandable
                            else {
                                if (scope.onload !== true) {
                                    // set height equals to height of content
                                    scope.setElementHeight("as content");
                                    scope.completeTransition("collapse");
                                    // execute default function on collapse
                                    scope.collapse();
                                }
                            }

                            scope.onload = false; // initial load can be just once, that's why need to change this to false
                        });
                    }

                    // if attribute "close-on-demand" exists then add $watcher on it
                    // this allow you to trigger a close from code if needed
                    if (attrs.closeOnDemand !== undefined) {
                        scope.unbindCloseOnDemandWatcher = scope.$watch("closeOnDemand", function (current_state) {
                            if (current_state === true) {
                                scope.toggleExpandable();
                                scope.closeOnDemand = false;
                            }
                        });
                    }

                    // makes sure that the on-focus highlight is removed when you click away from the focused item
                    local.header_el.bind("blur", function () {
                        local.header_el.removeClass("on-focus");
                    });

                    // makes sure we don't add the on-focus highlight if we are focusing on a link within the container
                    // checking for keyCode 9 - tab
                    local.header_el.bind("keyup", function (e) {
                        if (e.target.tagName === "DIV" && e.keyCode === 9) {
                            local.header_el.addClass("on-focus");
                        }
                    });

                    // ensures that the on-focus highlight is removed if we tab (keyCode 9)off the item
                    local.header_el.bind("keydown", function (e) {
                        if (e.keyCode === 9) {
                            local.header_el.removeClass("on-focus");
                        }

                        // stops the enter(13) and spacebar(32) keys opening everything inside the main header through propagation.
                        if (e.keyCode === 13 || e.keyCode === 32) {
                            e.preventDefault();
                            scope.toggleExpandable();
                        }
                    });

                    // delay to give time compiler to do the job and then trigger smooth transition
                    local.make_delay = true;

                    // add event listener to clickable element
                    local.clickable_el.bind(local.user_event, function () {
                        current_elem[local.one_a_time] = element[0];
                        $rootScope.$broadcast("triggerExpandable");

                        if (local.make_delay) {
                            $timeout(function () {
                                scope.toggleExpandable();
                                local.make_delay = false;
                            }, 150);
                        } else {
                            scope.toggleExpandable();
                        }
                    });
                };

                scope.unregisterExpandable = function () {
                    if (local.content_el !== undefined) {
                        local.content_el[0].removeEventListener("transitionend", scope.detectTheEnd, false);
                        local.content_el[0].removeEventListener("webkitTransitionEnd", scope.detectTheEnd, false);
                        local.content_el[0].removeEventListener("mozTransitionEnd", scope.detectTheEnd, false);
                        local.content_el[0].removeEventListener("msTransitionEnd", scope.detectTheEnd, false);
                        local.content_el[0].removeEventListener("oTransitionEnd", scope.detectTheEnd, false);
                    }

                    local.expanding = false;
                    scope.onload = false;
                    local.clickable_el.unbind(local.user_event);
                    scope.collapse();

                    if (scope.unbindCloseOnDemandWatcher !== undefined) {
                        scope.unbindCloseOnDemandWatcher();
                    }

                    if (scope.unbindStartOpenObserve !== undefined) {
                        scope.unbindStartOpenObserve();
                    }
                };

                // if directive used statically (i.e. without "ng-include" or any dynamic templates)
                // then start expandable automatically after directive is ready
                // otherwise use [ng-init="init()"] to start expandable from dynamically rendered layout
                let prevValue;
                attrs.$observe("expandable", (newVal) => {
                    if (prevValue === "true") {
                        if (newVal === "" || newVal === undefined || newVal === "false") {
                            scope.unregisterExpandable();
                            return;
                        }
                    }
                    if (newVal !== "false") {
                        if (element[0].getElementsByClassName("header")[0]) {
                            $timeout(function () {
                                scope.init();
                            });
                        }
                    }
                    prevValue = newVal;
                });
            }
        };
    }
]);
