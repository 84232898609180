import { flip, shift, Placement } from "@floating-ui/dom";
import { IFloatingPositioningConfig } from "./interfaces/IFloatingPositioningConfig";
import { floatingElementStyler } from "./utilities/FloatingElementStyler";
import { IPositioningData } from "./interfaces/IPositioningData";

export const dropdownMatchedWidthPositioningFactory = (boundaryElement: HTMLElement, placement: Placement): IFloatingPositioningConfig => {
    const middleware = [
        flip({
            boundary: boundaryElement,
            crossAxis: false
        }),
        shift({
            boundary: boundaryElement,
            rootBoundary: "document"
        })
    ];

    return {
        positionConfig: {
            middleware: middleware,
            placement: placement,
            strategy: "absolute"
        },
        styler: (floatingElement: HTMLElement, referenceElement: HTMLElement, data: IPositioningData) => {
            floatingElementStyler(floatingElement, referenceElement, data);
            floatingElement.style.width = `${referenceElement.clientWidth}px`;
        }
    };
};
