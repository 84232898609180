import { angularAMD } from "@pebblepad/amd";
import { dataEncryptionService } from "../utilities/dataEncryptionService";

class PersistentObjectStore {
    constructor($window) {
        this._$window = $window;
        /** @type {Map<string, {}>} */
        this._memoryStore = new Map();
    }

    /**
     * @param {string} key
     * @param {{}} data
     * @param {string} encryptionPassphrase
     */
    store(key, data, encryptionPassphrase) {
        this._memoryStore.set(key, data);
        const encryptedData = dataEncryptionService.AES.encrypt(JSON.stringify(data), encryptionPassphrase);
        try {
            this._$window.localStorage.setItem(key, encryptedData);
        } catch (error) {
            console.error(error.message);
        }
    }

    /**
     * @param {string} key
     * @return {boolean}
     */
    has(key) {
        return this._memoryStore.has(key) || this._$window.localStorage.hasOwnProperty(key);
    }

    /**
     * @returns {Array<string>}
     */
    keys() {
        const keys = new Set([...Object.keys(this._$window.localStorage), ...this._memoryStore.keys()]);
        return Array.from(keys);
    }

    clear() {
        this._memoryStore.clear();
        this._$window.localStorage.clear();
    }

    /**
     * @param {string} key
     * @param {string} encryptionPassphrase
     * @returns {null|{}}
     */
    retrieve(key, encryptionPassphrase) {
        const data = this._memoryStore.get(key);
        if (data !== undefined) {
            return this._normaliseMemoryReference(data);
        }

        const encryptedData = this._$window.localStorage.getItem(key);

        if (encryptedData !== null) {
            try {
                const result = dataEncryptionService.AES.decrypt(encryptedData, encryptionPassphrase);
                const dataAsString = result.toString(dataEncryptionService.enc.Utf8);
                return JSON.parse(dataAsString);
            } catch (error) {
                // Swallowing error silently as legacy uses of previous code try and retrieve all local storage keys
                // including keys which don't have json so they outputting this to console will cause alot of noise
                return null;
            }
        }

        return null;
    }

    /**
     * @param {string} key
     */
    remove(key) {
        this._memoryStore.delete(key);
        this._$window.localStorage.removeItem(key);
    }

    /**
     * @param {{}} data
     * @returns {{}}
     * @private
     */
    _normaliseMemoryReference(data) {
        // Normalize memory reference so that it'll match local storage value
        // eg, Dates will be iso strings etc
        return JSON.parse(JSON.stringify(data));
    }
}

PersistentObjectStore.$inject = ["$window"];

angularAMD.service("persistentObjectStore", PersistentObjectStore);

export { PersistentObjectStore as persistentObjectStore };
