import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../../../../constants/assessment.constants";
import "../../../../user/user.service";

SubmissionPermissionHelper.$inject = ["$q", "User"];

function SubmissionPermissionHelper($q, user) {
    this.services = {
        user: user,
        $q: $q
    };

    this.supportedPermissions = ["AddFeedbackComments", "AddGrades", "AddLevelOneApprovals", "AddLevelTwoApprovals", "AddLevelThreeApprovals", "AddPageVerifications"];
}

SubmissionPermissionHelper.prototype.getPermissions = function () {
    var user = this.services.user.getDto();
    return user !== null ? user.SubmissionPermissions : null;
};

SubmissionPermissionHelper.prototype.hasSupportedPermissions = function () {
    var user = this.services.user.getDto();
    if (user === null) {
        return false;
    }

    var keys = Object.keys(user.SubmissionPermissions);
    return keys.some(
        function (key) {
            return user.SubmissionPermissions[key] && this.supportedPermissions.indexOf(key) !== -1;
        }.bind(this)
    );
};

SubmissionPermissionHelper.prototype.doesWorkspaceAllowRelease = function () {
    var releaseSetting = this.getFeedbackReleaseSetting();
    return (
        releaseSetting === ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.ALWAYS_RELEASE ||
        releaseSetting === ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.DEFAULT_HOLD ||
        releaseSetting === ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.DEFAULT_RELEASE
    );
};

SubmissionPermissionHelper.prototype.getFeedbackReleaseSetting = function (feedbackType) {
    const user = this.services.user.getDto();
    if (user === null || user.SubmissionPermissions === null || user.SubmissionPermissions === undefined) {
        return "";
    }

    switch (feedbackType) {
        case ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_ONE:
            return user.SubmissionPermissions.ReleaseLevelOneApprovals === true ? user.SubmissionPermissions.ReleaseFeedbackSetting : ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.ALWAYS_HOLD;
        case ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_TWO:
            return user.SubmissionPermissions.ReleaseLevelTwoApprovals === true ? user.SubmissionPermissions.ReleaseFeedbackSetting : ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.ALWAYS_HOLD;
        case ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_THREE:
            return user.SubmissionPermissions.ReleaseLevelThreeApprovals === true ? user.SubmissionPermissions.ReleaseFeedbackSetting : ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.ALWAYS_HOLD;
        case ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.PAGEVERIFICATION:
            return user.SubmissionPermissions.ReleasePageVerifications === true ? ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.ALWAYS_RELEASE : ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.ALWAYS_HOLD;
        default:
            return user.SubmissionPermissions.ReleaseFeedbackSetting;
    }
};

SubmissionPermissionHelper.prototype.getSubmissionsMainAssetId = function () {
    var user = this.services.user.getDto();
    return user !== null && user.SubmissionPermissions ? user.SubmissionPermissions.SubmissionsMainAssetRefId : "";
};

SubmissionPermissionHelper.prototype.hasCorrectRoleForElement = function (userDto, elementDto) {
    if (!elementDto.EditAvailableFor || elementDto.EditAvailableFor.length === 0) {
        return true;
    }
    var useRole = userDto.Role.toLowerCase();
    return elementDto.EditAvailableFor.some(function (role) {
        return role.toLowerCase() === useRole;
    });
};

SubmissionPermissionHelper.prototype.canUserFillInAssessorFields = function (userDto) {
    return (this.canUserAddAssessorFeedback(userDto) || this.userHasManageFeedback(userDto)) && userDto.SubmissionPermissions.CanModifyAssessorFeedback;
};

SubmissionPermissionHelper.prototype.canUserAddAssessorFeedback = function (userDto) {
    return Boolean(userDto && userDto.SubmissionPermissions && userDto.SubmissionPermissions.AddAssessorFeedback);
};

SubmissionPermissionHelper.prototype.userHasManageFeedback = function (userDto) {
    return Boolean(userDto && userDto.SubmissionPermissions && userDto.SubmissionPermissions.ManageFeedback);
};

SubmissionPermissionHelper.prototype.canUserAddFeedbackComments = function (userDto = null) {
    return Boolean(
        userDto !== null &&
            userDto.SubmissionPermissions !== undefined &&
            userDto.SubmissionPermissions.CanAddBlockFeedback &&
            userDto.SubmissionPermissions.AddFeedbackComments &&
            userDto.SubmissionPermissions.CanAddFeedback
    );
};

SubmissionPermissionHelper.prototype.canUserAddCapabilityApprovals = function (userDto = null) {
    return Boolean(userDto !== null && userDto.SubmissionPermissions !== undefined && userDto.SubmissionPermissions.AddCapabilityApprovals && userDto.SubmissionPermissions.CanAddFeedback);
};

SubmissionPermissionHelper.prototype.canUserEditAssessorField = function (userDto, elementDto) {
    return Boolean(
        userDto &&
            userDto.SubmissionPermissions &&
            (userDto.SubmissionPermissions.ManageFeedback || (userDto.UserId === elementDto.AnswerLastUpdatedBy && userDto.SubmissionPermissions.AddAssessorFeedback))
    );
};

SubmissionPermissionHelper.prototype.canUserFillInAssessorField = function (userDto, elementDto) {
    if (elementDto.IsNew) {
        return this.canUserAddAssessorFeedback(userDto) && this.hasCorrectRoleForElement(userDto, elementDto);
    }

    if (this.userHasManageFeedback(userDto)) {
        return true;
    }

    return this.canUserEditAssessorField(userDto, elementDto) && this.hasCorrectRoleForElement(userDto, elementDto);
};

SubmissionPermissionHelper.prototype.isOwnerOrCollaborator = function (userDto, assetRefResponse, asset) {
    return Boolean(
        userDto && assetRefResponse && (asset.CreatedBy === userDto.UserId || (assetRefResponse.CollaborateWithUsers && assetRefResponse.CollaborateWithUsers.indexOf(userDto.UserId) >= 0))
    );
};

SubmissionPermissionHelper.prototype.doesWorkspaceRestrictRecallingFeedback = function (userDto) {
    return Boolean(userDto && userDto.SubmissionPermissions && userDto.SubmissionPermissions.RestrictRecallFeedback);
};

SubmissionPermissionHelper.prototype.getScorecardSettings = function () {
    var user = this.services.user.getDto();
    if (user !== null && user.SubmissionPermissions) {
        const permissions = user.SubmissionPermissions;
        return {
            components: permissions.ScorecardTemplateComponents,
            altGradeEnabled: permissions.IsScorecardAlternativeGradeEnabled,
            isWeighted: permissions.IsScorecardWeighted
        };
    }
};

angularAMD.service("SubmissionPermissionHelper", SubmissionPermissionHelper);

export { SubmissionPermissionHelper };
