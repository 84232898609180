import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../utilities/urlService";

angularAMD.factory("productMenuService", [
    "$http",
    "baseUrlsFactory",
    "urlService",
    function ($http, baseUrlsFactory, urlService) {
        var factory = {};

        factory.getProductMenuData = function () {
            $http.defaults.withCredentials = true;
            return $http.get(baseUrlsFactory.api_base_url + "SharedMenu/GetProductDropdown");
        };

        factory.checkLoggedIn = function () {
            return urlService.promisedGet(baseUrlsFactory.api_base_url + "SharedMenu/GetLoggedInUserId");
        };

        return factory;
    }
]);
