import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../services/versionHelper.service";
import "../../screenReaderAnnouncer/screenReaderAnnouncer.directive";
import template from "./templates/version-list.html";

angularAMD.directive("versionList", VersionList);
VersionList.$inject = [];

function VersionList() {
    return {
        template: template,
        restrict: "E",
        scope: {
            assetId: "@assetId",
            asset: "="
        },
        controller: "VersionListController",
        controllerAs: "vm",
        bindToController: true
    };
}

angularAMD.controller("VersionListController", VersionListController);
VersionListController.$inject = ["$location", "$filter", "multiLanguageService", "VersionHelper", "$scope"];

function VersionListController(_$location_, _$filter_, multiLanguageService, _VersionHelper_, _scope_) {
    this.services = {
        filter: _$filter_,
        location: _$location_,
        versionHelper: _VersionHelper_,
        scope: _scope_
    };
    this.multiLanguageService = multiLanguageService;
    this.errorMessage = "";
    this.loading = true;
    this.versions = [];
    this.setupVersionList(this.assetId);
}

VersionListController.prototype.setupVersionList = function (id) {
    this.loading = true;
    return this.services.versionHelper.getAssetVersions(id).then(
        function (response) {
            if (!response) {
                this.onError();
                return;
            }

            if (response.UserHasWritePermission === true) {
                // user has write and list provided (empty or not)
                this.versions = response.Versions;
                this.errorMessage = "";
            } else if (this.services.location.url().indexOf("public") > -1) {
                // user doesn't have write and its the public page
                this.versions = [];
                this.errorMessage = this.multiLanguageService.getString("versioning.side_bar.no_available_versions");
            }

            if (this.errorMessage !== null && this.errorMessage !== "") {
                this.loadedMessage = this.errorMessage;
            } else if (this.versions.length !== 0) {
                this.loadedMessage = this.multiLanguageService.getString("versioning.side_bar.versions_loaded");
            } else {
                this.loadedMessage = this.multiLanguageService.getString("versioning.side_bar.no_available_versions");
            }

            this.loading = false;
            this.services.scope.$emit("infoPanelContentLoaded", true, true); // let sidebar know it can turn off its loading spinner
        }.bind(this),
        this.onError.bind(this)
    );
};

VersionListController.prototype.onError = function (rejection) {
    this.loading = false;
    this.errorMessage = this.multiLanguageService.getString("versioning.side_bar.error_getting_versions");
    this.services.scope.$emit("infoPanelContentLoaded", true, true); // let sidebar know it can turn off its loading spinner
};

VersionListController.prototype.getVersionDescription = function (modifiedTime, index) {
    var timeFormatted = this.services.filter("pebbleDate")(modifiedTime, "timeOnly");
    var dateFormatted = this.services.filter("pebbleDate")(modifiedTime, "dateAndMonth");

    var description = this.multiLanguageService.getString("versioning.side_bar.versions_list_item_prompt", {
        time: timeFormatted,
        date: dateFormatted
    });

    if (this.versions.length === 1) {
        description += " " + this.multiLanguageService.getString("versioning.side_bar.versions_list_item_lonely_prompt");
    } else if (index === 0) {
        description += " " + this.multiLanguageService.getString("versioning.side_bar.versions_list_item_latest_prompt");
    } else if (index === this.versions.length - 1) {
        description += " " + this.multiLanguageService.getString("versioning.side_bar.versions_list_item_ealiest_prompt");
    }
    return description;
};
