import { flip, MiddlewareState, Placement, shift, size } from "@floating-ui/dom";
import { isAndroid, isIpadLike, isIphoneLike } from "@pjs/utilities";
import { IFloatingPositioningConfig } from "./interfaces/IFloatingPositioningConfig";
import { applyFloatingHeightRestriction } from "./utilities/ApplyFloatingHeightRestriction";
import { applyFloatingHeightRestrictionForMobileDevices } from "./utilities/ApplyFloatingHeightRestrictionForMobileDevices";
import { floatingElementStyler } from "./utilities/FloatingElementStyler";
import { IPositioningData } from "./interfaces/IPositioningData";
import { ISizeMiddlewareState } from "./interfaces/ISizeMiddlewareState";

export const toolbarDropdownPositioningFactory = (boundaryElement: HTMLElement, placement: Placement): IFloatingPositioningConfig => {
    const applyHeightRestriction = isAndroid || isIpadLike || isIphoneLike ? applyFloatingHeightRestrictionForMobileDevices : applyFloatingHeightRestriction;

    const sizingMethod = (sizeState: MiddlewareState & ISizeMiddlewareState): void => {
        applyHeightRestriction(sizeState);

        if (sizeState.elements.floating.offsetWidth > sizeState.availableWidth) {
            sizeState.elements.floating.style.maxWidth = `${sizeState.availableWidth}px`;
        }
    };

    const middleware = [
        flip({ boundary: boundaryElement, crossAxis: false }),
        shift({ boundary: boundaryElement, rootBoundary: "document" }),
        size({ apply: sizingMethod, boundary: boundaryElement })
    ];

    return {
        positionConfig: {
            middleware: middleware,
            placement: placement,
            strategy: "absolute"
        },
        styler: (floatingElement: HTMLElement, referenceElement: HTMLElement, data: IPositioningData) => {
            floatingElementStyler(floatingElement, referenceElement, data);
            floatingElement.style.right = "auto";
            floatingElement.style.bottom = "auto";
            floatingElement.style.transform = "none";
        }
    };
};
