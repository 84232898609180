import { IEditorPlaceholder } from "./interfaces/IEditorPlaceholder";

export class DefaultEditorPlaceholder implements IEditorPlaceholder {
    public active: boolean;
    private readonly _editorPlaceholderContainer: HTMLElement;
    private readonly _element: HTMLElement;
    private readonly _text: string;

    constructor(element: HTMLElement, editorPlaceholderContainer: HTMLElement, text: string) {
        this._element = element;
        this._editorPlaceholderContainer = editorPlaceholderContainer;
        this._text = text;
        const existingPlaceholder = this._element.dataset.editorPlaceholder;
        this.active = existingPlaceholder !== undefined && existingPlaceholder !== "";
    }

    public update(content: string): void {
        const hasContent = content !== "" && this._element.textContent !== null && this._element.textContent !== "";

        if (!hasContent && !this.active) {
            this.active = true;
            this._editorPlaceholderContainer.style.setProperty("--placeholder-text", `"${this._text}"`);
        } else if (hasContent && this.active) {
            this.active = false;
            this._editorPlaceholderContainer.style.setProperty("--placeholder-text", `""`);
        }
    }
}
