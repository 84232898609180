import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";

angularAMD.factory("assetFeedbackService", [
    "$http",
    "baseUrlsFactory",
    "AssetEndpointService",
    function ($http, baseUrlsFactory, AssetEndpointService) {
        return {
            getFeedback: function (mainAssetId, pageAssetId = "", anchorId = "") {
                var params = {
                    assetId: mainAssetId,
                    pageAssetId: pageAssetId,
                    anchorId: anchorId
                };

                return AssetEndpointService.getFeedback(params);
            },

            getFeedbackById: function (feedbackId, assetId, submissionId) {
                return AssetEndpointService.getSingleFeedback({
                    feedbackId: feedbackId,
                    assetId: assetId,
                    submissionId: submissionId
                });
            },

            addFeedback: function (feedbackItem) {
                return AssetEndpointService.addFeedback(
                    {
                        feedbackType: feedbackItem.FeedbackType
                    },
                    feedbackItem
                );
            },

            saveMultipleFeedback: function (feedbackItems, feedbackType, assetId, submissionId) {
                return AssetEndpointService.saveMultipleFeedback(
                    {
                        feedbackType: feedbackType,
                        assetId: assetId,
                        submissionId: submissionId
                    },
                    feedbackItems
                );
            },

            editFeedback: function (feedbackItem) {
                return AssetEndpointService.editFeedback(
                    {
                        feedbackType: feedbackItem.FeedbackType
                    },
                    feedbackItem
                );
            },

            deleteFeedback: function (assetId, feedbackId) {
                return AssetEndpointService.deleteFeedback({
                    assetId: assetId,
                    feedbackId: feedbackId
                });
            },

            getState: function (feedbackId) {
                return $http.get(`${baseUrlsFactory.api_base_url}FeedbackState/${feedbackId}`);
            },

            getReplyToFeedback: function (assetId, replyId, feedbackId) {
                return AssetEndpointService.getReplyToFeedback({ assetId: assetId, replyId: replyId, feedbackId: feedbackId });
            },

            addNewOrUpdateReplyToFeedback: function (dto) {
                dto.Comment = dto.Body;

                return AssetEndpointService.addNewOrUpdateReplyToFeedback(
                    {
                        assetId: dto.AssetId
                    },
                    dto
                );
            },

            deleteReplyToFeedback: function (dto) {
                var params = {
                    assetId: dto.AssetId,
                    feedbackId: dto.ParentId,
                    replyId: dto.Id
                };

                return AssetEndpointService.deleteReplyToFeedback(params);
            }
        };
    }
]);
