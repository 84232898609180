import { MultiLanguageContentProvider } from "./services/multiLanguageContentProvider.service";
import { I18NextServiceProvider } from "./services/pebbleI18next";

class MultiLanguageServiceProvider {
    constructor() {
        this.$get = [
            "$q",
            "MultiLanguageContentProvider",
            "I18NextService",
            ($q, multiLanguageContentProvider, i18NextService) => {
                const multiLanguageService = new MultiLanguageService($q, multiLanguageContentProvider, i18NextService);
                multiLanguageService.setDictionary(this.dictionaries);
                return multiLanguageService;
            }
        ];
        this.dictionaries = null;
    }
}

var multiLangModule = angular.module("multiLangModule", ["ng"]);
multiLangModule.service("MultiLanguageContentProvider", MultiLanguageContentProvider);
multiLangModule.service("I18NextService", I18NextServiceProvider);
multiLangModule.config([
    "$sceDelegateProvider",
    function ($sceDelegateProvider) {
        var whiteListUrlShared = window.config.getSharedComponentBaseUrl() + "**";

        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            "self",
            // Allow loading from our assets domain.  Notice the difference between * and **.
            whiteListUrlShared
        ]);
    }
]);

multiLangModule.provider("multiLanguageService", MultiLanguageServiceProvider);

function MultiLanguageService($q, multiLanguageContentProvider, i18NextService) {
    this.dictionary = null;
    this.subsets = {
        learningCentre: null
    };
    this.listOptions = null;
    this._services = {
        $q: $q,
        multiLanguageContentProvider: multiLanguageContentProvider,
        i18next: i18NextService.i18next
    };
    this._i18nDictionary = null;
    this.stringNamespace = "stringResources";
    this.urlNamespace = "urlResources";
    this.imageNamespace = "imageResources";
}

MultiLanguageService.prototype.defaultDictionary = "en-GB";

MultiLanguageService.prototype.getString = function (string, opts) {
    return this._getValue(this.stringNamespace, string, opts);
};

MultiLanguageService.prototype.getUrl = function (string, opts) {
    return this._getValue(this.urlNamespace, string, opts);
};

MultiLanguageService.prototype.getImage = function (string, opts) {
    return this._getValue(this.imageNamespace, string, opts);
};

MultiLanguageService.prototype.keyExists = function (string) {
    return this._i18nDictionary.exists(this.stringNamespace + this._i18nDictionary.options.nsSeparator + string);
};

MultiLanguageService.prototype._getValue = function (namespace, string, opts) {
    return this._i18nDictionary.t(namespace + this._i18nDictionary.options.nsSeparator + string, opts);
};

MultiLanguageService.prototype.addResourceBundle = function (lang, namespace, resources, deep, overwrite) {
    return this._i18nDictionary.addResourceBundle(lang, namespace, resources, deep, overwrite);
};

MultiLanguageService.prototype.setDictionary = function (newDictionaries) {
    this.dictionary = newDictionaries.main;
    this.lang_attr = newDictionaries.main.lang_attr;

    this._i18nDictionary = this._services.i18next.createInstance(
        {
            lng: newDictionaries.main.lang_attr,
            debug: false,
            resources: [],
            pluralSeparator: "__",
            contextSeparator: "^",
            fallbackLng: [this.defaultDictionary],
            interpolation: {
                escapeValue: false,
                skipOnVariables: false,
                format: function (value, format, lng) {
                    if (format === "listor") {
                        return this.handleLists(value, format, lng, this.listOptionsOr);
                    }

                    if (format === "listand") {
                        return this.handleLists(value, format, lng, this.listOptionsAnd);
                    }

                    if (format === "listnone") {
                        return this.handleLists(value, format, lng, this.listOptionsNone);
                    }

                    if (format === "bold") {
                        return "<b>" + value + "</b>";
                    }
                    if (format[0] === ".") {
                        var classNames = format.substring(1).replace(".", " ");
                        return '<span class="' + classNames + '">' + value + "</span>";
                    }

                    return value;
                }.bind(this)
            }
        },
        function (err, t) {
            // Ready
        }.bind(this)
    );

    [newDictionaries.main, newDictionaries.fallback].forEach(
        function (resource) {
            if (resource !== null) {
                this._i18nDictionary.addResourceBundle(resource.lang_attr, this.stringNamespace, resource.dictionary);
                this._i18nDictionary.addResourceBundle(resource.lang_attr, this.urlNamespace, resource.urls);
                this._i18nDictionary.addResourceBundle(resource.lang_attr, this.imageNamespace, resource.images);
            }
        }.bind(this)
    );

    this.listOptionsOr = {
        separator: this.getString("delimiter"),
        lastSeparator: this.getString("or")
    };

    this.listOptionsAnd = {
        separator: this.getString("delimiter"),
        lastSeparator: this.getString("and")
    };

    this.listOptionsNone = {
        separator: this.getString("delimiter"),
        lastSeparator: this.getString("delimiter")
    };
};

MultiLanguageService.prototype.handleLists = function (value, format, lng, listOptions) {
    // TODO Improve upon this sometime
    return this.arrayToSentence(value, listOptions);
};

MultiLanguageService.prototype.arrayToSentence = function (arr, options) {
    if (!Array.isArray(arr)) {
        throw new TypeError(String(arr) + " is not an array. Expected an array.");
    }

    if (arr.length === 0) {
        return "";
    }

    if (arr.length === 1) {
        return arr[0];
    }

    options = options || {};

    if (options.separator === undefined) {
        options.separator = this.getString("delimiter");
    } else {
        this.validateOptionIsString("separator", options);
    }

    if (options.lastSeparator === undefined) {
        options.lastSeparator = this.getString("and");
    } else {
        this.validateOptionIsString("lastSeparator", options);
    }

    return arr.slice(0, -1).join(options.separator) + options.lastSeparator + arr[arr.length - 1];
};

MultiLanguageService.prototype.validateOptionIsString = function (optionName, options) {
    if (typeof options[optionName] !== "string") {
        throw new TypeError(String(options[optionName]) + " is not a string. " + "`" + optionName + "` option must be a string.");
    }
};

MultiLanguageService.prototype.getDictionary = function () {
    //This is here to make sure we don't break the system
    // this.dictionary.getString = this.getString.bind(this);

    return this.dictionary;
};

MultiLanguageService.prototype.setSubset = function (name, data) {
    this.subsets[name] = data;
};

MultiLanguageService.prototype.getLanguage = function () {
    return this.lang_attr;
};

MultiLanguageService.prototype.getSubset = function (key) {
    var subset = this.subsets[key],
        deferred = this._services.$q.defer();

    if (subset) {
        deferred.resolve(subset);
    } else {
        this._services.multiLanguageContentProvider.getLanguageContentViaKey(key).then(
            function (data) {
                this.subsets[key] = data;
                deferred.resolve(data);
            }.bind(this),
            function () {
                deferred.reject();
            }
        );
    }

    return deferred.promise;
};
