/**
 * @description A basic binary search to get the closest number within a list of numbers. The list of numbers MUST BE ordered in ascending order!
 */
export function getClosestNumericalMatch(target: number, numbers: ReadonlyArray<number>): number | null {
    let left = 0;
    let right = numbers.length - 1;
    let closestValue = null;

    while (left <= right) {
        const middle = Math.floor((left + right) / 2);
        const value = numbers[middle];

        if (value === target) {
            return value;
        }

        if (closestValue === null || Math.abs(target - value) <= Math.abs(target - closestValue)) {
            closestValue = value;
        }

        if (value < target) {
            left = middle + 1;
        } else {
            right = middle - 1;
        }
    }

    return closestValue;
}
