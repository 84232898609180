import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../extensions/arrayExtensions";
import "./services/tagService";
import "../autocomplete/autoComplete";
import template from "./templates/tagger.html";

angularAMD.directive("tagger", [
    "$q",
    "$sce",
    "$rootScope",
    "$timeout",
    "$http",
    "baseUrlsFactory",
    "tagService",
    function ($q, $sce, $rootScope, $timeout, $http, baseUrlsFactory, tagService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                assetIds: "=?assetIds",
                tags: "=?tags",
                filterOnly: "=?filterOnly"
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.getTagList = function () {
                        tagService.getTags().then(function (response) {
                            $scope.autoCompleteTags = response.data;
                            angular.forEach($scope.autoCompleteTags, function (tag) {
                                // tslint:disable-next-line:triple-equals
                                tag.IconUrl = baseUrlsFactory.shared_component_base_url + "images/tagger/" + (tag.OrganisationalTag == true ? "institutional-tag.svg" : "tag.svg");
                            });
                        });
                    };
                    $scope.getTagList();

                    $scope.multiLanguageService = $rootScope.multiLanguageService;

                    if (!$scope.tags) {
                        $scope.tags = [];
                    }

                    $scope.viewMode = false;
                    if ($scope.assetIds && $scope.assetIds.length === 1) {
                        $http.defaults.withCredentials = true;
                        $http.get(baseUrlsFactory.api_base_url + "Locking/IsLockedByAnother?assetId=" + $scope.assetIds[0]).then(function (response) {
                            $scope.viewMode = response.data;
                        });
                    }

                    $scope.$on("autoCompleteItemSelected", function (event, data) {
                        $scope.addTag(data.Title);
                    });

                    $scope.addTag = function (tag) {
                        if (!tag) {
                            return;
                        }

                        var regex = new RegExp(",", "g");
                        tag = tag.replace(regex, "");
                        // Make sure there is an acceptable tag to add
                        if (!tag || tag.length < 1) {
                            // Clear text box
                            $scope.$broadcast("clearAutoCompleteInput");
                            return;
                        }
                        for (var i = 0; i < $scope.tags.length; i++) {
                            if ($scope.tags[i].toLowerCase() === tag.toLowerCase()) {
                                // Clear text box
                                $scope.$broadcast("clearAutoCompleteInput");
                                return;
                            }
                        }

                        $scope.tags.push(tag);
                        $scope.$emit("tagAdded", tag);

                        // If not adding tags just for filtering a search then save them
                        // tslint:disable-next-line:triple-equals
                        if ($scope.filterOnly == undefined) {
                            tagService.addTag($scope.assetIds, $scope.tags);
                        }

                        //Reset tag to stop it from being remembered when add is hit.
                        $scope.tag = "";

                        // Clear text box
                        $scope.$broadcast("clearAutoCompleteInput");

                        // Maintain focus within the tagger
                        $timeout(function () {
                            $scope.$broadcast("focusInput");
                        });
                    };

                    $scope.removeTag = function (index) {
                        var removedTag = {
                            index: index,
                            tag: $scope.tags[index]
                        };
                        $scope.tags.splice(index, 1);
                        $scope.$emit("tagRemoved", removedTag);

                        // If removing tags from an asset
                        // tslint:disable-next-line:triple-equals
                        if ($scope.filterOnly == undefined && $scope.assetIds) {
                            tagService.removeTag($scope.assetIds, [removedTag.tag]);
                        }

                        // Maintain focus within the tagger
                        $timeout(function () {
                            var first_tag_el = document.getElementsByClassName("tag-delete")[0];
                            if (first_tag_el) {
                                first_tag_el.focus();
                            } else {
                                $scope.$broadcast("focusInput");
                            }
                        });
                    };

                    $scope.$on("clearTagsFromFilters", function () {
                        $scope.tags = [];
                    });

                    $scope.onTagEntryKeyUp = function (event, text) {
                        var keyPressed = event.which;
                        $scope.tag = text;
                        if (keyPressed === 13 || keyPressed === 188) {
                            // enter or comma
                            $scope.addTag(text);
                        }
                    };
                }
            ],
            link: function (scope, element, attrs) {
                scope.$on("resetTagsList", function (event) {
                    scope.getTagList();
                });
            }
        };
    }
]);
