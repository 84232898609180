import { KeyboardEvent, MouseEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";

export function toggleIsOpen(_: KeyboardEvent | MouseEvent, currentModel: Immutable<MenuAriaModel>): Partial<Immutable<MenuAriaModel>> {
    const newModel = {
        isOpen: !currentModel.isOpen,
        type: currentModel.isOpen ? AriaMenuStatus.Closed : AriaMenuStatus.Open
    };

    return newModel as Partial<Immutable<MenuAriaModel>>;
}
