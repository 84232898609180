import { ExecutionQueue } from "../execution-queue/ExecutionQueue";
import { IExecutionQueue } from "../execution-queue/interfaces/IExecutionQueue";
import { NoopClient } from "../tracker/NoopClient";
import { ITrackerOptions } from "../tracker/interfaces/ITrackerOptions";
import { ICustomDimension } from "../tracker/interfaces/ICustomDimension";
import { ITracker } from "../tracker/interfaces/ITracker";
import { matomoGlobal } from "./consts/MatomoGlobal.const";
import { IMatomoClient } from "./interfaces/IMatomoClient";
import { setupMatomo } from "./functions/SetupMatomo.function";

export class MatomoTracker implements ITracker {
    private _matomoClient: IMatomoClient;
    private _trackerOptions: ITrackerOptions;
    private readonly _executionQueue: IExecutionQueue;

    constructor() {
        this._matomoClient = new NoopClient();
        this._executionQueue = new ExecutionQueue();
        this._trackerOptions = {
            baseUrl: "",
            siteId: 0
        };
    }

    public configure(initialTrackedRoute: string, trackerOptions: ITrackerOptions): Promise<void> {
        this._trackerOptions = trackerOptions;

        return setupMatomo(trackerOptions.baseUrl)
            .then(() => {
                this._onMatomoLoad(initialTrackedRoute);
            })
            .catch((rejection: unknown) => {
                if (rejection !== undefined) {
                    console.error(rejection);
                }
            })
            .finally(() => {
                this._executionQueue.enable();
            });
    }

    public setConsent(consentGiven: boolean): void {
        this._executionQueue.add(() => {
            if (!consentGiven) {
                this._matomoClient.optUserOut();
            } else {
                this._matomoClient.forgetUserOptOut();
            }
        });
    }

    public setCustomDimensions(customDimensions: Array<ICustomDimension>): void {
        for (const customDimension of customDimensions) {
            this._executionQueue.add(() => {
                this._matomoClient.setCustomDimension(customDimension.id, customDimension.value);
            });
        }
    }

    public trackEvent(category: string, action: string, name?: string, value?: number, customDimensions?: Array<ICustomDimension>): void {
        this._executionQueue.add(() => {
            let convertedDimensions: Record<string, string> = {};
            if (customDimensions !== undefined && customDimensions.length !== 0) {
                convertedDimensions = this._convertCustomDimensions(customDimensions);
            }

            this._matomoClient.trackEvent(category, action, name, value, convertedDimensions);
        });
    }

    public trackPageView(customRoute: string, customTitle: string, customDimensions?: Array<ICustomDimension>): void {
        this._executionQueue.add(() => {
            let convertedDimensions: Record<string, string> = {};
            if (customDimensions !== undefined && customDimensions.length !== 0) {
                convertedDimensions = this._convertCustomDimensions(customDimensions);
            }

            const validatedRoute = customRoute === "" ? "https://<pebble>/" : customRoute;
            this._matomoClient.setCustomUrl(validatedRoute);
            this._matomoClient.trackPageView(customTitle, convertedDimensions);
        });
    }

    private _onMatomoLoad(initialTrackedRoute: string): void {
        if (matomoGlobal.Matomo === undefined) {
            return;
        }

        this._matomoClient = matomoGlobal.Matomo.getAsyncTracker(`${this._trackerOptions.baseUrl}matomo.php`, this._trackerOptions.siteId);

        const validatedRoute = initialTrackedRoute === "" ? "https://<pebble>/" : initialTrackedRoute;
        this._matomoClient.setCustomUrl(validatedRoute);
    }

    private _convertCustomDimensions(customDimensions: Array<ICustomDimension>): Record<string, string> {
        const dimensions: Record<string, string> = {};

        return customDimensions.reduce((p, dimension) => {
            p[`dimension${dimension.id}`] = dimension.value;
            return p;
        }, dimensions);
    }
}
