import { FocusEvent, RefObject } from "react";
import { Immutable } from "@pjs/utilities";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";

export function closeIfFocusIsOutside(
    e: FocusEvent,
    triggerReference: RefObject<HTMLElement>,
    targetReference: RefObject<HTMLElement>,
    tempTarget: Element | null
): Partial<Immutable<MenuAriaModel>> | null {
    let targetNode = e.relatedTarget as Node;

    if (triggerReference.current === null || targetReference.current === null) {
        return null;
    }

    if (triggerReference.current.contains(tempTarget)) {
        return null;
    }

    if (targetNode === null && tempTarget !== null) {
        targetNode = tempTarget;
    }

    const isFocusOutside = targetNode === null || !targetReference.current.contains(targetNode);

    if (isFocusOutside) {
        return {
            isOpen: false,
            type: AriaMenuStatus.Closed
        };
    }

    return null;
}
