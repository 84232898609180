import { protocolWhitelist } from "./ProtocolWhitelist.const";

const normaliserElement = document.createElement<"a">("a");

export function isSafeUrlProtocol(url: string): boolean {
    if (protocolWhitelist.test(url)) {
        return true;
    }

    // Anchor element href is used over URL instance, due to Core-JS polyfill encoding issues - https://github.com/zloirock/core-js#caveats-when-using-url-and-urlsearchparams
    normaliserElement.href = url;
    const normalisedUrl = normaliserElement.href;

    return protocolWhitelist.test(normalisedUrl);
}
