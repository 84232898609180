import { angularAMD } from "@pebblepad/amd";
import "../feedbackText/feedbackText.component";
import template from "./certificate.html";

var certificateDefinition = {
    bindings: {
        feedback: "<"
    },
    template: template,
    controller: CertificateComponent
};

CertificateComponent.$inject = [];
// tslint:disable-next-line:no-empty
function CertificateComponent() {}

angularAMD.component("certificate", certificateDefinition);
export { certificateDefinition as certificate };
