import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../assetStore/services/assetStoreService";
import "../../extensions/arrayExtensions";
import "../../expandable/expandable";
import "../../utilities/pebbleDate";
import "../../react2angular/assetIcon";
import template from "../templates/assets-with-links.html";

/**
 * Usage:
 *<assets-with-links assetIds="assetIds"></share> - assetIds: Array<string>
 * OR
 * usage: <assets-with-links assets="assets"></share> - assets: Array<object>
 */
angularAMD.directive("assetsWithLinks", [
    "$http",
    "$sce",
    "$compile",
    "$timeout",
    "$rootScope",
    "baseUrlsFactory",
    "assetStoreService",
    function ($http, $sce, $compile, $timeout, $rootScope, baseUrlsFactory, assetStoreService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                assetIds: "=",
                assets: "=",
                removeFeedback: "=",
                isCopying: "=",
                giveAssetsTo: "=",
                resourceShare: "="
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.removeAsset = function (asset) {
                        $scope._assets.removeOne($scope.assets.indexOf(asset));
                        if ($scope.assets) {
                            $scope.assets.removeOne($scope.assets.indexOf(asset));
                        }
                        if ($scope.giveAssetsTo) {
                            $scope.giveAssetsTo.removeOne($scope.assets.indexOf(asset));
                        }
                        if ($scope.assetIds) {
                            $scope.assetIds.removeOne($scope.assets.indexOf(asset.Id));
                        }
                    };
                    $scope.hideLinked = false;
                    if ($scope.resourceShare) {
                        $scope.hideLinked = true;
                    }

                    $scope.changeAria = function () {
                        var assetWithLinksBlock = angular.element(document.getElementsByClassName("asset-links"));
                        var addAriaExpanded = angular.element(document.getElementsByClassName("add-aria-expanded"));

                        assetWithLinksBlock.hasClass("expanded") ? addAriaExpanded[0].setAttribute("aria-expanded", "true") : addAriaExpanded[0].setAttribute("aria-expanded", "false");
                    };
                }
            ],
            link: function ($scope) {
                $scope.setShareTitleText = function (MainType) {
                    // tslint:disable-next-line:triple-equals
                    if ($scope.resourceShare == true) {
                        if (MainType === "Form") {
                            $scope.shareTitleText = angular.copy($scope.multiLanguageService.getString("sharing.labels.share_as_template"));
                        } else if (MainType === "WorkBook") {
                            $scope.shareTitleText = angular.copy($scope.multiLanguageService.getString("sharing.labels.share_as_workbook"));
                        } else {
                            $scope.shareTitleText = angular.copy($scope.multiLanguageService.getString("sharing.labels.share_as_asset"));
                        }
                    }
                };

                $scope.multiLanguageService = $rootScope.multiLanguageService;
                if ($scope.assets && $scope.assets.length) {
                    $scope._assets = $scope.assets;
                    $scope.setShareTitleText($scope._assets[0].MainType);
                    $scope.ready = true;
                } else {
                    $scope._assets = [];
                    assetStoreService
                        .getAssetWithLinks($scope.assetIds[0], $scope.removeFeedback || false, $scope.isCopying || false)
                        .then(
                            function (response) {
                                $scope._assets[0] = response.data;
                                if ($scope.giveAssetsTo !== undefined) {
                                    $scope.giveAssetsTo = $scope._assets;
                                }
                                $scope.setShareTitleText($scope._assets[0].MainType);
                                $scope.ready = true;
                                // Tell the share modal what the asset type(s) are
                                $scope.$emit("updateAssetMainTypes", $scope._assets.selectOne("MainType"));
                            },
                            function (httpError) {
                                // timed out
                                // tslint:disable-next-line:triple-equals
                                if (httpError.status == 408) {
                                    $scope.tooManyLinks = true;
                                } else {
                                    console.error("Error getting asset links!", httpError);
                                }
                            }
                        )
                        .finally(function () {
                            $scope.ready = true;
                        });
                }

                var removeSelectShareMainTypeListener = $rootScope.$on("selectShareMainTypeOption", function (event, data) {
                    if ($scope.assetIds[0] === data.selectedId[0]) {
                        $scope.selectedShareOption = !$scope.selectedShareOption;
                    } else {
                        $scope.selectedShareOption = false;
                    }
                    $scope.hideLinked = false;
                });

                $scope.$on("$destroy", function () {
                    removeSelectShareMainTypeListener();
                });
            }
        };
    }
]);
