import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";

export class MapToStandardsService {
    constructor(multiLanguageService) {
        this.multiLanguageService = multiLanguageService;
    }

    createAllElementsArray(elements) {
        const elementsArray = [];
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].Groups.length > 0) {
                elementsArray.push(...this.createAllElementsArray(elements[i].Groups));
            } else if (elements[i].Standards.length > 0) {
                elementsArray.push(...elements[i].Standards);
            }
        }

        return elementsArray;
    }

    getOrderedMatchedStandards(allElements, matchedStandards) {
        const orderedMatchedStandards = [];
        for (let i = 0; i < allElements.length; i++) {
            for (let y = 0; y < matchedStandards.length; y++) {
                if (matchedStandards[y].standard === allElements[i]) {
                    orderedMatchedStandards.push(matchedStandards[y]);
                    break;
                }
            }
        }

        return orderedMatchedStandards;
    }

    getMatchedStandards(selectedStandards, standards, checkedValue) {
        this.checkedValue = checkedValue;
        const matchedStandardsArray = [];
        selectedStandards.forEach((selectedStandard) => {
            this._processSelectedStandard(selectedStandard, standards, matchedStandardsArray);
        });

        return matchedStandardsArray;
    }

    updateMatchedStandards(selectedStandard, standards, matchedStandardsArray, checkedValue) {
        this.checkedValue = checkedValue;
        this._processSelectedStandard(selectedStandard, standards, matchedStandardsArray);
        return matchedStandardsArray;
    }

    getSelectedCapabilities(group, assetRefId = null, assetId = null) {
        let selectedStandards = [];
        if (assetRefId !== null) {
            if (group.Groups !== undefined && group.Groups.length > 0) {
                group.Groups.forEach((item) => {
                    selectedStandards.push(...this.getSelectedCapabilities(item, assetRefId, assetId));
                });
            }

            if (group.Standards !== undefined && group.Standards.length > 0) {
                const newStandards = group.Standards.reduce((standards, item) => {
                    if (item.IsMapped) {
                        standards.push({
                            AssetRefId: assetRefId,
                            ElementId: item.ElementId,
                            FormElementId: item.ElementId,
                            PageFormId: group.PageFormId || assetRefId,
                            PageId: group.PageId || assetId,
                            Justification: item.Justification
                        });
                    }

                    return standards;
                }, []);
                selectedStandards.push(...newStandards);
            }
        } else {
            group.forEach((item) => {
                selectedStandards.push(...this.getSelectedCapabilities(item, item.AssetRefId, item.AssetId));
            });
        }

        return selectedStandards;
    }

    _processSelectedStandard(selectedStandard, standards, matchedStandardsArray) {
        const standardsLength = standards.length;
        for (let i = 0; i < standardsLength; i++) {
            if (standards[i].AssetRefId === undefined && standards[i].PageFormId !== selectedStandard.PageFormId) {
                continue;
            } else if (standards[i].AssetRefId !== undefined && standards[i].AssetRefId !== selectedStandard.AssetRefId) {
                continue;
            }
            if (standards[i].MainType === "Form" && standards[i].AssetRefId === selectedStandard.PageFormId) {
                const matchFoundInElement = this._matchFoundInElements(selectedStandard, standards[i].Groups[0].Standards, matchedStandardsArray);
                if (matchFoundInElement) {
                    this._changeSelectedCount(standards[i]);
                }
            } else if (standards[i].Groups.length > 0) {
                const matchFoundInGroup = this._matchFoundInGroups(selectedStandard, standards[i].Groups, matchedStandardsArray);
                if (matchFoundInGroup) {
                    this._changeSelectedCount(standards[i]);
                }
            } else if (standards[i].Standards.length > 0) {
                const matchFoundInElement = this._matchFoundInElements(selectedStandard, standards[i].Standards, matchedStandardsArray);
                if (standards[i].PageId === selectedStandard.PageId && matchFoundInElement) {
                    this._changeSelectedCount(standards[i]);
                }
            }
        }
    }

    _matchFoundInGroups(selectedStandard, groups, matchedStandardsArray) {
        const groupsLength = groups.length;
        let matchFound = false;

        for (let groupIndex = 0; groupIndex < groupsLength; groupIndex++) {
            if (groups[groupIndex].Groups.length > 0) {
                const matchFoundInGroup = this._matchFoundInGroups(selectedStandard, groups[groupIndex].Groups, matchedStandardsArray);
                if (matchFoundInGroup) {
                    matchFound = true;
                    this._changeSelectedCount(groups[groupIndex]);
                }
            }

            if (groups[groupIndex].Standards !== undefined && groups[groupIndex].Standards.length > 0 && groups[groupIndex].PageId === selectedStandard.PageId) {
                const matchFoundInElements = this._matchFoundInElements(selectedStandard, groups[groupIndex].Standards, matchedStandardsArray);
                if (matchFoundInElements) {
                    matchFound = true;
                    this._changeSelectedCount(groups[groupIndex]);
                }
            }
        }

        return matchFound;
    }

    _matchFoundInElements(selectedStandard, elements, matchedStandardsArray) {
        const elementsLength = elements.length;
        let matchFound = false;

        for (let elementIndex = 0; elementIndex < elementsLength; elementIndex++) {
            if (selectedStandard.ElementId === elements[elementIndex].ElementId) {
                const mapToStandardModel = {
                    selectedStandard: selectedStandard,
                    standard: elements[elementIndex]
                };

                if (this.checkedValue) {
                    matchedStandardsArray.push(mapToStandardModel);
                } else {
                    for (let i = 0; i < matchedStandardsArray.length; i++) {
                        if (
                            mapToStandardModel.selectedStandard.ElementId === matchedStandardsArray[i].selectedStandard.ElementId &&
                            mapToStandardModel.selectedStandard.PageId === matchedStandardsArray[i].selectedStandard.PageId
                        ) {
                            matchedStandardsArray.splice(i, 1);
                        }
                    }
                }

                elements[elementIndex].Checked = this.checkedValue;
                matchFound = true;
            }
        }
        return matchFound;
    }

    _changeSelectedCount(panel) {
        const incrementAmount = this.checkedValue === true ? 1 : -1;

        if (panel.StandardsSelected === undefined) {
            panel.StandardsSelected = 1;
        } else {
            panel.StandardsSelected = panel.StandardsSelected + incrementAmount;
        }
    }

    resetCount(panel) {
        for (let group of panel) {
            if (group.StandardsSelected) {
                group.StandardsSelected = 0;

                if (group.Standards.length > 0) {
                    for (let standard of group.Standards) {
                        standard.Checked = false;
                    }
                }
            }

            if (group.Groups !== undefined) {
                this.resetCount(group.Groups);
            }
        }

        return panel;
    }
}

angularAMD.service("mapToStandardsService", MapToStandardsService);
MapToStandardsService.$inject = ["multiLanguageService"];
