import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./sidebar-elements-view.html";
import "../../../userStatusPermissionsService/userStatusPermissions.service";
import "./sidebarElementViewSection/sidebarElementsViewSection.component";
import "../../../multiLanguageService/multiLanguageService";
import "../../../helpExpandable/helpExpandable.component";

class SidebarElementsView extends NgComponent {
    constructor(multiLanguageService, userStatusPermissions) {
        super();

        this.multiLanguageService = multiLanguageService;
        this.userStatusPermissions = userStatusPermissions;
    }

    $onInit() {
        this.userStatusPermissions
            .canSelfAssess(this.assetId)
            .then((response) => {
                this.canShowRating = response;
            })
            .catch(() => {
                this.canShowRating = false;
            });
    }
}

export const sidebarElementsView = {
    bindings: {
        data: "<",
        assetId: "<"
    },
    template: template,
    controller: SidebarElementsView
};

SidebarElementsView.$inject = ["multiLanguageService", "userStatusPermissions"];
angularAMD.component("sidebarElementsView", sidebarElementsView);
