import { angularAMD } from "@pebblepad/amd";

angularAMD.service("EventHelper", eventHelper);
function eventHelper() {
    return {
        EventThrottler: EventThrottler,
        stopEvent: stopEvent
    };
}

function stopEvent(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    return false;
}

/**
 * @param {Function} eventHandler
 * @param {Number} [throttleSpeed]
 * @constructor
 */
function EventThrottler(eventHandler, throttleSpeed) {
    this.inProgress = false;
    this.eventHandler = eventHandler;
    this.throttleSpeed = throttleSpeed || 250;
}

EventThrottler.prototype.handler = function (e) {
    if (this.inProgress === false) {
        this.inProgress = true;
        this.eventHandler(e);
        setTimeout(this.reset.bind(this), this.throttleSpeed);
    }
};

EventThrottler.prototype.reset = function () {
    this.inProgress = false;
};
