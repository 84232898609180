import { noop } from "@pjs/utilities";
import { HttpMethod } from "../../enums/HttpMethod";
import { IAbortableHttpRequest } from "../abortable-http-request/interfaces/IAbortableHttpRequest";
import { IHttpInterceptor } from "../../interfaces/IHttpInterceptor";
import { AbortableHttpRequest } from "../abortable-http-request/AbortableHttpRequest";
import { InterceptorRunner } from "../interceptor-runner/InterceptorRunner";
import { IPartialHttpOptions } from "./interfaces/IPartialHttpOptions";
import { IHttpService } from "./interfaces/IHttpService";
import { IHttpOptions } from "./interfaces/IHttpOptions";

export class HttpService implements IHttpService {
    private readonly _defaultOptions: IHttpOptions;
    private readonly _httpInterceptors: Array<IHttpInterceptor>;

    constructor(overrideDefaultOptions?: IPartialHttpOptions) {
        this._httpInterceptors = [];
        this._defaultOptions = HttpService.getDefaultHttpOptions();

        if (overrideDefaultOptions !== undefined) {
            this._defaultOptions = {
                ...this._defaultOptions,
                ...overrideDefaultOptions,
                headers: this._mergeHeaders(this._defaultOptions.headers, overrideDefaultOptions.headers)
            };
        }
    }

    public static getDefaultHttpOptions(): IHttpOptions {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        return {
            credentials: "same-origin",
            headers: headers,
            method: HttpMethod.Get,
            timeout: 0,
            url: ""
        };
    }

    public delete(url: string, options?: IPartialHttpOptions): IAbortableHttpRequest {
        return this._makeRequest(this._mergeOptions(options, url, HttpMethod.Delete));
    }

    public get(url: string, options?: IPartialHttpOptions): IAbortableHttpRequest {
        return this._makeRequest(this._mergeOptions(options, url, HttpMethod.Get));
    }

    public head(url: string, options?: IPartialHttpOptions): IAbortableHttpRequest {
        return this._makeRequest(this._mergeOptions(options, url, HttpMethod.Head));
    }

    public patch<T>(url: string, data: T, options?: IPartialHttpOptions): IAbortableHttpRequest {
        return this._makeRequestWithJson(this._mergeOptions(options, url, HttpMethod.Patch), data);
    }

    public post<T>(url: string, data: T, options?: IPartialHttpOptions): IAbortableHttpRequest {
        return this._makeRequestWithJson(this._mergeOptions(options, url, HttpMethod.Post), data);
    }

    public put<T>(url: string, data: T, options?: IPartialHttpOptions): IAbortableHttpRequest {
        return this._makeRequestWithJson(this._mergeOptions(options, url, HttpMethod.Put), data);
    }

    public request(method: HttpMethod, url: string, data?: BodyInit, options?: IPartialHttpOptions): IAbortableHttpRequest {
        return this._makeRequest(this._mergeOptions(options, url, method), data);
    }

    public registerInterceptor(httpInterceptor: IHttpInterceptor): void {
        const index = this._httpInterceptors.findIndex((interceptor) => httpInterceptor.id === interceptor.id);
        if (index !== -1) {
            throw new Error(`Interceptor with id:${httpInterceptor.id} is already registered`);
        }

        this._httpInterceptors.push(httpInterceptor);
    }

    public unregisterInterceptor(id: string): void {
        const index = this._httpInterceptors.findIndex((interceptor) => interceptor.id === id);
        if (index !== -1) {
            this._httpInterceptors.splice(index, 1);
        }
    }

    private _makeRequestWithJson(options: IHttpOptions, data?: unknown): IAbortableHttpRequest {
        const requestData = data === null || data === undefined || typeof data === "string" ? data : JSON.stringify(data);
        return this._makeRequest(options, requestData);
    }

    private _makeRequest(options: IHttpOptions, data?: BodyInit | null): IAbortableHttpRequest {
        const controller = new AbortController();
        const signal = controller.signal;
        const interceptorRunner = new InterceptorRunner(this._httpInterceptors);
        let promise: Promise<IHttpOptions> = Promise.resolve(options);

        if (this._httpInterceptors.length > 0) {
            promise = interceptorRunner.runRequests(promise);
        }

        let fetchPromise = promise.then((requestOptions) => {
            if (requestOptions.timeout > 0) {
                const timeout = setTimeout(() => controller.abort(), requestOptions.timeout);
                fetchPromise
                    .finally(() => clearTimeout(timeout))
                    .catch(() => {
                        noop();
                    });
            }

            return fetch(requestOptions.url, {
                body: data,
                credentials: requestOptions.credentials,
                headers: requestOptions.headers,
                method: requestOptions.method,
                signal: signal
            }).then((response: Response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response;
                }

                throw response;
            });
        });

        if (this._httpInterceptors.length > 0) {
            fetchPromise = interceptorRunner.runResponses(fetchPromise);
        }

        return new AbortableHttpRequest(fetchPromise, () => {
            interceptorRunner.abort();
            controller.abort();
        });
    }

    private _mergeOptions(options: IPartialHttpOptions | undefined, url: string, method: HttpMethod): IHttpOptions {
        const httpOptions = options ?? { ...this._defaultOptions };

        return {
            ...this._defaultOptions,
            ...httpOptions,
            headers: this._mergeHeaders(this._defaultOptions.headers, httpOptions.headers),
            method: method,
            url: url
        };
    }

    private _mergeHeaders(originalHeaders: Headers, overrideHeaders?: Headers): Headers {
        const headers = new Headers(originalHeaders);

        if (overrideHeaders !== undefined) {
            overrideHeaders.forEach((val, key) => headers.set(key, val));
        }

        return headers;
    }
}
