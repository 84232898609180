import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../../../../constants/assessment.constants";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../../constants/mapToStandards.constants";
import "../../../../utilities/helpers";
import "../../../../utilities/urlService";
import "../../../../multiLanguageService/multiLanguageService";
import "../../../../feedbackLeftOnChoice/feedbackLeftOn.service";

class FeedbackHelper {
    constructor(multiLanguageService, $q, $routeParams, helpers, urlService, feedbackLeftOnService) {
        this.multiLanguageService = multiLanguageService;
        this.$q = $q;
        this.helpers = helpers;
        this.$routeParams = $routeParams;
        this.urlService = urlService;
        this._approvalStrings = new Map();
        this.feedbackLeftOnService = feedbackLeftOnService;

        this.STATES = {
            NOPERMISSION: "NoPermission", // user can't do anything so hide all
            ADDINGWRITTEN: "AddingWritten", // user is adding normal feedback
            ADDINGGRADE: "AddingGrade", // user is adding a grade
            ADDINGTEMPLATE: "AddingTemplate", // user is adding a feedback template
            ADDINGBLOCKLEVEL: "AddingBlockLevel", // user is adding a feedback comment to a block
            ADDINGAPPROVAL: "AddingApproval", // user is adding an approval to a block
            ADDINGSCORECARD: "AddingScorecard" // user is adding a scorecard to a submission
        };

        this.GRADEFORMATS = {
            PERCENTAGE: "Percentage",
            STRING: "String"
        };
    }

    createAddFeedbackDto(type, assetId, status = "") {
        return {
            FeedbackType: type || ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.COMMENT,
            Comment: "",
            Released: true,
            AssetId: assetId || "",
            Attachments: [],
            FeedbackTemplates: [],
            Posted: new Date().toISOString(),
            Id: "00000000-0000-0000-0000-000000000000",
            Permissions: {},
            Replies: [],
            LeftOn: {},
            Status: status,
            AnchorId: null
        };
    }

    createAddAssessorFeedbackDto(assetId, elementDto) {
        return {
            Modifier: {},
            LeftOn: {},
            Values: [],
            AnchorId: elementDto.Id,
            FeedbackType: ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD,
            SubType: elementDto.ElementType,
            IsNew: true,
            AssetId: assetId
        };
    }

    createAddScorecardDto(assetId, permissions) {
        let scores = [];
        if (permissions.ScorecardTemplateComponents !== null) {
            scores = permissions.ScorecardTemplateComponents.map((component) => {
                return {
                    Title: component.Title,
                    Description: component.Description,
                    Score: null,
                    Weighting: component.Weighting,
                    ScorecardTemplateComponentId: component.Id
                };
            });
        }
        return {
            FeedbackType: ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.SCORECARD,
            AssetId: assetId || "",
            Attachments: [],
            FeedbackTemplates: [],
            Posted: new Date().toISOString(),
            Id: "00000000-0000-0000-0000-000000000000",
            Permissions: {},
            Replies: [],
            LeftOn: {},
            Status: "",
            AnchorId: null,
            Scores: scores,
            Weighted: permissions.IsScorecardWeighted,
            Grade: null,
            TotalScore: null
        };
    }

    getStateTitle(state) {
        switch (state) {
            case this.STATES.ADDINGGRADE:
                return this.multiLanguageService.getString("atlas_sidebar.feedback_types.grade.add");
            case this.STATES.ADDINGWRITTEN:
                return this.multiLanguageService.getString("atlas_sidebar.feedback_types.written.add");
            case this.STATES.ADDINGTEMPLATE:
                return this.multiLanguageService.getString("atlas_sidebar.feedback_types.template.add");
            case this.STATES.ADDINGSCORECARD:
                return this.multiLanguageService.getString("atlas_sidebar.feedback_types.scorecard.add");
            default:
                return "";
        }
    }

    getApprovalValidation(status) {
        const validation = {
            valid: true,
            message: ""
        };

        if (status === "") {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("sidebar.asset_feedback.approval_status_missing");
        }

        return validation;
    }

    getFeedbackValidation(feedbackText, attachments = [], templates = []) {
        const validation = {
            valid: true,
            message: ""
        };

        if (attachments.length === 0 && templates.length === 0 && this.isEmpty(feedbackText)) {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("sidebar.asset_feedback.blank_feedback_edit");
        }

        return validation;
    }

    getAssessorFeedbackValidation(feedback) {
        const isValid = feedback.Values !== undefined && feedback.Values.length > 0 && feedback.Values.every((v) => !this.isEmpty(v));

        return {
            valid: isValid,
            message: isValid ? "" : this.multiLanguageService.getString("sidebar.asset_feedback.blank_assessor_feedback_edit")
        };
    }

    getGradeValidation(feedbackString) {
        const validation = {
            valid: true,
            message: ""
        };

        if (this.isEmpty(feedbackString)) {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("sidebar.asset_feedback.blank_grade_edit");
        } else if (this.usingPercentages && !this.isValidGradePercentage(feedbackString)) {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("sidebar.asset_feedback.percentage_error_message");
        } else if (feedbackString.length >= 100) {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("sidebar.asset_feedback.grade_is_too_long");
        }

        return validation;
    }

    getScorecardValidation(changes) {
        const validation = {
            valid: true,
            message: ""
        };

        const noScores = changes.Scores.every((x) => x.Score === "" || x.Score === null);
        if (noScores && (changes.Grade === null || changes.Grade === "")) {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("sidebar.asset_feedback.blank_scorecard_edit");
        }

        if (changes.Grade !== null && changes.Grade.length >= 100) {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("sidebar.asset_feedback.grade_is_too_long");
        }

        return validation;
    }

    getApprovalCollectionValidation(status, feedbackString) {
        const validation = {
            valid: true,
            message: ""
        };

        if (status === "") {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("atlas_sidebar.tabs.mapping_approval.error_messages.no_option_selected_error");
        } else if (status === MAP_TO_STANDARDS_CONSTANTS.STATUS.REJECTED && this.isEmpty(feedbackString)) {
            validation.valid = false;
            validation.message = this.multiLanguageService.getString("atlas_sidebar.tabs.mapping_approval.error_messages.no_justification_error");
        }

        return validation;
    }

    isEmpty(comment) {
        return !/\S/g.test(this.helpers.htmlToPlain(comment));
    }

    isValidGradePercentage(value) {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
            return false;
        } else {
            return floatValue <= 100.0 && floatValue >= 0.0;
        }
    }

    setDefaults(permissions) {
        return {
            displayIWantTo: permissions,
            displayMenuBar: true,
            displayFeedbackList: true,
            feedback: null,
            validator: () => ({
                valid: true,
                message: ""
            }),
            data: {}
        };
    }

    setApprovalDefaults(assetId, anchorId, status = "", level) {
        const approvalDefaults = {
            displayIWantTo: false,
            displayMenuBar: false,
            displayFeedbackList: true,
            feedback: this.createAddFeedbackDto(level, assetId, status),
            validator: (feedback) => this.getApprovalValidation(feedback.Status),
            data: {}
        };
        if (anchorId !== undefined) {
            approvalDefaults.feedback.AnchorId = anchorId;
        }

        return approvalDefaults;
    }

    setFeedbackDefaults(assetId) {
        return {
            displayIWantTo: false,
            displayMenuBar: false,
            displayFeedbackList: true,
            feedback: this.createAddFeedbackDto(ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.COMMENT, assetId),
            validator: (feedback) => this.getFeedbackValidation(feedback.Comment, feedback.Attachments),
            data: {}
        };
    }

    setGradeDefaults(assetId) {
        return {
            displayIWantTo: false,
            displayMenuBar: false,
            displayFeedbackList: true,
            feedback: this.createAddFeedbackDto(ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.GRADE, assetId),
            validator: (feedback) => this.getGradeValidation(feedback.Comment),
            data: {
                gradeFormat: this.GRADEFORMATS.STRING
            }
        };
    }

    setNumericGradeDefaults(assetId, callback) {
        return {
            displayIWantTo: false,
            displayMenuBar: false,
            displayFeedbackList: true,
            feedback: this.createAddFeedbackDto(ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.GRADE, assetId),
            validator: (feedback) => this.getGradeValidation(feedback.Comment),
            data: {
                gradeFormat: this.GRADEFORMATS.PERCENTAGE,
                gradeSettings: {
                    decimalPlaces: 0,
                    max: 100,
                    min: 0,
                    value: 0,
                    eventId: "",
                    hideClearBtn: true,
                    ariaLabel: this.multiLanguageService.getString("atlas_sidebar.a11y.grade_input_edit"),
                    onValueChange: callback
                }
            }
        };
    }

    setScorecardDefaults(permissions, assetId) {
        return {
            displayIWantTo: false,
            displayMenuBar: false,
            displayFeedbackList: false,
            canBeLeftOnPage: false,
            feedback: this.createAddScorecardDto(assetId, permissions),
            validator: (feedback) => this.getScorecardValidation(feedback),
            data: {
                components: permissions.ScorecardTemplateComponents,
                altGradeEnabled: permissions.IsScorecardAlternativeGradeEnabled,
                isWeighted: permissions.IsScorecardWeighted
            }
        };
    }

    setTemplateDefaults() {
        return {
            displayIWantTo: false,
            displayMenuBar: false,
            displayFeedbackList: false,
            feedback: null,
            validator: () => ({
                valid: true,
                message: ""
            }),
            data: {}
        };
    }

    setPageVerificationDefaults(assetId, pageId) {
        const leaveFeedbackOnAssetId = this.feedbackLeftOnService.getLeftOnAssetInfo(assetId, pageId).leaveFeedbackOnAssetId;
        let newFeedback = this.createAddFeedbackDto(ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.PAGEVERIFICATION, leaveFeedbackOnAssetId);
        return {
            displayIWantTo: false,
            displayMenuBar: false,
            displayFeedbackList: true,
            feedback: newFeedback,
            validator: () => ({
                valid: true,
                message: ""
            }),
            data: {}
        };
    }

    getLeftOnString(pageTitle) {
        return this.multiLanguageService.getString("sidebar.asset_feedback.labels.left_on", { page: pageTitle });
    }

    getLeftOnOriginString(originName, subOriginName) {
        if (originName !== null && subOriginName !== null) {
            return this.multiLanguageService.getString("sidebar.asset_feedback.labels.left_from_multiple", { title1: originName, title2: subOriginName });
        }

        if (subOriginName !== null) {
            return this.multiLanguageService.getString("sidebar.asset_feedback.labels.left_from", { title: subOriginName });
        }

        if (originName !== null) {
            return this.multiLanguageService.getString("sidebar.asset_feedback.labels.left_from", { title: originName });
        }
    }

    getLeftOnRelativeLink(mainAssetId, pageId, forwardingTimeslice) {
        let timeslice = forwardingTimeslice;
        if (forwardingTimeslice === null) {
            timeslice = this.urlService.getTimesliceFromRoute();
        }

        const baseUrl = this.urlService.createHashUrl(mainAssetId, null, true, false, timeslice, this.$routeParams.submissionId);

        let paramsToAdd = [];
        if (this.$routeParams.historyId) {
            paramsToAdd.push({ key: "historyId", value: this.$routeParams.historyId });
        }
        if (pageId !== null) {
            paramsToAdd.push({ key: "pageId", value: pageId });
        }

        return this.urlService.addParameters(baseUrl, paramsToAdd);
    }

    setApprovalLevelStringsFromSettings(settings) {
        this._approvalStrings.clear();
        settings.forEach((setting) => this._approvalStrings.set(setting.ApprovalType, setting.Title));
    }

    getApprovalLevelString(level) {
        const matchingSetting = this._approvalStrings.get(level);
        return matchingSetting !== undefined ? matchingSetting : this.multiLanguageService.getString("sidebar.asset_feedback.type_titles.approval", "");
    }

    getLastFeedbackItemOfType(array, assetId, type) {
        for (let i = array.length - 1; i >= 0; i--) {
            if (array[i].AssetId === assetId && array[i].FeedbackType === type) {
                return array[i];
            }
        }

        return null;
    }
}

FeedbackHelper.$inject = ["multiLanguageService", "$q", "$routeParams", "helpers", "urlService", "feedbackLeftOnService"];
angularAMD.service("feedbackHelper", FeedbackHelper);
export { FeedbackHelper as feedbackHelper };
