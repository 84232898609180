import { angularAMD } from "@pebblepad/amd";
import { DATA_TYPES } from "../constants/dataTypes.constants";
import "./errorHighlighter.service";
import "./regexHelper.service";
import "../utilities/typeCheck.service";

RestrictDecimals.$inject = ["errorHighlighter", "regexHelper"];

var checks = [isDecimalOrInt, isNotTooLong, isBelowMax, isAboveMin];

function allChecksSucceed(config) {
    return checks.every(function (check) {
        return check(config);
    });
}

function isDecimalOrInt(config) {
    return config.regex.test(config.val);
}

function isNotTooLong(config) {
    const maxLength = config.maxLength || DATA_TYPES.MAX_DOUBLE_LENGTH;
    return config.val.length <= maxLength;
}

function isAboveMin(config) {
    return Number(config.val) >= config.min;
}

function isBelowMax(config) {
    return Number(config.val) <= config.max;
}

function RestrictDecimals(errorHighlighter, regexHelper) {
    return {
        restrict: "A",
        scope: {
            restrictDecimals: "@restrictDecimals",
            model: "=ngModel",
            min: "@min",
            max: "@?",
            shouldShake: "<?",
            errorCallback: "&?",
            maxLength: "<?"
        },
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
            var decimalPlaces = attrs.restrictDecimals ? parseInt(attrs.restrictDecimals, 10) : 0;
            var lastAcceptedValue = isNaN(scope.model) ? "" : scope.model;
            if (isNaN(decimalPlaces)) {
                decimalPlaces = 0;
            }

            const minValue = isNaN(scope.min) || scope.min === "" ? -Infinity : Number(scope.min);
            const maxValue = isNaN(scope.max) || scope.max === "" ? Infinity : Number(scope.max);

            var regex = regexHelper.decimalOrInt(decimalPlaces, scope.min);

            ngModel.$parsers.push((val) => {
                if (val && !allChecksSucceed({ val: val, min: minValue, max: maxValue, regex: regex, maxLength: scope.maxLength })) {
                    element.val(lastAcceptedValue);
                    if (scope.shouldShake !== false) {
                        errorHighlighter.highlightInvalidInput(element, "decline-shake");
                    }
                    ngModel.$setViewValue(lastAcceptedValue);
                    if (scope.errorCallback !== undefined) {
                        scope.errorCallback({ lastValue: lastAcceptedValue });
                    }
                    return lastAcceptedValue;
                }

                lastAcceptedValue = val;
                return val;
            });
        }
    };
}

angularAMD.directive("restrictDecimals", RestrictDecimals);

export { RestrictDecimals as restrictDecimals };
