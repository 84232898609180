import { flip, shift, Placement } from "@floating-ui/dom";
import { IFloatingPositioningConfig } from "./interfaces/IFloatingPositioningConfig";
import { floatingElementStyler } from "./utilities/FloatingElementStyler";
import { PositioningFactory } from "./types/PositioningFactory";
import { IPositioningData } from "./interfaces/IPositioningData";

export const createToolbarOverflowMenuPositioningFactory = (toolbarElement: HTMLElement | null): PositioningFactory => {
    return (boundaryElement: HTMLElement, placement: Placement): IFloatingPositioningConfig => {
        const middleware = [flip({ boundary: boundaryElement, crossAxis: false }), shift({ boundary: boundaryElement, rootBoundary: "document" })];

        return {
            positionConfig: {
                middleware: middleware,
                placement: placement,
                strategy: "absolute"
            },
            styler: (floatingElement: HTMLElement, referenceElement: HTMLElement, data: IPositioningData) => {
                floatingElementStyler(floatingElement, referenceElement, data);
                floatingElement.style.right = "auto";
                floatingElement.style.bottom = "auto";
                floatingElement.style.transform = "none";

                if (toolbarElement !== null) {
                    floatingElement.style.maxWidth = `${toolbarElement.clientWidth}px`;
                }
            }
        };
    };
};
