import { IHttpInterceptor } from "../../interfaces/IHttpInterceptor";
import { IHttpOptions } from "../http-service/interfaces/IHttpOptions";
import { IInterceptorRunner } from "./interfaces/IInterceptorRunner";

export class InterceptorRunner implements IInterceptorRunner {
    private readonly _interceptors: Array<IHttpInterceptor>;
    private _aborted: boolean;

    constructor(interceptors: Array<IHttpInterceptor>) {
        this._interceptors = interceptors;
        this._aborted = false;
    }

    public runResponses(promiseResponse: Promise<Response>): Promise<Response> {
        let responsePromise = promiseResponse;
        for (const interceptor of this._interceptors) {
            responsePromise = responsePromise.then((response) => this._response(response, interceptor), (reject: unknown) => this._reject(reject, interceptor));
        }

        return responsePromise;
    }

    public async runRequests(promiseHttpOptions: Promise<IHttpOptions>): Promise<IHttpOptions> {
        let httpOptions = await promiseHttpOptions;
        for (const interceptor of this._interceptors) {
            httpOptions = await this._request(httpOptions, interceptor);
        }
        return httpOptions;
    }

    public abort(): void {
        this._aborted = true;
    }

    private _request(value: IHttpOptions, interceptor: IHttpInterceptor): IHttpOptions | Promise<IHttpOptions> {
        if (this._aborted) {
            this._throwAbortError("Aborted before HTTP request");
        }
        return interceptor.request !== undefined ? interceptor.request(value) : value;
    }

    private _response(value: Response, interceptor: IHttpInterceptor): Response | Promise<Response> | never {
        if (this._aborted) {
            this._throwAbortError("Aborted after HTTP request");
        }
        return interceptor.response !== undefined ? interceptor.response(value) : value;
    }

    private _reject(rejection: unknown, interceptor: IHttpInterceptor): Response | Promise<Response> | never {
        if (this._aborted) {
            this._throwAbortError("Aborted after HTTP request");
        }
        if (interceptor.responseError !== undefined) {
            return interceptor.responseError(rejection);
        }
        throw rejection;
    }

    private _throwAbortError(message: string): never {
        const error = new Error(message);
        error.name = "AbortError";
        throw error;
    }
}
