import { angularAMD } from "@pebblepad/amd";

angularAMD.service("typeCheck", TypeCheck);
TypeCheck.$inject = [];

function TypeCheck() {
    // Public API
    // =============================================================================================================
    this.isObject = isObject;
    this.isString = isString;
    this.isArray = isArray;
    this.isFunction = isFunction;

    // Private methods
    // =============================================================================================================
    function isObject(variable) {
        return variable !== null && typeof variable === "object";
    }

    function isString(variable) {
        return typeof variable === "string" || variable instanceof String;
    }

    function isArray(variable) {
        return variable.constructor === Array;
    }

    function isFunction(variable) {
        return typeof variable === "function";
    }
}
