import { angularAMD } from "@pebblepad/amd";
import "../../modal/services/modal";
import template from "./templates/element-info-button.html";

// -- Directive

angularAMD.directive("elementInfoButton", ElementInfoButton);
ElementInfoButton.$inject = [];
function ElementInfoButton() {
    return {
        template: template,
        restrict: "E",
        controller: "ElementInfoButton",
        controllerAs: "vm",
        bindToController: true,
        scope: {
            small: "=",
            elementTitle: "@",
            extraInfo: "@"
        }
    };
}

// -- Controller

angularAMD.controller("ElementInfoButton", ElementInfoButtonController);
ElementInfoButtonController.$inject = ["$scope", "modal", "multiLanguageService"];
function ElementInfoButtonController($scope, modal, multiLanguageService) {
    this.modal = modal;
    this.$scope = $scope;
    this.multiLanguageService = multiLanguageService;

    $scope.$on(
        "$destroy",
        function () {
            delete this.$scope;
        }.bind(this)
    );
}

ElementInfoButtonController.prototype.openModal = function () {
    var modalScope = this.$scope.$new(true);
    modalScope.title = this.elementTitle;
    modalScope.message = this.extraInfo;
    modalScope.button_label = this.multiLanguageService.getString("asset_store.help.close");
    this.modal.newModal({
        scope: modalScope,
        templateUrl: this.modal.genericTemplates.popup,
        focusStyle: false,
        disableFocusScroll: true
    });

    modalScope.onClose = function () {
        modalScope.$destroy();
    };
};
