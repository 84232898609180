import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./feedback-info-wrapper.html";
import "./feedbackInfoWrapper.service";
import "../../feedback/feedbackComponents/feedbackLeftOn/feedbackLeftOn.component";
import "../../multiLanguageService/multiLanguageService";

class FeedbackInfoWrapper extends NgComponent {
    constructor(feedbackInfoWrapperService, multiLanguageService) {
        super();

        this.feedbackInfoWrapperService = feedbackInfoWrapperService;
        this.multiLanguageService = multiLanguageService;
        this.feedbackType = null;
        this.infoViewModel = null;
    }

    $onInit() {
        this.infoViewModel = this.feedbackInfoWrapperService.getInfoModel(this.feedback);
        this.feedbackType = this.feedback.FeedbackType;
    }

    $onChanges(changes) {
        if (changes.feedback !== undefined && !changes.feedback.isFirstChange()) {
            this.infoViewModel = this.feedbackInfoWrapperService.getInfoModel(this.feedback);
        }
    }
}

export const feedbackInfoWrapper = {
    bindings: {
        feedback: "<",
        currentAssetId: "<"
    },
    template: template,
    controller: FeedbackInfoWrapper
};

FeedbackInfoWrapper.$inject = ["feedbackInfoWrapperService", "multiLanguageService"];
angularAMD.component("feedbackInfoWrapper", feedbackInfoWrapper);
