import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";

angularAMD.directive("loadingSpinner", [
    "$sce",
    "$q",
    "$timeout",
    "$compile",
    "$templateRequest",
    "$templateCache",
    "baseUrlsFactory",
    function ($sce, $q, $timeout, $compile, $templateRequest, $templateCache, baseUrlsFactory) {
        return {
            restrict: "E",
            scope: true,
            template: "",
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.template = "";

                    $scope.onTemplateSet = function () {
                        if ($attrs.template === "withPlusIconAndText") {
                            // set timeout. if app is loading too long, then show message for user "Still working"
                            $timeout(function () {
                                angular.element($element[0].getElementsByClassName("still-working")[0]).addClass("enabled");
                            }, 5500);
                        }

                        if ($attrs.icon) {
                            $scope.spinner_icon = $attrs.icon;

                            // set timeout. if app is loading too long, then show message for user "Still working"
                            $timeout(function () {
                                angular.element($element[0].getElementsByClassName("still-working")[0]).addClass("enabled");
                            }, 5500);

                            // make delay on removing the class when app is ready
                            if ($attrs.ngHide) {
                                $scope.app_container = angular.element(document.getElementById("app-container"));
                                var unbindWatch = $scope.$watch($attrs.ngHide, function (ngHide) {
                                    // tslint:disable-next-line:triple-equals
                                    if (ngHide == true) {
                                        $scope.app_container.removeClass("loading");

                                        $timeout(function () {
                                            // remove class from app container to prevent app transition when ng-view is changing
                                            $scope.app_container.removeClass("init-show");
                                            unbindWatch();
                                        }, 800);
                                    }
                                });
                            }
                        }

                        if ($attrs.size) {
                            $scope.spinner_size = $attrs.size;
                        }
                    };

                    $scope.setTemplate = function (template) {
                        if ($attrs.icon) {
                            template = "loadingSpinner/views/app-loading-spinner.lazy.html";
                        } else if (template) {
                            switch (template) {
                                case "withText":
                                    template = "loadingSpinner/views/with-text-spinner.lazy.html";
                                    break;
                                case "withUploadingText":
                                    template = "loadingSpinner/views/with-uploading-text-spinner.lazy.html";
                                    break;
                                case "withPlusIconAndText":
                                    template = "loadingSpinner/views/with-plus-icon-and-text-spinner.lazy.html";
                                    break;
                                case "withATLASIconAndText":
                                    template = "loadingSpinner/views/with-atlas-icon-and-text-spinner.lazy.html";
                                    break;
                                default:
                                    template = "loadingSpinner/views/with-plus-icon-and-text-spinner.lazy.html";
                                    break;
                            }
                        } else {
                            template = "loadingSpinner/views/loading-spinner.lazy.html";
                        }
                        $scope.template = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + template);
                    };

                    $scope.compileTemplate = function (templateUrl) {
                        var template = $templateCache.get(templateUrl);
                        // console.log("LOADING-SPINNER", templateUrl, template);
                        var promise = template !== undefined ? $q.when(template) : $templateRequest(templateUrl);

                        return promise.then(function (html) {
                            $element.empty();
                            var template = angular.element(html);
                            // tslint:disable-next-line:no-unsafe-jq-lite
                            $element.append(template);
                            $compile(template)($scope);
                        });
                    };

                    $scope.observeTemplate = function (singleUse) {
                        var templateObserve = $attrs.$observe("template", function (value) {
                            $scope.setTemplate(value);
                            $scope.onTemplateSet();
                            $scope.compileTemplate($scope.template);
                            if (singleUse) {
                                templateObserve();
                            }
                        });
                    };

                    $scope.init = function () {
                        if ($attrs.template) {
                            $scope.observeTemplate($attrs.singleUse === "true");
                        } else {
                            $scope.setTemplate($attrs.template);
                            $scope.onTemplateSet();
                            $scope.compileTemplate($scope.template);
                        }
                    };

                    $scope.init();
                }
            ]
        };
    }
]);
