import { angularAMD } from "@pebblepad/amd";

angularAMD.directive("loadingAnnouncer", LoadingAnnouncer);
LoadingAnnouncer.$inject = ["$timeout"];

function LoadingAnnouncer($timeout) {
    return {
        restrict: "E",
        scope: {
            loading: "@",
            loadingMessage: "=",
            completedMessage: "="
        },
        template: '<p aria-live="polite" aria-atomic="true"><span ng-bind="announcement"></span></p>',
        link: function (scope, element, attr) {
            let isLoading = false;
            attr.$observe("loading", (newVal, oldVal) => {
                $timeout(() => {
                    if (newVal === "true") {
                        scope.announcement = scope.loadingMessage;
                        isLoading = true;
                    } else if (isLoading) {
                        isLoading = false;
                        scope.announcement = scope.completedMessage;
                    }
                });
            });
        }
    };
}
