import { angularAMD } from "@pebblepad/amd";

export class RouteChangeModifier {
    constructor($rootScope, $location) {
        this._$rootScope = $rootScope;
        this._$location = $location;
        this._modifiers = [];
    }

    add(modifier) {
        this._register();
        this._modifiers.push(modifier);
    }

    _register() {
        this._successDestroy = this._$rootScope.$on("$routeChangeSuccess", this._success.bind(this));
        this._errorDestroy = this._$rootScope.$on("$routeChangeError", this._error.bind(this));
    }

    _destroy() {
        this._successDestroy();
        this._errorDestroy();
    }

    _success() {
        if (this._modifiers.length > 0) {
            for (const modifier of this._modifiers) {
                const abs = this._$location.absUrl();

                if (modifier.target === abs) {
                    modifier.modifier();
                }
            }
        }

        this._modifiers.length = 0;
        this._destroy();
    }

    _error() {
        this._modifiers.length = 0;
        this._destroy();
    }
}

RouteChangeModifier.$inject = ["$rootScope", "$location"];
angularAMD.service("routeChangeModifier", RouteChangeModifier);
