import { angularAMD } from "@pebblepad/amd";
import "../../utilities/compileHelper.factory";
import "../directives/statusToolbar/statusToolbar.directive";
import "../factories/statusToolbar.factory";

angularAMD.provider("StatusToolbar", StatusToolbarProvider);

function StatusToolbarProvider() {
    this.container = null;

    this.setContainer = function (element) {
        this.container = element;
    };

    this.$get = [
        "$rootScope",
        "$timeout",
        "$document",
        "CompileHelperFactory",
        "StatusToolbarFactory",
        function ($rootScope, $timeout, $document, compileHelperFactory, statusToolbarFactory) {
            var compiler = new compileHelperFactory($rootScope, angular.element(this.container), true);
            return new StatusToolbar($timeout, $document, compiler, statusToolbarFactory);
        }.bind(this)
    ];
}

function StatusToolbar($timeout, $document, compiler, statusToolbarFactory) {
    this.$document = $document;
    this.$timeout = $timeout;

    this.persistentHandler = statusToolbarFactory.createHandler($timeout, Infinity);
    this.statusHandler = statusToolbarFactory.createHandler($timeout);
    this.actionHandler = statusToolbarFactory.createHandler($timeout, Infinity);
    var args = { persistentHandler: this.persistentHandler, statusHandler: this.statusHandler, actionHandler: this.actionHandler };

    this.toolbarContainer = compiler.compile(
        `<section class="status-toolbar-container">
            <status-toolbar handler="persistentHandler"></status-toolbar>
            <status-toolbar handler="statusHandler"></status-toolbar>
            <status-toolbar handler="actionHandler"></status-toolbar>
        </section>`,
        args,
        true,
        true,
        false
    );
}

StatusToolbar.prototype.addStatus = function (status) {
    var handler = status.isPersistent() ? this.persistentHandler : this.statusHandler;
    handler.addStatus(status);
};

StatusToolbar.prototype.addActionStatus = function (status) {
    this.actionHandler.addStatus(status);
};

StatusToolbar.prototype.removeStatus = function (status) {
    if (!status) {
        return;
    }

    var handler = status.isPersistent() ? this.persistentHandler : this.statusHandler;
    handler.removeStatus(status);
};

StatusToolbar.prototype.removeActionStatus = function (status) {
    if (!status) {
        return;
    }

    this.actionHandler.removeStatus(status);
};

StatusToolbar.prototype.removeAllStatuses = function () {
    this.persistentHandler.removeAllStatus();
    this.statusHandler.removeAllStatus();
    this.actionHandler.removeAllStatus();
};

StatusToolbar.prototype.hide = function () {
    this.toolbarContainer[0].style.display = "none";
};

StatusToolbar.prototype.show = function () {
    this.toolbarContainer[0].style.display = "block";
};

StatusToolbar.prototype.reposition = function () {
    var navigation = this.$document[0].querySelector("navigation");
    var assetTopBar = this.$document[0].querySelector('[data-hook="asset-top-bar"]');

    if (navigation) {
        this.toolbarContainer[0].style.top = navigation.getBoundingClientRect().bottom + "px";
    } else if (assetTopBar) {
        this.toolbarContainer[0].style.top = assetTopBar.getBoundingClientRect().bottom + "px";
    } else if (this.toolbarContainer[0].style.top !== "") {
        this.resetPosition();
    }
};

StatusToolbar.prototype.resetPosition = function () {
    this.toolbarContainer[0].removeAttribute("style");
};

StatusToolbar.prototype.focusElement = function (dataHook) {
    const element = this.$document[0].querySelector(`[data-hook="${dataHook}"]`);
    if (element !== null) {
        element.focus();
    }
};
