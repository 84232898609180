import { angularAMD } from "@pebblepad/amd";

export class ScrollToElement {
    constructor($location, $routeParams) {
        this._$location = $location;
        this._$routeParams = $routeParams;

        this._callbacks = new Set();
        this._cachedElement = "";
        this._cachedAssetId = "";
        this._cachedPageId = "";
    }

    subscribe(callback) {
        if (!this._callbacks.has(callback)) {
            this._callbacks.add(callback);
        }

        if (this._cachedElement !== "") {
            if (this._cachedAssetId !== "") {
                this.goToElementWithAssetId(this._cachedElement, this._cachedAssetId);
            } else if (this._cachedPageId !== "") {
                this.goToElementWithPageId(this._cachedElement, this._cachedPageId);
            } else {
                this.goToElement(this._cachedElement);
            }
        }
    }

    unsubscribe(callback) {
        this._callbacks.delete(callback);
    }

    goToElement(elementId) {
        this._triggerCallbacks(elementId);
    }

    goToElementWithPageId(elementId, pageId) {
        if (pageId === this._$location.search().pageId) {
            this._triggerCallbacks(elementId);
        } else {
            this._cachedElement = elementId;
            this._cachedPageId = pageId;
        }
    }

    goToElementWithAssetId(elementId, assetId) {
        if (assetId === this._$location.search().assetId || this._$routeParams.assetId === assetId) {
            this._triggerCallbacks(elementId);
        } else {
            this._cachedElement = elementId;
            this._cachedAssetId = assetId;
        }
    }

    _triggerCallbacks(elementId) {
        for (const callback of this._callbacks) {
            callback(elementId);
        }

        this._cachedElement = "";
        this._cachedPageId = "";
        this._cachedAssetId = "";
    }
}

ScrollToElement.$inject = ["$location", "$routeParams"];
angularAMD.service("scrollToElementService", ScrollToElement);
