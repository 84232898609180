import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";

angularAMD.factory("copyService", [
    "$http",
    "baseUrlsFactory",
    "$q",
    function ($http, baseUrlsFactory, $q) {
        function promisedPost(url, data) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.post(url, data, { timeout: httpTimeout.promise });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        return {
            takeACopy: function (copyRequest) {
                return promisedPost(baseUrlsFactory.api_base_url + "Asset/TakeACopy", copyRequest);
            }
        };
    }
]);
