import { angularAMD } from "@pebblepad/amd";
import "../utilities/focusStyleProvider";

angularAMD.directive("autoFocus", AutoFocus);
AutoFocus.$inject = ["$timeout", "focusStyleProvider"];

function AutoFocus($timeout, focusStyleProvider) {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            attrs.$observe("autoFocus", function (newValue) {
                if (newValue === "true") {
                    //Default to 300 seconds to facilitate animations
                    var delay = !isNaN(attrs.autoFocusDelay) ? Number(attrs.autoFocusDelay) : 300;
                    $timeout(
                        function () {
                            focusStyleProvider.addFocusStyleClass();
                            element[0].focus();
                        },
                        delay,
                        false
                    );
                }
            });
        }
    };
}
