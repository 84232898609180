import { angularAMD } from "@pebblepad/amd";
import "./userAPI.service";

angularAMD.service("User", User);
User.$inject = ["UserAPI", "$window"];

function User(UserAPI, $window) {
    //Private State - Not great to store state on a singleton, however this is an exception...better than $rootScope.
    //==============================================================================================================
    var activeUser = {
        dto: null
    };

    //Public API
    //==============================================================================================================
    this.getDto = getDto;
    this.setDto = setDto;
    this.getId = getId;
    this.getFullName = getFullName;
    this.setupDto = setupDto;
    this.isLoggedIn = isLoggedIn;
    this.isExternal = isExternal;
    this.reset = reset;
    this.isVersioningEnabled = isVersioningEnabled;
    this.canUseMml = canUseMml;
    this.hasConsentedToEmbeddedContent = hasConsentedToEmbeddedContent;
    this.canUseInlineFilePreview = canUseInlineFilePreview;

    //Private Methods
    //==============================================================================================================
    /**
     * @returns {Object|null}
     */
    function getDto() {
        return activeUser.dto;
    }

    /**
     * @param {Object|null} userDto
     * @returns {Object|null}
     */
    function setDto(userDto) {
        activeUser.dto = userDto;
        return activeUser.dto;
    }

    function reset() {
        activeUser.dto = null;
    }

    /**
     * @returns {string}
     */
    function getId() {
        return activeUser.dto !== null ? activeUser.dto.UserId : "";
    }

    /**
     * @returns {string}
     */
    function getFullName() {
        return activeUser.dto !== null ? activeUser.dto.Firstname + " " + activeUser.dto.Surname : "";
    }

    /**
     * @returns {Promise} - Resolve with UserDto or null
     */
    function setupDto() {
        return UserAPI.getLoggedInUserData().then(function (userDto) {
            activeUser.dto = userDto;
            return getDto();
        });
    }

    /**
     * @returns {Promise}
     */
    function isLoggedIn() {
        return setupDto().then(function (userDto) {
            return !!userDto;
        });
    }

    /**
     * @returns {Boolean}
     */
    function isExternal() {
        return activeUser.dto !== null && activeUser.dto.IsExternal;
    }

    /**
     * @returns {Boolean}
     */
    function isVersioningEnabled() {
        return activeUser.dto !== null && activeUser.dto.VersioningEnabled;
    }

    /**
     * @returns {boolean}
     */
    function canUseMml() {
        return activeUser.dto.EnableMapMyLearning;
    }

    function hasConsentedToEmbeddedContent() {
        return activeUser.dto !== null ? activeUser.dto.EmbedBlockConsent : false;
    }

    /**
     * @returns {boolean}
     */
    function canUseInlineFilePreview() {
        return activeUser.dto !== null ? activeUser.dto.EnableInlineFilePreview : false;
    }
}
