import { angularAMD } from "@pebblepad/amd";
import { TABLE_DATA_TYPES } from "../../../constants/tableDataTypes.constants";
import "./tableConfig.factory";
import "./tableDataHelper.service";
import "../../dataManager/helperService";
import "../../../utilities/helpers";
import "../../../utilities/typeCheck.service";

angularAMD.service("tableDtoHelper", TableDtoHelper);
TableDtoHelper.$inject = ["tableConfig", "tableDataHelper", "helperService", "helpers", "typeCheck"];

function TableDtoHelper(tableConfig, tableDataHelper, helperService, helpers, typeCheck) {
    // Public API
    // =============================================================================================================
    this.generateNewDto = generateNewDto;
    this.isRubric = isRubric;
    this.duplicateTable = duplicateTable;
    this.isNotEmpty = isNotEmpty;

    // Private properties
    // =============================================================================================================
    var presets = {
        labels: {
            columnHeaders: [],
            rowHeaders: []
        }
    };

    // Private methods
    // =============================================================================================================
    var propertyMethods = {
        IsHeader: function (tableType, params) {
            switch (tableType) {
                case tableConfig.type.builder.table.columnsOnly:
                    return params.r === 0;
                case tableConfig.type.builder.table.rowsOnly:
                    return params.c === 0;
                case tableConfig.type.builder.table.singleCell:
                    return;
                case tableConfig.type.builder.table.rowsAndColumns:
                case tableConfig.type.builder.rubric.rowsAndColumns:
                    return params.r === 0 || params.c === 0;
            }
        },
        Label: function (tableType, params) {
            return "";
        },
        Points: function (tableType, params) {
            return 0;
        },
        ShowPoints: function (tableType, params) {
            switch (tableType) {
                case tableConfig.type.builder.table.columnsOnly:
                case tableConfig.type.builder.table.rowsOnly:
                case tableConfig.type.builder.table.rowsAndColumns:
                case tableConfig.type.builder.table.singleCell:
                    return false;
                case tableConfig.type.builder.rubric.rowsAndColumns:
                    return params.r === 0 && params.c !== 0;
            }
        },
        IsSelectable: function (tableType, params) {
            return false;
        },
        IsSelected: function (tableType, params) {
            return false;
        },
        Type: function (tableType, params) {
            switch (tableType) {
                case tableConfig.type.builder.table.columnsOnly:
                    return TABLE_DATA_TYPES.CELLS.STRING;
                case tableConfig.type.builder.table.rowsOnly:
                    return TABLE_DATA_TYPES.CELLS.STRING;
                case tableConfig.type.builder.table.rowsAndColumns:
                    return params.r === 0 && params.c === 0 ? TABLE_DATA_TYPES.CELLS.DUMMY : TABLE_DATA_TYPES.CELLS.STRING;
                case tableConfig.type.builder.table.singleCell:
                    return;
                case tableConfig.type.builder.rubric.rowsAndColumns:
                    if ((params.r === 0 && params.c !== 0) || (params.r !== 0 && params.c === 0)) {
                        return TABLE_DATA_TYPES.CELLS.HEADER;
                    }
                    return params.r === 0 && params.c === 0 ? TABLE_DATA_TYPES.CELLS.DUMMY : TABLE_DATA_TYPES.CELLS.STRING;
            }
        },
        default: function () {
            throw new Error("Unknown property requested.");
        }
    };

    function cellPropertyRules(tableType, property, params) {
        return (propertyMethods[property] || propertyMethods["default"])(tableType, params);
    }

    function generateNewDto(opts) {
        if (!opts) {
            throw new Error("None of options provided. Can't generate new DTO.");
        }
        var calculateColWidth = opts.calculateColWidth || false,
            guid = opts.guid || false,
            tableSize = opts.tableSize || tableConfig.configs.defaultTableSize,
            tableType = opts.tableType || false,
            tableRowsCount = tableType === tableConfig.type.builder.table.rowsOnly ? tableSize - 1 : tableSize,
            newDto = {},
            Rows = [],
            Cols = [],
            Cells = [],
            cell,
            colWidth = calculateColWidth ? calculateColWidth(tableSize) : false,
            i = 0,
            r = 0,
            c,
            cl = 0,
            rowId,
            colIds = [];

        if (tableType === tableConfig.type.builder.table.singleCell) {
            while (cl < tableSize) {
                cell = {
                    Id: guid(),
                    IsHeader: true,
                    Label: "",
                    Points: 0,
                    ShowPoints: false,
                    IsSelectable: false,
                    IsSelected: false,
                    Type: TABLE_DATA_TYPES.CELLS.STRING
                };
                Cells.push(cell);

                cl++;
            }

            return Cells;
        }

        while (i < tableSize) {
            var currentId = guid();

            colIds.push(currentId);
            Cols.push({
                Id: currentId,
                Width: colWidth,
                ColumnType: TABLE_DATA_TYPES.CELLS.STRING
            });

            i++;
        }

        while (r < tableRowsCount) {
            rowId = guid();
            var row = { RowId: rowId, Cells: [], RowType: TABLE_DATA_TYPES.CELLS.STRING };

            c = 0;
            while (c < tableSize) {
                cell = {
                    Id: colIds[c] + "_" + rowId,
                    IsHeader: cellPropertyRules(tableType, "IsHeader", { r: r, c: c }),
                    Label: cellPropertyRules(tableType, "Label", { r: r, c: c }),
                    Points: cellPropertyRules(tableType, "Points", { r: r, c: c }),
                    ShowPoints: cellPropertyRules(tableType, "ShowPoints", { r: r, c: c }),
                    IsSelectable: cellPropertyRules(tableType, "IsSelectable", { r: r, c: c }),
                    IsSelected: cellPropertyRules(tableType, "IsSelected", { r: r, c: c }),
                    Type: cellPropertyRules(tableType, "Type", { r: r, c: c })
                };

                row.Cells.push(cell);
                c++;
            }

            Rows.push(row);
            r++;
        }

        if (tableType === tableConfig.type.builder.table.rowsOnly || tableType === tableConfig.type.builder.table.rowsAndColumns) {
            Cols[0].ColumnType = TABLE_DATA_TYPES.CELLS.HEADER;
        }

        if (
            tableType === tableConfig.type.builder.table.columnsOnly ||
            tableType === tableConfig.type.builder.table.rowsAndColumns ||
            tableType === tableConfig.type.builder.table.columnsOnlyRepeating
        ) {
            Rows[0].RowType = TABLE_DATA_TYPES.CELLS.HEADER;
        }

        newDto.Rows = Rows;
        newDto.Cols = Cols;

        return newDto;
    }

    function isRubric(tableType) {
        var rubrics = tableConfig.type.builder.rubric;

        for (var rubric in rubrics) {
            if (rubrics.hasOwnProperty(rubric)) {
                return rubrics[rubric] === tableType;
            }
        }
    }

    function duplicateTable(dto) {
        dto.Id = helperService.guid(); // update dto Id

        if (dto.TableType === tableConfig.type.builder.table.singleCell) {
            updateIds(dto, { dataKey: "Cells" }); // update cells ids
        } else {
            updateIds(dto, { dataKey: "Columns" }); // update columns ids
            updateIds(dto, { dataKey: "Rows", idKey: "RowId" }); // update rows id and cells id
        }
    }

    function updateIds(dto, opts) {
        opts = opts || {};
        const dataKey = opts.dataKey || false;
        const idKey = opts.idKey || "Id";
        const loopData = dto[dataKey];

        for (let i = 0; i < loopData.length; i++) {
            let iterationItem = loopData[i];
            let newId = helperService.guid();
            iterationItem[idKey] = newId;

            if (dataKey === "Rows") {
                let columnsData = dto.Columns;
                let subItems = iterationItem.Cells;

                for (let n = 0; n < subItems.length; n++) {
                    subItems[n].Id = tableDataHelper.formatCellId(columnsData[n].Id, newId);
                }
            }
        }
    }

    function isNotEmpty(dto) {
        switch (dto.TableType) {
            case tableConfig.type.fillerViewer.table.columnsOnly:
            case tableConfig.type.fillerViewer.table.columnsOnlyRepeating:
            case tableConfig.type.fillerViewer.table.rowsOnly:
            case tableConfig.type.fillerViewer.table.rowsAndColumns:
            case tableConfig.type.fillerViewer.table.singleCell:
            case tableConfig.type.fillerViewer.table.repeatingContainer:
            case tableConfig.type.fillerViewer.rubric.rowsAndColumns:
                return checkIfNotEmpty(dto);
            default:
                return true;
        }
    }

    function cellIsNotEmpty(cell) {
        var isSingleCell = cell.UserAnswer !== void 0;
        var regularCell = !isSingleCell && !cell.IsSelectable && !cell.IsHeader && helpers.htmlToPlain(cell.Label).trim().length > 0;
        var rubricCell = !isSingleCell && cell.IsSelectable && cell.IsSelected;
        var singleCell = cell.UserAnswer !== void 0 && helpers.htmlToPlain(cell.UserAnswer).trim().length > 0;
        var dateCell = !cell.IsHeader && TABLE_DATA_TYPES.CELLS.DATE && cell.DateAnswer;
        var numericCell = !cell.IsHeader && TABLE_DATA_TYPES.CELLS.NUMERIC && cell.NumericAnswer !== null && !isNaN(cell.NumericAnswer);
        var timeCell = !cell.IsHeader && TABLE_DATA_TYPES.CELLS.TIME && ((cell.Hours !== null && !isNaN(cell.Hours)) || (cell.Minutes !== null && !isNaN(cell.Minutes)));

        return regularCell || rubricCell || singleCell || dateCell || numericCell || timeCell;
    }

    function checkIfNotEmpty(dto) {
        var rowsNotEmpty = false,
            cellsNotEmpty = false;

        loopThroughData(dto, "Rows", function (loopItem) {
            loopThroughData(loopItem.Cells, null, function (iterationItem) {
                rowsNotEmpty = cellIsNotEmpty(iterationItem);
                return rowsNotEmpty;
            });

            return rowsNotEmpty;
        });

        loopThroughData(dto, "Cells", function (iterationItem) {
            cellsNotEmpty = cellIsNotEmpty(iterationItem);
            return cellsNotEmpty;
        });

        return rowsNotEmpty || cellsNotEmpty;
    }

    function loopThroughData(data, dataKey, callback) {
        const loopData = dataKey ? data[dataKey] : data;

        if (loopData && loopData.length > 0) {
            for (let i = 0; i < loopData.length; i++) {
                const iterationItem = loopData[i];
                if (typeCheck.isFunction(callback)) {
                    const output = callback(iterationItem);

                    if (output) {
                        break;
                    }
                }
            }
        }
    }
}
