import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { IAriaModel } from "../menu-event-adapter/interfaces/IAriaModel";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";

export function selectLastItem<T>(_: KeyboardEvent, _currentModel: IAriaModel, items: Array<T>): Partial<Immutable<IAriaModelWithType>> {
    return {
        activeItemIndex: items.length - 1,
        type: AriaMenuStatus.Open
    };
}
