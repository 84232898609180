import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { tickIcon } from "@pjs/core-ui";
import "../../react2angular/assetIcon";
import "../../react2angular/icon";
import template from "./asset-item.html";

class AssetItem extends NgComponent {
    constructor() {
        super();
        this.itemType = this.item.SubType === null ? this.item.MainType.toLowerCase() : this.item.SubType.toLowerCase();
        this.image = this.getImage(this.item);
        this.tickIcon = tickIcon;
    }

    _triggerOnSelectItem() {
        this.onSelectItem(this.item);
    }
}

export const assetItemDefinition = {
    bindings: {
        mainSelection: "<",
        viewType: "<",
        onSelectItem: "<",
        item: "<",
        getImage: "<"
    },
    template: template,
    controller: AssetItem
};

assetItemDefinition.$inject = [];
angularAMD.component("assetItem", assetItemDefinition);
