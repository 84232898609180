import { FunctionComponent, KeyboardEvent, useId, useRef } from "react";
import { Select } from "../select/Select.component";
import { TextOption } from "../select/options/text-option/TextOption.component";
import { Input } from "../input/Input.component";
import { IconButton } from "../icon-button/IconButton.component";
import { IconButtonClass } from "../icon-button/enums/IconButtonClass";
import { searchIcon } from "../icon/icons/Search.icon";
import { crossIcon } from "../icon/icons/Cross.icon";
import { Keys } from "../../enums/Keys";
import { IScopedSearchProps } from "./interfaces/IScopedSearchProps";
import "./styles/scoped-search.css";

export const ScopedSearch: FunctionComponent<IScopedSearchProps> = (props) => {
    const id = useId();
    const inputRef = useRef<HTMLInputElement>(null);

    const onKeyDown = (event: KeyboardEvent): void => {
        if (event.key === Keys.Enter) {
            props.onSearch(props.searchString, props.filterOptions[props.filterModel.activeItemIndex]);
            event.preventDefault();
        }
    };

    const onClearSearch = (): void => {
        props.onChange("");
        props.onSearch("", props.filterOptions[props.filterModel.activeItemIndex]);
        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    };

    return (
        <div className={`${props.className !== undefined ? props.className : ""} cui-scoped-search`} data-hook="scoped-search">
            <span className="cui-scoped-search__list-label" id={`cui-scoped-search-list-label-${id}`}>
                {props.scopedSearchLabel}
            </span>
            <span className="cui-scoped-search__label" id={"cui-scoped-search-label-" + id}>
                {props.filterOptions[props.filterModel.activeItemIndex].fullLabel} {props.scopedSearchLabel}
            </span>
            <Select
                autoFocus={props.autoFocus}
                model={props.filterModel}
                onModelChange={props.onFilterChange}
                dataSet={props.filterOptions}
                menuTriggerLabelId={`cui-scoped-search-label-${id}`}
                labelId={`cui-scoped-search-list-label-${id}`}
                renderOption={TextOption}
                dataHook={props.dataHooks?.select ?? "cui-scoped-search-select"}
            />
            <div className="cui-scoped-search__search">
                <div className="cui-scoped-search__text-field-container">
                    <Input
                        ref={inputRef}
                        type="search"
                        value={props.searchString}
                        onKeyDown={onKeyDown}
                        onChange={(event) => props.onChange(event.target.value)}
                        placeholder={props.placeholder}
                        aria-label={props.searchInputLabel}
                        data-hook={props.dataHooks?.input ?? "cui-scoped-search-input"}
                    />
                    {props.searchString.length > 0 && (
                        <IconButton
                            className={IconButtonClass.Outlined}
                            source={crossIcon}
                            onClick={onClearSearch}
                            ariaLabel={props.clearSearchLabel}
                            dataHook={props.dataHooks?.clearButton ?? "cui-scoped-clear-button"}
                        />
                    )}
                </div>
                <IconButton
                    className={IconButtonClass.Outlined}
                    source={searchIcon}
                    onClick={() => props.onSearch(props.searchString, props.filterOptions[props.filterModel.activeItemIndex])}
                    ariaLabel={props.searchButtonLabel}
                    dataHook={props.dataHooks?.searchButton ?? "cui-scoped-search-button"}
                />
            </div>
        </div>
    );
};
