import { angularAMD } from "@pebblepad/amd";
import { FeedbackFilterer } from "../feedbackFilterer/feedbackFilterer";

export class FeedbackFiltererCollection {
    constructor() {
        this._filterers = new Map();
    }

    get(id) {
        let filtererWrapper = this._filterers.get(id);
        if (filtererWrapper === undefined) {
            filtererWrapper = {
                Filterer: new FeedbackFilterer(),
                UsageCount: 0
            };
            this._filterers.set(id, filtererWrapper);
        }
        filtererWrapper.UsageCount++;
        return filtererWrapper.Filterer;
    }

    remove(id) {
        const filtererWrapper = this._filterers.get(id);
        if (filtererWrapper !== undefined) {
            filtererWrapper.UsageCount--;
            if (filtererWrapper.UsageCount <= 0) {
                this._filterers.delete(id);
            }
        }
    }
}

FeedbackFiltererCollection.$inject = [];
angularAMD.service("feedbackFiltererCollection", FeedbackFiltererCollection);
