import { authenticated401Reasons } from "../constants/authenticated401Reasons";
import { CsrfCookie } from "@pjs/http";
import "../utilities/baseUrlsFactory";
import "../utilities/helpers";
import "../versioning/services/versionHelper.service";

var pebbleHttpModule = angular.module("pebbleHttp", ["ng"]);
pebbleHttpModule.constant("ACTIONS_TO_FIDDLE", ["GetRevisionInformation", "SaveOrUpdate", "GetEditStatusForAssets", "RecoverAssetsByServerBackup", "Recover"]);
pebbleHttpModule.provider("PebbleHttpCacheBuster", PebbleHttpCacheBuster);

pebbleHttpModule.service("PebbleHttpInterceptor", PebbleHttpInterceptor);
PebbleHttpInterceptor.$inject = ["$q", "$location", "$injector", "$window", "$document", "baseUrlsFactory", "helpers", "PebbleHttpCacheBuster", "ACTIONS_TO_FIDDLE"];

//Provider
//=============================================================================================================
function PebbleHttpCacheBuster() {
    this.value = Date.now() + ""; //Default to prevent caching, override in main app module config
    this.cacheBusterKey = "v";
    this.$get = function () {
        var urlArg = this.createUrlArg();
        return {
            value: this.value,
            urlArg: urlArg
        };
    };
}

PebbleHttpCacheBuster.prototype.createUrlArg = function () {
    return this.cacheBusterKey + "=" + this.value;
};

//Service
//=============================================================================================================
function PebbleHttpInterceptor($q, $location, $injector, $window, $document, baseUrlsFactory, helpers, pebbleHttpCacheBuster, ACTIONS_TO_FIDDLE) {
    function HttpInterceptor() {
        this.activeErrorModal = null;
        this.request = this.requestHandler.bind(this);
        this.response = this.responseHandler.bind(this);
        this.responseError = this.responseErrorHandler.bind(this);
        this.safeUrlLength = 1800;
    }

    HttpInterceptor.prototype.urlLengthIsSafe = function (requestConfig) {
        return requestConfig.url.length <= this.safeUrlLength;
    };

    // For adding debug data to urls
    HttpInterceptor.prototype.addAssetIdToRequest = function (requestConfig) {
        const fiddleUrl = ACTIONS_TO_FIDDLE.find((urlPartial) => requestConfig.url.indexOf(urlPartial) > 0);
        if (fiddleUrl === undefined || !Boolean(requestConfig.data)) {
            return;
        }

        switch (fiddleUrl) {
            case "SaveOrUpdate": {
                requestConfig.url += `&assetIdForLogging=${requestConfig.data.model.Id}&maintypeForLogging=${requestConfig.data.model.MainType}`;
                break;
            }
            case "RecoverAssetsByServerBackup": {
                for (let i = 0; i < requestConfig.data.length; i++) {
                    if (!this.urlLengthIsSafe(requestConfig)) {
                        return;
                    }
                    requestConfig.url += `&pageDtoIdForLogging${i}=${requestConfig.data[i].Id}`;
                }
                break;
            }
            case "Recover": {
                requestConfig.url += "&assetIdForLogging=" + requestConfig.data.MainAssetId;
                for (let i = 0; i < requestConfig.data.Dtos.length; i++) {
                    if (!this.urlLengthIsSafe(requestConfig)) {
                        return;
                    }
                    requestConfig.url += `&pageDtoIdForLogging${i}=${requestConfig.data.Dtos[i].Id}`;
                }
                break;
            }
            default: {
                for (let i = 0; i < requestConfig.data.length; i++) {
                    if (!this.urlLengthIsSafe(requestConfig)) {
                        return;
                    }
                    requestConfig.url += `&assetIdForLogging${i}=${requestConfig.data[i]}`;
                }
            }
        }
    };

    HttpInterceptor.prototype.requestHandler = function (requestConfig) {
        var isResourceRequest = requestConfig.url.indexOf(".html") !== -1 || requestConfig.url.indexOf(".json") !== -1;
        if (isResourceRequest) {
            requestConfig.withCredentials = false;
        } else {
            // Move CSRF cookie to the head to prove we own it
            var csrfTokenCookie = CsrfCookie.get();
            if (csrfTokenCookie !== "") {
                requestConfig.headers["X-CSRF-Token"] = csrfTokenCookie;
            }
        }

        //If there's something strange going through your HEAD, who ya gonna call? CacheBuster!
        requestConfig.url = helpers.addToQueryString(requestConfig.url, pebbleHttpCacheBuster.urlArg);

        //Timeslice detection!
        var $route = $injector.get("$route");
        var currentRoute = $route.current;
        if (currentRoute && currentRoute.params && currentRoute.params.timeslice) {
            var versionHelper = $injector.get("VersionHelper");
            if (versionHelper.isTimesliceSupportedAPICall(requestConfig.url)) {
                requestConfig.url += "&timeslice=" + currentRoute.params.timeslice;
            }
        }

        this.addAssetIdToRequest(requestConfig);

        return requestConfig;
    };

    HttpInterceptor.prototype.responseHandler = function (response) {
        return response;
    };

    HttpInterceptor.prototype.responseErrorHandler = function (httpRejection) {
        //If 401 unauthorised error then redirect accordingly
        // tslint:disable-next-line:triple-equals
        if (httpRejection.status == 401 && !this.activeErrorModal) {
            return this.unauthorisedHandler(httpRejection);
        } else if (httpRejection.status === 0 && $window.navigator.onLine === false) {
            if (!this.activeErrorModal) {
                var self = this;
                var deferred = $q.defer();
                var userStatusService = $injector.get("UserStatusService");
                this.activeErrorModal = userStatusService.noNetwork(function () {
                    self.activeErrorModal = null;
                    deferred.reject(httpRejection);
                });
            }

            return deferred.promise;
        }
        return $q.reject(httpRejection);
    };

    HttpInterceptor.prototype.unauthorisedHandler = function (httpRejection) {
        var reason = httpRejection.data && httpRejection.data.Message ? httpRejection.data.Message : authenticated401Reasons.NotLoggedIn;
        var userStatusService = $injector.get("UserStatusService");
        var preventRedirect = $injector.get("preventRedirect");
        var user = $injector.get("User");
        var deferred = $q.defer();
        var self = this;

        var onModalClose = function (hasUser) {
            if (hasUser) {
                $location.url("/");
            } else {
                self.redirectToLogin();
            }
            deferred.reject(httpRejection);
            self.activeErrorModal = null;
        };

        switch (reason) {
            case authenticated401Reasons.NotEnabledSystem: // if pebblepad not enabled redirect to plus
                window.location.href = baseUrlsFactory.api_base_url + "SharedMenu/Forwarder?location=UserDefaultPlus&action=JustOpenLocation";
                break;
            case authenticated401Reasons.ExternalUser:
            case authenticated401Reasons.MarkedForDeletion: // if external or deleted user then go to externals page
                if (user.isExternal()) {
                    $location.url("/dashboard/externals");
                } else {
                    window.location.href = baseUrlsFactory.api_base_url + "SharedMenu/Forwarder?location=Portfolio&action=View&mainType=ExternalsPage";
                }
                break;
            case authenticated401Reasons.NotLoggedIn:
                preventRedirect.allow_redirect = true;

                if (preventRedirect.windowNavigationHandler && preventRedirect.windowNavigationHandler.beforeUnloadHandler) {
                    preventRedirect.windowNavigationHandler.unbind();
                }

                this.activeErrorModal = preventRedirect.checkIfCanLogOut() ? userStatusService.loggedOut(onModalClose) : userStatusService.loggedOutWithUnsavedChanges(onModalClose);
                break;
            case authenticated401Reasons.NotPublic:
                this.activeErrorModal = user.getDto() ? userStatusService.notAuthorisedToView(onModalClose.bind(null, true)) : userStatusService.loggedOut(onModalClose);
                break;
            default:
                this.redirectToLogin();
                deferred.reject();
                break;
        }

        return deferred.promise;
    };

    HttpInterceptor.prototype.redirectToLogin = function (customReturnUrl) {
        var redirectUrl = "";

        if (baseUrlsFactory.testing_environment) {
            redirectUrl = "#/login";
            var $rootScope = $injector.get("$rootScope");
            $rootScope.appIsLoaded = true;
            $rootScope.recoveryInProgress = false;
        } else {
            redirectUrl = baseUrlsFactory.api_base_url + "SharedMenu/Forwarder?location=LoginService&action=JustOpenLocation";
            var returnUrl = customReturnUrl !== void 0 ? customReturnUrl : window.location.href;
            redirectUrl += "&redirect=" + encodeURIComponent(returnUrl);
        }

        window.location.href = redirectUrl;
    };

    return new HttpInterceptor();
}
