import { Plugin } from "@pebblepad/ckeditor";
import { richTextI18n } from "../../i18n/RichTextI18n.const";

export class SpecialCharactersGreek extends Plugin {
    public init(): void {
        const plugin = this.editor.plugins.get("SpecialCharacters");

        plugin.addItems(richTextI18n.getString("toolbar_items.special_characters.greek.name"), [
            { character: "Α", title: richTextI18n.getString("toolbar_items.special_characters.greek.alpha") },
            { character: "α", title: richTextI18n.getString("toolbar_items.special_characters.greek.alpha_lower") },
            { character: "Β", title: richTextI18n.getString("toolbar_items.special_characters.greek.beta") },
            { character: "β", title: richTextI18n.getString("toolbar_items.special_characters.greek.beta_lower") },
            { character: "Γ", title: richTextI18n.getString("toolbar_items.special_characters.greek.gamma") },
            { character: "γ", title: richTextI18n.getString("toolbar_items.special_characters.greek.gamma_lower") },
            { character: "Δ", title: richTextI18n.getString("toolbar_items.special_characters.greek.delta") },
            { character: "δ", title: richTextI18n.getString("toolbar_items.special_characters.greek.delta_lower") },
            { character: "Ε", title: richTextI18n.getString("toolbar_items.special_characters.greek.epsilon") },
            { character: "ε", title: richTextI18n.getString("toolbar_items.special_characters.greek.epsilon_lower") },
            { character: "Ζ", title: richTextI18n.getString("toolbar_items.special_characters.greek.zeta") },
            { character: "ζ", title: richTextI18n.getString("toolbar_items.special_characters.greek.zeta_lower") },
            { character: "Η", title: richTextI18n.getString("toolbar_items.special_characters.greek.eta") },
            { character: "η", title: richTextI18n.getString("toolbar_items.special_characters.greek.eta_lower") },
            { character: "Θ", title: richTextI18n.getString("toolbar_items.special_characters.greek.theta") },
            { character: "θ", title: richTextI18n.getString("toolbar_items.special_characters.greek.theta_lower") },
            { character: "Ι", title: richTextI18n.getString("toolbar_items.special_characters.greek.iota") },
            { character: "ι", title: richTextI18n.getString("toolbar_items.special_characters.greek.iota_lower") },
            { character: "Κ", title: richTextI18n.getString("toolbar_items.special_characters.greek.kappa") },
            { character: "κ", title: richTextI18n.getString("toolbar_items.special_characters.greek.kappa_lower") },
            { character: "Λ", title: richTextI18n.getString("toolbar_items.special_characters.greek.lambda") },
            { character: "λ", title: richTextI18n.getString("toolbar_items.special_characters.greek.lambda_lower") },
            { character: "Μ", title: richTextI18n.getString("toolbar_items.special_characters.greek.mu") },
            { character: "μ", title: richTextI18n.getString("toolbar_items.special_characters.greek.mu_lower") },
            { character: "Ν", title: richTextI18n.getString("toolbar_items.special_characters.greek.nu") },
            { character: "ν", title: richTextI18n.getString("toolbar_items.special_characters.greek.nu_lower") },
            { character: "Ξ", title: richTextI18n.getString("toolbar_items.special_characters.greek.xi") },
            { character: "ξ", title: richTextI18n.getString("toolbar_items.special_characters.greek.xi_lower") },
            { character: "Ο", title: richTextI18n.getString("toolbar_items.special_characters.greek.omicron") },
            { character: "ο", title: richTextI18n.getString("toolbar_items.special_characters.greek.omicron_lower") },
            { character: "Π", title: richTextI18n.getString("toolbar_items.special_characters.greek.pi") },
            { character: "π", title: richTextI18n.getString("toolbar_items.special_characters.greek.pi_lower") },
            { character: "Ρ", title: richTextI18n.getString("toolbar_items.special_characters.greek.rho") },
            { character: "ρ", title: richTextI18n.getString("toolbar_items.special_characters.greek.rho_lower") },
            { character: "Σ", title: richTextI18n.getString("toolbar_items.special_characters.greek.sigma") },
            { character: "σ", title: richTextI18n.getString("toolbar_items.special_characters.greek.sigma_lower") },
            { character: "ς", title: richTextI18n.getString("toolbar_items.special_characters.greek.sigma_lower_end") },
            { character: "Τ", title: richTextI18n.getString("toolbar_items.special_characters.greek.tau") },
            { character: "τ", title: richTextI18n.getString("toolbar_items.special_characters.greek.tau_lower") },
            { character: "Υ", title: richTextI18n.getString("toolbar_items.special_characters.greek.upsilon") },
            { character: "υ", title: richTextI18n.getString("toolbar_items.special_characters.greek.upsilon_lower") },
            { character: "Φ", title: richTextI18n.getString("toolbar_items.special_characters.greek.phi") },
            { character: "φ", title: richTextI18n.getString("toolbar_items.special_characters.greek.phi_lower") },
            { character: "Χ", title: richTextI18n.getString("toolbar_items.special_characters.greek.chi") },
            { character: "χ", title: richTextI18n.getString("toolbar_items.special_characters.greek.chi_lower") },
            { character: "Ψ", title: richTextI18n.getString("toolbar_items.special_characters.greek.psi") },
            { character: "ψ", title: richTextI18n.getString("toolbar_items.special_characters.greek.psi_lower") },
            { character: "Ω", title: richTextI18n.getString("toolbar_items.special_characters.greek.omega") },
            { character: "ω", title: richTextI18n.getString("toolbar_items.special_characters.greek.omega_lower") }
        ]);
    }
}
