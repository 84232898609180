import { angularAMD } from "@pebblepad/amd";
import "../../utilities/helpers";
import "../../preventRedirect/preventRedirect";

angularAMD.factory("historyService", [
    "$rootScope",
    "$routeParams",
    "$window",
    "$location",
    "$timeout",
    "$interval",
    "helpers",
    "preventRedirect",
    function ($rootScope, $routeParams, $window, $location, $timeout, $interval, helpers, preventRedirect) {
        return {
            historyKeyPrefix: "history-id:",
            currentHistory: null,
            currentHistoryId: null,
            interval: null,
            $off: null,

            init: function (historyDto) {
                const historyId = this.getCurrentHistoryId();
                let historyObject = null;

                if (historyId !== "") {
                    historyObject = this.loadHistory(historyId);
                    this.setCurrentHistoryId(historyId);

                    if (historyObject !== false && historyObject !== null) {
                        historyDto.anchorInfo = historyObject.history.history.length < 2 ? null : this.getAssetAnchorInfo();
                    } else {
                        historyObject = this.newHistory();
                    }
                } else {
                    historyObject = this.newHistory();
                }

                this.cleanup();

                if (historyObject !== false && historyObject !== null) {
                    this.addToHistory(historyDto);
                }
                return this;
            },

            getLabel: function (type) {
                if (type) {
                    type = type.toLowerCase();
                }

                const dictionary = {
                    blog: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.blog", { count: 1 }),
                    workbookresponse: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.workbook", { count: 1 }),
                    webfoliopage: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.webfoliopage", { count: 1 }),
                    webfolio: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.webfolio", { count: 1 }),
                    post: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.post", { count: 1 }),
                    assetcollectionwrapper: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.collection", { count: 1 }),
                    activitylog: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.activitylog", { count: 1 }),
                    formresponse: $rootScope.multiLanguageService.getString("pebble_terms.template", { count: 1 }),
                    templatebuilder: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.templatebuilder"),
                    workbookbuilder: $rootScope.multiLanguageService.getString("pebble_terms.asset_types.workbookbuilder")
                };

                const label = dictionary[type];
                return (label ? label : type).toLowerCase();
            },

            getBackButton: function () {
                if (!this.currentHistory) {
                    return;
                }
                var historyArray = this.currentHistory.history;

                if (historyArray.length < 2 || !this.getCurrentHistoryId()) {
                    return false;
                } else {
                    const previousHistoryItem = historyArray[historyArray.length - 2];
                    return {
                        label: $rootScope.multiLanguageService.getString("labels.history_back_to_type", {
                            assetType: this.getLabel(previousHistoryItem.type)
                        }),
                        type: previousHistoryItem.type,
                        icon: previousHistoryItem.type,
                        url: previousHistoryItem.url
                    };
                }
            },

            getAssetAnchorInfo: function () {
                if (this.currentHistory !== null) {
                    const historyItem = this.currentHistory.history.find((item) => item.routeParam === $routeParams.assetId);

                    if (historyItem !== undefined) {
                        return historyItem.anchorInfo;
                    }
                }

                return null;
            },

            getDefaultBackAction: function (backButtonDto) {
                $location.url(backButtonDto.url);
            },

            setupHistory: function (dto) {
                // Initialise and return back button data if exists
                var backButtonDto = this.init(dto).getBackButton();

                this.triggerBackButton({
                    dto: backButtonDto
                });

                return backButtonDto;
            },

            triggerBackButton: function (opts) {
                var loops = 0,
                    maximumLoops = 3,
                    self = this;

                $interval.cancel(self.interval);
                if (self.$off) {
                    self.$off();
                }

                self.$off = $rootScope.$on("response-trigger-back-button", function () {
                    $interval.cancel(self.interval);
                    self.$off();
                });

                self.interval = $interval(function () {
                    if (loops >= maximumLoops) {
                        $interval.cancel(self.interval);
                        self.$off();
                    }
                    $rootScope.$broadcast("trigger-back-button", opts);
                    loops++;
                }, 500);

                $rootScope.$broadcast("trigger-back-button", opts);
            },

            historyItemDto: function (opts) {
                this.type = opts.type || "none";
                this.routeParam = opts.routeParam || $routeParams.assetId;
                this.url = opts.url || $location.url();
                this.anchorInfo = opts.anchorInfo || null;
            },

            checkIfDuplicate: function (dto) {
                var index = this.getIndexOfHistoryItem(dto);
                return { isDuplicate: index !== -1, index: index };
            },

            getIndexOfHistoryItem: function (dto) {
                for (var i = 0; i < this.currentHistory.history.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (this.currentHistory.history[i].routeParam == dto.routeParam) {
                        return i;
                    }
                }
                return -1;
            },

            addToHistory: function (opts) {
                if (this.currentHistory === null) {
                    return;
                }

                const newHistoryDto = new this.historyItemDto(opts);
                const isDuplicate = this.checkIfDuplicate(newHistoryDto);

                if (!isDuplicate.isDuplicate) {
                    this.currentHistory.history.push(newHistoryDto);
                } else {
                    this.removeFollowingHistoryFrom(newHistoryDto);
                    this.currentHistory.history[isDuplicate.index] = newHistoryDto;
                }

                this.saveHistory(this.currentHistoryId, this.currentHistory);
            },

            removeFollowingHistoryFrom: function (dto) {
                var dtoIndex = this.getIndexOfHistoryItem(dto);

                if (dtoIndex !== -1 && dtoIndex + 1 < this.currentHistory.history.length) {
                    this.currentHistory.history.splice(dtoIndex + 1, this.currentHistory.history.length);
                }
            },

            newHistory: function () {
                var historyObject = this.createHistory();

                if (historyObject && historyObject.historyId) {
                    this.setCurrentHistoryId(historyObject.historyId);
                    this.updateHistoryUrlParam(historyObject.historyId);
                }

                return historyObject;
            },

            updateHistoryUrlParam: function (historyId) {
                historyId = historyId || null;
                var searchItems = $location.search();
                var replaceObject = { historyId: historyId };

                for (var searchItem in searchItems) {
                    if (searchItems.hasOwnProperty(searchItem) && searchItem !== "historyId") {
                        replaceObject[searchItem] = searchItems[searchItem];
                    }
                }

                preventRedirect.allowRedirect(true);

                $location.search(replaceObject).replace();

                $timeout(function () {
                    preventRedirect.allowRedirect(false);
                }, 300);
            },

            updateHistory: function (routeParam, update) {
                if (this.currentHistory === null) {
                    return;
                }

                const historyArray = this.currentHistory.history;
                const currentHistory = historyArray[historyArray.length - 1];

                if (routeParam !== currentHistory.routeParam) {
                    return;
                }

                const updatedHistory = { ...currentHistory, ...update };

                this.addToHistory(updatedHistory);
            },

            /**
             * need this so, encoded historyIds nearly never overwrite each other
             * @returns {*}
             */
            createHistoryKey: function () {
                return helpers.randomString(10);
            },

            createHistoryObject: function () {
                return {
                    history: []
                };
            },

            createHistory: function () {
                var key = this.createHistoryKey();
                var history = this.createHistoryObject();
                this.setCurrentHistory(history);

                return this.saveHistory(key, history) ? { historyId: key, history: history } : false;
            },

            saveHistory: function (key, itemData) {
                if (itemData) {
                    try {
                        itemData = JSON.stringify(itemData);
                    } catch (e) {
                        itemData = null;
                        console.warn("[Local storage] Can't save because:", e);
                    }
                }

                if (itemData) {
                    try {
                        $window.sessionStorage.setItem(this.historyKeyPrefix + key, itemData);
                        return true;
                    } catch (e) {
                        return console.warn("[Local storage] Cant's save e:", e);
                    }
                } else {
                    return false;
                }
            },

            setCurrentHistory: function (historyObject) {
                this.currentHistory = historyObject;
            },

            setCurrentHistoryId: function (historyId) {
                this.currentHistoryId = historyId;
            },

            getCurrentHistoryId: function (formatted) {
                var historyId = $routeParams.historyId;
                historyId = historyId ? historyId : "";

                if (historyId && formatted) {
                    historyId = "?historyId=" + historyId;
                }

                return historyId;
            },

            loadHistory: function (historyId) {
                try {
                    var history = $window.sessionStorage.getItem(this.historyKeyPrefix + historyId);
                    if (history) {
                        history = JSON.parse(history);
                        this.setCurrentHistory(history);
                        return { historyId: historyId, history: history };
                    } else {
                        return false;
                    }
                } catch (e) {
                    console.warn("[Local storage] Can't get item because:", e);
                    return false;
                }
            },

            cleanup: function () {
                var items = this.getAllHistoryItemsFromSessionStorage(this.historyKeyPrefix);

                for (var i = 0; i < items.length; i++) {
                    // if found item is not current, then remove it
                    if (items[i].key !== this.historyKeyPrefix + this.currentHistoryId) {
                        $window.sessionStorage.removeItem(items[i].key);
                    }
                }
            },

            getLastHistoryItem: function (historyId) {
                var data = this.loadHistory(historyId);
                if (data === false) {
                    return null;
                } else {
                    var history = data.history.history;
                    return history[history.length - 1];
                }
            },

            getLastHistoryPath: function (historyId) {
                var lastHistoryItem = this.getLastHistoryItem(historyId);
                return lastHistoryItem ? lastHistoryItem.url : "";
            },

            getAllHistoryItemsFromSessionStorage: function (key, except) {
                var itemArr = [];
                var objKey,
                    storageObj = $window.sessionStorage;

                // Loop through localStorage and remove all items that match passed in 'key'
                for (objKey in storageObj) {
                    if (storageObj.hasOwnProperty(objKey)) {
                        var regex = new RegExp(key, "g");
                        // tslint:disable-next-line:triple-equals
                        if (regex.test(objKey) && objKey != except) {
                            itemArr.push({ key: objKey, value: JSON.parse(storageObj.getItem(objKey)) });
                        }
                    }
                }

                return itemArr;
            }
        };
    }
]);
