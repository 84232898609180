import { FunctionComponent, useRef } from "react";
import { useClassRef, useOnMount, useOnValueChange } from "@pjs/react-utilities";
import { IParseHtmlProps } from "./interfaces/IParseHtmlProps";
import { ParseHtmlSanitiser } from "./ParseHtmlSanitiser";

export const ParseHtml: FunctionComponent<IParseHtmlProps> = (props) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const parseHtmlSanitiser = useClassRef(ParseHtmlSanitiser, props.debounceTime ?? 0);

    useOnMount(() => {
        parseHtmlSanitiser.sanitise(props.htmlString, elementRef, true);

        return () => {
            parseHtmlSanitiser.destroy();
        };
    });

    useOnValueChange(() => {
        parseHtmlSanitiser.sanitise(props.htmlString, elementRef, false);
    }, [props.htmlString]);

    return <div className={props.className} ref={elementRef} data-hook={props.dataHook} />;
};
