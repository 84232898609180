import { i18nManager, withImport } from "@pjs/i18n";
import defaultLanguageJson from "../../resources/lang/en-GB.json";
import "./CkeI18nSet.effect";

export const richTextI18n = i18nManager.createSet<typeof defaultLanguageJson>("rich-text");

richTextI18n.add(
    "en-GB",
    withImport(() => import("../../resources/lang/en-GB.json"))
);

richTextI18n.add(
    "cy-GB",
    withImport(() => import("../../resources/lang/cy-GB.json"))
);

richTextI18n.add(
    "nl-NL",
    withImport(() => import("../../resources/lang/nl-NL.json"))
);

richTextI18n.add(
    "en-US",
    withImport(() => import("../../resources/lang/en-US.json"))
);
