import "../../../multiLanguageService/multiLanguageService";
import { angularAMD } from "@pebblepad/amd";
import template from "./default.html";

var defaultDefinition = {
    bindings: {
        feedback: "<"
    },
    template: template,
    controller: DefaultFeedbackComponent
};

DefaultFeedbackComponent.$inject = ["multiLanguageService"];
function DefaultFeedbackComponent(multiLanguageService) {
    this._multiLanguageService = multiLanguageService;
    this.defaultFeedbackText = this._multiLanguageService.getString("sidebar.asset_feedback.default_feedback_message", { feedback_type: this.feedback.FeedbackType });
}

angularAMD.component("defaultFeedback", defaultDefinition);
export { defaultDefinition as defaultFeedback };
