import { Component, ReactNode } from "react";
import { approvalNegativeIcon, approvalNeutralIcon, approvalPositiveIcon, IconOption, IIcon, IIconSelectOption, MenuAriaModel, Select } from "@pjs/core-ui";
import { ApprovalStatus } from "../enums/ApprovalStatus";
import { IApprovalSelectProps } from "./interfaces/IApprovalSelectProps.interface";
import { IApprovalItem } from "./interfaces/IApprovalItem.interface";
import { IApprovalSelectState } from "./interfaces/IApprovalSelectState.interface";

export class ApprovalSelect extends Component<IApprovalSelectProps, IApprovalSelectState> {
    public renderOption: (item: IApprovalItem) => JSX.Element;

    private readonly _modelChangedBound: (model: MenuAriaModel) => void = this._handleModelChanged.bind(this);

    private readonly _approvalIcons: { [type in ApprovalStatus]: { icon: IIcon; fill: string } } = {
        [ApprovalStatus.Positive]: {
            fill: "#008540",
            icon: approvalPositiveIcon
        },
        [ApprovalStatus.Neutral]: {
            fill: "#D04010",
            icon: approvalNeutralIcon
        },
        [ApprovalStatus.Negative]: {
            fill: "#B40000",
            icon: approvalNegativeIcon
        }
    };

    constructor(props: IApprovalSelectProps) {
        super(props);

        this.renderOption = this._renderOption.bind(this);
        this.state = {
            model: this.props.initialModel
        };
    }

    public render(): ReactNode {
        return (
            <Select dataSet={this.props.options} id={this.props.id} labelId={this.props.labelId} model={this.state.model} onModelChange={this._modelChangedBound} renderOption={this.renderOption} />
        );
    }

    private _handleModelChanged(model: MenuAriaModel): void {
        if (model.activeItemIndex !== this.state.model.activeItemIndex) {
            this.props.onItemSelect(this.props.options[model.activeItemIndex]);
        }

        this.setState({
            model: model
        });
    }

    private _renderOption(item: IApprovalItem): JSX.Element {
        return IconOption(this._mapApprovalItemToIconOption(item));
    }

    private _mapApprovalItemToIconOption(item: IApprovalItem): IIconSelectOption {
        const icon = this._approvalIcons[item.approvalStatus];

        return {
            fill: icon.fill,
            icon: icon.icon,
            value: item.value
        };
    }
}
