import { NgComponent } from "angular/ngComponent";
import { createAsyncModelError, createAsyncModelPending, createAsyncModelResult } from "@pjs/utilities";
import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../../constants/assessment.constants";
import { ACTION_CONSTANTS } from "../../constants/action.constants";
import { tracker } from "@pjs/analytics";
import template from "./feedback-wrapper.html";
import "../../feedback/feedbackComponents/approvalCollection/approvalCollection.component";
import "../../feedback/feedbackComponents/feedbackTemplate/feedbackTemplate.component";
import "../../react2angular/verificationView";
import "../../feedback/feedbackComponents/similarityScore/similarityScore.component";
import "../../feedback/feedbackComponents/releaseStatus/releaseStatus.component";
import "../../feedback/feedbackComponents/assessorField/assessorField.component";
import "../../feedback/feedbackComponents/certificate/certificate.component";
import "../../feedback/feedbackInfoWrapper/feedbackInfoWrapper.component";
import "../../feedback/feedbackComponents/scorecard/scorecard.component";
import "../../feedback/feedbackDataService/feedbackDataService.service";
import "../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "../../feedback/feedbackComponents/feedback/feedback.component";
import "../feedbackComponents/approval/approval.component";
import "../feedbackComponents/capabilityApproval/capabilityApproval.component";
import "../../feedback/feedbackComponents/default/default.component";
import "../../feedback/feedbackComponents/grade/grade.component";
import "../../feedback/feedbackComponents/gradeNumeric/gradeNumeric.component";
import "../../feedbackInput/directives/feedbackInput.directive";
import "../../reply/replyDataService/replyDataService.service";
import "../feedbackSettingsProvider/feedbackSettings.provider";
import "../../manageAttachments/manageAttachments.component";
import "../../feedbackInput/directives/FeedbackInputHelper";
import "../../dropdownWrapper/dropdownWrapper.component";
import "../../multiLanguageService/multiLanguageService";
import "../../feedbackLeftOnChoice/feedbackLeftOnChoice";
import "../../attachments/attachments.component";
import "../../reply/replyWrapper/replyWrapper";
import "../../feedbackRelease/feedbackRelease";
import "../../utilities/pebbleDate";
import "../../tooltip/tooltip";
import "../jumpToButton/jumpToButton";
import "../../spaMenu/assetInfoPanel/assetFeedback/helpers/SubmissionPermissionHelper";
import "../../feedbackStatements/statementCollectionCache.service";
import "../../utilities/urlService";

class FeedbackWrapperComponent extends NgComponent {
    constructor(
        $scope,
        $filter,
        $element,
        $routeParams,
        $route,
        $location,
        multiLanguageService,
        replyDataService,
        FeedbackDataService,
        FeedbackInputHelper,
        feedbackSettingsService,
        SubmissionPermissionHelper,
        feedbackHelper,
        statementCollectionCache,
        UrlService
    ) {
        super();

        this._$scope = $scope;
        this._$filter = $filter;
        this._$element = $element;
        this._multiLanguageService = multiLanguageService;
        this._replyDataService = replyDataService;
        this._feedbackDataService = FeedbackDataService;
        this._submissionPermissionHelper = SubmissionPermissionHelper;
        this._feedbackInputHelper = FeedbackInputHelper;
        this._feedbackHelper = feedbackHelper;
        this._statementCollectionCache = statementCollectionCache;
        this._urlService = UrlService;

        this._ASSESSMENT_CONSTANTS = ASSESSMENT_CONSTANTS;
        this.ACTION_CONSTANTS = ACTION_CONSTANTS;
        this.$route = $route;
        this.$location = $location;

        this.mode = this.ACTION_CONSTANTS.VIEW;
        this.feedbackItemOptions = [];
        this.feedbackTemplates = null;
        this.currentModel = null;
        this.leftOnDetails = null;
        this.actionPending = false;
        this.actionSaving = false;
        this.isRecognisedType = false;
        this.disabledDropdownToolTip = "";
        this.editingReply = false;
        this.config = feedbackSettingsService.config;
        this.showThreeDotButton = false;
        this._validation = null;
        this.disableButtons = false;
        this.assetId = $routeParams.assetId;
        this.showJumpToButton = false;
        this._feedbackStatementsSubscription = null;
        this.feedbackStatements = createAsyncModelPending(multiLanguageService.getString("atlas_sidebar.loading_feedback_statements"));

        let permissions = this._submissionPermissionHelper.getPermissions();
        this.gradeFormat = permissions !== undefined && permissions !== null ? permissions.GradeFormat : this._feedbackHelper.GRADEFORMATS.STRING;

        this.releaseSetting = "";

        this.updateRoute = (targetAssetId) => {
            this.$location.url(this._urlService.createUrl(targetAssetId, null, true, null, null, null, true));
        };

        this.updateQueryStringParam = (searchAttribute, searchValue) => {
            this.$location.search(searchAttribute, searchValue);
        };

        this.feedbackAriaTitle = multiLanguageService.getString("atlas_sidebar.a11y.feedback_item_lower");
    }

    $onInit() {
        this.actionPending = this.pending || this.feedbackItem.Releasing;
        this.isDisabled = this.isDisabled || false;
        this.disabledDropdownToolTip = this._multiLanguageService.getString("sidebar.asset_feedback.messages.edit_in_progress");
        this.isRecognisedType = this._isRecognisedFeedbackType();
        this.allowAttachments =
            this.feedbackItem.FeedbackType === this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.COMMENT ||
            this.feedbackItem.FeedbackType === this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.CAPABILITY ||
            this.feedbackItem.FeedbackType === this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL;
        this.isBlockFeedback = this.feedbackItem.AnchorId !== null;
        this.disableLeftOn = this.isBlockFeedback || this.feedbackItem.FeedbackType === this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVALCOLLECTION;
        this.canBeLeftOnPage = this._canFeedbackBeLeftOnPage(this.feedbackItem);

        this._setLeftOnDetails();
        this._setCurrentModel();

        if (!this.actionPending) {
            this._populateFeedbackOptions();
        }

        if (this.feedbackItem.FeedbackTemplates.length > 0) {
            this._feedbackDataService.setObservable(this.feedbackItem.Id, this.feedbackItem.FeedbackTemplates);
            this._feedbackDataService.subscribe(this.feedbackItem.Id, this.updateTemplateChanges.bind(this));
        }

        this.showThreeDotButton = this._showThreeDotButton();

        if (this.feedbackItem.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL) {
            let level = ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_ONE;
            if (this.feedbackItem.Level === "2") {
                level = ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_TWO;
            } else if (this.feedbackItem.Level === "3") {
                level = ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL_LEVEL_THREE;
            }
            this.releaseSetting = this._submissionPermissionHelper.getFeedbackReleaseSetting(level);
        } else {
            this.releaseSetting = this._submissionPermissionHelper.getFeedbackReleaseSetting();
        }
    }

    $postLink() {
        this.editedTooltipBoundary = angular.element(this._$element[0].querySelector(".feedback-wrapper__footer"));
    }

    $onChanges(changes) {
        if (changes.feedbackItem !== undefined && !changes.feedbackItem.isFirstChange()) {
            this._setCurrentModel();
        }

        if (changes.pending !== undefined && !changes.pending.isFirstChange()) {
            this.actionPending = this.pending;

            if (!this.actionPending) {
                this._populateFeedbackOptions();

                if (typeof this.reloadStandards === "function") {
                    this.reloadStandards();
                }
            }
        }

        if (changes.feedbackItem !== undefined || changes.isBlockFeedbackListView !== undefined) {
            this.showJumpToButton = this._shouldShowJumpToButton();
        }

        if (changes.feedbackCount !== undefined || changes.feedbackIndex !== undefined || changes.feedbackGroup !== undefined) {
            this._setOptionsMenuLabel();
        }

        this.editedDate = this.getEditedTooltipMessage();
        this.showThreeDotButton = this._showThreeDotButton();
    }

    $onDestroy() {
        this._feedbackDataService.unsubscribe(this.feedbackItem.Id, this.updateTemplateChanges.bind(this));
        if (this.mode === ACTION_CONSTANTS.EDIT) {
            this.onToggleEditMode({ disabled: false });
        }

        this._unsubscribeFromFeedbackStatements();
    }

    onEdit(validation, changes) {
        if (changes !== null) {
            Object.assign(this.currentModel, changes);
            this._$scope.$emit("feedbackInProgress", { id: this._$scope.$id, feedbackInProgress: true, isTemplate: false });
        }

        this.disableButtons = !validation.valid;
        this._validation = validation;
    }

    onCancel() {
        this.mode === this.ACTION_CONSTANTS.ADD ? this._cancel() : this._feedbackInputHelper.launchCancelModal(this._cancel.bind(this));
    }

    onSave(released) {
        this.actionSaving = true;

        if (this._validation && !this._validation.valid) {
            this._feedbackInputHelper.launchInvalidModal(this._validation.message, this._multiLanguageService.getString("sidebar.asset_feedback.blank_feedback_title"));
            this.actionSaving = false;
            return;
        }

        tracker.trackEvent("Feedback", "Edit feedback", `${this.feedbackItem.FeedbackType} ${this.feedbackItem.AnchorId !== null ? "on block" : ""}`);
        this.currentModel.Released = released;
        this.currentModel.AssetId = this.leftOnDetails.leaveFeedbackOnPage ? this.leftOnDetails.leaveFeedbackOnAssetId : this.leftOnDetails.mainAssetId;

        this._feedbackDataService
            .replace(this.assetId, this.currentModel, this.feedbackItem)
            .then(() => {
                this._$scope.$emit("feedbackInProgress", { id: this._$scope.$id, feedbackInProgress: false, isTemplate: false });
                this.mode = this.ACTION_CONSTANTS.VIEW;
                this.onToggleEditMode({ disabled: false, updateDetails: true });
                this.actionSaving = false;
                this._unsubscribeFromFeedbackStatements();
            })
            .catch(() => {
                this.actionSaving = false;
            });
    }

    onReplyAdd(body, author) {
        return this._replyDataService.addNewReply(body, author, this.feedbackItem.Id, this.feedbackItem.AssetId, this.feedbackItem.AnchorId).then((response) => {
            this.onRepliesChange !== undefined ? this.onRepliesChange({ id: this.feedbackItem.Id }) : null;
            return response;
        });
    }

    onReplyUpdate(dto) {
        return this._replyDataService.updateReply(dto, this.feedbackItem.Id).then((response) => {
            this.onRepliesChange !== undefined ? this.onRepliesChange({ id: response.data.Id }) : null;
            return response;
        });
    }

    onReplyDelete(dto) {
        return this._replyDataService.deleteReply(dto);
    }

    updateAttachments(attachments) {
        this.currentModel.Attachments = [...attachments];
    }

    updateTemplates(templates) {
        this.currentModel.FeedbackTemplates = [...templates];
        this._$scope.$emit("feedbackInProgress", { id: this._$scope.$id, feedbackInProgress: false, isTemplate: false });
    }

    updateTemplateChanges(changes) {
        if (changes.action !== ACTION_CONSTANTS.EDIT) {
            return;
        }

        for (let i = 0; i < this.feedbackItem.FeedbackTemplates.length; i++) {
            if (this.feedbackItem.FeedbackTemplates[i].AssetId === changes.newItem.AssetId) {
                this.feedbackItem.FeedbackTemplates[i] = changes.newItem;
                break;
            }
        }

        for (let i = 0; i < this.feedbackTemplates.length; i++) {
            if (this.feedbackTemplates[i].AssetId === changes.newItem.AssetId) {
                this.feedbackTemplates[i] = changes.newItem;
                break;
            }
        }

        this.updateTemplates(this.feedbackTemplates);
    }

    toggleReply(isEditing) {
        this.editingReply = isEditing;
        this.onToggleEditMode({ disabled: isEditing, updateDetails: false });
        this._$scope.$emit("feedbackInProgress", { id: this._$scope.$id, feedbackInProgress: isEditing, isTemplate: false });
    }

    getEditedTooltipMessage() {
        const params = {
            modifiedByName: this.feedbackItem.Modifier.Name,
            date: this._$filter("pebbleDate")(this.feedbackItem.Modified, "default"),
            time: this._$filter("pebbleDate")(this.feedbackItem.Modified, "timeOnly")
        };
        return this._multiLanguageService.getString("sidebar.asset_feedback.labels.edited_tooltip", params);
    }

    showEditedByTooltip() {
        return this.config.showModifiedTooltip && !this.editingReply && this.mode === this.ACTION_CONSTANTS.VIEW && new Date(this.feedbackItem.Modified) > new Date(this.feedbackItem.Posted);
    }

    _shouldShowJumpToButton() {
        if (this.isBlockFeedbackListView === true) {
            return false;
        }
        const isMostRecentAssessorFeedback = this.feedbackItem.FeedbackType === this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD && this.feedbackItem.MostRecentForElementId;
        const isBlockLevelFeedback = this.feedbackItem.FeedbackType !== this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD && this.feedbackItem.AnchorId !== null;

        if (!isMostRecentAssessorFeedback && !isBlockLevelFeedback) {
            return false;
        }

        const submissionMainAssetId = this._submissionPermissionHelper.getSubmissionsMainAssetId();

        const isOnFeedbackAssetOrItsContainer = this.feedbackItem.AssetId === this.assetId || [this.assetId, submissionMainAssetId].includes(this.feedbackItem.LeftOn.MainAssetId);
        const isAssessorFieldOnLinkedAsset =
            this.assetId === undefined && this.feedbackItem.LeftOn.LinkedAsset && this.feedbackItem.FeedbackType === this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD;

        return isOnFeedbackAssetOrItsContainer || isAssessorFieldOnLinkedAsset;
    }

    _setCurrentModel() {
        this.currentModel = { ...this.feedbackItem };

        if (this.feedbackItem.FeedbackTemplates.length > 0) {
            this.feedbackTemplates = [...this.feedbackItem.FeedbackTemplates];
        }
    }

    _setInitialValidationState() {
        switch (this.feedbackItem.FeedbackType) {
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.CAPABILITY:
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL:
                this.disableButtons = !this._feedbackHelper.getApprovalValidation(this.feedbackItem.Status).valid;
                break;
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.SCORECARD:
                this.disableButtons = false;
                break;
            default:
                this.disableButtons = true;
                break;
        }
    }

    _isRecognisedFeedbackType() {
        return Object.values(this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES).indexOf(this.feedbackItem.FeedbackType) > -1;
    }

    _showThreeDotButton() {
        return Boolean(this.feedbackItemOptions.length !== 0 && !this.feedbackItem.ReadOnly && !this.feedbackItem.IsLegacy);
    }

    _isFeedbackTemplate() {
        return this.feedbackItem.FeedbackTemplates.length === 1 && this.feedbackItem.Comment === "" && this.feedbackItem.Attachments.length === 0;
    }

    _confirmDelete() {
        this._feedbackDataService.remove(this.assetId, this.feedbackItem).catch(angular.noop);
    }

    _fetchFeedbackStatements() {
        const subscriptionResponseHandlers = {
            next: (statements) => this._handleFeedbackStatementsResponse(statements, false),
            error: () => this._handleFeedbackStatementsResponse([], true)
        };

        this._feedbackStatementsSubscription = this._statementCollectionCache.getStatements(this.feedbackItem.SubmissionId).subscribe(subscriptionResponseHandlers);
    }

    _handleFeedbackStatementsResponse(statements, hasError) {
        this._$scope.$applyAsync(() => {
            if (hasError) {
                this.feedbackStatements = createAsyncModelError(this._multiLanguageService.getString("atlas_sidebar.feedback_statements_error"));
                return;
            }

            this.feedbackStatements =
                statements.length > 0 ? createAsyncModelResult(statements) : createAsyncModelError(this._multiLanguageService.getString("atlas_sidebar.no_feedback_statements_available"));
        });
    }

    _unsubscribeFromFeedbackStatements() {
        if (this._feedbackStatementsSubscription !== null) {
            this._feedbackStatementsSubscription.unsubscribe();
            this._feedbackStatementsSubscription = null;
        }
    }

    _confirmEdit() {
        this._fetchFeedbackStatements();
        this._setLeftOnDetails();
        this._setInitialValidationState();
        this.mode = this._isFeedbackTemplate() ? this.ACTION_CONSTANTS.ADD : this.ACTION_CONSTANTS.EDIT;
        this.onToggleEditMode({ disabled: true, updateDetails: false });
    }

    _cancel() {
        if (!this._isFeedbackTemplate()) {
            this._$scope.$emit("feedbackInProgress", { id: this._$scope.$id, feedbackInProgress: false, isTemplate: false });
        }
        this._unsubscribeFromFeedbackStatements();
        this.mode = this.ACTION_CONSTANTS.VIEW;
        this._setCurrentModel();
        this.onToggleEditMode({ disabled: false, updateDetails: false });
    }

    _updateReleasedStatus(released) {
        const newFeedbackItem = { ...this.feedbackItem, Released: released, Pending: true };
        this._feedbackDataService.updateReleasedStatus(this.assetId, this.feedbackItem, newFeedbackItem).catch(angular.noop);
    }

    _multiLangFeedbackType() {
        if (this._isFeedbackTemplate()) {
            return this._multiLanguageService.getString("atlas_sidebar.feedback_types.template.short").toLowerCase();
        }

        switch (this.feedbackItem.FeedbackType) {
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.COMMENT:
                return this._multiLanguageService.getString("atlas_sidebar.feedback_types.written.title").toLowerCase();
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.GRADE:
                return this._multiLanguageService.getString("atlas_sidebar.feedback_types.grade.title").toLowerCase();
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD:
                return this._multiLanguageService.getString("atlas_sidebar.feedback_types.assessor_field.title").toLowerCase();
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.CAPABILITY:
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL:
                return this._multiLanguageService.getString("atlas_sidebar.feedback_types.approval.title").toLowerCase();
            case this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.SCORECARD:
                return this._multiLanguageService.getString("atlas_sidebar.feedback_types.scorecard.title").toLowerCase();
            default:
                return "";
        }
    }

    _setLeftOnDetails() {
        if (this.disableLeftOn) {
            this.leftOnDetails = {
                leaveFeedbackOnPage: true,
                leaveFeedbackOnAssetId: this.feedbackItem.AssetId,
                mainAssetId: this.feedbackItem.LeftOn.MainAssetId,
                pageId: this.feedbackItem.LeftOn.PageId,
                feedbackAssetId: this.feedbackItem.AssetId
            };
            return;
        }

        this.leftOnDetails = {
            leaveFeedbackOnPage: false,
            // If feedback is not left on the main asset, use the current assetId
            leaveFeedbackOnAssetId: this.feedbackItem.LeftOn.MainAssetId === this.feedbackItem.AssetId && this.feedbackItem.LeftOn.PageId === null ? "" : this.assetId,
            mainAssetId: this.feedbackItem.LeftOn.MainAssetId,
            pageId: this.feedbackItem.LeftOn.PageId,
            feedbackAssetId: this.feedbackItem.AssetId
        };

        // If the feedback is left on the main asset, leftOnChoice should be unchecked.
        if (this.feedbackItem.AssetId === this.feedbackItem.LeftOn.MainAssetId) {
            this.leftOnDetails.leaveFeedbackOnPage = false;
        }
        // If feedback is left on linked asset, leftOnChoice should be checked if we are viewing the asset it was left on.
        else if (this.feedbackItem.LeftOn.LinkedAsset && this.assetId === this.feedbackItem.AssetId) {
            this.leftOnDetails.leaveFeedbackOnPage = true;
        }
        // If the feedback is left on a workbook page, leftOnChoice should be checked if we are viewing the page it was left on.
        else if (this.feedbackItem.LeftOn.PageId !== null && this.assetId === this.feedbackItem.LeftOn.PageId) {
            this.leftOnDetails.leaveFeedbackOnPage = true;
        }
    }

    _feedbackLabels(action) {
        switch (action) {
            case this.ACTION_CONSTANTS.CANCEL:
                return {
                    text: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.cancel"),
                    title: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.cancel_title")
                };
            case this.ACTION_CONSTANTS.EDIT:
                return {
                    text: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.edit.name", { type: this._multiLangFeedbackType() }),
                    allyLabel: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.edit.title", { type: this._multiLangFeedbackType() })
                };
            case this.ACTION_CONSTANTS.DELETE:
                return {
                    text: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.name", { type: this._multiLangFeedbackType() }),
                    allyLabel: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.title", { type: this._multiLangFeedbackType() }),
                    confirm: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.confirm"),
                    confirmA11yLabel: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.confirm_ally")
                };
            case this.ACTION_CONSTANTS.RECALL:
                return {
                    text: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.recall.name", { type: this._multiLangFeedbackType() }),
                    allyLabel: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.recall.title", { type: this._multiLangFeedbackType() }),
                    confirm: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.recall.confirm"),
                    confirmA11yLabel: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.recall.confirm_ally")
                };
            case this.ACTION_CONSTANTS.RELEASE:
                return {
                    text: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.release.name", { type: this._multiLangFeedbackType() }),
                    allyLabel: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.release.title", { type: this._multiLangFeedbackType() }),
                    confirm: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.release.confirm"),
                    confirmA11yLabel: this._multiLanguageService.getString("sidebar.asset_feedback.buttons.release.confirm_ally")
                };
        }
    }

    _setOptionsMenuLabel() {
        if (this.feedbackIndex === undefined || this.feedbackCount === undefined || this.feedbackGroup === undefined) {
            this.feedbackOptionsLabel = this._multiLanguageService.getString("atlas_sidebar.a11y.feedback_options");

            return;
        }

        this.feedbackOptionsLabel = this._multiLanguageService.getString("atlas_sidebar.a11y.feedback_options_for_n_of_n_in_the_group", {
            number: this.feedbackIndex,
            total: this.feedbackCount,
            groupValue: this.feedbackGroup
        });
    }

    _populateFeedbackOptions() {
        this._setOptionsMenuLabel();

        this.feedbackItemOptions = [];

        if (this.feedbackItem.Permissions.AllowRelease) {
            const releaseText = this._feedbackLabels(this.ACTION_CONSTANTS.RELEASE);

            this.feedbackItemOptions.push({
                name: releaseText.text,
                dataHook: `dropdown-option-release-${this.feedbackItem.FeedbackType}`,
                ally: releaseText.allyLabel,
                displayConfirmation: true,
                confirm: releaseText.confirm,
                confirmA11y: releaseText.confirmA11yLabel,
                onConfirm: this._updateReleasedStatus.bind(this, true),
                icon: "icon-release"
            });
        }

        if (this.feedbackItem.Permissions.AllowRecall) {
            const recallText = this._feedbackLabels(this.ACTION_CONSTANTS.RECALL);

            this.feedbackItemOptions.push({
                name: recallText.text,
                dataHook: `dropdown-option-recall-${this.feedbackItem.FeedbackType}`,
                ally: recallText.allyLabel,
                displayConfirmation: true,
                confirm: recallText.confirm,
                confirmA11y: recallText.confirmA11yLabel,
                onConfirm: this._updateReleasedStatus.bind(this, false),
                icon: "icon-recall"
            });
        }

        if (this.feedbackItem.Permissions.AllowEdit) {
            const editText = this._feedbackLabels(this.ACTION_CONSTANTS.EDIT);
            this.feedbackItemOptions.push({
                name: editText.text,
                dataHook: `dropdown-option-edit-${this.feedbackItem.FeedbackType}`,
                ally: editText.allyLabel,
                displayConfirmation: false,
                onConfirm: this._confirmEdit.bind(this),
                icon: "icon-edit"
            });
        }

        if (this.feedbackItem.Permissions.AllowDelete) {
            const deleteText = this._feedbackLabels(this.ACTION_CONSTANTS.DELETE);

            this.feedbackItemOptions.push({
                name: deleteText.text,
                dataHook: `dropdown-option-delete-${this.feedbackItem.FeedbackType}`,
                ally: deleteText.allyLabel,
                displayConfirmation: true,
                confirm: deleteText.confirm,
                confirmA11y: deleteText.confirmA11yLabel,
                onConfirm: this._confirmDelete.bind(this),
                icon: "icon-delete"
            });
        }
    }

    _canFeedbackBeLeftOnPage(feedbackItem) {
        return feedbackItem.FeedbackType !== this._ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.SCORECARD;
    }
}

const feedbackWrapperDefinition = {
    bindings: {
        feedbackItem: "<",
        pending: "<",
        onToggleEditMode: "&",
        isDisabled: "<?",
        onRepliesChange: "&?",
        reloadStandards: "<?",
        isBlockFeedbackListView: "<?",
        feedbackIndex: "<?",
        feedbackCount: "<?",
        feedbackGroup: "<?"
    },
    template: template,
    controller: FeedbackWrapperComponent
};

FeedbackWrapperComponent.$inject = [
    "$scope",
    "$filter",
    "$element",
    "$routeParams",
    "$route",
    "$location",
    "multiLanguageService",
    "replyDataService",
    "feedbackDataService",
    "FeedbackInputHelper",
    "feedbackSettingsService",
    "SubmissionPermissionHelper",
    "feedbackHelper",
    "statementCollectionCache",
    "urlService"
];
angularAMD.component("feedbackWrapper", feedbackWrapperDefinition);
export { feedbackWrapperDefinition as feedbackWrapper };
