import { angularAMD } from "@pebblepad/amd";
import { ButtonClass } from "@pjs/core-ui";
import { STORE_CONSTANTS } from "../../constants/store.constants";
import "../../tagger/tagger";
import "../../datePicker/datePickerHelper";
import "../../extensions/arrayExtensions";
import "../../dateToggle/dateToggle";
import "../../dropdown/dropdown";
import "../../assetSelector/assetSelector.service";
import "../../react2angular/buttonComponent";
import "../../react2angular/switch";
import template from "../templates/advanced-search.html";

var CustomFilter = function (name, filter, type) {
    this.DisplayName = name;
    this.Filter = filter ?? null;
    this.FilterType = type;
};

angularAMD.directive("advancedSearchContent", [
    "multiLanguageService",
    "datePickerHelper",
    "assetSelector",
    "$timeout",
    function (multiLanguageService, datePickerHelper, assetSelector, $timeout) {
        return {
            restrict: "E",
            template: template,
            scope: {
                filterOptions: "=",
                searchType: "@",
                inSidebar: "=",
                labels: "=",
                alignment: "@", //Left or Right, default of centre
                buttons: "=", //Expects an array of button objects: [{ title: 'My Button Title', class: 'btn-success', action: function(){}, validation: true}]
                defaults: "=", //Default values to load and set.
                showIncludeHiddenOption: "=",
                disableResponsesToFilter: "<?",
                disableSearchResult: "=",
                allowSearchDefaulting: "<?"
            },

            controller: [
                "$scope",
                "$attrs",
                function ($scope, $attrs) {
                    //Initialise variables ---------------------------------------------------------------------------------
                    $scope.STORE_CONSTANTS = STORE_CONSTANTS;

                    $scope.multiLanguageService = multiLanguageService;
                    $scope.outlinedButtonClass = ButtonClass.Outlined;
                    $scope.searchDefaultDisabled = true;
                    $scope.filtersValid = false;
                    $scope.responsesToFilterDataHooks = {
                        selectButton: "criteria-select-template-button",
                        removeButton: "criteria-remove-template-button"
                    };

                    var ownerType = "secondary.ownership";

                    $scope.filters = {
                        keywords: new CustomFilter(multiLanguageService.getString("asset_store.filters.keywords"), [], "primary.keywords"),
                        assetTypes: new CustomFilter(),
                        responsesTo: new CustomFilter(multiLanguageService.getString("asset_store.headings.filter_types.by_responses_to"), null, "primary.responsesTo"),
                        ownership: new CustomFilter(multiLanguageService.getString("asset_store.filters.all"), null, "secondary.ownership"),
                        tags: new CustomFilter(multiLanguageService.getString("asset_store.filters.tag"), [], "secondary.tags"),
                        dateFrom: new CustomFilter(multiLanguageService.getString("date_picker.from"), null, "secondary.dateFrom"),
                        dateTo: new CustomFilter(multiLanguageService.getString("date_picker.to"), null, "secondary.dateTo"),
                        includeHidden: new CustomFilter(multiLanguageService.getString("asset_store.filters.include_hidden"), false, "secondary.includeHidden"),
                        enableSearchByDefault: new CustomFilter("", false)
                    };

                    $scope.ownerTypes = [
                        new CustomFilter(multiLanguageService.getString("asset_store.filters.all"), null, ownerType),
                        new CustomFilter(multiLanguageService.getString("asset_store.filters.by_me"), "ByMe", ownerType),
                        new CustomFilter(multiLanguageService.getString("asset_store.filters.for_me"), "ForMe", ownerType)
                    ];

                    //FUNCTIONS --------------------------------------------------------------------------------------------
                    const updateResponsesToFilter = (filter) => {
                        $scope.filters.responsesTo.Filter = filter;
                        const subType = $scope.filters.responsesTo.FilterType.split(".")[1];
                        $scope.$emit("searchFilterChange", subType, $scope.filters.responsesTo, $scope.validateFilters());
                    };

                    $scope.openAssetSelector = function () {
                        assetSelector
                            .selectAsset({
                                requiresWritePermission: true,
                                selectType: "ResourceCentre",
                                mainTypes: ["Form"]
                            })
                            .then((data) => {
                                if (data === null) {
                                    return;
                                }

                                const filter = {
                                    Title: data.Title,
                                    Icon: data.Icon,
                                    Id: data.Id,
                                    LastModified: data.LastModified
                                };
                                updateResponsesToFilter(filter);
                            })
                            .catch(angular.noop);
                    };

                    $scope.removeResponsesToFilter = function () {
                        updateResponsesToFilter(null);
                    };

                    $scope.addFilter = function (filter, overrideType) {
                        var originalType = filter.FilterType;
                        if (overrideType) {
                            if (!filter.OriginalType) {
                                filter.OriginalType = filter.FilterType;
                            }

                            filter.FilterType = overrideType;
                        }

                        var subType = filter.FilterType.split(".")[1];
                        var property = $scope.filters[subType];
                        if (property !== undefined) {
                            //If the filter did not originally have a FilterType, then it is a default (reset required!)
                            $scope.filters[subType] = originalType ? filter : new CustomFilter(null, null, $scope.filters[subType].FilterType);
                            $scope.$emit("searchFilterChange", subType, $scope.filters[subType], $scope.validateFilters());
                        }
                    };

                    $scope.addKeywordsFilter = function (keywords) {
                        $scope.filters.keywords.Filter = $scope.filterOptions.primary.keywords;
                        $scope.validateFilters();
                        $scope.$emit("searchFilterChange", $scope.filters.keywords.FilterType.split(".")[1], $scope.filters.keywords, $scope.validateFilters());
                    };

                    $scope.includeHiddenClicked = function () {
                        if (!$scope.filters.includeHidden.Filter) {
                            $scope.resetFilter($scope.filters.includeHidden);
                        }
                    };

                    $scope.toggleCriteriaSearch = (isChecked) => {
                        $scope.filters.enableSearchByDefault.Filter = isChecked;
                        $scope.validateFilters();
                    };

                    $scope.resetFilter = function (filter) {
                        if (filter.DisplayName) {
                            filter.Filter = null;
                        } else {
                            filter = null;
                        }
                    };

                    $scope.getDisplayNameIfValid = function (filter) {
                        return filter && filter.Filter ? filter.DisplayName : null;
                    };

                    $scope.setValuesFromDefaults = function () {
                        var filters = $scope.filters,
                            defaults = $scope.defaults,
                            assetTypeFilter,
                            ownershipFilter;

                        // tslint:disable-next-line:no-conditional-assignment
                        if (defaults.assetTypes && (assetTypeFilter = getFilterFromValue($scope.filterOptions.primary.assetTypes, defaults.assetTypes))) {
                            filters.assetTypes = assetTypeFilter;
                        }

                        // tslint:disable-next-line:no-conditional-assignment
                        if (defaults.ownership && $scope.filterOptions.secondary.ownership && (ownershipFilter = getFilterFromValue($scope.filterOptions.secondary.ownership, defaults.ownership))) {
                            filters.ownership = ownershipFilter;
                        }

                        $scope.showKeywordField = defaults.keywords;
                        filters.keywords.Filter = defaults.keywords;
                        filters.responsesTo.Filter = defaults.responsesTo;
                        filters.tags.Filter = defaults.tags || [];
                        filters.dateFrom.Filter = defaults.dateFrom || null;
                        filters.dateTo.Filter = defaults.dateTo || null;
                        filters.includeHidden = defaults.includeHidden;
                        filters.enableSearchByDefault.Filter = defaults.enableSearchByDefault;

                        if ($scope.allowSearchDefaulting === true) {
                            $scope.searchDefaultDisabled = !validateEnableSearchCriteria();
                        }
                        $scope.validateFilters();
                    };

                    $scope.validateFilters = function () {
                        if ($scope.allowSearchDefaulting === true) {
                            const isSearchDefaultDisabled = $scope.searchDefaultDisabled;
                            $scope.searchDefaultDisabled = !validateEnableSearchCriteria();

                            if (isSearchDefaultDisabled !== $scope.searchDefaultDisabled) {
                                $scope.filters["enableSearchByDefault"].Filter = !$scope.searchDefaultDisabled;
                            }
                        }

                        $scope.filtersValid = false;
                        for (const filterName in $scope.filters) {
                            if (filterName !== "enableSearchByDefault" && $scope.filters.hasOwnProperty(filterName)) {
                                const filter = $scope.filters[filterName];
                                if (filter?.Filter !== undefined && filter.Filter !== null && (!Array.isArray(filter.Filter) || filter.Filter.length > 0)) {
                                    $scope.filtersValid = true;
                                    break;
                                }
                            }
                        }

                        $scope.$applyAsync();
                        return $scope.filtersValid;
                    };

                    $scope.updateDateDisplayName = function (dateFilter, baseName) {
                        if (dateFilter.Filter) {
                            dateFilter.DisplayName = baseName + " " + datePickerHelper.toString(dateFilter.Filter);
                        }
                    };

                    //PRIVATE FUNCTIONS ------------------------------------------------------------------------------------
                    var setActiveFilter = function (filter, activeFilter) {
                        filter.DisplayName = activeFilter.DisplayName;
                        filter.Filter = activeFilter.Filter;

                        if (!filter.FilterType) {
                            filter.FilterType = activeFilter.FilterType;
                        }
                    };

                    var getFilterFromType = function (filterType) {
                        var filter;
                        for (var filterName in $scope.filters) {
                            if ($scope.filters.hasOwnProperty(filterName)) {
                                filter = $scope.filters[filterName];
                                // tslint:disable-next-line:no-conditional-assignment
                                if (filter && (filter.FilterType === filterType || (filter = $scope.filters[filterType.split(".")[1]]))) {
                                    return filter;
                                }
                            }
                        }
                    };

                    var getFilterFromValue = function (filtersObj, value) {
                        var result = filtersObj.where({ Filter: value });
                        return result[0] || null;
                    };

                    var onTagUpdate = function (event) {
                        event.stopPropagation();
                        $scope.validateFilters();
                        $scope.$emit("searchFilterChange", $scope.filters.tags.FilterType.split(".")[1], $scope.filters.tags, $scope.validateFilters());
                    };

                    const validateEnableSearchCriteria = () => {
                        const tagsFilter = $scope.filters["tags"];
                        const responsesToFilter = $scope.filters["responsesTo"];
                        const isTagFilterValid = tagsFilter?.Filter?.length !== undefined && tagsFilter.Filter.length > 0;
                        const hasResourceSelected = responsesToFilter?.Filter !== undefined && responsesToFilter.Filter !== null;
                        return isTagFilterValid || hasResourceSelected;
                    };

                    //EVENTS -----------------------------------------------------------------------------------------------
                    $scope.$on("tagAdded", onTagUpdate);
                    $scope.$on("tagRemoved", onTagUpdate);
                    $scope.$on("setActiveFilter", function (event, activeFilter) {
                        var filter = getFilterFromType(activeFilter.FilterType);
                        if (filter) {
                            setActiveFilter(filter, activeFilter);
                        }
                    });
                    $scope.$on("datesUpdated", function (event, dates) {
                        event.stopPropagation();
                        var dateFilter, baseName, date;
                        for (var i = 0, l = dates.length; i < l; i++) {
                            date = dates[i];
                            if (date.type === "after") {
                                dateFilter = $scope.filters.dateFrom;
                                baseName = $scope.labels.dates.after;
                            } else {
                                dateFilter = $scope.filters.dateTo;
                                baseName = $scope.labels.dates.before;
                            }
                            dateFilter.Filter = date.value;
                            $scope.updateDateDisplayName(dateFilter, baseName);
                            $scope.$emit("searchFilterChange", dateFilter.FilterType.split(".")[1], dateFilter, i === l - 1 ? $scope.validateFilters() : false);
                        }
                    });

                    $scope.$on("removeFilter", function (event, filterType) {
                        var filter = getFilterFromType(filterType);
                        if (filter) {
                            $scope.resetFilter(filter);
                        }
                    });

                    $scope.$on("$destroy", function () {
                        $scope.filters.tags.Filter.length = null;
                    });

                    $attrs.$observe("searchType", function (newValue, oldValue) {
                        $scope.displayAssetAndResponseFilters = newValue.indexOf(STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) === -1;
                    });
                }
            ],

            link: function (scope, element, attrs) {
                if (attrs.defaults) {
                    $timeout(function () {
                        scope.setValuesFromDefaults();
                    });
                }
            }
        };
    }
]);
