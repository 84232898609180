import { FunctionComponent, PropsWithChildren } from "react";
import { IComponentWithClassNameProps } from "../../interfaces/IComponentWithClassNameProps";
import { CollapsibleContext } from "./Collapsible.context";

export const CollapsibleHeader: FunctionComponent<PropsWithChildren<IComponentWithClassNameProps>> = (props) => {
    return (
        <CollapsibleContext.Consumer>
            {(context) => (
                <button onClick={context.onToggle} aria-expanded={context.isOpen} aria-controls={context.contentId} className={props.className} data-hook="collapsible-header">
                    {props.children}
                </button>
            )}
        </CollapsibleContext.Consumer>
    );
};
