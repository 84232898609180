/**
 * @typedef AbortablePromiseLike
 * @template T
 * @template R
 * @property { (onResolve: (value: T) => R, onReject: (error: unknown) => R) => AbortablePromiseLike } then
 * @property { (onReject: (error: unknown) => R) => AbortablePromiseLike } catch
 * @property { (onComplete: () => void) => AbortablePromiseLike } finally
 * @property { () => void } [abort]
 */

export class AbortablePromiseChain {
    constructor(promise, aborter) {
        this._promise = promise;
        this._aborter = aborter;
    }

    then(resolve, reject) {
        const onResolve = typeof resolve === "function" ? (data) => this._onCompletion(resolve, data) : null;
        const onReject = typeof reject === "function" ? (rejection) => this._onCompletion(reject, rejection) : null;

        return new AbortablePromiseChain(this._promise.then(onResolve, onReject), this._aborter);
    }

    catch(reject) {
        return new AbortablePromiseChain(
            this._promise.catch((rejection) => this._onCompletion(reject, rejection)),
            this._aborter
        );
    }

    finally(resolve) {
        return new AbortablePromiseChain(
            this._promise.finally((data) => this._onCompletion(resolve, data)),
            this._aborter
        );
    }

    abort() {
        this._aborter.abort();
    }

    _onCompletion(callback, data) {
        if (this._aborter.isAborted()) {
            return;
        }

        return callback(data);
    }
}
