import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../assetEndpointService/assetEndpoint.service";

angularAMD.factory("assetBaseInfoService", [
    "$http",
    "AssetEndpointService",
    function ($http, assetEndpointService) {
        const factory = {};

        factory.getBaseInfo = function (assetId) {
            $http.defaults.withCredentials = true;
            return assetEndpointService.getBasicDetails(assetId);
        };

        return factory;
    }
]);
