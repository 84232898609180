import { angularAMD } from "@pebblepad/amd";
import { DialogActionController } from "./dialogAction.component";
import template from "./busy-dialog-action.html";
import "../react2angular/busyButtonComponent";

const busyDialogActionDefinition = {
    bindings: {
        buttonClass: "<",
        dataHook: "@",
        label: "@",
        onClick: "&",
        ariaLabel: "<",
        ariaDescribedBy: "@",
        hideDialog: "<?", // Boolean
        isAsyncAction: "<?", // Boolean
        spinnerColour: "<?", // Boolean
        busy: "<?" // Boolean
    },
    template: template,
    controller: DialogActionController
};

DialogActionController.$inject = ["$rootScope", "$scope"];
angularAMD.component("busyDialogAction", busyDialogActionDefinition);
