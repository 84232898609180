import { angularAMD } from "@pebblepad/amd";

/**
 * Injected services should be lower camel as they should be treated as if we have an instance of something!
 * @param $window
 * @constructor
 */
function UserAgentService($window) {
    this.window = $window;
    this.deviceVersion = null;
    this.browserVersion = null;
    this.edge = false;
    this.ie = false;
    this.unsupported = false;
    this.android = false;
    this.ios = false;
    this.firefox = false;
    this.chrome = false;
    this.safari = false;
    this.touch = false;
    var htmlClasses = ["no-edge", "no-ie", "no-ie10", "no-firefox", "no-safari", "no-touch", "no-chrome", "no-android", "no-ios"];
    var self = this;
    /**
     * Run the user agent checks.
     */
    this.init = function () {
        detectBrowser();
        detectDevice();
        testTouch();
        cleanUpHtmlClasses();
    };

    /**
     * Append appropriate CSS classes to HTML element
     */
    function cleanUpHtmlClasses() {
        var htmlDoc = self.window.document.querySelector("html");
        angular.element(htmlDoc).removeClass("no-js");
        for (var i = 0, ii = htmlClasses.length; i < ii; i++) {
            angular.element(htmlDoc).addClass(htmlClasses[i]);
        }
    }

    /**
     * Remove the class of the user agent from the list of CSS classes
     * @param uaData {Object}
     * @param uaData.browser {?string}
     * @param uaData.device {?string}
     * @constructor
     */
    function removeUAClass(uaData) {
        var classPart = uaData.browser !== undefined ? uaData.browser.toLowerCase() : uaData.device.toLowerCase();
        htmlClasses[htmlClasses.indexOf("no-" + classPart)] = classPart;
    }

    /**
     * Check which browser the user is using.
     */
    function detectBrowser() {
        if (testIe()) {
            return;
        }
        //Edge must be checked first because browser wars
        var tests = [
            {
                browser: "Edge",
                keyword: "Edge"
            },
            {
                browser: "Firefox",
                keyword: "Firefox"
            },
            {
                browser: "Chrome",
                keyword: "Chrome"
            },
            {
                browser: "Safari",
                keyword: "Version"
            }
        ];

        for (var i = 0; i < tests.length; i++) {
            if (testGeneric(tests[i])) {
                return;
            }
        }
        self.unsupported = true;
    }

    /**
     * Check if user agent is mobile device
     */
    function detectDevice() {
        if (
            testGeneric({
                device: "Android",
                keyword: "Android",
                //Some android versions are 5 digits long
                //User Agents are difficult to parse on purpose
                //This makes it consistent.
                offset: 3
            })
        ) {
            return;
        }
        if (testIOS()) {
            return;
        }
    }

    /**
     * A method for parsing the most common type of user agent.
     * Just like browser user agents, this is a tiny bit hacky, but can be used to successfully parse:
     * Safari, Firefox, Edge, Chrome and Android devices
     * @param uaData
     * @param uaData.offset {?Number}
     * @param uaData.browser {?String}
     * @param uaData.device {?String}
     * @param uaData.keyword {String}
     * @param uaData.ignoreVersion {Boolean}
     * @returns {boolean} Checks complete
     */
    function testGeneric(uaData) {
        var searchIndex = self.window.navigator.userAgent.indexOf(uaData.keyword);

        if (uaData.offset === undefined) {
            uaData.offset = 2;
        }

        if (searchIndex > -1) {
            var name = "";
            var type = "";
            if (uaData.browser !== undefined) {
                type = "browser";
                name = uaData.browser.toLowerCase();
            } else {
                type = "device";
                name = uaData.device.toLowerCase();
            }
            self[name] = true;
            if (!uaData.ignoreVersion) {
                var versionPosition = uaData.keyword.length + searchIndex + 1;
                var versionString = self.window.navigator.userAgent.substring(versionPosition, versionPosition + uaData.offset);
                if (versionString.length === 2 && versionString[1] === ".") {
                    versionString = versionString[0];
                }
                self[type + "Version"] = versionString;
            }
            removeUAClass(uaData);
            return true;
        }
    }

    /**
     * User agent strings are even less consistent on older browsers.
     * This will detect IE10 and above. Other versions of IE are considered to be unsupported
     * @returns {boolean} Is it IE?
     */
    function testIe() {
        if (Function("/*@cc_on return document.documentMode===10@*/")()) {
            self.version = 10;
            self.ie = true;
            removeUAClass({
                browser: "ie"
            });
            return true;
        }
        if (/(?:\sTrident\/7\.0;.*\srv:11\.0)/i.test(self.window.navigator.userAgent)) {
            self.browserVersion = "11";
            self.ie = true;
            removeUAClass({
                browser: "ie"
            });
            return true;
        }
        return false;
    }

    /**
     * Catch all for Apple devices.
     * Currently no version checks, but the could be added if needed.
     * @returns {boolean}
     */
    function testIOS() {
        if (/iP(hone|od|ad)/.test(self.window.navigator.userAgent)) {
            self.ios = true;
            //Currently no mechanism of easily getting the IOS version, could be added if needed.
            removeUAClass({
                device: "ios"
            });
            return true;
        }

        // Check if the user is on an iPad Pro running iOS 13+
        if (/MacIntel/.test(self.window.navigator.platform) && self.window.navigator.maxTouchPoints && self.window.navigator.maxTouchPoints > 2) {
            self.ios = true;
            removeUAClass({
                device: "ios"
            });
            return true;
        }

        return false;
    }

    /**
     * Check if the device is touchscreen.
     * Devices with mice will fallback to non-touch version.
     */
    function testTouch() {
        if ("onpointerdown" in self.window) {
            self.touch = false;

            return;
        }
        if ("ontouchstart" in self.window) {
            self.touch = true;
            removeUAClass({
                device: "touch"
            });
            return;
        }
        self.touch = false;
        return;
    }
}

UserAgentService.$inject = ["$window"];
angularAMD.service("UserAgentService", UserAgentService);
