import { IToolbarItem } from "../interfaces/IToolbarItem";
import { removeFormat } from "../RemoveFormat";
import { numberedList } from "../NumberedList";
import { bulletedList } from "../BulletedList";
import { outdent } from "../Outdent";
import { indent } from "../Indent";
import { bold } from "../Bold";
import { italic } from "../Italic";
import { underline } from "../Underline";
import { strikethrough } from "../StrikeThrough";
import { linker } from "../Linker";
import { undo } from "../Undo";
import { redo } from "../Redo";
import { specialCharacters } from "../SpecialCharacters";

export const compactToolbarPreset: Array<Array<IToolbarItem>> = [
    [bold, italic, underline, strikethrough],
    [numberedList, bulletedList],
    [removeFormat],
    [linker],
    [outdent, indent],
    [specialCharacters],
    [undo, redo]
];
