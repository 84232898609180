import { angularAMD } from "@pebblepad/amd";
import "./stickyContainer.directive";

function StickyItem() {
    return {
        restrict: "A",
        require: "?^stickyContainer",
        link: (scope, element, attrs, stickyContainer) => {
            if (stickyContainer === null) {
                return;
            }

            stickyContainer.coordinator.add(element[0]);

            element.on("$destroy", () => {
                stickyContainer.coordinator.remove(element[0]);
            });
        }
    };
}

angularAMD.directive("stickyItem", StickyItem);
