import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ButtonClass, DialogSize, launchDialog } from "@pjs/core-ui";
import { mapCapabilityValueToLabel } from "../../mapCapabilityValueToLabel/mapCapabilityValueToLabel";
import "./answerHistoryAccordion/answerHistoryAccordion.component";
import "../../../multiLanguageService/multiLanguageService";
import "../../../utilities/pebbleDate";
import "../../../user/user.service";
import "../../../utilities/helpers";
import template from "./answer-history.html";

class AnswerHistoryComponent extends NgComponent {
    constructor($filter, $scope, multiLanguageService, User, helpers) {
        super();

        this._$filter = $filter;
        this._$scope = $scope;
        this.multiLanguageService = multiLanguageService;
        this.helpers = helpers;
        this.userId = User.getId();
        this.historyTitle = this.multiLanguageService.getString("labels.answer_history.answer_history_title");
        this.historyItems = null;
        this.onEditJustification = this._editJustification.bind(this);
        this._setHistoryItems();
    }

    $onChanges(changes) {
        if (changes.answerHistory !== undefined && !changes.answerHistory.isFirstChange()) {
            this._setHistoryItems();
        }
    }

    _createAndSortCapabilityHistoryArray() {
        return this.answerHistory
            .sort((a, b) => new Date(b.LastUpdatedOn) - new Date(a.LastUpdatedOn))
            .map((item) => {
                const answer = mapCapabilityValueToLabel(this.originalElement, item.Answer);
                const previousAnswer = item.PreviousAnswer !== "" ? mapCapabilityValueToLabel(this.originalElement, item.PreviousAnswer) : undefined;

                return {
                    answer: answer,
                    justification: item.Justification,
                    lastUpdatedBy: item.LastUpdatedBy,
                    lastUpdatedByName: item.LastUpdatedByName,
                    lastUpdatedOnDate: this._$filter("pebbleDate")(item.LastUpdatedOn, "dateOnly"),
                    lastUpdatedOnTime: this._$filter("pebbleDate")(item.LastUpdatedOn, "timeOnly"),
                    originalItem: item,
                    previousAnswer: previousAnswer
                };
            });
    }

    _editJustification(historyItem) {
        const cancelText = this.multiLanguageService.getString("buttons.cancel");
        const confirmText = this.multiLanguageService.getString("buttons.confirm");
        const initialModel = {
            newValue: mapCapabilityValueToLabel(this.originalElement, historyItem.originalItem.Answer),
            justificationContent: historyItem.justification
        };

        const currentValue = historyItem.originalItem.PreviousAnswer;

        if (currentValue !== "" && currentValue !== null) {
            initialModel.currentValue = mapCapabilityValueToLabel(this.originalElement, currentValue);
        }

        launchDialog({
            title: this.multiLanguageService.getString("capability_justification.justify"),
            size: DialogSize.Large,
            component: () => import("@pjs/form-response-element").then((module) => module.JustificationDialogContent),
            actions: [
                {
                    label: cancelText,
                    ariaLabel: cancelText,
                    buttonClass: ButtonClass.Outlined,
                    dataHook: "justification-cancel-button",
                    disabled: () => false,
                    action: (_, instance) => {
                        instance.close();
                        this._$scope.$applyAsync();
                    }
                },
                {
                    label: confirmText,
                    ariaLabel: confirmText,
                    buttonClass: ButtonClass.Contained,
                    dataHook: "justification-confirm-button",
                    disabled: (model) => !/\S/g.test(this.helpers.htmlToPlain(model.justificationContent)),
                    action: (model, instance) => {
                        this._updateJustification(model.justificationContent);
                        instance.close();
                        this._$scope.$applyAsync();
                    }
                }
            ],
            initialModel: initialModel
        });

        this._$scope.$applyAsync();
    }

    _setHistoryItems() {
        this.historyItems = this._createAndSortCapabilityHistoryArray();
        this.historyTitle = this.multiLanguageService.getString("labels.answer_history.answer_history_title", { count: this.historyItems.length });
    }

    _updateJustification(justification) {
        this.onUpdateAnswerHistoryJustification(justification);
    }
}

const AnswerHistoryDefinition = {
    bindings: {
        titleId: "<",
        answerHistory: "<",
        viewMode: "<",
        originalElement: "<",
        onUpdateAnswerHistoryJustification: "<"
    },
    template: template,
    controller: AnswerHistoryComponent
};

AnswerHistoryComponent.$inject = ["$filter", "$scope", "multiLanguageService", "User", "helpers"];

angularAMD.component("answerHistory", AnswerHistoryDefinition);

export { AnswerHistoryDefinition as answerHistory };
