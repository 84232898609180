import { angularAMD } from "@pebblepad/amd";
import "../../utilities/urlService";

function FeedbackUrlServiceProvider() {
    this.config = {
        fullURL: true,
        queryParams: null
    };

    this.$get = [
        "$location",
        "urlService",
        function ($location, urlService) {
            return new FeedbackUrlService(this.config.fullURL, this.config.queryParams, $location, urlService);
        }
    ];
}

function FeedbackUrlService(fullURL, queryParams, $location, urlService) {
    this._fullURL = fullURL;
    this._queryParams = queryParams;
    this._location = $location;
    this._urlService = urlService;
}

/**
 * @param {object} params
 * @param {boolean} noHash
 * @return {string}
 */
FeedbackUrlService.prototype.createFeedbackTemplateUrl = function (params, noHash) {
    if (this._fullURL) {
        return this._urlService.createHashUrl(params.assetId, "", true);
    } else {
        var queryParams = [];
        for (var key in this._queryParams) {
            if (params[key] !== undefined) {
                queryParams.push({ key: this._queryParams[key], value: params[key] });
            }
        }

        return (noHash ? "" : "#") + this._urlService.addParameters(this._location.url(), queryParams);
    }
};

/**
 * @param {string} assetId
 * @param {string=} mainType
 * @param {boolean=} viewer
 * @return {string}
 */
FeedbackUrlService.prototype.createFeedbackAttachmentUrl = function (assetId, mainType, viewer) {
    return this._urlService.createHashUrl(assetId, mainType, viewer === true);
};

angularAMD.provider("feedbackUrlService", FeedbackUrlServiceProvider);

export { FeedbackUrlServiceProvider as feedbackUrlService };
