import { IAsyncModelResult } from "./interfaces/IAsyncModelResult";
import { AsyncModelStatus } from "./enums/AsyncModelStatus";

export function createAsyncModelResult<T>(result: T): IAsyncModelResult<T> {
    return {
        error: null,
        pending: null,
        result: result,
        status: AsyncModelStatus.Resolved
    };
}
