import { angularAMD } from "@pebblepad/amd";
import "../../../multiLanguageService/multiLanguageService";
import template from "./release-status.html";

var releaseStatusDefinition = {
    bindings: {
        released: "<"
    },
    template: template,
    controller: ReleaseStatus
};

ReleaseStatus.$inject = ["multiLanguageService"];
function ReleaseStatus(multiLanguageService) {
    this._multiLanguageService = multiLanguageService;
    this.feedbackReleasedText = this._multiLanguageService.getString("sidebar.asset_feedback.released");
    this.feedbackUnreleasedText = this._multiLanguageService.getString("sidebar.asset_feedback.feedback_unreleased");
}

angularAMD.component("releaseStatus", releaseStatusDefinition);
export { releaseStatusDefinition as releaseStatus };
