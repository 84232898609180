import { KeyboardEvent, RefObject, FocusEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { Keys } from "../../../../../enums/Keys";
import { closeIfFocusIsOutside } from "../../reducers/CloseIfFocusIsOutside.function";
import { selectFirstItem } from "../../reducers/SelectFirstItem.function";
import { selectLastItem } from "../../reducers/SelectLastItem.function";
import { createSelectMatchedItem } from "../../reducers/SelectMatchedItem.function";
import { selectNextItemWithWrapping } from "../../reducers/SelectNextItemWithWrapping.function";
import { selectPreviousItemWithWrapping } from "../../reducers/SelectPreviousItemWithWrapping.function";
import { setClosed } from "../../reducers/SetClosed.function";
import { IAriaModelWithType } from "../interfaces/IAriaModelWithType";
import { MenuAriaModel } from "../types/MenuAriaModel";
import { IMenuTargetKeyEvents } from "../interfaces/IMenuTargetKeyEvents";

type TargetEvents<T> = IMenuTargetKeyEvents<T> & {
    onFocusLoss: (e: FocusEvent, trigger: RefObject<HTMLElement>, target: RefObject<HTMLElement>, tempTarget: Element | null) => Partial<Immutable<IAriaModelWithType>> | null;
};

export const menuRoleTargetEventsFactory = <T>(
    matchItemOnKeys: (item: T, currentKeys: string) => boolean,
    selectAction: (_: KeyboardEvent, currentModel: IAriaModelWithType, items: Array<T>) => Partial<Immutable<MenuAriaModel>>
): TargetEvents<T> => {
    return {
        [Keys.ArrowUp]: selectPreviousItemWithWrapping,
        [Keys.ArrowDown]: selectNextItemWithWrapping,
        [Keys.End]: selectLastItem,
        [Keys.Home]: selectFirstItem,
        [Keys.Enter]: selectAction,
        [Keys.Escape]: setClosed,
        [Keys.Space]: selectAction,
        default: createSelectMatchedItem(matchItemOnKeys),
        onFocusLoss: closeIfFocusIsOutside
    };
};
