import { RefObject } from "react";
import { Plugin } from "@pebblepad/ckeditor";
import { isIpadLike, isIphoneLike, noop } from "@pjs/utilities";
import { replaceDropdownPositioningLogic } from "../behaviours/replace-dropdown-positioning-logic/ReplaceDropdownPositioningLogic";
import { IEditor } from "../editor/interfaces/IEditor";
import { Editor } from "../editor/Editor";
import { ToolbarDisplayState } from "../editor/enums/ToolbarDisplayState";
import { handleIosBlockingClicks } from "../behaviours/handle-ios-blocking-clicks/HandleIosBlockingClicks.function";
import { richTextI18n } from "../i18n/RichTextI18n.const";
import { trackToolbarItems } from "../behaviours/track-toolbar-items/TrackToolbarItems.function";
import { richTextConfig } from "../../RichTextConfig";
import { slowPasteLogging } from "../behaviours/slow-paste-logging/SlowPasteLogging.function";
import { slowUndoRedoLogging } from "../behaviours/slow-undo-redo-logging/SlowUndoRedoLogging.function";
import { IEditorConfig } from "./interfaces/IEditorConfig";
import { EditorChangeCallback } from "./types/EditorChangeCallback";
import { IEditorFactoryConfig } from "./interfaces/IEditorFactoryConfig";
import { baseCkEditorPlugins } from "./consts/BaseCkEditorPlugins.const";

export class EditorFactory {
    private static readonly _htmlSupportConfig: IEditorConfig["htmlSupport"] = {
        allow: [
            {
                attributes: true,
                classes: true,
                name: /.*/,
                styles: true
            }
        ]
    };

    public static create(
        editorContainer: RefObject<HTMLElement>,
        toolbarContainer: RefObject<HTMLElement>,
        editorPlaceholderContainer: RefObject<HTMLElement>,
        content: string,
        onChange: EditorChangeCallback,
        config: IEditorFactoryConfig,
        plugins: Array<typeof Plugin> = [],
        onToolbarDisplayChange?: (toolbarState: ToolbarDisplayState, toolbarContainer: HTMLElement) => void
    ): IEditor {
        const editorConfig = EditorFactory._mapToCkEditorConfig(config);
        editorConfig.initialData = content;
        editorConfig.plugins = editorConfig.plugins.concat(baseCkEditorPlugins, plugins);
        editorConfig.licenseKey = richTextConfig.licenseKey;

        const toolbarDisplayCallback = onToolbarDisplayChange ?? noop;
        const editor = new Editor(editorConfig, editorContainer, toolbarContainer, editorPlaceholderContainer, config.parser, onChange, toolbarDisplayCallback, config.placeholder);

        const behaviourCleanupDelegates = config.behaviours.map((behaviour) => behaviour(editor, config));

        behaviourCleanupDelegates.push(replaceDropdownPositioningLogic(editor, config));
        behaviourCleanupDelegates.push(trackToolbarItems(editor, config));
        behaviourCleanupDelegates.push(slowPasteLogging(editor, config));
        behaviourCleanupDelegates.push(slowUndoRedoLogging(editor, config));

        if (isIpadLike || isIphoneLike) {
            behaviourCleanupDelegates.push(handleIosBlockingClicks(editor, config));
        }

        editor.destruction.subscribe(() => {
            behaviourCleanupDelegates.forEach((cleanUpMethod) => cleanUpMethod());
        });

        return editor;
    }

    private static _mapToCkEditorConfig(config: IEditorFactoryConfig): IEditorConfig {
        const ckeConfig: IEditorConfig = {
            htmlSupport: EditorFactory._htmlSupportConfig,
            initialData: "",
            language: richTextI18n.getLanguage(),
            plugins: [],
            toolbar: {
                items: [],
                shouldNotGroupWhenFull: false
            },
            updateSourceElementOnDestroy: true
        };

        const toolbarItems = ckeConfig.toolbar.items;
        const plugins = new Set<typeof Plugin>();

        for (const toolbarGroup of config.toolbarItems) {
            for (const toolbarItem of toolbarGroup) {
                toolbarItems.push(toolbarItem.name);

                if (toolbarItem.pluginConfig !== null) {
                    Object.assign(ckeConfig, toolbarItem.pluginConfig);
                }

                for (const plugin of toolbarItem.plugins) {
                    plugins.add(plugin);
                }
            }

            toolbarItems.push("|");
        }

        toolbarItems.pop();
        ckeConfig.plugins = [...plugins];

        if (config.pluginOverrides !== null) {
            Object.assign(ckeConfig, config.pluginOverrides);
        }

        return ckeConfig;
    }
}
