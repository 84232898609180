import { angularAMD } from "@pebblepad/amd";
import Mark from "mark.js/dist/mark.min";
import "../../../multiLanguageService/multiLanguageService";
import "../../../react2angular/htmlNotice";
import "../../../react2angular/dismissableHtmlNotice";
import "../../../utilities/baseUrlsFactory";
import "../../../utilities/scrollToItem.directive";
import "../../../utilities/highlightItem.directive";
import "../../../builder/dataManager/dataManagerService";
import "../../../user/user.service";
import "../../../messageAnnouncer/messageAnnouncer.directive";
import "../../../utilities/pebbleDate";
import "../../../feedback/feedbackSearchService/feedbackSearchService.service";
import "../../../searchBar/searchBar.component";
import "./commentsDataModel";
import "./directives/commentInputBox";
import "./directives/comment";
import template from "./templates/asset-comments.html";

angularAMD.directive("assetComments", AssetComments);
AssetComments.$inject = [];

angularAMD.controller("AssetCommentsController", AssetCommentsController);
AssetCommentsController.$inject = [
    "$scope",
    "$routeParams",
    "$timeout",
    "$rootScope",
    "$window",
    "commentsDataModel",
    "multiLanguageService",
    "dataManagerService",
    "User",
    "feedbackSearchService",
    "$element",
    "$q",
    "domSearchHelper"
];

//Directive
function AssetComments() {
    return {
        restrict: "E",
        template: template,
        scope: {
            assetId: "=",
            submissionId: "<",
            anchorId: "=?",
            onUpdate: "&",
            asset: "<?"
        },
        bindToController: true,
        controller: "AssetCommentsController",
        controllerAs: "vm"
    };
}

//Controller
function AssetCommentsController(
    $scope,
    $routeParams,
    $timeout,
    $rootScope,
    $window,
    commentsDataModel,
    multiLanguageService,
    dataManagerService,
    User,
    feedbackSearchService,
    $element,
    $q,
    domSearchHelper
) {
    this.services = {
        eventEmitter: $scope.$emit.bind($scope),
        $routeParams: $routeParams,
        $timeout: $timeout,
        $rootScope: $rootScope,
        commentsDataModel: commentsDataModel,
        multiLanguageService: multiLanguageService,
        dataManagerService: dataManagerService,
        User: User,
        $element: $element,
        $q: $q,
        domSearchHelper: domSearchHelper
    };
    this._$window = $window;
    this._feedbackSearchService = feedbackSearchService;
    this.editorLabel = this.services.multiLanguageService.getString("labels.form_element_toolbar.a11y.comment_editor");
    this.dataLoaded = false;
    this.commentExists = false;
    this.screenReaderMessage = this.services.multiLanguageService.getString("sidebar.asset_comments.aria.loading_message");

    this.isDisabled = false;
    this.isNewCommentDisabled = false;
    this.searchBarOptions = null;
    this.sortAscending = false;
    this.showSearchHelp = false;
    this.sortButtonTitle = "";
    let assetRef = null;
    this.assetManager = this.services.dataManagerService.getDto(this.assetId);
    this._searchTimeout = null;
    this._searchText = "";
    this._assetCommentsMark = new Mark("asset-comments [data-markable]");
    this.commentOptions = null;
    this.pageCommentMessage = "";
    this.newCommentScrollId = "";
    this.newCommentHighlightId = "";

    if (this.asset === undefined && this.assetManager !== null && this.assetManager !== undefined) {
        assetRef = this.assetManager.data;
    } else if (this.asset !== undefined) {
        assetRef = this.asset;
    }
    this.assetInfo = {
        mainType: assetRef === null ? "" : assetRef.MainType,
        title: assetRef === null || assetRef.Title === "" ? this.services.multiLanguageService.getString("sidebar.asset_comments.no_title") : assetRef.Title
    };

    this.requiresAuthor = this.services.User.getDto() === null;
    this.services.commentsDataModel.getComments(this.assetId, this.submissionId, this.anchorId).then(this.setupComments.bind(this, true));

    this.urlChange = this.services.$rootScope.$on("$locationChangeSuccess", () => {
        this.createCommentOptions();
    });
}

AssetCommentsController.prototype.$onDestroy = function () {
    this.urlChange();
    this.services.$timeout.cancel(this._searchTimeout);
};

AssetCommentsController.prototype.setupComments = function (focusPanelTab) {
    this.data = this.services.commentsDataModel.data;
    if (this.data.Comments === undefined) {
        this._setCommentState();
        this.showSearchHelp = false;
        this.dataLoaded = true;
        return;
    }

    this.comments = this.services.commentsDataModel.data.Comments;
    this.sort(this.sortAscending);
    this.showInconsistentWarning = this.services.commentsDataModel.data.CommentCountDifferentFromCountOnAssetRef;

    this._setCommentState();

    this.searchBarOptions = {
        buttonStyle: "light",
        dataHookName: "comments",
        inputAria: this.services.multiLanguageService.getString("sidebar.asset_comments.aria.search_input"),
        disabledPlaceholder: this.services.multiLanguageService.getString("search_bar.disabled_placeholder")
    };

    this.createCommentOptions();
    this.dataLoaded = true;

    this.services.eventEmitter("infoPanelContentLoaded", true, focusPanelTab);
};

AssetCommentsController.prototype.onCommentChange = function (commentInProgress, isNewComment) {
    if (isNewComment) {
        this.isNewCommentDisabled = commentInProgress;
    } else {
        this.isDisabled = commentInProgress;
    }

    this.onUpdate({
        id: this.assetId,
        commentInProgress: commentInProgress
    });
};

AssetCommentsController.prototype.createCommentOptions = function () {
    const pageId = this.services.$routeParams.pageId;
    this.commentOptions = null;
    this.pageDto = null;
    this.pageCommentMessage = "";

    if (pageId !== undefined && /^(workbookresponse|webfolio)$/i.test(this.assetInfo.mainType)) {
        this.pageDto = this.assetManager.get(pageId, false);
        const pageAssetId = this.pageDto.UserResponseId || this.pageDto.PageAssetId;

        this.commentOptions = {
            mainAssetId: this.assetId,
            pageId: pageAssetId,
            pageTitle: this.services.multiLanguageService.getString("sidebar.asset_comments.save_on_this_page_title", {
                assetTitle: this.pageDto.PageTitle
            })
        };

        if (this.pageDto.UserResponseId === "" || this.pageDto.UserResponseId === null) {
            this.commentOptions = null;
            this.pageCommentMessage = this.services.multiLanguageService.getString("sidebar.asset_comments.page_comments_disabled");
        }
    }
};

/**
 * @param {{body: string; author: string; isPageComment: string}} data
 * @returns Promise<void>
 */
AssetCommentsController.prototype.onNewCommentAdd = function (data) {
    const assetId = data.isPageComment ? this.commentOptions.pageId : this.assetId;
    let commentDto = null;
    return this.services.commentsDataModel
        .addNewComment(data.body, data.author, "", assetId, this.anchorId, this.submissionId)
        .then((comment) => {
            commentDto = comment;
            commentDto.AssetTitle = data.isPageComment ? this.pageDto.PageTitle : this.assetInfo.title;
            commentDto.CommentType = "Normal";
            this.comments.push(commentDto);
            this.sort(this.sortAscending);
            this.isNewCommentDisabled = false;
            return this.onSearch(this._searchText);
        })
        .then(() => {
            this.screenReaderMessage = `${this.services.multiLanguageService.getString("sidebar.asset_comments.comment_added")}. ${this.screenReaderMessage}`;
            const newCommentId = `asset-comments-comment-${commentDto.Id}`;
            this.newCommentScrollId = newCommentId;
            this.newCommentHighlightId = newCommentId;
            this.onUpdate({
                id: this.assetId,
                commentInProgress: false
            });

            if (this.comments.length === 0 || (this.comments[0].Id !== commentDto.Id && this.comments[this.comments.length - 1].Id !== commentDto.Id)) {
                return;
            }
            const commentElement = document.getElementById(newCommentId);
            const focusTarget = this.services.domSearchHelper.getFirstTabbableElement(commentElement) ?? commentElement.querySelector("[data-hook='saved-comment-reply-text']");
            focusTarget.focus({ preventScroll: true });
        });
};

AssetCommentsController.prototype.clearScrollId = function () {
    this.newCommentScrollId = "";
};

AssetCommentsController.prototype.clearHighlightId = function () {
    this.newCommentHighlightId = "";
};

AssetCommentsController.prototype.onCommentDelete = function (deletedIndex, isMainComment) {
    this.onSearch(this._searchText).then(() => {
        this.screenReaderMessage = `${this.services.multiLanguageService.getString("sidebar.asset_comments.comment_deleted")}. ${this.screenReaderMessage}`;

        if (!isMainComment) {
            return;
        }

        let isLastElement = deletedIndex === this.comments.length;
        if (this.comments.length === 0) {
            this.services.$element[0].querySelector(`[data-hook="comment-text-area"]`).focus();
            return;
        }

        const targetIndex = isLastElement ? this.comments.length - 1 : deletedIndex;
        const commentElement = document.getElementById(`asset-comments-comment-${this.comments[targetIndex].Id}`);
        const focusTarget = this.services.domSearchHelper.getFirstTabbableElement(commentElement) ?? commentElement.querySelector("[data-hook='saved-comment-reply-text']");
        focusTarget.focus();
    });
};

AssetCommentsController.prototype.onSearch = function (searchText) {
    this._searchText = searchText;
    this._resetInitialLoadState(false);
    this.screenReaderMessage = "";

    const deferred = this.services.$q.defer();

    this._searchTimeout = this.services.$timeout(() => {
        let comments = this.services.commentsDataModel.data.Comments;
        if (this._searchText !== "") {
            comments = this._feedbackSearchService.searchComment(this._searchText, this.comments);
        }
        this.comments = comments;
        this.dataLoaded = true;

        this._setCommentState();
        this._markText(this._assetCommentsMark);
        deferred.resolve();
    });

    return deferred.promise;
};

AssetCommentsController.prototype.sort = function (ascending) {
    this.sortAscending = ascending;
    this.comments.sort(this.sortAscending ? this._sortByDateAsc : this._sortByDateDesc);

    this.sortButtonTitle = this.sortAscending
        ? this.services.multiLanguageService.getString("search_bar.sort_options.sort_ascending")
        : this.services.multiLanguageService.getString("search_bar.sort_options.sort_descending");
};

AssetCommentsController.prototype._sortByDateAsc = function (a, b) {
    if (a.Posted < b.Posted) {
        return -1;
    } else if (a.Posted > b.Posted) {
        return 1;
    }
    return 0;
};

AssetCommentsController.prototype._sortByDateDesc = function (a, b) {
    if (a.Posted > b.Posted) {
        return -1;
    } else if (a.Posted < b.Posted) {
        return 1;
    }
    return 0;
};

AssetCommentsController.prototype._setCommentState = function () {
    if (this.data.Comments === undefined) {
        this.screenReaderMessage = this.services.multiLanguageService.getString("sidebar.asset_comments.no_permission");
        this.showSearchHelp = false;
        this.commentExists = false;
    } else if (this.data.Comments.length < 1) {
        this.screenReaderMessage = this.services.multiLanguageService.getString("sidebar.asset_comments.no_comments");
        this.showSearchHelp = false;
        this.commentExists = false;
    } else if (this.comments.length < 1) {
        this.screenReaderMessage = this.services.multiLanguageService.getString("search_bar.no_search_results.message");
        this.showSearchHelp = true;
        this.commentExists = true;
    } else {
        this.screenReaderMessage = this.services.multiLanguageService.getString("sidebar.asset_comments.aria.loaded_message", {
            count: this.comments.length
        });
        this.commentExists = true;
    }
};

AssetCommentsController.prototype._resetInitialLoadState = function (focusPanelTab) {
    this._assetCommentsMark.unmark();
    this.setupComments(focusPanelTab);
    this.showSearchHelp = false;
};

AssetCommentsController.prototype._markText = function (markElement) {
    if (markElement !== null && this._searchText !== "") {
        this._$window.requestAnimationFrame(() => {
            markElement.mark(this._searchText, {
                acrossElements: true,
                ignoreJoiners: true,
                separateWordSearch: false
            });
        });
    }
};
