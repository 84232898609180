import { AsyncModelStatus } from "./enums/AsyncModelStatus";
import { IAsyncModelPending } from "./interfaces/IAsyncModelPending";

export function createAsyncModelPending<T>(pending: T): IAsyncModelPending<T> {
    return {
        error: null,
        pending: pending,
        result: null,
        status: AsyncModelStatus.Pending
    };
}
