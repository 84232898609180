export class LegacyLinkerModalSettings {
    constructor(config, multiLanguageService) {
        this._config = config;
        this._multiLanguageService = multiLanguageService;
        this._dropdownOptions = [];

        this._createDropdownOptions();
    }

    getDropdownOptions() {
        return this._dropdownOptions;
    }

    _createDropdownOptions() {
        const defaultDropdownOptions = [
            { display: this._multiLanguageService.getString("ckeditor.plugins.linker.url"), type: "url", hook: "link-url-dropdown", newTabCheckbox: false },
            { display: this._multiLanguageService.getString("ckeditor.plugins.linker.email"), type: "email", hook: "link-email-dropdown", newTabCheckbox: false }
        ];

        if (this._config.enableAssetLinking) {
            this._dropdownOptions.push({
                display: this._multiLanguageService.getString("ckeditor.plugins.linker.asset"),
                type: "asset",
                hook: "link-asset-dropdown",
                newTabCheckbox: true
            });
        }

        if (this._config.enableResourceLinking) {
            this._dropdownOptions.push({
                display: this._multiLanguageService.getString("ckeditor.plugins.linker.resource"),
                type: "resource",
                hook: "link-resource-dropdown",
                newTabCheckbox: true
            });
        }

        this._dropdownOptions = this._dropdownOptions.concat(defaultDropdownOptions);
    }
}
