import { angularAMD } from "@pebblepad/amd";
import template from "../templates/feedback-input.html";
import { ASSESSMENT_CONSTANTS } from "../../constants/assessment.constants";
import { tracker } from "@pjs/analytics";
import "../../manageAttachments/manageAttachments.component";
import "../../attachments/attachment/attachment.component";
import "../../multiLanguageService/multiLanguageService";
import "../../feedbackLeftOnChoice/feedbackLeftOnChoice";
import "../../assetSelector/assetSelector.service";
import "../../feedbackRelease/feedbackRelease";
import "./FeedbackInputHelper";
import "../../feedbackLeftOnChoice/feedbackLeftOn.service";
import "../../utilities/escapeHelper.service";
import "../../spaMenu/assetInfoPanel/assetFeedback/helpers/SubmissionPermissionHelper";

angularAMD.directive("feedbackInput", FeedbackInput);
FeedbackInput.$inject = [];

angularAMD.controller("FeedbackInputController", FeedbackInputController);
FeedbackInputController.$inject = ["$timeout", "multiLanguageService", "FeedbackInputHelper", "assetSelector", "feedbackLeftOnService", "EscapeHelper", "SubmissionPermissionHelper"];

//Directive
//==============================================================================================================
function FeedbackInput() {
    return {
        restrict: "E",
        template: template,
        scope: {
            feedback: "<",
            singleLine: "<",
            allowAttachments: "<",
            onSubmit: "&", //Expects to be called with {body: String, author: String}
            onCancel: "&", //Expects {active: false}
            onChange: "&",
            feedbackFormat: "<",
            validation: "<",
            disableButtons: "<",
            assetId: "<",
            pageId: "<",
            blockTitle: "<",
            canBeLeftOnPage: "<"
        },
        bindToController: true, //binds directive attributes ($scope values above) directly to the Controller (this.)
        controller: "FeedbackInputController",
        controllerAs: "$ctrl",
        transclude: true
    };
}

//Controller
//==============================================================================================================
function FeedbackInputController($timeout, multiLanguageService, feedbackInputHelper, assetSelector, feedbackLeftOnService, escapeHelper, SubmissionPermissionHelper) {
    this.$timeout = $timeout;
    this.multiLanguageService = multiLanguageService;
    this.feedbackInputHelper = feedbackInputHelper;
    this.assetSelector = assetSelector;
    this.savingFeedback = false;
    this.placeholderText = "";
    this.feedbackLeftOnService = feedbackLeftOnService;
    this.isBlockLevelFeedback = false;
    this.isApprovalFeedback = false;
    this.leftOnDetails = null;
    this._escapeHelper = escapeHelper;
    this.blockTitleText = "";
    this.submissionPermissionHelper = SubmissionPermissionHelper;
    this.releaseSetting = this.submissionPermissionHelper.getFeedbackReleaseSetting(this.feedback.FeedbackType);
}

FeedbackInputController.prototype.$onChanges = function (changes) {
    if (changes.feedback !== undefined) {
        this._setupLeftOnDetails();
    }

    if (changes.blockTitle !== undefined) {
        this.blockTitleText = this.multiLanguageService.getString("sidebar.asset_feedback.left_on_choice.leave_feedback_on_block", { title: this._escapeHelper.convertHTMLChars(this.blockTitle) });
    }
};

FeedbackInputController.prototype._setupLeftOnDetails = function () {
    this.isBlockLevelFeedback = this.feedback.AnchorId !== null;
    this.isApprovalFeedback = this.feedback.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.CAPABILITY;

    if (this.canBeLeftOnPage === false) {
        this.leftOnDetails = {
            leaveFeedbackOnPage: false
        };
        return;
    }

    this.leftOnDetails = {
        leaveFeedbackOnPage: this.isBlockLevelFeedback,
        leaveFeedbackOnAssetId: this.feedbackLeftOnService.getLeftOnAssetInfo(this.assetId, this.pageId, this.feedback.AnchorId).leaveFeedbackOnAssetId
    };
};

FeedbackInputController.prototype.validateThenSubmit = function (released) {
    if (this._validate()) {
        this.savingFeedback = true;
        this._submit(released);
    }
};

FeedbackInputController.prototype._validate = function () {
    if (this.validation === undefined || this.validation.valid) {
        return true;
    }

    this.feedbackInputHelper.launchInvalidModal(this.validation.message, this.multiLanguageService.getString("sidebar.asset_feedback.blank_feedback_title"));
    return false;
};

FeedbackInputController.prototype._submit = function (released) {
    let leaveOnPageOrLinkedAssetId = "";

    tracker.trackEvent("Feedback", "Add feedback", `${this.feedback.FeedbackType} ${this.isBlockLevelFeedback ? "on block" : ""}`);

    if (this.leftOnDetails.leaveFeedbackOnPage === true && this.leftOnDetails.leaveFeedbackOnAssetId !== "") {
        leaveOnPageOrLinkedAssetId = this.leftOnDetails.leaveFeedbackOnAssetId;
    }

    this.feedback.Released = released;

    if (leaveOnPageOrLinkedAssetId !== "") {
        this.feedback.AssetId = leaveOnPageOrLinkedAssetId;
    }

    this.onSubmit({ feedback: this.feedback })
        .catch(angular.noop)
        .finally(() => {
            this.savingFeedback = false;
        });
};

FeedbackInputController.prototype.cancelEdit = function () {
    const feedbackHasCommentsOrAttachments = this.feedback.Comment !== "" || this.feedback.Attachments.length > 0;
    feedbackHasCommentsOrAttachments ? this.feedbackInputHelper.launchCancelModal(this.cancel.bind(this)) : this.cancel();
};

FeedbackInputController.prototype.cancel = function () {
    this.onCancel({ active: false });
};

/**
 * Update feedback attachments
 *
 * @param attachments
 * @param added
 * @param removed
 */
FeedbackInputController.prototype.updateAttachments = function (attachments, added, removed) {
    this.feedback.Attachments = attachments;

    /* call change event on parent component */
    this.onChange();
};
