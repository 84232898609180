import { cookieStore } from "@pjs/storage";
import { Observable, of, ReplaySubject, share, defer, tap, takeUntil, merge } from "@pjs/observables";
import { SameSite } from "@pjs/storage";
import { IEmbedConsentManager } from "./interfaces/IEmbedConsentManager";

export class CookieConsentManager implements IEmbedConsentManager {
    public readonly consent: Observable<boolean>;

    private readonly _hasConsentSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private readonly _cookieName: string = "__PP_Embed_content_consent";

    constructor() {
        this.consent = this._createHasConsentObservable();
    }

    public grantConsent(): Observable<void> {
        this._hasConsentSubject.next(true);

        const now = new Date();
        this._writeCookie(this._cookieName, now.toUTCString());

        return of();
    }

    public revokeConsent(): Observable<void> {
        this._hasConsentSubject.next(false);

        this._deleteCookie();

        return of();
    }

    private _createHasConsentObservable(): Observable<any> {
        const getCookie = defer(() => of(this._readCookie() === null ? false : true)).pipe(
            share(),
            tap((value) => this._hasConsentSubject.next(value)),
            takeUntil(this._hasConsentSubject)
        );

        return merge(this._hasConsentSubject, getCookie);
    }

    private _readCookie(): string | null {
        const cookie = cookieStore.get(this._cookieName);

        if (cookie === null) {
            return null;
        }

        return cookie.value;
    }

    private _writeCookie(key: string, value: string): void {
        cookieStore.set(key, value, {
            sameSite: SameSite.Strict,
            secure: true
        });
    }

    private _deleteCookie(): void {
        cookieStore.delete(this._cookieName);
    }
}
