import { angularAMD } from "@pebblepad/amd";
import "./baseUrlsFactory";

angularAMD.provider("urlService", UrlServiceProvider);

function UrlServiceProvider() {
    var useRelativeAssetPaths = true;

    this.useRelativeAssetPaths = function (value) {
        useRelativeAssetPaths = value;
    };

    this.$get = [
        "$http",
        "$window",
        "$location",
        "$routeParams",
        "$q",
        "baseUrlsFactory",
        function ($http, $window, $location, $routeParams, $q, baseUrlsFactory) {
            return urlService($http, $window, $location, $routeParams, $q, baseUrlsFactory, useRelativeAssetPaths);
        }
    ];
}

function urlService($http, $window, $location, $routeParams, $q, baseUrlsFactory, useRelativeAssetPaths) {
    //Private variables --------------------------------------------------------------------------------------------
    var paths = {
        assetcollectionwrapper: "collection",
        form: "template",
        formresponse: "template",
        workbook: "workbook",
        workbookresponse: "workbook",
        webfoliopage: "foliopage",
        templatebuilder: "builder/template",
        workbookbuilder: "builder/workbook",
        activitylog: "activitylog",
        post: "post",
        blog: "blog",
        file: "file",
        webfolio: "webfolio",
        viewer: "viewer"
    };

    var pathsRegex = Object.keys(paths).map(function (key) {
        return new RegExp("#/" + paths[key], "i");
    });

    //Private Methods ----------------------------------------------------------------------------------------------
    function getParameterByName(url, name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(url);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    function fixMainType(mainType) {
        if (!mainType) {
            return paths.viewer;
        }

        var path = paths[mainType.toLowerCase()];
        return path !== void 0 ? path : paths.viewer;
    }

    function getSubmissionId(submissionId) {
        if (submissionId) {
            return submissionId;
        } else if ($routeParams.submissionId) {
            return $routeParams.submissionId;
        } else {
            return "";
        }
    }

    function getSubmissionIdQueryString(submissionId) {
        var queryStringSubmissionId = getSubmissionId(submissionId);

        if (queryStringSubmissionId) {
            return "&submissionId=" + queryStringSubmissionId;
        }

        return "";
    }

    //Public API ---------------------------------------------------------------------------------------------------
    return {
        useRelativeAssetPaths: useRelativeAssetPaths,
        /**
         * Add parameters to a URL
         * @param url {String}
         * @param values {Array<{key: String, value: String}>}
         */
        addParameters: function (url, values) {
            var joiningChar = !/\?/.test(url) ? "?" : "&";
            var regex = null;
            var param = null;
            for (var i = 0, l = values.length; i < l; i++) {
                regex = new RegExp("([?&])" + values[i].key + "=.*?(&|$)", "i");
                param = values[i];

                if (url.match(regex) !== null) {
                    url = url.replace(regex, "$1" + param.key + "=" + param.value + "$2");
                } else {
                    url = url + joiningChar + param.key + "=" + param.value;
                }

                joiningChar = "&";
            }
            return url;
        },
        configureAssetUrl: function (url, overrideAction, compileUrl, timeslice, submissionId) {
            if (!this.useRelativeAssetPaths) {
                return url;
            }

            var urlSubmissionId = getSubmissionId(submissionId);

            var urlTimeslice = null;
            if (timeslice) {
                urlTimeslice = timeslice;
                // tslint:disable-next-line:triple-equals
            } else if (timeslice != 0) {
                urlTimeslice = $routeParams.timeslice;
            }

            var assetUrl = url;
            var id = getParameterByName(url, "id");
            var pageId = getParameterByName(url, "pageId");
            var location = getParameterByName(url, "location");
            var action = overrideAction || getParameterByName(url, "action");
            var maintype = getParameterByName(url, "mainType");
            var isPublic = $location.path().indexOf("public") > -1;
            maintype = fixMainType(maintype);

            // If it can be loaded in the local project
            if (location.toLowerCase() === "plushtml5" || location.toLowerCase() === "admissions" || compileUrl) {
                // Use relative path instead

                if (action.toLowerCase() === "edit" && !isPublic) {
                    assetUrl = "#/" + maintype.toLowerCase() + "/" + id;
                } else {
                    // Check for public viewing
                    if (isPublic) {
                        assetUrl = "#/public/" + id;
                    } else {
                        assetUrl = "#/viewer/";

                        if (urlSubmissionId) {
                            assetUrl = assetUrl + urlSubmissionId + "/";
                        }

                        if (urlTimeslice) {
                            assetUrl = assetUrl + urlTimeslice + "/";
                        }

                        assetUrl = assetUrl + id;
                    }
                }

                if (pageId) {
                    assetUrl = assetUrl + "?pageId=" + pageId;
                }
            }

            return assetUrl;
        },

        configureMappingUrl: function (assetId) {
            return `mapping/${assetId}`;
        },

        promisedGet: function (url, params) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.get(url, {
                timeout: httpTimeout.promise,
                params: params
            });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return $q.reject(httpError);
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        },

        promisedPost: function (url, data) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.post(url, data, { timeout: httpTimeout.promise });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        },
        /**
         *
         * @param params Array<KeyValue>
         * @returns {string}
         */
        getForwardingUrl: function (params) {
            return this.addParameters(baseUrlsFactory.forwarding_url, params);
        },
        getFileImageUrl: function (id, submissionId) {
            return baseUrlsFactory.api_base_url + "file/transmitImage?id=" + id + getSubmissionIdQueryString(submissionId) + "&x=0&y=0";
        },

        /**
         * @param {string} assetId
         * @param {string|null=} assetMainType
         * @param {Boolean=} viewMode
         * @param {Boolean=} publicUrl
         * @param {Number=} timeslice
         * @param {Number=} submissionid
         * @returns {string}
         */
        createBaseUrl: function (assetId, assetMainType, viewMode, publicUrl, timeslice, submissionId) {
            let url = "";

            let urlSubmissionId = getSubmissionId(submissionId);
            if (urlSubmissionId) {
                urlSubmissionId = urlSubmissionId + "/";
            }

            if (publicUrl) {
                url = "/public/" + assetId;
            } else if (viewMode) {
                url = "/viewer/" + urlSubmissionId + assetId;
            } else {
                url = "/" + fixMainType(assetMainType) + "/" + assetId;
            }

            //url could be set to /viewer/ via the viewMode Boolean or fixMainType, so we have to check the current value here;
            if (/^\/viewer/.test(url)) {
                let urlTimeslice = null;
                if (timeslice) {
                    urlTimeslice = timeslice;
                    // tslint:disable-next-line:triple-equals
                } else if (timeslice != 0) {
                    urlTimeslice = $routeParams.timeslice;
                }

                if (urlTimeslice) {
                    url = "/viewer/" + urlSubmissionId + urlTimeslice + "/" + assetId;
                }
            }

            return url;
        },

        /**
         * @param {string} assetId
         * @param {string|null=} assetMainType
         * @param {Boolean=} viewMode
         * @param {Boolean=} publicUrl
         * @param {Number=} timeslice
         * @param {Number=} submissionid
         * @param {Boolean=} includeHistoryId
         * @returns {string}
         */
        createUrl: function (assetId, assetMainType, viewMode, publicUrl, timeslice, submissionId, includeHistoryId) {
            let url = this.createBaseUrl(assetId, assetMainType, viewMode, publicUrl, timeslice, submissionId);

            if (includeHistoryId && $routeParams.historyId) {
                url += `?historyId=${$routeParams.historyId}`;
            }

            return url;
        },

        createHashUrl: function (assetId, assetMainType, viewMode, publicUrl, timeslice, submissionId, includeHistoryId) {
            return `#${this.createUrl(assetId, assetMainType, viewMode, publicUrl, timeslice, submissionId, includeHistoryId)}`;
        },

        setNavId: function (url) {
            if ($routeParams.navId) {
                const joiningChar = !/\?/.test(url) ? "?" : "&";
                url += `${joiningChar}navId=${$routeParams.navId}`;
            }

            return url;
        },

        isAsset: function (url) {
            return !!(getParameterByName(url, "id") && getParameterByName(url, "mainType"));
        },

        isViewer: function (url) {
            return /\/viewer\//.test(url);
        },

        isPublic: function (url) {
            return /\/public\//.test(url);
        },

        isSupportedRoute: function (url) {
            if (url[0] !== "#" && url.indexOf($window.location.origin) === -1) {
                return false;
            }

            return pathsRegex.some((pathRegex) => pathRegex.test(url));
        },

        createStoreRedirectUrl: function (path) {
            return this.checkIfAssetFromPath(path) ? "/store?type=asset" : "/store?type=resource";
        },

        checkIfAssetFromPath: function (path) {
            return !/\/builder\/(template|workbook)\//.test(path);
        },

        checkIfOnBuilderForAsset: function (assetId, mainType) {
            var typePath = fixMainType(mainType);

            if (typePath === "viewer") {
                return false;
            }

            return new RegExp("/" + typePath + "/" + assetId).test($location.path());
        },

        removeHash: function (path) {
            var hashIndex = path.indexOf("#");
            return hashIndex !== -1 ? path.slice(hashIndex + 1) : path;
        },

        getTimesliceQueryString: function () {
            return $routeParams.timeslice ? "&timeslice=" + $routeParams.timeslice : "";
        },

        getTimesliceFromRoute: function () {
            return $routeParams.timeslice ? $routeParams.timeslice : "";
        },

        getParameterByName: getParameterByName,

        isUrl: function (value) {
            return /^http(s)?:\/\//.test(value);
        },

        convertRouteToFullUrl: function (route) {
            return `${location.origin}${location.pathname}${route}`;
        },

        getRouteFromFullUrl: function (url) {
            const hashIndex = url.indexOf("#");
            if (hashIndex === -1 || url.slice(0, hashIndex) !== `${location.origin}${location.pathname}`) {
                return "";
            }

            return url.slice(hashIndex);
        }
    };
}
