function redactGuid(str) {
    const regex = /[0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12}/gi;
    let filteredStr = str.replace(regex, "<FILTERED_GUID>");
    return filteredStr.replace(/("id"\s*:\s*")[0-9a-f\-]+[\.]{3}/gi, "$1<FILTERED_GUID>...");
}

function redactAssetId(str) {
    const regex = /(\W)([ybndrfg8WjkmcpqxMtHRwGszZ345h769]{26})(\W|$)/gi;
    let filteredStr = str.replace(regex, "$1<FILTERED_ASSET_ID>$3");
    return filteredStr.replace(/(assetId"\s*:\s*")[ybndrfg8WjkmcpqxMtHRwGszZ345h769]+[\.]{3}/gi, "$1<FILTERED_ASSET_ID>...");
}

export function redactIdsFromString(str) {
    const filteredStr = redactGuid(str);
    return redactAssetId(filteredStr);
}
