import { finalise, from, share } from "@pjs/observables";
import { noop } from "@pjs/utilities";

/**
 * @template T
 * @param { AbortablePromiseLike } abortablePromiseLike
 * @param { () => void } [onAbort]
 * @return { Observable<T> }
 */
export function from$Promise(abortablePromiseLike, onAbort = noop) {
    return from(abortablePromiseLike).pipe(
        finalise(() => {
            if (abortablePromiseLike.abort !== undefined) {
                abortablePromiseLike.abort();
                onAbort();
            }
        }),
        share()
    );
}
