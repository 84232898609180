import { angularAMD } from "@pebblepad/amd";
import "../services/spaHistoryService";
import "../../../utilities/baseUrlsFactory";
import "../../../assetStore/services/useResourceService";
import "../../../utilities/urlService";
import "../../../history/services/historyService";
import "../../../multiLanguageService/multiLanguageService";
import "../../../screenReaderAnnouncer/screenReaderAnnouncer.directive";
import "../../../assetListItem/assetListItem.directive";
import template from "../views/spa-history.html";

angularAMD.directive("spaHistory", [
    "$timeout",
    "$sce",
    "SpaHistoryService",
    "baseUrlsFactory",
    "$rootScope",
    "$routeParams",
    "$window",
    "$location",
    "useResourceService",
    "urlService",
    "historyService",
    "multiLanguageService",
    function ($timeout, $sce, SpaHistoryService, baseUrlsFactory, $rootScope, $routeParams, $window, $location, useResourceService, urlService, historyService, multiLanguageService) {
        return {
            restrict: "E",
            template: template,
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.historyList = [];
                    $scope.routeParams = $routeParams;

                    $scope.setupHistory = function () {
                        $scope.history_panel_el = angular.element($element[0].getElementsByClassName("spa-history__tab-trap")[0]);
                        $scope.spaAppContainer = document.getElementById("app-container");
                        $scope.spaMenuWrapper = document.getElementById("spa-menu-wrapper");

                        if ($scope.initiatingButton === "dash") {
                            $scope.history_panel_button = document.getElementById("dashboard-history-button");
                        } else if ($scope.initiatingButton === "spa") {
                            $scope.history_panel_button = document.getElementById("spa-history-btn");
                        }

                        bindEscKey();

                        SpaHistoryService.getHistory(Date.now()).then(function (response) {
                            setHistoryDataOnScope(response.data);
                            autoFocusOnFirstResult();
                        });
                    };

                    $scope.getMore = function () {
                        $scope.loading = true;
                        var nextItemIndex = $scope.historyList.length;
                        SpaHistoryService.getHistory($scope.getDateOfLastItem()).then(function (response) {
                            setHistoryDataOnScope(response.data);
                            autoFocusOnNextResult(nextItemIndex);
                        });
                    };

                    $scope.historyBtnClicked = function () {
                        if ($scope.historyList.length === 0) {
                            $scope.setupHistory();
                        }
                    };

                    $scope.loadMore = function () {
                        if (!$scope.loading) {
                            $scope.showHistorySpinner = true;
                            $scope.getMore();
                        }
                    };

                    // a singleton signal r gets the message on the rootscope and broadcasts down to here
                    $rootScope.$on("refreshHistorySection", function () {
                        $scope.setupHistory();
                    });

                    $scope.getDateOfLastItem = function () {
                        return $scope.earliestDateLoaded;
                    };

                    $scope.closeHistoryPanel = function () {
                        closeHistoryPanel();
                    };

                    $scope.$on("$routeChangeStart", removeEventListeners);
                    $scope.$on("spa-action-menu-collapse", closeHistoryPanel);
                    $element.on("$destroy", function () {
                        removeEventListeners();
                        $scope.$destroy();
                    });

                    function bindEscKey() {
                        document.addEventListener("keydown", escKeyPressed, true);
                    }

                    function unbindEscKey() {
                        document.removeEventListener("keydown", escKeyPressed, true);
                    }

                    function escKeyPressed(e) {
                        if (e.keyCode === 27) {
                            e.stopPropagation();
                            closeHistoryPanel();
                        }
                    }

                    function closeHistoryPanel(e) {
                        SpaHistoryService.cancel();
                        removeEventListeners();

                        $scope.history_panel_button.focus();

                        if (!e) {
                            $scope.$emit("spaMenuClosePanels");
                            $scope.$destroy();
                        }
                    }

                    function removeEventListeners() {
                        unbindEscKey();
                    }

                    function autoFocusOnFirstResult() {
                        $timeout(function () {
                            var firstResult = angular.element($element[0].getElementsByClassName("asset-list-item__title")[0]);
                            if (firstResult[0]) {
                                firstResult[0].focus();
                            }
                        });
                    }

                    function autoFocusOnNextResult(nextResultIndex) {
                        $timeout(function () {
                            var allLinks = angular.element($element[0].getElementsByClassName("asset-list-item__title"));
                            allLinks[nextResultIndex].focus();
                        });
                    }

                    function setHistoryDataOnScope(historyList) {
                        historyList.Items = flatPackHistoryItems(historyList.Items);
                        $scope.historyList = $scope.historyList.concat(historyList.Items);

                        $scope.loading = false;
                        $scope.noMoreItems = historyList.NoMoreItems;
                        $scope.noMoreItemsText = historyList.NoMoreItemsText;
                        $scope.showMore = !historyList.noMoreItems;

                        if (!$scope.noMoreItems) {
                            $scope.showNoMore = false;
                        } else {
                            $scope.showNoMore = true;
                        }
                        $scope.showHistorySpinner = false;
                    }

                    function flatPackHistoryItems(oldHistoryList) {
                        var newHistoryList = [];

                        for (var i = 0; i < oldHistoryList.length; i++) {
                            newHistoryList = newHistoryList.concat(oldHistoryList[i].Children);

                            if (oldHistoryList.length - 1 === i) {
                                $scope.earliestDateLoaded = oldHistoryList[i].UtcDayBeforeThisDate;
                            }
                        }

                        return newHistoryList;
                    }
                }
            ],

            link: function (scope, element, attrs) {
                scope.showNoMore = false;
                scope.showHistorySpinner = true;

                var resourceDict = {
                    form: "templateBuilder",
                    workbook: "workbookBuilder"
                };
                scope.redirectURL = function (path, targetType) {
                    if (path) {
                        if (targetType === "_blank") {
                            return path;
                        } else {
                            if (urlService.isAsset(path)) {
                                //Local Asset / Resource
                                var match = path.match(/maintype=(form|workbook)(&|$)/i);
                                if (match !== null) {
                                    path = path.replace(/(maintype=)(form|workbook)/i, "$1" + resourceDict[match[1].toLowerCase()]);
                                }
                                return urlService.configureAssetUrl(path, null);
                            } else {
                                //Relative Path
                                return path;
                            }
                        }
                    }
                };

                scope.setupHistory();
            }
        };
    }
]);
