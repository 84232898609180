import { angularAMD } from "@pebblepad/amd";
import { LegacyLinkerModalSettings } from "./legacyLinkerModalSettings";
import "../../multiLanguageService/multiLanguageService";

class LegacyLinkerModalServiceProvider {
    constructor() {
        this.config = {
            enableAssetLinking: true,
            enableResourceLinking: true
        };

        this.$get = [
            "multiLanguageService",
            (multiLanguageService) => {
                return new LegacyLinkerModalSettings(this.config, multiLanguageService);
            }
        ];
    }
}

angularAMD.provider("legacyLinkerModalService", LegacyLinkerModalServiceProvider);
export { LegacyLinkerModalServiceProvider as legacyLinkerModalService };
