import { angularAMD } from "@pebblepad/amd";
import "../../loadingSpinner/loadingSpinner";
import "../../builder/dataManager/helperService";
import "../autoComplete";
import "../../externalContact/externalContact";
import "../../extendSearch/directives/extendSearch";
import "../../extendSearch/services/searchService";
import "../../extensions/arrayExtensions";
import template from "./contact-auto-complete.html";

angularAMD.directive("contactAutoComplete", [
    "$rootScope",
    "$timeout",
    "helperService",
    "searchService",
    function ($rootScope, $timeout, helperService, searchService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                selectedUsers: "=selectedUsers",
                sharees: "=sharees",
                selectedContacts: "=selectedContacts",
                inputTitle: "@inputTitle",
                autoFocus: "=",
                describedBy: "@"
            },
            link: function (scope, element, attrs) {
                searchService.getContacts().then(function (response) {
                    scope.userContacts = response.data;
                });

                scope.auto_complete_broadcast_id = helperService.guid();
                scope.multiLanguageService = $rootScope.multiLanguageService;

                scope.$on(scope.auto_complete_broadcast_id + "autoCompleteItemSelected", function (event, data) {
                    data.canRemoveFromShare = true;
                    scope.pushContact(data);
                    scope.focusAutoCompleteInput();
                });

                scope.pushContact = function (data) {
                    if (scope.selectedContacts.indexOf(data.ContactId) === -1) {
                        scope.sharees.push(data);
                        scope.selectedContacts.push(data.ContactId);
                    }
                };

                scope.removeUser = function (index) {
                    var user = scope.sharees[index];
                    if (user && user.ContactId !== null && user.ContactId !== undefined) {
                        scope.removeContact(user, index);
                        return;
                    }

                    var userId = scope.sharees[index].UserId;
                    var selectedUserIndex = scope.selectedUsers.findIndex(function (item) {
                        return item.Id === userId;
                    });

                    if (selectedUserIndex !== -1) {
                        scope.selectedUsers.splice(selectedUserIndex, 1);
                    }
                    scope.sharees.splice(index, 1);
                    scope.$broadcast("focusInput");
                };

                scope.removeContact = function (user, index) {
                    const contactId = scope.sharees[index].ContactId;
                    scope.selectedContacts.remove(contactId);
                    scope.sharees.splice(index, 1);
                };

                ////////////////////////////////////////////////////
                //Listener for opening the add external contact form
                scope.$on("showAddContactForm", function (event, data) {
                    // tslint:disable-next-line:triple-equals
                    if (data != null) {
                        scope.addExternalContactData = data;
                    }
                    scope.showAddContactForm();
                });

                ////////////////////////////////////////////////////
                //Listener for closing the add external contact form
                scope.$on("closeExternalForm", function (event) {
                    scope.showAddContact = false;
                });

                scope.showAddContactForm = function () {
                    scope.showAddContact = true;
                    scope.showExtendSearch = false;

                    $timeout(() => {
                        element[0].scrollIntoView();
                        document.getElementById("externalContactFirstName").focus();
                    });
                };

                /////////////////////////////////////////////
                //Listener for opening the extend search form
                scope.$on("showExtendSearchForm", function (event, data, input) {
                    scope.showExtendSearchForm(input);
                });

                /////////////////////////////////////////////
                //Listener for closing the extend search form
                scope.$on("closeExtendSearchForm", function (event, data) {
                    scope.showExtendSearch = false;
                });

                scope.showExtendSearchForm = function (input) {
                    scope.extendSearchData = {
                        firstName: "",
                        secondName: "",
                        email: ""
                    };

                    const trimmedInput = input.trim().replace(/\s{2,}/g, "");
                    const spaceMatch = trimmedInput.match(/^([^\s]+)\s(.+?)$/);

                    if (spaceMatch !== null) {
                        scope.extendSearchData.firstName = spaceMatch[1];
                        scope.extendSearchData.secondName = spaceMatch[2];
                    } else if (trimmedInput.includes("@")) {
                        scope.extendSearchData.email = trimmedInput;
                    } else {
                        scope.extendSearchData.firstName = trimmedInput;
                    }

                    scope.showExtendSearch = true;
                    scope.showAddContact = false;
                };

                scope.$on("newContactAdded", function (event, data) {
                    if (!scope.sharees.contains(data) && !scope.selectedContacts.contains(data.ContactId)) {
                        data.canRemoveFromShare = true;
                        scope.sharees.push(data);
                        scope.selectedContacts.push(data.ContactId || data.UserId);
                    }

                    scope.focusAutoCompleteInput();
                });

                scope.actions = [
                    {
                        Title: scope.multiLanguageService.getString("extend_search.headers.search"),
                        ActionFunction: "showExtendSearchForm",
                        IconUrl: "icon-extend-search"
                    }
                ];

                scope.$watch("showExtendSearch", function (showExtendSearch) {
                    // tslint:disable-next-line:triple-equals
                    if (showExtendSearch == true) {
                        $timeout(function () {
                            element[0].scrollIntoView();
                            document.getElementById("extendSearchFirstName").focus();
                        });
                    }
                });

                scope.focusAutoCompleteInput = function () {
                    const autoCompleteInput = element[0].querySelector("[data-hook='auto-complete-input']");
                    if (autoCompleteInput !== null) {
                        autoCompleteInput.focus();
                    }
                };
            }
        };
    }
]);
