import { SandboxedTagMutation } from "@pjs/security";
import { commonInlineTagNames } from "../CommonInlineTagNames.const";

export class DivCleanupMutation extends SandboxedTagMutation<HTMLDivElement> {
    protected _mutate(sandboxedDiv: HTMLDivElement, sandboxedDocument: Document): boolean {
        if (sandboxedDiv.childNodes.length === 0) {
            return false;
        }

        const parent = sandboxedDiv.parentNode;
        if (parent === null) {
            return true;
        }

        const sibling = sandboxedDiv.nextSibling;

        let child = sandboxedDiv.firstChild;
        let inlineContainer = null;

        while (child !== null) {
            if (child.nodeType === Node.TEXT_NODE || commonInlineTagNames.includes(child.nodeName)) {
                if (inlineContainer === null) {
                    inlineContainer = sandboxedDocument.createDocumentFragment();
                }

                inlineContainer.appendChild(child);
            } else {
                if (inlineContainer !== null) {
                    const p = this._createParagraphReplacement(sandboxedDiv, sandboxedDocument);
                    p.appendChild(inlineContainer);
                    parent.insertBefore(p, sibling);

                    inlineContainer = null;
                }

                parent.insertBefore(child, sibling);
            }

            child = sandboxedDiv.firstChild;
        }

        if (inlineContainer !== null) {
            const p = this._createParagraphReplacement(sandboxedDiv, sandboxedDocument);
            p.appendChild(inlineContainer);
            parent.insertBefore(p, sibling);

            inlineContainer = null;
        }

        return false;
    }

    private _createParagraphReplacement(sandboxedDiv: HTMLDivElement, sandboxedDocument: Document): HTMLParagraphElement {
        const p = sandboxedDocument.createElement("p");
        const attributes = sandboxedDiv.getAttributeNames();

        for (const attribute of attributes) {
            p.setAttribute(attribute, sandboxedDiv.getAttribute(attribute) as string);
        }

        return p;
    }
}
