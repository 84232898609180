import { IHtmlTagTransform } from "./interfaces/IHtmlTagTransform";

/**
 * @description Only to be used in conjunction with a HtmlStringParser and IHtmlStringParserConfig.
 */
export abstract class SandboxedTagMutation<T extends Element = Element> implements IHtmlTagTransform {
    public transform(sandboxedElement: Element): Element | null {
        const keepElement = this._mutate(sandboxedElement as T, sandboxedElement.ownerDocument);
        return keepElement ? sandboxedElement : null;
    }

    /**
     * @description Allow any mutation on the given element before full sanitisation. If `false` is returned the element will be discarded.
     */
    protected abstract _mutate(sandboxedElement: T, sandboxedDocument: Document): boolean;
}
