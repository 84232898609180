import { ASSET_CONSTANTS } from "../constants/asset.constants";
import { mapAssetToPortfolioPageModel } from "../assetPageHandler/mapAssetToPageModel.function";

/**
 * @param {object} dataManagerService
 * @param {object} multiLanguageService
 * @returns {PromiseLike<{ assetId: string, manager: object, pageId: string}>}
 */
export function createPortfolio(dataManagerService, multiLanguageService) {
    return dataManagerService
        .init({
            AssetId: null,
            Asset: { AddDefaultBannerSection: true, MainType: ASSET_CONSTANTS.TYPES.WEBFOLIO_PAGE }
        })
        .then(() => {
            const pageManager = dataManagerService.pageManagerObject;
            const pageModel = mapAssetToPortfolioPageModel(pageManager.data, pageManager.AssetId);
            pageModel.PageTitle = multiLanguageService.getString("labels.navigation.new_page");

            return dataManagerService.init({ AssetId: null, Asset: { MainType: ASSET_CONSTANTS.TYPES.WEBFOLIO, Pages: [pageModel] } }).then(() => {
                const manager = dataManagerService.webfolioManagerObject;
                return { assetId: manager.AssetId, manager: manager, pageId: pageModel.PageId };
            });
        });
}
