import { Immutable } from "@pjs/utilities";
import { RefObject } from "react";
import { FloatingMenu } from "../FloatingMenu";
import { MenuAriaModel } from "../../menu-event-adapter/types/MenuAriaModel";
import { IMenuEventModelHandlers } from "../../menu-event-adapter/interfaces/IMenuEventModelHandlers";
import { dropdownMatchedWidthPositioningFactory } from "../../../../floating-positioner/factories/DropdownMatchedWidthPositioningFactory";
import { MenuEventAdapter } from "../../menu-event-adapter/MenuEventAdapter";
import { selectClickedItemAndSetClosed } from "../../reducers/SelectClickedItemAndSetClosed.function";
import { Keys } from "../../../../../enums/Keys";
import { selectPreviousItem } from "../../reducers/SelectPreviousItem.function";
import { selectNextItem } from "../../reducers/SelectNextItem.function";
import { selectLastItem } from "../../reducers/SelectLastItem.function";
import { selectFirstItem } from "../../reducers/SelectFirstItem.function";
import { setClosed } from "../../reducers/SetClosed.function";
import { createSelectMatchedItem } from "../../reducers/SelectMatchedItem.function";
import { closeIfFocusIsOutside } from "../../reducers/CloseIfFocusIsOutside.function";
import { setOpened } from "../../reducers/SetOpened.function";
import { toggleIsOpenOnClick } from "../../reducers/ToggleIsOpenOnClick.function";
import { IMenuModelAdapterConfig } from "../../menu-event-adapter/interfaces/IMenuModelAdapterConfig";
import { toggleIsOpen } from "../../reducers/ToggleIsOpen.function";

export function floatingListBoxFactory<T>(config: {
    matchItemOnKeys: (item: T, currentKeys: string) => boolean;
    initialModel: Immutable<MenuAriaModel>;
    onModelChange: (model: MenuAriaModel) => void;
    triggerRef: RefObject<HTMLElement>;
    floatingRef: RefObject<HTMLElement>;
    boundaryRef: RefObject<HTMLElement>;
}): FloatingMenu<MenuAriaModel, IMenuEventModelHandlers<T>> {
    const eventAdapterPreset: IMenuModelAdapterConfig<T> = {
        item: {
            onClick: selectClickedItemAndSetClosed
        },
        target: {
            [Keys.ArrowUp]: selectPreviousItem,
            [Keys.ArrowDown]: selectNextItem,
            [Keys.End]: selectLastItem,
            [Keys.Home]: selectFirstItem,
            [Keys.Enter]: setClosed,
            [Keys.Escape]: setClosed,
            [Keys.Space]: setClosed,
            default: createSelectMatchedItem(config.matchItemOnKeys),
            onFocusLoss: closeIfFocusIsOutside
        },
        trigger: {
            [Keys.ArrowUp]: setOpened,
            [Keys.ArrowDown]: setOpened,
            [Keys.Enter]: toggleIsOpen,
            [Keys.Space]: toggleIsOpen,
            onClick: toggleIsOpenOnClick
        }
    };

    return new FloatingMenu<MenuAriaModel, IMenuEventModelHandlers<T>>(config.triggerRef, config.floatingRef, config.boundaryRef, {
        ariaEventAdapter: new MenuEventAdapter(eventAdapterPreset, config.triggerRef, config.floatingRef, config.initialModel),
        floatingPositionerConfig: {
            middlewareFactory: dropdownMatchedWidthPositioningFactory,
            placement: "bottom-end"
        },
        onFirstPosition: () => {
            if (config.floatingRef.current !== null) {
                config.floatingRef.current.focus();
            }
        },
        onModelChange: config.onModelChange
    });
}
