import { ckEditorModule } from "../pebbleCkEditor";

ckEditorModule.controller("InlineCkEditorController", InlineCkEditorController);
InlineCkEditorController.$inject = ["$scope", "$element", "$attrs", "CkEditorBuilder", "CKEDITOR_EVENTS", "CkEditorSelectionHelper"];

function InlineCkEditorController($scope, $element, $attrs, ckEditorBuilder, CKEDITOR_EVENTS, ckEditorSelectionHelper) {
    this.constants = {
        CKEDITOR_EVENTS: CKEDITOR_EVENTS,
        UPDATE_EVENTS: {
            INPUT: "input",
            BLUR: "blur",
            CUT: "cut"
        }
    };
    this.ckEditorBuilder = ckEditorBuilder;
    this.ckEditorSelectionHelper = ckEditorSelectionHelper;

    this.eventHandlers = {
        onReady: this.onReady.bind(this),
        onReadyWithSelection: this.onReadyWithSelection.bind(this),
        onChange: this.onEditorChange.bind(this),
        onPaste: this.onPaste.bind(this),
        onBlur: this.onBlur.bind(this),
        modelUpdate: this.setEditorData.bind(this)
    };

    this.pasteHandlers = [];
    this.blurHandlers = [this.resetPlaceholder.bind(this)];
    this.bookmark = null;
    this.element = $element;
    this.onUpdate = null;
    this.editor = null;
    this.config = null;
    this._originalAriaLabel = null;
    $scope.$on("$destroy", this.onDestroy.bind(this));
}

InlineCkEditorController.prototype.setConfig = function (config) {
    this.config = config;
};

InlineCkEditorController.prototype.setupEditor = function () {
    //Remove the previous editor (if one exists)
    this._originalAriaLabel = this.element[0].getAttribute("aria-label");
    this.destroyEditor();
    //If no config is provided, CKEDITOR will inherit the default config from the config block of the App or CkEditorModule (CkEditorServiceProvider).
    this.editor = this.ckEditorBuilder.createInlineEditor(this.element[0], this.config);
    //Bind event handlers
    this.editor.on(this.constants.CKEDITOR_EVENTS.READY, this.eventHandlers.onReady);
    this.editor.on(this.constants.CKEDITOR_EVENTS.CHANGE, this.eventHandlers.onChange);
    this.editor.on(this.constants.CKEDITOR_EVENTS.PASTE, this.eventHandlers.onPaste);
    this.editor.on(this.constants.CKEDITOR_EVENTS.BLUR, this.eventHandlers.onBlur);

    this.element.bind(this.constants.UPDATE_EVENTS.INPUT, this.eventHandlers.onChange);
    this.element.bind(this.constants.UPDATE_EVENTS.BLUR, this.eventHandlers.onChange);
    this.element.bind(this.constants.UPDATE_EVENTS.CUT, this.eventHandlers.onChange);
};

InlineCkEditorController.prototype.onReady = function () {
    this.resetPlaceholder();
    this._resetAriaLabel();
    this.editor.removeListener(this.constants.CKEDITOR_EVENTS.READY, this.eventHandlers.onReady);
};

InlineCkEditorController.prototype.onEditorChange = function (e) {
    if (this.onUpdate) {
        this.onUpdate(this.editor.getData());
    }
};

InlineCkEditorController.prototype.onPaste = function (e) {
    for (var i = 0, l = this.pasteHandlers.length; i < l; i++) {
        this.pasteHandlers[i](e);
    }
};

InlineCkEditorController.prototype.onBlur = function (e) {
    for (var i = 0, l = this.blurHandlers.length; i < l; i++) {
        this.blurHandlers[i](e);
    }
};

InlineCkEditorController.prototype.onReadyWithSelection = function () {
    if (this.bookmark && this.bookmark.start) {
        this.ckEditorSelectionHelper.restoreSelectionFromNativeBookmarks(this.editor, this.bookmark);
    }
};

InlineCkEditorController.prototype.setEditorData = function (data) {
    if (this.editor && data !== this.editor.getData()) {
        this.editor.setData(data);
    }
};

InlineCkEditorController.prototype.resetPlaceholder = function () {
    if (this.editor.getData() === "" && this.element[0].getAttribute("placeholder")) {
        var firstChild = this.editor.element.getFirst();
        if (firstChild) {
            firstChild.remove();
        }
    }
};

InlineCkEditorController.prototype.destroyEditor = function () {
    // tslint:disable-next-line:triple-equals
    if (this.editor != null) {
        //Remove any event listeners
        this.editor.removeListener(this.constants.CKEDITOR_EVENTS.READY, this.eventHandlers.onReady);
        this.editor.removeListener(this.constants.CKEDITOR_EVENTS.READY, this.eventHandlers.onReadyWithSelection);
        this.editor.removeListener(this.constants.CKEDITOR_EVENTS.CHANGE, this.eventHandlers.onChange);
        this.editor.removeListener(this.constants.CKEDITOR_EVENTS.PASTE, this.eventHandlers.onPaste);
        this.editor.removeListener(this.constants.CKEDITOR_EVENTS.BLUR, this.eventHandlers.onBlur);

        this.element.unbind(this.constants.UPDATE_EVENTS.INPUT, this.eventHandlers.onChange);
        this.element.unbind(this.constants.UPDATE_EVENTS.BLUR, this.eventHandlers.onChange);
        this.element.unbind(this.constants.UPDATE_EVENTS.CUT, this.eventHandlers.onChange);
        //Destroy the editor
        this.editor.destroy(true);
        this.editor = null;
    }
};

InlineCkEditorController.prototype.onDestroy = function () {
    this.destroyEditor();
    this.onUpdate = null;
};

InlineCkEditorController.prototype._resetAriaLabel = function () {
    if (this._originalAriaLabel !== null && this._originalAriaLabel !== "") {
        this.element[0].setAttribute("aria-label", this._originalAriaLabel);
    }
};
