import { angularAMD } from "@pebblepad/amd";
import { HTML_INPUT } from "../../constants/htmlInput.constants";
import "../../ckEditorModule/pebbleCkEditor";
import "../../multiLanguageService/multiLanguageService";
import "../../copyPaste/selection.service";
import "../../copyPaste/nodeAttributeModifier.service";
import "../../copyPaste/paste.service";
import "../../colourPicker/colourPicker.service";
import "../../ckEditorPluginServices/Linker/ckEditorLinkerPlugin.service";
import "../../ckEditorPluginServices/paragraphIndent/ckEditorParagraphIndentPlugin";
import "../../ckEditorPluginServices/FeedbackStatements/ckEditorFeedbackStatementPlugin.service";

angularAMD.service("InlineCkEditorHelper", InlineCkEditorHelper);
InlineCkEditorHelper.$inject = [
    "baseUrlsFactory",
    "multiLanguageService",
    "SelectionService",
    "NodeAttributeModifier",
    "PasteService",
    "CkEditorService",
    "ColourPickerService",
    "CkEditorLinkerPluginService",
    "CkEditorParagraphIndentPlugin",
    "ckEditorFeedbackStatementPluginService"
];

function InlineCkEditorHelper(
    baseUrlsFactory,
    multiLanguageService,
    selectionService,
    nodeAttributeModifier,
    pasteService,
    ckEditorService,
    colourPickerService,
    CkEditorLinkerPluginService,
    CkEditorParagraphIndentPlugin
) {
    var bookmarkDomInfo = selectionService.getBookmarkDomInfo();

    this.multiLanguageService = multiLanguageService;
    this.baseUrlsFactory = baseUrlsFactory;
    this.ckEditor = ckEditorService;
    this.pasteService = pasteService;
    this.nodeAttributeModifier = nodeAttributeModifier;

    this.colourButtonsPerRow = 7;
    this.availableColours = this.getAvailableColours(colourPickerService);
    this.formattingStyles = this.getFormattingStyles();

    var whitelistedAttributes = pasteService.defaultSanitizeConfig.ALLOWED_ATTR.concat([bookmarkDomInfo.data, bookmarkDomInfo.dataEnd]).join(",");
    var whitelistedStyles = HTML_INPUT.WYSIWYG_STYLES_WHITELIST.join(",");

    this._singleLineConifg = {
        ckEditorAttributes: whitelistedAttributes,
        ckEditorExcludeButtons: this.ckEditor.config.removeButtons + ",NumberedList,Outdent,Language,Indent,BulletedList,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,paragraphIndent",
        ckEditorStyles: whitelistedStyles,
        ckEditorTags: HTML_INPUT.INLINE_TAGS_WHITELIST.concat([bookmarkDomInfo.tagName]).join(" "),
        tags: HTML_INPUT.INLINE_TAGS_WHITELIST
    };

    this._multiLineConfig = {
        ckEditorAttributes: whitelistedAttributes,
        ckEditorExcludeButtons: this.ckEditor.config.removeButtons,
        ckEditorStyles: whitelistedStyles,
        ckEditorTags: pasteService.defaultSanitizeConfig.ALLOWED_TAGS.concat([bookmarkDomInfo.tagName]).join(" "),
        tags: pasteService.defaultSanitizeConfig.ALLOWED_TAGS
    };
}

InlineCkEditorHelper.prototype.getWhitelistedStyles = function (nodeAttributeModifier) {
    return nodeAttributeModifier.getStyleWhitelist().join(",");
};

InlineCkEditorHelper.prototype.getAvailableColours = function (colourPicker) {
    return colourPicker.getColoursAsStringList(false, this.colourButtonsPerRow, "");
};

InlineCkEditorHelper.prototype.getFormattingStyles = function () {
    return [
        { name: this.multiLanguageService.getString("ckeditor.text_format_options.header"), element: "h2" },
        { name: this.multiLanguageService.getString("ckeditor.text_format_options.subheader"), element: "h4" },
        { name: this.multiLanguageService.getString("ckeditor.text_format_options.normal"), element: "p" },
        { name: this.multiLanguageService.getString("ckeditor.text_format_options.hint"), element: "small" }
    ];
};

InlineCkEditorHelper.prototype.createEditorConfig = function (toolbarHolder, singleLine, language) {
    var config = singleLine ? this._singleLineConifg : this._multiLineConfig;

    return {
        extraAllowedContent: config.ckEditorTags + "{" + config.ckEditorStyles + "}" + "[" + config.ckEditorAttributes + "]",
        skin: "pebble-skin," + this.baseUrlsFactory.shared_component_base_url + "ckEditorModule/styles/editor/",
        colorButton_colors: this.availableColours,
        colorButton_colorsPerRow: this.colourButtonsPerRow,
        removePlugins: "colordialog,floatingspace,link,unlink",
        extraPlugins: "sharedspace,linker,paragraphIndent",
        sharedSpaces: {
            top: toolbarHolder
        },
        title: this.multiLanguageService.getString("builder.page.inlineTextEditor.inline_text_editor"),
        pasteFilter: null,
        language: language !== undefined ? language : this.multiLanguageService.getLanguage(),
        removeButtons: config.ckEditorExcludeButtons,
        stylesSet: this.formattingStyles
    };
};

/**
 *
 * @param config {object=}
 * @return {Function}
 */
InlineCkEditorHelper.prototype.createPasteHandler = function (config) {
    var pasteHandler = new this.pasteService(Object.assign({ target: null }, config));

    pasteHandler.attributeModifiers = pasteHandler.attributeModifiers.concat([
        this.nodeAttributeModifier.removeEmptyBackgroundColours,
        this.nodeAttributeModifier.removeListStyles,
        this.nodeAttributeModifier.removeRelativeFontSizes
    ]);

    return function (e) {
        if (e.data.type === "html") {
            e.data.dataValue = pasteHandler.sanitizeData(e.data.dataValue);
        }
    };
};

InlineCkEditorHelper.prototype.createSingleLinePasteHandler = function () {
    var config = {
        sanitizeConfig: {
            ALLOWED_TAGS: HTML_INPUT.INLINE_TAGS_WHITELIST
        },
        attributeModifiers: [this.nodeAttributeModifier.removeLeftIndent]
    };

    return this.createPasteHandler(config);
};

InlineCkEditorHelper.prototype.createMultiLinePasteHandler = function () {
    var config = {
        attributeModifiers: [this.nodeAttributeModifier.limitLeftIndent]
    };
    return this.createPasteHandler(config);
};
