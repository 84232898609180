import { ITimedAnimation } from "./interfaces/ITimedAnimation";
import { EasingFunction } from "./types/easingFunction.type";

export class TimedAnimation implements ITimedAnimation {
    private _requestId: number;
    private readonly _duration: number;
    private readonly _easingFunction: EasingFunction;

    private _startTime: DOMHighResTimeStamp;
    private _onFrame: ((easedProgress: number, progress: number) => void) | null;

    constructor(duration: number, easingFunction: EasingFunction) {
        this._duration = duration;
        this._easingFunction = easingFunction;

        this._requestId = -1;
        this._startTime = -1;

        this._onFrame = null;
    }

    public start(onFrame: (easedProgress: number, progress: number) => void): void {
        if (this.isAnimating()) {
            this.stop();
        }

        this._onFrame = onFrame;

        // Fix: Chrome's elapsed time / current frame time can be behind start time
        // performance.now() before a requestAnimationFrame
        // Link to Chrome Bug Issue https://bugs.chromium.org/p/chromium/issues/detail?id=652680&q=performance.now
        this._requestId = requestAnimationFrame((currentTime) => {
            this._startTime = currentTime;
            this._requestId = requestAnimationFrame(this._frameUpdate.bind(this));
        });
    }

    public stop(): void {
        cancelAnimationFrame(this._requestId);

        this._requestId = -1;
    }

    public isAnimating(): boolean {
        return this._requestId !== -1;
    }

    private _frameUpdate(currentFrameTime: DOMHighResTimeStamp): void {
        const progress = Math.min((currentFrameTime - this._startTime) / this._duration, 1);
        const easedProgress = this._easingFunction(progress);

        if (this._onFrame !== null) {
            this._onFrame(easedProgress, progress);
        }

        if (progress < 1) {
            this._requestId = requestAnimationFrame(this._frameUpdate.bind(this));
        } else {
            this._requestId = -1;
        }
    }
}
