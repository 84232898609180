import { INumberFormatter } from "./interfaces/INumberFormatter";

export const invalidNumberFormatter: INumberFormatter = {
    formatNumber: (): string => {
        throw new Error("Invalid NumberFormatter, language not configured!");
    },
    parseNumber: (): number => {
        throw new Error("Invalid NumberFormatter, language not configured!");
    }
};
