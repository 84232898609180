import { angularAMD } from "@pebblepad/amd";
import "./commentsService";
import "../../../builder/dataManager/helperService";
import "../../../user/user.service";
import "../../../multiLanguageService/multiLanguageService";

angularAMD.factory("commentsDataModel", [
    "$sce",
    "commentsService",
    "helperService",
    "multiLanguageService",
    "User",
    function ($sce, commentsService, helperService, multiLanguageService, User) {
        return {
            data: {},

            getComments: function (assetId, submissionId, anchorId) {
                return commentsService
                    .getComments(assetId, submissionId, anchorId)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.data = error.data;
                    });
            },

            deleteComment: function (commentDto, submissionId, anchorId) {
                return commentsService.deleteComment(commentDto.AssetId, submissionId, commentDto.Id, anchorId).then((response) => {
                    if (response.data !== null) {
                        response.data.Replies = commentDto.Replies; // endpoint doesn't get all children so keep them
                        return response.data;
                    }
                    for (let i = 0; i < this.data.Comments.length; i++) {
                        const comment = this.data.Comments[i];
                        if (comment.Id === commentDto.Id) {
                            this.data.Comments.splice(i, 1);
                        }
                        if (comment.Id === commentDto.ParentId) {
                            for (let j = 0; j < comment.Children.length; j++) {
                                const child = comment.Children[j];
                                if (child.Id === commentDto.Id) {
                                    comment.Children.splice(j, 1);
                                }
                            }
                        }
                    }
                    return null;
                });
            },

            addNewComment: function (body, author, parentId, assetId, anchorId, submissionId) {
                const commentDto = this._createInsertModel(body, parentId, author || User.getFullName(), assetId, anchorId);
                return commentsService.addNewOrUpdate(commentDto, submissionId, anchorId).then(() => {
                    return this._createFullModel(commentDto.Id, body, parentId, author || User.getFullName(), assetId, anchorId);
                });
            },

            updateComment: function (dto, id, anchorId, submissionId) {
                const commentDto = this._createUpdateModel(dto, id, anchorId);
                return commentsService.addNewOrUpdate(commentDto, submissionId);
            },

            _createUpdateModel: function (dto, parentId, anchorId) {
                return {
                    AssetId: dto.AssetId,
                    Id: dto.Id,
                    Body: dto.Body,
                    ParentId: parentId,
                    AnchorId: anchorId
                };
            },

            _createInsertModel: function (bodyText, parentId, posterName, assetId, anchorId) {
                return {
                    AnchorId: anchorId || "",
                    AssetId: assetId || "",
                    Body: bodyText ? $sce.trustAsHtml(bodyText).toString() : "",
                    Id: helperService.guid(),
                    ParentId: parentId ? parentId : "",
                    PosterName:
                        posterName !== null && posterName !== undefined && posterName !== ""
                            ? $sce.trustAsHtml(posterName).toString()
                            : multiLanguageService.getString("sidebar.asset_comments.anonymous"),
                    NewComment: true
                };
            },

            _createFullModel: function (id, bodyText, parentId, posterName, assetId, anchorId) {
                const userDto = User.getDto();
                const hasUser = userDto !== null;

                return {
                    AssetTitle: "",
                    Permissions: {
                        AllowEdit: hasUser,
                        AllowDelete: hasUser,
                        AllowReply: true
                    },
                    AnchorId: anchorId || "",
                    AssetId: assetId || "",
                    Attachments: [],
                    Blanked: false,
                    Comment: bodyText ? $sce.trustAsHtml(bodyText).toString() : "",
                    Replies: [],
                    Deleted: false,
                    Id: id || helperService.guid(),
                    LastModified: new Date(),
                    Posted: new Date().toString(),
                    ParentId: parentId ? parentId : "",
                    Poster: {
                        Name:
                            posterName !== null && posterName !== undefined && posterName !== ""
                                ? $sce.trustAsHtml(posterName).toString()
                                : multiLanguageService.getString("sidebar.asset_comments.anonymous"),
                        ProfilePicId: hasUser === true ? userDto.ProfilePic.slice(userDto.ProfilePic.indexOf("=") + 1) : ""
                    }
                };
            }
        };
    }
]);
