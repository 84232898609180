import { ACTION_CONSTANTS } from "../constants/action.constants";

class ObservableList {
    constructor(listArray, comparator = this._defaultComparator) {
        this._subscriptions = [];
        this.listArray = listArray;
        this._comparator = comparator;
    }

    subscribe(observer) {
        this._subscriptions.push(observer);
    }

    unsubscribe(observer) {
        for (let i = 0; i < this._subscriptions.length; i++) {
            if (this._subscriptions[i] === observer) {
                this._subscriptions.splice(i, 1);
                return;
            }
        }
    }

    add(...items) {
        this.listArray.push(...items);
        this._update({ action: ACTION_CONSTANTS.ADD, items: items });
    }

    remove(item) {
        for (let i = 0; i < this.listArray.length; i++) {
            if (this._comparator(this.listArray[i], item)) {
                this.listArray.splice(i, 1);
                this._update({ action: ACTION_CONSTANTS.DELETE, item: item });
                return true;
            }
        }
        return false;
    }

    replace(oldItem, newItem) {
        for (let i = 0; i < this.listArray.length; i++) {
            if (this._comparator(this.listArray[i], oldItem)) {
                this.listArray.splice(i, 1, newItem);
                this._update({
                    action: ACTION_CONSTANTS.EDIT,
                    oldItem: oldItem,
                    newItem: newItem
                });
                return true;
            }
        }
        return false;
    }

    merge(dataSet) {
        const newItems = dataSet.filter((item) => !this.listArray.some((listItem) => this._comparator(listItem, item)));
        this.add(...newItems);
    }

    _update(changes) {
        this._subscriptions.forEach((subscription) => {
            subscription(changes);
        });
    }

    _defaultComparator(a, b) {
        return a === b;
    }
}

export { ObservableList };
