/**
 * A class which will tell you if a particular user agent can accept the cookie `samesite=none` without issues.
 * See this [google announcement](https://blog.chromium.org/2019/10/developers-get-ready-for-new.html) for details.
 */
export class CookieSameSiteHelper {
    public static shouldSendSameSiteNone(useragent: string): boolean {
        return !CookieSameSiteHelper._isSameSiteNoneIncompatible(useragent);
    }

    private static _isSameSiteNoneIncompatible(useragent: string): boolean {
        return CookieSameSiteHelper._hasWebKitSameSiteBug(useragent) || CookieSameSiteHelper._dropsUnrecognizedSameSiteCookies(useragent);
    }

    private static _hasWebKitSameSiteBug(useragent: string): boolean {
        return (
            CookieSameSiteHelper._isIosVersion(12, useragent) ||
            (CookieSameSiteHelper._isMacosxVersion(10, 14, useragent) && (CookieSameSiteHelper._isSafari(useragent) || CookieSameSiteHelper._isMacEmbeddedBrowser(useragent)))
        );
    }

    private static _dropsUnrecognizedSameSiteCookies(useragent: string): boolean {
        if (CookieSameSiteHelper._isUcBrowser(useragent)) {
            return !CookieSameSiteHelper._isUcBrowserVersionAtLeast(12, 13, 2, useragent);
        }
        return CookieSameSiteHelper._isChromiumBased(useragent) && CookieSameSiteHelper._isChromiumVersionAtLeast(51, useragent) && !CookieSameSiteHelper._isChromiumVersionAtLeast(67, useragent);
    }

    private static _isIosVersion(major: number, useragent: string): boolean {
        const regex = /\(iP.+; CPU .*OS (\d+)[_\d]*.*\) AppleWebKit\//;
        const matches = useragent.match(regex) ?? ["", ""];
        return matches[1] === String(major);
    }

    private static _isMacosxVersion(major: number, minor: number, useragent: string): boolean {
        const regex = /\(Macintosh;.*Mac OS X (\d+)_(\d+)[_\d]*.*\) AppleWebKit\//;
        const matches = useragent.match(regex) ?? ["", "", ""];
        return matches[1] === String(major) && matches[2] === String(minor);
    }

    private static _isSafari(useragent: string): boolean {
        const safariRegex = /Version\/.* Safari\//;
        return safariRegex.test(useragent) && !CookieSameSiteHelper._isChromiumBased(useragent);
    }

    private static _isMacEmbeddedBrowser(useragent: string): boolean {
        const regex = /^Mozilla\/[\.\d]+ \(Macintosh;.*Mac OS X [_\d]+\) AppleWebKit\/[\.\d]+ \(KHTML, like Gecko\)$/;
        return regex.test(useragent);
    }

    private static _isChromiumBased(useragent: string): boolean {
        return /Chrom(e|ium)/.test(useragent) && !CookieSameSiteHelper._isEdgeBrowser(useragent);
    }

    private static _isEdgeBrowser(useragent: string): boolean {
        // "EdgiOS/", "edga/", "edgios/" & "edg/" are not included in this check as they use different engines
        return /Edge\/((\d+)?[\w\.]+)/.test(useragent);
    }

    private static _isChromiumVersionAtLeast(major: number, useragent: string): boolean {
        const regex = /Chrom[^ \/]+\/(\d+)[\.\d]*/;
        const match = useragent.match(regex) ?? ["", ""];
        const version = parseInt(match[1], 10);
        return version >= major;
    }

    private static _isUcBrowser(useragent: string): boolean {
        return /UCBrowser\//.test(useragent);
    }

    private static _isUcBrowserVersionAtLeast(major: number, minor: number, build: number, useragent: string): boolean {
        const regex = /UCBrowser\/(\d+)\.(\d+)\.(\d+)[\.\d]* /;
        const match = useragent.match(regex) ?? ["", "", "", ""];
        const majorVersion = parseInt(match[1], 10);
        const minorVersion = parseInt(match[2], 10);
        const buildVersion = parseInt(match[3], 10);
        if (majorVersion !== major) {
            return majorVersion > major;
        }
        if (minorVersion !== minor) {
            return minorVersion > minor;
        }
        return buildVersion >= build;
    }
}
