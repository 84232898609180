import { FavouriteStates } from "./FavouriteStates";
import { Subject } from "@pjs/observables";

export class FavouritesObserver {
    constructor() {
        this._subject = new Subject();
    }

    add(items, fromSidebar) {
        this._updateState(FavouriteStates.Favourite, items, fromSidebar);
    }

    remove(items, fromSidebar) {
        this._updateState(FavouriteStates.Unfavourite, items, fromSidebar);
    }

    pending(items) {
        this._updateState(FavouriteStates.Pending, items);
    }

    error() {
        this._updateState(FavouriteStates.Error, []);
    }

    onChange() {
        return this._subject.asObservable();
    }

    _updateState(state, items, fromSidebar = false) {
        const stateInfo = {
            state: state,
            fromSidebar: fromSidebar,
            items: items
        };

        this._subject.next(stateInfo);
    }
}
