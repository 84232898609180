import { PageModelSearch } from "../pageModelSearch/pageModelSearch";
import { byPageId } from "../pageModelSearch/helpers/ByPageId.function";
import { PAGE_ERROR } from "../constants/pageError.constants";
import { ASSET_CONSTANTS } from "../constants/asset.constants";
import { mapPageTreeToProcurePageModel } from "./mapPageTreeToProcurePageModel.function";
import { ProcurePageResponse } from "./procurePageResponse";
import { ErrorWithData } from "../errorWithData/errorWithData";

export class WorkbookResponsePageHandler {
    constructor(assetContext, pages, $q, requester) {
        this._assetContext = assetContext;
        this._$q = $q;
        this._requester = requester;
        this._pageSearch = new PageModelSearch(pages);
        this._containerAssetTypes = [ASSET_CONSTANTS.TYPES.WEBFOLIO, ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE, ASSET_CONSTANTS.TYPES.WORKBOOK];
    }

    /**
     * Gets the latest response for page.
     * Returns an object with the page's PageDto, the page's parents PageDtos and the id of the previous response.
     * The previous response id (`outdatedResponseId`) can be used to deal with concurrency scenarios which may result in a lose of work.
     * For example a placeholder page response could of been changed in another tab.
     * @param {string} pageId
     * @param {boolean} canCreate
     * @return {IPromise<{ outdatedResponseId: string, page: PageDto, parents: Array<PageDto>}>}
     */
    procurePageById(pageId, canCreate) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        if (this._containerAssetTypes.includes(match.page.PageType)) {
            const firstPage = new PageModelSearch(match.page.Children).find((p) => p.PageType !== ASSET_CONSTANTS.TYPES.WORKBOOK);
            return this._$q.reject(new ErrorWithData(PAGE_ERROR.PAGE_IS_CONTAINER, firstPage));
        }

        return this.procurePage(match.page, match.parents, canCreate);
    }

    procurePage(page, parents, canCreate) {
        if (page.PageType === ASSET_CONSTANTS.SPECIAL_TYPES.APPLICANT || page.PageType === ASSET_CONSTANTS.SPECIAL_TYPES.STANDARDS_OVERVIEW) {
            return this._$q.when({ page: page, parents: parents, outdatedResponseId: "" });
        }

        const pageRequest = mapPageTreeToProcurePageModel(page, parents, this._assetContext.assetId);
        return this._requester.procurePage(pageRequest, { ...this._assetContext, canCreate: canCreate, pageId: page.PageId }).then((updates) => this._updatePageTreeResponses(page, parents, updates));
    }

    /**
     * Updates the page's ProgressTrackingComplete property
     * Returns an object with the page's PageDto
     * @param {string} pageId
     * @param {boolean} completed
     * @return {Promise<PageDto>}
     */
    updatePageCompleteStatusById(pageId, completed) {
        return this._updatePageById(pageId, { Completed: completed }).then((page) => {
            page.ProgressTrackingComplete = completed;
            return page;
        });
    }

    updatePageResponseById(pageId, responseId) {
        return this._updatePageById(pageId, { Id: pageId, ResponseId: responseId }).then((page) => {
            page.UserResponseId = responseId;
            page.ProgressTrackingComplete = false;
            return page;
        });
    }

    removePageById(pageId) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        return this._removePage(match.page, match.parents);
    }

    _removePage(page, parents) {
        return this._requester.removePage(this._determineContainerId(parents), page.PageId).then(() => {
            page.UserResponseId = "";
            page.ProgressTrackingComplete = false;
            return page;
        });
    }

    _updatePageTreeResponses(page, parents, updates) {
        for (const parentUpdate of updates.Parents) {
            const parent = parents.find(byPageId(parentUpdate.Id));
            if (parent !== undefined) {
                parent.PageFolder = parentUpdate.FolderName;
                parent.UserResponseId = parentUpdate.ResponseId;
            }
        }

        page.PageFolder = updates.Page.FolderName;
        const currentResponseId = page.UserResponseId;
        if (currentResponseId !== updates.Page.ResponseId) {
            page.UserResponseId = updates.Page.ResponseId;
            return new ProcurePageResponse(page, parents, currentResponseId);
        }

        return new ProcurePageResponse(page, parents, "");
    }

    _determineContainerId(parents) {
        return parents.length > 0 ? parents[0].UserResponseId : this._assetContext.assetId;
    }

    _updatePageById(pageId, updates) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        return this._updatePage(match.page, match.parents, updates);
    }

    _updatePage(page, parents, updates) {
        const pageUpdate = { ResponseId: page.UserResponseId, ...updates };

        return this._requester.updatePage(this._determineContainerId(parents), page.PageId, pageUpdate).then(() => {
            return page;
        });
    }
}
