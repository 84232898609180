import { isVisuallyHidden } from "../is-visually-hidden/IsVisuallyHidden.function";
import { getTabIndex } from "../get-tab-index/GetTabIndex.function";
import { isRadioButtonTabbable } from "./IsRadioButtonTabbable.function";
import { withinDisablingParent } from "./WithinDisablingParent.function";

export function isValidTabBoundaryElement(element: HTMLElement, container: HTMLElement): boolean {
    if (getTabIndex(element) === -1) {
        return false;
    }

    if (withinDisablingParent(element, container)) {
        return false;
    }

    if (element instanceof HTMLInputElement && element.type === "radio" && !isRadioButtonTabbable(element)) {
        return false;
    }

    return !isVisuallyHidden(element);
}
