import { angularAMD } from "@pebblepad/amd";
import { PANEL_CONSTANTS } from "../../constants/panel.constants";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import { ACTION_CONSTANTS } from "../../constants/action.constants";
import "./formElementInfoSidebar.provider";
import "../../feedback/feedbackSidebarServiceProvider/feedbackSidebarService.provider";
import "../../assetEndpointService/assetEndpoint.service";
import "../../builder/dataManager/dataManagerService";
import "../../spaMenu/assetInfoPanel/assetComments/commentsService";

export class FormElementInfoService {
    constructor(formElementInfoSidebarService, $rootScope, AssetEndpointService, baseUrlsFactory, dataManagerService, commentsService, feedbackSidebarService) {
        this._formElementInfoSidebarService = formElementInfoSidebarService;
        this._feedbackSidebarService = feedbackSidebarService;
        this._elementData = new Map();
        this._$rootScope = $rootScope;
        this._assetEndpointService = AssetEndpointService;
        this._baseUrlsFactory = baseUrlsFactory;
        this._dataManagerService = dataManagerService;

        commentsService.addCommentListener(this._updateCommentCount.bind(this));
    }

    getElementDetails(elementId, assetId, timeslice = "") {
        return this._assetEndpointService.getElementDetails(assetId, elementId, timeslice);
    }

    addEvidence(responseId, elementId, newEvidence) {
        const elementData = this.getElementData(elementId);
        const saveObj = {
            ResponseAssetRefId: responseId,
            FormElementId: elementId,
            EvidenceMappings: newEvidence.map((evidence) => {
                return {
                    EvidenceAssetRefId: evidence.Id,
                    EvidenceJustification: evidence.Justification
                };
            })
        };

        return this._assetEndpointService.addEvidenceMapFromResponse(saveObj).then((response) => {
            const evidenceCount = elementData.elementInfo.evidence.length > 0 ? elementData.elementInfo.evidence[0].EvidenceItems.length : 0;
            const latestEvidenceList = evidenceCount > 0 ? [...elementData.elementInfo.evidence[0].EvidenceItems, ...response.data] : response.data;

            let status = "";
            if (latestEvidenceList.length > 0 && elementData.elementInfo.evidenceStatus === ASSET_CONSTANTS.EVIDENCE_STATES.NOT_EVIDENCED) {
                status = ASSET_CONSTANTS.EVIDENCE_STATES.PARTIALLY_EVIDENCED;
            }

            const manager = this._dataManagerService.getDto(responseId);
            elementData.onUpdateEvidence(latestEvidenceList, status, !manager.is_saved);

            if (elementData.onEvidenceAdded !== undefined) {
                elementData.onEvidenceAdded(response.data);
            }

            return latestEvidenceList;
        });
    }

    createTextOnlyEvidence(title, text) {
        const saveObj = {
            Title: title,
            Evidence: text
        };
        return this._assetEndpointService.addTextOnlyEvidence(saveObj);
    }

    removeEvidence(responseId, elementId, aggregateId) {
        return this._assetEndpointService.removeEvidenceMapFromResponse({ AggregateId: aggregateId }).then(() => {
            const elementData = this.getElementData(elementId);
            const latestEvidenceList = elementData.elementInfo.evidence[0].EvidenceItems.filter((evidence) => evidence.AggregateId !== aggregateId);

            let status = "";
            if (latestEvidenceList.length === 0 && elementData.elementInfo.evidenceStatus !== ASSET_CONSTANTS.EVIDENCE_STATES.NOT_EVIDENCED) {
                status = ASSET_CONSTANTS.EVIDENCE_STATES.NOT_EVIDENCED;
            }

            const manager = this._dataManagerService.getDto(responseId);
            elementData.onUpdateEvidence(latestEvidenceList, status, !manager.is_saved);

            return { evidence: latestEvidenceList, status: status };
        });
    }

    updateAnswerHistoryJustification(elementId, justification) {
        const elementData = this._elementData.get(elementId);
        elementData.onUpdateHistoryJustification(justification);
    }

    updateEvidenceJustification(evidenceItem) {
        const updateObj = {
            AggregateId: evidenceItem.AggregateId,
            Justification: evidenceItem.Justification
        };
        return this._assetEndpointService.updateEvidenceJustificationFromResponse(updateObj);
    }

    updateEvidenceStatus(elementId, evidenceIsComplete) {
        const elementData = this._elementData.get(elementId);
        elementData.onUpdateEvidence(null, evidenceIsComplete ? ASSET_CONSTANTS.EVIDENCE_STATES.FULLY_EVIDENCED : ASSET_CONSTANTS.EVIDENCE_STATES.PARTIALLY_EVIDENCED, true);

        return evidenceIsComplete;
    }

    _updateCommentCount(responseId, action, elementId) {
        const elementData = this.getElementData(elementId);
        if (elementData === undefined) {
            return;
        }

        const currentCount = elementData.elementInfo.totalComments;
        const newCount = action === ACTION_CONSTANTS.ADD ? currentCount + 1 : currentCount - 1;
        const manager = this._dataManagerService.getDto(responseId);
        elementData.onUpdateCommentCount(newCount, !manager.is_saved);
    }

    getElementData(elementId) {
        return this._elementData.get(elementId);
    }

    _setElementData(elementInfo, callbacks) {
        this._elementData.set(elementInfo.id, { elementInfo: elementInfo, ...callbacks });
    }

    _updateElementCallbacks(elementInfo, callbacks) {
        let elementData = this._elementData.get(elementInfo.id);
        elementData = { ...elementData, ...callbacks };
        this._elementData.set(elementInfo.id, elementData);
    }

    openElementInfoSidebar(data) {
        this._setElementData(data.info, data.callbacks);
        this._formElementInfoSidebarService.displayElementInfo(data.info.id, data.panelData, data.assetId, data.topLevelAssetId, data.onPanelClose);
    }

    openBlockFeedbackSidebar(data) {
        this._setElementData(data.info, data.callbacks);
        this._feedbackSidebarService.displayBlockFeedback(data.info.id, data.panelData, data.assetId, data.topLevelAssetId, data.onPanelClose);
    }

    openApprovalFeedbackSidebar(data) {
        this._setElementData(data.info, data.callbacks);
        this._feedbackSidebarService.displayApprovalFeedback(data.info.id, data.panelData, data.assetId, data.topLevelAssetId, data.onPanelClose);
    }

    openElementCommentsSidebar(data) {
        this._setElementData(data.info, data.callbacks);
        this._formElementInfoSidebarService.displayElementCommentsSidebar(data.assetId, data.info.id, data.panelData, data.topLevelAssetId, data.onPanelClose);
    }

    _openAddEvidenceSidebarAfterSave(data) {
        if (this._elementData.has(data.info.id)) {
            this._updateElementCallbacks(data.info, data.callbacks);
        } else {
            this._setElementData(data.info, data.callbacks);
        }

        const evidenceAssetIds = [];
        if (data.info.evidence !== undefined && data.info.evidence.length > 0) {
            data.info.evidence.forEach((evidence) => {
                evidence.EvidenceItems.forEach((evidenceItem) => {
                    evidenceAssetIds.push(evidenceItem.Id);
                });
            });
        }

        const dataToBroadcast = {
            panel: PANEL_CONSTANTS.PANEL.ADD_EVIDENCE,
            assetId: data.assetId,
            anchorId: data.info.id,
            evidenceAssetIds: evidenceAssetIds,
            topLevelAssetId: data.topLevelAssetId,
            evidenceCount: data.evidenceCount,
            evidenceMinimum: data.evidenceMinimum
        };

        this._$rootScope.$broadcast("openRightHandSidePanel", dataToBroadcast);
    }

    openAddEvidenceSidebar(data) {
        this._$rootScope.$broadcast("checkIsSavedFromIWantTo", {
            afterSaveAction: { type: "method", method: this._openAddEvidenceSidebarAfterSave.bind(this, data) },
            forceSaveOfFormIfZeroRevision: true
        });
    }

    clearElementData(elementId) {
        this._elementData.delete(elementId);
    }
}

FormElementInfoService.$inject = ["formElementInfoSidebarService", "$rootScope", "AssetEndpointService", "baseUrlsFactory", "dataManagerService", "commentsService", "feedbackSidebarService"];

angularAMD.service("formElementInfoService", FormElementInfoService);
