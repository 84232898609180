import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { PrintableCharacterMatcher } from "../../../printable-character-matcher/PrintableCharacterMatcher";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";

export function createSelectMatchedItem<T>(
    matcher: (item: T, characters: string) => boolean
): (e: KeyboardEvent, model: Immutable<IAriaModelWithType>, items: Array<T>) => Partial<Immutable<IAriaModelWithType>> | null {
    let printableMatcher: PrintableCharacterMatcher<T> | null = null;
    let cachedItems: Array<T> | null = null;

    return (e: KeyboardEvent, model: IAriaModelWithType, items: Array<T>) => {
        if (cachedItems === null || printableMatcher === null || items !== cachedItems) {
            cachedItems = items;
            printableMatcher = new PrintableCharacterMatcher<T>(cachedItems, matcher);
        }

        printableMatcher.trackKey(e.key);

        const index = printableMatcher.getIndexOfNextMatch(model.activeItemIndex);

        if (index > -1) {
            return {
                activeItemIndex: index,
                type: AriaMenuStatus.Open
            };
        }

        return null;
    };
}
