import i18next from "i18next";

function I18NextServiceProvider() {
    this.i18next = i18next;
    this.$get = function () {
        return this.i18next;
    };
}

export { I18NextServiceProvider };
