var HTML_INPUT = {
    INLINE_TAGS_WHITELIST: ["a", "strong", "b", "i", "em", "font", "u", "span", "sub", "sup", "small", "mark", "cite", "abbr", "s", "strike"],
    BLOCK_TAGS_WHITELIST: ["div", "p", "h1", "h2", "h3", "h4", "h5", "h6", "ul", "ol", "li", "br"],
    ATTRIBUTES_WHITELIST: ["style", "href", "title", "target", "face", "size", "color", "align", "start", "reversed", "type"],
    STYLE_PROPERTY_BLACKLIST: [
        //TODO: Rework paste data to have whitelist instead!
        "orphans",
        "widows",
        "fontVariantLigatures",
        "fontFeatureSettings",
        "fontKerning",
        "verticalAlign",
        "whiteSpace",
        "letterSpacing",
        "wordWrap",
        "userSelect",
        "outline",
        "cursor",
        "display",
        "flex",
        "alignContent",
        "alignItems",
        "justifyContent",
        "float",
        "overflow",
        "position",
        "top",
        "right",
        "bottom",
        "left",
        "margin",
        "marginTop",
        "marginBottom",
        "marginRight",
        "padding",
        "paddingTop",
        "paddingBottom",
        "paddingLeft",
        "paddingRight",
        "width",
        "height",
        "transform",
        "opacity",
        "backgroundImage",
        "backgroundSize",
        "backgroundAttachment",
        "backgroundPosition",
        "backgroundRepeatY",
        "backgroundRepeatX",
        "backgroundRepeat",
        "backgroundOrigin",
        "backgroundClip",
        "clip",
        "border",
        "borderTop",
        "borderRight",
        "borderBottom",
        "borderLeft",
        "borderWidth",
        "borderStyle",
        "zIndex",
        "lineHeight",
        "letterSpacing"
    ],
    WYSIWYG_STYLES_WHITELIST: ["font", "font-size", "font-style", "font-variant", "font-weight", "font-stretch", "font-family", "color"]
};

export { HTML_INPUT };
