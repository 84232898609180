import { ASSESSMENT_CONSTANTS } from "../../constants/assessment.constants";
import { FILTERER_CONSTANTS } from "../../constants/filterer.constants";

class FeedbackFilterer {
    constructor() {
        this._activeFilter = this._getDefaultFilter();
        this._callbacks = new Set();

        this._filterOperations = [this._forElement.bind(this), this._leftOn.bind(this), this._excludeTypes.bind(this), this._showMostRecentPerAssessorField.bind(this)];
    }

    subscribe(callback) {
        this._callbacks.add(callback);
        return () => {
            this._callbacks.delete(callback);
        };
    }

    filter(data) {
        if (Object.keys(this._activeFilter).length === 0) {
            return data;
        }
        return data.filter((feedback) => this._filterOperations.every((op) => op(feedback)));
    }

    getActiveFilter() {
        return this._activeFilter;
    }

    setActiveFilter(newFilter) {
        this._activeFilter = newFilter;
        this._updateSubscribers(FILTERER_CONSTANTS.EVENTS.SET);
    }

    updateActiveFilter(newFilter) {
        this._activeFilter = { ...this._activeFilter, ...newFilter };
        this._updateSubscribers(FILTERER_CONSTANTS.EVENTS.UPDATE);
    }

    resetFilter() {
        this._activeFilter = this._getDefaultFilter();
        this._updateSubscribers(FILTERER_CONSTANTS.EVENTS.RESET);
    }

    _updateSubscribers(action) {
        for (const callback of this._callbacks) {
            callback(action);
        }
    }

    _getDefaultFilter() {
        return {
            showMostRecentPerAssessorField: false
        };
    }

    _leftOn(feedback) {
        if (this._activeFilter.leftOn === undefined) {
            return true;
        }

        return this._activeFilter.leftOn === feedback.AssetId;
    }

    _excludeTypes(feedback) {
        const excludeList = this._activeFilter.excludeTypes !== undefined ? this._activeFilter.excludeTypes : [];
        return !excludeList.includes(feedback.FeedbackType);
    }

    _showMostRecentPerAssessorField(feedback) {
        if (this._activeFilter.showMostRecentPerAssessorField === undefined) {
            return true;
        }

        if (this._activeFilter.forElement !== undefined) {
            return true;
        }

        if (this._activeFilter.showMostRecentPerAssessorField) {
            return feedback.FeedbackType !== ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD || feedback.MostRecentForElementId;
        }

        return feedback.FeedbackType !== ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD;
    }

    _forElement(feedback) {
        if (this._activeFilter.forElement === undefined) {
            return true;
        }

        return feedback.AnchorId !== null && feedback.LeftOn !== null && feedback.AnchorId === this._activeFilter.forElement.anchorId && feedback.AssetId === this._activeFilter.forElement.assetId;
    }
}

export { FeedbackFilterer };
