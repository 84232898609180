import { moveElementChildren } from "@pjs/utilities";
import { HeadingTagName } from "../../types/HeadingTagName";

export class SemanticHeadingFixer {
    public static readonly headingNames: Array<HeadingTagName> = ["h1", "h2", "h3", "h4", "h5", "h6"];
    private readonly _startingIndex: number;

    constructor(startingHeadingIndex: number) {
        this._startingIndex = startingHeadingIndex;
    }

    public static createFromHeadingName(startingHeadingName: HeadingTagName): SemanticHeadingFixer {
        const index = SemanticHeadingFixer.headingNames.indexOf(startingHeadingName);
        return new SemanticHeadingFixer(index);
    }

    public fix(containerElement: Element): void {
        if (this._startingIndex === -1) {
            return;
        }

        let index = this._startingIndex;
        let newTagName = SemanticHeadingFixer.headingNames[index];

        const allHeadings: Array<Array<Element>> = [];
        for (const headingName of SemanticHeadingFixer.headingNames) {
            const headings = containerElement.getElementsByTagName(headingName);

            if (headings.length > 0) {
                allHeadings.push(Array.from(headings));
            }
        }

        const lastIndex = SemanticHeadingFixer.headingNames.length - 1;
        for (const headings of allHeadings) {
            if (headings[0].tagName === newTagName) {
                continue;
            }

            for (const heading of headings) {
                this._replace(heading, newTagName);
            }

            index++;
            newTagName = SemanticHeadingFixer.headingNames[Math.min(index, lastIndex)];
        }
    }

    private _replace(heading: Element, newHeadingName: HeadingTagName): void {
        const newHeading = heading.ownerDocument.createElement(newHeadingName);

        const attributes = heading.getAttributeNames();
        for (const attribute of attributes) {
            newHeading.setAttribute(attribute, heading.getAttribute(attribute) as string);
        }

        moveElementChildren(heading, newHeading);
        heading.replaceWith(newHeading);
    }
}
