function CkEditorParagraphIndentCommand(editor, processor, ckEditor) {
    this._editor = editor;
    this._processor = processor;
    this._ckEditor = ckEditor;
    this._externalCommands = [];
    this._afterExecHandlers = {};
}

CkEditorParagraphIndentCommand.prototype.exec = function (applyStyle) {
    this._editor.focus();
    var processor = applyStyle !== false ? this._processor.applyStyle.bind(this._processor) : this._processor.removeStyle.bind(this._processor);

    if (this.processSelection(processor)) {
        this._editor.fire("saveSnapshot");
    }
};

CkEditorParagraphIndentCommand.prototype.wrapExternalCommandFormatting = function (externalCommand) {
    if (!this._externalCommands.includes(externalCommand)) {
        if (this._externalCommands.length === 0) {
            this._editor.on("beforeCommandExec", this._onExternalCommand.bind(this));
        }

        this._externalCommands.push(externalCommand);
    }
};

/**
 * For certain CkEditor commands (e.g. bullet-list), text-indent and margins can be moved onto the wrong element.
 * In order to ensure existing indent styles get applied correctly, we have to:
 * - Remove the indent styles.
 * - Wait for the 'after command executed' event (applying styles during the execution of the external command will result in unexpected explosions!).
 * - Reapply the indent styles.
 * - Replace the last 'snapshot' state with the current state.
 */
CkEditorParagraphIndentCommand.prototype._onExternalCommand = function (event) {
    var commandName = event.data.name;
    if (!this._externalCommands.includes(commandName)) {
        return;
    }

    //In case previous external exec-command failed, force the previous listener for the afterCommandExec to be destroyed.
    var existingHandler = this._afterExecHandlers[commandName];
    if (existingHandler) {
        existingHandler.removeListener();
        this._afterExecHandlers[commandName] = null;
    }

    var requiresUpdate = this.processSelection(
        function (element) {
            if (this._processor.applied(element)) {
                this._processor.removeStyle(element);
                return true;
            }

            return false;
        }.bind(this)
    );

    if (!requiresUpdate) {
        return;
    }

    this._afterExecHandlers[commandName] = this._editor.on(
        "afterCommandExec",
        function (event) {
            if (event.data.name === commandName) {
                this.processSelection(this._processor.applyStyle.bind(this._processor));
                this._editor.fire("updateSnapshot");

                this._afterExecHandlers[commandName].removeListener();
                this._afterExecHandlers[commandName] = null;
            }
        }.bind(this)
    );
};

CkEditorParagraphIndentCommand.prototype.onOutdent = function () {
    var processor = function (element) {
        return this._processor.onOutdent !== undefined && this._processor.onOutdent(element);
    }.bind(this);

    if (this.processSelection(processor)) {
        this._editor.fire("updateSnapshot");
    }
};

CkEditorParagraphIndentCommand.prototype.processSelection = function (processAction) {
    var selection = this._editor.getSelection();
    if (selection === null) {
        return;
    }

    var bookmarks = selection.createBookmarks();
    var range = selection.getRanges()[0];
    var iterator = range.createIterator();
    var enterMode = this._editor.config.enterMode;
    iterator.enforceRealBlocks = true;
    iterator.enlargeBr = enterMode !== this._ckEditor.ENTER_BR;

    var blockElement = iterator.getNextParagraph();
    var updated = false;

    while (blockElement !== null) {
        if (!blockElement.isReadOnly() && processAction(blockElement)) {
            updated = true;
        }

        blockElement = iterator.getNextParagraph();
    }

    selection.selectBookmarks(bookmarks);
    return updated;
};

export { CkEditorParagraphIndentCommand };
