import { angularAMD } from "@pebblepad/amd";

class AssetLinkService {
    getLinkAttribs(linkData) {
        const historyId = linkData.currentTarget !== "_blank" && linkData.historyId !== undefined && linkData.historyId !== "" ? `?historyId=${linkData.historyId}` : "";
        const timeslice = linkData.timeslice !== undefined ? linkData.timeslice + "/" : "";
        return {
            href: `#/${linkData.route}/${timeslice}${linkData.assetId}${historyId}`
        };
    }
}

angularAMD.service("assetLinkService", AssetLinkService);
export { AssetLinkService };
