import { assetIcons } from "@pjs/asset-core";

export const mapSelectorItemsToAutoCompleteItems = (selectorItems, getTypeLabel) => {
    return selectorItems.map((selectorItem) => {
        return {
            icon: assetIcons.get(selectorItem.MainType, selectorItem.SubType),
            id: selectorItem.Id,
            mainType: selectorItem.MainType,
            value: selectorItem.Title,
            label: getTypeLabel(selectorItem.MainType)
        };
    });
};
