const radioHostCache = new Map<HTMLElement, Record<string, HTMLInputElement | null>>();
let hasScheduledCacheClear = false;

export function isRadioButtonTabbable(radioButton: HTMLInputElement): boolean {
    if (radioButton.checked) {
        return true;
    }

    const radioButtonHost = radioButton.form ?? document.body;
    const hostQueryResults = radioHostCache.get(radioButtonHost) ?? {};
    let groupsCheckedRadio = hostQueryResults[radioButton.name];

    if (groupsCheckedRadio === undefined) {
        const checkedRadioSelector = `input[type="radio"][name="${CSS.escape(radioButton.name)}"]:checked`;
        const selector = radioButton.form === null ? `${checkedRadioSelector}:not(:is(form input))` : `form ${checkedRadioSelector}`;
        groupsCheckedRadio = radioButtonHost.querySelector(selector);
        hostQueryResults[radioButton.name] = groupsCheckedRadio;
        radioHostCache.set(radioButtonHost, hostQueryResults);
    }

    if (!hasScheduledCacheClear) {
        hasScheduledCacheClear = true;
        queueMicrotask(() => {
            radioHostCache.clear();
            hasScheduledCacheClear = false;
        });
    }

    if (groupsCheckedRadio === null) {
        return true;
    }
    return groupsCheckedRadio === radioButton;
}
