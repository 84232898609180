import { angularAMD } from "@pebblepad/amd";

angularAMD.service("tableConfig", factory());

function factory() {
    function TableConfig() {
        // Table Configs
        var configs = {
            maxColumn: 10,
            minColumn: 1,
            minRow: 1,
            maxRow: 100,
            minCell: 1,
            maxCell: 100,
            minCellWidth: 8,
            widthIncrement: 5,
            defaultTableSize: {
                "BuilderFormTableContainer-FixedRows": 3,
                "BuilderFormTableContainer-RepeatingRows": 3,
                "BuilderFormTableContainer-RowHeadingsNoColumnHeadings": 3,
                "BuilderFormTableContainer-RowHeadings": 3,
                BuilderSingleCells: 4,
                BuilderRubricContainer: 4
            }
        };

        // Public API
        // =============================================================================================================
        this.configs = configs;
        this.type = {
            builder: {
                table: {
                    columnsOnly: "BuilderFormTableContainer-FixedRows",
                    columnsOnlyRepeating: "BuilderFormTableContainer-RepeatingRows",
                    rowsOnly: "BuilderFormTableContainer-RowHeadingsNoColumnHeadings",
                    rowsAndColumns: "BuilderFormTableContainer-RowHeadings",
                    singleCell: "BuilderSingleCells"
                },
                rubric: {
                    rowsAndColumns: "BuilderRubricContainer"
                }
            },
            fillerViewer: {
                table: {
                    columnsOnly: "FormTableContainer-FixedRows",
                    columnsOnlyRepeating: "FormTableContainer-RepeatingRows",
                    rowsOnly: "FormTableContainer-RowHeadingsNoColumnHeadings",
                    rowsAndColumns: "FormTableContainer-RowHeadings",
                    singleCell: "ElementCells",
                    repeatingContainer: "RepeatingContainer"
                },
                rubric: {
                    rowsAndColumns: "RubricContainer"
                }
            }
        };

        this.checkLimit = {
            isMax: isLimit("max"),
            isMin: isLimit("min")
        };

        // Private methods
        // =============================================================================================================
        var self = this;

        function isLimit(limitType) {
            return function (role, tableType, data) {
                var limitValue; // if undefined, then it means 'infinite' (no limit)
                var valueToCheck = data[role + "s"].length;
                switch (tableType) {
                    case self.type.builder.table.columnsOnly:
                    case self.type.builder.table.rowsOnly:
                    case self.type.builder.table.columnsOnlyRepeating:
                        limitValue = configs[limitType + role];
                        break;
                    case self.type.builder.table.rowsAndColumns:
                    case self.type.builder.rubric.rowsAndColumns:
                        limitValue = configs[limitType + role] + 1;
                        break;
                    case self.type.builder.table.singleCell:
                        limitValue = configs[limitType + "Cell"];
                        break;
                }

                if (!limitValue) {
                    return false;
                }

                if (limitType === "max") {
                    return valueToCheck >= limitValue;
                } else if (limitType === "min") {
                    return valueToCheck <= limitValue;
                }
            };
        }
    }

    return TableConfig;
}
