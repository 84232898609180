import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/urlService";
import "../../../utilities/baseUrlsFactory";
import "../../../uberDropdown/uberDropdown";
import "../../../multiLanguageService/multiLanguageService";
import "../legacyLinkerModal.provider";
import template from "../templates/legacy-linker-modal.html";

angularAMD.directive("legacyLinkerModal", LegacyLinkerModal);
LegacyLinkerModal.$inject = [];

angularAMD.controller("LegacyLinkerModalController", LegacyLinkerModalController);
LegacyLinkerModalController.$inject = ["$http", "$rootScope", "$routeParams", "urlService", "multiLanguageService", "legacyLinkerModalService"];

function LegacyLinkerModal() {
    return {
        template: template,
        restrict: "E",
        scope: {
            onComplete: "&",
            deferred: "="
        },
        controller: "LegacyLinkerModalController",
        controllerAs: "vm",
        bindToController: true
    };
}

function LegacyLinkerModalController($http, $rootScope, $routeParams, urlService, multiLanguageService, legacyLinkerModalService) {
    this.services = {
        http: $http,
        rootScope: $rootScope,
        routeParams: $routeParams,
        urlService: urlService,
        multiLanguageService: multiLanguageService,
        linkerModalService: legacyLinkerModalService
    };

    this.constants = {
        URL: "url",
        EMAIL: "email",
        ASSET: "asset",
        RESOURCE: "resource"
    };

    this.urlLink = "";
    this.target = false;
    this.dropdownOptions = this.services.linkerModalService.getDropdownOptions();
    this.selectedOption = this.dropdownOptions[0];
}

LegacyLinkerModalController.prototype.createAssetLink = function () {
    const deferred = this.deferred;
    this.getAssetResourceLink(deferred, "createAssetLink", "AssetStore", true, true, this.target ? "_blank" : "_self");
    this.onComplete();
};

LegacyLinkerModalController.prototype.createResourceLink = function () {
    const deferred = this.deferred;
    this.getAssetResourceLink(deferred, "createResourceLink", "ResourceCentre", false, false, this.target ? "_blank" : "_self");
    this.onComplete();
};

LegacyLinkerModalController.prototype.createLink = function () {
    const deferred = this.deferred;
    const link = this.processLink(this.urlLink, this.constants.URL);
    deferred.resolve({ link: link, target: "_blank" });
    this.onComplete();
};

LegacyLinkerModalController.prototype.createEmailLink = function () {
    const deferred = this.deferred;
    const link = this.processLink(this.urlLink, this.constants.EMAIL);
    deferred.resolve({ link: link, target: "_blank" });
    this.onComplete();
};

LegacyLinkerModalController.prototype.processLink = function (urlLink, type) {
    switch (type) {
        case this.constants.URL:
            const httpCheck = /^http(s)?:\/\//.test(urlLink);
            if (!httpCheck) {
                urlLink = "http://" + urlLink;
            }
            return urlLink;
        case this.constants.EMAIL:
            const mailtoCheck = /^mailto:.*/.test(urlLink);
            if (!mailtoCheck) {
                urlLink = "mailto:" + urlLink;
            }
            return urlLink;
    }
};

LegacyLinkerModalController.prototype.getAssetResourceLink = function (deferred, broadcastCommand, selectType, viewerMode, allowUpload, target) {
    if (target !== "_self" && target !== "_blank") {
        target = "_self";
    }

    var disableListener = this.services.rootScope.$on(
        broadcastCommand,
        function (event, data) {
            disableListener();
            if (data.length) {
                deferred.resolve({
                    link: this.services.urlService.createHashUrl(data[0].Id, data[0].MainType, viewerMode, false),
                    target: target
                });
            } else {
                deferred.reject();
            }
            return deferred;
        }.bind(this)
    );

    var currentAssetId = this.services.routeParams.assetId ? [this.services.routeParams.assetId] : [];
    this.services.rootScope.$broadcast("openRightHandSidePanel", {
        panel: "selector",
        broadcastCommand: broadcastCommand,
        broadcastType: "onlyOnDone",
        singleOrMultipleSelect: "Single",
        selectType: selectType,
        ownerships: ["ByMe", "WithMe"],
        requiresWritePermission: true,
        excludeIds: currentAssetId,
        includeUpload: allowUpload
    });
};

LegacyLinkerModalController.prototype.setSelectedOption = function (option) {
    this.selectedOption = option;
};

LegacyLinkerModalController.prototype.rejectModal = function () {
    this.deferred.reject();
    this.onComplete();
};
