import { convertPixelToRem } from "@pjs/theming";
import { passThrough } from "../pass-through/PassThrough.function";

export class RemConverter {
    private static readonly _cmToPxAtStandardDpiRatio: number = 37.795;
    private static readonly _ptToPxRatio: number = 1.333;
    private static readonly _numberAndUnitMatcher: RegExp = /^([0-9.-]+)([a-zA-Z%]+)$/;
    private static readonly _unitMap: Readonly<Record<string, (value: number) => number>> = {
        cm: (value) => convertPixelToRem(value * RemConverter._cmToPxAtStandardDpiRatio),
        em: passThrough,
        in: (value) => convertPixelToRem(value * 2.54 * RemConverter._cmToPxAtStandardDpiRatio),
        mm: (value) => convertPixelToRem(value * 0.1 * RemConverter._cmToPxAtStandardDpiRatio),
        pt: (value) => convertPixelToRem(value * RemConverter._ptToPxRatio),
        px: convertPixelToRem,
        rem: passThrough
    };

    public static cssValueToRem(cssValue: string): number {
        const match = cssValue.match(RemConverter._numberAndUnitMatcher);
        if (match === null) {
            return NaN;
        }

        return RemConverter._convertToRem(parseFloat(match[1]), match[2]);
    }

    private static _convertToRem(value: number, unit: string): number {
        const converter = RemConverter._unitMap[unit];

        if (converter === undefined) {
            return NaN;
        }

        return converter(value);
    }
}
