import { IEditorPlaceholder } from "./interfaces/IEditorPlaceholder";

export class CKEditorPlaceholder implements IEditorPlaceholder {
    private _active: boolean;
    private readonly _editorPlaceholderContainer: HTMLElement;
    private readonly _text: string;

    constructor(editorPlaceholderContainer: HTMLElement, text: string) {
        this._editorPlaceholderContainer = editorPlaceholderContainer;
        this._text = text;

        const existingPlaceholder = getComputedStyle(editorPlaceholderContainer).getPropertyValue("--placeholder-text");
        this._active = existingPlaceholder !== undefined && existingPlaceholder !== "";
    }

    public static doesEditorHaveContent(content: string): boolean {
        return content !== "" && content !== "<p>&nbsp;</p>" && !/^(?:<br>)+$/.test(content);
    }

    public update(content: string): void {
        const hasContent = CKEditorPlaceholder.doesEditorHaveContent(content);
        if (hasContent && !this._active) {
            return;
        }

        this._active = !hasContent;
        const placeholderValue = !hasContent ? this._text : "";

        this._editorPlaceholderContainer.style.setProperty("--placeholder-text", `"${placeholderValue}"`);
    }
}
