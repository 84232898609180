import { angularAMD } from "@pebblepad/amd";
import "./helpers";

angularAMD.service("subscriptionServiceProvider", [
    "$q",
    "helpers",
    function ($q, helpers) {
        this.services = {};

        this.addService = function (id) {
            if (id !== undefined) {
                this.services[id] = new helpers.ObjectCollection();
                return this.services[id];
            }
        };

        this.removeService = function (id) {
            var service = this.services[id];
            if (service !== undefined) {
                delete this.services[id];
            }
        };

        this.clearServices = function () {
            for (var service in this.services) {
                if (this.services.hasOwnProperty(service)) {
                    this.services[service] = null;
                    delete this.services[service];
                }
            }
        };

        this.runSubscriptions = function (id, data) {
            var list = this.services[id].items,
                q = $q.defer(),
                promise = q.promise;
            for (var i = 0, l = list.length, item; i < l; i++) {
                item = list[i];
                promise = promise.then(item.callback);
            }

            q.resolve(data);
            return promise;
        };

        this.subscribe = function (serviceId, id, callback) {
            var service = this.services[serviceId];
            if (service !== undefined) {
                service.add({
                    id: id,
                    callback: callback
                });
            }
        };

        this.unsubscribe = function (serviceId, id) {
            var service = this.services[serviceId];
            if (service !== undefined) {
                service.remove("id", id);
            }
        };

        this.clearSubscriptions = function (serviceId) {
            var service = this.services[serviceId];
            if (service !== undefined) {
                service.removeAll();
            }
        };

        this.subscriptionExists = function (serviceId, id) {
            var service = this.services[serviceId];
            return service !== undefined ? service.exists("id", id) : false;
        };
    }
]);
