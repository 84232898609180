import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("globalEvents", [
    "$timeout",
    "$window",
    function ($timeout, $window) {
        var globalEvents = {};

        globalEvents.bindEvent = function (target, event, handler) {
            target.addEventListener(event, handler, false);

            return function () {
                target.removeEventListener(event, handler, false);
            };
        };

        globalEvents.runAll = function () {
            var self = this;
            $timeout.cancel(globalEvents.timer);
            globalEvents.timer = $timeout(function () {
                for (var i = 0; i < self.fnArray.length; i++) {
                    self.fnArray[i]();
                }
            }, 250);
        };

        globalEvents.onWindowResize = {
            fnArray: [],
            unbindHandler: null,

            addFn: function (fn) {
                var index = this.fnArray.indexOf(fn);
                if (index === -1) {
                    this.fnArray.push(fn);
                }

                // Bind event and run all commands
                if (this.fnArray.length > 0 && !this.unbindHandler) {
                    var handler = globalEvents.runAll.bind(this);
                    this.unbindHandler = globalEvents.bindEvent($window, "resize", handler);
                }
            },

            removeFn: function (fn) {
                var index = this.fnArray.indexOf(fn);
                if (index > -1) {
                    this.fnArray.splice(index, 1);
                }

                // Unbind event in case there is no functions to run
                if (this.fnArray.length === 0 && this.unbindHandler) {
                    this.unbindHandler();
                    this.unbindHandler = null;
                }
            }
        };

        return globalEvents;
    }
]);
