import { angularAMD } from "@pebblepad/amd";
import template from "./radio-button-option-component.html";

var ComponentObject = {
    bindings: {
        onValueChange: "&",
        heading: "<",
        tip: "<",
        value: "<",
        key: "<",
        options: "<"
    },
    controller: RadioButtonComponent,
    template: template
};

// tslint:disable-next-line:no-empty
function RadioButtonComponent() {}

RadioButtonComponent.prototype.radioButtonChange = function () {
    this.onValueChange({ key: this.key, value: this.value });
};
angularAMD.component("radioButtonOptionComponent", ComponentObject);

export { ComponentObject as radioButtonOptionComponent };
