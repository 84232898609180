import { Placement } from "@floating-ui/dom";
import { Observable } from "@pjs/observables";
import { createFloatingPositioner } from "./CreateFloatingPositioner.function";
import { createToolbarOverflowMenuPositioningFactory } from "./factories/CreateToolbarOverflowMenuPositioningFactory";

export function createToolbarOverflowMenuPositioner(refEl: HTMLElement, floatingEl: HTMLElement, boundaryEl: HTMLElement, toolbarElement: HTMLElement | null, placement: Placement): Observable<void> {
    const factory = createToolbarOverflowMenuPositioningFactory(toolbarElement);

    return createFloatingPositioner(refEl, floatingEl, boundaryEl, factory, placement);
}
