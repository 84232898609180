import { angularAMD } from "@pebblepad/amd";
import "../../multiLanguageService/multiLanguageService";
import template from "../templates/share-link.html";

angularAMD.directive("shareLink", ShareLink);
ShareLink.$inject = ["$sce", "baseUrlsFactory"];

angularAMD.controller("ShareLinkController", ShareLinkController);
ShareLinkController.$inject = ["$location", "$timeout", "$element", "multiLanguageService"];

function ShareLink($sce, baseUrlsFactory) {
    return {
        template: template,
        restrict: "E",
        scope: {
            viewerType: "@",
            assetIds: "=",
            linkTitle: "@",
            linkDescription: "@",
            hideNewTabLink: "@"
        },
        controller: "ShareLinkController",
        controllerAs: "vm",
        bindToController: true
    };
}

function ShareLinkController($location, $timeout, $element, multiLanguageService) {
    this.services = {
        location: $location,
        timeout: $timeout,
        multiLanguageService: multiLanguageService
    };

    this.copyBtnText = "";
    this.webLink = "";
    this.web_link_el = $element[0].querySelector("#web-link-text-box");
    this.tooltipBoundaryEl = $element[0].querySelector(".share-link__container");
    this.tooltip = this.services.multiLanguageService.getString("tips.sharing.keyboard_copy.content");

    if (!document.queryCommandSupported("copy")) {
        this.hideCopyUrl = true;
    }

    this.init();
}

ShareLinkController.prototype.init = function () {
    var absUrl = this.services.location.absUrl();
    var url = absUrl.substring(0, absUrl.indexOf("#") + 2);
    this.webLink = url + this.viewerType + "/" + this.assetIds[0];
    this.copyBtnText = this.services.multiLanguageService.getString("sharing.buttons.copy_url");
};

ShareLinkController.prototype.copyUrl = function () {
    this.web_link_el.select();

    try {
        var successful = document.execCommand("copy");
        if (successful) {
            this.copyBtnText = this.services.multiLanguageService.getString("sharing.buttons.copy_success");
            this.services.timeout(
                function () {
                    this.copyBtnText = this.services.multiLanguageService.getString("sharing.buttons.copy_url");
                }.bind(this),
                2000
            );
        }
    } catch (err) {
        console.warn("Oops, unable to copy");
    }
};

ShareLinkController.prototype.selectUrl = function () {
    this.web_link_el.select();
};
