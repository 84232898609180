import { angularAMD } from "@pebblepad/amd";
import "./detachedToolbarRefContext.directive";

const detachedToolbarRef = () => {
    return {
        require: "^detachedToolbarRefContext",
        scope: true,
        restrict: "A",
        link: function (scope, element, attrs, detachedToolbarRefController) {
            detachedToolbarRefController.setToolbarElement(element[0]);
            element.on("$destroy", () => {
                detachedToolbarRefController.setToolbarElement(null);
            });
        }
    };
};

angularAMD.directive("detachedToolbarRef", detachedToolbarRef);
