import { convertToHtmlSafeChars } from "./ConvertToHtmlSafeChars.function";

export function convertPlainTextToSemanticHtml(value: string): string {
    return convertToHtmlSafeChars(value)
        .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
        .replace(/^[^\S\r\n]/, "&nbsp;")
        .replace(/\n([^\n]+)(?=\n|$)/g, "<p>$1</p>")
        .replace(/\n/g, "<p>&nbsp;</p>")
        .replace(/\s\s/g, " &nbsp;")
        .replace(/\s$/, "&nbsp;");
}
