import { FunctionComponent } from "react";
import { ILoadingSpinnerProps } from "./interfaces/ILoadingSpinnerProps";
import "./styles/loading-spinner.css";

const sizeRegex = /^[0-9]+(\.[0-9]+)?(px|%|em|rem)$/i;
export const LoadingSpinner: FunctionComponent<ILoadingSpinnerProps> = (props) => {
    if (!sizeRegex.test(props.size)) {
        throw new Error(`LoadingSpinner size prop is not a valid unit, The value must be in px, %, rem or em. ${props.size} was provided`);
    }

    return (
        <span className="cui-loading-spinner__container" data-hook="loading-spinner-container" style={{ height: props.size, width: props.size }}>
            <svg className="cui-loading-spinner__svg" data-hook="loading-spinner-svg" xmlns="http://www.w3.org/2000/svg" stroke={props.colour} preserveAspectRatio="xMidYMid" viewBox="0 0 100 100">
                <circle className="cui-loading-spinner__ball" cx="50" cy="50" r="47.5" fill="none" strokeDasharray="224.5 74.25663103256524" strokeWidth="5" />
            </svg>
        </span>
    );
};

LoadingSpinner.displayName = "LoadingSpinner";
