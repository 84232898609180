import { FunctionComponent, useId } from "react";
import { Icon } from "../icon/Icon.component";
import { ISegmentedControlProps } from "./interfaces/ISegmentedControlProps";
import "../../public-utilities.css";
import "./styles/segmented-control.css";

export const SegmentedControl: FunctionComponent<ISegmentedControlProps> = (props) => {
    const uniqueId = useId();
    return (
        <fieldset className={`${props.className !== undefined ? props.className : ""} cui-segmented-control`} data-hook="segmented-control">
            <legend className="cui-util-hide-element">{props.label}</legend>
            {props.buttons.map((button) => {
                return (
                    <div key={button.id} className="cui-segmented-control__control">
                        <input
                            className="cui-util-hide-element"
                            id={button.id + uniqueId}
                            name={uniqueId}
                            type="radio"
                            onChange={button.onChange}
                            defaultChecked={button.default}
                            disabled={props.disabled}
                        />
                        <label htmlFor={button.id + uniqueId} className="cui-segmented-control__label">
                            <Icon source={button.source} />
                            <span className="cui-util-hide-element" data-hook="segmented-control-label-text">
                                {button.label}
                            </span>
                        </label>
                    </div>
                );
            })}
        </fieldset>
    );
};
