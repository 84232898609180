import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../constants/mapToStandards.constants";
import { tracker } from "@pjs/analytics";
import "../../../mapToStandards/mapToStandardsWrapper/standardsRatingSlider/standardsRatingSlider.component";
import "../../../mapToStandards/mapToStandardsWrapper/standardsRatingInfo/standardsRatingInfo.component";
import "../../../multiLanguageService/multiLanguageService";
import "../../../userStatusPermissionsService/userStatusPermissions.service";
import "../../../feedback/feedbackComponents/approvalStatus/approvalStatus.component";
import "../../../inlineTextEditor/services/richTextEditingSettings.service";
import template from "./templates/sidebar-mapping.html";

class SidebarMappingComponent extends NgComponent {
    constructor($scope, multiLanguageService, standardsOverviewService, userStatusPermissions, richTextEditingSettings) {
        super();

        this._$scope = $scope;
        this.MAP_TO_STANDARDS_CONSTANTS = MAP_TO_STANDARDS_CONSTANTS;
        this.multiLanguageService = multiLanguageService;
        this.standardsOverviewService = standardsOverviewService;
        this.userStatusPermissions = userStatusPermissions;
        this.hasStandards = this.standards !== null && this.standards.length > 0;
        this.thumbDimensions = {
            thumbHeight: MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.THUMB_HEIGHT,
            thumbWidth: MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.THUMB_WIDTH
        };
        this.isCKEditor5Enabled = richTextEditingSettings.isEditorEnabled();
    }

    $onInit() {
        this._setStatus();

        this.userStatusPermissions
            .canSelfAssess(this.assetId)
            .then((response) => {
                this.canShowRating = response;
            })
            .catch(() => {
                this.canShowRating = false;
            });

        this.userStatusPermissions
            .canApprove(this.assetId)
            .then((response) => {
                this.canApprove = response;
            })
            .catch(() => {
                this.canApprove = false;
            });

        this.workspaceSharingInfoProps = {
            header: this.multiLanguageService.getString("sidebar.submissions.mapping_sharing_info.header"),
            content: this.multiLanguageService.getString("sidebar.submissions.mapping_sharing_info.content"),
            isOpen: false,
            onToggle: () => {
                this.workspaceSharingInfoProps.isOpen = !this.workspaceSharingInfoProps.isOpen;
                this._$scope.$applyAsync();
            }
        };
    }

    $onChanges() {
        this._setStatus();
    }

    _setStatus() {
        if (this.standards !== null && this.standards[0] !== undefined) {
            this.status = this.standardsOverviewService.getStatusInfo(this.standards[0].Status, this.standards[0].Recalled, this.standards[0].Released);
        }
    }

    _goToSubmissionLink(link) {
        tracker.trackEvent("EWC Mapping Approval", "Click", "Go to assessor view");
        window.open(link, "_blank");
    }
}

export const sidebarMapping = {
    bindings: {
        sidebarMessage: "@",
        submissionInfo: "<",
        standards: "<",
        assetId: "<"
    },
    template: template,
    controller: SidebarMappingComponent
};

SidebarMappingComponent.$inject = ["$scope", "multiLanguageService", "standardsOverviewService", "userStatusPermissions", "richTextEditingSettings"];
angularAMD.component("sidebarMapping", sidebarMapping);
