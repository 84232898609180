import { LazyDialogInstance } from "../dialog-instance/LazyDialogInstance";
import { dialogDirector } from "../dialog-director/DialogDirector.const";
import { ISingleStepDialogInstanceConfig } from "../dialog-instance/interfaces/ISingleStepDialogInstanceConfig";

export function launchDialog<TModel>(config: ISingleStepDialogInstanceConfig<TModel>): LazyDialogInstance<TModel> {
    const lazyInstance = new LazyDialogInstance<TModel>({
        dismissAction: config.dismissAction,
        initialModel: config.initialModel,
        size: config.size,
        steps: [
            {
                actions: {
                    left: [],
                    right: config.actions
                },
                component: config.component,
                title: config.title
            }
        ]
    });

    lazyInstance.load(dialogDirector);

    return lazyInstance;
}
