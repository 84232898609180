import { Keys } from "../../../../../enums/Keys";
import { setOpenedAndSelectFirstItem } from "../../reducers/SetOpenedAndSelectFirstItem.function";
import { setOpenedAndSelectLastItem } from "../../reducers/SetOpenedAndSelectLastItem.function";
import { toggleIsOpenAndResetIndex } from "../../reducers/ToggleIsOpenAndResetIndex.function";
import { toggleIsOpenAndResetIndexOnClick } from "../../reducers/ToggleIsOpenAndResetIndexOnClick.function";

export const menuRoleTriggerEvents = {
    [Keys.ArrowUp]: setOpenedAndSelectLastItem,
    [Keys.ArrowDown]: setOpenedAndSelectFirstItem,
    [Keys.Enter]: toggleIsOpenAndResetIndex,
    [Keys.Space]: toggleIsOpenAndResetIndex,
    onClick: toggleIsOpenAndResetIndexOnClick
};
