import { angularAMD } from "@pebblepad/amd";
import { ButtonClass } from "@pjs/core-ui";
import "../../utilities/baseUrlsFactory";
import "../../extensions/arrayExtensions";
import "../../expandable/expandable";
import "../services/copyService";
import "../../assetsWithLinks/directives/assetsWithLinks";
import "../../react2angular/buttonComponent";
import "../../react2angular/checkbox";
import template from "../templates/take-a-copy.html";

angularAMD.directive("takeACopy", [
    "$q",
    "$sce",
    "$rootScope",
    "baseUrlsFactory",
    "copyService",
    "$timeout",
    function ($q, $sce, $rootScope, baseUrlsFactory, copyService, $timeout) {
        return {
            template: template,
            restrict: "E",
            scope: {
                assetId: "=assetId"
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                    $scope.copyData = {
                        copyLinked: false
                    };
                    $scope.toggleCopyLinked = (isChecked) => {
                        $scope.copyData.copyLinked = isChecked;
                        $scope.$applyAsync();
                    };
                    $scope.assetsWithLinks = [];
                    $scope.dataHooks = {
                        copyButton: "modal-take-a-copy-btn",
                        cancelButton: "modal-cancel-btn"
                    };
                }
            ],
            link: function (scope, element, attrs) {
                scope.buttonClass = ButtonClass;

                const setModalText = () => {
                    switch ((scope.asset[0] || scope.asset).MainType.toLowerCase()) {
                        case "form":
                            scope.linked_message = $rootScope.multiLanguageService.getString("take_a_copy.labels.linked_message_resource");
                            scope.isResource = true;
                            break;
                        case "workbook":
                            scope.linked_message = $rootScope.multiLanguageService.getString("take_a_copy.labels.linked_message_resource");
                            scope.isResource = true;
                            break;
                        default:
                            scope.linked_message = $rootScope.multiLanguageService.getString("take_a_copy.labels.linked_message_asset");
                            scope.isResource = false;
                    }
                };

                const removeAssetWithLinksListener = scope.$watchCollection("assetsWithLinks", function (newValue) {
                    if (newValue.length > 0) {
                        scope.asset = newValue[0];
                        scope.copyData.title = scope.multiLanguageService.getString("take_a_copy.labels.copy", { title: angular.copy(scope.asset.Title) });
                        scope.copyData.copyLinked = true;
                        setModalText();
                    }
                });

                const overlay = angular.element(document.getElementsByClassName("builder-canvas-dark-layer")[0]);
                scope.sendCopyRequest = function () {
                    const copyRequest = {
                        AssetId: scope.asset.Id,
                        Title: scope.copyData.title,
                        NewTags: [],
                        CopyExistingTags: true,
                        CopyLinkedItems: scope.asset.Links.length ? scope.copyData.copyLinked : false,
                        TagLinkedAssets: true
                    };

                    // Start animation for saving.
                    overlay.addClass("show-overlay");

                    const copyPromise = copyService.takeACopy(copyRequest);
                    copyPromise.then(function (response) {
                        if (response.data && response.data.error) {
                            overlay.removeClass("copy-complete");
                            overlay.removeClass("show-overlay");
                        } else if (response.status === 200) {
                            $timeout(function () {
                                scope.copyCompleted(true);
                            }, 1000);
                        }

                        scope.$emit("copySuccessful", scope.asset.Id);
                        scope.$parent.hideModal();
                    });
                };

                scope.copyCompleted = function (queued) {
                    scope.$emit("copySuccessful", scope.asset.Id);
                    let timeoutLimit;
                    // Used for asset updates.
                    if (queued) {
                        timeoutLimit = 3000;
                        overlay.addClass("copy-request-queued");
                    } else {
                        timeoutLimit = 1500;
                        overlay.addClass("copy-complete");
                    }
                    // Remove overlay from saving animation
                    $timeout(function () {
                        // Finish up the saving animation
                        if (queued) {
                            overlay.removeClass("copy-request-queued");
                        } else {
                            overlay.removeClass("copy-complete");
                        }
                        overlay.removeClass("show-overlay");
                    }, timeoutLimit);
                };

                scope.cancel = function () {
                    scope.$parent.hideModal();
                };

                scope.$on("$destroy", function () {
                    removeAssetWithLinksListener();
                });
            }
        };
    }
]);
