import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ButtonClass } from "@pjs/core-ui";
import { ExportOptionItem } from "./ExportOptionItem";
import "../multiLanguageService/multiLanguageService";
import "../react2angular/buttonComponent";
import "../react2angular/checkbox";
import "../assetEndpointService/assetEndpoint.service";
import template from "./templates/export-options.html";

class ExportOptionsController extends NgComponent {
    constructor($scope, multiLanguageService, AssetEndpointService) {
        super();
        this.multiLanguageService = multiLanguageService;
        this.url = AssetEndpointService.exportToPdfUrl(this.assetId);
        this.buttonClass = ButtonClass;
        this.exporting = false;
        this._scope = $scope;
        this._assetEndpointService = AssetEndpointService;
        this.close = this.close.bind(this);
        this.startAgain = this.startAgain.bind(this);

        this.layoutOptions = [
            new ExportOptionItem(this._scope, "portraitPrinting", multiLanguageService.getString("export_options.labels.portrait"), true),
            new ExportOptionItem(this._scope, "landscapePrinting", multiLanguageService.getString("export_options.labels.landscape"))
        ];

        this.fileNameOptions = [
            new ExportOptionItem(this._scope, "assetTitle", multiLanguageService.getString("export_options.labels.asset_title"), true),
            new ExportOptionItem(this._scope, "prefixUsernameToFilename", multiLanguageService.getString("export_options.labels.prefix_username_to_filename"))
        ];

        this._populateOptionalContent();

        this.layout = { mode: this.layoutOptions[0].key };
        this.fileName = { mode: this.fileNameOptions[0].key };
    }

    $onChanges(changes) {
        if (changes.optionalContent !== undefined && !changes.optionalContent.isFirstChange()) {
            this._populateOptionalContent();
        }
    }

    onSubmit() {
        this.exporting = true;
    }

    close() {
        if (typeof this.onComplete === "function") {
            this.onComplete();
            this._scope.$applyAsync();
        }
    }

    startAgain() {
        this.exporting = false;
        this._scope.$applyAsync();
    }

    _populateOptionalContent() {
        this.optionalContentItems = [
            new ExportOptionItem(this._scope, "linked", this.multiLanguageService.getString("export_options.labels.linked_items")),
            new ExportOptionItem(this._scope, "downloadLinked", this.multiLanguageService.getString("export_options.labels.download_linked_items")),
            new ExportOptionItem(this._scope, "banners", this.multiLanguageService.getString("export_options.labels.banners")),
            new ExportOptionItem(this._scope, "footers", this.multiLanguageService.getString("export_options.labels.footers"), true)
        ];

        if (this.optionalContent.comments) {
            this.optionalContentItems.push(new ExportOptionItem(this._scope, "comments", this.multiLanguageService.getString("export_options.labels.comments")));
        }

        if (this.optionalContent.feedback) {
            this.optionalContentItems.push(new ExportOptionItem(this._scope, "feedbackAndGrades", this.multiLanguageService.getString("export_options.labels.feedback")));
        }
    }
}

ExportOptionsController.$inject = ["$scope", "multiLanguageService", "AssetEndpointService"];

angularAMD.component("exportOptions", {
    bindings: {
        assetId: "<",
        optionalContent: "<",
        onComplete: "&?",
        allowMultipleExports: "<?",
        exportTitle: "<?"
    },
    template: template,
    controller: ExportOptionsController
});
