import { Observable, of, share, tap } from "@pjs/observables";
import { FeedbackStatementsList } from "../../mappers/feedbackStatementsMapper/types/FeedbackStatementsList";
import { getFeedbackStatements } from "../functions/getFeedbackStatementsRequest.function";

export class StatementCollection {
    private readonly _statementCache: Map<number, FeedbackStatementsList> = new Map();

    public getStatements(submissionId: number): Observable<FeedbackStatementsList> {
        const statements = this._statementCache.get(submissionId);

        return statements !== undefined ? of(statements) : this._fetchStatementsForSubmission(submissionId);
    }

    public removeStatements(submissionId: number): void {
        this._statementCache.delete(submissionId);
    }

    public clearCache(): void {
        this._statementCache.clear();
    }

    private _fetchStatementsForSubmission(submissionId: number): Observable<FeedbackStatementsList> {
        const request = getFeedbackStatements(submissionId);

        return request.pipe(
            tap((statements) => {
                this._statementCache.set(submissionId, statements);
            }),
            share()
        );
    }
}
