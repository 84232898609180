import { ProcessAttribute } from "@pjs/security";

export function processCssClassFactory(validClassesUnion: string): ProcessAttribute {
    const allValidClassesMatcher = new RegExp(`^(?:(?:${validClassesUnion})(?:\\s|$))+$`);
    const validClassesMatcher = new RegExp(`(?:${validClassesUnion})(?:\\s|$)`, "g");

    return (value) => {
        if (allValidClassesMatcher.test(value)) {
            return value;
        }

        const validClassesMatch = value.match(validClassesMatcher);
        return validClassesMatch !== null ? validClassesMatch.join("").trim() : null;
    };
}
