import { FunctionComponent } from "react";
import { ParseHtml } from "@pjs/security";
import { StyledCollapsible } from "../styled-collapsible/StyledCollapsible.component";
import { ICollapsibleInformationProps } from "./interfaces/ICollapsibleInformationProps";

import "./styles/collapsible-information.css";

export const CollapsibleInformation: FunctionComponent<ICollapsibleInformationProps> = (props) => {
    return (
        <StyledCollapsible header={props.header} isContained={props.isContained} onToggle={props.onToggle} isOpen={props.isOpen}>
            <ParseHtml className="cui-collapsible-information__content-text" htmlString={props.content} data-hook="collapsible-information-content" />
        </StyledCollapsible>
    );
};
