import { IHtmlTagConfig, isSafeUrlProtocol } from "@pjs/security";

export const anchorConfig: IHtmlTagConfig = {
    attributes: {
        href: (value) => (isSafeUrlProtocol(value) ? value : null),
        rel: (value) => (/^\s*(?:(?:noreferrer|noopener)\s*){1,2}\s*$/.test(value) ? value : ""),
        target: (value) => (value === "_blank" ? value : null)
    },
    styles: null
};
