import { ngHtmlStringSanitiser } from "./NgHtmlStringSanitiser.const";

PebbleSecurityService.$inject = [];

/**
 * Provides helper methods for a secure PebblePad
 * @constructor
 */
function PebbleSecurityService() {
    this._safeSrcRegex = new RegExp("^(" + location.origin + "|.|..|data:image)/.*", "i");
}

/**
 * Sanitise the given string, removing any potentially dangerous HTML.
 * Do NOT use this method to sanitise strings intended as values for
 * attributes (especially 'src' or 'href').
 * @param {String} input The html string
 */
PebbleSecurityService.prototype.sanitise = function (input) {
    return ngHtmlStringSanitiser.clean(input);
};

PebbleSecurityService.prototype.sanitiseSauce = function (src) {
    return this._safeSrcRegex.test(src) ? src : "";
};

export { PebbleSecurityService };
