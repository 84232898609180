import { IMatomoClient } from "../matomo/interfaces/IMatomoClient";

export class NoopClient implements IMatomoClient {
    public forgetUserOptOut(): void {
        // Noop function
    }

    public optUserOut(): void {
        // Noop function
    }

    public setCustomUrl(): void {
        // Noop function
    }

    public setCustomDimension(): void {
        // Noop function
    }

    public trackEvent(): void {
        // Noop function
    }

    public trackPageView(): void {
        // Noop function
    }
}
