import { angularAMD } from "@pebblepad/amd";

//Segment used for Menu, Products and Tools
var SpaSegmentProperties = function (display, excludeRoles, filters, order) {
    this.display = display;
    this.excludeRoles = excludeRoles || null;
    this.filters = filters || null;
    this.order = order || null; //Used to sort the order of appearance within a segment.
};

angularAMD.factory("spaDisplayHelper", function () {
    return {
        createSegment: function (display, excludeRoles, filters, order) {
            return new SpaSegmentProperties(display, excludeRoles, filters, order);
        },
        //Check whether a segment should be shown depending on a value in an obj and the excludeRoles
        checkRoles: function (segment, obj) {
            if (segment.excludeRoles !== null) {
                var keys = Object.keys(obj),
                    index;
                for (var i = 0, l = segment.excludeRoles.length; i < l; i++) {
                    index = keys.indexOf(segment.excludeRoles[i]);

                    if (index !== -1 && obj[keys[index]] === true) {
                        return false;
                    }
                }
                return true;
            }
            return segment.display;
        },

        filter: function (segment, obj) {
            const filteredResults = [];

            if (segment.filters !== null) {
                for (let i = 0; i < segment.filters.length; i++) {
                    let item = segment.filters[i];
                    if (typeof obj[item] !== "undefined") {
                        filteredResults.push(obj[item]);
                    }
                }
            } else {
                //If no filters, return an array of all the buttons.
                var keys = Object.keys(obj);
                for (let i = 0; i < keys.length; i++) {
                    filteredResults.push(obj[keys[i]]);
                }
            }
            return filteredResults;
        },
        sort: function (segment, toSort, property) {
            if (segment.order !== null) {
                var valueIndex = -1,
                    nextValueIndex = -1,
                    length = toSort.length - 1;

                toSort.sort(function (value, nextValue) {
                    valueIndex = segment.order.indexOf(value[property]);
                    nextValueIndex = segment.order.indexOf(nextValue[property]);

                    //If an item is not in the order array, it gets pushed to the back of the list
                    if (valueIndex === -1) {
                        valueIndex = length;
                    }
                    if (nextValueIndex === -1) {
                        nextValueIndex = length;
                    }

                    return valueIndex - nextValueIndex;
                });
            }
        }
    };
});
