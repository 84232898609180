import { angularAMD } from "@pebblepad/amd";
import "../../spaMenu/assetInfoPanel/assetFeedback/assetFeedbackService";
import "../../user/user.service";
import "../../builder/dataManager/helperService";

class ReplyDataService {
    constructor(assetFeedbackService, User, helperService, $sce) {
        this._assetFeedbackService = assetFeedbackService;
        this._user = User;
        this._helperService = helperService;
        this._$sce = $sce;
    }

    addNewReply(body, author, parentId, assetId, anchorId) {
        const replyDto = this._createInsertModel(null, body, parentId, author || this._user.getFullName(), assetId, anchorId);
        return this._assetFeedbackService.addNewOrUpdateReplyToFeedback(replyDto);
    }

    updateReply(dto, id, anchorId) {
        const replyDto = this._createUpdateModel(dto, id, anchorId);
        return this._assetFeedbackService.addNewOrUpdateReplyToFeedback(replyDto);
    }

    deleteReply(dto) {
        return this._assetFeedbackService.deleteReplyToFeedback(dto);
    }

    _createUpdateModel(dto, parentId, anchorId) {
        return {
            AssetId: dto.AssetId,
            Id: dto.Id,
            Body: dto.Body,
            ParentId: parentId,
            AnchorId: anchorId
        };
    }

    _createInsertModel(id, bodyText, parentId, posterName, assetId, anchorId) {
        return {
            AnchorId: anchorId || "",
            AssetId: assetId || "",
            Body: bodyText ? this._$sce.trustAsHtml(bodyText).toString() : "",
            Id: id || this._helperService.guid(),
            ParentId: parentId ? parentId : "",
            PosterName: posterName ? this._$sce.trustAsHtml(posterName).toString() : ""
        };
    }
}

ReplyDataService.$inject = ["assetFeedbackService", "User", "helperService", "$sce"];
angularAMD.service("replyDataService", ReplyDataService);
