import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../services/mediaAssetService";
import template from "../templates/media-asset.html";

angularAMD.directive("mediaAsset", [
    "$rootScope",
    "$sce",
    "$interval",
    "$routeParams",
    "baseUrlsFactory",
    "mediaAssetService",
    function ($rootScope, $sce, $interval, $routeParams, baseUrlsFactory, mediaAssetService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                video: "@",
                audio: "@",
                assetId: "=",
                isComplete: "@",
                pollInterval: "@",
                margin: "@"
            },
            link: function ($scope, element, attrs) {
                $scope.multiLanguageService = $rootScope.multiLanguageService;

                $scope.init = function () {
                    if (typeof $scope.assetId === "undefined") {
                        $scope.assetId = attrs.assetId;
                    }

                    $scope._isComplete = $scope.isComplete !== undefined && $scope.isComplete !== false.toString();
                    if (!$scope.pollInterval) {
                        $scope.pollInterval = 2000;
                    }
                    $scope.ready = false;

                    if ($scope._isComplete) {
                        getStreamUrl();
                    } else {
                        $scope._isComplete = true; //Don't show the transcoding message until after the first response.
                        mediaAssetService.pollUploadComplete($scope.assetId, $scope.pollInterval, function (isComplete) {
                            if (isComplete) {
                                $scope._isComplete = true;
                                getStreamUrl();
                            } else {
                                $scope._isComplete = false;
                            }
                        });
                    }
                };

                function getStreamUrl() {
                    mediaAssetService.getDirectFileStream($scope.assetId, $routeParams.timeslice).then(function (response) {
                        $scope.sourceUrl = $sce.trustAsResourceUrl(response.data.Key);
                        $scope.sourceSubType = response.data.Value;
                        $scope.ready = true;
                    });
                }

                $scope.$on("$destroy", function () {
                    if (!$scope._isUploaded) {
                        mediaAssetService.stopPolling($scope.assetId);
                    }
                });

                $scope.init();
            }
        };
    }
]);
