const styleOptionKeys = {
    WORD_COUNT_ENABLED: "word-count-enabled",
    INCLUDE_TITLE: "include-title",
    INFIELD_HINT: "infield-hint",
    ADD_EVIDENCE_COMPONENT: "add-evidence-component",
    SHOW_HINT_WHEN_VIEWING: "show-hint-when-viewing",
    SHOW_EMPTY_FIELD_WHEN_VIEWING: "show-empty-field-when-viewing",
    SHOW_HINT_BLOCK_WHEN_VIEWING: "show-hint-block-when-viewing",
    PRIVACY_STATE: "privacy-state",
    MANDATORY_FOR_PROGRESS: "mandatory-for-progress",
    CUSTOM_ASSESSOR_FIELD_LABEL: "custom-assessor-field-label",
    CAPABILITY_EVIDENCE_AMOUNT: "capabilityEvidenceAmount"
};

export { styleOptionKeys as STYLE_OPTIONS };
