import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { selectPreviousItem } from "./SelectPreviousItem.function";

export function selectPreviousItemWithWrapping<T>(e: KeyboardEvent, currentModel: Immutable<IAriaModelWithType>, items: Array<T>): Partial<Immutable<IAriaModelWithType>> {
    const model = selectPreviousItem(e, currentModel);

    if (model === null) {
        return {
            activeItemIndex: items.length - 1,
            type: AriaMenuStatus.Open
        };
    }

    return model;
}
