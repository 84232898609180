import { angularAMD } from "@pebblepad/amd";

ScrollToItemDirective.$inject = [];
function ScrollToItemDirective() {
    return {
        restrict: "A",
        scope: {
            options: "<?",
            onScroll: "&"
        },
        controller: ScrollToItemController,
        bindToController: true
    };
}

ScrollToItemController.$inject = ["$element", "$attrs"];
function ScrollToItemController($element, $attrs) {
    this._$element = $element;
    this._$attrs = $attrs;
    this.options = this.options || { block: "end", behavior: "smooth" };

    this._$attrs.$observe(
        "scrollToItem",
        function (newValue) {
            if (newValue !== "") {
                this._scrollToItem(newValue);
            }
        }.bind(this)
    );
}

ScrollToItemController.prototype._scrollToItem = function (itemId) {
    var element = this._$element[0].querySelector("#" + itemId);

    if (element) {
        element.scrollIntoView(this.options);
    }

    this.onScroll();
};

angularAMD.directive("scrollToItem", ScrollToItemDirective);
export { ScrollToItemDirective as scrollToItem };
