import { angularAMD } from "@pebblepad/amd";

angularAMD.directive("onRepeatEnd", [
    "$timeout",
    function ($timeout) {
        return {
            scope: true,
            restrict: "A",

            link: function (scope, _, attrs) {
                if (scope.$last) {
                    var fnName = attrs.onRepeatEnd || "init";

                    var properties = fnName.split(".");
                    var value = scope;
                    var context = null;

                    for (var i = 0; i < properties.length; i++) {
                        context = value;
                        value = value[properties[i]];

                        if (!value) {
                            return;
                        }
                    }

                    $timeout(value.bind(context));
                }
            }
        };
    }
]);
