import { angularAMD } from "@pebblepad/amd";
import "../../dataManager/helperService";
import "../../../multiLanguageService/multiLanguageService";

angularAMD.service("BlockInputOptionsHelper", BlockInputOptionsHelper);
BlockInputOptionsHelper.$inject = ["helperService", "multiLanguageService"];

function BlockInputOptionsHelper(helperService, multiLanguageService) {
    //Public API
    //=============================================================================================================
    this.getConfig = getConfig;
    this.getModelKeyFromType = getModelKeyFromType;
    this.getOptions = getOptions;
    this.setOptions = setOptions;
    this.getInputType = getInputType;
    this.getStyleKeys = getStyleKeys;
    this.createOptionDto = createOptionDto;
    this.validAmountOfItemsPerRow = validAmountOfItemsPerRow;
    this.getDefaultNumberOfOptions = getDefaultNumberOfOptions;
    this.getInlineOptions = getInlineOptions;

    //Private Variables
    //=============================================================================================================
    var defaultNumberOfOptions = 3; //Number of options that a newly created Block should contain

    var inlineOptionsConfig = {
        //Display horizontal config options - min, max, default
        min: 1,
        max: 6,
        default: 3
    };

    var typeConfigs = {
        "BuilderOptionsList-Checkboxes": {
            modelKey: "Checkboxes",
            inputType: "checkbox",
            styleKeys: {
                privacy: "privacy-state"
            },
            forceEvidence: false,
            labels: {
                add: multiLanguageService.getString("labels.builder.add_checkbox_button")
            }
        },

        "BuilderOptionsList-RadioButtons": {
            modelKey: "Radios",
            inputType: "radio",
            styleKeys: {
                privacy: "privacy-state"
            },
            forceEvidence: false,
            labels: {
                add: multiLanguageService.getString("labels.builder.add_radio_btn_button")
            }
        },
        "BuilderCapabilityOptionList-RadioButtons": {
            modelKey: "Radios",
            inputType: "radio",
            styleKeys: {
                privacy: "privacy-state-private-only"
            },
            forceEvidence: true,
            labels: {
                add: multiLanguageService.getString("labels.builder.add_radio_btn_button")
            }
        }
    };

    //Private methods
    //=============================================================================================================
    /**
     * @param {string} type
     * @returns {Object|null}
     */
    function getConfig(type) {
        return typeConfigs[type] || null;
    }

    /**
     * @param {string} type
     * @returns {string}
     */
    function getModelKeyFromType(type) {
        var typeObj = typeConfigs[type];
        return typeObj !== void 0 ? typeObj.modelKey : "";
    }

    /**
     * @param {Object} dto
     * @returns {Object|null}
     */
    function getOptions(dto) {
        var modelKey = getModelKeyFromType(dto.SectionType);
        return dto[modelKey] || null;
    }

    /**
     * @param {Object} dto
     * @param {Object[]} options
     */
    function setOptions(dto, options) {
        var modelKey = getModelKeyFromType(dto.SectionType);
        dto[modelKey] = options || [];
    }

    /**
     * @param {string} type
     * @returns {*}
     */
    function getInputType(type) {
        var typeObj = typeConfigs[type];
        return typeObj !== void 0 ? typeObj.inputType : "";
    }

    /**
     * @param {string} type
     * @returns {Object|null}
     */
    function getStyleKeys(type) {
        var typeObj = typeConfigs[type];
        return typeObj !== void 0 ? typeObj.styleKeys : null;
    }

    /**
     * @returns {{Key: string, Value: string}}
     */
    function createOptionDto() {
        return {
            Key: helperService.guid(),
            Value: ""
        };
    }

    /**
     * @param {number} itemsPerRow
     * @returns {Boolean}
     */
    function validAmountOfItemsPerRow(itemsPerRow) {
        return itemsPerRow >= inlineOptionsConfig.min && itemsPerRow <= inlineOptionsConfig.max;
    }

    /**
     * @returns {number}
     */
    function getDefaultNumberOfOptions() {
        return defaultNumberOfOptions;
    }

    /**
     * @returns {{min: number, max: number, default: number}}
     */
    function getInlineOptions() {
        return inlineOptionsConfig;
    }
}
