import { angularAMD } from "@pebblepad/amd";

angularAMD.service("IconHelper", IconHelper);
IconHelper.$inject = [];

function IconHelper() {
    var mainTypeMap = {
        activitylog: "icon-activitylog",
        assetcollectionwrapper: "icon-assetcollectionwrapper",
        blog: "icon-blog",
        conversation: "icon-summary",
        cv: "icon-cv",
        file: "icon-file",
        post: "icon-post",
        tempatebuilder: "icon-form-reversed",
        webfolio: "icon-webfolio",
        webfoliopage: "icon-webfoliopage",
        workbookresponse: "icon-workbook",
        workbookbuilder: "icon-workbook-reversed"
    };

    var subTypeMap = {
        audio: "icon-audio",
        image: "icon-image",
        document: "icon-document",
        "document/database": "icon-database",
        "document/pdf": "icon-pdf",
        "document/presentation": "icon-presentation",
        "document/spreadsheet": "icon-spreadsheet",
        "document/text": "icon-text",
        experience: "icon-experience",
        feedback: "icon-feedback-template",
        plan: "icon-plan",
        other: "icon-other",
        reflection: "icon-reflection",
        talent: "icon-talent",
        worksheet: "icon-worksheet",
        video: "icon-video"
    };

    /**
     * @param {Object} map
     * @param {String} type
     * @returns {String}
     */
    this.getIcon = function (map, type) {
        if (!type) {
            return "";
        }

        var icon = map[type.toLowerCase()];
        return icon || "";
    };

    /**
     * @param {String} type
     * @returns {String}
     */
    this.getIconFromMainType = function (type) {
        return this.getIcon(mainTypeMap, type);
    };

    /**
     * @param {String} type
     * @returns {String}
     */
    this.getIconFromSubType = function (type) {
        return this.getIcon(subTypeMap, type);
    };

    /**
     * @param {String} type
     * @returns {string}
     */
    this.getIconFromType = function (type) {
        var icon = this.getIconFromMainType(type);
        return icon ? icon : this.getIconFromSubType(type);
    };
}
