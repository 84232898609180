import { angularAMD } from "@pebblepad/amd";

angularAMD.service("assetMapper", function () {
    this.collectionWrapper = {};
    this.collection = {
        advancedFiltersToCollection: function (filters, collectionDto) {
            collectionDto.Search.Keywords = filters.keywords.Filter ? filters.keywords.Filter.split(" ") : null;
            collectionDto.Search.SubType = filters.assetTypes.OriginalType !== "Category" ? filters.assetTypes.Filter : null;

            if (filters.responsesTo.Filter !== null) {
                collectionDto.Search.FormId = filters.responsesTo.Filter.Id;
                collectionDto.Search.ResponsesTo = {
                    Id: filters.responsesTo.Filter.Id,
                    Title: filters.responsesTo.Filter.Title,
                    Icon: filters.responsesTo.Filter.Icon,
                    LastModified: filters.responsesTo.Filter.LastModified
                };
            } else {
                collectionDto.Search.FormId = null;
                collectionDto.Search.ResponsesTo = null;
            }

            collectionDto.Filter.Category = filters.assetTypes.Filter;
            collectionDto.Filter.Ownership = filters.ownership.Filter;
            collectionDto.Search.EnableSearchByDefault = filters.enableSearchByDefault.Filter;
            collectionDto.Search.Tags = filters.tags.Filter.slice(0);

            if (filters.dateFrom.Filter) {
                collectionDto.Search.CreatedAfter = {};
                collectionDto.Search.CreatedAfter.DateValue = filters.dateFrom.Filter;
            } else {
                collectionDto.Search.CreatedAfter = null;
            }

            if (filters.dateTo.Filter) {
                collectionDto.Search.CreatedBefore = {};
                collectionDto.Search.CreatedBefore.DateValue = filters.dateTo.Filter;
            } else {
                collectionDto.Search.CreatedBefore = null;
            }
        },
        collectionDtoToCriteria: function (collectionDto, criteria) {
            criteria.assetTypes = collectionDto.Search.SubType || collectionDto.Filter.Category;
            criteria.responsesTo = collectionDto.Search.ResponsesTo === undefined ? null : collectionDto.Search.ResponsesTo;
            criteria.ownership = collectionDto.Filter.Ownership;
            criteria.tags = collectionDto.Search.Tags.slice(0);
            criteria.dateFrom = collectionDto.Search.CreatedAfter ? collectionDto.Search.CreatedAfter.DateValue : null;
            criteria.dateTo = collectionDto.Search.CreatedBefore ? collectionDto.Search.CreatedBefore.DateValue : null;
            criteria.keywords = collectionDto.Search.Keywords ? collectionDto.Search.Keywords.join(" ") : null;
            criteria.enableSearchByDefault = collectionDto.Search.EnableSearchByDefault ?? false;
        },

        collectionDtoToPage: function (collectionDto, page) {
            page.CollectionAssetType = collectionDto.Search.SubType || collectionDto.Filter.Category;
            page.CollectionResponsesTo =
                collectionDto.Search.ResponsesTo === null
                    ? null
                    : {
                          Id: collectionDto.Search.ResponsesTo.Id,
                          Title: collectionDto.Search.ResponsesTo.Title,
                          Icon: collectionDto.Search.ResponsesTo.Icon,
                          LastModified: collectionDto.Search.ResponsesTo.LastModified
                      };
            page.CollectionTags = collectionDto.Search.Tags.slice(0);
            page.CollectionCreatedAfter = collectionDto.Search.CreatedAfter ? collectionDto.Search.CreatedAfter.DateValue : null;
            page.CollectionCreatedBefore = collectionDto.Search.CreatedBefore ? collectionDto.Search.CreatedBefore.DateValue : null;
            page.CollectionEnableSearchByDefault = collectionDto.Search.EnableSearchByDefault ?? false;
        },

        collectionPageToDto: function (page, collectionDto) {
            if (page.CollectionCategoryOrAssetType === "Category") {
                collectionDto.Filter.Category = page.CollectionAssetType;
            } else {
                collectionDto.Search.SubType = page.CollectionAssetType;
            }
            collectionDto.Search.ResponsesTo = page.CollectionResponsesTo;
            collectionDto.Search.Tags = page.CollectionTags.slice(0);
            collectionDto.Search.CreatedAfter = page.CollectionCreatedAfter ? { DateValue: new Date(page.CollectionCreatedAfter) } : null;
            collectionDto.Search.CreatedBefore = page.CollectionCreatedBefore ? { DateValue: new Date(page.CollectionCreatedBefore) } : null;
            collectionDto.Search.EnableSearchByDefault = page.CollectionEnableSearchByDefault;
        }
    };
});
