import { DeepPartial } from "@pjs/utilities";
import { i18n } from "i18next";
import { noop } from "../../utils/noop";
import { AsyncResolver } from "../../interfaces/AsyncResolver";
import { SingularPaths } from "../../types/SingularPaths";
import { PluralPaths } from "../../types/PluralPaths";
import { PluralProperties } from "../../types/PluralProperties";
import { ResourceLoader } from "../resource-loader/ResourceLoader";
import { NumberFormatter } from "../number-formatter/NumberFormatter";
import { invalidNumberFormatter } from "../number-formatter/InvalidNumberFormatter";
import { INumberFormatter } from "../number-formatter/interfaces/INumberFormatter";
import { I18nSetNamespace } from "./enums/I18nSetNamespace";
import { I18nInterpolationProperties } from "./types/I18nInterpolationProperties";
import { II18nSet } from "./interfaces/II18nSet";

export class I18nSet<T extends Record<string, unknown>> implements II18nSet<T> {
    protected readonly _i18next: i18n;
    protected readonly _resourceLoader: ResourceLoader<DeepPartial<T>>;
    private _numberFormatter: INumberFormatter;
    private _defaultLanguage: string = "";
    private _language: string = "";

    constructor(i18nextFactory: i18n, escapeValue: boolean = true, formatParser?: (languageCode: string, value: any, format: string) => string) {
        this._i18next = i18nextFactory.createInstance({
            contextSeparator: "^",
            defaultNS: "",
            fallbackLng: "",
            initImmediate: true,
            interpolation: {
                escapeValue: escapeValue,
                format: (value, format) => {
                    if (formatParser !== undefined && format !== undefined) {
                        return formatParser(this._i18next.language, value, format);
                    }

                    if (typeof value === "string") {
                        return value;
                    }

                    return "";
                },
                skipOnVariables: false
            },
            pluralSeparator: "__",
            resources: {}
        });

        this._i18next.init().catch(noop);
        this._resourceLoader = new ResourceLoader<DeepPartial<T>>(this._addResourceToI18next.bind(this));
        this._numberFormatter = invalidNumberFormatter;
    }

    public add(languageCode: string, resolver: AsyncResolver<DeepPartial<T>>): this {
        this._resourceLoader.add(I18nSetNamespace.String, languageCode, resolver);
        return this;
    }

    public getLanguage(): string {
        return this._language;
    }

    public getDefaultLanguage(): string {
        return this._defaultLanguage;
    }

    public setDefaultLanguage(languageCode: string): this {
        this._defaultLanguage = languageCode;
        this._i18next.options.fallbackLng = languageCode;
        return this;
    }

    public setLanguage(languageCode: string): this {
        this._language = languageCode;
        this._i18next.changeLanguage(languageCode).catch(noop);
        this._numberFormatter = languageCode !== "" ? new NumberFormatter(languageCode) : invalidNumberFormatter;
        return this;
    }

    public changeLanguage(languageCode: string): Promise<void> {
        const currentLanguage = this.getLanguage();
        return this.setLanguage(languageCode)
            .load()
            .catch((error) => {
                this.setLanguage(currentLanguage);
                throw error;
            });
    }

    public getString(key: SingularPaths<T>, properties?: I18nInterpolationProperties): string {
        return this._i18next.t(`${I18nSetNamespace.String}:${key}`, "", properties);
    }

    public getPluralString<P extends { count: number }>(key: PluralPaths<T>, properties: PluralProperties<P>): string {
        return this._i18next.t(`${I18nSetNamespace.String}:${key}`, "", properties);
    }

    public load(languageCode: string = this._i18next.language): Promise<void> {
        if (this._i18next.options.fallbackLng !== languageCode) {
            return this._resourceLoader.load(this._i18next.options.fallbackLng as string).finally(() => {
                return this._resourceLoader.load(languageCode);
            });
        }

        return this._resourceLoader.load(languageCode);
    }

    public formatNumber(value: number): string {
        return this._numberFormatter.formatNumber(value);
    }

    public parseNumber(value: string): number {
        return this._numberFormatter.parseNumber(value);
    }

    private _addResourceToI18next(languageCode: string, namespace: string, resource: DeepPartial<T>): void {
        this._i18next.addResourceBundle(languageCode, namespace, resource);
    }
}
