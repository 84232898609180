import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("mediaAssetService", [
    "$http",
    "$timeout",
    "baseUrlsFactory",
    "$q",
    "urlService",
    "AssetEndpointService",
    function ($http, $timeout, baseUrlsFactory, $q, urlService, AssetEndpointService) {
        var timeoutStore = {};

        function promisedGet(url, params) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.get(url, {
                timeout: httpTimeout.promise,
                params: params
            });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        function promisedPost(url, data) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.post(url, data, { timeout: httpTimeout.promise });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        function isComplete(assetId) {
            return promisedGet(baseUrlsFactory.api_base_url + "File/IsComplete?id=" + assetId);
        }

        function pollIsComplete(assetId, interval, callback) {
            var timeoutPromise = $timeout(function () {
                isComplete(assetId).then(function (response) {
                    removeTimeout(assetId, timeoutPromise);
                    if (response.data) {
                        callback(true);
                    } else {
                        pollIsComplete(assetId, interval, callback);
                    }
                });
            }, interval);

            if (timeoutStore[assetId] === undefined) {
                timeoutStore[assetId] = [];
            }

            timeoutStore[assetId].push(timeoutPromise);
        }

        function removeTimeout(assetId, timeoutPromise) {
            var timeoutList = timeoutStore[assetId];
            if (timeoutList !== undefined) {
                var index = timeoutList.indexOf(timeoutPromise);
                if (index !== -1) {
                    timeoutList.splice(index);
                }

                $timeout.cancel(timeoutPromise);
            }
        }

        return {
            getDirectFileStream: function (id, timeslice) {
                var params = { id: id };

                if (parseInt(timeslice, 10)) {
                    params.timeslice = timeslice;
                }

                return AssetEndpointService.getDirectStreamLink(params);
            },
            isComplete: isComplete,

            pollUploadComplete: function (id, interval, callback) {
                if (id === undefined || id === "") {
                    return callback(true);
                }
                isComplete(id).then(function (response) {
                    if (response.data === true) {
                        callback(true);
                    } else {
                        callback(false);
                        pollIsComplete(id, interval, callback);
                    }
                });
            },
            stopPolling: function (assetId) {
                var timeoutList = timeoutStore[assetId];
                if (timeoutList !== undefined) {
                    for (var i = 0; i < timeoutList.length; i++) {
                        $timeout.cancel(timeoutList[i]);
                    }

                    timeoutList.length = 0;
                    delete timeoutStore[assetId];
                }
            },
            getActiveTimeoutsForAssetId: function (assetId) {
                return timeoutStore[assetId];
            }
        };
    }
]);
