import { exhaustMap, first, from, KillSignal, map, Observable, of, takeUntil, tap } from "@pjs/observables";
import { DecoupledEditor } from "@pebblepad/ckeditor";
import { noop, preventDefault } from "@pjs/utilities";
import { tracker } from "@pjs/analytics";
import { IEditorConfig } from "../editor-factories/interfaces/IEditorConfig";
import { InteractionTracker } from "../editor-interactions/InteractionTracker";
import { insertMarkerWithinContainerSelection } from "../insert-marker-within-container-selection/InsertMarkerWithinContainerSelection.function";
import { PpMarkerRange } from "../pp-marker-range/PpMarkerRange";
import { IEditorDataHandler } from "../editor/interfaces/IEditorDataHandler";
import { SlowProcessReporter } from "../../utils/slow-process-reporter/SlowProcessReporter";
import { generateElapsedTimeEventName } from "../../utils/slow-process-reporter/GenerateElapsedTimeEventName.function";
import { IEditorViewState } from "./interfaces/IEditorViewState";
import { ICKEditorAdapter } from "./interfaces/ICKEditorAdapter";

export class CKEditorAdapter implements ICKEditorAdapter {
    private readonly _interactionTracker: InteractionTracker;
    private readonly _sourceElement: HTMLElement;
    private readonly _killSignal: KillSignal;
    private readonly _slowStartupReporter: SlowProcessReporter;
    private _creationPromise: Promise<DecoupledEditor> | null;

    constructor(sourceElement: HTMLElement) {
        this._interactionTracker = new InteractionTracker(sourceElement);
        this._sourceElement = sourceElement;
        this._creationPromise = null;
        this._killSignal = new KillSignal();
        this._slowStartupReporter = new SlowProcessReporter(this._logSlowStartup.bind(this), 500, 5000);
    }

    private static _preventPaste(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    public startEventTracking(): void {
        this.stopEventTracking();

        this._interactionTracker.startTracking();
        this._sourceElement.addEventListener("paste", CKEditorAdapter._preventPaste);
    }

    public stopEventTracking(): void {
        this._interactionTracker.stopTracking();
        this._sourceElement.removeEventListener("paste", CKEditorAdapter._preventPaste);
    }

    public load(editorConfig: IEditorConfig, dataHandler: IEditorDataHandler): Observable<IEditorViewState> {
        this._creationPromise = this._createInstance(editorConfig);

        return from(this._creationPromise).pipe(
            exhaustMap((ckeInstance) => {
                const hasInteraction = this._interactionTracker.hasEventsInProgress();

                if (hasInteraction) {
                    return this._interactionTracker.onInteractionsEnd().pipe(
                        first(),
                        map(() => ckeInstance)
                    );
                }

                return of(ckeInstance);
            }),
            exhaustMap((ckeInstance) => this._finishSetup(ckeInstance, dataHandler)),
            tap(() => {
                this._creationPromise = null;
                this.stopEventTracking();
            }),
            takeUntil(this._killSignal)
        );
    }

    public destroy(): void {
        this.stopEventTracking();
        this._killSignal.send();
        this._enableInputEvents();

        if (this._creationPromise !== null) {
            this._creationPromise
                .then((ckeInstance) => {
                    ckeInstance.destroy().catch(noop);

                    const toolbar = ckeInstance.ui.view.toolbar.element;
                    if (toolbar !== null && toolbar !== undefined) {
                        toolbar.remove();
                    }
                })
                .catch(noop);
        }
    }

    private _createInstance(editorConfig: IEditorConfig): Promise<DecoupledEditor> {
        const ckeInstance = new DecoupledEditor(this._sourceElement, editorConfig) as DecoupledEditor;
        ckeInstance.editing.view.disableObservers();

        return ckeInstance.initPlugins().then(() => ckeInstance);
    }

    private _finishSetup(ckeInstance: DecoupledEditor, dataHandler: IEditorDataHandler): Promise<IEditorViewState> {
        const sourceElementHasFocus = document.activeElement === this._sourceElement;
        const userSelectionMarker = sourceElementHasFocus ? insertMarkerWithinContainerSelection(this._sourceElement) : null;
        const hasUserUpdatedContent = this._interactionTracker.hasInsertedContent();

        const initialDataToLoad = dataHandler.getData();
        let content = hasUserUpdatedContent || userSelectionMarker !== null ? this._sourceElement.innerHTML : initialDataToLoad;

        this._disableInputEvents();

        this._slowStartupReporter.start();
        ckeInstance.ui.init();

        return ckeInstance.data.init(content).then(() => {
            ckeInstance.fire("ready");
            this._slowStartupReporter.stop();

            this._enableInputEvents();
            ckeInstance.editing.view.enableObservers();

            const latestDataToLoad = dataHandler.getData();
            if (!hasUserUpdatedContent && latestDataToLoad !== initialDataToLoad) {
                content = latestDataToLoad;
                ckeInstance.setData(content);
            }

            if (document.activeElement === this._sourceElement) {
                this._handleEditorFocus(this._sourceElement, ckeInstance);
            }

            if (userSelectionMarker !== null) {
                this._restoreSelection(ckeInstance, userSelectionMarker);
            }

            return { ckeInstance: ckeInstance, hasUserUpdatedContent: hasUserUpdatedContent };
        });
    }

    private _restoreSelection(ckeInstance: DecoupledEditor, userSelectionMarker: PpMarkerRange): void {
        ckeInstance.model.enqueueChange({ isUndoable: false }, (writer) => {
            const startMarker = ckeInstance.model.markers.get(userSelectionMarker.start.id);
            if (startMarker === null) {
                return;
            }

            const endMarker = userSelectionMarker.end !== null ? ckeInstance.model.markers.get(userSelectionMarker.end.id) : null;
            const startPosition = startMarker.getEnd();
            const endPosition = endMarker !== null ? endMarker.getStart() : undefined;
            const range = ckeInstance.editing.model.createRange(startPosition, endPosition);
            writer.setSelection(range);
            writer.removeMarker(startMarker.name);

            if (endMarker !== null) {
                writer.removeMarker(endMarker.name);
            }
        });
    }

    private _handleEditorFocus(editorContainer: HTMLElement, editor: DecoupledEditor): void {
        editorContainer.dispatchEvent(new FocusEvent("focus"));
        editor.editing.view.focus();
    }

    private _disableInputEvents(): void {
        this._sourceElement.addEventListener("beforeinput", preventDefault);
        this._sourceElement.addEventListener("mousedown", preventDefault);
        this._sourceElement.addEventListener("touchstart", preventDefault);
        this._sourceElement.addEventListener("keydown", preventDefault);
    }

    private _enableInputEvents(): void {
        this._sourceElement.removeEventListener("beforeinput", preventDefault);
        this._sourceElement.removeEventListener("mousedown", preventDefault);
        this._sourceElement.removeEventListener("touchstart", preventDefault);
        this._sourceElement.removeEventListener("keydown", preventDefault);
    }

    private _logSlowStartup(elapsedTime: number): void {
        tracker.trackEvent("CKE5 Input", "Startup", generateElapsedTimeEventName(elapsedTime), this._sourceElement.querySelectorAll("*").length);
    }
}
