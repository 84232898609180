import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../../constants/asset.constants";
import "../../../pui/src/accordion/accordion.component";
import "../../../pui/src/accordion/accordion-content.component";
import "../../../pui/src/accordion/accordion-header.component";
import "../../../pui/src/accordion/accordion-panel.component";
import "../../../builder/dataManager/helperService";
import "../../../multiLanguageService/multiLanguageService";
import "../StandardsSelectionCheckboxGroup/standardsSelectionCheckboxGroup.component";
import template from "./templates/standards-selection-sidebar-accordion.component.html";

var standardsSelectionSidebarAccordionDefinition = {
    bindings: {
        standardsData: "<",
        level: "<",
        headerLevel: "<",
        updateStandard: "&",
        pageId: "<",
        assetRefId: "<",
        pageFormId: "<"
    },
    template: template,
    controller: StandardsSelectionSidebarAccordion
};

StandardsSelectionSidebarAccordion.$inject = ["helperService", "multiLanguageService", "User"];

function StandardsSelectionSidebarAccordion(helperService, multiLanguageService, userService) {
    this.helperService = helperService;
    this.multiLanguageService = multiLanguageService;
    this.mmlEnabled = userService.getDto().EnableMapMyLearning;
    this.ASSET_CONSTANTS = ASSET_CONSTANTS;
    // Very sorry old sports, turns out it's hard to find the PageId when super deep in recursion
    // Easy to find it when returning actions
    // This just helps us uniquely key them so that we don't end up with duplicate ids across branches
    // due to ElementId not being totally unique
}

StandardsSelectionSidebarAccordion.prototype.$onInit = function () {
    this.standardsData.forEach((data) => {
        // Only workbook pages and MML forms contain standards at this level. Nested workbooks are dealt with in child accordion components.
        const workingData = data.MainType === this.ASSET_CONSTANTS.TYPES.FORM && data.Groups !== undefined && data.Groups.length === 1 ? data.Groups[0] : data;

        if (workingData.Standards !== undefined) {
            workingData.Standards.forEach((standard) => {
                standard.uniqueId = this.helperService.guid();
            });
        }
    });
};

/**
 * Using a variety of nested documents rather than key relationships makes it hard to work with this recursively
 * This method deals with that by collecting all of the required standards data as the callbacks trigger up the accordion tree
 *
 *
 * @param pageFormId These are the actual values that are passed up the accordion tree
 * @param pageId
 * @param formElementId
 * @param value
 * @param assetRefId
 */
StandardsSelectionSidebarAccordion.prototype.updateChildStandard = function (pageFormId, pageId, formElementId, value, assetRefId) {
    this.updateStandard({
        value: value,
        pageFormId: pageFormId,
        pageId: pageId,
        formElementId: formElementId,
        assetRefId: assetRefId
    });
};
/**
 * ng-click handler for when the user changes the checkbox value
 * @param event
 * @param pageFormId
 * @param pageId
 * @param formElementId
 * @param assetRefId
 */
StandardsSelectionSidebarAccordion.prototype.setChecked = function (event, pageFormId, pageId, formElementId, assetRefId) {
    this.updateStandard({
        value: event.target.checked,
        pageFormId: pageFormId,
        pageId: pageId,
        formElementId: formElementId,
        assetRefId: assetRefId
    });
};

angularAMD.component("standardsSelectionSidebarAccordionComponent", standardsSelectionSidebarAccordionDefinition);

export { standardsSelectionSidebarAccordionDefinition as standardsSelectionSidebarAccordionComponent };
