import { dialogDirector, DialogState } from "@pjs/core-ui";
import { angularAMD } from "@pebblepad/amd";

export class DialogAccessKeyService {
    constructor(accessKeyService) {
        this._accessKeyService = accessKeyService;
        this.init();
    }

    init() {
        this._directorSubscription = dialogDirector.applicationState.subscribe((state) => {
            switch (state) {
                case DialogState.OPEN:
                    this._accessKeyService.pauseHandlers();
                    break;

                case DialogState.CLOSED:
                    this._accessKeyService.resumeHandlers();
                    break;
            }
        });
    }
}

DialogAccessKeyService.$inject = ["accessKeyService"];
angularAMD.service("dialogAccessKeyService", DialogAccessKeyService);
