import { SandboxedTagMutation } from "@pjs/security";
import { moveElementChildren } from "@pjs/utilities";
import { doNodesDirectlyContainText } from "../../../../utils/do-nodes-directly-contain-text/DoNodesDirectlyContainText.function";
import { doesNodeDirectlyContainText } from "../../../../utils/do-nodes-directly-contain-text/DoesNodeDirectlyContainText.function";

export class ParagraphCleanupMutation extends SandboxedTagMutation<HTMLParagraphElement> {
    private readonly _attributeProcessors: ReadonlyArray<(sandboxedElement: HTMLElement, sandboxedDocument: Document) => void>;

    constructor(attributeProcessors: ReadonlyArray<(sandboxedElement: HTMLElement, sandboxedDocument: Document) => void>) {
        super();
        this._attributeProcessors = attributeProcessors;
    }

    protected _mutate(sandboxedP: HTMLParagraphElement, sandboxedDocument: Document): boolean {
        if (this._isEmptyStarter(sandboxedP)) {
            return false;
        }

        if (this._requiresFiller(sandboxedP)) {
            sandboxedP.innerHTML = "&nbsp;";
        }

        if (this._handleCke5LiConflict(sandboxedP, sandboxedDocument)) {
            return false;
        }

        for (const attributeProcessor of this._attributeProcessors) {
            attributeProcessor(sandboxedP, sandboxedDocument);
        }

        return true;
    }

    private _requiresFiller(sandboxedP: HTMLParagraphElement): boolean {
        return sandboxedP.childElementCount === 0 && !doNodesDirectlyContainText(sandboxedP.childNodes);
    }

    private _isEmptyStarter(sandboxedP: HTMLParagraphElement): boolean {
        const parent = sandboxedP.parentElement;
        if (parent === null || parent.tagName !== "BODY" || parent.childNodes.length > 1) {
            return false;
        }

        const childNodesCount = sandboxedP.childNodes.length;
        if (childNodesCount > 1) {
            return false;
        }

        if (childNodesCount === 0) {
            return true;
        }

        const elementCount = sandboxedP.childElementCount;
        const firstChild = sandboxedP.firstChild as Node;
        if (elementCount > 1 || !(elementCount === 1 && firstChild.nodeName === "BR")) {
            return false;
        }

        return !doesNodeDirectlyContainText(firstChild);
    }

    private _handleCke5LiConflict(sandboxedP: HTMLParagraphElement, sandboxedDocument: Document): boolean {
        const parent = sandboxedP.parentElement;
        const isCke5ParagraphInvalidWithinLi = parent !== null && parent.tagName === "LI" && parent.childElementCount === 1 && sandboxedP.style.length === 0 && sandboxedP.className === "";

        if (!isCke5ParagraphInvalidWithinLi) {
            return false;
        }

        const span = sandboxedDocument.createElement("span");
        moveElementChildren(sandboxedP, span);
        sandboxedP.replaceWith(span);
        return true;
    }
}
