import { autoUpdate, computePosition, Placement } from "@floating-ui/dom";
import { defer, finalise, Observable, share, Subject } from "@pjs/observables";
import { PositioningFactory } from "./factories/types/PositioningFactory";

export function createFloatingPositioner(
    referenceElement: HTMLElement,
    floatingElement: HTMLElement,
    boundaryElement: HTMLElement,
    middlewareFactory: PositioningFactory,
    placement: Placement
): Observable<void> {
    const createObservable = (): Observable<void> => {
        const sub = new Subject<void>();

        const middlewareConfig = middlewareFactory(boundaryElement, placement);

        const cleanup = autoUpdate(
            referenceElement,
            floatingElement,
            () => {
                computePosition(referenceElement, floatingElement, middlewareConfig.positionConfig)
                    .then((data) => {
                        middlewareConfig.styler(floatingElement, referenceElement, data);
                        sub.next();
                    })
                    .catch((e) => {
                        sub.error(e);
                    });
            },
            { layoutShift: false }
        );

        return sub.pipe(
            finalise(() => {
                cleanup();
                sub.complete();
            })
        );
    };

    return defer(createObservable).pipe(share());
}
