import { angularAMD } from "@pebblepad/amd";
import { RouteRejectionReason } from "./routeRejectionReason";
import "../user/user.service";
import "../utilities/assetBuilderHelper.service";
import "../versioning/services/versionHelper.service";
import "../assetStore/services/assetCreateService";

var plusRoutingModule = angular.module("pebbleRouting", ["ng"]);

//Constants
//=============================================================================================================
var ROUTE_REJECTION_REASONS = {
    AUTHENTICATION_FAILED: "authenticationFailed",
    INTERNAL_USER_REQUIRED: "internalUserRequired",
    EXTERNAL_USER_REQUIRED: "externalUserRequired",
    INVALID_TIMESLICE: "invalidTimeslice"
};
plusRoutingModule.constant("ROUTE_REJECTION_REASONS", ROUTE_REJECTION_REASONS);

//Provider
//=============================================================================================================
plusRoutingModule.provider("PebbleRoutingValidation", PebbleRoutingValidation);
function PebbleRoutingValidation() {
    this.validator = new RoutingValidator();

    var validator = this.validator;
    //Factories for Angular routing resolves. Format: ['Dependency', 'Dependency', 'Dependency', Function]
    //Dependencies can be injected from any module the main App module contains (Plus, Components, Flow, etc.)
    this.whenAuthenticated = ["$q", "User", validator.whenAuthenticated.bind(validator)];
    this.whenInternalUser = ["$q", "User", validator.whenInternalUser.bind(validator)];
    this.whenExternalUser = ["$q", "User", validator.whenExternalUser.bind(validator)];
    this.whenAssetBuilder = ["$q", "$route", "$location", "$rootScope", "User", "AssetBuilderHelper", "assetCreateService", validator.whenAssetBuilder.bind(validator)];
    this.whenAssetVersioned = ["$q", "$route", "$location", "User", "VersionHelper", validator.whenAssetVersioned.bind(validator)];

    this.$get = function () {
        return {
            createRoutingValidator: function () {
                return new RoutingValidator();
            },
            createRejectionReason: function (reason, user, assetId) {
                return new RouteRejectionReason(reason, user, assetId);
            }
        };
    };
}

//RoutingValidator Class
//=============================================================================================================
// tslint:disable-next-line:no-empty
function RoutingValidator() {}

RoutingValidator.prototype.whenAuthenticated = function ($q, User) {
    return User.isLoggedIn()
        .then(function (loggedIn) {
            return loggedIn ? true : $q.reject();
        })
        .catch(function () {
            return $q.reject(new RouteRejectionReason(ROUTE_REJECTION_REASONS.AUTHENTICATION_FAILED));
        });
};

RoutingValidator.prototype.whenInternalUser = function ($q, User) {
    return this.whenAuthenticated($q, User).then(function () {
        return !User.isExternal() ? true : $q.reject(new RouteRejectionReason(ROUTE_REJECTION_REASONS.INTERNAL_USER_REQUIRED, User.getDto()));
    });
};

RoutingValidator.prototype.whenExternalUser = function ($q, User) {
    return this.whenAuthenticated($q, User).then(function () {
        return User.isExternal() ? true : $q.reject(new RouteRejectionReason(ROUTE_REJECTION_REASONS.EXTERNAL_USER_REQUIRED, User.getDto()));
    });
};

RoutingValidator.prototype.whenAssetBuilder = function ($q, $route, $location, $rootScope, User, AssetBuilderHelper, assetCreateService) {
    $rootScope.appIsLoaded = false;
    //Run Authentication, then checking Collaboration and Locking
    return this.whenAuthenticated($q, User).then(function () {
        //Update title as url, title and view could potentially get out of sync on rejection.
        $rootScope.title = $route.current.title;
        if ($route.current.params.assetId) {
            return AssetBuilderHelper.beforeLoad($route.current.params.assetId, $route.current.mainType, $location.url());
        } else {
            return assetCreateService.createAssetFromUrl($route.current.mainType).then(function (url) {
                $location.path(url).replace();
                return $q.reject();
            });
        }
    });
};

RoutingValidator.prototype.whenAssetVersioned = function ($q, $route, $location, User, VersionHelper) {
    var params = $route.current.params;
    if (!params.timeslice || !VersionHelper.validateTimeslice(params.timeslice)) {
        return $q.reject(new RouteRejectionReason(ROUTE_REJECTION_REASONS.INVALID_TIMESLICE, User.getDto(), params.assetId));
    }

    return this.whenAuthenticated($q, User);
};
