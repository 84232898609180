import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import "../../utilities/characterLimit.directive";
import "../../utilities/text-editable/contenteditable";
import "../../utilities/helpers";
import "../../autoFocus/autoFocus.directive";
import "../../multiLanguageService/multiLanguageService";
import template from "./text-section-input.html";

class TextSectionInput extends NgComponent {
    constructor(helpers, multiLanguageService) {
        super();

        this.helpers = helpers;
        this.multiLanguageService = multiLanguageService;
        this.value = this.initialValue;
        this.checkInputLimit(this.value);
    }

    updateModel() {
        const newValue = this.helpers.htmlToPlain(this.value);
        this.checkInputLimit(newValue);
        this.onValueChange({ value: newValue });
    }

    checkInputLimit(value) {
        if (this.inputLimit === undefined || this.inputLimit === "") {
            this.info = "";
        } else if (value !== null && value.length >= this.inputLimit) {
            this.info = this.multiLanguageService.getString("accessibility.text_section_input.reached_limit", { value: this.inputLimit });
        } else {
            this.info = this.multiLanguageService.getString("accessibility.text_section_input.character_limit", { value: this.inputLimit });
        }
    }
}

export const textSectionInputDefinition = {
    bindings: {
        header: "@",
        hint: "@",
        placeholder: "@?",
        inputLimit: "@?",
        initialValue: "<",
        focus: "<",
        onValueChange: "&"
    },
    template: template,
    controller: TextSectionInput
};

textSectionInputDefinition.$inject = ["helpers", "multiLanguageService"];
angularAMD.component("textSectionInput", textSectionInputDefinition);
