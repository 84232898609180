import { Fragment, FunctionComponent } from "react";
import { historyIcon, Icon, StaticLabel } from "@pjs/core-ui";
import { feedbackI18n } from "../../i18n/FeedbackI18n.const";
import { ApprovalViewBase } from "../approval-view-base/ApprovalViewBase.component";
import { ICapabilityApprovalViewProps } from "./interfaces/ICapabilityApprovalViewProps";
import "./styles/capability-approval-view.css";

export const CapabilityApprovalView: FunctionComponent<ICapabilityApprovalViewProps> = (props) => {
    const tooltipText = feedbackI18n.getString("accessibility.capability_approval_view.icon_title");
    return (
        <ApprovalViewBase
            feedback={props.feedback}
            statusNotice={props.statusNotice}
            header={
                <Fragment>
                    <StaticLabel text={props.labelText} />

                    {props.isHistorical && (
                        <Fragment>
                            <span title={tooltipText} aria-hidden={true}>
                                <Icon className="fbk-capability-approval-view__history-icon" source={historyIcon} />
                            </span>
                            <span className="fbk-capability-approval-view__screen-reader-only" data-hook="capability-approval-screen-reader-only">
                                {tooltipText}
                            </span>
                        </Fragment>
                    )}
                </Fragment>
            }
        />
    );
};
