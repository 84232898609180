import { ICookieOptions } from "./interfaces/ICookieOptions";
import { cookieOptionsToString } from "./functions/CookieOptionsToString.function";
import { ICookieResult } from "./interfaces/ICookieResult";
import { ICookieStore } from "./interfaces/ICookieStore";

const cookieRegex = /[^\s,;]+=[^\s,;]+/g;

export const cookieStore: ICookieStore = {
    delete: (name: string): void => {
        document.cookie = `${name}=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    },
    get: (name: string): ICookieResult | null => {
        const matchRegex = new RegExp(`${name}=[^\\s,;]+`);
        const matchingCookie = document.cookie.match(matchRegex);

        if (matchingCookie === null) {
            return null;
        }

        const splitCookie = matchingCookie[0].split("=");
        const key = splitCookie[0];
        const value = splitCookie[1];

        if (value.length === 0) {
            return null;
        }

        return {
            name: key,
            value: value
        };
    },
    getAll: (): Array<ICookieResult> => {
        const cookieMatches = document.cookie.match(cookieRegex);

        if (cookieMatches === null) {
            return [];
        }

        return cookieMatches.map((s) => {
            const arr = s.split("=");
            return {
                name: arr[0],
                value: arr[1]
            };
        });
    },
    set: (name: string, value: string, options?: ICookieOptions): void => {
        const cookieOptions = options === undefined ? "" : cookieOptionsToString(options);
        document.cookie = `${name}=${value}; ${cookieOptions}`;
    }
};
