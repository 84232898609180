export function generateCsrfToken(): string {
    const tokenLength = 64;
    const crypto = window.crypto;
    const bigIndexes = crypto.getRandomValues(new Uint32Array(tokenLength));
    const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789[]";

    let token = "";

    for (let i = 0; i < tokenLength; i++) {
        token += charSet[bigIndexes[i] % charSet.length];
    }

    return token;
}
