import { i18nManager } from "@pjs/i18n";

const i18n = angular.module("i18n", ["ng"]);
i18n.config([
    "$sceDelegateProvider",
    function ($sceDelegateProvider) {
        var whiteListUrlShared = window.config.getSharedComponentBaseUrl() + "**";

        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            "self",
            // Allow loading from our assets domain.  Notice the difference between * and **.
            whiteListUrlShared
        ]);
    }
]);

i18n.service("i18nHelper", I18nHelper);
I18nHelper.$inject = ["$http"];

function I18nHelper($http) {
    // Public API
    // =============================================================================================================
    this.getLanguageData = getLanguageData;
    this.getLanguageContent = getLanguageContent;
    this.setCacheBuster = setCacheBuster;
    this.fetchDictionary = fetchDictionary;

    // Private variables
    // =============================================================================================================
    let cacheBuster = "";

    // Private methods

    // =============================================================================================================
    function getLanguageForUser() {
        $http.defaults.withCredentials = true;
        return $http.get(window.config.getApiBaseUrl() + "SharedMenu/GetLoggedInUserData").then(function (userData) {
            return userData.data.Language || "en-GB";
        });
    }

    function fetchLanguageFile(path, lang, bail) {
        const url = window.config.getSharedComponentBaseUrl() + path + lang + ".json?" + cacheBuster;

        $http.defaults.withCredentials = false;
        return $http
            .get(url)
            .then(function (file) {
                return file.data;
            })
            .catch(function () {
                if (bail) {
                    throw new Error("Failed to load file located at " + path + ", for " + lang + " language");
                }

                return fetchLanguageFile(path, "en-GB", true);
            });
    }

    function getLanguageData() {
        let dictionaries = {
            main: null,
            fallback: null
        };

        let mainLang = "en-GB";

        return getLanguageForUser()
            .then((lang) => {
                mainLang = lang;
                i18nManager.setDefaultLanguage("en-GB").setLanguage(lang);

                return i18nManager.load();
            })
            .then(() => {
                return fetchDictionary(mainLang).then(function (fetchedLang) {
                    dictionaries.main = fetchedLang;

                    if (fetchedLang.lang_attr.toLowerCase() !== "en-gb") {
                        return fetchDictionary("en-GB").then(function (langData) {
                            dictionaries.fallback = langData;

                            return dictionaries;
                        });
                    } else {
                        return dictionaries;
                    }
                });
            });
    }

    function fetchDictionary(lang) {
        return fetchLanguageFile("multiLanguageService/languages/", lang);
    }

    function getLanguageContent(filePath) {
        return getLanguageForUser().then(function (lang) {
            return fetchLanguageFile(filePath, lang);
        });
    }

    function setCacheBuster(cacheBusterValue) {
        cacheBuster = cacheBusterValue;
    }
}
