import { angularAMD } from "@pebblepad/amd";
import "./assetLockedWarning.controller";
import "../../../profileImage/profileImg";
import onAddWarningTemplate from "./templates/asset-locked-on-add-warning.html";
import warningTemplate from "./templates/asset-locked-warning.html";

angularAMD.directive("assetLockedWarning", AssetLockedWarning);
AssetLockedWarning.$inject = ["$sce", "baseUrlsFactory"];

function AssetLockedWarning() {
    return {
        template: function ($scope, $attrs) {
            return $attrs.addingAsset === "true" ? onAddWarningTemplate : warningTemplate;
        },
        restrict: "E",
        scope: true,
        controller: "AssetLockedWarningController",
        controllerAs: "vm"
    };
}
