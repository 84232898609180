import { angularAMD } from "@pebblepad/amd";
import { crossIcon } from "@pjs/core-ui";
import enquire from "enquire.js/dist/enquire";
import "../utilities/baseUrlsFactory";
import "./datePickerHelper";
import "../utilities/customEvent.service";
import "../multiLanguageService/multiLanguageService";
import "../userAgent/userAgent.service";
import "../utilities/errorHighlighter.service";
import "../utilities/helpers";
import "../utilities/typeCheck.service";
import "../utilities/pebbleDate";
import "../react2angular/icon";
import datePickerTemplate from "./templates/date-picker.html";

angularAMD.directive("datePicker", [
    "$sce",
    "$rootScope",
    "$timeout",
    "baseUrlsFactory",
    "datePickerHelper",
    "customEvent",
    "multiLanguageService",
    "typeCheck",
    "UserAgentService",
    "errorHighlighter",
    function ($sce, $rootScope, $timeout, baseUrlsFactory, datePickerHelper, customEvent, multiLanguageService, typeCheck, UserAgentService, errorHighlighter) {
        // Get spa menu height
        // get it done before outside return
        // because in this case it will be triggered only once. (performance reason)
        var spa_menu_el = document.getElementById("spa-menu-wrapper");
        var spa_menu_height = spa_menu_el ? spa_menu_el.offsetHeight : 0;
        enquire.register("screen and (max-width: 460px)", {
            match: function () {
                spa_menu_height = spa_menu_el ? spa_menu_el.offsetHeight : 0;
            },
            unmatch: function () {
                spa_menu_height = spa_menu_el ? spa_menu_el.offsetHeight : 0;
            }
        });

        var current_selected_calendar, last_selected_calendar;

        return {
            scope: {
                bindTo: "<",
                presetDate: "=presetDate",
                minDate: "=minDate",
                maxDate: "=maxDate",
                label: "@label",
                alternativeAriaLabel: "@alternativeAriaLabel",
                clearDateAriaLabel: "@",
                onUpdate: "&",
                endOfDay: "=endOfDay",
                compact: "<?"
            },
            restrict: "E",
            template: datePickerTemplate,
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.crossIcon = crossIcon;

                    /**
                     * Get options for time select
                     */
                    var getOptions = function (time, max_time, time_increment) {
                        // TODO: MOVE TO: datePickerHelper
                        var options = [];

                        while (time < max_time) {
                            var temp_date = new Date(time);
                            var opt_title =
                                (temp_date.getHours() < 10 ? "0" : "") + temp_date.getHours().toString() + ":" + (temp_date.getMinutes() < 10 ? "0" : "") + temp_date.getMinutes().toString();

                            // add new value into the list
                            options.push({
                                title: opt_title === "12:00" ? multiLanguageService.getString("date_picker.midday") : opt_title,
                                value: temp_date.getTime()
                            });

                            // increment time
                            time.setMinutes(time.getMinutes() + time_increment);
                        }

                        // add last item into options for "Midnight"
                        // this is to clarify which "Midnight" is
                        options.push({
                            title: multiLanguageService.getString("date_picker.midnight"),
                            value: max_time.getTime()
                        });

                        return options;
                    };

                    var buildTimeDropdownOptions = function () {
                        // MOVE TO: datePickerHelper
                        var time_increment = 30, // default time increment is 30 minutes
                            min_time = new Date(),
                            max_time = new Date();

                        if ($attrs.timeIncrement) {
                            var parsed_increment = parseInt($attrs.timeIncrement, 10); // parse string into int
                            // if time increment is not bigger that 60 minutes or negative then set it to provided value
                            if (parsed_increment >= 0) {
                                time_increment = parsed_increment;
                            }
                        }

                        $scope.time_increment = time_increment;

                        // minimum time for dropdown menu
                        min_time.setHours(0, time_increment, 0, 0);

                        // maximum time for dropdown menu
                        max_time.setHours(23, 59, 0, 0);

                        return getOptions(min_time, max_time, time_increment);
                    };

                    var options = {
                        year: null,
                        month: null,
                        date: null,

                        default_date: null,
                        isRTL: false,

                        // how many months are visible
                        number_of_months: 1,

                        first_day: 1,
                        min_date: null,
                        max_date: null,
                        year_range: 100,
                        show_week_number: false,
                        timeOptions: false
                    };

                    $scope.equalizeDateWithPicker = function (new_date) {
                        if ($scope.time_dropdown_options) {
                            var closest_minute = datePickerHelper.findClosestMinute($scope.time_dropdown_options, new_date.getMinutes(), new_date.getHours());
                            new_date.setMinutes(closest_minute);
                            new_date.setSeconds(0);
                        }

                        return new_date;
                    };

                    $scope.config = function (opts) {
                        var new_date = new Date();

                        // if onlyFuture flag is set then make min date equals to today
                        if ($scope.onlyFuture) {
                            opts.min_date = datePickerHelper.setToStartOfDay(new_date);
                        }

                        $timeout(function () {
                            opts.date = $scope.equalizeDateWithPicker(new_date);
                        }, 0);

                        return opts;
                    };

                    $scope.checkForCalendarOffset = function () {
                        var cur_elem = $element[0];

                        do {
                            cur_elem = cur_elem.parentNode;
                            if (!cur_elem) {
                                break;
                            }
                        } while (cur_elem.nodeName !== "FORM-CANVAS");

                        return cur_elem !== null;
                    };

                    $scope.init = function () {
                        /**
                         * date format guidelines: https://en.wikipedia.org/wiki/Date_format_by_country
                         * Order of the basic components:
                         *      B - Big-endian (year, month, day), e.g. 1996-04-22
                         *      L - Little-endian (day, month, year), e.g. 22.04.96 or 22/04/96 or 22 April 1996
                         *      M - Middle-endian (month, day, year), e.g. 04/22/96 or April 22, 1996
                         */
                        $scope.date_format = "L";
                        $scope.multiLanguageService = multiLanguageService;
                        $scope.onlyFuture = $attrs.onlyFuture !== undefined;
                        $scope.watch_bind_to = $attrs.watchBindTo !== undefined;
                        $scope.time_dropdown_options = $attrs.time === "" || $attrs.time === "true" ? buildTimeDropdownOptions() : false;
                        $scope.icon = $attrs.icon === "";
                        $scope.time_position = $attrs.timePosition === "" || $attrs.timePosition === undefined ? "" : $attrs.timePosition;
                        $scope.blank_date = $attrs.blankDate !== undefined;
                        $scope.ignore_spa_menu = $attrs.ignoreSpaMenu !== undefined;

                        options.timeOptions = !!$scope.time_dropdown_options;
                        $scope.opts = $scope.config(options);
                        $scope.calendar_has_offset = $scope.checkForCalendarOffset();
                    };
                }
            ],

            link: function (scope, element, attrs) {
                var enter_event = "click",
                    start_enter_event = "mousedown";

                scope.toggleCalendar = function () {
                    if (scope.is_open) {
                        scope.hideCalendar();
                    } else {
                        scope.showCalendar();
                    }
                };

                scope.setDate = function (date) {
                    var originally_passed_date = date;

                    if (!date && !scope.blank_date) {
                        return;
                    }
                    if (typeof date === "string") {
                        date = new Date(Date.parseDate(date, scope.date_format));
                    }

                    if (!datePickerHelper.isDate(date) && !scope.blank_date) {
                        return;
                    } else if (!datePickerHelper.isDate(date) && scope.blank_date) {
                        date = null;
                    }

                    if (!date) {
                        date = new Date();
                    }

                    var min = scope.opts.min_date,
                        max = scope.opts.max_date;

                    if (datePickerHelper.isDate(min) && date <= min) {
                        date = min;
                        if (scope.time_dropdown_options) {
                            date = scope.getNearestTime(date, 1);
                        }
                    } else if (datePickerHelper.isDate(max) && date >= max) {
                        date = max;
                        if (scope.time_dropdown_options) {
                            date = scope.getNearestTime(date, -1);
                        }
                    }

                    date.setMilliseconds(0); // do this for data that came from watcher
                    scope.opts.date = new Date(date.getTime());
                    scope.opts.year = scope.opts.date.getFullYear();
                    scope.opts.month = scope.opts.date.getMonth();

                    var modifiedDate = scope.blank_date && !originally_passed_date ? originally_passed_date : date;
                    if (datePickerHelper.isDate(originally_passed_date)) {
                        if (scope.bindTo || scope.bindTo === null) {
                            if (scope.time_dropdown_options !== false) {
                                if (scope.opts.date) {
                                    var closestMinute = datePickerHelper.findClosestMinute(scope.time_dropdown_options, scope.opts.date.getMinutes(), scope.opts.date.getHours());
                                    scope.opts.date.setMinutes(closestMinute);
                                }

                                modifiedDate = scope.opts.date;
                            } else {
                                modifiedDate = scope.endOfDay ? datePickerHelper.setToEndOfDay(scope.opts.date) : datePickerHelper.setToStartOfDay(scope.opts.date);
                            }

                            /**
                             * Normalise the date to the nearest 30mins (only options available for time)
                             * Check if normalised date is the same as the bindTo date.
                             * If not, normalised date takes priority to make sure correct time is stored in data.
                             */
                            var new_date = new Date(scope.equalizeDateWithPicker(modifiedDate));
                            if (String(new_date) !== String(scope.bindTo)) {
                                modifiedDate = new_date;
                            }

                            if (!scope.$$phase && !scope.$root.$$phase) {
                                scope.$apply();
                            }
                        }
                    }

                    // update dropdown with time
                    if (scope.time_dropdown_options !== false) {
                        scope.unbindTimeDropdownEvents();
                        scope.updateTimeDropdown(modifiedDate);
                    }

                    element[0].classList.remove("date-picker--invalid");
                    errorHighlighter.removeInvalidStyling(scope.input_el, "decline-shake", 0);
                    scope.updateInputField(modifiedDate);
                    scope.goToDate();

                    return modifiedDate;
                };

                scope.clearDatePressed = (event) => {
                    scope.clearDate();
                    event.stopPropagation();
                };

                scope.clearDate = function () {
                    scope.onUpdate({ date: null });
                    var id = scope.label || "";
                    scope.$emit("calendarCleared" + id, null);
                    triggerDatePickerUpdateEvent();
                };

                function triggerDatePickerUpdateEvent() {
                    $timeout(function () {
                        customEvent.emit(scope.input_el[0], "date-picker-update");
                    });
                }

                scope.goToDate = function () {
                    if (!datePickerHelper.isDate(scope.opts.date) && !scope.blank_date) {
                        return;
                    }

                    scope.adjustCalendars();
                };

                scope.goToToday = function () {
                    var today = new Date();
                    scope.opts.month = today.getMonth();
                    scope.opts.year = today.getFullYear();
                    scope.adjustCalendars();
                };

                scope.goToMonth = function (month) {
                    if (!isNaN(month)) {
                        scope.opts.month = parseInt(month, 10);
                        scope.adjustCalendars();
                    }
                };

                scope.goToYear = function (year) {
                    if (year && typeCheck.isString(year)) {
                        scope.opts.year = parseInt(year, 10);
                        scope.adjustCalendars();
                    }
                };

                scope.prevMonth = function () {
                    scope.opts.month--;
                    scope.adjustCalendars();
                };
                scope.nextMonth = function () {
                    scope.opts.month++;
                    scope.adjustCalendars();
                };

                scope.adjustCalendars = function () {
                    scope.adjustCalendar();
                    var html = datePickerHelper.renderCalendar(scope.opts);
                    // tslint:disable-next-line:no-unsafe-jq-lite
                    scope.calendar_el.html(html);
                    bindTableEvents();
                };

                scope.adjustTimeDropdown = function (originally_passed_date) {
                    var html = datePickerHelper.renderTimeDropdown(originally_passed_date, scope.time_dropdown_options, scope.opts.min_date);
                    // tslint:disable-next-line:no-unsafe-jq-lite
                    scope.time_select_el.html(html);

                    if (originally_passed_date) {
                        scope.time_select_el[0].removeAttribute("disabled");
                    } else {
                        scope.time_select_el[0].setAttribute("disabled", "");
                    }

                    scope.bindTimeDropdownEvents();
                };

                scope.adjustCalendar = function () {
                    if (scope.opts.month < 0) {
                        scope.opts.year -= Math.ceil(Math.abs(scope.opts.month) / 12);
                        scope.opts.month += 12;
                    }
                    if (scope.opts.month > 11) {
                        scope.opts.year += Math.floor(Math.abs(scope.opts.month) / 12);
                        scope.opts.month -= 12;
                    }
                };

                scope.showCalendar = function () {
                    if (!scope.is_open) {
                        // core
                        current_selected_calendar = scope.calendar_el;
                        $rootScope.$broadcast("triggerDatePicker");
                        scope.is_open = true;

                        scope.calendar_el.addClass("show");

                        // events
                        bindCalendarEvents();

                        // other
                        scope.locateCalendar();
                        last_selected_calendar = scope.calendar_el;
                    }
                };

                scope.hideCalendar = function () {
                    if (scope.is_open) {
                        element[0].classList.remove("date-picker--input-focused");
                        // core
                        scope.is_open = false;
                        scope.keep_open = false;
                        scope.calendar_el.removeClass("show");

                        // events
                        unbindCalendarEvents();

                        // other
                        last_selected_calendar = scope.calendar_el; // save current calendar as last selected
                        var id = scope.label || "";
                        scope.$emit("calendarClosed" + id);
                    }
                };

                /**
                 * @param {Date} date
                 * @param {Number} sign (1 or -1)
                 * @returns {Date}
                 */
                scope.getNearestTime = function (date, sign) {
                    return new Date(date.getTime() + scope.time_increment * sign * 60000);
                };

                scope.onChange = function (e) {
                    element[0].classList.remove("date-picker--invalid");
                    var date;
                    //Validate that the target value is not an empty value or a string containing only whitespace chars
                    if (e.target.value && /\S+/.test(e.target.value)) {
                        if (datePickerHelper.toString(scope.bindTo) === e.target.value) {
                            return;
                        }
                        try {
                            date = new Date(Date.parseDate(e.target.value, scope.date_format));
                            if (isNaN(date)) {
                                throw new Error();
                            }
                        } catch (err) {
                            errorHighlighter.highlightInvalidInput(e.target, "decline-shake", true);
                            element[0].classList.add("date-picker--invalid");
                            return;
                        }
                    } else {
                        scope.clearDate();
                        scope.$apply();
                    }

                    const modifiedDate = scope.setDate(date);
                    scope.onUpdateDate(modifiedDate);
                };

                var onEnterEvent = function (e) {
                    var target = angular.element(e.target || e.srcElement),
                        pEl = target[0];

                    if (!target[0]) {
                        return;
                    }

                    do {
                        if (angular.element(pEl).hasClass("calendar")) {
                            return;
                        }
                        // tslint:disable-next-line:no-conditional-assignment
                    } while ((pEl = pEl.parentNode));

                    if (target[0] !== scope.input_el[0]) {
                        scope.hideCalendar();
                    }
                };

                var onStartEnterEvent = function (e) {
                    var target = angular.element(e.target || e.srcElement);

                    if (!target[0]) {
                        return;
                    }

                    if (!target.hasClass("is-disabled")) {
                        if (target.hasClass("picker-btn") && !target.hasClass("is-empty")) {
                            if (scope.time_dropdown_options) {
                                var temp_date = new Date(parseInt(scope.time_select_el[0].value, 10));
                                if (!datePickerHelper.isDate(temp_date)) {
                                    temp_date = new Date();
                                }

                                const modifiedDate = scope.setDate(
                                    new Date(
                                        target[0].getAttribute("data-picker-year"),
                                        target[0].getAttribute("data-picker-month"),
                                        target[0].getAttribute("data-picker-day"),
                                        temp_date.getHours(),
                                        temp_date.getMinutes()
                                    )
                                );

                                scope.onUpdateDate(modifiedDate);
                                triggerDatePickerUpdateEvent();
                            } else {
                                const modifiedDate = scope.setDate(
                                    new Date(target[0].getAttribute("data-picker-year"), target[0].getAttribute("data-picker-month"), target[0].getAttribute("data-picker-day"))
                                );
                                scope.onUpdateDate(modifiedDate);
                                triggerDatePickerUpdateEvent();
                            }
                            scope.hideCalendar();
                            return;
                        } else if (target.hasClass("picker-prev")) {
                            scope.prevMonth();
                        } else if (target.hasClass("picker-next")) {
                            scope.nextMonth();
                        } else if (target.hasClass("picker-today")) {
                            scope.goToToday();
                        }
                    }

                    if (!target.hasClass("picker-select")) {
                        if (e.preventDefault) {
                            e.preventDefault();
                        } else {
                            e.returnValue = false;
                            return false;
                        }
                    } else {
                        scope.keep_open = true;
                    }
                };

                var bindCalendarEvents = function () {
                    scope.document_el[0].addEventListener(enter_event, onEnterEvent, true);
                    scope.calendar_el.bind(start_enter_event, onStartEnterEvent);

                    // stop propagation
                    scope.calendar_el.bind(enter_event, function (e) {
                        e.stopPropagation();
                    });

                    scope.input_el.bind("keydown", function (e) {
                        if (e.keyCode === 13 || e.keyCode === 32) {
                            e.stopPropagation();
                        }
                    });

                    scope.input_el.bind("keyup", scope.triggerChange);
                    scope.input_el.bind("change", scope.onChange);

                    bindTableEvents();

                    // hide calendar on blur on input field
                    scope.input_el.bind("blur", function () {
                        setTimeout(function () {
                            if (!scope.keep_open) {
                                scope.hideCalendar();
                            }

                            scope.keep_open = false;
                        }, 50);
                    });
                };

                var bindTableEvents = function () {
                    scope.select_elems = angular.element(scope.calendar_el[0].getElementsByClassName("picker-select"));

                    scope.select_elems.bind("change", function (event) {
                        var target = angular.element(event.target || event.srcElement);

                        if (target.hasClass("picker-select-month")) {
                            scope.goToMonth(target[0].value);
                        } else if (target.hasClass("picker-select-year")) {
                            scope.goToYear(target[0].value);
                        }
                    });
                };

                scope.bindTimeDropdownEvents = function () {
                    scope.time_select_el.bind("change", function (e) {
                        var temp_date = new Date(parseInt(e.target.value, 10)),
                            date = angular.copy(scope.bindTo);
                        date.setHours(temp_date.getHours());
                        date.setMinutes(temp_date.getMinutes());

                        const modifiedDate = scope.setDate(date);
                        scope.onUpdateDate(modifiedDate);
                    });
                };

                scope.unbindTimeDropdownEvents = function () {
                    scope.time_select_el.unbind("change");
                };

                var unbindTableEvents = function () {
                    if (scope.select_elems) {
                        scope.select_elems.unbind("change");
                    }
                };

                var unbindCalendarEvents = function () {
                    scope.document_el[0].removeEventListener(enter_event, onEnterEvent, true);
                    scope.calendar_el.unbind(start_enter_event, onStartEnterEvent);
                    scope.calendar_el.unbind(enter_event);
                    scope.input_el.unbind("blur");
                    scope.input_el.unbind("change");
                    scope.input_el.unbind("keydown");
                    scope.input_el.unbind("keyup");

                    unbindTableEvents();
                };

                scope.$on("triggerDatePicker", function () {
                    // tslint:disable-next-line:triple-equals
                    if (last_selected_calendar == scope.calendar_el && last_selected_calendar != current_selected_calendar && scope.is_open) {
                        scope.hideCalendar();
                    }
                });

                // unbind not needed event listener on destroy
                scope.$on("$destroy", function () {
                    unbindCalendarEvents();
                    scope.destroyCalendarElement();
                });

                scope.destroyCalendarElement = function () {
                    if (scope.calendar_el && scope.calendar_el[0]) {
                        scope.calendar_el.remove();
                    }
                };

                scope.$on("$routeChangeStart", function () {
                    if (scope.is_open) {
                        scope.hideCalendar();
                        unbindCalendarEvents();
                    }
                });

                scope.updateTimeDropdown = function (originally_passed_date) {
                    if (datePickerHelper.isDate(originally_passed_date)) {
                        scope.equalizeDateWithPicker(originally_passed_date);
                    }
                    $timeout(function () {
                        scope.adjustTimeDropdown(originally_passed_date);
                    });
                };

                scope.updateInputField = function (date) {
                    scope.input_el[0].value = date ? datePickerHelper.toString(date) : "";
                };

                scope.onUpdateDate = function (date) {
                    if (scope.onUpdate && date !== undefined && scope.bindTo_init_run === false) {
                        scope.onUpdate({ date: date });
                    }
                };

                scope.onUpdateMinDate = function (date) {
                    if (scope.onUpdate && date !== undefined && scope.minDate_init_run === false) {
                        scope.onUpdate({ date: date });
                    }
                };

                scope.onUpdateMaxDate = function (date) {
                    if (scope.onUpdate && date !== undefined && scope.maxDate_init_run === false) {
                        scope.onUpdate({ date: date });
                    }
                };

                scope.locateCalendar = function () {
                    scope.input_el_data = scope.input_el[0].getBoundingClientRect();
                    scope.calendar_el_data = scope.calendar_el[0].getBoundingClientRect();

                    const focusRingOffset = 1;
                    const distance_to_bottom = window.innerHeight - scope.input_el_data.bottom - scope.calendar_el_data.height;
                    const distance_to_top = scope.input_el_data.top - spa_menu_height;
                    let distance_top = scope.input_el_data.bottom - spa_menu_height + (window.pageYOffset || scope.document_el[0].scrollTop || 0);
                    let distance_left = window.innerWidth <= 340 ? 0 : scope.input_el_data.left;

                    if (distance_to_bottom <= 5 && distance_to_top > scope.calendar_el_data.height) {
                        distance_top = scope.input_el_data.top - spa_menu_height + (window.pageYOffset || scope.document_el[0].scrollTop || 0) - scope.calendar_el_data.height;
                        distance_top = distance_top - focusRingOffset;
                    } else {
                        distance_top = distance_top + focusRingOffset;
                    }

                    var windowWidth = window.innerWidth,
                        calendarWidth = scope.calendar_el[0].clientWidth,
                        calendarOffsetX = distance_left + calendarWidth;

                    //Correct position if the calendar is off screen on the right side.
                    if (calendarOffsetX > windowWidth) {
                        distance_left += windowWidth - calendarOffsetX;
                    }

                    // fix for IE and when used in sidebar
                    if (
                        scope.ignore_spa_menu ||
                        (scope.document_el.hasClass("ie") && scope.document_el.hasClass("modal-enabled")) ||
                        (scope.right_panel_holder_el.hasClass("active") && scope.document_el.hasClass("plus")) ||
                        scope.calendar_has_offset
                    ) {
                        distance_top += spa_menu_height;
                    }

                    scope.calendar_el.css({
                        top: distance_top + "px",
                        left: distance_left + "px"
                    });
                };

                var bindToWatch = function (new_val, old_val) {
                    if (datePickerHelper.isDate(new_val) && new_val.getSeconds() !== 0) {
                        new_val = scope.equalizeDateWithPicker(new_val);
                    }

                    const modifiedDate = scope.setDate(new_val);
                    scope.onUpdateDate(modifiedDate);
                };

                var minDateWatch = function (new_val, old_val) {
                    if ((new_val !== undefined && old_val === undefined && !scope.blank_date) || new_val === old_val) {
                        return;
                    }

                    if (datePickerHelper.isDate(new_val)) {
                        new_val = scope.equalizeDateWithPicker(new_val);
                    }
                    scope.opts.min_date = new_val;
                    let modifiedDate = null;

                    if (scope.blank_date && scope.bindTo === null) {
                        modifiedDate = scope.setDate(null);
                    } else {
                        modifiedDate = scope.setDate(scope.opts.date);
                    }

                    scope.onUpdateMinDate(modifiedDate);
                };

                var maxDateWatch = function (new_val, old_val) {
                    if (new_val !== undefined && old_val === undefined && !scope.blank_date) {
                        return;
                    }
                    scope.opts.max_date = new_val;
                    let modifiedDate = null;

                    if (scope.blank_date && scope.bindTo === null) {
                        modifiedDate = scope.setDate(null);
                    } else {
                        modifiedDate = scope.setDate(scope.opts.date);
                    }

                    scope.onUpdateMaxDate(modifiedDate);
                };

                var watchFn = function (new_val, old_val, callback, init_run) {
                    if (new_val === old_val && !init_run) {
                        return;
                    }

                    if (new_val !== null) {
                        new_val = datePickerHelper.normalizeDate(new_val);
                    }

                    old_val = datePickerHelper.normalizeDate(old_val);

                    if (!datePickerHelper.compareDates(new_val, old_val) || init_run) {
                        callback(new_val, old_val);
                    }
                };

                scope.triggerChange = function (e) {
                    if (UserAgentService.ie && e.keyCode === 13) {
                        scope.onChange(e);
                    }
                };

                scope.initVisuals = function () {
                    scope.descriptionId = `date-picker-instructions-${scope.$id}`;
                    scope.ariaDescribedBy = scope.alternativeAriaLabel === undefined ? "" : scope.descriptionId;
                    scope.document_el = angular.element(document.documentElement);
                    scope.right_panel_holder_el = angular.element(document.getElementsByClassName("right-panel-holder")[0]);
                    var calendar_el = document.createElement("div");
                    calendar_el.className = "calendar";
                    // tslint:disable-next-line:no-unsafe-dom-insert-calls
                    document.body.appendChild(calendar_el);
                    scope.calendar_el = angular.element(calendar_el);
                    scope.input_el = angular.element(element[0].getElementsByTagName("input")[0]);
                    scope.time_select_el = scope.time_dropdown_options !== false ? angular.element(element[0].getElementsByClassName("picker-select-time")[0]) : undefined;

                    const modifiedDate = scope.setDate(scope.opts.date);
                    scope.onUpdateDate(modifiedDate);

                    // show calendar on click on input field
                    scope.input_el.bind(enter_event, function (event) {
                        event.stopPropagation();
                        scope.showCalendar();
                    });

                    // show calendar on focus on input field
                    scope.input_el.bind("focus", function () {
                        scope.keep_open = false;
                        scope.showCalendar();
                        element[0].classList.add("date-picker--input-focused");
                    });

                    scope.minDate_init_run = true;
                    scope.maxDate_init_run = true;

                    scope.bindToWatch = scope.$watch("bindTo", function (new_val, old_val) {
                        watchFn(new_val, old_val, bindToWatch, scope.bindTo_init_run);
                        scope.bindTo_init_run = false;
                    });

                    if (attrs.endOfDay !== undefined) {
                        scope.$watch("endOfDay", function (new_val, old_val) {
                            if (new_val !== old_val) {
                                const modifiedDate = scope.setDate(scope.bindTo);
                                scope.onUpdateDate(modifiedDate);
                            }
                        });
                    }

                    if (attrs.minDate !== undefined) {
                        scope.minDateWatch = scope.$watch("minDate", function (new_val, old_val) {
                            watchFn(new_val, old_val, minDateWatch, scope.minDate_init_run);
                            scope.minDate_init_run = false;
                        });
                    }

                    if (attrs.maxDate !== undefined) {
                        scope.maxDateWatch = scope.$watch("maxDate", function (new_val, old_val) {
                            watchFn(new_val, old_val, maxDateWatch, scope.maxDate_init_run);
                            scope.maxDate_init_run = false;
                        });
                    }
                };

                // stop binding to non-empty object because it can overwrite existing sensitive data
                if (!datePickerHelper.isEmptyObject(scope.bindTo)) {
                    throw new Error("'bindTo' object is not empty. You can't bind to non-empty object. Try different object or add key to your current bindTo object like so: bindTo='obj.date'");
                } else {
                    scope.bindTo_init_run = true;
                    scope.init();
                    scope.initVisuals();
                }
            }
        };
    }
]);
