const constants = {
    PERMISSIONS: {
        VIEW: "View",
        WRITE: "Write",
        COLLABORATE: "Collaborate",
        LINK: "Link",
        NOPERMISSION: "NoPermission"
    },
    FOLDERS: {
        MAIN: "Main",
        INVISIBLE: "Invisible",
        DELETED: "Deleted",
        NOT_IN_USERS: "NotInUsersFolders"
    },
    PAGE_KEYS: {
        CONTENT_ID: "ContentId",
        PAGE_ASSET_ID: "PageAssetId",
        USER_RESPONSE_ID: "UserResponseId"
    },
    TYPES: {
        ACTIVITY_LOG: "ActivityLog",
        BLOG: "Blog",
        CALLING_CARD: "CallingCard",
        COLLECTION: "AssetCollection",
        COLLECTION_WRAPPER: "AssetCollectionWrapper",
        CONVERSATION: "Conversation",
        CV: "CV",
        FILE: "File",
        FORM: "Form",
        FORM_RESPONSE: "FormResponse",
        POST: "Post",
        TEMPLATE_BUILDER: "TemplateBuilder",
        WEBFOLIO: "Webfolio",
        WEBFOLIO_PAGE: "WebfolioPage",
        WORKBOOK: "WorkBook",
        WORKBOOK_BUILDER: "WorkbookBuilder",
        WORKBOOK_RESPONSE: "WorkBookResponse"
    },
    SUB_TYPES: {
        ACCOLADE: "Professional/AboutMeAccolade",
        AFFILIATION: "Professional/AboutMeAffiliation",
        BELIEF: "Personal/AboutMeBelief",
        DATABASE: "Document/Database",
        DOCUMENT: "Document",
        EMPLOYMENT: "Professional/AboutMeEmployment",
        EXPERIENCE: "Experience",
        FEEDBACK: "Feedback",
        HOBBY: "Personal/AboutMeHobby",
        LANGUAGE: "Academic/AboutMeLanguage",
        PDF: "Document/PDF",
        PERSONAL_STATEMENT: "Personal/AboutMePersonalStatement",
        PLAN: "Plan",
        PRESENTATION: "Document/Presentation",
        PUBLICATION: "Academic/AboutMePublication",
        QUALIFICATION: "Academic/AboutMeQualification",
        REFEREE: "Professional/AboutMeReferee",
        REFLECTION: "Reflection",
        ROLE: "Professional/AboutMeRole",
        SPREADSHEET: "Document/Spreadsheet",
        STANDARDS: "Standards",
        TALENT: "Talent",
        TEXT: "Document/Text",
        WORKSHEET: "Worksheet"
    },
    FILE: {
        TYPES: {
            IMAGE: "image",
            VIDEO: "video",
            AUDIO: "audio",
            PDF: "application/pdf"
        }
    },
    CATEGORIES: {
        POSTS: "Posts",
        RECORDS: "Records",
        DESIGNS: "Designs",
        DOCUMENTS: "Documents",
        MEDIA: "Media",
        CV: "Cv",
        OTHER_FILES: "OtherFiles",
        IMAGE: "Image",
        VIDEO: "Video",
        AUDIO: "Audio"
    },
    SPECIAL_TYPES: {
        APPLICANT: "Applicant",
        STANDARDS_OVERVIEW: "StandardsOverview"
    },
    PRIVACY_STATES: {
        CONFIDENTIAL: "Confidential",
        PRIVATE: "Private",
        ASSESSOR: "Assessor",
        SPECIFIC_ASSESSORS: "SpecificAssessors",
        ASSESSOR_FEEDBACK: "AssessorFeedback",
        DEFAULT: "Normal"
    },
    ALL_PRIVACY_STATES: ["Confidential", "Private", "Assessor", "SpecificAssessors"],
    WORKSPACE_ASSESSOR_ROLES: {
        INTERNAL_LIST: ["Tutor", "LeadTutor", "Moderator"],
        LEAD_TUTOR: "Lead Tutor",
        TUTOR: "Tutor",
        MODERATOR: "Moderator",
        EXTERNAL: "External",
        ALL: ["Tutor", "LeadTutor", "Moderator", "External"]
    },
    ASSET_OPTIONS: {
        IS_MAPPABLE: "IsMappable",
        CASCADE_BANNER_ID: "cascadeBannerId"
    },
    EVIDENCE_STATES: {
        NOT_EVIDENCED: "NotEvidenced",
        PARTIALLY_EVIDENCED: "PartiallyEvidenced",
        FULLY_EVIDENCED: "FullyEvidenced"
    }
};

export { constants as ASSET_CONSTANTS };
