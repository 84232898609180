const blockTypes = [
    "SingleLineTextBox",
    "FormContentEmbedElement",
    "MultiLineTextBox",
    "OptionsList-RadioButtons",
    "OptionsList-Checkboxes",
    "OptionsList-DropDown",
    "DatePickerInput",
    "BinaryAnswerInput",
    "RangeInput-Likert",
    "RangeInput-Standard",
    "GenericTable",
    "CapabilityRangeInput-Standard",
    "CapabilityBinaryAnswerInput",
    "CapabilityOptionList-RadioButtons",
    "CapabilityEvidenceOnly"
];

export { blockTypes as FEEDBACK_BLOCK_TYPES };
