import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../../constants/assessment.constants";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/pebbleDate";
import "../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "../../utilities/helpers";

class FeedbackSearchService {
    constructor(multiLanguageService, feedbackHelper, helpers, $filter) {
        this._$filter = $filter;
        this._multiLanguageService = multiLanguageService;
        this._feedbackHelper = feedbackHelper;
        this._helpers = helpers;

        this._searchTermFilters = [
            this._checkReplies.bind(this),
            this._checkName.bind(this),
            this._checkComment.bind(this),
            this._checkType.bind(this),
            this._checkStatus.bind(this),
            this._checkLeftOn.bind(this),
            this._checkDate.bind(this),
            this._checkSimilarity.bind(this),
            this._checkCapability.bind(this),
            this._checkApproval.bind(this),
            this._checkGrade.bind(this),
            this._checkPageVerification.bind(this),
            this._checkAttachmentName.bind(this),
            this._checkFeedbackTemplateName.bind(this),
            this._checkScorecard.bind(this),
            this._checkTurnitinInfo.bind(this)
        ];

        this._searchCommentTermFilters = [this._checkReplies.bind(this), this._checkName.bind(this), this._checkComment.bind(this), this._checkLeftOn.bind(this)];
    }

    searchFeedback(searchText, feedback) {
        const text = searchText.toLowerCase();

        return feedback.filter((feedback) => {
            return this._searchTermFilters.some((comparator) => comparator(feedback, text));
        });
    }

    searchComment(searchText, comments) {
        const text = searchText.toLowerCase();

        return comments.filter((comment) => {
            return this._searchCommentTermFilters.some((comparator) => comparator(comment, text));
        });
    }

    _checkName(feedback, searchText) {
        return this._containsText(feedback.Poster.Name, searchText);
    }

    _checkComment(feedback, searchText) {
        return this._containsTextNoHtml(feedback.Comment, searchText);
    }

    _checkType(feedback, searchText) {
        return this._containsText(feedback.FeedbackType, searchText);
    }

    _checkStatus(feedback, searchText) {
        return (searchText === "released" && feedback.Released) || (searchText === "unreleased" && !feedback.Released);
    }

    _checkLeftOn(feedback, searchText) {
        const dateText = this._multiLanguageService.getString("sidebar.asset_feedback.labels.left_at", {
            time: this._$filter("pebbleDate")(feedback.Posted, "timeOnly"),
            date: this._$filter("pebbleDate")(feedback.Posted)
        });

        let page = "";
        let origin = "";

        if (feedback.LeftOn === undefined) {
            page = ` ${this._feedbackHelper.getLeftOnString(feedback.AssetTitle)}`;
        } else {
            if (feedback.LeftOn.PageTitle !== null) {
                page = ` ${this._feedbackHelper.getLeftOnString(feedback.LeftOn.PageTitle)}`;
            }
            if (feedback.LeftOn.WorkspaceName !== null || feedback.LeftOn.AssignmentName !== null) {
                origin = ` ${this._feedbackHelper.getLeftOnOriginString(feedback.LeftOn.WorkspaceName, feedback.LeftOn.AssignmentName)}`;
            }
        }

        return this._containsText(`${dateText}${page}${origin}`, searchText);
    }

    _checkDate(feedback, searchText) {
        const monthList = this._multiLanguageService.getString("sidebar.asset_feedback.filtering.months").split(",");
        return monthList[new Date(feedback.Posted).getMonth()].toLowerCase().includes(searchText);
    }

    _checkSimilarity(feedback, searchText) {
        if (feedback.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.SIMILARITYSCORE) {
            const keys = [
                ASSESSMENT_CONSTANTS.FEEDBACK_PROPERTIES.SIMILARITYSCORE.INTERNETSIMILARITY,
                ASSESSMENT_CONSTANTS.FEEDBACK_PROPERTIES.SIMILARITYSCORE.PUBLICATIONSSIMILARITY,
                ASSESSMENT_CONSTANTS.FEEDBACK_PROPERTIES.SIMILARITYSCORE.DOCUMENTSSIMILARITY,
                ASSESSMENT_CONSTANTS.FEEDBACK_PROPERTIES.SIMILARITYSCORE.OVERALLSIMILARITY
            ];
            const foundText = keys.some((key) => this._containsText(feedback[key], searchText));

            if (foundText) {
                return true;
            }

            return this._containsText(this._multiLanguageService.getString("sidebar.asset_feedback.type_titles.similarity"), searchText);
        }
        return false;
    }

    _checkCapability(feedback, searchText) {
        if (feedback.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.CAPABILITY) {
            if (this._containsText(this._multiLanguageService.getString("atlas_sidebar.feedback_types.approval.capability_label"), searchText)) {
                return true;
            }

            const statusText = feedback[ASSESSMENT_CONSTANTS.FEEDBACK_PROPERTIES.APPROVAL.STATUSTEXT];
            if (this._containsText(statusText, searchText)) {
                return true;
            }
        }

        return false;
    }

    _checkApproval(feedback, searchText) {
        if (feedback.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.APPROVAL) {
            const keys = [ASSESSMENT_CONSTANTS.FEEDBACK_PROPERTIES.APPROVAL.STATUSTEXT, ASSESSMENT_CONSTANTS.FEEDBACK_PROPERTIES.APPROVAL.LEVELTEXT];
            return keys.some((key) => this._containsText(feedback[key], searchText));
        }
        return false;
    }

    _checkGrade(feedback, searchText) {
        if (feedback.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.GRADE) {
            const foundText = this._containsTextNoHtml(feedback.Grade, searchText);

            if (foundText) {
                return true;
            }

            return this._containsText(this._multiLanguageService.getString("atlas_sidebar.feedback_types.grade.graded"), searchText);
        }
        return false;
    }

    _checkPageVerification(feedback, searchText) {
        if (feedback.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.PAGEVERIFICATION) {
            return this._containsText(this._multiLanguageService.getString("sidebar.asset_feedback.labels.page_verified"), searchText);
        }
        return false;
    }

    _checkAttachmentName(feedback, searchText) {
        if (feedback.Attachments.length > 0) {
            return feedback.Attachments.some((attachment) => this._containsTextNoHtml(attachment.Title, searchText));
        }
        return false;
    }

    _checkFeedbackTemplateName(feedback, searchText) {
        if (feedback.FeedbackTemplates.length > 0) {
            return feedback.FeedbackTemplates.some((feedbackTemplate) => this._containsText(feedbackTemplate.Title.replace(/&nbsp;/gi, " "), searchText));
        }
        return false;
    }

    _checkScorecard(feedback, searchText) {
        if (feedback.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.SCORECARD) {
            if (this._containsText(this._multiLanguageService.getString("sidebar.asset_feedback.labels.additional_grade"), searchText)) {
                return true;
            }

            if (this._containsText(feedback.Grade, searchText)) {
                return true;
            }

            if (this._containsText(this._multiLanguageService.getString("sidebar.asset_feedback.labels.total_weighted_score"), searchText)) {
                return true;
            }

            if (this._numberContainsText(feedback.TotalScore, searchText)) {
                return true;
            }

            return feedback.Scores.some((score) => {
                if (this._containsText(score.Description, searchText)) {
                    return true;
                }
                if (feedback.Weighted !== true) {
                    if (this._containsText(score.Title, searchText)) {
                        return true;
                    }
                } else if (this._containsText(this._multiLanguageService.getString("sidebar.asset_feedback.labels.weighted_title", { title: score.Title, weighting: score.Weighting }), searchText)) {
                    return true;
                }
                if (this._containsText(this._multiLanguageService.getString("sidebar.asset_feedback.labels.score", { score: score.Score }), searchText)) {
                    return true;
                }
            });
        }
        return false;
    }

    _checkReplies(feedback, searchText) {
        const replies = feedback.Replies;

        if (replies.length > 0) {
            const foundText = replies.some((reply) => {
                if (this._containsText(reply.Comment, searchText)) {
                    return true;
                }

                if (this._containsText(reply.Poster.Name, searchText)) {
                    return true;
                }

                const dateText = this._multiLanguageService.getString("sidebar.asset_feedback.labels.left_at", {
                    time: this._$filter("pebbleDate")(reply.Posted, "timeOnly"),
                    date: this._$filter("pebbleDate")(reply.Posted)
                });

                if (dateText.toLowerCase().includes(searchText)) {
                    return true;
                }
            });

            if (foundText && replies.length > ASSESSMENT_CONSTANTS.FEEDBACK_REPLIES.DEFAULT_SHOW_AMOUNT) {
                feedback.expandReplies = true;
            }
            return foundText;
        }
        return false;
    }

    _checkTurnitinInfo(feedback, searchText) {
        return (
            feedback.SubType === ASSESSMENT_CONSTANTS.FEEDBACK_SUBTYPES.TURNITIN &&
            this._containsText(this._multiLanguageService.getString(`sidebar.asset_feedback.title_${feedback.SubType}.type_${feedback.FeedbackType}`), searchText)
        );
    }

    _containsText(text, searchText) {
        return text !== null && text.toLowerCase().includes(searchText);
    }

    _numberContainsText(number, searchText) {
        return number !== null && number.toString().includes(searchText);
    }

    _containsTextNoHtml(text, searchText) {
        const sanitisedText = this._helpers.htmlToPlain(text.replace(/&nbsp;/gi, " ")).toLowerCase();
        return sanitisedText.includes(searchText);
    }
}

FeedbackSearchService.$inject = ["multiLanguageService", "feedbackHelper", "helpers", "$filter"];
angularAMD.service("feedbackSearchService", FeedbackSearchService);
export { FeedbackSearchService };
