import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../constants/mapToStandards.constants";
import { noCkEditorLinkUnlinkPlugin as editorConfig } from "../../inlineTextEditor/editorConfigs/noCkEditorLinkUnlinkPlugin";
import "../../multiLanguageService/multiLanguageService";
import "../../titledTextEditor/titledTextEditor.component";
import "../../persistentCompactRichTextEditor/peristentCompactRichTextEditor.component";
import template from "./evidence-justification.html";

class EvidenceJustification extends NgComponent {
    constructor(multiLanguageService) {
        super();

        this.multiLanguageService = multiLanguageService;
        this.richTextAutoFocus = true;
        this.richTextConfig = {
            ...editorConfig,
            extraPlugins: "sharedspace,linker",
            startupFocus: this.richTextAutoFocus
        };
        this.MAP_TO_STANDARDS_CONSTANTS = MAP_TO_STANDARDS_CONSTANTS;
        this.singleJustification = "";
        this.updateJustification = this._updateJustification.bind(this);
        this.richTextClassName = "evidence-justification__justification";
        this.richTextEditorDataHook = "justification-single-textbox";
        this.justificationLabel = this.multiLanguageService.getString("labels.evidence.evidence_justification_editor");
    }

    updateTitledJustification(justification, evidence) {
        this.onJustificationChange({
            evidenceId: evidence.EvidenceId,
            assetRefId: evidence.AssetRefId,
            capabilityId: evidence.CapabilityId,
            pageId: evidence.PageId,
            justification: justification
        });
    }

    _updateJustification(justification) {
        this.singleJustification = justification;

        this.onJustificationChange({
            justification: justification
        });
    }
}

export const evidenceJustificationDefinition = {
    bindings: {
        evidence: "<",
        justificationMode: "<",
        onJustificationModeChange: "&",
        justifyAllText: "<",
        onJustificationChange: "&"
    },
    template: template,
    controller: EvidenceJustification
};

EvidenceJustification.$inject = ["multiLanguageService"];
angularAMD.component("evidenceJustification", evidenceJustificationDefinition);
