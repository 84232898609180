import { angularAMD } from "@pebblepad/amd";
import template from "./feedbackCommentsEditor.html";
import "../react2angular/feedbackCommentEditor";
import { feedbackCommentEditorAdapterBindings } from "../reactEditorAdapter/FeedbackCommentEditorAdapterBindings.const";
import { StickyReactEditorAdapterController } from "../reactEditorAdapter/StickyReactEditorAdapterController";

export const feedbackCommentsEditorDefinition = {
    bindings: {
        autoFocus: "<",
        ...feedbackCommentEditorAdapterBindings
    },
    require: {
        stickyContainer: "^stickyContainer"
    },
    controller: StickyReactEditorAdapterController,
    name: "feedbackCommentsEditor",
    template: template
};

angularAMD.component(feedbackCommentsEditorDefinition.name, feedbackCommentsEditorDefinition);
