import { angularAMD } from "@pebblepad/amd";
import "./typeCheck.service";

angularAMD.service("computedStyleService", ComputedStyleService);
ComputedStyleService.$inject = ["typeCheck"];

function ComputedStyleService(typeCheck) {
    // Public API
    // =============================================================================================================
    this.getComputedStyles = getComputedStyles;
    this.parseStyleValue = parseStyleValue;

    // Private methods
    // =============================================================================================================
    var _stripValueUnitRegex = /(\D*$)/;

    function getComputedStyles(el, styles, parse) {
        var computedStyles = window.getComputedStyle(el),
            styleValues = {},
            currStyle;

        if (!typeCheck.isArray(styles)) {
            styles = [styles];
        }

        if (computedStyles !== void 0) {
            for (var i = 0, len = styles.length; i < len; i++) {
                currStyle = styles[i];
                styleValues[currStyle] = computedStyles[currStyle];
                if (parse) {
                    styleValues[currStyle] = parseStyleValue(styleValues[currStyle]);
                }
            }
        }

        return styleValues;
    }

    function parseStyleValue(value) {
        if (value !== void 0) {
            return parseFloat(value.replace(_stripValueUnitRegex, ""));
        }

        return null;
    }
}
