import { angularAMD } from "@pebblepad/amd";
import template from "./dropdown-option-component.html";

var ComponentObject = {
    bindings: {
        onValueChange: "&",
        heading: "<",
        tip: "<",
        value: "<",
        key: "<",
        options: "<"
    },
    controller: DropDownOptionComponent,
    template: template
};

// tslint:disable-next-line:no-empty
function DropDownOptionComponent() {}

DropDownOptionComponent.prototype.changeOption = function () {
    this.onValueChange({ key: this.key, value: this.value });
};

angularAMD.component("dropDownOptionComponent", ComponentObject);

export { ComponentObject as dropDownOptionComponent };
