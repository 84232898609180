import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import template from "./templates/progress-dots.html";

angularAMD.directive("progressDots", [
    "$sce",
    "$http",
    "baseUrlsFactory",
    function ($sce, $http, baseUrlsFactory) {
        return {
            template: template,
            restrict: "E",
            scope: {
                stages: "=",
                currentIndex: "=",
                goToStage: "&",
                showIndicators: "@"
            },

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.indicators = $scope.showIndicators === "true";
                }
            ],

            link: function (scope, element, attrs) {
                if (scope.goToStage) {
                    element.addClass("progress-dots--clickable");
                }

                scope.allyTabs = function (e) {
                    let index = scope.currentIndex;
                    switch (e.key) {
                        case "ArrowLeft":
                            index--;
                            break;
                        case "ArrowRight":
                            index++;
                            break;
                        default:
                            return;
                    }

                    scope.moveStage(index);
                };

                scope.moveStage = function (index) {
                    const maxLimit = Math.max(scope.stages.length - 1, 0);
                    let newIndex = index;

                    if (index < 0) {
                        newIndex = 0;
                    } else if (index > maxLimit) {
                        newIndex = maxLimit;
                    }

                    if (newIndex !== scope.currentIndex) {
                        scope.currentIndex = newIndex;
                        scope.goToStage({ index: scope.currentIndex });
                    }
                };
            }
        };
    }
]);
