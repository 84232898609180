export class SlowProcessReporter {
    private readonly _onReport: (elapsedTime: number) => void;
    private readonly _minimumElapsedTime: number;
    private readonly _throttleTime: number;
    private _startTime: number = 0;
    private _lastTriggeredTime: number = 0;

    constructor(onReport: (elapsedTime: number) => void, minimumElapsedTime: number, throttleTime: number) {
        this._onReport = onReport;
        this._minimumElapsedTime = minimumElapsedTime;
        this._throttleTime = throttleTime;
    }

    public start(): void {
        this._startTime = Date.now();
    }

    public stop(): void {
        if (this._lastTriggeredTime + this._throttleTime >= this._startTime) {
            return;
        }

        const endTime = Date.now();
        const elapsedTime = endTime - this._startTime;
        if (elapsedTime <= this._minimumElapsedTime) {
            return;
        }

        this._lastTriggeredTime = endTime;
        this._onReport(elapsedTime);
    }

    public cancel(): void {
        this._startTime = Infinity;
    }
}
