import { ComponentType, FunctionComponent } from "react";
import { BoundaryBridge } from "@pjs/core-ui";
import { WithLabelAndLabelledBy } from "../../types/WithLabelAndLabelledBy";
import { ContentInjectionEditorProps } from "./types/ContentInjectionEditorProps";
import { ContentInjectionEditor } from "./ContentInjectionEditor.component";

// eslint-disable-next-line @typescript-eslint/naming-convention
const EditorWithLabelAndLabelledBy = ContentInjectionEditor as ComponentType<WithLabelAndLabelledBy<ContentInjectionEditorProps>>;

export const ContentInjectionEditorWithBoundary: FunctionComponent<ContentInjectionEditorProps> = (props) => {
    return (
        <BoundaryBridge>
            <EditorWithLabelAndLabelledBy
                ariaLabel={props.ariaLabel}
                ariaLabelledBy={props.ariaLabelledBy}
                autoFocus={props.autoFocus}
                className={props.className}
                content={props.content}
                dataHook={props.dataHook}
                onChange={props.onChange}
                onToolbarDisplayChange={props.onToolbarDisplayChange}
                placeholder={props.placeholder}
                contentObservable={props.contentObservable}
                trackingElements={props.trackingElements}
                onLink={props.onLink}
            />
        </BoundaryBridge>
    );
};
