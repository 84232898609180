/**
 * A class used to run data migrators where appropriate.
 */
export class MigratorRunner {
    constructor() {
        this._migrators = [];
    }

    /**
     * Register the provided migrator to be run provided its conditions are met.
     * @param migrator - The data migrator being registered.
     * @returns {void}
     */
    registerMigrator(migrator) {
        this._migrators.push(migrator);
    }

    /**
     * Iterate over the registered migrators, and run them if their conditions are met.
     * @param {string} assetId - The short Id of the current asset
     * @param {Object} assetDto - The dto representing the current asset
     * @param {Object} localStorageData - Changes to the current asset present in local storage
     * @returns {Promise} - Promise that resolves to the result of the last migrator to run
     */

    async execute(assetId, assetDto, localStorageData) {
        if (assetDto === null || localStorageData === null) {
            return localStorageData;
        }

        let workingData = localStorageData;
        for (const migrator of this._migrators) {
            if (migrator.isRequired(assetDto, workingData)) {
                workingData = await migrator.run(assetId, workingData);
            }
        }

        return workingData;
    }
}
