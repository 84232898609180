"use strict";
import { angularAMD } from "@pebblepad/amd";

var puiAccordionHeaderDefinition = {
    require: {
        accordionPanel: "^puiAccordionPanel"
    },
    bindings: {
        headingLevel: "<",
        focus: "<",
        ariaDescribedById: "@"
    },
    transclude: {
        headerTitle: "headerTitle",
        headerContent: "?headerContent"
    },
    template:
        '<dt role="heading" aria-level="{{::$ctrl.safeHeadingLevel}}" class="pui-accordion__heading">\n' +
        '    <button id="pui-accordion-header-{{::$ctrl.id}}"\n' +
        '            ng-click="::$ctrl.handleClick()"\n' +
        '            class="pui-accordion__trigger pui-accordion__h{{::$ctrl.safeHeadingLevel}}"\n' +
        '            aria-expanded="{{$ctrl.expanded}}"\n' +
        '            auto-focus="{{$ctrl.focus}}"\n' +
        '            data-hook="accordion-header"\n' +
        '            uber-described-by="{{$ctrl.ariaDescribedById}}"\n' +
        '            aria-controls="pui-accordion-content-{{::$ctrl.id}}"\n' +
        "            >\n" +
        '       <div class="pui-accordion__heading-content-wrapper">' +
        '           <span class="pui-accordion__heading-title" ng-transclude="headerTitle">' +
        "           </span>" +
        '           <span class="pui-accordion__heading-content" ng-transclude="headerContent">' +
        "           </span>" +
        "       </div>" +
        '        <span class="pui-accordion__icon icon-arrow-down" ng-class="{\'pui-accordion__icon--open\': $ctrl.expanded}" aria-hidden="true">' +
        "        </span>\n" +
        "    </button>\n" +
        "</dt>",
    controller: PuiAccordionHeader
};

PuiAccordionHeader.$inject = ["$element"];

function PuiAccordionHeader($element) {
    this.expanded = false;
    this.id = null;
    this._$element = $element;
}

PuiAccordionHeader.prototype.handleClick = function () {
    this.expanded = this.accordionPanel.toggleExpanded();
};

PuiAccordionHeader.prototype.$onInit = function () {
    var accordionHeaderManager = new AccordionHeaderManager(this.setExpanded.bind(this), this._$element.find("button")[0]);
    this.id = this.accordionPanel.setHeaderManager(accordionHeaderManager);
    this.safeHeadingLevel = this.headingLevel ? this.headingLevel : 3;
};
PuiAccordionHeader.prototype.setExpanded = function (value) {
    this.expanded = value;
};

function AccordionHeaderManager(setExpanded, buttonElement) {
    this.setExpanded = setExpanded;
    this.buttonElement = buttonElement;
}

if (angularAMD.default !== null) {
    angularAMD.component("puiAccordionHeader", puiAccordionHeaderDefinition);
}
export { puiAccordionHeaderDefinition };
