import { Plugin, SchemaItemDefinition, ViewElement, IElementToMarkerConfig } from "@pebblepad/ckeditor";
import { PpMarkerRange } from "../../pp-marker-range/PpMarkerRange";

export class PpMarkerRangePlugin extends Plugin {
    private static readonly _schemaConfig: SchemaItemDefinition = {
        allowAttributes: ["id"],
        allowIn: ["$root", "$container", "$block", "blockQuote", "paragraph", "$text"],
        isBlock: false,
        isContent: false,
        isInline: false,
        isLimit: false,
        isSelectable: false
    };

    private static readonly _upcastConfig: IElementToMarkerConfig = {
        model: PpMarkerRangePlugin._viewElementToMarker,
        view: {
            attributes: ["id"],
            name: PpMarkerRange.tagName
        }
    };

    private static _viewElementToMarker(viewElement: ViewElement): string {
        const idValue = viewElement.getAttribute("id");
        return idValue !== undefined ? idValue : "";
    }

    public init(): void {
        this.editor.model.schema.register(PpMarkerRange.tagName, PpMarkerRangePlugin._schemaConfig);
        this.editor.conversion.for("upcast").elementToMarker(PpMarkerRangePlugin._upcastConfig);
    }
}
