import { FunctionComponent, PropsWithChildren, useContext, useRef } from "react";
import { useFactoryRef, useOnMount } from "@pjs/react-utilities";
import { BoundaryContext } from "../../boundary/Boundary.context";
import { floatingEmptyMenuFactory } from "../../aria-roles/menus/floating-menu/factories/FloatingEmptyMenuFactory";
import { IEmptyGroupedItemMenuProps } from "../interfaces/IEmptyGroupedItemMenuProps";
import "../styles/grouped-item-picker-empty-dropdown.css";

export const EmptyGroupedItemsMenu: FunctionComponent<PropsWithChildren<IEmptyGroupedItemMenuProps>> = (props) => {
    const boundary = useContext(BoundaryContext);
    const listRef = useRef<HTMLDivElement>(null);

    const floatingMenu = useFactoryRef(floatingEmptyMenuFactory, {
        boundaryRef: boundary.element,
        floatingRef: listRef,
        initialModel: props.menuModel,
        matchItemOnKeys: () => true,
        onModelChange: props.onMenuModelChange,
        tempTargetRef: props.tempTargetRef,
        triggerRef: props.buttonRef
    });

    useOnMount(() => {
        floatingMenu.updateModel(props.menuModel);

        return () => floatingMenu.destroy();
    });

    return (
        <div
            id={props.dropdownId}
            ref={listRef}
            data-hook={props.dataHook}
            tabIndex={-1}
            onBlur={floatingMenu.events.target.onFocusLoss}
            onKeyDown={(e) => floatingMenu.events.target.onKeyDown(e, [])}
            className="cui-grouped-item-picker__popup-container">
            {props.children}
        </div>
    );
};
