/**
 * Returns a boolean indicating whether the item is within this array
 * @param item Item to add to the Array
 */
Object.defineProperty(Array.prototype, "contains", {
    enumerable: false,
    writable: true,
    value: function (item, startIndex) {
        return this.indexOf(item, startIndex || 0) !== -1;
    }
});

/**
 * Returns a boolean indicating whether any of the items is within this array
 * @param item Item to add to the Array
 */
Object.defineProperty(Array.prototype, "containsAny", {
    enumerable: false,
    writable: true,
    value: function (b) {
        return this.some(function (el) {
            return b.indexOf(el) !== -1;
        });
    }
});

/**
 * Adds an item to the array while it does not already exist in it
 * @param item Item to add to this Array
 */
Object.defineProperty(Array.prototype, "safePush", {
    enumerable: false,
    writable: true,
    value: function (item) {
        if (!this.contains(item)) {
            this.push(item);
        }
    }
});

/**
 * Adds many items to the array
 * @param item Item to add to this Array
 */
Object.defineProperty(Array.prototype, "pushMany", {
    enumerable: false,
    writable: true,
    value: function (items, safePush) {
        for (var i in items) {
            this[safePush ? "safePush" : "push"](items[i]);
        }
    }
});

/**
 * Returns a new list with a sub set of the items within this Array,
 * where the item matches all of the queryObject members in member name and value,
 * or gets a true return from query method when given it
 * Example: [{name: 'Dave'}, {name: 'Bob'}].where({name: 'Dave'});
 * @param query
 * @returns {Array} New subset array of query results
 */
Object.defineProperty(Object.prototype, "where", {
    enumerable: false,
    writable: true,
    value: function (query) {
        var result = [];
        for (var item in this) {
            if (!this.hasOwnProperty(item)) {
                continue;
            }
            var matches = true;
            if (query instanceof Function) {
                matches = query(this[item], item);
            } else {
                for (var qItem in query) {
                    if (!query.hasOwnProperty(qItem)) {
                        continue;
                    }
                    // tslint:disable-next-line:triple-equals
                    if (this[item][qItem] != query[qItem]) {
                        matches = false;
                        break;
                    }
                }
            }
            // tslint:disable-next-line:triple-equals
            if (matches == null) {
                matches = true;
            }
            if (matches) {
                result.push(this[item]);
            }
        }
        return result;
    }
});

Object.defineProperty(Object.prototype, "forEachMember", {
    enumerable: false,
    writable: true,
    value: function (query, func) {
        for (var item in this) {
            if (!this.hasOwnProperty(item)) {
                continue;
            }

            var target = null;
            if (query) {
                if (query instanceof Function) {
                    target = query(this[item], item);
                } else if (typeof query === typeof "" || query instanceof String) {
                    target = this[item][query];
                }
            }
            target = target || this[item];
            func(target);
        }
    }
});

/**
 * Flattens arrays (or targeted nested arrays) into a single array
 * @param query Function returning member to iteratively collate (given an element)
 * @param query String identifying member to iteratively collate
 * @Returns {Array}
 */
Object.defineProperty(Object.prototype, "selectMany", {
    enumerable: false,
    writable: true,
    value: function (query) {
        var result = [];
        this.forEachMember(query, function (item) {
            for (var part in item) {
                if (item.hasOwnProperty(part)) {
                    result.push(item[part]);
                }
            }
        });
        return result;
    }
});

/**
 * Collates a new array out of targeted member of each element in the object
 * @param query Function returning member to collate (given an element)
 * @param query String identifying member to collate
 * @returns {Array}
 */
Object.defineProperty(Object.prototype, "selectOne", {
    enumerable: false,
    writable: true,
    value: function (query) {
        var result = [];
        this.forEachMember(query, function (item) {
            result.push(item);
        });
        return result;
    }
});

/**
 * Returns a new list with a sub set of the items within this Array,
 * where the items are only uniquely listed once
 */
Object.defineProperty(Array.prototype, "distinct", {
    enumerable: false,
    writable: true,
    value: function () {
        var result = [];
        for (var i = 0; i < this.length; i++) {
            result.safePush(this[i]);
        }

        return result;
    }
});

/**
 * Returns a new list with all of the items within this Array
 */
Object.defineProperty(Array.prototype, "clone", {
    enumerable: false,
    writable: true,
    value: function () {
        return this.slice();
    }
});

/**
 * Removes an n-amount of items from an Array.
 */
Object.defineProperty(Array.prototype, "remove", {
    enumerable: false,
    writable: true,
    value: function () {
        var argument = null,
            foundIndex = -1;

        for (var i = 0, l = arguments.length; i < l; i++) {
            argument = arguments[i];
            foundIndex = this.indexOf(argument);

            if (foundIndex !== -1) {
                this.splice(foundIndex, 1);
            }
        }

        return this;
    }
});

/**
 * Removes a single element from the array
 */
Object.defineProperty(Array.prototype, "removeOne", {
    enumerable: false,
    writable: true,
    value: function (i, findIndex) {
        if (findIndex) {
            i = this.indexOf(i);
        }

        if (i !== -1) {
            this.splice(i, 1);
        }
    }
});

/**
 * Removes a multiple elements from the array
 */
Object.defineProperty(Array.prototype, "removeMany", {
    enumerable: false,
    writable: true,
    value: function (array, findIndex) {
        var item = null,
            index = -1;
        for (var i = 0, l = array.length; i < l; i++) {
            item = array[i];
            if (findIndex === true) {
                index = this.indexOf(item);
            }

            if (index !== -1) {
                this.splice(index, 1);
            }
        }
    }
});

/**
 * Returns the indexes of all occurrences
 */
Object.defineProperty(Array.prototype, "indexesOf", {
    enumerable: false,
    writable: true,
    value: function (item) {
        var result = [];
        for (var i = 0; i < this.length; i++) {
            if (this[i] === item) {
                result.push(i);
            }
        }
        return result;
    }
});

/**
 * Returns all keys in the array
 */
Object.defineProperty(Array.prototype, "keysArray", {
    enumerable: false,
    get: function () {
        var keys = [],
            i = 0;
        // tslint:disable-next-line:no-empty
        for (keys[i++] in this) {
        }
        return keys;
    }
});

/**
 * Given method returns true for all elements in the array
 */
Object.defineProperty(Array.prototype, "all", {
    enumerable: false,
    writable: true,
    value: function (func) {
        var result = true;
        for (var i = 0; i < this.length; i++) {
            result = result && func(this[i]);
        }
        return result;
    }
});

/**
 * Check if two arrays contain exactly the same values - does not work with multi-dimensional arrays.
 */
Object.defineProperty(Array.prototype, "isEqualTo", {
    enumerable: false,
    value: function (contains) {
        var isEqual = false;
        if (this.length === contains.length) {
            isEqual = true;

            for (var i = 0, l = contains.length; i < l; i++) {
                if (this.indexOf(contains[i]) === -1) {
                    isEqual = false;
                    break;
                }
            }
        }
        return isEqual;
    }
});

/**
 * Concat two arrays and ensure no duplicates are added. Considerably faster than doing concat() then distinct().
 */
Object.defineProperty(Array.prototype, "distinctConcat", {
    enumerable: false,
    /**
     * @param {Array} arrayToMerge
     * @returns {Array}
     */
    value: function (arrayToMerge) {
        var mergedResult = this.slice(),
            item;
        for (var i = 0, l = arrayToMerge.length; i < l; i++) {
            item = arrayToMerge[i];
            if (mergedResult.indexOf(item) === -1) {
                mergedResult.push(item);
            }
        }
        return mergedResult;
    }
});

/**
 * Join two arrays which contain objects where values of a selected property are unique
 */
Object.defineProperty(Array.prototype, "uniquePropertyConcat", {
    enumerable: false,
    /**
     * @param {Array} arrayToMerge
     * @param {string} property - Object property key
     * @returns {Array}
     */
    value: function (arrayToMerge, property) {
        const merged = [];
        const values = [];

        //Cache the properties
        for (let i = 0; i < this.length; i++) {
            let item = this[i];
            merged.push(item);
            values.push(item[property]);
        }

        //Check if the value of a property exists in the other array
        for (let i = 0; i < arrayToMerge.length; i++) {
            let item = arrayToMerge[i];

            //If the value is unique add to the merged array
            if (values.indexOf(item[property]) === -1) {
                merged.push(item);
            }
        }

        return merged;
    }
});

/**
 * Removes items from an array where they are found in a compared array.
 */
Object.defineProperty(Array.prototype, "removeItems", {
    enumerable: false,
    writable: true,
    value: function (toRemove) {
        var item, index;
        for (var i = 0, l = toRemove.length; i < l; i++) {
            item = toRemove[i];
            // tslint:disable-next-line:no-conditional-assignment
            if ((index = this.indexOf(item)) !== -1) {
                this.splice(index, 1);
            }
        }
    }
});

/**
 * Removes items from an array where they are found in a compared array.
 */
Object.defineProperty(Array.prototype, "removeItemsByObjectKey", {
    enumerable: false,
    writable: true,
    /**
     * @param {Array} toRemove
     * @param {string} keyName
     * @returns {Array}
     */
    value: function (toRemove, keyName) {
        var item,
            resultArr = [],
            isInArray;

        for (var i = 0, l = this.length; i < l; i++) {
            isInArray = false;
            item = this[i];
            for (var j = 0, len = toRemove.length; j < len; j++) {
                if (toRemove[j].hasOwnProperty(keyName) && item.hasOwnProperty(keyName) && toRemove[j][keyName] === item[keyName]) {
                    isInArray = true;
                    break;
                }
            }

            if (!isInArray) {
                resultArr.push(item);
            }
        }

        return resultArr;
    }
});

/**
 * Checks if a property on an object inside of an array exists.
 */
Object.defineProperty(Array.prototype, "findInNestedObject", {
    enumerable: false,
    writable: true,
    /**
     * @param {string} property
     * @param value
     * @returns {number} - Found = > 0, Not found = -1
     */
    value: function (property, value) {
        var item;
        for (var i = 0, l = this.length; i < l; i++) {
            item = this[i];
            if (item[property] === value) {
                return i;
            }
        }
        return -1;
    }
});

Object.defineProperty(Array.prototype, "findInNestedObjectsRecursively", {
    enumerable: false,
    writable: true,
    /**
     * @param {string} property
     * @param value
     * @param {string} nestedArrayKey
     * @param {string} typeKey
     * @param nestedType
     * @returns {Object|null}
     */
    value: function (property, value, nestedArrayKey, typeKey, nestedType) {
        var match = null;
        for (var i = 0, l = this.length, item; i < l; i++) {
            item = this[i];
            if (item[property] === value) {
                match = item;
                break;
            } else if (!typeKey || item[typeKey] === nestedType) {
                match = item[nestedArrayKey].findInNestedObjectsRecursively(property, value, nestedArrayKey, typeKey, nestedType);
                if (match) {
                    break;
                }
            }
        }
        return match;
    }
});

/**
 * Returns an array of values based off keys found within a nested object
 */
Object.defineProperty(Array.prototype, "getValuesFromNestedKeys", {
    enumerable: false,
    writable: true,
    /**
     * @param {string} property
     * @returns {Array}
     */
    value: function (property) {
        var found = [],
            item;
        for (var i = 0, l = this.length; i < l; i++) {
            item = this[i];
            if (item[property] !== undefined) {
                found.push(item[property]);
            }
        }
        return found;
    }
});

/**
 * Returns the sum of the values in an array
 */
Object.defineProperty(Array.prototype, "sumValues", {
    enumerable: false,
    writable: true,
    value: function () {
        return this.reduce(function (prev, current) {
            return parseInt(current, 10) + prev;
        }, 0);
    }
});

/**
 * Randomises the order of an array
 */
Object.defineProperty(Array.prototype, "randomiseOrder", {
    enumerable: false,
    writable: true,
    value: function () {
        for (var i = this.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = this[i];
            this[i] = this[j];
            this[j] = temp;
        }

        return this;
    }
});

/**
 * Gets the next item from the array, looping back to the beginning if it reaches the end
 */
Object.defineProperty(Array.prototype, "getNextItem", {
    enumerable: false,
    writable: true,
    value: function (currentItem) {
        var nextItem = this[0];
        var currentPos = this.indexOf(currentItem);

        if (currentPos + 1 < this.length) {
            nextItem = this[currentPos + 1];
        }

        return nextItem;
    }
});

//Polyfills!
//------------------------------------------------------------------------------------------------------------------
if (!Array.prototype.findIndex) {
    /**
     * Returns the index of the first element which satisfies a passed testing function.
     * Docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
     * Support for ES6 version: >= Chrome 45, >= FF 25,  Edge, >= Opera 32, >= Safari 7.1 - NO IE support
     */
    Object.defineProperty(Array.prototype, "findIndex", {
        enumerable: false,
        writable: true,
        value: function (evaluator) {
            for (var i = 0, l = this.length; i < l; i++) {
                if (evaluator(this[i], i, this)) {
                    return i;
                }
            }

            return -1;
        }
    });
}

if (!Array.prototype.find) {
    /**
     * Returns the value of the first element which satisfies a passed testing function.
     * Docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
     * Support for ES6 version: >= Chrome 45, >= FF 25,  >= Edge 12, >= Opera 32, >= Safari 7.1 - NO IE support
     */
    Object.defineProperty(Array.prototype, "find", {
        enumerable: false,
        writable: true,
        value: function (evaluator) {
            //In keeping with the full version, if no value is found 'undefined' is returned. :/
            return this[this.findIndex(evaluator)];
        }
    });
}

if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        writable: true,
        value: Array.prototype.contains
    });
}
