import "../../utilities/i18nHelper.service";

MultiLanguageContentProvider.$inject = ["$q"];

function MultiLanguageContentProvider($q) {
    //=== Public API ===============================================================================================
    this.getLanguageContentViaKey = getLanguageContentViaKey;
    this.getLanguageContent = getLanguageContent;

    //=== Private Properties =======================================================================================
    var i18nHelper = angular.injector(["i18n"]).get("i18nHelper"),
        content = {
            learningCentre: "learningCentre/languages/learningCentre-",
            customMessages: "homeDash/customMessages/languages/custom-messages-"
        };

    //=== Private Methods ==========================================================================================
    function getLanguageContent(contentPath) {
        var deferred = $q.defer();
        if (!contentPath) {
            deferred.reject();
        } else {
            i18nHelper.getLanguageContent(contentPath).then(function (data) {
                deferred.resolve(data);
            });
        }

        return deferred.promise;
    }

    function getLanguageContentViaKey(key) {
        return getLanguageContent(content[key]);
    }
}

export { MultiLanguageContentProvider };
