import { angularAMD } from "@pebblepad/amd";
import "../utilities/shameOnBrowser";
import "../progressDots/progressDots";
import "../utilities/onRepeatEnd";
import "../multiLanguageService/multiLanguageService";
import template from "./templates/sliding-banner-new.html";

angularAMD.directive("slidingBannerNew", SlidingBannerNew);
SlidingBannerNew.$inject = ["$sce", "$rootScope", "baseUrlsFactory", "multiLanguageService"];

angularAMD.controller("SlidingBannerNewController", SlidingBannerNewController);
SlidingBannerNewController.$inject = ["$timeout", "shameOnBrowser", "$element"];

function SlidingBannerNew($sce, $rootScope, baseUrlsFactory, multiLanguageService) {
    return {
        template: template,
        restrict: "E",
        scope: {
            slides: "="
        },
        controller: "SlidingBannerNewController",
        controllerAs: "vm",
        bindToController: true,
        link: function (scope) {
            scope.shared_component_base_url = baseUrlsFactory.shared_component_base_url;
            scope.helpIconsFolder = $sce.getTrustedResourceUrl(scope.shared_component_base_url + "builder/helpBannerTemplates/workbook/images/");
            scope.multiLanguageService = multiLanguageService;
            $rootScope.banner_ready = true;
        }
    };
}

function SlidingBannerNewController($timeout, shameOnBrowser, $element) {
    this.services = {
        timeout: $timeout,
        shameOnBrowser: shameOnBrowser
    };
    this.element = $element;
    this.currentStage = 0;
    this.styleClasses = {
        active: "sliding-banner-new__pane--active"
    };

    this.elements = {
        panes: []
    };
}

SlidingBannerNewController.prototype.prevSlide = function () {
    this.nextSlidingStage(-1);
};

SlidingBannerNewController.prototype.nextSlide = function () {
    this.nextSlidingStage(1);
};

SlidingBannerNewController.prototype.nextSlidingStage = function (position) {
    var newStage = this.currentStage + position;

    if (newStage < 0) {
        this.setStage(this.slides.length - 1);
    } else if (newStage >= this.slides.length) {
        this.setStage(0);
    } else {
        this.setStage(newStage);
    }
};

SlidingBannerNewController.prototype.setStage = function (index) {
    if (index < 0) {
        index = this.slides.length - 1;
    } else if (index >= this.slides.length) {
        index = 0;
    }

    var stage = this.slides[index],
        lastStage = this.currentStage;
    if (stage) {
        this.currentStage = index;
        this.updateView(this.currentStage, lastStage);
    }

    return this.currentStage;
};

SlidingBannerNewController.prototype.updateView = function (index, lastIndex) {
    var newPane = this.elements.panes[index],
        lastPane = this.elements.panes[lastIndex];

    lastPane.removeClass(this.styleClasses.active);
    newPane.addClass(this.styleClasses.active);
};

SlidingBannerNewController.prototype.cachePaneElements = function () {
    this.elements.panes = Array.prototype.slice.call(this.element[0].getElementsByClassName("sliding-banner-new__pane"));
    for (var i = 0, l = this.elements.panes.length; i < l; i++) {
        this.elements.panes[i] = angular.element(this.elements.panes[i]);
    }

    return this.elements.panes;
};

SlidingBannerNewController.prototype.init = function () {
    this.cachePaneElements();
    this.elements.panes[this.currentStage].addClass(this.styleClasses.active);
};
