import { tracker } from "@pjs/analytics";

export class ProgramWorkspaceStatusLinkService {
    constructor(statusToolbarFactory, user, $http, baseUrlsFactory, $window, multiLanguageService) {
        this._userService = user;
        this._$http = $http;
        this._baseUrlsFactory = baseUrlsFactory;
        this._statusToolbarFactory = statusToolbarFactory;
        this._$window = $window;
        this._multiLanguageService = multiLanguageService;
    }

    getLink(templateResponseAssetRefId) {
        const userDto = this._userService.getDto();
        if (userDto === null || !userDto.EnableProgramReport) {
            return Promise.resolve({ link: "", reportAvailable: false });
        }

        return this._$http
            .get(`${this._baseUrlsFactory.api_base_url}Program/CheckIsProgramTemplateInAtlas?templateResponseRef=${templateResponseAssetRefId}`)
            .then((rsp) => ({ link: rsp.data.ProgramReportUrl, reportAvailable: rsp.data.ReportAvailable }));
    }

    createStatus(url) {
        const message = this._multiLanguageService.getString("program_workspace_status_link.message");
        const linkText = this._multiLanguageService.getString("program_workspace_status_link.link_text");
        const linkAriaText = this._multiLanguageService.getString("program_workspace_status_link.link_aria_text");
        const a11yNotification = `${message} ${linkText}`;
        const statusDictionary = this._statusToolbarFactory.createStatusDictionary(message, a11yNotification);
        const linkOptions = {
            role: "link",
            ariaLabel: linkAriaText,
            onClick: () => {
                tracker.trackEvent("Asset viewer", "Click", "Program workspace feedback template link");
                this._$window.open(url, "_blank", { popup: true });
            },
            text: linkText
        };
        return this._statusToolbarFactory.createStatus(statusDictionary, false, Infinity, null, null, "", "", [linkOptions], "program-workspace-status-link");
    }
}
