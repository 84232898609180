import { doesNodeDirectlyContainText } from "./DoesNodeDirectlyContainText.function";

export function doNodesDirectlyContainText(nodes: NodeList): boolean {
    for (const node of nodes) {
        if (doesNodeDirectlyContainText(node)) {
            return true;
        }
    }

    return false;
}
