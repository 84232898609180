import { AttributeConverter } from "../../types/AttributeConverter";
import { semanticInlineSwap } from "../../../../utils/semantic-inline-tag-swap/SemanticInlineSwap.function";

export const inlineTextDecorationConverter: AttributeConverter = (sandboxedElement, sandboxedDocument) => {
    let decorationLineStyle = sandboxedElement.style.textDecorationLine;
    if (decorationLineStyle === "") {
        decorationLineStyle = sandboxedElement.style.textDecoration;

        if (decorationLineStyle === "") {
            return;
        }
    }

    sandboxedElement.style.textDecorationLine = "";
    sandboxedElement.style.textDecoration = "";

    if (decorationLineStyle === "line-through") {
        semanticInlineSwap("S", sandboxedElement, sandboxedDocument, "STRIKE");
        return;
    }

    if (decorationLineStyle === "underline") {
        semanticInlineSwap("U", sandboxedElement, sandboxedDocument);
    }
};
