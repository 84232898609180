import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../utilities/urlService";

angularAMD.factory("spaLogOutService", [
    "$http",
    "$window",
    "baseUrlsFactory",
    "urlService",
    function ($http, $window, baseUrlsFactory, urlService) {
        var factory = {};

        factory.logOut = function () {
            try {
                $window.localStorage.setItem("seenWelcomeModal", false);
                // tslint:disable-next-line:no-empty
            } catch (e) {}

            $http.defaults.withCredentials = true;
            return urlService.promisedPost(baseUrlsFactory.api_base_url + "SharedMenu/Logout");
        };

        return factory;
    }
]);
