import { FunctionComponent } from "react";
import { ParseInlineHtml } from "@pjs/security";
import { Checkbox } from "../checkbox/Checkbox.component";
import { IHtmlLabelCheckboxProps } from "./interfaces/IHtmlLabelCheckboxProps";

export const HtmlLabelCheckbox: FunctionComponent<IHtmlLabelCheckboxProps> = (props) => {
    return (
        <Checkbox isChecked={props.isChecked} isDisabled={props.isDisabled} isRequired={props.isRequired} onChange={props.onChange}>
            <ParseInlineHtml htmlString={props.label} />
        </Checkbox>
    );
};
