import { FunctionComponent } from "react";
import { Icon, evidenceCompleteIcon, Checkbox } from "@pjs/core-ui";
import { formResponseElementI18n } from "../i18n/FormResponseElementI18n.const";
import { IAnswerEvidenceProps } from "./interfaces/IAnswerEvidenceProps";
import { EvidenceItem } from "./EvidenceItem.component";
import "./styles/answer-evidence.css";

const iconEnabledFill: string = "#0077BB";
const iconDisabledFill: string = "#89929A";

export const AnswerEvidence: FunctionComponent<IAnswerEvidenceProps> = (props: IAnswerEvidenceProps) => {
    const evidenceComplete = formResponseElementI18n.getString("evidence.complete");
    const evidenceNotComplete = formResponseElementI18n.getString("evidence.not_complete");
    const noEvidence = formResponseElementI18n.getString("evidence.no_evidence");
    const hasEvidence = props.evidence.length > 0;
    const isDisabled = props.isDisabled || !hasEvidence;

    const evidenceCompletionText = props.isEvidenceComplete ? evidenceComplete : evidenceNotComplete;

    const answerEvidenceIconHiddenClass = props.isEvidenceComplete ? "" : "fre-answer-evidence__status-icon--hidden";

    return (
        <div className="fre-answer-evidence">
            <div className={`fre-answer-evidence__status ${hasEvidence ? "" : "fre-answer-evidence__status--disabled"}`}>
                <div className="fre-answer-evidence__status-checkbox-wrapper">
                    {props.showMarkAsComplete ? (
                        <Checkbox isChecked={props.isEvidenceComplete} isDisabled={isDisabled} onChange={props.onToggleEvidenceIsComplete}>
                            {evidenceComplete}
                        </Checkbox>
                    ) : (
                        <span data-hook="answer-evidence-topbar-text">{evidenceCompletionText}</span>
                    )}
                </div>
                <span aria-hidden="true" className="fre-answer-evidence__status-icon-wrapper" data-hook="answer-evidence-status-icon-wrapper">
                    <Icon
                        className={`fre-answer-evidence__status-icon ${answerEvidenceIconHiddenClass}`}
                        source={evidenceCompleteIcon}
                        fill={isDisabled ? iconDisabledFill : iconEnabledFill}
                        data-hook="answer-evidence-status-icon"
                    />
                </span>
            </div>
            {hasEvidence ? (
                props.evidence.map((evidence) => (
                    <EvidenceItem
                        key={evidence.aggregateId}
                        evidence={evidence}
                        isDisabled={props.isDisabled}
                        onDeleteEvidence={props.onDeleteEvidence}
                        onEditJustification={props.onEditJustification}
                        onSelectEvidence={props.onSelectEvidence}
                    />
                ))
            ) : (
                <div className="fre-answer-evidence__no-evidence-message" data-hook="answer-evidnce-no-evidence-message">
                    <p>{noEvidence}</p>
                </div>
            )}
        </div>
    );
};
