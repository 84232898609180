import { Placement } from "@floating-ui/dom";
import { Immutable } from "@pjs/utilities";
import { RefObject } from "react";
import { first, Subscription, switchMap, tap } from "@pjs/observables";
import { PositioningFactory } from "../../../floating-positioner/factories/types/PositioningFactory";
import { createFloatingPositioner } from "../../../floating-positioner/CreateFloatingPositioner.function";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";

import { IAriaEventAdapter } from "../menu-event-adapter/interfaces/IAriaEventAdapter";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";

export class FloatingMenu<TModel extends IAriaModelWithType, TEvents> {
    public readonly events: Readonly<TEvents>;

    private readonly _floatingPositionerConfig: {
        middlewareFactory: PositioningFactory;
        placement: Placement;
    };
    private readonly _ariaEventAdapter: IAriaEventAdapter<TModel, TEvents>;
    private readonly _triggerRef: RefObject<HTMLElement>;
    private readonly _floatingRef: RefObject<HTMLElement>;
    private readonly _boundaryRef: RefObject<HTMLElement>;
    private readonly _reactionMap: Map<AriaMenuStatus, Array<(model: Immutable<TModel>) => void>> = new Map();
    private readonly _modelChangeSubscription: Subscription;
    private readonly _onFirstPosition: undefined | (() => void) = undefined;
    private _currentModel: Immutable<TModel> | null = null;
    private _floatingPositionSubscription: Subscription | null = null;

    constructor(
        triggerRef: RefObject<HTMLElement>,
        floatingRef: RefObject<HTMLElement>,
        boundaryRef: RefObject<HTMLElement>,
        config: {
            floatingPositionerConfig: {
                middlewareFactory: PositioningFactory;
                placement: Placement;
            };
            ariaEventAdapter: IAriaEventAdapter<TModel, TEvents>;
            onModelChange: (model: TModel) => void;
            onFirstPosition?: () => void;
        }
    ) {
        this._triggerRef = triggerRef;
        this._floatingRef = floatingRef;
        this._boundaryRef = boundaryRef;
        this._floatingPositionerConfig = config.floatingPositionerConfig;

        this._onFirstPosition = config.onFirstPosition;

        this._ariaEventAdapter = config.ariaEventAdapter;
        this.events = this._ariaEventAdapter.events;
        this._modelChangeSubscription = this._ariaEventAdapter.modelChanges.subscribe((model) => {
            config.onModelChange(model);
        });

        this.addReaction(AriaMenuStatus.Open, this._positionFloatingElement.bind(this));
        this.addReaction(AriaMenuStatus.Closed, this._cleanupFloating.bind(this));
        this.addReaction(AriaMenuStatus.ClosedByKeyboardEvent, this._cleanupFloating.bind(this));
    }

    public destroy(): void {
        this._modelChangeSubscription.unsubscribe();
        if (this._floatingPositionSubscription !== null) {
            this._floatingPositionSubscription.unsubscribe();
        }
    }

    public updateModel(model: Immutable<TModel>): void {
        this._ariaEventAdapter.updateModel(model);
        this._handleModelUpdate(model);
        this._currentModel = model;
    }

    public addReaction(modelType: AriaMenuStatus, callback: (model: Immutable<TModel>) => void): void {
        const currentMappedActions = this._reactionMap.get(modelType);

        if (currentMappedActions === undefined) {
            this._reactionMap.set(modelType, [callback]);
            return;
        }

        currentMappedActions.push(callback);
    }

    private _positionFloatingElement(): void {
        if (this._triggerRef.current === null || this._floatingRef.current === null) {
            return;
        }

        const boundaryElement = this._boundaryRef.current;
        if (boundaryElement === null) {
            const htmlStringRef = (this._triggerRef.current.cloneNode(false) as HTMLElement).outerHTML;
            throw new Error(`Missing boundary for positioning! Ensure it is provided correctly.\nTrigger ref: ${htmlStringRef}`);
        }

        const positionerObservable = createFloatingPositioner(
            this._triggerRef.current,
            this._floatingRef.current,
            boundaryElement,
            this._floatingPositionerConfig.middlewareFactory,
            this._floatingPositionerConfig.placement
        );

        if (this._onFirstPosition !== undefined) {
            this._floatingPositionSubscription = positionerObservable
                .pipe(
                    first(),
                    tap(() => {
                        (this._onFirstPosition as () => void)();
                    }),
                    switchMap(() => positionerObservable)
                )
                .subscribe();

            return;
        }

        this._floatingPositionSubscription = positionerObservable.subscribe();
    }

    private _cleanupFloating(): void {
        if (this._floatingPositionSubscription !== null) {
            this._floatingPositionSubscription.unsubscribe();
        }
    }

    private _handleModelUpdate(model: Immutable<TModel>): void {
        if (this._currentModel !== null && this._currentModel.type === model.type) {
            return;
        }

        const reactions = this._reactionMap.get(model.type);

        if (reactions === undefined) {
            return;
        }

        for (const reaction of reactions) {
            reaction(model);
        }
    }
}
