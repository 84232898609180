function CkEditorElementStyleHelper(element) {
    this._element = element;
}

CkEditorElementStyleHelper.prototype.setElement = function (element) {
    this._element = element;
    return this;
};

CkEditorElementStyleHelper.prototype.getValue = function (styleName) {
    return this._element.getStyle(styleName);
};

CkEditorElementStyleHelper.prototype.getNumericValue = function (styleName, unit) {
    return Number(this.getValue(styleName).split(unit)[0]);
};

export { CkEditorElementStyleHelper };
