import { DropdownView, EventInfo } from "@pebblepad/ckeditor";
import { createToolbarDropdownPositioner, createToolbarOverflowMenuPositioner, isVisuallyHidden, Placement } from "@pjs/core-ui";
import { finalise, first, KillSignal, Subscription, switchMap, takeUntil, tap } from "@pjs/observables";

const priorityHighest = { priority: "highest" } as const;
const priorityLowest = { priority: "lowest" } as const;

export class CkePanelPositionHandler {
    public static handle(
        toolbarElement: HTMLElement | null,
        dropdownView: DropdownView,
        placement: Placement,
        boundaryElement: HTMLElement | null,
        killSignal: KillSignal,
        isOverflowMenu: boolean
    ): void {
        const referenceElement = dropdownView.buttonView.element as HTMLElement;
        const floatingElement = dropdownView.panelView.element as HTMLElement;

        const boundary = boundaryElement ?? document.body;

        let subscription: Subscription | null = null;

        dropdownView.on("change:isOpen", CkePanelPositionHandler._blockPanelFocus, priorityHighest);

        dropdownView.on(
            "change:isOpen",
            (_evt, _name, value: boolean) => {
                if (!value) {
                    if (subscription !== null) {
                        subscription.unsubscribe();
                    }

                    return;
                }

                const observable = isOverflowMenu
                    ? createToolbarOverflowMenuPositioner(referenceElement, floatingElement, boundary, toolbarElement, placement)
                    : createToolbarDropdownPositioner(referenceElement, floatingElement, boundary, placement);

                subscription = observable
                    .pipe(
                        first(),
                        tap(() => {
                            CkePanelPositionHandler._resetPanelState(dropdownView);
                            CkePanelPositionHandler._setPanelSizeCssVariables(dropdownView, boundary);
                        }),
                        switchMap(() => observable),
                        tap(() => {
                            CkePanelPositionHandler._setScrollbarState(dropdownView);
                            CkePanelPositionHandler._setPanelSizeCssVariables(dropdownView, boundary);
                        }),
                        takeUntil(killSignal),
                        finalise(() => CkePanelPositionHandler._clearPanelStyles(dropdownView))
                    )
                    .subscribe();
            },
            priorityLowest
        );
    }

    private static _blockPanelFocus(evt: EventInfo, _name: string, value: boolean): void {
        if (value) {
            const dropdownView = evt.source as unknown as DropdownView;
            (dropdownView.panelView.element as HTMLElement).style.visibility = "hidden";
        }
    }

    private static _setPanelSizeCssVariables(dropdownView: DropdownView, boundary: HTMLElement): void {
        const panelElement = dropdownView.panelView.element as HTMLElement;

        panelElement.style.setProperty("--ck-dropdown-height", `${panelElement.offsetHeight}px`);
        panelElement.style.setProperty("--ck-dropdown-boundary-width", `${boundary.clientWidth}px`);
    }

    private static _clearPanelStyles(dropdownView: DropdownView): void {
        if (dropdownView.panelView.element !== undefined && dropdownView.panelView.element !== null) {
            dropdownView.panelView.element.removeAttribute("style");
        }
    }

    private static _setScrollbarState(dropdownView: DropdownView): void {
        const element: HTMLElement = dropdownView.panelView.element as HTMLElement;
        if (element.style.maxHeight !== "") {
            element.style.overflowY = "auto";
            return;
        }
        element.style.removeProperty("overflow-y");
    }

    private static _resetPanelState(dropdownView: DropdownView): void {
        const selectedItem = (dropdownView.panelView.element as HTMLElement).querySelector<HTMLElement>(".ck-on");
        const element: HTMLElement = dropdownView.panelView.element as HTMLElement;

        element.style.visibility = "";

        CkePanelPositionHandler._setScrollbarState(dropdownView);

        if (selectedItem !== null && !isVisuallyHidden(selectedItem)) {
            selectedItem.focus();
        } else {
            dropdownView.panelView.focus();
        }
    }
}
