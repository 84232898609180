import { angularAMD } from "@pebblepad/amd";
import { Subject, filter } from "@pjs/observables";
import { SHARE_TYPES } from "../../constants/shareTypes.constants";

export class ShareStatusService {
    constructor() {
        this._subject = new Subject();
    }

    observeAssessmentShares() {
        return this._subject.pipe(filter((share) => share.type === SHARE_TYPES.ASSESSMENT));
    }

    notifyShareUpdate(shareMode, assetIds) {
        this._subject.next({
            type: shareMode,
            assetIds: assetIds
        });
    }
}

ShareStatusService.$inject = [];
angularAMD.service("shareStatusService", ShareStatusService);
