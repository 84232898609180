import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../utilities/relationalPositioningService";
import "../utilities/clickableService";
import "../multiLanguageService/multiLanguageService";
import template from "./templates/tooltip.html";

angularAMD.directive("tooltip", [
    "$sce",
    "baseUrlsFactory",
    "clickableService",
    "relationalPositioningService",
    "multiLanguageService",
    function ($sce, baseUrlsFactory, clickableService, relationalPositioningService, multiLanguageService) {
        return {
            restrict: "E",
            controller: [
                "$scope",
                "$element",
                function ($scope, $element) {
                    $scope.getTemplate = function () {
                        return $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + $scope.template);
                    };

                    $scope.onTransitionEnd = function () {
                        //Handle rapid clicks which would otherwise cause unnecessary positioning calculations, due to transitions not happening
                        if ($scope.clickable.isOpen === true && $scope.transitionsEnded > $scope.numberOfTransitions) {
                            $scope.transitionsEnded -= $scope.numberOfTransitions;
                        }

                        if (--$scope.transitionsEnded < 1) {
                            $scope.displayTip();
                            /* When the tooltip is toggled off the number of transitions is double the original, due to transitions running when the tip gets reset.
                            Prevents the position calculations from running unnecessarily */
                            $scope.transitionsEnded = $scope.numberOfTransitions * 2;
                        }
                    };

                    $scope.displayTip = function () {
                        var last = $scope.positioner.lastSide;
                        $scope.positioner.fit($scope.side, $scope.position, $scope.setToBoundaryWidth);
                        if (last !== $scope.positioner.lastSide) {
                            $scope.trigger_el.removeClass(last);
                        }
                        $scope.trigger_el.addClass($scope.positioner.lastSide);
                        var sides = $scope.positioner.getPositioningSides($scope.positioner.lastSide, true);
                        $scope.content_el.css("float", sides.x);
                        $scope.content_el[0].focus();
                    };
                }
            ],

            scope: {
                label: "@",
                tip: "<",
                template: "@template", //Full path to template
                side: "@side", //Preferred side to position the tooltip on
                position: "@position", //Empty, relative or absolute
                boundary: "=boundary", //Angular Element. Element to use as a boundary for positioning logic and Clickable Service.
                setToBoundaryWidth: "=",
                targetText: "<?",
                hover: "<?"
            },
            template: template,
            link: function (scope, element, attr) {
                scope.multiLanguageService = multiLanguageService;
                scope.tip_el = angular.element(element[0].getElementsByClassName("tooltip-content-wrapper")[0]);
                scope.content_el = angular.element(element[0].getElementsByClassName("tooltip-content")[0]);
                scope.container = angular.element(element[0].getElementsByClassName("tooltip")[0]);
                scope.trigger_el = angular.element(element[0].getElementsByClassName("tooltip-trigger")[0]);
                scope.side = attr.side || "left";
                scope.initialScopeId = scope.$id;

                var styles = window.getComputedStyle(scope.tip_el[0]);
                var transitionsOnElement = styles.transitionProperty !== undefined ? styles.transitionProperty.split(/,/g) : [""];
                var isActuallyEmpty = styles.transitionDelay === "0s";

                //Even if a transition has not been applied the transition property defaults to 'all', check if the delay is 0s to determine if it is actually empty
                scope.transitionsEnded = scope.numberOfTransitions = isActuallyEmpty !== true && transitionsOnElement[0] !== "" ? transitionsOnElement.length : 0;

                //Create container at end of DOM to absolute position tooltip
                if (attr.position === "absolute") {
                    var wrapper_el = document.getElementById("tooltip-wrapper");
                    if (wrapper_el === null) {
                        wrapper_el = document.createElement("div");
                        wrapper_el.id = "tooltip-wrapper";
                        //Save on re-draw by appending the tip before it is actually applied to the body
                        // tslint:disable-next-line:no-unsafe-dom-insert-calls
                        wrapper_el.appendChild(scope.tip_el[0]);
                        // tslint:disable-next-line:no-unsafe-dom-insert-calls
                        document.body.appendChild(wrapper_el);
                    } else {
                        // tslint:disable-next-line:no-unsafe-dom-insert-calls
                        wrapper_el.appendChild(scope.tip_el[0]);
                    }
                }

                //TODO: Check for better solution
                var unbind = scope.$watch("boundary", function () {
                    var boundingBox_el = scope.boundary;
                    const spaMenu = document.getElementById("spa-menu-wrapper");
                    const spaMenuHeight = spaMenu !== null ? spaMenu.clientHeight : 0;

                    scope.clickable = new clickableService(scope.trigger_el, boundingBox_el, scope.tip_el, scope.tip_el);
                    scope.positioner = new relationalPositioningService(scope.tip_el, scope.container, boundingBox_el, 0, { top: spaMenuHeight });

                    //If there are CSS transitions attached to the tip element wait for the transitions to end before running fit().
                    if (scope.numberOfTransitions !== 0) {
                        //Check which event to register - Move to Helper?
                        var transitionEndEvent = (function () {
                            var transitions = {
                                    transition: "transitionend",
                                    WebkitTransition: "webkitTransitionEnd",
                                    MozTransition: "transitionend",
                                    OTransition: "oTransitionEnd"
                                },
                                element = document.body;
                            for (var t in transitions) {
                                if (typeof element.style[t] !== "undefined") {
                                    return transitions[t];
                                }
                            }
                        })();

                        scope.tip_el[0].addEventListener(transitionEndEvent, scope.onTransitionEnd, false);
                        scope.clickable.addTrigger();
                    } else {
                        //Apply fit() directly to the callback.
                        scope.clickable.addTrigger(function () {
                            scope.displayTip();
                        });
                    }

                    if (scope.hover) {
                        scope.trigger_el.bind("mouseenter", (e) => {
                            scope.clickable.toggle(e);
                            scope.displayTip();
                        });
                        scope.trigger_el.bind("mouseleave", (e) => scope.clickable.hide());
                    }

                    scope.content_el.bind("blur", function (e) {
                        scope.clickable.hide();
                    });
                    unbind();
                });
            }
        };
    }
]);
