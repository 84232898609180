import { ButtonView, DecoupledEditor, DirectChange, EventInfo, LinkCommand, ManualDecorator, Plugin, UnlinkCommand } from "@pebblepad/ckeditor";
import { isSafeUrlProtocol } from "@pjs/security";
import { richTextI18n } from "../../i18n/RichTextI18n.const";
import { FocusTrackerProxy } from "./FocusTrackerProxy";
import { linkModelAttributes } from "./constants/LinkModelAttributes.const";
import { linkIcon } from "./Linker.icon";
import { LinkerProcessor } from "./LinkerProcessor";
import { ProcessLink } from "./types/ProcessLink";
import { LinkerModelSchema } from "./LinkerModelSchema";
import { ILinkerSelectionPosition } from "./interfaces/ILinkerSelectionPosition";

export class LinkerPlugin extends Plugin {
    public static readonly linkCommand: string = "link";
    public static readonly unlinkCommand: string = "unlink";
    public static readonly configKey: string = "linker";
    private _caretGravityId: string = "";

    public init(): void {
        const editor = this.editor as DecoupledEditor;

        const linkerConfig = editor.config.get(LinkerPlugin.configKey) as { onLink: ProcessLink } | undefined;
        if (linkerConfig === undefined || linkerConfig.onLink === undefined) {
            return;
        }

        LinkerModelSchema.applyTo(editor);
        editor.model.document.selection.on("change:range", this._fixCaretPosition.bind(this));
        editor.model.document.selection.on("change:attribute", this._fixStartOfLinkCaretPosition.bind(this));

        const linkCommand = new LinkCommand(editor);
        const unlinkCommand = new UnlinkCommand(editor);

        editor.commands.add(LinkerPlugin.linkCommand, linkCommand);
        editor.commands.add(LinkerPlugin.unlinkCommand, unlinkCommand);

        linkCommand.manualDecorators.add(
            new ManualDecorator({
                attributes: {
                    rel: "noopener noreferrer",
                    target: "_blank"
                },
                id: linkModelAttributes.isExternal,
                label: "",
                mode: "manual"
            })
        );

        editor.ui.componentFactory.add("linker", () => this._setupButtonView(editor, linkerConfig.onLink, linkCommand, unlinkCommand));
    }

    private _setupButtonView(editor: DecoupledEditor, onLink: ProcessLink, linkCommand: LinkCommand, unlinkCommand: UnlinkCommand): ButtonView {
        const button = new ButtonView();

        editor.keystrokes.set("Ctrl+K", (_, cancel) => {
            button.fire("execute");
            cancel();
        });

        button.set({
            icon: linkIcon,
            label: richTextI18n.getString("toolbar_items.linker.label"),
            tooltip: true
        });

        button.on("execute", () => {
            const linkProcessor = new LinkerProcessor(editor, linkCommand, unlinkCommand);
            const focusTrackerProxy = new FocusTrackerProxy(editor);
            const result = linkProcessor.getSelectedContent();

            onLink(result, focusTrackerProxy).subscribe({
                complete: () => focusTrackerProxy.restore(),
                next: (linkObj) => {
                    if (linkObj.mode === "remove") {
                        linkProcessor.removeLink();
                        return;
                    }

                    if (!isSafeUrlProtocol(linkObj.updatedLink)) {
                        return;
                    }

                    const updatedLinkText = linkObj.updatedLinkText === "" && result.blockCount <= 1 ? linkObj.updatedLink : linkObj.updatedLinkText;
                    if (updatedLinkText !== result.linkText) {
                        linkProcessor.insertText(updatedLinkText);
                    }

                    linkProcessor.addLink(linkObj.updatedLink, linkObj.isExternal);
                }
            });
        });

        return button;
    }

    private _fixCaretPosition(): void {
        if (this._caretGravityId !== "") {
            this.editor.model.change((writer) => {
                writer.restoreSelectionGravity(this._caretGravityId);
                this._caretGravityId = "";
            });
        }

        const selection = this.editor.model.document.selection;
        if (!selection.isCollapsed) {
            return;
        }

        const position = selection.getFirstPosition();
        if (position === null) {
            return;
        }

        if (this._isCaretAtStartOfLink(position)) {
            this._removeLinkAttributesFromSelection();
            return;
        }

        if (this._isCaretAtEndOfLink(position)) {
            this._caretGravityId = this.editor.model.change((writer) => writer.overrideSelectionGravity());
        }
    }

    private _isCaretAtEndOfLink(position: ILinkerSelectionPosition): boolean {
        return position.nodeBefore !== null && position.nodeBefore.hasAttribute(linkModelAttributes.href) && position.nodeBefore.endOffset === position.path[position.path.length - 1];
    }

    private _isCaretAtStartOfLink(position: ILinkerSelectionPosition): boolean {
        return position.nodeBefore === null && position.isAtStart && position.nodeAfter !== null && position.nodeAfter.hasAttribute(linkModelAttributes.href);
    }

    private _removeLinkAttributesFromSelection(): void {
        this.editor.model.change((writer) => {
            writer.removeSelectionAttribute("linkHref");
            writer.removeSelectionAttribute("linkIsExternal");
        });
    }

    private _fixStartOfLinkCaretPosition(_: EventInfo, directChange: DirectChange): void {
        const selection = this.editor.model.document.selection;
        if (!selection.isCollapsed || !directChange.attributeKeys.includes("linkHref")) {
            return;
        }

        const position = selection.getFirstPosition();
        if (position !== null && this._isCaretAtStartOfLink(position)) {
            this._removeLinkAttributesFromSelection();
        }
    }
}
