import { FocusEvent, KeyboardEvent, MouseEvent, RefObject } from "react";
import { Observable, Subject } from "@pjs/observables";
import { Immutable } from "@pjs/utilities";
import { IGroupedItemMenuModelAdapterConfig } from "./interfaces/IGroupedItemMenuModelAdapterConfig";
import { IGroupedItemsMenuEventAdapter } from "./interfaces/IGroupedItemsMenuEventAdapter";
import { MenuAriaModel } from "./types/MenuAriaModel";
import { IMenuTargetKeyEvents } from "./interfaces/IMenuTargetKeyEvents";
import { IAriaEventAdapter } from "./interfaces/IAriaEventAdapter";

export class GroupedItemsMenuEventAdapter<TData> implements IAriaEventAdapter<MenuAriaModel, IGroupedItemsMenuEventAdapter<TData>> {
    public modelChanges: Observable<Immutable<MenuAriaModel>>;
    public events: IGroupedItemsMenuEventAdapter<TData>;

    private readonly _config: IGroupedItemMenuModelAdapterConfig<TData>;
    private readonly _modelSubject: Subject<Immutable<MenuAriaModel>>;
    private readonly _targetRef: RefObject<HTMLElement>;
    private readonly _triggerRef: RefObject<HTMLElement>;

    private _currentModel: Immutable<MenuAriaModel>;
    private readonly _tempTargetRef: RefObject<HTMLElement>;

    constructor(
        config: IGroupedItemMenuModelAdapterConfig<TData>,
        triggerRef: RefObject<HTMLElement>,
        targetRef: RefObject<HTMLElement>,
        initialModel: MenuAriaModel,
        tempTargetRef: RefObject<HTMLElement>
    ) {
        this._targetRef = targetRef;
        this._triggerRef = triggerRef;
        this._config = config;
        this._currentModel = initialModel;
        this._modelSubject = new Subject<Immutable<MenuAriaModel>>();
        this.modelChanges = this._modelSubject.asObservable();
        this._tempTargetRef = tempTargetRef;

        this.events = {
            item: {
                onClick: (_e: MouseEvent, itemIndex: number) => this._generateNewModel(config.item.onClick(_e, itemIndex))
            },
            target: {
                onFocusLoss: this._handleTargetFocusLoss.bind(this),
                onKeyDown: this._handleTargetKeyDown.bind(this)
            }
        };
    }

    public updateModel(newModel: MenuAriaModel): void {
        this._currentModel = newModel;
    }

    private _handleTargetFocusLoss(e: FocusEvent): void {
        this._generateNewModel(this._config.target.onFocusLoss(e, this._triggerRef, this._targetRef, this._tempTargetRef.current));
    }

    private _handleTargetKeyDown(e: KeyboardEvent, items: Array<TData>): void {
        const onKey = this._config.target[e.key as keyof IMenuTargetKeyEvents<TData>] ?? this._config.target.default;

        if (onKey === undefined) {
            return;
        }

        e.stopPropagation();

        if (this._config.target[e.key as keyof IMenuTargetKeyEvents<TData>] !== undefined) {
            e.preventDefault();
        }

        this._generateNewModel(onKey(e, this._currentModel, items));
    }

    private _generateNewModel(newBaseModel: Partial<Immutable<MenuAriaModel>> | null): void {
        if (newBaseModel === null) {
            return;
        }

        const newModel = { ...this._currentModel, ...newBaseModel } as unknown as Immutable<MenuAriaModel>;

        this._modelSubject.next(newModel);
    }
}
