import { angularAMD } from "@pebblepad/amd";

HighlightItemDirective.$inject = [];
function HighlightItemDirective() {
    return {
        restrict: "A",
        scope: {
            onHighlight: "&"
        },
        controller: HighlightItemController,
        bindToController: true
    };
}

HighlightItemController.$inject = ["$element", "$timeout", "$attrs"];
function HighlightItemController($element, $timeout, $attrs) {
    this._$element = $element;
    this._$timeout = $timeout;
    this._$attrs = $attrs;
    this._highlightTimers = [];

    this._$attrs.$observe(
        "highlightItem",
        function (newValue) {
            if (newValue !== "") {
                this._highlightItem(newValue);
            }
        }.bind(this)
    );

    this._$element.on(
        "$destroy",
        function () {
            for (var i = 0; i < this._highlightTimers.length; i++) {
                this._$timeout.cancel(this._highlightTimers[i]);
            }
        }.bind(this)
    );
}

HighlightItemController.prototype._highlightItem = function (itemId) {
    var element = this._$element[0].querySelector("#" + itemId);
    var highlightClass = "highlight-item--highlight";

    if (element && !element.classList.contains(highlightClass)) {
        element.classList.add(highlightClass);
        var highlightTimer = this._$timeout(function () {
            element.classList.remove(highlightClass);
        }, 7500);

        this._highlightTimers.push(highlightTimer);
    }

    this.onHighlight();
};

angularAMD.directive("highlightItem", HighlightItemDirective);
export { HighlightItemDirective as highlightItem };
