import { from, KillSignal, Observable, Subject, takeUntil } from "@pjs/observables";
import { Immutable } from "@pjs/utilities";
import { reactDialogRenderer } from "../dialog-view/ReactDialogRenderer";
import { IDialogDirector } from "../dialog-director/interfaces/IDialogDirector";
import { IMultiStepDialogInstanceConfig } from "./interfaces/IMultiStepDialogInstanceConfig";
import { DialogInstance } from "./DialogInstance";
import { IMultiStepDialogInstance } from "./interfaces/IMultiStepDialogInstance";
import { IDialogStep } from "./interfaces/IDialogStep";
import { LazyDialogInstanceType } from "./types/LazyDialogInstanceType";
import { IDialogInstanceResolvedConfig } from "./interfaces/IDialogInstanceResolvedConfig";

export class LazyDialogInstance<TModel> implements LazyDialogInstanceType<TModel> {
    public readonly modelChanges: Observable<Immutable<TModel>>;

    private readonly _modelChangesSubject: Subject<Immutable<TModel>>;
    private _model: Immutable<TModel>;
    private readonly _lazyConfig: IMultiStepDialogInstanceConfig<TModel>;
    private _instance: IMultiStepDialogInstance<TModel> | null = null;
    private readonly _killSignal: KillSignal;

    constructor(config: IMultiStepDialogInstanceConfig<TModel>) {
        this._model = config.initialModel;
        this._lazyConfig = config;
        this._killSignal = new KillSignal();
        this._modelChangesSubject = new Subject<Immutable<TModel>>();
        this.modelChanges = this._modelChangesSubject.asObservable();
    }

    public load(director: IDialogDirector): void {
        from(this._generateRealConfig())
            .pipe(takeUntil(this._killSignal))
            .subscribe((dialogConfig) => {
                const instance: IMultiStepDialogInstance<TModel> = new DialogInstance(dialogConfig, director);
                this._instance = instance;

                director.enqueue({
                    instance: instance,
                    render: reactDialogRenderer
                });

                instance.modelChanges.pipe(takeUntil(this._killSignal)).subscribe(this._modelChangesSubject);
            });
    }

    public updateModel(model: Partial<TModel>): void {
        if (this._instance === null) {
            this._model = Object.assign({}, this._model, model);
            this._modelChangesSubject.next(this._model);
            return;
        }

        this._instance.updateModel(model);
    }

    public destroy(): void {
        this._killSignal.send();
        this._modelChangesSubject.complete();

        if (this._instance !== null) {
            this._instance.destroy();
        }
    }

    private async _generateRealConfig(): Promise<IDialogInstanceResolvedConfig<TModel>> {
        const steps: Array<IDialogStep<TModel, IMultiStepDialogInstance<TModel>>> = await Promise.all(
            this._lazyConfig.steps.map(async (step) => {
                const component = await step.component();

                return {
                    ...step,
                    component: component
                };
            })
        );

        return {
            ...this._lazyConfig,
            initialModel: this._model,
            steps: steps
        };
    }
}
