var date = "date";
var numeric = "numeric";
var pbString = "string";
var time = "time";

var constants = {
    MAX_DATA_TYPES: 2,
    CELLS: {
        DATE: date,
        DUMMY: "dummy",
        EDITABLE: "editable",
        EMPTY: "empty",
        STRING: pbString,
        NUMERIC: numeric,
        TOTAL: "total",
        TIME: time,
        HEADER: "header",
        ALL_SELECTABLE: [
            // Any new dropdown items need to go in here as well
            date,
            numeric,
            pbString,
            time
        ],
        ALL_AUTOSUM: [numeric, time],
        OPTIONS: {
            NUMERIC: {
                MAX_DECIMALS: 13
            }
        }
    },
    CLASSES: {
        DATE: "icon-calendar table-el__icon-calendar",
        NUMERIC: "icon-one-two-three table-el__icon-one-two-three",
        STRING: "icon-title table-el__icon-title",
        TIME: "icon-timer table-el__icon-timer"
    },
    BUILDER_TABLES: {
        ROW_HEADINGS: "BuilderFormTableContainer-RowHeadings",
        FIXED_ROWS: "BuilderFormTableContainer-FixedRows",
        ROWS_ONLY: "BuilderFormTableContainer-RowHeadingsNoColumnHeadings",
        RUBRIC: "BuilderRubricContainer",
        GENERIC: "GenericBuilderTable"
    },
    RUBRIC_CONTAINER: "RubricContainer",
    AUTO_SUM_BUILDER_TABLES: ["BuilderFormTableContainer-RowHeadings", "BuilderFormTableContainer-FixedRows", "BuilderFormTableContainer-RowHeadingsNoColumnHeadings"],
    AUTO_SUM_FORM_CONTAINERS: ["FormTableContainer-FixedRows", "FormTableContainer-RowHeadings", "FormTableContainer-RowHeadingsNoColumnHeadings"],
    MODES: {
        BUILDER: "builder",
        VIEWER: "viewer",
        FILLER: "filler"
    }
};

export { constants as TABLE_DATA_TYPES };
