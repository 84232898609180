import { angularAMD } from "@pebblepad/amd";
import { ApiConsentManager, CookieConsentManager } from "@pjs/asset-block";
import "../../../user/user.service";
import "../../../assetEndpointService/assetEndpoint.service";

class EmbedConsentService {
    constructor(_$q_, User, assetEndpointService) {
        this._$q = _$q_;
        this._user = User;
        this._assetEndpointService = assetEndpointService;
        this._apiConsentManager = new ApiConsentManager(
            () => this._getConsent(),
            (consented) => this._setConsent(consented)
        );
        this._cookieConsentManager = new CookieConsentManager();
    }

    _getConsent() {
        return this._$q.resolve(this._user.hasConsentedToEmbeddedContent());
    }

    _setConsent(consented) {
        if (consented) {
            return this._assetEndpointService.grantEmbeddedConsent();
        } else {
            return this._assetEndpointService.revokeEmbeddedConsent();
        }
    }

    _getConsentManager() {
        if (this._user.getDto() === null) {
            return this._cookieConsentManager;
        }

        return this._apiConsentManager;
    }

    grantConsent() {
        return this._getConsentManager().grantConsent();
    }

    revokeConsent() {
        return this._getConsentManager().revokeConsent();
    }

    consent() {
        return this._getConsentManager().consent;
    }
}

EmbedConsentService.$inject = ["$q", "User", "AssetEndpointService"];
angularAMD.service("embedConsentService", EmbedConsentService);
