import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";
import "../utilities/baseUrlsFactory";
import "../autoFocus/autoFocus.directive";
import template from "./templates/number-picker.html";

angularAMD.directive("numberPicker", [
    "$sce",
    "multiLanguageService",
    "baseUrlsFactory",
    "$timeout",
    function ($sce, multiLanguageService, baseUrlsFactory, $timeout) {
        return {
            template: template,
            restrict: "E",
            scope: {
                eventId: "@",
                min: "@",
                max: "@",
                cancelAriaLabel: "@",
                fields: "=", //Array of objects {value: null, min: null, max: null, placeholder: 'Bob'} or Object of Objects,
                decimalPlaces: "<",
                hideClearBtn: "=",
                optionalClass: "@"
            },

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    //Variables --------------------------------------------------------------------------------------------
                    $scope.hasChanged = false;
                    $scope.inputFields = null;
                    $scope.elements = { firstInput: null };
                    $scope.defaultCancelAria = multiLanguageService.getString("accessibility.buttons.cancel_number_picker");
                    //Functions --------------------------------------------------------------------------------------------
                    //Although the input type is number, we run extra validation in case the browser does not support that type or the type gets changed
                    $scope.validate = function () {
                        var isValid = false; //False until prove true!

                        var item;
                        for (var key in $scope.fields) {
                            if ($scope.fields.hasOwnProperty(key)) {
                                item = $scope.fields[key];
                                //Test is there is input and the input is not full of whitespace
                                if (item && item.value !== null && /\S/.test(item.value)) {
                                    isValid = !isNaN(item.value);
                                    if (isValid === false) {
                                        break;
                                    }
                                }
                            }
                        }
                        return isValid;
                    };

                    $scope.updatedValue = function (field) {
                        $scope.hasChanged = true;
                        // If a number was input, change from string to number ie '3' becomes 3 so that arithmetic still works for that input
                        // Otherwise, leave the input as-is and allow restrictDecimals to validate it
                        if (field.value !== null && /^[0-9]+$/.test(field.value)) {
                            field.value = +field.value;
                        }
                        if (typeof field.onValueChange === "function") {
                            field.onValueChange(field.value, $scope.eventId);
                        }
                    };

                    $scope.clearValue = function (field) {
                        field.value = null;
                        if (typeof field.onValueChange === "function") {
                            field.onValueChange(field.value, $scope.eventId);
                        }
                        $scope.hasChanged = false;
                        $scope.elements.firstInput.focus();
                        $scope.$emit("numberPickerUpdated", $scope.eventId, $scope.fields);
                    };

                    $scope.onConfirm = function () {
                        $scope.hasChanged = false;
                        $scope.elements.firstInput.focus();
                        $scope.$emit("numberPickerUpdated", $scope.eventId, $scope.fields);
                    };

                    $scope.clear = function () {
                        for (var key in $scope.fields) {
                            if ($scope.fields.hasOwnProperty(key)) {
                                $scope.fields[key].value = null;
                            }
                        }
                    };

                    $scope.checkLength = function () {
                        return $scope.fields.length || Object.keys($scope.fields).length;
                    };

                    $scope.cacheElements = function () {
                        $timeout(function () {
                            $scope.elements.firstInput = $element[0].getElementsByClassName("number-picker__input")[0];
                        });
                    };

                    $scope.init = function () {
                        $scope.cacheElements();

                        if (!$scope.eventId) {
                            $scope.eventId = null;
                        }

                        $scope.toggle = $scope.toggle && $scope.toggle === "true";
                    };
                }
            ],

            link: function (scope, element, attrs) {
                scope.init();
            }
        };
    }
]);
