export class PromiseAbortController {
    constructor() {
        this._isAborted = false;
    }

    abort() {
        this._isAborted = true;
    }

    isAborted() {
        return this._isAborted;
    }
}
