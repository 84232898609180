import { PANEL_CONSTANTS } from "@pjs/components/constants/panel.constants";
import { MAP_TO_STANDARDS_CONSTANTS } from "@pjs/components/constants/mapToStandards.constants";
import "@pjs/components/multiLanguageService/multiLanguageService";

export class StandardsOverviewService {
    constructor($rootScope, multiLanguageService) {
        this.$rootScope = $rootScope;
        this.multiLanguageService = multiLanguageService;
        this.PANEL_CONSTANTS = PANEL_CONSTANTS;
    }

    displayElementsOverview(data) {
        this.$rootScope.$broadcast("openRightHandSidePanel", {
            panelData: data,
            panel: this.PANEL_CONSTANTS.PANEL.ELEMENTS_OVERVIEW
        });
    }

    toggleSidebar() {
        this.$rootScope.$broadcast("spaMenuClosePanels");
    }

    getStatusInfo(status, isRecalled) {
        if (isRecalled) {
            return { text: this.multiLanguageService.getString("asset_summary.mapping.recalled"), class: "" };
        }

        switch (status) {
            case MAP_TO_STANDARDS_CONSTANTS.STATUS.APPROVED:
                return { text: this.multiLanguageService.getString("asset_summary.mapping.approved"), class: "mapping__approved" };
            case MAP_TO_STANDARDS_CONSTANTS.STATUS.REJECTED:
                return { text: this.multiLanguageService.getString("asset_summary.mapping.not_approved"), class: "mapping__not-approved" };
            case MAP_TO_STANDARDS_CONSTANTS.STATUS.PENDING:
                return { text: this.multiLanguageService.getString("asset_summary.mapping.awaiting_approval"), class: "" };
            default:
                return null;
        }
    }
}
