import { angularAMD } from "@pebblepad/amd";

angularAMD.filter("capitalize", [
    function () {
        return function (input) {
            if (input) {
                input = input.charAt(0).toUpperCase() + input.slice(1);
            }

            return input;
        };
    }
]);
