import { Observable, finalise, exhaustMap, last, takeWhile, timer, share, defer } from "@pjs/observables";
import { IAbortableHttpRequest } from "../abortable-http-request/interfaces/IAbortableHttpRequest";
import { IWebRequestConfig } from "./interfaces/IWebRequestConfig";
import { IResponseError } from "./interfaces/IResponseError";
import { WebRequestWithBody } from "./types/WebRequestWithBody";
import { IWebPollingRequestConfig } from "./interfaces/IWebPollingRequestConfig";
import { WebRequest } from "./types/WebRequest";

export class WebRequestFactory {
    public static createGet<TResponse, TParams = void>(config: IWebRequestConfig<TResponse, TParams>): WebRequest<TResponse, TParams> {
        return WebRequestFactory._createRequestWithoutBody<TResponse, TParams>(config, "get");
    }

    public static createDelete<TResponse, TParams = void>(config: IWebRequestConfig<TResponse, TParams>): WebRequest<TResponse, TParams> {
        return WebRequestFactory._createRequestWithoutBody<TResponse, TParams>(config, "delete");
    }

    public static createPut<TResponse, TBody, TParams = void>(config: IWebRequestConfig<TResponse, TParams>): WebRequestWithBody<TResponse, TBody, TParams> {
        return WebRequestFactory._createRequestWithBody(config, "put");
    }

    public static createPatch<TResponse, TBody, TParams = void>(config: IWebRequestConfig<TResponse, TParams>): WebRequestWithBody<TResponse, TBody, TParams> {
        return WebRequestFactory._createRequestWithBody(config, "patch");
    }

    public static createPost<TResponse, TBody, TParams = void>(config: IWebRequestConfig<TResponse, TParams>): WebRequestWithBody<TResponse, TBody, TParams> {
        return WebRequestFactory._createRequestWithBody(config, "post");
    }

    public static createPolledGet<TResponse, TParams = void>(config: IWebPollingRequestConfig<TResponse, TParams>): WebRequest<TResponse, TParams> {
        return (urlParams: TParams): Observable<TResponse> => {
            let abortablePromise: IAbortableHttpRequest;
            const url = config.url(urlParams);

            return timer(0, config.interval).pipe(
                exhaustMap(() => {
                    abortablePromise = config.http.get(url);
                    return abortablePromise.then(config.responseMapper, (error) => WebRequestFactory._handleResponseError(error, config.errorMapper));
                }),
                takeWhile(config.takeWhile, true),
                last(),
                finalise(() => abortablePromise.abort()),
                share()
            );
        };
    }

    private static _createRequestWithBody<TResponse, TBody, TParams = void>(
        config: IWebRequestConfig<TResponse, TParams>,
        httpMethod: "post" | "patch" | "put"
    ): WebRequestWithBody<TResponse, TBody, TParams> {
        return (bodyData: TBody, urlParams: TParams): Observable<TResponse> => {
            let abortableRequest: IAbortableHttpRequest;

            return defer<Promise<TResponse>>(() => {
                abortableRequest = config.http[httpMethod](config.url(urlParams), bodyData);
                return abortableRequest.then(config.responseMapper, (error) => WebRequestFactory._handleResponseError(error, config.errorMapper));
            }).pipe(
                finalise(() => abortableRequest.abort()),
                share()
            );
        };
    }

    private static _createRequestWithoutBody<TResponse, TParams = void>(config: IWebRequestConfig<TResponse, TParams>, method: "get" | "delete"): WebRequest<TResponse, TParams> {
        return (urlParams: TParams): Observable<TResponse> => {
            let abortableRequest: IAbortableHttpRequest;

            return defer<Promise<TResponse>>(() => {
                const url = config.url(urlParams);
                abortableRequest = config.http[method](url);
                return abortableRequest.then(config.responseMapper, (error) => WebRequestFactory._handleResponseError(error, config.errorMapper));
            }).pipe(
                finalise(() => abortableRequest.abort()),
                share()
            );
        };
    }

    private static async _handleResponseError(error: Response | Error, mapper: (error: Response) => Promise<IResponseError>): Promise<never> {
        if (error instanceof Response) {
            throw await mapper(error);
        }
        throw error;
    }
}
