import { RefObject } from "react";
import { animateElement } from "../../../utils/animate-element/AnimateElement";
import { dialogAnimationKeyFrames } from "../consts/DialogAnimationKeyFrames.const";
import { animateNewDialogStepIn } from "../utils/AnimateNewDialogStepIn";

export const animateStepChange = async (stepContainerRef: RefObject<HTMLElement>, swapStep: () => void): Promise<void> => {
    if (stepContainerRef.current === null) {
        return;
    }

    stepContainerRef.current.style.height = "";
    const oldHeight = stepContainerRef.current.getBoundingClientRect().height;
    stepContainerRef.current.style.height = `${oldHeight}px`;

    await animateElement(stepContainerRef.current, {
        duration: 115,
        easing: "ease-in",
        keyframes: dialogAnimationKeyFrames.contentOut
    });

    swapStep();

    await animateNewDialogStepIn(stepContainerRef.current, oldHeight);
};
