import { angularAMD } from "@pebblepad/amd";
import "../userAgent/userAgent.service";

angularAMD.factory("uberDropdownHelper", [
    "$window",
    "$timeout",
    "UserAgentService",
    function ($window, $timeout, UserAgentService) {
        var dropdownHelper = {};

        dropdownHelper.toggleScrollIndicators = function () {
            dropdownHelper.detectScrollEdges(this.scrollableEl, this.element, this.bodyEl);
        };

        /**
         * Stop scrolling when user hits top or bottom of scrollable dropdown
         * @param e
         */
        dropdownHelper.stopScrollingInBodyEl = function (e) {
            var detectScrollEdgedData = dropdownHelper.detectScrollEdges(this.scrollableEl, this.element, this.bodyEl);

            // cross-browser wheel delta
            var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));

            // if there is no scroll distance on top and user scrolls up, then do nothing
            // or
            // if user is on the bottom of scrolling distance and tries to scroll down, then do nothing
            if ((detectScrollEdgedData.scrollTop < 1 && delta > 0) || (detectScrollEdgedData.scrollTop >= detectScrollEdgedData.verticalScrollPos && delta < 0)) {
                e.preventDefault();
            }
        };

        dropdownHelper.detectScrollEdges = function (scrollableEl, element, bodyEl) {
            // find scroll position inside of target
            var thisRect = scrollableEl.getBoundingClientRect(),
                verticalScrollPos = scrollableEl.scrollHeight - thisRect.height,
                scrollTop = scrollableEl.scrollTop;

            dropdownHelper.applyScrollingIndicators({
                element: element,
                scrollTop: scrollTop,
                bodyEl: bodyEl,
                verticalScrollPos: verticalScrollPos
            });

            return {
                scrollTop: scrollTop,
                verticalScrollPos: verticalScrollPos
            };
        };

        dropdownHelper.applyScrollingIndicators = function (opts) {
            opts = opts || {};
            var element = opts.element,
                bodyEl = opts.bodyEl,
                scrollTop = opts.scrollTop,
                verticalScrollPos = parseInt(opts.verticalScrollPos, 10) || 0,
                resetIndicators = opts.resetIndicators;

            if (resetIndicators) {
                element.removeClass("dropdown__scrolling-indicator--top");
                element.removeClass("dropdown__scrolling-indicator--bottom");
                bodyEl.removeClass("dropdown__scrolling-indicator--top");
                bodyEl.removeClass("dropdown__scrolling-indicator--bottom");
                return;
            }

            if (scrollTop < 1) {
                element.removeClass("dropdown__scrolling-indicator--top");
                bodyEl.removeClass("dropdown__scrolling-indicator--top");
                element.addClass("dropdown__scrolling-indicator--bottom");
                bodyEl.addClass("dropdown__scrolling-indicator--bottom");
            } else if (scrollTop >= verticalScrollPos) {
                element.removeClass("dropdown__scrolling-indicator--bottom");
                bodyEl.removeClass("dropdown__scrolling-indicator--bottom");
                element.addClass("dropdown__scrolling-indicator--top");
                bodyEl.addClass("dropdown__scrolling-indicator--top");
            } else {
                element.addClass("dropdown__scrolling-indicator--top");
                bodyEl.addClass("dropdown__scrolling-indicator--top");
                element.addClass("dropdown__scrolling-indicator--bottom");
                bodyEl.addClass("dropdown__scrolling-indicator--bottom");
            }
        };

        dropdownHelper.adjustOptions = {
            getBoxModelExtras: function (target) {
                var style = target.currentStyle || window.getComputedStyle(target);
                return (parseInt(style.marginTop, 10) || 0) + (parseInt(style.paddingTop, 10) || 0) + (parseInt(style.marginBottom, 10) || 0) + (parseInt(style.paddingBottom, 10) || 0);
            },
            toTop: function (target, height, element, bodyEl) {
                element.addClass("dropdown--reverse");
                bodyEl.addClass("dropdown--reverse");
                target.css({ height: height ? height + "px" : "" });
            },
            toBottom: function (target, height) {
                target.css({ height: height + "px" });
            },
            resetVertical: function (element, bodyEl, bodyInnerEl, headerEl, relativeToEl) {
                var top;

                if (relativeToEl) {
                    var headerElTop = headerEl[0].getBoundingClientRect().bottom;
                    top = headerElTop + "px";
                } else {
                    top = "";
                }

                element.removeClass("dropdown--reverse");
                bodyEl.css({ height: "" });
                bodyEl.css({ top: top });
                bodyEl.removeClass("dropdown--reverse");
                bodyInnerEl.unbind("scroll");
                bodyInnerEl.unbind("mousewheel DOMMouseScroll");
            },
            resetHorizontal: function (bodyEl) {
                bodyEl.css({ right: "" });
                bodyEl.css({ left: "" });
            }
        };

        dropdownHelper.setTopValue = function (direction, bodyEl, headerElRect, relativeToElRect, relativeToEl) {
            if (!relativeToEl) {
                return;
            }

            var top;

            if (direction === "bottom") {
                top = headerElRect.bottom + "px";
            } else if (direction === "top") {
                top = headerElRect.top + "px";
            }

            bodyEl.css({ top: top });
            bodyEl.css({ bottom: "auto" }); // overwrite styles, that can be applied by developer
        };

        dropdownHelper.adjustVerticalPosition = function (opts) {
            var element = opts.element,
                bodyEl = opts.bodyEl,
                headerEl = opts.headerEl,
                bodyInnerEl = opts.bodyInnerEl,
                bodyElRect = opts.bodyElRect,
                relativeToEl = opts.relativeToEl,
                relativeToElRect = opts.relativeToElRect,
                limitationBoxEl = opts.limitationBoxEl,
                limitationBoxElRect = limitationBoxEl ? limitationBoxEl.getBoundingClientRect() : false,
                headerElRect = headerEl[0].getBoundingClientRect(),
                windowHeight = $window.innerHeight,
                extraBottomOrTopRoom = opts.minimumVerticalSpace !== void 0 ? opts.minimumVerticalSpace : Math.floor(windowHeight * 0.07),
                distanceToBottom,
                distanceToTop,
                top;

            // get distance from bottom
            if (limitationBoxElRect) {
                distanceToBottom = limitationBoxElRect.height + limitationBoxElRect.top - bodyElRect.bottom - extraBottomOrTopRoom;
            } else {
                distanceToBottom = windowHeight - bodyElRect.bottom - extraBottomOrTopRoom;
            }

            // Timeout for fixing Firefox
            var dirtyDelay = UserAgentService.firefox ? 100 : 0;
            $timeout(function () {
                // get distance from top
                element.addClass("dropdown--reverse");
                bodyEl.addClass("dropdown--reverse");
                dropdownHelper.setTopValue("top", bodyEl, headerElRect, relativeToElRect, relativeToEl);
                bodyElRect = bodyEl[0].getBoundingClientRect();
                if (limitationBoxElRect) {
                    distanceToTop = bodyElRect.top - limitationBoxElRect.top - dropdownHelper.adjustOptions.getBoxModelExtras(relativeToEl) - extraBottomOrTopRoom;
                } else {
                    distanceToTop = bodyElRect.top - extraBottomOrTopRoom;
                }

                element.removeClass("dropdown--reverse");
                bodyEl.removeClass("dropdown--reverse");
                dropdownHelper.applyScrollingIndicators({
                    element: element,
                    bodyEl: bodyEl,
                    resetIndicators: true
                });

                // dropdown doesn't fit screen; both negative
                if (distanceToTop < 0 && distanceToBottom < 0) {
                    // drop to bottom
                    if (Math.abs(distanceToTop) > Math.abs(distanceToBottom)) {
                        dropdownHelper.adjustOptions.toBottom(bodyEl, bodyElRect.height + distanceToBottom);
                        dropdownHelper.setTopValue("bottom", bodyEl, headerElRect, relativeToElRect, relativeToEl);
                    }
                    // drop to top
                    else {
                        dropdownHelper.adjustOptions.toTop(bodyEl, bodyElRect.height + distanceToTop, element, bodyEl);
                        dropdownHelper.setTopValue("top", bodyEl, headerElRect, relativeToElRect, relativeToEl);
                    }

                    // in this case bodyInnerEl has scrollbar, so we need to apply all scrolling related logic and events
                    dropdownHelper.detectScrollEdges(bodyInnerEl[0], element, bodyEl);
                    if (UserAgentService.touch) {
                        bodyInnerEl.bind(
                            "scroll",
                            dropdownHelper.toggleScrollIndicators.bind({
                                scrollableEl: bodyInnerEl[0],
                                element: element,
                                bodyEl: bodyEl
                            })
                        );
                    } else {
                        bodyInnerEl.bind(
                            "scroll",
                            dropdownHelper.toggleScrollIndicators.bind({
                                scrollableEl: bodyInnerEl[0],
                                element: element,
                                bodyEl: bodyEl
                            })
                        );
                        // stop scrolling when reaches top or bottom side of dropdown scrollable body
                        bodyInnerEl.bind(
                            "mousewheel DOMMouseScroll",
                            dropdownHelper.stopScrollingInBodyEl.bind({
                                scrollableEl: bodyInnerEl[0],
                                element: element,
                                bodyEl: bodyEl
                            })
                        );
                    }
                }
                // not enough on the bottom only, so render dropdown in to top direction
                else if (distanceToBottom < 0) {
                    dropdownHelper.adjustOptions.toTop(bodyEl, null, element, bodyEl);
                    dropdownHelper.setTopValue("top", bodyEl, headerElRect, relativeToElRect, relativeToEl);
                } else {
                    dropdownHelper.setTopValue("bottom", bodyEl, headerElRect, relativeToElRect, relativeToEl);
                }
            }, dirtyDelay);
        };

        dropdownHelper.adjustHorizontalPosition = function (opts) {
            var headerEl = opts.headerEl,
                bodyEl = opts.bodyEl,
                bodyElRect = opts.bodyElRect,
                windowWidth = $window.innerWidth, // initialise core dimensions
                relativeToEl = opts.relativeToEl,
                limitationBoxEl = opts.limitationBoxEl,
                limitationBoxElRect = limitationBoxEl ? limitationBoxEl.getBoundingClientRect() : false,
                hasPosFixedInTransformBug = opts.hasPosFixedInTransformBug,
                extraRoom = 6,
                headerElRect = headerEl[0].getBoundingClientRect(),
                headerElDistanceToRight,
                headerElDistanceToLeft,
                bodyElDistanceToLeft,
                bodyElWidthOffset = (bodyElRect.width - headerElRect.width) / 2, // get offset distance from right
                right,
                left;

            if (limitationBoxElRect) {
                headerElDistanceToLeft = headerElRect.left - extraRoom - limitationBoxElRect.left;
                headerElDistanceToRight = limitationBoxElRect.right - headerElRect.right - extraRoom;
            } else {
                headerElDistanceToLeft = headerElRect.left - extraRoom;
                headerElDistanceToRight = windowWidth - headerElRect.right - extraRoom;
            }

            bodyElDistanceToLeft = bodyElRect.left - extraRoom;

            // if there is room for moving bodyEl to right, then do it
            if (bodyElWidthOffset < headerElDistanceToRight) {
                if (relativeToEl) {
                    if (limitationBoxElRect) {
                        right = (hasPosFixedInTransformBug ? 0 : windowWidth - limitationBoxElRect.right) + headerElDistanceToRight + extraRoom - bodyElWidthOffset + "px";
                    } else {
                        right = headerElDistanceToRight + extraRoom - bodyElWidthOffset + "px";
                    }
                } else {
                    right = -bodyElWidthOffset + "px";
                }
                bodyEl.css({ right: right });
                bodyEl.css({ left: "auto" }); // overwrite styles, that can be applied by developer
            } else {
                if (relativeToEl) {
                    if (headerElDistanceToRight >= extraRoom) {
                        if (limitationBoxElRect) {
                            right = (hasPosFixedInTransformBug ? 0 : windowWidth - limitationBoxElRect.right) + extraRoom + "px";
                        } else {
                            right = extraRoom + "px";
                        }
                    } else {
                        if (limitationBoxElRect) {
                            right = (hasPosFixedInTransformBug ? 0 : windowWidth - limitationBoxElRect.right) + headerElDistanceToRight + extraRoom + "px";
                        } else {
                            right = headerElDistanceToRight + extraRoom + "px";
                        }
                    }
                } else {
                    right = -headerElDistanceToRight + "px";
                }
                bodyEl.css({ right: right });
                bodyEl.css({ left: "auto" }); // overwrite styles, that can be applied by developer
            }

            // if bodyEl doesn't fit on the left, then stick it to left
            if (bodyElWidthOffset > headerElDistanceToLeft) {
                bodyEl.css({ right: "" });

                // if no room on left then align to left
                if (headerElDistanceToLeft <= bodyElDistanceToLeft) {
                    if (limitationBoxElRect) {
                        left = (hasPosFixedInTransformBug ? 0 : limitationBoxElRect.left) + extraRoom + "px";
                    } else {
                        left = extraRoom + "px";
                    }
                    bodyEl.css({ left: left });
                    bodyEl.css({ right: "auto" }); // overwrite styles, that can be applied by developer
                }
                // if there is room on left, then move bodyEl into that spare space
                else {
                    if (relativeToEl) {
                        if (limitationBoxElRect) {
                            left = (hasPosFixedInTransformBug ? 0 : limitationBoxElRect.left) + extraRoom + "px";
                        } else {
                            left = extraRoom + "px";
                        }
                    } else {
                        left = -headerElDistanceToLeft + "px";
                    }
                    bodyEl.css({ left: left });
                    bodyEl.css({ right: "auto" }); // overwrite styles, that can be applied by developer
                }
            }
        };

        dropdownHelper.setDropdownBodyWidth = function (headerEl, bodyEl) {
            var headerElWidth = headerEl[0].getBoundingClientRect().width;
            if (headerElWidth) {
                bodyEl.css({ width: headerElWidth + "px" });
            }
        };

        dropdownHelper.adjustPosition = function (element, headerEl, bodyEl, bodyInnerEl, relativeToEl, limitationBoxEl, hasPosFixedInTransformBug, minimumVerticalSpace) {
            // reset any previously added adjustments
            dropdownHelper.adjustOptions.resetHorizontal(bodyEl);
            dropdownHelper.adjustOptions.resetVertical(element, bodyEl, bodyInnerEl, headerEl, relativeToEl);

            // cache initial bodyEl dimensions
            var bodyElRect = bodyEl[0].getBoundingClientRect(),
                relativeToElRect;

            if (relativeToEl) {
                relativeToElRect = relativeToEl.getBoundingClientRect();
            }

            // apply position adjustments on both axises
            dropdownHelper.adjustVerticalPosition({
                element: element,
                bodyEl: bodyEl,
                headerEl: headerEl,
                bodyInnerEl: bodyInnerEl,
                bodyElRect: bodyElRect,
                relativeToEl: relativeToEl,
                relativeToElRect: relativeToElRect,
                limitationBoxEl: limitationBoxEl,
                minimumVerticalSpace: minimumVerticalSpace
            });

            dropdownHelper.adjustHorizontalPosition({
                headerEl: headerEl,
                bodyEl: bodyEl,
                bodyElRect: bodyElRect,
                relativeToEl: relativeToEl,
                relativeToElRect: relativeToElRect,
                limitationBoxEl: limitationBoxEl,
                hasPosFixedInTransformBug: hasPosFixedInTransformBug
            });
        };

        dropdownHelper.getStyleTransformAttr = function (elem) {
            var style = window.getComputedStyle(elem),
                transform = style.transform || style.webkitTransform || style.mozTransform;

            return transform !== "none";
        };

        dropdownHelper.checkPosFixedInTransformBug = function (dropdownBodyEl, hasPosFixedInTransformBug) {
            if (hasPosFixedInTransformBug !== void 0) {
                return hasPosFixedInTransformBug;
            }
            if (UserAgentService.ie) {
                return false;
            }

            while (!dropdownHelper.getStyleTransformAttr(dropdownBodyEl)) {
                dropdownBodyEl = dropdownBodyEl.parentNode;

                if (dropdownBodyEl.nodeName === "HTML") {
                    return (dropdownBodyEl = false);
                }
            }

            return true;
        };

        dropdownHelper.getRequestedItem = function (selector, element, requestedItemEl, dropdownBodyEl) {
            if (requestedItemEl || !selector) {
                return requestedItemEl;
            }

            requestedItemEl = dropdownHelper.findClosestParentBySelector(selector, element);
            if (dropdownBodyEl && dropdownBodyEl.length > 0 && dropdownBodyEl[0]) {
                dropdownHelper.compileRelativeDropdownBody(requestedItemEl, dropdownBodyEl[0]);
                dropdownBodyEl.addClass("relative-to");
            }
            return requestedItemEl;
        };

        dropdownHelper.compileRelativeDropdownBody = function (relativeToEl, dropdownBodyEl) {
            // tslint:disable-next-line:no-unsafe-dom-insert-calls
            relativeToEl.parentNode.insertBefore(dropdownBodyEl, relativeToEl.nextSibling);
        };

        dropdownHelper.findClosestParentBySelector = function (selector, element) {
            if (!selector) {
                return false;
            }

            var target = element;
            var el;

            try {
                while (target !== null) {
                    if (target.parentNode) {
                        el = target.parentNode.querySelectorAll(selector);
                    } else {
                        return false;
                    }

                    for (var x = 0; x < el.length; x++) {
                        if (el[x] === target) {
                            return target;
                        }
                    }

                    target = target.parentNode;
                }

                return false;
            } catch (e) {
                return false;
            }
        };

        return dropdownHelper;
    }
]);
