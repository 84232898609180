export class AbortablePromiseSet {
    constructor() {
        this._promises = [];
    }

    add(abortablePromise) {
        this._promises.push(abortablePromise);

        abortablePromise.finally(() => {
            const index = this._promises.indexOf(abortablePromise);
            if (index !== -1) {
                this._promises.splice(index, 1);
            }
        });
    }

    abortAll() {
        for (let abortablePromise of this._promises) {
            if (abortablePromise.abort !== undefined) {
                abortablePromise.abort();
            }
        }

        this._promises.length = 0;
    }
}
