import { angularAMD } from "@pebblepad/amd";

angularAMD.service("EscapeHelper", EscapeHelper);

function EscapeHelper() {
    // Public API
    // =============================================================================================================
    this.convertHTMLChars = convertHTMLChars;
    this.convertRegexChars = convertRegexChars;

    // Private Variables
    // =============================================================================================================
    var regexStringHelper = {
        unescapedRegex: /[\*\+\?\.\-\/\{\}\(\)\[\]\\\^\$\|]/g,
        escapedMatch: "\\$&"
    };

    var htmlStringHelper = {
        unescapedRegex: /[<>"'&]/g,
        escapedCharsDictionary: {
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
            "&": "&amp;"
        }
    };

    // Private Methods
    // =============================================================================================================
    /**
     * Converts HTML characters in a string to their safe encoded entities.
     * Allows HTML strings inserted into the DOM to be displayed as the plain text, rather than HTML.
     * In most cases using Angulars {{}} or ng-bind will be enough.
     * Some scenarios may require a string containing HTML chars to not be rendered as HTML and to be escaped before it is used in something like compile-html
     * @param {string} htmlString
     * @returns {string}
     */
    function convertHTMLChars(htmlString) {
        return htmlStringHelper.unescapedRegex.test(htmlString) ? htmlString.replace(htmlStringHelper.unescapedRegex, replaceUnescapedHTMLChar) : htmlString;
    }

    /**
     * @param char
     * @returns {*|string}
     */
    function replaceUnescapedHTMLChar(char) {
        return htmlStringHelper.escapedCharsDictionary[char] || "";
    }

    /**
     * Converts regex characters in a string to their escaped versions.
     * Can allow Regex objects to be safely created from strings containing characters like | and $, without treating them as special characters
     * Example:
     *  var regexStr = 'Brown | October 21, 2015';
     *  var searchStr = 'Emmett Brown | October 21, 2015'
     *  var searchStr2 = ' October 21, 2015';
     *
     *  var safeRegexStr = convertRegexChars(regexStr);
     *
     *  new RegExp(regexStr).test(searchStr) === true
     *  new RegExp(regexStr).test(searchStr2) === true //Should be false
     *
     *  new RegExp(safeRegexStr).test(searchStr) === true
     *  new RegExp(safeRegexStr).test(searchStr2) === false
     *
     * @param {string} regexString
     * @returns {string}
     */
    function convertRegexChars(regexString) {
        return regexString.replace(regexStringHelper.unescapedRegex, regexStringHelper.escapedMatch);
    }
}
