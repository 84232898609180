import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import "./assetLockingAPI.service";
import "../../modal/services/modal";
import "../../localChanges/services/localChangesResolver";
import "../../utilities/urlService";
import "../../recoveryLogger/recoveryLogger";

angularAMD.service("AssetLockingHelper", AssetLockingHelper);
AssetLockingHelper.$inject = [
    "$rootScope",
    "$q",
    "$window",
    "$interval",
    "$location",
    "AssetLockingAPI",
    "modal",
    "baseUrlsFactory",
    "multiLanguageService",
    "helperService",
    "localChangesResolver",
    "urlService",
    "recoveryLogger"
];

function AssetLockingHelper(
    $rootScope,
    $q,
    $window,
    $interval,
    $location,
    AssetLockingAPI,
    modal,
    baseUrlsFactory,
    multiLanguageService,
    helperService,
    localChangesResolver,
    UrlService,
    recoveryLogger
) {
    //Public API
    //==============================================================================================================
    this.lock = lock;
    this.unlock = unlock;
    this.keepLockAlive = keepLockAlive;
    this.lockedByAnother = lockedByAnother;
    this.setupLock = setupLock;
    this.createActiveLock = createActiveLock;
    this.getActiveLockById = getActiveLockById;
    this.hasPreexisitingLockError = hasPreexisitingLockError;
    this.removePreexisitingLockError = removePreexisitingLockError;
    this.removeActiveLock = removeActiveLock;
    this.cancelAllActiveLocks = cancelAllActiveLocks;

    //Private Variables
    //==============================================================================================================
    var activeLocks = [];
    var preexistingLockErrors = [];
    var preexistingLockTypes = [ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE, ASSET_CONSTANTS.TYPES.WEBFOLIO];
    var intervalTime = 180000; //Poll every 3 minutes

    //Private Classes
    //==============================================================================================================
    /**
     * @param {string} id
     * @param {string} sessionKey
     * @param {Boolean} originallyLockedAsset
     * @param {interval} interval
     * @param {Function=} onCancel
     * @constructor
     */
    function ActiveLock(id, sessionKey, originallyLockedAsset, interval, onCancel) {
        this.id = id;
        this.sessionKey = sessionKey;
        this.interval = interval;
        this.onCancel = onCancel;
        this.listeners = {
            start: null,
            success: null
        };

        $window.sessionStorage.setItem(this.sessionKey, originallyLockedAsset);
    }

    ActiveLock.prototype.removeLockOnRedirect = function () {
        //On Route start register $routeChangeSuccess listener - Prevents issue when removeLockOnRedirect is called while a Route is currently resolving.
        this.listeners.start = $rootScope.$on(
            "$routeChangeStart",
            function () {
                //Remove listener
                this.listeners.start();
                this.listeners.start = null;

                //On successful redirect from Asset (after PreventRedirect checks have been approved)
                this.listeners.success = $rootScope.$on(
                    "$routeChangeSuccess",
                    function () {
                        //Remove listener
                        this.listeners.success();

                        this.cancelAndUnlock();
                    }.bind(this)
                );
            }.bind(this)
        );

        return this;
    };

    ActiveLock.prototype.cancel = function () {
        if (this.listeners.start !== null) {
            this.listeners.start();
            this.listeners.start = null;
        }

        if (this.listeners.success !== null) {
            this.listeners.success();
            this.listeners.success = null;
        }

        //Cancel interval
        $interval.cancel(this.interval);
        this.interval = null;

        if (this.onCancel !== void 0) {
            this.onCancel(this.id);
        }

        return this;
    };

    ActiveLock.prototype.cancelAndUnlock = function () {
        this.cancel();
        $window.sessionStorage.removeItem(this.sessionKey);
        return unlock(this.id);
    };

    //Private Methods
    //==============================================================================================================
    /**
     * @param {string} assetId
     * @returns {Promise} - Rejected if no lock data or HTTP fails
     */
    function lock(assetId) {
        return AssetLockingAPI.lock(assetId).then(function (data) {
            return data ? data : $q.reject();
        });
    }

    /**
     * @param {string} assetId
     * @returns {Promise} - Rejected if HTTP request fails
     */
    function unlock(assetId) {
        var activeLock = getActiveLockById(assetId);
        if (activeLock) {
            activeLock.cancel();
        }
        return AssetLockingAPI.unlock(assetId);
    }

    /**
     * If locked, get lock info and display Modal, else resolve with true;
     * @param {string} assetId
     * @returns {Promise}
     */
    function lockedByAnother(assetId) {
        return AssetLockingAPI.lockedByAnother(assetId);
    }

    /**
     * @param {string} assetId
     * @param {string} mainType
     * @returns {Promise} - Resolved on lock success, Rejected on lock failure.
     */
    function setupLock(assetId, mainType) {
        recoveryLogger.step("lock", `Locking asset - ${mainType}`);
        return lock(assetId).then(function (lockedInfo) {
            //Asset Locked!
            var originallyLockedAsset = false;
            var originalLocker = $window.sessionStorage.getItem(createSessionKey(assetId)); //null|"false"|"true"

            if (!lockedInfo) {
                return $q.reject();
            } else if (lockedInfo.LockGranted === false) {
                return $q.reject(lockedInfo);
            } else if (lockedInfo.IsPreexistingLock && originalLocker !== "true") {
                //If we get a IsPreexistingLock issue and we are the original locking tab, ignore.
                var registeredError = registerPreexistingLockError(assetId, mainType);
                //If we have successfully registered the error (not already registered) and the user has not been into edit mode (originalLocker would be "false"), bail!
                if (registeredError && originalLocker === null) {
                    return $q.reject(lockedInfo);
                }
            } else {
                originallyLockedAsset = true;
                removePreexisitingLockError(assetId);
            }

            return createActiveLock(assetId, mainType, originallyLockedAsset);
        });
    }

    /**
     * @param {string} assetId
     * @param {string} mainType
     * @returns {Promise} - Resolved on Locked success / continuation. Rejected on lock failure and recovery.
     */
    function keepLockAlive(assetId, mainType) {
        //Get current state of lock.
        return AssetLockingAPI.keepLockAlive(assetId).then(function (success) {
            //If lock kept, continue!
            if (success === true) {
                return success;
            }

            //Create handler for lock failure.
            var onFailure = function () {
                return onLockLost(assetId, mainType).then($q.reject);
            };

            //Attempt to re-lock. If the user disconnected and reconnected they may have lost the lock.
            return lock(assetId).then(function (lockedInfo) {
                if (!lockedInfo.LockGranted) {
                    //Lock failed. Handle failure then reject the chain
                    return onFailure();
                }
            }, onFailure);
        });
    }

    /**
     * @param {string} assetId
     * @param {string} mainType
     * @returns {Promise}
     */
    function onLockLost(assetId, mainType) {
        //Check for a current Interval, cancel if exists - interval no longer needed.
        var activeLock = getActiveLockById(assetId);
        if (activeLock !== null) {
            activeLock.cancel();
        }

        recoveryLogger.step("lock", `Asset lock lost - ${mainType}`);
        return localChangesResolver.forceRecoveryIfUnsaved(assetId).then(launchLostActiveLockModal.bind(null, true, mainType), launchLostActiveLockModal.bind(null, false, mainType));
    }

    /**
     * @param {String} assetId
     * @param {String} type
     * @return {Boolean}
     */
    function registerPreexistingLockError(assetId, type) {
        if (!preexistingLockTypes.contains(type)) {
            return false;
        }

        var hasError = preexistingLockErrors.contains(assetId);
        if (hasError) {
            return false;
        }

        preexistingLockErrors.push(assetId);
        return true;
    }

    /**
     * @param assetId
     * * @return {Boolean}
     */
    function hasPreexisitingLockError(assetId) {
        return preexistingLockErrors.contains(assetId);
    }

    /**
     * @param {String} assetId
     */
    function removePreexisitingLockError(assetId) {
        preexistingLockErrors.remove(assetId);
    }

    function createSessionKey(assetId) {
        return "original-locker-" + assetId;
    }

    /**
     * Once a lock has been established call this method to allow semi-automated updates and removal of lock.
     * @param {string} assetId
     * @param {string} mainType
     * @param {Boolean} originallyLockedAsset
     * @returns {ActiveLock}
     */
    function createActiveLock(assetId, mainType, originallyLockedAsset) {
        var interval = $interval(keepLockAlive.bind(null, assetId, mainType), intervalTime);
        var sessionKey = createSessionKey(assetId);
        var activeLock = new ActiveLock(assetId, sessionKey, originallyLockedAsset, interval, removeActiveLock);

        activeLock.removeLockOnRedirect();
        activeLocks.push(activeLock);

        return activeLock;
    }

    /**
     * @param {string} assetId
     * @returns {ActiveLock|null}
     */
    function getActiveLockById(assetId) {
        var index = activeLocks.findInNestedObject("id", assetId);
        return index !== -1 ? activeLocks[index] : null;
    }

    /**
     * @returns {Promise}
     */
    function cancelAllActiveLocks() {
        var promises = [];

        //Duplicate array to ensure no issues with indexes when items get removed.
        activeLocks.slice().forEach(function (lock) {
            promises.push(lock.cancelAndUnlock());
        });

        return $q.all(promises);
    }

    /**
     * @param {string} assetId
     * @returns {Boolean} - Success or failure of removal.
     */
    function removeActiveLock(assetId) {
        var index = activeLocks.findInNestedObject("id", assetId);

        if (index !== -1) {
            activeLocks.splice(index, 1);
            removePreexisitingLockError(assetId);
            return true;
        }
        return false;
    }

    /**
     * @param {Boolean} hasRecoveredData
     * @param {string} mainType
     */
    function launchLostActiveLockModal(hasRecoveredData, mainType) {
        var modalScope = $rootScope.$new(true);

        modalScope.multiLanguageService = multiLanguageService;
        modalScope.hasRecoveredData = hasRecoveredData;
        modalScope.i18nLocked = {
            lost_lock: helperService.isResourceBuilder(mainType)
                ? multiLanguageService.getString("locking.lost_lock_on_resource.lost_lock")
                : multiLanguageService.getString("locking.lost_lock_on_asset.lost_lock"),
            try_again_later: helperService.isResourceBuilder(mainType)
                ? multiLanguageService.getString("locking.lost_lock_on_resource.try_again_later")
                : multiLanguageService.getString("locking.lost_lock_on_asset.try_again_later")
        };
        modalScope.onClose = function () {
            //If there was unsaved data, it has been recovered. Forcefully allow redirect.
            $location.url(UrlService.createStoreRedirectUrl($location.url()));
            modalScope.$destroy();
        };

        return modal.newModal({
            scope: modalScope,
            focusStyle: false,
            disableClose: true,
            templateUrl: baseUrlsFactory.shared_component_base_url + "assetLocking/templates/asset-lock-lost-modal.lazy.html"
        });
    }
}
