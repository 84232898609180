import { FunctionComponent } from "react";
import { isSafeUrlProtocol } from "@pjs/security";
import { IHyperlinkProps } from "./interfaces/IHyperlinkProps";

export const Hyperlink: FunctionComponent<IHyperlinkProps> = ({ href: requestedHref, children, ...rest }) => {
    return (
        // eslint-disable-next-line react/forbid-elements,react/forbid-dom-props,react/jsx-props-no-spreading
        <a {...rest} href={isSafeUrlProtocol(requestedHref) ? requestedHref : undefined}>
            {children}
        </a>
    );
};
