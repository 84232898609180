import { angularAMD } from "@pebblepad/amd";

angularAMD.service("debounce", DebounceService);
DebounceService.$inject = ["$timeout"];

function DebounceService($timeout) {
    // Public API
    // =============================================================================================================
    this.event = getDebounceMethod("Event");
    this.fn = getDebounceMethod("Fn");

    // Private methods
    // =============================================================================================================
    var DebounceClass = {
        Event: EventDebounceClass,
        Fn: FnDebounceClass
    };

    function getDebounceMethod(type) {
        return function (fn, context, delay) {
            if (!fn || typeof fn !== "function") {
                throw new Error("Function undefined or not valid. Please provide a proper function.");
            }

            return new DebounceClass[type](fn, context, delay);
        };
    }

    function EventDebounceClass(fn, context, delay) {
        this.timeout = null;
        var self = this;
        return {
            cancel: function () {
                $timeout.cancel(self.timeout);
            },
            run: function (e) {
                $timeout.cancel(self.timeout);
                self.timeout = $timeout(fn.bind(context || this, e), delay || 250);
            }
        };
    }

    function FnDebounceClass(fn, context, delay) {
        this.timeout = null;
        var self = this;

        return {
            cancel: function () {
                $timeout.cancel(self.timeout);
            },
            run: function () {
                var args = arguments;
                this.cancel();
                self.timeout = $timeout(function () {
                    fn.apply(context || this, args);
                }, delay || 250);
            }
        };
    }
}
