import { IAbortableHttpRequest } from "./interfaces/IAbortableHttpRequest";

export class AbortableHttpRequest implements IAbortableHttpRequest {
    public readonly [Symbol.toStringTag]: string = "Promise";
    public readonly then: Promise<Response>["then"];
    public readonly catch: Promise<never>["catch"];
    public readonly finally: Promise<Response>["finally"];
    public readonly abort: () => void;

    constructor(promise: Promise<Response>, abort: () => void) {
        this.then = promise.then.bind(promise);
        this.catch = promise.catch.bind(promise);
        this.finally = promise.finally.bind(promise);
        this.abort = abort;
    }

    public toArrayBuffer(): Promise<ArrayBuffer> {
        return this.then((response) => response.arrayBuffer());
    }

    public toBlob(): Promise<Blob> {
        return this.then((response) => response.blob());
    }

    public toJson<T>(): Promise<T> {
        return this.then((response) => response.json() as Promise<T>);
    }

    public toText(): Promise<string> {
        return this.then((response) => response.text());
    }
}
