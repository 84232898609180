import { angularAMD } from "@pebblepad/amd";
import "../../../loadingSpinner/loadingSpinner";
import "../../../utilities/onRepeatEnd";
import template from "./feedback-statements.component.html";

var feedbackStatementsDefinition = {
    bindings: {
        ready: "<",
        collections: "<",
        handleSelect: "&"
    },
    template: template,
    controller: FeedbackStatementsComponent
};

FeedbackStatementsComponent.$inject = ["$element"];

// tslint:disable-next-line:no-empty
function FeedbackStatementsComponent($element) {
    this.$element = $element;
}

FeedbackStatementsComponent.prototype.handleStatementSelect = function (event) {
    this.handleSelect({
        event: event
    });
};

FeedbackStatementsComponent.prototype.focusItem = function () {
    this.$element[0].querySelector(".feedback-statements--button").focus();
};

angularAMD.component("feedbackStatements", feedbackStatementsDefinition);

export { feedbackStatementsDefinition as feedbackStatementsComponent };
