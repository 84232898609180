import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../a11y/services/a11yMenuFactory.service";
import "../utilities/accessibilityKeyEventHelper.service";
import "../userAgent/userAgent.service";

angularAMD.directive("findLast", function () {
    return {
        scope: true,

        link: function (scope) {
            if (scope.$last && scope.init) {
                scope.init();
            }
        }
    };
});

angularAMD.directive("dropdown", Dropdown);
Dropdown.$inject = ["$rootScope", "$window", "$timeout", "A11yMenuFactory", "accessibilityKeyEventHelper", "domSearchHelper", "UserAgentService"];

function Dropdown($rootScope, $window, $timeout, a11yMenuFactory, accessibilityKeyHelper, domSearchHelper, UserAgentService) {
    var recent_dropdown = 0;

    return {
        restrict: "A",
        scope: true,

        controller: [
            "$scope",
            "$element",
            "$attrs",
            function ($scope, $element, $attrs) {
                $scope.document_el = angular.element(document);
                $scope.header_el = angular.element($element[0].getElementsByClassName("header")[0]);
                $scope.list_el = angular.element($element[0].getElementsByClassName("list")[0]);
                $scope.has_template = $scope.header_el.hasClass("template");
                $scope.roleMenu = null;

                var limitationBox = $attrs.limitationBox;
                if (limitationBox !== undefined && limitationBox !== "") {
                    $scope.limitation_box = angular.element(document.querySelector(limitationBox));
                } else if (document.getElementById("app-container")) {
                    $scope.limitation_box = angular.element(document.getElementById("app-container"));
                } else {
                    $scope.limitation_box = angular.element(document.body);
                }

                // applies all events for core dropdown functionality
                $scope.addListenerToLastItemList = function (last_element) {
                    last_element.bind("blur", function () {
                        $scope.collapseDropdownOnBlur();
                    });
                };

                $scope.unsetParentActiveIndicator = function () {
                    if ($scope.indicate_parent) {
                        $scope.indicate_parent.removeClass("dropdown-is-active");
                    }

                    if ($scope.header_el[0].attributes["aria-expanded"] !== undefined) {
                        $scope.header_el[0].setAttribute("aria-expanded", false);
                    }
                };

                $scope.setParentActiveIndicator = function () {
                    if ($scope.indicate_parent) {
                        $scope.indicate_parent.addClass("dropdown-is-active");
                    }

                    if ($scope.header_el[0].attributes["aria-expanded"] !== undefined) {
                        $scope.header_el[0].setAttribute("aria-expanded", true);
                    }
                };

                $scope.setFocusOnCurrentTab = function () {
                    if ($scope.focus_on_open_first) {
                        $scope.focus_on_open_first.focus();
                    }

                    if ($scope.focus_on_open_second) {
                        $scope.focus_on_open_second.focus();
                    }
                };

                $scope.reset = function (className) {
                    if ($scope.is_open) {
                        $scope.collapseDropdown();
                    }
                    //Reset init_open to allow the stored base height to be recalculated.
                    $scope.init_open = true;
                    $scope.triggerCloseOnAnyListItemClick(className);
                };

                $scope.onAttachMenu = function (event) {
                    if ($scope.roleMenu === null) {
                        $scope.roleMenu = a11yMenuFactory.createMenu($scope.header_el, $scope.list_el, function (event) {
                            const isTooltipPresent = domSearchHelper.getElementFromDomTree(event.target, (targetElement) => targetElement !== null && targetElement.dataset.hook === "tooltip");

                            if (isTooltipPresent === null) {
                                $scope.roleMenu.unconfigureMenu();
                                return $timeout($scope.collapseDropdown);
                            }
                        });
                    }

                    $scope.roleMenu.configureMenu();
                    var menuItems = $scope.roleMenu.getElements();

                    if (menuItems.length > 0) {
                        $scope.roleMenu.focusFirstItem(null, menuItems);
                    }

                    $scope.verticalDropdownAlign($scope.header_el, $scope.list_el);
                };

                // after click on any link or button or element that matches the class name inside dropdown list, it closes the dropdown.
                $scope.triggerCloseOnAnyListItemClick = function () {
                    var isClickableElem = function (elem) {
                        return elem.nodeName === "A" || (elem.nodeName === "BUTTON" && elem.getAttribute("ignore-dropdown-bind") === null) || elem.classList.contains($scope.triggerCloseElemClassName);
                    };

                    $scope.list_el.bind("click", function (event) {
                        if (domSearchHelper.getElementFromDomTree(event.target, isClickableElem, { node: $scope.list_el })) {
                            $scope.collapseDropdown();

                            $timeout(function () {
                                $scope.focus_el.focus();
                            });
                        }
                    });
                };

                $scope.collapseDropdownOnBlur = function () {
                    setTimeout(function () {
                        if (!$element[0].querySelector(":active") && !$element[0].querySelector(":focus")) {
                            $scope.collapseDropdown();
                        }
                    }, 50);
                };

                $scope.collapseDropdown = function () {
                    $scope.is_open = false;
                    $scope.document_el.unbind($scope.dropdown_event, $scope.documentEventHandler);
                    $scope.list_el.unbind($scope.dropdown_event);
                    $element.removeClass("active");
                    $scope.ariaExpanded($element, false);
                    $scope.unsetParentActiveIndicator();

                    if ($scope.roleMenu !== null) {
                        $scope.roleMenu.unconfigureMenu();
                    }

                    if ($scope.onDropdownClose && $scope[$scope.onDropdownClose]) {
                        $scope[$scope.onDropdownClose]($scope.identifier); //Shared Scope Callback
                    }
                };

                $scope.configureTriggerCloseElem = function (elemClassName) {
                    $scope.triggerCloseElemClassName = elemClassName;
                };
            }
        ],

        link: function (scope, element, attrs) {
            scope.is_open = false;
            scope.align_left = attrs.alignLeft !== undefined;
            scope.align_to_pointer = attrs.alignToPointer || false;
            scope.indicate_parent = attrs.indicateParent || false ? angular.element(document.querySelector(attrs.indicateParent)) : null;
            scope.focus_on_open_first = attrs.focusOnOpenFirst || false ? element[0].querySelector(attrs.focusOnOpenFirst) : null;
            scope.focus_on_open_second = attrs.focusOnOpenSecond || false ? element[0].querySelector(attrs.focusOnOpenSecond) : null;
            scope.identifier = attrs.identifier || null;

            attrs.$observe("identifier", function () {
                if (attrs.identifier !== scope.identifier) {
                    scope.identifier = attrs.identifier || null;
                }
            });

            scope.onDropdownClose = attrs.onDropdownClose;
            scope.init_open = true;
            scope.clickBound = false;
            scope.verticalAlign = attrs.verticalAlign !== undefined;

            if (UserAgentService.touch) {
                scope.dropdown_event = "tap";
            } else {
                scope.dropdown_event = "click";
            }

            if (attrs.dropdownAttachMenuEvent) {
                scope.$on(attrs.dropdownAttachMenuEvent, scope.onAttachMenu);
            }

            scope.initDropdown = function () {
                if (attrs.disableClick === undefined) {
                    scope.clickable_el = angular.element(scope.header_el[0].getElementsByClassName("clickable"));

                    // toggle dropdown clicking on dropdown header
                    if (scope.clickable_el[0]) {
                        scope.clickable_el.unbind(scope.dropdown_event);
                        scope.bindActivationToElem(scope.clickable_el);
                        scope.clickable_el.bound = true;
                    } else {
                        scope.bindActivationToElem(scope.header_el);
                    }
                } else {
                    scope.header_el.bind(scope.dropdown_event, function (e) {
                        e.stopPropagation();
                    });
                }
            };

            scope.bindActivationToElem = function (angularElem) {
                angularElem.bind(scope.dropdown_event, function (e) {
                    scope.toggleDropDown(e);
                });

                if (attrs.dropdownSubMenu) {
                    angularElem.bind("keyup", function (e) {
                        if (e.keyCode === accessibilityKeyHelper.keyCodeMap.downArrow) {
                            scope.toggleDropDown(e);
                        }
                    });
                }
            };

            // kick start automatically only when there is no template
            if (!scope.has_template) {
                scope.initDropdown();
            }

            scope.ariaExpanded = function (element, expanded) {
                var button = angular.element(element[0].getElementsByTagName("button")[0]);

                if (button) {
                    button.attr("aria-expanded", expanded);
                }
            };

            scope.toggleDropDown = function (e) {
                scope.is_open = !scope.is_open; // toggle dropdown state

                // center buttons when open
                if (scope.is_open) {
                    scope.triggerCloseOnAnyListItemClick();
                    scope.focus_el = document.activeElement;
                    element.addClass("active");
                    scope.ariaExpanded(element, true);
                    scope.setParentActiveIndicator();
                    scope.setFocusOnCurrentTab();

                    if (scope.align_to_pointer) {
                        scope.alignToPointerDropdownList(e);
                    } else {
                        scope.centerDropdownList();
                    }

                    scope.verticalDropdownAlign(element, scope.list_el);

                    // prevent collapsing dropdown when clicking inside dropdown list
                    scope.list_el.bind(scope.dropdown_event, function (e) {
                        if (e) {
                            e.stopPropagation();
                        }
                    });

                    // collapse dropdown when user clicks around dropdown
                    scope.documentEventHandler = function (event) {
                        const isTooltipPresent = domSearchHelper.getElementFromDomTree(event.target, (targetElement) => targetElement !== null && targetElement.dataset.hook === "tooltip");

                        if (isTooltipPresent === null) {
                            $timeout(scope.collapseDropdown);
                        }
                    };
                    scope.document_el.bind(scope.dropdown_event, scope.documentEventHandler);

                    if (attrs.dropdownAttachMenu) {
                        scope.onAttachMenu();
                    }
                } else {
                    scope.collapseDropdown();
                }

                // in case any other dropdown was opened before, remove class from it
                // tslint:disable-next-line:triple-equals
                if (recent_dropdown && recent_dropdown != 0 && recent_dropdown != element) {
                    if (recent_dropdown.hasClass("active")) {
                        if (recent_dropdown.scope && recent_dropdown.scope() && recent_dropdown.scope().collapseDropdown) {
                            recent_dropdown.scope().collapseDropdown();
                        }
                    }
                }

                recent_dropdown = element; // make current element make last

                if (e) {
                    e.stopPropagation();
                }
            };

            // unbind not needed event listener on destroy
            scope.$on("$destroy", function () {
                scope.document_el.unbind(scope.dropdown_event, scope.collapseDropdown);
                scope.list_el.unbind(scope.dropdown_event);

                if (scope.roleMenu !== null) {
                    scope.roleMenu.unconfigureMenu();
                    scope.roleMenu.onClose = null;
                }

                dropDownCloseEvent();
            });

            element.on("$destroy", function () {
                scope.$destroy();
            });

            scope.setListElHeight = function () {
                scope.list_el_height = scope.list_el_data.height + scope.distance_to_bottom - 5;
                scope.list_el.css({ height: scope.list_el_height + "px" });
            };

            scope.adjustOptions = {
                toTop: function (element) {
                    element.addClass("inverted");
                },
                reset: function (element) {
                    element.removeClass("inverted");
                }
            };

            scope.verticalDropdownAlign = function (element, bodyEl) {
                // don't run vertical align in case it's not set
                if (!scope.verticalAlign) {
                    return;
                }

                // reset any previously added adjustments
                scope.adjustOptions.reset(element);

                var targetRect = bodyEl[0].getBoundingClientRect(),
                    windowHeight = $window.innerHeight,
                    limitationBoxData,
                    extraBottomRoom,
                    distanceToBottom;

                // measure against limitation box
                if (scope.limitation_box && scope.limitation_box[0]) {
                    limitationBoxData = scope.limitation_box[0].getBoundingClientRect();
                    distanceToBottom = limitationBoxData.bottom - targetRect.bottom - 8; // 8 is a extra fixed gap
                } else {
                    //measure against window
                    extraBottomRoom = Math.floor(windowHeight * 0.07);
                    // get distance from bottom
                    distanceToBottom = windowHeight - targetRect.bottom - extraBottomRoom;
                }

                if (distanceToBottom < 0) {
                    scope.adjustOptions.toTop(element);
                }
            };

            scope.alignToPointerDropdownList = function (e) {
                var headerElRect = scope.header_el[0].getBoundingClientRect(),
                    listElRect = scope.list_el[0].getBoundingClientRect(),
                    pointerXPos = UserAgentService.touch ? e.originalEvent.changedTouches[0].clientX : e.pageX || e.clientX,
                    listMiddlePoint = headerElRect.left + listElRect.width / 2,
                    listOffset = 0;

                // place on the left
                if (pointerXPos <= listMiddlePoint) {
                    scope.list_el.css({ left: "" });
                }
                // place in the middle of cursor coords
                else {
                    listOffset = pointerXPos - listMiddlePoint;
                    scope.list_el.css({ left: listOffset + "px" });
                }
            };

            scope.centerDropdownList = function () {
                if (scope.init_open || scope.list_el[0].offsetHeight !== scope.original_list_el_height) {
                    scope.original_list_el_height = scope.list_el[0].getBoundingClientRect().height; // save original height of list for future use
                    scope.init_open = false;
                }

                scope.list_el.css({ height: "" });
                scope.list_el_data = scope.list_el[0].getBoundingClientRect(); // list dimensions data
                scope.limitation_box_data = scope.limitation_box[0].getBoundingClientRect(); // app wrapper dimensions data
                scope.header_el_width = scope.header_el[0].offsetWidth; // header width
                scope.list_el_width = scope.list_el_data.width; // list width
                scope.list_el_height = scope.list_el_data.height;
                scope.distance_to_left = scope.list_el_data.left - scope.limitation_box_data.left; // find distance to left
                scope.distance_to_bottom = window.innerHeight - scope.list_el_data.bottom;

                // find side offset only on initial run
                if (!scope.side_offset) {
                    scope.side_offset = (scope.list_el_width - scope.header_el_width) / 2; // find side offsets
                    scope.distance_to_right = scope.limitation_box_data.right - (scope.list_el_data.right + scope.side_offset); // find distance to right
                } else {
                    scope.distance_to_right = scope.limitation_box_data.right - scope.list_el_data.right; // find distance to right
                }

                // handle if list is offset offset on the right side
                if (scope.distance_to_right < 0) {
                    scope.list_el.css({ "margin-left": "" });
                    scope.list_el.css({ left: "" });
                    scope.list_el.css({ right: "0" });
                }

                if (scope.distance_to_left < 0) {
                    scope.list_el.css({ "margin-left": "-" + Math.A });
                    scope.list_el.css({ left: "0" });
                    scope.list_el.css({ right: "" });
                }

                // set height of dropdown list if it doesn't fit screen vertically
                if (!scope.verticalAlign) {
                    if (scope.distance_to_bottom < 5) {
                        scope.setListElHeight();
                    } else {
                        if (scope.distance_to_bottom > scope.original_list_el_height + 5 - scope.list_el_height + 5) {
                            scope.list_el.css({ height: "" });
                        } else {
                            scope.setListElHeight();
                        }
                    }
                }

                // horizontal align
                // don't align dropdown list element if it didn't change
                if (Math.round(scope.list_el_width) !== Math.round(scope.list_el_width_prev_value) && !scope.align_left) {
                    // if list is wider than header then add negative margin to the list to center it.
                    if (scope.list_el_width > scope.header_el_width) {
                        if (scope.distance_to_right > 0 && scope.distance_to_left > (scope.list_el_width - scope.header_el_width) / 2) {
                            scope.list_el.css({ "margin-left": "-" + scope.list_el_width / 2 + "px" });
                            scope.list_el.css({ left: "50%" });
                            scope.list_el.css({ right: "" });
                        }
                    } else {
                        // reset all styles in case list fits normally
                        scope.list_el.css({ "margin-left": "" });
                        scope.list_el.css({ left: "" });
                        scope.list_el.css({ right: "" });
                    }

                    // save last changed value of list element width
                    scope.list_el_width_prev_value = scope.list_el_width;
                }
            };

            if (attrs.isOpen !== undefined) {
                // start watcher for "is-open"
                attrs.$observe("isOpen", function (current_state) {
                    // tslint:disable-next-line:triple-equals
                    if (JSON.parse(current_state) == true) {
                        scope.toggleDropDown();
                    } else {
                        scope.collapseDropdown();
                    }
                });
            }

            var dropDownCloseEvent = $rootScope.$on("onDropdownClose", function () {
                scope.collapseDropdown();
            });
        }
    };
}
