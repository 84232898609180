export class IWantToOption {
    constructor(name, label, ariaLabel, broadcastName, order, icon) {
        this.name = name;
        this.label = label;
        this.ariaLabel = ariaLabel;
        this.broadcastName = broadcastName;
        this.order = order;
        this.icon = icon;
    }
}
