import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../expandable/expandable";
import "../../../assetEndpointService/assetEndpoint.service";
import "../../../multiLanguageService/multiLanguageService";

angularAMD.factory("spaSelectorService", [
    "$http",
    "baseUrlsFactory",
    "AssetEndpointService",
    "multiLanguageService",
    function ($http, baseUrlsFactory, AssetEndpointService, multiLanguageService) {
        const factory = {};
        const subTypeMap = {
            document: "Documents"
        };
        const defaultItemsForSelectorMapper = {
            AssetStoreAndResourceCentre: (selectorData) => {
                const assets = selectorData[0].Items;
                const resources = selectorData[1].Items.map((r) => ({ ...r, Subtitle: multiLanguageService.getString(`labels.selector.subtitles.${r.MainType.toLowerCase()}`) }));

                const merged = assets.concat(resources).sort((a, b) => (a.LastModified <= b.LastModified ? 1 : -1));
                return {
                    Description: multiLanguageService.getString("labels.selector.asset_and_resource", { count: merged.length }),
                    Items: merged
                };
            },
            default: (selectorData) => selectorData[0]
        };

        factory.getDefaultItemsForSelector = function (select_type, main_types, sub_types, exclude_ids, exclude_main_types, exclude_children_of, ownerships, config) {
            $http.defaults.withCredentials = true;
            const selectorObj = {
                SelectType: select_type,
                MainTypes: main_types,
                SubTypes: Array.isArray(sub_types) ? this.convertSubTypesToSearchSubTypes(sub_types) : null,
                ExcludeIds: exclude_ids,
                ExcludeChildrenOf: exclude_children_of,
                ExcludeMainTypes: exclude_main_types,
                Ownerships: ownerships
            };

            const responseMapper = defaultItemsForSelectorMapper[select_type] ?? defaultItemsForSelectorMapper.default;
            return $http
                .post(baseUrlsFactory.api_base_url + "SharedMenu/GetDefaultItemsForSelector", selectorObj, config)
                .then((response) => (response.data.length > 0 ? responseMapper(response.data) : { Items: [], Description: "" }));
        };

        factory.getFeedbackTemplates = function () {
            $http.defaults.withCredentials = true;
            return AssetEndpointService.getFeedbackTemplates();
        };

        factory.getSelectorsBasedOnIds = function (select_type, ids, config) {
            $http.defaults.withCredentials = true;
            const selectorObj = {
                SelectType: select_type,
                Ids: ids
            };
            return $http.post(baseUrlsFactory.api_base_url + "SharedMenu/GetSelectorsForSpecifiedIds", selectorObj, config);
        };

        factory.convertSubTypesToSearchSubTypes = function (subTypes) {
            return subTypes.map(function (subType) {
                const value = subTypeMap[subType.toLowerCase()];
                return value !== undefined ? value : subType;
            });
        };

        return factory;
    }
]);
