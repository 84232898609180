import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../assetEndpointService/assetEndpoint.service";

angularAMD.factory("fileUploadService", [
    "$http",
    "baseUrlsFactory",
    "$q",
    "AssetEndpointService",
    function ($http, baseUrlsFactory, $q, AssetEndpointService) {
        return {
            getUploadUrl: function () {
                return AssetEndpointService.getUploadUrl();
            },

            sendSaveFile: function (saveObject) {
                var body = {
                    title: saveObject.title,
                    description: saveObject.description,
                    altText: saveObject.altText,
                    ticketId: saveObject.ticketId,
                    tags: saveObject.tags,
                    folder: saveObject.folder,
                    assetOptions: saveObject.assetOptions
                };
                return AssetEndpointService.saveUpload(body, { returnRef: true }).then(function (response) {
                    if (!response.data || response.data.error) {
                        return $q.reject(response);
                    }
                    return {
                        assetRef: response.data,
                        ticketId: saveObject.ticketId
                    };
                });
            },

            cancelUpload: function (ticketId) {
                $http.defaults.withCredentials = true;

                var request = $http.post(baseUrlsFactory.api_base_url + "upload/cancel", {
                    ticketId: ticketId
                });

                var promise = request.then(
                    function (response) {
                        return response;
                    },
                    function (httpError) {
                        return httpError;
                    }
                );
                return promise;
            },

            getFileQuotas: function () {
                $http.defaults.withCredentials = true;

                var request = $http.get(baseUrlsFactory.api_base_url + "upload/quota", {});

                var promise = request.then(
                    function (response) {
                        return response;
                    },
                    function (httpError) {
                        return httpError;
                    }
                );
                return promise;
            },

            saveUpdatedFile: function (saveObject) {
                $http.defaults.withCredentials = true;

                var request = $http.post(baseUrlsFactory.api_base_url + "upload/save", {
                    title: saveObject.title,
                    description: saveObject.description,
                    altText: saveObject.altText,
                    assetId: saveObject.assetId,
                    ticketId: saveObject.ticketId
                });

                var promise = request.then(
                    function (response) {
                        if (response && response.status) {
                            return response;
                        } else {
                            return $q.reject();
                        }
                    },
                    function (httpError) {
                        return httpError;
                    }
                );
                return promise;
            },

            //This service is for when we add in checking for restricted file types on the selector upload. I.e. restricting upload to just images if you are replacing an image.
            getAllowedFileTypes: function (mainType) {
                var checkType = mainType.toLowerCase();
                switch (checkType) {
                    case "presentation":
                        return ["odp", "ppt", "pptx"];
                    case "spreadsheet":
                        return ["csv", "ods", "sxc", "xls", "xlsx"];
                    case "document":
                        return ["doc", "docx", "odt", "pdf", "txt"];
                    case "image":
                        return ["ai", "bmp", "dxf", "eps", "gif", "jpg", "jpeg", "png", "svg"];
                    case "audio":
                        return ["mp3", "ogg", "wav", "wma", "m4a"];
                    case "video":
                        return ["avi", "mp4", "mpg", "mpeg", "mkv", "mov", "wmv"];
                    case "archive":
                        return ["zip"];
                    default:
                        return;
                }
            },

            getCorrectFileIcon: function (subType) {
                var checkSubType = subType.toLowerCase();
                if (["odp", "ppt", "pptx"].indexOf(checkSubType) > -1) {
                    return "presentation";
                } else if (["csv", "ods", "sxc", "xls", "xlsx"].indexOf(checkSubType) > -1) {
                    return "spreadsheet";
                } else if (["doc", "docx", "odt", "txt"].indexOf(checkSubType) > -1) {
                    return "text";
                } else if (checkSubType === "pdf") {
                    return "pdf";
                } else if (["ai", "bmp", "dxf", "eps", "gif", "jpg", "jpeg", "png", "svg"].indexOf(checkSubType) > -1) {
                    return "image";
                } else if (["mp3", "ogg", "wav", "wma", "m4a"].indexOf(checkSubType) > -1) {
                    return "audio";
                } else if (["avi", "mp4", "mpg", "mpeg", "mkv", "mov", "wmv"].indexOf(checkSubType) > -1) {
                    return "video";
                } else if (checkSubType === "zip") {
                    return "archive";
                } else {
                    return "file";
                }
            },

            base64ToBlob: function (b64Data, contentType, sliceSize) {
                if (!sliceSize) {
                    sliceSize = 512;
                }

                var byteCharacters = window.atob(b64Data);
                var byteArrays = [];

                for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    var slice = byteCharacters.slice(offset, offset + sliceSize);

                    var byteNumbers = new Array(slice.length);
                    for (var i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

                return new Blob(byteArrays, { type: contentType });
            },

            getBase64FromDataUri: function (data) {
                return data.substring(data.indexOf(",") + 1);
            }
        };
    }
]);
