import { Component, PropsWithChildren, ReactNode } from "react";
import { createGuid } from "@pjs/utilities";
import { CollapsibleHeader } from "./CollapsibleHeader.component";
import { CollapsibleContent } from "./CollapsibleContent.component";
import { ICollapsibleManualProps } from "./interfaces/ICollapsibleManualProps";
import { CollapsibleContext } from "./Collapsible.context";
import { ICollapsibleContextObject } from "./interfaces/ICollapsibleContextObject";
import "./styles/collapsible.css";

export class CollapsibleManual extends Component<PropsWithChildren<ICollapsibleManualProps>> {
    public static Header: typeof CollapsibleHeader = CollapsibleHeader;
    public static Content: typeof CollapsibleContent = CollapsibleContent;
    private _contextObject: ICollapsibleContextObject;
    private readonly _contentId: string;

    constructor(props: ICollapsibleManualProps) {
        super(props);
        this._contentId = createGuid();

        this._contextObject = {
            contentId: this._contentId,
            isOpen: true,
            onToggle: this._toggle.bind(this)
        };
    }

    public render(): ReactNode {
        if (this._contextObject.isOpen !== this.props.isOpen) {
            this._updateContext();
        }

        return (
            <CollapsibleContext.Provider value={this._contextObject}>
                <div className={this.props.className}>{this.props.children}</div>
            </CollapsibleContext.Provider>
        );
    }

    private _toggle(): void {
        this.props.onToggle(!this._contextObject.isOpen);
    }

    private _updateContext(): void {
        this._contextObject = {
            contentId: this._contextObject.contentId,
            isOpen: this.props.isOpen,
            onToggle: this._contextObject.onToggle
        };
    }
}
