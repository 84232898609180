import { FunctionComponent } from "react";
import { Icon } from "../icon/Icon.component";
import { IButtonMenuRowProps } from "./interfaces/IButtonMenuRowProps";
import "./styles/button-menu-row.css";

export const ButtonMenuRow: FunctionComponent<IButtonMenuRowProps> = (props: IButtonMenuRowProps) => {
    return (
        <li aria-selected={props.activeRow} aria-label={props.option.ariaLabel} className="cui-button-menu-option" data-hook="button-menu-option" onClick={props.onClick} id={props.id} role="menuitem">
            <Icon className="cui-button-menu-option__icon" fill={props.option.iconColour} source={props.option.icon} />
            <span className="cui-button-menu-option__label">{props.option.text}</span>
        </li>
    );
};
