export class AssetContext {
    constructor(assetId, timeslice = "0", submissionId = "") {
        this.assetId = assetId;
        this.timeslice = timeslice;
        this.submissionId = submissionId;
    }

    cloneForLinkedAsset(assetId) {
        return new AssetContext(assetId, this.timeslice, this.submissionId);
    }
}
