import { angularAMD } from "@pebblepad/amd";
import "./typeCheck.service";

angularAMD.service("accessibilityKeyEventHelper", AccessibilityKeyHelper);
AccessibilityKeyHelper.$inject = ["typeCheck"];

function AccessibilityKeyHelper(typeCheck) {
    this.keyboardEventTypes = ["keyup", "keydown", "keypress"];
    this.keyCodeMap = {
        numbers: {
            normal: { from: 48, to: 57 },
            numpad: { from: 96, to: 105 }
        },
        backspace: 8,
        delete: 46,
        tab: 9,
        escape: 27,
        F5: 116,
        enter: 13,
        dot: [190, 110],
        a: 65,
        r: 82,
        home: 36,
        end: 35,
        leftArrow: 37,
        upArrow: 38,
        rightArrow: 39,
        downArrow: 40,
        pageUp: 33,
        pageDown: 34
    };

    this.keyMap = {
        enter: "Enter"
    };

    this.arrowCombos = {
        leftUpArrows: [this.keyCodeMap.leftArrow, this.keyCodeMap.upArrow],
        leftDownArrows: [this.keyCodeMap.leftArrow, this.keyCodeMap.downArrow],
        rightUpArrows: [this.keyCodeMap.rightArrow, this.keyCodeMap.upArrow],
        rightDownArrows: [this.keyCodeMap.rightArrow, this.keyCodeMap.downArrow],
        allArrows: []
    };
    this.arrowCombos.allArrows = this.arrowCombos.leftUpArrows.concat(this.arrowCombos.rightDownArrows);

    this.isArrowKey = function (e, which) {
        var keyCodes = this.arrowCombos[which + "Arrows"] || this.arrowCombos.allArrows;
        return keyCodes.indexOf(e.keyCode) > -1;
    };

    /**
     * Return true if event happened from keyboard.
     * Use this check, when you share event method with other input type events.
     * @param e
     * @returns {boolean}
     */
    this.isKeyboardEvent = function (e) {
        return this.keyboardEventTypes.indexOf(e.type) > -1;
    };

    this.isEnterKey = function (e) {
        return this.keyMap.enter === e.key;
    };

    this.isShiftKey = function (e) {
        return (e.shiftKey && e.keyCode === 16) || (e.type === "keyup" && e.keyCode === 16);
    };

    this.isWithShiftKey = function (e) {
        return e.shiftKey && e.keyCode !== 16;
    };

    this.isWithCtrlKey = function (e) {
        // ctrlKey - Windows, Linux
        // metaKey - COMMAND key on macOS
        return e.ctrlKey || e.metaKey;
    };

    this.isWithAltKey = function (e) {
        return e.altKey && e.keyCode !== 18;
    };

    this.isCtrlKey = function (e) {
        // ctrlKey - Windows, Linux
        // metaKey - COMMAND key on macOS
        return e.ctrlKey || e.metaKey || (e.type === "keyup" && e.keyCode === 17);
    };

    this.isTabKey = function (e) {
        return this.keyCodeMap.tab === e.keyCode;
    };

    // ctrl+A / cmd+A
    this.isSelectAll = function (e) {
        return e.keyCode === this.keyCodeMap.a && this.isCtrlKey(e);
    };

    this.refreshKeyCombination = function (e) {
        return e.keyCode === this.keyCodeMap.r && this.isCtrlKey(e);
    };

    this.isNumber = function (e, supportFloats, decimalSeparator) {
        var decimalSeparatorKeys = [];

        if (supportFloats) {
            var decimalSeparatorKeyCode = this.keyCodeMap[decimalSeparator || "dot"] || null;
            if (typeCheck.isArray(decimalSeparatorKeyCode)) {
                decimalSeparatorKeys = decimalSeparatorKeys.concat(decimalSeparatorKeyCode);
            } else {
                decimalSeparatorKeys.push(decimalSeparatorKeyCode);
            }
        }

        var isDecimalSeparator = decimalSeparatorKeys.indexOf(e.keyCode) > -1, // backspace, delete, tab, home, end, (possibly dot|comma if 'supportFloats' flag is set to true)
            digits =
                (e.keyCode >= this.keyCodeMap.numbers.normal.from && e.keyCode <= this.keyCodeMap.numbers.normal.to) ||
                (e.keyCode >= this.keyCodeMap.numbers.numpad.from && e.keyCode <= this.keyCodeMap.numbers.numpad.to);

        if (this.isWithShiftKey(e)) {
            return false;
        }

        return digits || isDecimalSeparator;
    };

    this.isSelectionManipulationKeys = function (e, backspaceDelete) {
        var manipulateSelectionKeys = [this.keyCodeMap.tab, this.keyCodeMap.home, this.keyCodeMap.end];
        if (backspaceDelete) {
            manipulateSelectionKeys = manipulateSelectionKeys.concat([this.keyCodeMap.backspace, this.keyCodeMap.delete]);
        }

        var selectAll = this.isSelectAll(e),
            arrowKey = this.isArrowKey(e),
            shiftKey = this.isShiftKey(e),
            refreshKeyCombination = this.refreshKeyCombination(e),
            escKey = e.keyCode === this.keyCodeMap.escape,
            F5Key = e.keyCode === this.keyCodeMap.F5,
            manipulateSelection = manipulateSelectionKeys.indexOf(e.keyCode) > -1; // backspace, delete, tab, home, end, (possibly dot|comma if 'supportFloats' flag is set to true)

        return selectAll || arrowKey || shiftKey || manipulateSelection || escKey || F5Key || refreshKeyCombination;
    };
}
