import { KeyboardEvent, MouseEvent } from "react";
import { Observable, Subject } from "@pjs/observables";
import { Immutable } from "@pjs/utilities";
import { IGroupedItemTriggerModelAdapterConfig } from "./interfaces/IGroupedItemTriggerModelAdapterConfig";
import { ITriggerEventAdapter } from "./interfaces/ITriggerEventAdapter";
import { MenuAriaModel } from "./types/MenuAriaModel";
import { IAriaEventAdapter } from "./interfaces/IAriaEventAdapter";
import { IMenuTriggerKeyEvents } from "./interfaces/IMenuTriggerKeyEvents";

export class TriggerEventAdapter<TData> implements IAriaEventAdapter<MenuAriaModel, ITriggerEventAdapter<TData>> {
    public modelChanges: Observable<Immutable<MenuAriaModel>>;
    public events: ITriggerEventAdapter<TData>;

    private readonly _config: IGroupedItemTriggerModelAdapterConfig<TData>;
    private readonly _modelSubject: Subject<Immutable<MenuAriaModel>>;
    private _currentModel: Immutable<MenuAriaModel>;

    constructor(config: IGroupedItemTriggerModelAdapterConfig<TData>, initialModel: MenuAriaModel) {
        this._config = config;
        this._currentModel = initialModel;
        this._modelSubject = new Subject<MenuAriaModel>();
        this.modelChanges = this._modelSubject.asObservable();

        this.events = {
            onClick: this._handleClick.bind(this),
            onKeyDown: this._handleKeyDown.bind(this)
        };
    }

    public updateModel(newModel: MenuAriaModel): void {
        this._currentModel = newModel;
    }

    private _handleKeyDown(e: KeyboardEvent, items: Array<TData>): void {
        const onKey = this._config[e.key as keyof IMenuTriggerKeyEvents<TData>];

        if (onKey !== undefined) {
            e.preventDefault();
            e.stopPropagation();

            this._generateNewModel(onKey(e, this._currentModel, items));
        }
    }

    private _handleClick(e: MouseEvent): void {
        e.stopPropagation();

        this._generateNewModel(this._config.onClick(e, this._currentModel));
    }

    private _generateNewModel(newBaseModel: Partial<Immutable<MenuAriaModel>> | null): void {
        if (newBaseModel === null) {
            return;
        }

        const newModel = { ...this._currentModel, ...newBaseModel } as unknown as Immutable<MenuAriaModel>;

        this._modelSubject.next(newModel);
    }
}
