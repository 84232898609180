import { RefObject } from "react";
import { Immutable } from "@pjs/utilities";
import { ComboboxAriaModel } from "../../menu-event-adapter/types/ComboboxAriaModel";
import { FloatingMenu } from "../FloatingMenu";
import { IComboboxEventModelHandlers } from "../../menu-event-adapter/interfaces/IComboboxEventModelHandlers";
import { ComboboxEventAdapter } from "../../menu-event-adapter/ComboboxEventAdapter";
import { dropdownMatchedWidthPositioningFactory } from "../../../../floating-positioner/factories/DropdownMatchedWidthPositioningFactory";
import { comboBoxAdapterPresetFactory } from "../../menu-event-adapter/factories/ComboBoxAdapterPresetFactory";

export function floatingAutoCompleteMenuFactory<T>(
    initialModel: Immutable<ComboboxAriaModel>,
    onModelChange: (model: ComboboxAriaModel) => void,
    triggerRef: RefObject<HTMLElement>,
    floatingRef: RefObject<HTMLElement>,
    boundaryRef: RefObject<HTMLElement>,
    onItemSelect: (item: T) => void,
    openLength: number
): FloatingMenu<ComboboxAriaModel, IComboboxEventModelHandlers<T>> {
    const eventAdapterPreset = comboBoxAdapterPresetFactory(onItemSelect, openLength, triggerRef, floatingRef);

    return new FloatingMenu<ComboboxAriaModel, IComboboxEventModelHandlers<T>>(triggerRef, floatingRef, boundaryRef, {
        ariaEventAdapter: new ComboboxEventAdapter(eventAdapterPreset, initialModel),
        floatingPositionerConfig: {
            middlewareFactory: dropdownMatchedWidthPositioningFactory,
            placement: "bottom-end"
        },
        onModelChange: onModelChange
    });
}
