import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../assetStore/services/assetStoreService";
import "../../../assetViews/sidebar/shares/shares";
import "../../../assetViews/sidebar/submissions/submissions";
import "../../../tagger/tagger";
import template from "./templates/base.html";

angularAMD.directive("institutionInfo", [
    "$sce",
    "$rootScope",
    "$compile",
    "$http",
    "baseUrlsFactory",
    "assetStoreService",
    function ($sce, $rootScope, $compile, $http, baseUrlsFactory, assetStoreService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                info: "=?info"
            },

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                }
            ],

            link: function (scope, element, attrs) {
                scope.letterLimit = 150;
            }
        };
    }
]);
