import { Immutable } from "@pjs/utilities";
import { RefObject } from "react";
import { MenuAriaModel } from "../../menu-event-adapter/types/MenuAriaModel";
import { IGroupedItemsMenuEventAdapter } from "../../menu-event-adapter/interfaces/IGroupedItemsMenuEventAdapter";
import { IGroupedItemMenuModelAdapterConfig } from "../../menu-event-adapter/interfaces/IGroupedItemMenuModelAdapterConfig";
import { closeIfFocusIsOutside } from "../../reducers/CloseIfFocusIsOutside.function";
import { setClosed } from "../../reducers/SetClosed.function";
import { Keys } from "../../../../../enums/Keys";
import { GroupedItemsMenuEventAdapter } from "../../menu-event-adapter/GroupedItemsMenuEventAdapter";
import { dropdownPositioningFactory } from "../../../../floating-positioner/factories/DropdownPositioningFactory";
import { FloatingMenu } from "../FloatingMenu";

export function floatingEmptyMenuFactory<T>(config: {
    matchItemOnKeys: (item: T, currentKeys: string) => boolean;
    initialModel: Immutable<MenuAriaModel>;
    onModelChange: (model: Immutable<MenuAriaModel>) => void;
    triggerRef: RefObject<HTMLElement>;
    floatingRef: RefObject<HTMLElement>;
    boundaryRef: RefObject<HTMLElement>;
    tempTargetRef: RefObject<HTMLElement>;
}): FloatingMenu<MenuAriaModel, IGroupedItemsMenuEventAdapter<T>> {
    const noopEvent = (): null => {
        return null;
    };

    const eventAdapterPreset: IGroupedItemMenuModelAdapterConfig<T> = {
        item: {
            onClick: noopEvent
        },
        target: {
            [Keys.ArrowUp]: noopEvent,
            [Keys.ArrowDown]: noopEvent,
            [Keys.End]: noopEvent,
            [Keys.Home]: noopEvent,
            [Keys.Enter]: noopEvent,
            [Keys.Escape]: setClosed,
            [Keys.Space]: noopEvent,
            default: noopEvent,
            onFocusLoss: closeIfFocusIsOutside
        }
    };

    const eventAdapter = new GroupedItemsMenuEventAdapter(eventAdapterPreset, config.triggerRef, config.floatingRef, config.initialModel, config.tempTargetRef);

    return new FloatingMenu<MenuAriaModel, IGroupedItemsMenuEventAdapter<T>>(config.triggerRef, config.floatingRef, config.boundaryRef, {
        ariaEventAdapter: eventAdapter,
        floatingPositionerConfig: {
            middlewareFactory: dropdownPositioningFactory,
            placement: "bottom-start"
        },
        onFirstPosition: () => {
            if (config.floatingRef.current !== null) {
                config.floatingRef.current.focus();
            }
        },
        onModelChange: config.onModelChange
    });
}
