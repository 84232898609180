import { angularAMD } from "@pebblepad/amd";
import { addFavouritesIcon, copyIcon, deleteIcon, editIcon, mapIcon, printIcon, purgeIcon, removeFavouritesIcon, restoreIcon, shareIcon, tagIcon, unarchiveIcon } from "@pjs/core-ui";

angularAMD.service("iWantToHandler", IWantToHandler);
IWantToHandler.$inject = ["multiLanguageService"];

function IWantToHandler(multiLanguageService) {
    this.multiLanguageService = multiLanguageService;
}

IWantToHandler.prototype.getEmptyOption = function () {
    return new IWantToOption("Empty", this.multiLanguageService.getString("buttons.iWantToHandler.noOptions"), "", null, 0, null);
};

IWantToHandler.prototype.getOptions = function () {
    return [
        new IWantToOption("Edit", this.multiLanguageService.getString("buttons.iWantToHandler.edit"), "", "editAssets", 1, editIcon),
        new IWantToOption("Copy", this.multiLanguageService.getString("buttons.iWantToHandler.copy"), "", "copyAssets", 10, copyIcon),
        new IWantToOption("Share", this.multiLanguageService.getString("buttons.iWantToHandler.share"), "", "shareAssets", 15, shareIcon),
        new IWantToOption("Tag", this.multiLanguageService.getString("buttons.iWantToHandler.tag"), "", "tagAssets", 20, tagIcon),
        new IWantToOption("Delete", this.multiLanguageService.getString("buttons.iWantToHandler.delete"), "", "deleteAssets", 25, deleteIcon),
        new IWantToOption("Restore", this.multiLanguageService.getString("buttons.iWantToHandler.restore"), "", "restoreAssets", 30, restoreIcon),
        new IWantToOption("Unarchive", this.multiLanguageService.getString("buttons.iWantToHandler.unhide"), "", "unarchiveAssets", 32, unarchiveIcon),
        new IWantToOption("Purge", this.multiLanguageService.getString("buttons.iWantToHandler.purge"), "", "purgeAssets", 35, purgeIcon),
        new IWantToOption("Export", this.multiLanguageService.getString("buttons.iWantToHandler.export"), "", "exportAssets", 80, printIcon),
        new IWantToOption("Map", this.multiLanguageService.getString("buttons.iWantToHandler.map"), "", "mapAssets", 85, mapIcon),
        new IWantToOption("Favourite", this.multiLanguageService.getString("buttons.iWantToHandler.favourite"), "", "favouriteAssets", 12, addFavouritesIcon),
        new IWantToOption("Unfavourite", this.multiLanguageService.getString("buttons.iWantToHandler.unfavourite"), "", "unfavouriteAssets", 12, removeFavouritesIcon),
        this.getEmptyOption()
    ];
};

/**
 * @param {String} name
 * @param {String} label
 * @param {String} ariaLabel
 * @param {String} broadcastName
 * @param {Number} order
 * @param {IIcon} icon
 * @constructor
 */
function IWantToOption(name, label, ariaLabel, broadcastName, order, icon) {
    this.name = name;
    this.label = label;
    this.ariaLabel = ariaLabel;
    this.broadcastName = broadcastName;
    this.order = order;
    this.icon = icon;
}
