import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import "../../../multiLanguageService/multiLanguageService";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "../../../numberPicker/numberPicker";
import template from "./grade-numeric.html";

class GradeNumericComponent extends NgComponent {
    constructor(multiLanguageService, feedbackHelper) {
        super();

        this.multiLanguageService = multiLanguageService;
        this._feedbackHelper = feedbackHelper;
        this.ACTION_CONSTANTS = ACTION_CONSTANTS;

        this.numberPickerField = {
            decimalPlaces: 0,
            max: 100,
            min: 0,
            value: this.grade,
            eventId: "",
            hideClearBtn: true,
            ariaLabel: this.multiLanguageService.getString("atlas_sidebar.a11y.grade_input_edit"),
            onValueChange: this.editNumericGrade.bind(this)
        };
    }

    $onChanges() {
        this.numberPickerField.value = this.grade;
    }

    editNumericGrade(newGrade) {
        const validation = this._feedbackHelper.getGradeValidation(newGrade);

        this.onEdit({
            validation: validation,
            changes: { Grade: newGrade }
        });
    }
}

const gradeNumericDefinition = {
    bindings: {
        grade: "<",
        mode: "<",
        onEdit: "&"
    },
    template: template,
    controller: GradeNumericComponent
};

GradeNumericComponent.$inject = ["multiLanguageService", "feedbackHelper"];
angularAMD.component("gradeNumeric", gradeNumericDefinition);
export { gradeNumericDefinition as gradeNumeric };
