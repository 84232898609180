export class PageModelSearch {
    constructor(pages) {
        this._pages = pages;
    }

    /**
     * @param {...function(PageViewModel):boolean} comparators
     * @return {boolean}
     */
    contains(...comparators) {
        return this._traverseTree(this._pages, comparators, null) !== null;
    }

    /**
     * @param {...function(PageViewModel):boolean} comparators
     * @return {PageViewModel | null}
     */
    find(...comparators) {
        return this._traverseTree(this._pages, comparators, null);
    }

    /**
     * @param {...function(PageViewModel):boolean} comparators
     * @return {Array<PageViewModel>>}}
     */
    filter(...comparators) {
        const matchingPages = [];
        const filter = (page, pages, parent) => {
            const pageMatch = this._runComparators(comparators, page, pages, parent);
            if (pageMatch) {
                matchingPages.push(page);
            }

            return false;
        };

        this._traverseTree(this._pages, [filter], null);
        return matchingPages;
    }

    /**
     * Searches for a matching page and returns the found page along with a list of its parents (0 index being closest parent, last index being furthest parent)
     * @param {...function(PageViewModel):boolean} comparators
     * @return {{page: PageViewModel, parents: Array<PageViewModel>} | null}
     */
    tracedFind(...comparators) {
        const result = this._traverseTreeWithTracing(this._pages, comparators, null);

        if (result === null) {
            return null;
        }

        return result;
    }

    _traverseTree(pages, comparators, parent) {
        for (const page of pages) {
            let bail = this._runComparators(comparators, page, pages, parent);
            if (bail) {
                return page;
            }

            if (page.Children.length > 0) {
                const result = this._traverseTree(page.Children, comparators, page);
                if (result !== null) {
                    return result;
                }
            }
        }

        return null;
    }

    _traverseTreeWithTracing(pages, comparators, parent) {
        for (const page of pages) {
            let bail = this._runComparators(comparators, page, pages, parent);
            if (bail) {
                return { page: page, parents: [] };
            }

            if (page.Children.length > 0) {
                const result = this._traverseTreeWithTracing(page.Children, comparators, page);
                if (result !== null) {
                    result.parents.push(page);
                    return result;
                }
            }
        }

        return null;
    }

    _runComparators(comparators, page, pages, parent) {
        for (const comparator of comparators) {
            if (!comparator(page, pages, parent)) {
                return false;
            }
        }

        return true;
    }
}
