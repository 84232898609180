import { angularAMD } from "@pebblepad/amd";
import { directiveAttributeFactory } from "../conditionalAttributeModule/directiveAttributeFactory";

//Module setup
//=============================================================================================================
var uberAriaModule = angular.module("uberAria", ["ng"]);

//Directive setup
//=============================================================================================================
var directives = [
    { key: "uberDescribedBy", value: "aria-describedby" },
    { key: "uberLabelledBy", value: "aria-labelledby" },
    { key: "uberLabel", value: "aria-label" }
];

directiveAttributeFactory.create(uberAriaModule, directives);

export { uberAriaModule as UberAria };
