import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";

angularAMD.factory("shareService", [
    "$http",
    "baseUrlsFactory",
    "$q",
    function ($http, baseUrlsFactory, $q) {
        function promisedGet(url, params) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.get(url, {
                timeout: httpTimeout.promise,
                params: params
            });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        function promisedPost(url, data) {
            $http.defaults.withCredentials = true;
            var httpTimeout = $q.defer();
            var request = $http.post(url, data, { timeout: httpTimeout.promise });
            var promise = request.then(
                function (response) {
                    return response;
                },
                function (httpError) {
                    return httpError;
                }
            );
            promise._httpTimeout = httpTimeout;
            return promise;
        }

        return {
            shareAssets: function (ids, accessControl, shareMode, message) {
                return promisedPost(baseUrlsFactory.api_base_url + "Share/ShareAssets", {
                    ...ids,
                    ...accessControl,
                    shareType: shareMode,
                    message: message
                });
            },

            deleteShare: function (shareOrPublishedId, isWebShare) {
                if (isWebShare) {
                    return promisedPost(baseUrlsFactory.api_base_url + "Share/DeleteWebShare?publishedId=" + shareOrPublishedId);
                }
                return promisedPost(baseUrlsFactory.api_base_url + "Share/DeleteShare?id=" + shareOrPublishedId);
            },

            removeSubmission: function (submissionId) {
                return promisedPost(baseUrlsFactory.api_base_url + "Share/RemoveSubmission?id=" + submissionId);
            },

            getSubmissionLocations: function (assetId) {
                return promisedGet(baseUrlsFactory.api_base_url + "Share/GetSubmissionLocations?assetId=" + assetId);
            },

            getHowCanIShare: function (assetId) {
                return promisedGet(baseUrlsFactory.api_base_url + "Share/HowCanIShare?assetId=" + assetId).then(function (response) {
                    return response.data && !response.data.ErrorMessage ? response.data : $q.reject(response);
                });
            },

            createExternalAssessorShare: function (externalAssessorShareCreateRequest) {
                return promisedPost(baseUrlsFactory.api_base_url + "Share/CreateExternalAssessorShare", externalAssessorShareCreateRequest);
            },

            removeSLA: function (user, submission) {
                return promisedPost(baseUrlsFactory.api_base_url + "Share/RemoveExternalAssessorShare", {
                    UserId: user,
                    SubmissionId: submission
                });
            },
            updateExternalAssessorShare: function (externalShareUpdate) {
                return promisedPost(baseUrlsFactory.api_base_url + "Share/UpdateExternalAssessorShare", externalShareUpdate);
            },

            getSubmissionLocationDetails: function (assignmentId) {
                return $http.get(baseUrlsFactory.api_base_url + "Workspace/GetSubmissionLocationDetails?assignmentId=" + assignmentId);
            }
        };
    }
]);
