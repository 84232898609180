import { angularAMD } from "@pebblepad/amd";

export class AssetFilteringConfig {
    constructor() {
        this.config = {
            enablePersistentFilters: true,
            disableWhenInFolders: []
        };
    }

    $get() {
        return this.config;
    }
}

angularAMD.provider("assetFilteringConfig", AssetFilteringConfig);
