function isScreenReaderHidden(element: HTMLElement): boolean {
    // Using getAttribute instead of element.ariaHidden due to FF not supporting it until version 119
    return element.getAttribute("aria-hidden") === "true" || (element as HTMLElement & { inert: boolean }).inert;
}

const inputElements: ReadonlyArray<string> = ["INPUT", "BUTTON", "SELECT", "TEXTAREA"];

export function withinDisablingParent(element: HTMLElement, container: HTMLElement): boolean {
    if (isScreenReaderHidden(element) || (element as HTMLInputElement).disabled === true) {
        return true;
    }

    let ancestor = element.parentElement;

    while (ancestor !== container && ancestor !== null) {
        if (isScreenReaderHidden(ancestor)) {
            return true;
        }

        if (ancestor instanceof HTMLFieldSetElement && ancestor.disabled && inputElements.includes(element.tagName)) {
            return true;
        }

        ancestor = ancestor.parentElement;
    }
    return false;
}
