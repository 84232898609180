import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../utilities/customEvent.service";
import "../../ckEditorModule/controllers/inlineCkEditor.controller";
import "../../copyPaste/selection.service";
import "../services/inlineCkEditorHelper.service";
import "../../ckEditorModule/services/ckEditorToolbarHolder.service";
import "../services/richTextEditingSettings.service";

angularAMD.directive("inlineTextEditor", [
    "$rootScope",
    "$compile",
    "$sce",
    "$timeout",
    "$http",
    "$templateCache",
    "$interpolate",
    "$window",
    "baseUrlsFactory",
    "multiLanguageService",
    "customEvent",
    "CkEditorService",
    "CKEDITOR_EVENTS",
    "SelectionService",
    "InlineCkEditorHelper",
    "CkEditorToolbarHolder",
    "richTextEditingSettings",
    function (
        $rootScope,
        $compile,
        $sce,
        $timeout,
        $http,
        $templateCache,
        $interpolate,
        $window,
        baseUrlsFactory,
        multiLanguageService,
        customEvent,
        CkEditorService,
        CKEDITOR_EVENTS,
        SelectionService,
        InlineCkEditorHelper,
        CkEditorToolbarHolder,
        richTextEditingSettings
    ) {
        if (!richTextEditingSettings.isLegacyEditorEnabled()) {
            return {};
        }

        return {
            restrict: "A",
            scope: {},
            require: "?ngModel",
            controller: "InlineCkEditorController",
            controllerAs: "vm",
            link: function (scope, element, attrs, ngModel) {
                var inlineController = scope.vm;
                var maxCkEditorInstances = 20;

                scope.clearObserver = attrs.$observe("inlineTextEditor", function () {
                    if (attrs.inlineTextEditor !== "false") {
                        scope.setupEditor();
                        scope.addElementListeners();

                        if (ngModel) {
                            scope.addFormatters();
                        } else if (attrs.compileHtml && attrs.compileHtmlEvent) {
                            scope.$on(attrs.compileHtmlEvent, function (e) {
                                scope.setDataFromElement(element[0]);
                            });
                        } else {
                            $timeout(function () {
                                scope.setDataFromElement(element[0]);
                            });
                        }
                    }
                    scope.clearObserver();
                });

                scope.setupEditor = function () {
                    const useSingleLine = attrs.singleLine === "true";
                    const config = InlineCkEditorHelper.createEditorConfig(CkEditorToolbarHolder.getToolbarElement(), useSingleLine, multiLanguageService.getLanguage().toLowerCase());
                    inlineController.onUpdate = customEvent.emit.bind(customEvent, element[0], "texteditorchange");
                    const pasteHandler = useSingleLine ? InlineCkEditorHelper.createSingleLinePasteHandler() : InlineCkEditorHelper.createMultiLinePasteHandler();
                    inlineController.pasteHandlers.push(pasteHandler);

                    inlineController.setConfig(config);

                    if (Object.keys(CkEditorService.instances).length < maxCkEditorInstances) {
                        inlineController.setupEditor();

                        element.on("focus", () => {
                            CkEditorToolbarHolder.displayToolbar(inlineController.editor, element, document.getElementsByClassName(attrs.inlineTextEditorWrapper)[0]);
                        });
                    } else {
                        scope.setupEditorOnFocus();
                    }
                };

                scope.setupEditorOnFocus = function () {
                    //When creating a CkEditor instance on the fly we have to get the caret position and add a bookmark so we can restore it / a selection.
                    //If it's a click event then we can just use that, if it's a touch, we have to wait for touchEnd,
                    //if it's keyboard, then we need to ignore any touch / click events and position the caret at the start
                    element.bind("focus", scope.onFirstSelection);
                    element.bind("touchstart", scope.onSelectionStart);
                    element.bind("mousedown", scope.onSelectionStart);
                };

                scope.onSelectionStart = function (e) {
                    scope.selectionStarted = true;
                    $window.addEventListener(e.type === "touchstart" ? "touchend" : "mouseup", scope.onSelectionEnd);
                };

                scope.onSelectionEnd = function (e) {
                    scope.selectionStarted = false;
                    $window.removeEventListener(e.type, scope.onSelectionEnd);

                    if ((e.type === "touchend" && element[0].contains(e.target)) || e.type === "mouseup") {
                        scope.onFirstSelection(e);
                    }
                };

                scope.onFirstSelection = function (e) {
                    //Remove events as we no longer need them
                    element.unbind("mousedown", scope.onFirstSelection);
                    element.unbind("touchstart", scope.onSelectionStart);
                    element.unbind("focus", scope.onSelectionStart);

                    if (inlineController.editor || scope.selectionStarted) {
                        return;
                    }

                    //Place a bookmark in the HTML to store caret / selection
                    if (SelectionService.isSelectionWithinElement(element[0])) {
                        inlineController.bookmark = SelectionService.insertBookmark();
                    }

                    inlineController.setupEditor();
                    inlineController.blurHandlers.push(scope.onEditorBlur);
                    inlineController.setEditorData(element[0].innerHTML);
                    //When the instance has fully been setup, restore the caret / selection via the bookmark
                    inlineController.editor.on(CKEDITOR_EVENTS.READY, inlineController.eventHandlers.onReadyWithSelection);
                    CkEditorToolbarHolder.displayToolbar(inlineController.editor, element, document.getElementsByClassName(attrs.inlineTextEditorWrapper)[0]);
                };

                scope.onEditorBlur = function () {
                    inlineController.destroyEditor();
                    scope.setupEditorOnFocus();
                };

                scope.addElementListeners = function () {
                    element.bind("focus", customEvent.emit.bind(customEvent, element[0], "customfocus"));
                };

                scope.addFormatters = function () {
                    var updater = function (model) {
                        inlineController.setEditorData(model);
                        return model;
                    };

                    ngModel.$formatters.push(updater);
                    scope.$on("$destroy", function () {
                        ngModel.$formatters.remove(updater);
                    });
                };

                scope.setDataFromElement = function (element) {
                    inlineController.setEditorData(element.innerHTML);
                };
            }
        };
    }
]);
