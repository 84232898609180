import DOMPurify, { SanitizeAttributeHookEvent } from "dompurify";
import { HtmlSanitiserEvent } from "../../enums/HtmlSanitiserEvent";
import { HeadingTagName } from "../../types/HeadingTagName";
import { SanitiserEventCallback } from "../../types/SanitiserEventCallback";
import { DomPurifyConfig } from "../../types/DomPurifyConfig";
import { convertElementToFragment } from "../../utilities/ConvertElementToFragment.function";
import { cleanupWhitespaceInHtmlString } from "../../utilities/cleanupWhitespaceInHtmlString.function";
import { SemanticHeadingFixer } from "./SemanticHeadingFixer";
import { KebabCssStyleDeclarationKeys } from "./types/KebabCssStyleDeclarationKeys";
import { HTMLElementWithSpecStyles } from "./types/HTMLElementWithSpecStyles";
import { IHtmlStringParserConfig } from "./interfaces/IHtmlStringParserConfig";
import { IHtmlTagConfig } from "./interfaces/IHtmlTagConfig";
import { domPurifyDumpster } from "./DomPurifyDumpster.const";
import { emptyTagConfig } from "./EmptyTagConfig.const";
import { ReadonlyElement } from "./types/ReadonlyElement";
import { ReadonlyCssStyleDeclaration } from "./types/ReadonlyCssStyleDeclaration";
import { IHtmlStringParser } from "./interfaces/IHtmlStringParser";
import { StyleConfig } from "./types/StyleConfig";

export class HtmlStringParser implements IHtmlStringParser {
    private readonly _config: IHtmlStringParserConfig;
    private readonly _domPurifyConfig: DomPurifyConfig;
    private readonly _headingFixer: SemanticHeadingFixer | null;
    private readonly _processNodeWithContext: SanitiserEventCallback;
    private readonly _preprocessWithContext: SanitiserEventCallback;
    private readonly _processAttributeWithContext: (element: Element, eventData: SanitizeAttributeHookEvent) => void;
    private _activeTagConfig: IHtmlTagConfig = emptyTagConfig;

    constructor(config: IHtmlStringParserConfig, baseHeadingLevel: HeadingTagName | null) {
        this._config = config;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this._domPurifyConfig = { ADD_ATTR: ["target"], ALLOWED_TAGS: Object.keys(this._config.tags), RETURN_DOM: true };
        this._headingFixer = baseHeadingLevel === null ? null : SemanticHeadingFixer.createFromHeadingName(baseHeadingLevel);

        this._processNodeWithContext = this._processNode.bind(this);
        this._processAttributeWithContext = this._processAttribute.bind(this);
        this._preprocessWithContext = this._preprocess.bind(this);
    }

    private static _isElement(node: Node): node is Element {
        return node.nodeType === Node.ELEMENT_NODE;
    }

    /**
     * @description The provided content is parsed into a sandbox Document. Once the content is cleaned, the body of that document is returned.
     * This allows:
     * - consumers to do further post-processing using the HTMLElement API.
     * - an efficient way to work with the internal DOMPurify (avoids unnecessary post-processing to a DocumentFragment)
     */
    public parse(dirtyContent: string): HTMLBodyElement {
        DOMPurify.addHook(HtmlSanitiserEvent.BeforeElement, this._preprocessWithContext);

        const parsedHtml = DOMPurify.sanitize(cleanupWhitespaceInHtmlString(dirtyContent), this._domPurifyConfig);

        DOMPurify.removeAllHooks();
        DOMPurify.removed.length = 0;
        domPurifyDumpster.empty();
        this._activeTagConfig = emptyTagConfig;

        return parsedHtml as HTMLBodyElement;
    }

    public parseToFragment(dirtyContent: string): DocumentFragment {
        const parsedHtml = this.parse(dirtyContent);
        return convertElementToFragment(parsedHtml);
    }

    public parseToString(dirtyContent: string): string {
        return this.parse(dirtyContent).innerHTML;
    }

    private _preprocess(node: Node): void {
        if (this._headingFixer !== null && HtmlStringParser._isElement(node)) {
            this._headingFixer.fix(node);
        }

        DOMPurify.removeHook(HtmlSanitiserEvent.BeforeElement);
        DOMPurify.addHook(HtmlSanitiserEvent.BeforeElement, this._processNodeWithContext);
        DOMPurify.addHook(HtmlSanitiserEvent.OnAttribute, this._processAttributeWithContext);
    }

    private _processNode(node: Node): void {
        this._activeTagConfig = emptyTagConfig;

        if (node.nodeType === Node.TEXT_NODE) {
            if (this._isRedundantWhitespace(node as Text)) {
                domPurifyDumpster.trash(node);
            }
            return;
        }

        if (!HtmlStringParser._isElement(node)) {
            domPurifyDumpster.trash(node);
            return;
        }

        const tagName = node.tagName.toLowerCase();
        if (tagName === "body") {
            return;
        }

        const transformer = this._config.transforms[tagName];
        if (transformer !== undefined) {
            const newElement = transformer.transform(node);

            if (newElement !== node) {
                if (newElement !== null) {
                    node.replaceWith(newElement);
                }

                domPurifyDumpster.trash(node);
                return;
            }
        }

        const tagConfig = this._config.tags[tagName];
        if (tagConfig === undefined) {
            if (node.childNodes.length > 0) {
                node.replaceWith(node, ...node.childNodes);
            }

            domPurifyDumpster.trash(node);
            return;
        }

        this._activeTagConfig = tagConfig;
    }

    private _processAttribute(element: Element, eventData: SanitizeAttributeHookEvent): void {
        if (this._activeTagConfig.styles !== null && eventData.attrName === "style") {
            eventData.attrValue = this._processStyles(element as HTMLElementWithSpecStyles, this._activeTagConfig.styles);
            eventData.keepAttr = eventData.attrValue !== "";
            return;
        }

        if (this._activeTagConfig.attributes === null) {
            eventData.keepAttr = false;
            return;
        }

        const processor = this._activeTagConfig.attributes[eventData.attrName];
        if (processor === undefined) {
            eventData.keepAttr = false;
            return;
        }

        const newValue = processor(eventData.attrValue, element as ReadonlyElement);
        if (newValue === null) {
            eventData.keepAttr = false;
            return;
        }

        eventData.attrValue = newValue;
    }

    private _processStyles(element: HTMLElementWithSpecStyles, styleConfig: StyleConfig): string {
        const styles = Array.from(element.style) as Array<KebabCssStyleDeclarationKeys>;
        let processedStyles = "";

        for (const style of styles) {
            const processor = styleConfig[style];
            if (processor === undefined) {
                continue;
            }

            const currentValue = element.style[style];
            const newValue = processor(currentValue, element.style as ReadonlyCssStyleDeclaration);
            if (newValue !== "") {
                processedStyles += `${style}: ${newValue};`;
            }
        }

        return processedStyles;
    }

    private _isRedundantWhitespace(textNode: Text): boolean {
        const textValue = textNode.nodeValue;
        if (textValue === null) {
            return true;
        }

        const previousSibling = textNode.previousSibling;
        if (previousSibling === null || previousSibling.nodeType !== Node.TEXT_NODE || previousSibling.nodeValue === null) {
            return false;
        }

        return /^\s*$/.test(textValue) && /(?:\s|\u00A0)$/.test(previousSibling.nodeValue);
    }
}
