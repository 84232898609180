export function getEditorToolbarHeight(toolbarContainer) {
    if (toolbarContainer.children.length === 0) {
        return toolbarContainer.offsetHeight;
    }

    const toolbar = toolbarContainer.children[0];

    const display = toolbar.style.display;
    const position = toolbar.style.position;

    toolbar.style.display = "";
    toolbar.style.position = "static";
    const toolbarHeight = parseFloat(getComputedStyle(toolbar).height);
    toolbar.style.display = display;
    toolbar.style.position = position;

    return toolbarHeight;
}
