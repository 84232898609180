import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";

angularAMD.factory("spaActionMenuService", [
    "$http",
    "baseUrlsFactory",
    function ($http, baseUrlsFactory) {
        var factory = {};

        factory.getMenu = function () {
            $http.defaults.withCredentials = true;
            return $http.get(baseUrlsFactory.api_base_url + "SharedMenu/MainMenu");
        };

        return factory;
    }
]);
