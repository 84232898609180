import { IIcon } from "./interfaces/IIcon";

export class Icon implements IIcon {
    private readonly _svgSource: string;

    constructor(svgSource: string) {
        this._svgSource = svgSource;
    }

    public toString(): string {
        return this._svgSource;
    }
}
