import { angularAMD } from "@pebblepad/amd";
import { Subject } from "@pjs/observables";
import { LinkerGlobalConfig } from "./LinkerGlobalConfig.provider";
import "../../user/user.service";
import "../../modal/services/modal";
import "../../assetSelector/assetSelector.service";
import "../../utilities/baseUrlsFactory";
import "./LinkerModal.component";

class LinkerService {
    constructor($rootScope, $routeParams, linkerGlobalConfig, user, modal, baseUrlsFactory, multiLanguageService) {
        this._$rootScope = $rootScope;
        this._$routeParams = $routeParams;
        this._linkerGlobalConfig = linkerGlobalConfig;
        this._user = user;
        this._modal = modal;
        this._baseUrlsFactory = baseUrlsFactory;
        this._multiLanguageService = multiLanguageService;
    }

    createModal(selectionDetails, focusTracker) {
        focusTracker.freeze();
        const linkChangeSubject = new Subject();
        const modalTemplateUrl = this._baseUrlsFactory.shared_component_base_url + "pebbleEditorPlugins/linkerPlugin/templates/linker-dialog.lazy.html";
        const user = this._user.getDto();

        const isEditing = selectionDetails.link !== "";
        const modalProps = {
            allowAssets: this._linkerGlobalConfig.allowAssets && user !== null && !user.IsExternal,
            allowTextField: selectionDetails.blockCount <= 1,
            excludedAssets: [this._$routeParams.assetId],
            isEditing: isEditing,
            isExternal: selectionDetails.isExternal,
            link: selectionDetails.link,
            linkText: selectionDetails.linkText,
            onClose: () => linkChangeSubject.complete(),
            onConfirm: (result) => linkChangeSubject.next({ mode: "insert", isExternal: result.isExternal, updatedLink: result.link, updatedLinkText: result.linkText }),
            onRemove: () => linkChangeSubject.next({ mode: "remove" }),
            title: this._multiLanguageService.getString(isEditing ? "pebble_editor.plugins.linker.modal.edit_link" : "pebble_editor.plugins.linker.modal.add_link")
        };

        this._modal.launch(modalTemplateUrl, modalProps, { autoRestoreFocus: false });
        this._$rootScope.$applyAsync();

        return linkChangeSubject;
    }
}

LinkerService.$inject = ["$rootScope", "$routeParams", LinkerGlobalConfig.name, "User", "modal", "baseUrlsFactory", "multiLanguageService"];
angularAMD.service("linkerService", LinkerService);
