import { KillSignal, Observable, Subject, takeUntil } from "@pjs/observables";

import { IDialogStep } from "./interfaces/IDialogStep";
import { IDialogSteps } from "./interfaces/IDialogSteps";
import { IMultiStepDialogInstance } from "./interfaces/IMultiStepDialogInstance";

export class DialogSteps<TModel> implements IDialogSteps<TModel> {
    public readonly changeStream: Observable<IDialogStep<TModel, IMultiStepDialogInstance<TModel>>>;
    public readonly items: Array<IDialogStep<TModel, IMultiStepDialogInstance<TModel>>>;

    private readonly _changeStreamSubject: Subject<IDialogStep<TModel, IMultiStepDialogInstance<TModel>>> = new Subject<IDialogStep<TModel, IMultiStepDialogInstance<TModel>>>();
    private _stepPointer: number = 0;

    constructor(items: Array<IDialogStep<TModel, IMultiStepDialogInstance<TModel>>>, killSignal: KillSignal) {
        this.items = items;
        this.changeStream = this._changeStreamSubject.pipe(takeUntil(killSignal));
    }

    public goTo(i: number): void {
        if (this.items[i] !== undefined && i > -1 && i !== this._stepPointer) {
            this._stepPointer = i;
            this._changeStreamSubject.next(this.items[i]);
        }
    }

    public next(): void {
        if (this._stepPointer < this.items.length - 1) {
            this._stepPointer = this._stepPointer + 1;
            this._changeStreamSubject.next(this.items[this._stepPointer]);
        }
    }

    public previous(): void {
        if (this._stepPointer > 0) {
            this._stepPointer = this._stepPointer - 1;
            this._changeStreamSubject.next(this.items[this._stepPointer]);
        }
    }
}
