import { FunctionComponent } from "react";
import { ParseHtml } from "@pjs/security";
import { coreUiI18n } from "../i18n/CoreUiI18n.const";
import { crossIcon } from "../icon/icons/Cross.icon";
import { IconButtonClass } from "../icon-button/enums/IconButtonClass";
import { Notice } from "../notice/Notice.component";
import { IconButton } from "../icon-button/IconButton.component";
import { IDismissableHtmlNoticeProps } from "./interfaces/IDismissableHtmlNoticeProps";
import "./styles/dismissable-html-notice.css";

export const DismissableHtmlNotice: FunctionComponent<IDismissableHtmlNoticeProps> = (props) => {
    const ariaLabel = coreUiI18n.getString("notice.labels.dismiss");

    return (
        <Notice type={props.type} ariaLabel={props.ariaLabel}>
            <div className="cui-dismissable-notice__wrapper">
                <ParseHtml className="cui-dismissable-notice__content" htmlString={props.content} />
                <IconButton ariaLabel={ariaLabel} className={IconButtonClass.Circular} source={crossIcon} onClick={props.onDismiss} dataHook="dimissable-notice-button" />
            </div>
        </Notice>
    );
};
