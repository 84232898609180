import { Immutable } from "@pjs/utilities";
import { RefObject, KeyboardEvent } from "react";
import { MenuAriaModel } from "../../menu-event-adapter/types/MenuAriaModel";
import { IGroupedItemsMenuEventAdapter } from "../../menu-event-adapter/interfaces/IGroupedItemsMenuEventAdapter";
import { setClosed } from "../../reducers/SetClosed.function";
import { IGroupedItemMenuModelAdapterConfig } from "../../menu-event-adapter/interfaces/IGroupedItemMenuModelAdapterConfig";
import { selectClickedItemAndSetClosed } from "../../reducers/SelectClickedItemAndSetClosed.function";
import { GroupedItemsMenuEventAdapter } from "../../menu-event-adapter/GroupedItemsMenuEventAdapter";
import { dropdownPositioningFactory } from "../../../../floating-positioner/factories/DropdownPositioningFactory";
import { FloatingMenu } from "../FloatingMenu";
import { menuRoleTargetEventsFactory } from "../../menu-event-adapter/factories/MenuRoleTargetEventsFactory";
import { IAriaModelWithType } from "../../menu-event-adapter/interfaces/IAriaModelWithType";

export function floatingMenuPickerFactory<T>(config: {
    matchItemOnKeys: (item: T, currentKeys: string) => boolean;
    initialModel: Immutable<MenuAriaModel>;
    onModelChange: (model: Immutable<MenuAriaModel>) => void;
    triggerRef: RefObject<HTMLElement>;
    floatingRef: RefObject<HTMLElement>;
    boundaryRef: RefObject<HTMLElement>;
    onItemSelect: (itemIndex: number) => void;
    tempTargetRef: RefObject<HTMLElement>;
}): FloatingMenu<MenuAriaModel, IGroupedItemsMenuEventAdapter<T>> {
    const selectItemAndSetClosed = (_e: KeyboardEvent, currentModel: IAriaModelWithType, _: Array<T>): Partial<MenuAriaModel> => {
        config.onItemSelect(currentModel.activeItemIndex);
        return setClosed();
    };

    const eventAdapterPreset: IGroupedItemMenuModelAdapterConfig<T> = {
        item: {
            onClick: (event, itemIndex) => {
                config.onItemSelect(itemIndex);
                return selectClickedItemAndSetClosed(event, itemIndex);
            }
        },
        target: menuRoleTargetEventsFactory(config.matchItemOnKeys, selectItemAndSetClosed)
    };

    const eventAdapter = new GroupedItemsMenuEventAdapter(eventAdapterPreset, config.triggerRef, config.floatingRef, config.initialModel, config.tempTargetRef);

    return new FloatingMenu<MenuAriaModel, IGroupedItemsMenuEventAdapter<T>>(config.triggerRef, config.floatingRef, config.boundaryRef, {
        ariaEventAdapter: eventAdapter,
        floatingPositionerConfig: {
            middlewareFactory: dropdownPositioningFactory,
            placement: "bottom-start"
        },
        onFirstPosition: () => {
            if (config.floatingRef.current !== null) {
                config.floatingRef.current.focus();
            }
        },
        onModelChange: config.onModelChange
    });
}
