import { Component, createRef, ReactNode, RefObject } from "react";
import { BoundaryContext } from "../boundary/Boundary.context";
import { IMutableBoundaryContext } from "./interfaces/IMutableBoundaryContext";
import "./styles/boundary-bridge.css";

export class BoundaryBridge extends Component<{ children?: ReactNode }> {
    private readonly _context: IMutableBoundaryContext = { element: { current: null } };
    private readonly _container: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    public componentDidMount(): void {
        if (this._container.current === null) {
            return;
        }

        let container: HTMLElement = this._container.current;
        let element = container.parentElement;

        while (element !== null) {
            if (element.dataset.dropdownContainer !== undefined) {
                container = element;
                break;
            }

            element = element.parentElement;
        }

        this._context.element.current = element;
    }

    public render(): ReactNode {
        return (
            <div className="cui-boundary-bridge__container" ref={this._container}>
                <BoundaryContext.Provider value={this._context}>{this.props.children}</BoundaryContext.Provider>
            </div>
        );
    }
}
