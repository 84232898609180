import { PureComponent, ReactElement } from "react";
import { getTrackBackground, Range } from "react-range";
import { IRangeSliderProps } from "./interfaces/IRangeSliderProps";
import { Tick } from "./tick/Tick.component";
import { TickAlignment } from "./enums/TickAlignment";
import { IRangeSliderDefaultProps } from "./interfaces/IRangeSliderDefaultProps";
import "./styles/range-slider.css";

export class RangeSlider extends PureComponent<IRangeSliderProps> {
    public static defaultProps: IRangeSliderDefaultProps = {
        colour: "#ccc",
        disabled: false,
        progressColour: "#0566a9"
    };

    public readonly onChangeHandler: (values: Array<number>) => void;

    constructor(props: IRangeSliderProps) {
        super(props);
        this.onChangeHandler = this._onChangeHandler.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<IRangeSliderProps>): void {
        if (prevProps.max !== this.props.max && this.props.value > this.props.max) {
            this._onChangeHandler([this.props.max]);
        }

        if (prevProps.min !== this.props.min && this.props.value < this.props.min) {
            this._onChangeHandler([this.props.min]);
        }
    }

    public render(): ReactElement {
        const values = [this.props.value];

        return (
            <div className="cui-range-slider__container" data-hook={`range-slider-${this.props.value}`} style={{ padding: `0 ${this.props.thumbWidth / 2}px` }}>
                <Range
                    step={this.props.step}
                    min={this.props.min}
                    max={this.props.max}
                    disabled={this.props.disabled}
                    values={values}
                    onChange={this.onChangeHandler}
                    renderTrack={(track) => (
                        <div onMouseDown={track.props.onMouseDown} onTouchStart={track.props.onTouchStart} className="cui-range-slider" style={{ ...track.props.style }}>
                            <div className="cui-range-slider__ticks">
                                {this.props.ticks.map((tick) => (
                                    <Tick
                                        key={tick.id}
                                        alignment={tick.placement <= 50 ? TickAlignment.Left : TickAlignment.Right}
                                        placement={tick.placement}
                                        tooltip={tick.tooltip}
                                        label={tick.label}
                                        colour={this.props.colour}
                                        progressColour={this.props.progressColour}
                                        ratingValues={values}
                                    />
                                ))}
                            </div>

                            <div
                                ref={track.props.ref}
                                className="cui-range-slider__track"
                                data-hook="range-slider-track"
                                style={{
                                    background: getTrackBackground({
                                        colors: [this.props.progressColour, this.props.colour],
                                        max: this.props.max,
                                        min: this.props.min,
                                        values: values
                                    })
                                }}>
                                {track.children}
                            </div>
                        </div>
                    )}
                    renderThumb={(thumb) => (
                        <div
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...thumb.props}
                            className="cui-range-slider__thumb"
                            data-hook="range-slider-thumb"
                            style={{
                                ...thumb.props.style,
                                display: this.props.disabled ? "none" : "block",
                                height: this.props.thumbHeight,
                                width: this.props.thumbWidth
                            }}
                        />
                    )}
                />
            </div>
        );
    }

    private _onChangeHandler(values: Array<number>): void {
        if (this.props.disabled) {
            return;
        }

        if (typeof this.props.onChange === "function") {
            this.props.onChange(values[0]);
        }
    }
}
