import { ckEditorModule } from "../pebbleCkEditor";
import "../../utilities/domSearchHelper.service";

ckEditorModule.provider("CkEditorToolbarHolder", CkEditorToolbarHolderProvider);

function CkEditorToolbarHolderProvider() {
    this.container = null;
    this.setContainer = function (element) {
        this.container = element;
    };

    this.$get = function ($document, CKEDITOR_EVENTS, domSearchHelper) {
        return new CkEditorToolbarHolder($document, CKEDITOR_EVENTS, domSearchHelper, this.container);
    };
    this.$get.$inject = ["$document", "CKEDITOR_EVENTS", "domSearchHelper"];
}

function CkEditorToolbarHolder($document, CKEDITOR_EVENTS, domSearchHelper, container) {
    this.constants = {
        CKEDITOR_EVENTS: CKEDITOR_EVENTS
    };

    this.fallbackContainer = container;
    this.$document = $document;
    this.domSearchHelper = domSearchHelper;

    this.activeToolbarStyle = "inline-text-editor-toolbar-holder--active";
    this.editors = [];
    this.toolbarHook = null;
    this.toolbarStagingElement = this.$document[0].createElement("inline-text-editor-toolbar-staging-area");
    // tslint:disable-next-line:no-unsafe-dom-insert-calls
    this.$document[0].body.appendChild(this.toolbarStagingElement);
    this.toolbarContainer = this.$document[0].createElement("inline-text-editor-toolbar-holder");
    // tslint:disable-next-line:no-unsafe-dom-insert-calls
    this.toolbarStagingElement.appendChild(this.toolbarContainer);
}

CkEditorToolbarHolder.prototype.getToolbarElement = function () {
    return this.toolbarContainer;
};

CkEditorToolbarHolder.prototype.moveToolbarElement = function (element, toolbarAnchorSelector) {
    var container = this.$document[0].querySelector(toolbarAnchorSelector) || this.fallbackContainer;

    this.domSearchHelper.getElementFromDomTree(
        element,
        function (el) {
            if (_anchorFilter(el)) {
                container = el;
                return true;
            }

            var validSibling = _checkSiblings(el, _anchorFilter);
            if (validSibling) {
                container = validSibling;
                return true;
            }

            return false;
        },
        null,
        true
    );

    if (container.lastChild !== this.toolbarContainer) {
        // tslint:disable-next-line:no-unsafe-dom-insert-calls
        container.appendChild(this.toolbarContainer);
    }
};

CkEditorToolbarHolder.prototype.displayToolbar = function (editor, element, scrollContainer) {
    this.moveToolbarElement(element[0], this.toolbarHook);
    var editorId = editor.id;
    this.editors.push(editorId);

    var activeToolbarStyle = this.activeToolbarStyle;
    this.toolbarContainer.classList.add(activeToolbarStyle);

    var notification = this.$document[0].querySelector(".builders-toolbar__notifier");
    var navigation = this.$document[0].querySelector("navigation");
    if (navigation && this.toolbarHook === null) {
        this.toolbarContainer.style.top = navigation.getBoundingClientRect().bottom + "px";
    } else if (notification && this.toolbarHook === null) {
        this.toolbarContainer.style.top = notification.getBoundingClientRect().bottom + "px";
    }

    var self = this;
    var onBlur = function () {
        self.editors.remove(editorId);
        editor.removeListener(self.constants.CKEDITOR_EVENTS.BLUR, onBlur);
        editor.removeListener(self.constants.CKEDITOR_EVENTS.DESTROY, onBlur);

        if (self.editors.length === 0) {
            self.hideToolbar(element, scrollContainer);
        }
    };

    setTimeout(function () {
        self.repositionScrollContainer(element, scrollContainer);
    }, 300);

    editor.on(this.constants.CKEDITOR_EVENTS.BLUR, onBlur);
    editor.on(this.constants.CKEDITOR_EVENTS.DESTROY, onBlur);
};

CkEditorToolbarHolder.prototype.repositionScrollContainer = function (element, scrollContainer) {
    if (!scrollContainer) {
        return;
    }

    var toolbarBox = this.toolbarContainer.getBoundingClientRect();
    var elementBox = element[0].getBoundingClientRect();
    if (elementBox.top > 0 && elementBox.top < toolbarBox.bottom && scrollContainer.scrollTop < toolbarBox.height) {
        scrollContainer.style.transition = "margin 0.2s";
        scrollContainer.style.marginTop = toolbarBox.height - scrollContainer.scrollTop + "px";
    }
};

CkEditorToolbarHolder.prototype.hideToolbar = function (element, scrollContainer) {
    this.toolbarContainer.classList.remove(this.activeToolbarStyle);
    this.toolbarContainer.style.top = "";

    if (scrollContainer) {
        scrollContainer.style.transition = "";
        scrollContainer.style.marginTop = "";
    }
};

CkEditorToolbarHolder.prototype.setToolbarHook = function (element) {
    this.toolbarHook = element;
};

CkEditorToolbarHolder.prototype.clearToolbarHook = function () {
    this.toolbarHook = null;
};

//Private methods
function _anchorFilter(el) {
    return el.getAttribute("data-inline-editor-anchor") !== null;
}

function _checkSiblings(el, filter) {
    var elements = el.parentElement.children;
    for (var i = 0; i < elements.length; i++) {
        if (el !== elements[i] && filter(elements[i])) {
            return elements[i];
        }
    }
    return null;
}
