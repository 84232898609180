import { FunctionComponent } from "react";
import { IconButtonClass } from "../icon-button/enums/IconButtonClass";
import { ButtonMenu } from "../button-menu/ButtonMenu.component";
import { overflowMenuIcon } from "../icon/icons/OverflowMenu.icon";
import { Icon } from "../icon/Icon.component";
import { IOverflowMenuProps } from "./interfaces/IOverflowMenuProps";
import "./styles/overflow-menu.css";
import "../icon-button/styles/icon-button.css";

export const OverflowMenu: FunctionComponent<IOverflowMenuProps> = (props: IOverflowMenuProps) => {
    return (
        <ButtonMenu {...props} buttonStyle={`${IconButtonClass.Circular} cui-overflow-menu__button`} openStyle="cui-overflow-menu__button--menu-open" placement="bottom-end">
            <Icon className={`cui-icon-button__icon ${props.disabled === true ? "cui-overflow-menu__icon--disabled" : ""}`} data-hook="overflow-menu-icon" source={overflowMenuIcon} />
        </ButtonMenu>
    );
};
