import { Immutable } from "@pjs/utilities";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";

export function setClosed(): Partial<Immutable<MenuAriaModel>> {
    return {
        isOpen: false,
        type: AriaMenuStatus.ClosedByKeyboardEvent
    };
}
