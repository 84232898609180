import { noop } from "@pjs/utilities";
import { Observable, ReplaySubject, switchMap, tap, from, defer, share, race, catchError, of } from "@pjs/observables";
import { IEmbedConsentManager } from "./interfaces/IEmbedConsentManager";

export class ApiConsentManager implements IEmbedConsentManager {
    public readonly consent: Observable<boolean>;

    private readonly _hasConsentSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private readonly _makeRequestForConsentState: () => Promise<boolean>;
    private readonly _makeRequestToSetConsentState: (state: boolean) => Promise<void>;

    constructor(makeRequestForConsentState: () => Promise<boolean>, makeRequestToSetConsentState: (state: boolean) => Promise<void>) {
        this._makeRequestForConsentState = makeRequestForConsentState;
        this._makeRequestToSetConsentState = makeRequestToSetConsentState;

        this.consent = this._createHasConsentObservable();
    }

    public grantConsent(): Observable<void> {
        this._hasConsentSubject.next(true);
        return from(this._makeRequestToSetConsentState(true).then(noop));
    }

    public revokeConsent(): Observable<void> {
        return from(this._makeRequestToSetConsentState(false).then(noop)).pipe(tap(() => this._hasConsentSubject.next(false)));
    }

    private _createHasConsentObservable(): Observable<boolean> {
        const getInititalConsentState = defer(() => this._makeRequestForConsentState()).pipe(
            catchError(() => of(false)),
            share(),
            switchMap((value) => {
                this._hasConsentSubject.next(value);
                return this._hasConsentSubject;
            })
        );

        return race(this._hasConsentSubject, getInititalConsentState);
    }
}
