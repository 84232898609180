import { angularAMD } from "@pebblepad/amd";
import { NgComponent } from "angular/ngComponent";
import "../react2angular/buttonComponent";
import template from "./dialog-action.html";

export class DialogActionController extends NgComponent {
    constructor($rootScope, $scope) {
        super();

        this.$rootScope = $rootScope;
        this.$scope = $scope;

        this.handleClick = this._handleClick.bind(this);
    }

    _handleAction() {
        this.onClick();
    }

    _handleAsyncAction() {
        const result = this.onClick();
        if (typeof result.then === "function") {
            this.disabled = true;
            result.finally(() => {
                this.disabled = false;
            });
        }
    }

    _handleClick() {
        try {
            if (this.isAsyncAction) {
                this._handleAsyncAction();
            } else {
                this._handleAction();
            }

            if (this.$scope.$$phase === null && this.$scope.$root.$$phase === null) {
                this.$scope.$apply();
            } else {
                this.$scope.$evalAsync();
            }
        } catch (e) {
            console.error(e);
        }

        if (this.hideDialog) {
            this.$scope.$parent.hideModal();
        }
    }
}

const dialogActionDefinition = {
    bindings: {
        buttonClass: "<",
        dataHook: "@",
        label: "@",
        onClick: "&",
        disabled: "<?",
        type: "@?",
        ariaLabel: "<",
        ariaDescribedBy: "@",
        hideDialog: "<?", // Boolean
        isAsyncAction: "<?" // Boolean
    },
    template: template,
    controller: DialogActionController
};

DialogActionController.$inject = ["$rootScope", "$scope"];
angularAMD.component("dialogAction", dialogActionDefinition);
