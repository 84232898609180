import { AsyncModelStatus } from "./enums/AsyncModelStatus";
import { IAsyncModelError } from "./interfaces/IAsyncModelError";

export function createAsyncModelError<T>(error: T): IAsyncModelError<T> {
    return {
        error: error,
        pending: null,
        result: null,
        status: AsyncModelStatus.Error
    };
}
