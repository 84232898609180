import { TimedArray } from "@pjs/utilities";

export class PrintableCharacterMatcher<T> {
    private readonly _list: ReadonlyArray<T>;
    private readonly _itemIsMatch: (item: T, characters: string) => boolean;
    private readonly _keys: TimedArray<string>;

    constructor(list: ReadonlyArray<T>, itemIsMatch: (item: T, charactersEntered: string) => boolean) {
        this._list = list;
        this._itemIsMatch = itemIsMatch;
        this._keys = new TimedArray(150);
    }

    public trackKey(key: string): void {
        if (key.length > 1) {
            return;
        }
        this._keys.push(key);
    }

    public getIndexOfNextMatch(currentIndex: number): number {
        const keys = this._keys.getItems();

        if (keys.length < 1) {
            return -1;
        }

        const currentKeys = keys.join("");

        for (let i = currentIndex + 1; i < this._list.length; i++) {
            if (this._itemIsMatch(this._list[i], currentKeys)) {
                return i;
            }
        }

        for (let i = 0; i <= currentIndex; i++) {
            if (this._itemIsMatch(this._list[i], currentKeys)) {
                return i;
            }
        }

        return -1;
    }
}
