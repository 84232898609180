import { animateElement } from "../../../utils/animate-element/AnimateElement";
import { dialogAnimationKeyFrames } from "../consts/DialogAnimationKeyFrames.const";

export const animateDialogIn = (dialog: HTMLDialogElement, animateFromBottom: boolean): Promise<void> => {
    return animateElement(dialog, {
        duration: 260,
        easing: "cubic-bezier(.14,.65,.11,.85)",
        keyframes: animateFromBottom ? dialogAnimationKeyFrames.fadeInUp : dialogAnimationKeyFrames.fadeInDown
    });
};
