import { IEditor } from "../editor/interfaces/IEditor";
import { IEditorCache } from "./interfaces/IEditorCache";

export class EditorCache implements IEditorCache {
    private readonly _maxEditors: number;
    private readonly _editors: Set<IEditor> = new Set();

    constructor(maxEditors: number) {
        this._maxEditors = maxEditors;
    }

    public add(newEditorToCache: IEditor): void {
        if (this._editors.has(newEditorToCache)) {
            this._editors.delete(newEditorToCache);
        }

        if (this._editors.size === this._maxEditors) {
            this._removeLeastRecentlyUsed();
        }

        this._editors.add(newEditorToCache);
    }

    public remove(editor: IEditor): void {
        this._editors.delete(editor);
    }

    public has(editor: IEditor): boolean {
        return this._editors.has(editor);
    }

    private _removeLeastRecentlyUsed(): void {
        const addedLeastRecently = this._editors.keys().next().value;

        if (addedLeastRecently !== undefined) {
            addedLeastRecently.disable();
            this._editors.delete(addedLeastRecently);
        }
    }
}
