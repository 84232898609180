/**
 * @param {object} dataManagerService
 * @returns {PromiseLike<{ assetId: string, manager: object }>}
 */
export function createWorkbookResource(dataManagerService) {
    return dataManagerService
        .init({
            AssetId: null,
            Asset: { MainType: "WorkbookBuilder" }
        })
        .then(() => {
            const manager = dataManagerService.workbookBuilderManagerObject;
            return { assetId: manager.AssetId, manager: manager };
        });
}
