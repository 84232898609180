import { angularAMD } from "@pebblepad/amd";
import "../../builder/dataManager/dataManagerService";
import "../../collections/directives/collection";
import "../../extensions/arrayExtensions";
import "../../utilities/helpers";
import "../../utilities/urlService";
import { TIMEOUT_CONSTANTS } from "../../constants/timeout.constants";

angularAMD.factory("assetDtoHelperFactory", [
    "$rootScope",
    "$location",
    "$timeout",
    "$window",
    "baseUrlsFactory",
    "$q",
    "dataManagerService",
    "helpers",
    "urlService",
    function ($rootScope, $location, $timeout, $window, baseUrlsFactory, $q, dataManagerService, helpers, urlService) {
        var AssetDtoHelper = (function () {
            function AssetDtoHelper(overlay_el) {
                this.overlay = overlay_el;
            }

            AssetDtoHelper.prototype.assetOptionsKeys = ["BackgroundImageAssetId", "SubTitle", "SortOrder", "ReverseOrder", "CpdTimeInMins", "CpdPoints"];

            AssetDtoHelper.prototype.updateModel = function (modelKey, newValue, assetDto, hasLocalStorage, assetId) {
                var keys = modelKey.split("."),
                    key = keys[keys.length - 1],
                    updated = false;

                if (this.assetOptionsKeys.contains(key)) {
                    updated = this.updateAssetOption(assetDto, key, newValue, true);
                } else if (assetDto.hasOwnProperty(key)) {
                    assetDto[key] = newValue;
                    updated = true;
                }

                if (hasLocalStorage && updated && assetId) {
                    assetDto.LastModified = new Date();
                    var localData = dataManagerService.dtos[assetId];
                    localData.reviseData();
                    localData.updateDtoModel(assetDto);
                    localData.save();
                }
            };

            AssetDtoHelper.prototype.updateAssetOption = function (assetDto, key, value) {
                var option = assetDto.AssetOptions.where({ Key: key });
                if (helpers.isNotEmptyArray(option)) {
                    if (option[0].Value !== value) {
                        option[0].Value = value;
                        return true;
                    }
                } else {
                    assetDto.AssetOptions.push({
                        Key: key,
                        Value: value
                    });

                    return true;
                }
                return false;
            };

            AssetDtoHelper.prototype.removeAssetOption = function (assetDto, key) {
                assetDto.AssetOptions = assetDto.AssetOptions.filter((x) => x.Key !== key);
            };

            AssetDtoHelper.prototype.getAssetOptionValue = function (assetDto, key) {
                var found = assetDto.AssetOptions.where({ Key: key })[0];
                return found ? found.Value : "";
            };

            AssetDtoHelper.prototype.saveAsset = function (assetDto) {
                var self = this;
                this.showSavingDialog();
                // check revision number hasn't been set to NaN
                if (isNaN(assetDto.Revision)) {
                    assetDto.Revision = -1;
                }
                var savePromise = urlService.promisedPost(baseUrlsFactory.api_base_url + "AssetDto/saveOrUpdate", { model: assetDto });
                return savePromise.then(self.hideSavingDialog);
            };

            AssetDtoHelper.prototype.postSaveActions = function (postSaveAction, assetId) {
                switch (postSaveAction.type) {
                    case "urlChange":
                        $window.location.href = postSaveAction.url;
                        break;
                    case "preview":
                        $location.path("/viewer/" + assetId);
                        break;
                    case "callback":
                        postSaveAction.callback();
                }
            };

            AssetDtoHelper.prototype.showSavingDialog = function () {
                this.overlay.addClass("show-overlay");
            };

            AssetDtoHelper.prototype.hideSavingDialog = function () {
                var self = this;
                return $timeout(function () {
                    self.overlay.removeClass("show-overlay");
                }, TIMEOUT_CONSTANTS.SAVE_OVERLAY);
            };

            return AssetDtoHelper;
        })();

        return AssetDtoHelper;
    }
]);
