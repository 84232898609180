export enum MainType {
    ActivityLog = "ActivityLog",
    Blog = "Blog",
    CallingCard = "CallingCard",
    Collection = "AssetCollectionWrapper",
    Conversation = "Conversation",
    Cv = "CV",
    Page = "WebfolioPage",
    File = "File",
    FormResponse = "FormResponse",
    Portfolio = "Webfolio",
    Post = "Post",
    WorkBookResponse = "WorkBookResponse",
    WorkBook = "WorkBook",
    Form = "Form"
}
