import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";

export function setOpenedAndSelectFirstItem(_: KeyboardEvent): Partial<Immutable<MenuAriaModel>> {
    return {
        activeItemIndex: 0,
        isOpen: true,
        type: AriaMenuStatus.Open
    };
}
