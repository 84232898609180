import { FunctionComponent } from "react";
import { Notice } from "@pjs/core-ui";
import { IFeedbackNoticeProps } from "./interfaces/IFeedbackNoticeProps";
import "./styles/feedback-notice.css";

export const FeedbackNotice: FunctionComponent<IFeedbackNoticeProps> = (props) => {
    return (
        <div className="fbk-notice">
            <Notice ariaLabel={props.noticeAriaLabel} type={props.statusNotice.noticeType}>
                <span className="fbk-notice__content" data-hook="feedback-notice-content">
                    {props.statusNotice.statusText}
                </span>
            </Notice>
        </div>
    );
};
