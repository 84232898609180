import { IHtmlTagConfig } from "@pjs/security";

export const olConfig: IHtmlTagConfig = {
    attributes: null,
    styles: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "list-style-type": (value) => {
            if (/^(?:decimal|decimal-leading-zero|lower-roman|upper-roman|lower-latin|upper-latin)$/.test(value)) {
                return value;
            }

            if (value === "lower-alpha") {
                return "lower-latin";
            }

            if (value === "upper-alpha") {
                return "upper-latin";
            }

            return "";
        }
    }
};
