import { angularAMD } from "@pebblepad/amd";
import { WorkbookResponsePageHandler } from "./workbookResponsePageHandler";
import { PortfolioPageHandler } from "./portfolioPageHandler";

export class AssetPageHandlerFactory {
    constructor($q, assetEndpointService) {
        this._$q = $q;
        this._assetEndpointService = assetEndpointService;
    }

    createWorkbookResponseHandler(asset, assetContext) {
        return new WorkbookResponsePageHandler(assetContext, asset.Pages, this._$q, {
            procurePage: this._assetEndpointService.procureWorkbookResponsePage.bind(this._assetEndpointService),
            removePage: this._assetEndpointService.removeWorkbookResponsePage.bind(this._assetEndpointService),
            updatePage: this._assetEndpointService.updateWorkbookResponsePage.bind(this._assetEndpointService)
        });
    }

    createPortfolioPageHandler(asset, assetContext) {
        return new PortfolioPageHandler(assetContext, asset.Pages, this._$q, {
            procurePage: this._assetEndpointService.procureWorkbookResponsePage.bind(this._assetEndpointService),
            removePage: this._assetEndpointService.removeWorkbookResponsePage.bind(this._assetEndpointService),
            removePortfolioPage: this._assetEndpointService.removePortfolioPage.bind(this._assetEndpointService),
            updatePage: this._assetEndpointService.updateWorkbookResponsePage.bind(this._assetEndpointService),
            updatePortfolioPage: this._assetEndpointService.updatePortfolioPage.bind(this._assetEndpointService),
            convertPage: this._assetEndpointService.convertPortfolioPage.bind(this._assetEndpointService)
        });
    }
}

AssetPageHandlerFactory.$inject = ["$q", "AssetEndpointService"];
angularAMD.service("assetPageHandlerFactory", AssetPageHandlerFactory);
