import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";

angularAMD.directive("profileImgComp", [
    "$sce",
    "baseUrlsFactory",
    function ($sce, baseUrlsFactory) {
        return {
            restrict: "A",
            link: function (scope, el, attrs) {
                var unbindWatcher = scope.$watch(
                    function () {
                        return attrs.profileId;
                    },
                    function () {
                        if (attrs.profileId) {
                            if (attrs.profileId.indexOf("=") === -1) {
                                // tslint:disable-next-line:no-unsafe-src-attribute
                                el.attr("src", baseUrlsFactory.api_base_url + "file/TransmitProfilePic?id=" + attrs.profileId + "&x=" + attrs.size + "&y=" + attrs.size + "&shortSideScale=True");
                                if (!attrs.keepactive) {
                                    unbindWatcher();
                                }
                            } else {
                                // tslint:disable-next-line:no-unsafe-src-attribute
                                el.attr("src", attrs.profileId);
                                if (!attrs.keepactive) {
                                    unbindWatcher();
                                }
                            }
                        } else {
                            var urlString = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "profileImage/image/no_profile.svg").toString();
                            // tslint:disable-next-line:no-unsafe-src-attribute
                            el.attr("src", urlString);
                        }
                    }
                );
            }
        };
    }
]);
