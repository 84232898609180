import { angularAMD } from "@pebblepad/amd";

export class RecoveryLogger {
    constructor() {
        this._trail = [];
        this._id = "";
    }

    start(id, message) {
        this.reset();
        this._id = id;
        this.step("start", message);
        return this;
    }

    step(type, message) {
        if (this._id !== "") {
            this._trail.push(this._createTrailItem(type, message));
        }

        return this;
    }

    end(type, message) {
        if (this._id === "" || this._trail.length === 0) {
            return this;
        }

        this.step("end", message);
        this.reset();
        return this;
    }

    reset() {
        this._trail.length = 0;
        this._id = "";
        return this;
    }

    isInProgress() {
        return this._id !== "";
    }

    _createTrailItem(type, message) {
        return { type: type, message: message, timestamp: new Date().toJSON() };
    }
}

RecoveryLogger.$inject = [];
angularAMD.service("recoveryLogger", RecoveryLogger);
