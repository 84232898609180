import { angularAMD } from "@pebblepad/amd";
import "../../../multiLanguageService/multiLanguageService";
import "../../../react2angular/richTextView";
import template from "./feedback-text.html";

var feedbackTextDefinition = {
    bindings: {
        text: "<"
    },
    template: template,
    controller: feedbackTextComponent
};

feedbackTextComponent.$inject = ["multiLanguageService"];
function feedbackTextComponent(multiLanguageService) {
    this.multiLanguageService = multiLanguageService;
}

angularAMD.component("feedbackText", feedbackTextDefinition);
export { feedbackTextDefinition as feedbackText };
