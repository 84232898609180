export const mapCapabilityValueToLabel = (elementDto, value) => {
    if (elementDto.ElementType === "CapabilityBinaryAnswerInput") {
        if (value === "high") {
            return elementDto.PositiveLabel;
        } else if (value === "low") {
            return elementDto.NegativeLabel;
        } else {
            if (elementDto.Capability.AnswerHistory[0]) {
                if (elementDto.Capability.AnswerHistory[0].Answer === "high") {
                    return elementDto.PositiveLabel;
                } else if (elementDto.Capability.AnswerHistory[0].Answer === "low") {
                    return elementDto.NegativeLabel;
                }
            } else {
                return "";
            }
        }
    } else if (elementDto.ElementType === "CapabilityOptionList-RadioButtons") {
        if (typeof value === "string") {
            for (var i = 0; i < elementDto.Options.length; i++) {
                if (value === elementDto.Options[i].Id) {
                    return elementDto.Options[i].Label;
                }
            }
        } else {
            return "";
        }
    } else {
        return value;
    }
};
