import { filter } from "@pjs/observables";
import { IEditor } from "../../editor/interfaces/IEditor";
import { EditorEnabledState } from "../../editor/types/EditorEnabledState";

export function persistentEditorToolbar(editor: IEditor): () => void {
    const onEnabled = (enabledState: EditorEnabledState): void => {
        if (editor.toolbar === null || enabledState.editor === null) {
            return;
        }

        editor.toolbar.show();
    };

    const onEnableSubscription = editor.enabled.pipe(filter((state: EditorEnabledState) => state.isEnabled)).subscribe(onEnabled);

    return () => {
        onEnableSubscription.unsubscribe();
    };
}
