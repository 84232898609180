import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../assetEndpointService/assetEndpoint.service";

angularAMD.service("AssetLockingAPI", AssetLockingAPI);
AssetLockingAPI.$inject = ["$http", "$q", "baseUrlsFactory", "AssetEndpointService"];

function AssetLockingAPI($http, $q, baseUrlsFactory, assetEndpointService) {
    //Public API
    //=============================================================================================================
    this.lock = lock;
    this.unlock = unlock;
    this.lockedByAnother = lockedByAnother;
    this.keepLockAlive = keepLockAlive;

    //Private methods
    //=============================================================================================================
    /**
     * @param {string} assetId
     * @returns {Promise} - Rejected if HTTP fails
     */
    function lock(assetId) {
        return $http.get(baseUrlsFactory.api_base_url + "Locking/RequestLock?assetId=" + assetId).then(function (response) {
            return response.data;
        });
    }

    /**
     * @param {string} assetId
     * @returns {Promise} - Rejected if HTTP fails
     */
    function unlock(assetId) {
        return $http.get(baseUrlsFactory.api_base_url + "Locking/ReleaseLock?assetId=" + assetId).then(function (response) {
            return response.data;
        });
    }

    /**
     * @param assetId
     * @returns {Promise} - Rejected if HTTP fails
     */
    function lockedByAnother(assetId) {
        return assetEndpointService.isLockedByAnother(assetId).then(function (response) {
            return response.data;
        });
    }

    /**
     * @param {string} assetId
     * @returns {Promise} - Rejected if HTTP fails
     */
    function keepLockAlive(assetId) {
        return $http.get(baseUrlsFactory.api_base_url + "Locking/KeepLockAlive?assetId=" + assetId).then(function (response) {
            return response.data;
        });
    }
}
