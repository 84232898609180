import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { FILTERER_CONSTANTS } from "../constants/filterer.constants";
import template from "./feedback-search.html";
import "../searchBar/searchBar.component";
import "../feedbackFilters/feedbackFilters.component";
import "../multiLanguageService/multiLanguageService";
import "../builder/dataManager/dataManagerService";
import "../feedback/feedbackFilterer/feedbackFiltererCollection.service";

class FeedbackSearchComponent extends NgComponent {
    constructor($scope, multiLanguageService, $routeParams, $rootScope, dataManagerService, feedbackFiltererCollection) {
        super();

        this.multiLanguageService = multiLanguageService;
        this._$routeParams = $routeParams;
        this._$rootscope = $rootScope;
        this._dataManagerService = dataManagerService;
        this._filtererCollection = feedbackFiltererCollection;
        this.isFilterOpen = false;
        this.searchBarOptions = null;
        this.assetManager = null;
        this.ariaId = $scope.$id;
        this._dtoAddedHandler = null;

        this._filtererUnsubscribe = null;
        this.filterButtonTitle = "";
        this.sortButtonTitle = "";
        this.currentFilterCount = 0;
        this.filterer = null;
        this.activeFiltersList = [];
    }

    $onInit() {
        if (this.searchDisabled === undefined) {
            this.searchDisabled = false;
        }

        this.searchBarOptions = {
            buttonStyle: "light",
            dataHookName: "feedback",
            inputAria: this.multiLanguageService.getString("sidebar.asset_feedback.aria.keyword_search_input"),
            disabledPlaceholder: this.multiLanguageService.getString("search_bar.disabled_placeholder")
        };

        this.assetManager = this._dataManagerService.getDto(this._$routeParams.assetId);

        if (this.assetManager === undefined || this.assetManager === null) {
            this._dtoAddedHandler = this._$rootscope.$on("dtoAdded", (event, assetId) => {
                if (this._$routeParams.assetId === assetId) {
                    this.assetManager = this._dataManagerService.getDto(this._$routeParams.assetId);
                    this._initialiseFilterer();
                    this._dtoAddedHandler();
                }
            });
        } else {
            this._initialiseFilterer();
        }
    }

    $onChanges(changes) {
        if (changes.searchDisabled !== undefined && changes.searchDisabled.currentValue) {
            this.isFilterOpen = false;
        }

        if (changes.sortAscending !== undefined) {
            this.sortButtonTitle = this.sortAscending
                ? this.multiLanguageService.getString("search_bar.sort_options.sort_ascending")
                : this.multiLanguageService.getString("search_bar.sort_options.sort_descending");
        }
    }

    $onDestroy() {
        if (this._dtoAddedHandler !== null) {
            this._dtoAddedHandler();
        }

        if (this.filterer !== null) {
            this.filterer.resetFilter();
        }

        if (this._filtererUnsubscribe !== null) {
            this._filtererUnsubscribe();
            this._filtererCollection.remove(this.assetId);
        }
    }

    onSearchTextChange(searchText) {
        this.onSearch({
            searchText: searchText
        });
    }

    onSorting(ascending) {
        this.onSort({
            ascending: ascending
        });
    }

    toggleFilter() {
        this.isFilterOpen = !this.isFilterOpen;
    }

    resetFilterer() {
        this.filterer.resetFilter();
    }

    _initialiseFilterer() {
        this.filterer = this._filtererCollection.get(this.assetId);
        this._filtererUnsubscribe = this.filterer.subscribe(this._setActiveFilters.bind(this));
        this._setActiveFilters();
    }

    _setActiveFilters() {
        const activeFiltersList = [];
        const { excludeTypes, ...filters } = this.filterer.getActiveFilter();

        Object.entries(filters).forEach(([key, value]) => {
            if (value !== false && value !== undefined) {
                activeFiltersList.push(key);
            }
        });

        this.activeFiltersList = activeFiltersList;
        this.currentFilterCount = activeFiltersList.length;
        this.filterButtonTitle = this.multiLanguageService.getString("sidebar.asset_feedback.filter_options.feedback_filter", { count: this.currentFilterCount });
    }
}

const FeedbackSearch = {
    bindings: {
        onSearch: "&",
        sortAscending: "<",
        onSort: "&",
        assetId: "<",
        searchDisabled: "<?",
        filterForElement: "<",
        filterForElementText: "<"
    },
    template: template,
    controller: FeedbackSearchComponent
};

FeedbackSearch.$inject = ["$scope", "multiLanguageService", "$routeParams", "$rootScope", "dataManagerService", "feedbackFiltererCollection"];
angularAMD.component("feedbackSearch", FeedbackSearch);
export { FeedbackSearch as feedbackSearch };
