/**
 * @param {Array<string>} events
 * @constructor
 */
function EventEmitter(events) {
    this._eventMap = new Map(events.map((e) => [e, []]));
}

/**
 * Register listener for event
 * @param {String} eventName
 * @param {Function} callback
 */
EventEmitter.prototype.on = function (eventName, callback) {
    let events = this._eventMap.get(eventName);
    if (events === undefined) {
        events = [];
        this._eventMap.set(eventName, events);
    }

    events.push(callback);
};

EventEmitter.prototype.off = function (eventName, callback) {
    const events = this._eventMap.get(eventName);
    if (events === undefined) {
        return;
    }

    const index = events.findIndex((ref) => ref === callback);
    if (index !== -1) {
        events.splice(index, 1);
    }

    if (events.length === 0) {
        this._eventMap.delete(eventName);
    }
};

/**
 * Emit an event
 * @param {String} eventName
 * @param {any=} value
 */
EventEmitter.prototype.emit = function (eventName, value) {
    const events = this._eventMap.get(eventName);
    if (events === undefined) {
        return;
    }

    events.forEach((callback) => callback(value));
};

/**
 * Remove events
 * @param {Array} events : ["event", "anotherEvent"]
 */
EventEmitter.prototype.removeEvents = function (events) {
    events.forEach((eventName) => this._eventMap.delete(eventName));
};

export { EventEmitter };
