import { MouseEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { toggleIsOpenOnClick } from "./ToggleIsOpenOnClick.function";

export function toggleIsOpenAndResetIndexOnClick(_: MouseEvent, currentModel: Immutable<MenuAriaModel>): Partial<Immutable<MenuAriaModel>> {
    const newModel = { activeItemIndex: 0, ...toggleIsOpenOnClick(_, currentModel) };

    return newModel as Partial<Immutable<MenuAriaModel>>;
}
