import { createGuid } from "@pjs/utilities";
import { ISelectionMarkerRange } from "./interfaces/ISelectionMarkerRange";

export class PpMarkerRange implements ISelectionMarkerRange {
    public static readonly tagName: string = "ppmarker";
    public readonly start: HTMLElement;
    public readonly end: HTMLElement | null;

    constructor(hasEnd: boolean = false) {
        this.start = PpMarkerRange._createElement();
        this.end = hasEnd ? PpMarkerRange._createElement() : null;
    }

    private static _createElement(): HTMLElement {
        const element = document.createElement(PpMarkerRange.tagName);
        element.id = createGuid();
        return element;
    }
}
