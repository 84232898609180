import { angularAMD } from "@pebblepad/amd";

angularAMD.service("relationalPositioningService", function () {
    /**
     * Repositions elements to fit within a boundary and next to an existing relative element
     * @param target_el - Angular Element
     * @param linked_el - Angular Element
     * @param boundary_el - Angular Element, Optional
     * @param {Number} [buffer] - Optional
     * @param {Object} [clearance] - Optional
     * @returns {Object}
     */
    var RelationalPositioner = function (target_el, linked_el, boundary_el, buffer, clearance) {
        this.target_el = target_el;
        this.linked_el = linked_el[0];
        this.boundary_el = boundary_el[0] || document.body;
        this.target = this.target_el[0].getBoundingClientRect(); //top, left, bottom, right, width, height
        this.linked = this.linked_el.getBoundingClientRect();
        this.boundary = this.convertClientRectToObj(this.boundary_el.getBoundingClientRect());
        this.buffer = buffer || 0; //Space between linked_el and positioned target_el
        this.setToBoundaryWidth = false;

        //Space between sides of container (e.g. reduce available space the target_el can fit in)
        this.clearance = this.createPositionObject(clearance);

        //Pre-populate differences object for better browser optimisations
        this.differences = this.createPositionObject();

        this.lastSide = null;
    };

    RelationalPositioner.prototype.defaultPositioningOrder = ["left", "bottom", "right", "top"];

    RelationalPositioner.prototype.createPositionObject = function (obj) {
        var positionObj = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        };

        for (var property in obj) {
            if (obj.hasOwnProperty(property) && typeof positionObj[property] !== "undefined") {
                positionObj[property] = obj[property];
            }
        }

        return positionObj;
    };

    RelationalPositioner.prototype.convertClientRectToObj = function (rect) {
        return {
            top: rect.top,
            right: rect.right,
            bottom: rect.bottom,
            left: rect.left,
            width: rect.width,
            height: rect.height
        };
    };

    //Call update before checking any axis IF the target element has been changed from being hidden (display none) or scrolling has occurred.
    RelationalPositioner.prototype.update = function () {
        this.target = this.target_el[0].getBoundingClientRect();
        this.linked = this.linked_el.getBoundingClientRect();
        var boundary = this.boundary_el.getBoundingClientRect(),
            widthDiff = boundary.width - this.boundary_el.clientWidth; //Remove a vertical scrollbar from being in the available position

        this.boundary.top = boundary.top + this.clearance.top;
        this.boundary.right = boundary.right - widthDiff - this.clearance.right;
        this.boundary.bottom = boundary.bottom - this.clearance.top - this.clearance.bottom;
        this.boundary.left = boundary.left + this.clearance.left;
        this.boundary.width = boundary.width - widthDiff - this.clearance.left - this.clearance.right;
        this.boundary.height = boundary.height - this.clearance.top - this.clearance.bottom;

        this.setDifferences();
    };

    //Find the difference between all the sides (available space).
    RelationalPositioner.prototype.setDifferences = function () {
        this.differences.top = this.linked.top - this.boundary.top;
        this.differences.right = this.boundary.right - this.linked.right;
        this.differences.bottom = this.boundary.bottom - this.linked.bottom;
        this.differences.left = this.linked.left - this.boundary.left;
    };

    RelationalPositioner.prototype.isSpaceOnSide = function (side, dimension) {
        return this.differences[side] + this.buffer >= this.target[dimension];
    };

    RelationalPositioner.prototype.getAreaSize = function (side) {
        if (side === "left" || side === "right") {
            return {
                x: this.differences[side],
                y: this.differences.top + this.differences.bottom + this.linked.height
            };
        } else {
            return {
                x: this.differences.left + this.differences.right + this.linked.width,
                y: this.differences[side]
            };
        }
    };

    RelationalPositioner.prototype.alignOnAxisRelative = function (side) {
        var space = { x: -1, y: -1 },
            dimension = this.getDimensionFromSide(side);

        if (this.isSpaceOnSide(side, dimension) === true) {
            if (dimension === "width") {
                space.x = this.linked.width;
                space.y = this.getNewPositionOnAxis("top", "height");
            } else {
                space.y = this.linked.height;
                space.x = this.getNewPositionOnAxis("left", "width");
            }
        }
        return space;
    };

    RelationalPositioner.prototype.alignOnAxisAbsolute = function (side) {
        var space = { x: -1, y: -1 },
            dimension = this.getDimensionFromSide(side),
            offsetObj,
            operator = 1,
            scrollOffsetX = this.boundary_el.scrollLeft,
            scrollOffsetY = this.boundary_el.scrollTop;

        //Determine how much to offset the position (e.g. width of element or width of the linked element - left or right)
        if (side === "left" || side === "top") {
            offsetObj = this.target;
        } else {
            offsetObj = this.linked;
            operator = 0;
        }

        if (this.isSpaceOnSide(side, dimension) === true) {
            if (dimension === "width") {
                space.x = this.linked[side] - offsetObj.width * operator;
                space.y = this.linked.top + this.getNewPositionOnAxis("top", "height");
            } else {
                space.y = this.linked[side] - offsetObj.height * operator;
                space.x = this.linked.left + this.getNewPositionOnAxis("left", "width") + scrollOffsetX;
            }
        }
        return space;
    };

    RelationalPositioner.prototype.getNewPositionOnAxis = function (side, dimension) {
        var pos = -1;
        if (this.setToBoundaryWidth) {
            this.target.width = this.boundary.width;
        }
        if (this.boundary[dimension] - this.buffer >= this.target[dimension]) {
            //Centre if possible
            pos = this.linked[dimension] / 2 - this.target[dimension] / 2;
            var total = this.linked[side] - this.boundary[side] + Math.abs(pos);
            //Check if the sum total of the new position wanted is outside of the boundary
            if (total > this.boundary[dimension]) {
                pos -= total - this.boundary[dimension] + this.linked[dimension];
            } else if (this.linked[side] + pos < 0) {
                pos = this.boundary[side] - this.linked[side];
            }
        }
        return pos;
    };

    RelationalPositioner.prototype.getDimensionFromSide = function (side) {
        return side === "left" || side === "right" ? "width" : "height";
    };

    RelationalPositioner.prototype.getPositioningSides = function (side, mirrorSides) {
        if (mirrorSides === true) {
            return {
                x: side === "left" ? "right" : "left",
                y: side === "top" ? "bottom" : "top"
            };
        } else {
            return {
                x: "left",
                y: "top"
            };
        }
    };

    RelationalPositioner.prototype.setPos = function (pos, positioningSides, maxWidth, maxHeight) {
        var rules = {
            maxWidth: maxWidth + "px",
            maxHeight: maxHeight + "px"
        };

        //Do not like to add extra properties to an object after creation...though it is easier to read and saves on extra function calls.
        rules[positioningSides.x] = pos.x + "px";
        rules[positioningSides.y] = pos.y + "px";

        if (this.target.width > maxWidth || this.target.height > maxHeight) {
            rules.overflow = "scroll";
        }
        this.resetStyles();
        this.target_el.css(rules);

        return pos;
    };

    RelationalPositioner.prototype.resetStyles = function () {
        this.target_el[0].removeAttribute("style");
    };

    RelationalPositioner.prototype.fillAreaWidth = function (side, positioningAlignment) {
        this.update();
        var areaSize;
        if (side) {
            areaSize = this.getAreaSize(side);
        } else {
            var currentSide,
                largest = { x: -1, y: -1 };
            for (var i = 0, l = this.defaultPositioningOrder.length; i < l; i++) {
                currentSide = this.defaultPositioningOrder[i];
                areaSize = this.getAreaSize(currentSide);
                if (this.isLargerArea(areaSize, largest)) {
                    largest = areaSize;
                    side = currentSide;
                }
            }
            areaSize = largest;
        }
        this.target_el.css("width", areaSize.x + "px");
        this.lastSide = side;
    };

    RelationalPositioner.prototype.isLargerArea = function (areaSize, largest) {
        return areaSize.x + areaSize.y > largest.x + largest.y;
    };

    RelationalPositioner.prototype.findLargestSuitableSide = function (side, positioningAlignment) {
        var currentSide = "",
            largest = {
                side: "",
                area: side ? this.getAreaSize(side) : { x: -1, y: -1 },
                pos: null
            },
            newPos,
            areaSize;

        positioningAlignment = positioningAlignment !== undefined ? positioningAlignment : this.alignOnAxisRelative;

        for (var i = 0, l = this.defaultPositioningOrder.length; i < l; i++) {
            currentSide = this.defaultPositioningOrder[i];

            //No point in checking the same side again. Avoiding splicing the side out of the array as it is considerably slower.
            if (currentSide !== side) {
                newPos = positioningAlignment(currentSide);
                areaSize = this.getAreaSize(currentSide);

                //Keep track of the largest available space.
                if (this.isLargerArea(areaSize, largest.area)) {
                    largest.side = currentSide;
                    largest.area = areaSize;
                    largest.pos = newPos;
                }

                //Break as soon as finds a side that it will fit in
                if (newPos.x !== -1 && newPos.y !== -1) {
                    largest.side = currentSide;
                    largest.area = areaSize;
                    largest.pos = newPos;
                    this.lastSide = currentSide;
                    break;
                }
            }
        }
        return largest;
    };

    /**
     * Repositions elements to fit within a boundary
     * @param {String} [side] - Optional: top, right, bottom or left
     * @param {String} [positioningType] - Optional: relative or absolute
     * @returns {Object}
     */
    RelationalPositioner.prototype.fit = function (side, positioningType, setToBoundaryWidth) {
        this.setToBoundaryWidth = !!setToBoundaryWidth;
        side = side ? side : this.lastSide !== null ? this.lastSide : this.defaultPositioningOrder[0];
        positioningType = positioningType ? positioningType : "relative";
        var positioningAlignment,
            mirrorSides = true;

        if (positioningType === "absolute") {
            positioningAlignment = this.alignOnAxisAbsolute.bind(this);
            mirrorSides = false;
        } else {
            positioningAlignment = this.alignOnAxisRelative.bind(this);
        }

        //Update before trying to fit as scrolling or other page movement changes viewport / clientRect relative values
        this.update();

        var areaSize = this.getAreaSize(side),
            newPos = positioningAlignment(side);
        //If both x and y are not equal to -1, then it can fit on the desired side.
        if (newPos.x === -1 && newPos.y === -1) {
            var largest = this.findLargestSuitableSide(null, positioningAlignment);

            //In the event that it cannot fit on any of the sides, width and height must be adjusted
            if (largest.pos.x === -1 && largest.pos.y === -1) {
                this.target_el.css({
                    width: largest.area.x + "px"
                    //height: largest.area.y + 'px'
                });
                this.target = this.target_el[0].getBoundingClientRect();
                largest.pos = positioningAlignment(largest.side);
            }
            side = largest.side;
            newPos = largest.pos;
            areaSize = largest.area;
        }

        this.lastSide = side;
        this.target_el.addClass(positioningType);
        return this.setPos(newPos, this.getPositioningSides(side, mirrorSides), areaSize.x, areaSize.y);
    };

    return RelationalPositioner;
});
