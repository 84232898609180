import { IShortcut } from "./interfaces/IShortcut";
import { ShortcutKeys } from "./types/ShortcutKeys";
import { IShortcutGroup } from "./interfaces/IShortcutGroup";

export class ShortcutStack {
    private readonly _groupDescriptions: WeakMap<IShortcut, string> = new WeakMap();
    private readonly _allShortcuts: Array<IShortcut> = [];

    public addGroup(shortcuts: ReadonlyArray<IShortcut>, description: string): () => void {
        const lowerCaseShortcuts = shortcuts.map((shortcut) => {
            const newShortcut = {
                callback: shortcut.callback,
                description: shortcut.description,
                keys: shortcut.keys.map((key) => key.toLowerCase()) as ShortcutKeys
            };

            const isDuplicate = this._allShortcuts.some((existingShortcut) => this._doesKeysMatchShortcut(newShortcut.keys, existingShortcut));
            if (isDuplicate) {
                throw new Error(`The shortcut ${shortcut.keys} has a duplicate key.`);
            }

            this._allShortcuts.push(newShortcut);
            this._groupDescriptions.set(newShortcut, description);
            return newShortcut;
        });

        return () => this._removeGroup(lowerCaseShortcuts);
    }

    public getShortcut(keys: ReadonlyArray<string>): IShortcut | null {
        return this._allShortcuts.find((shortcut) => this._doesKeysMatchShortcut(keys, shortcut)) ?? null;
    }

    public getShortcutGroups(): Array<IShortcutGroup> {
        const groups: Record<string, IShortcutGroup> = {};

        for (const shortcut of this._allShortcuts) {
            const description = this._groupDescriptions.get(shortcut);
            if (description === undefined) {
                continue;
            }

            if (groups[description] === undefined) {
                groups[description] = {
                    description: description,
                    shortcuts: [shortcut]
                };
            } else {
                groups[description].shortcuts.push(shortcut);
            }
        }

        return Object.values(groups);
    }

    private _removeGroup(shortcuts: ReadonlyArray<IShortcut>): void {
        for (const shortcut of shortcuts) {
            const index = this._allShortcuts.indexOf(shortcut);
            if (index !== -1) {
                this._allShortcuts.splice(index, 1);
            }
        }
    }

    private _doesKeysMatchShortcut(keys: ReadonlyArray<string>, shortcut: IShortcut): boolean {
        if (keys.length === shortcut.keys.length) {
            return shortcut.keys.every((key) => keys.includes(key));
        }

        return false;
    }
}
