import { angularAMD } from "@pebblepad/amd";
import "./helpers";

CharacterLimit.$inject = ["helpers"];

function CharacterLimit(helpers) {
    return {
        restrict: "A",
        scope: {
            model: "=ngModel",
            max: "@?"
        },
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
            const maxLimit = isNaN(scope.max) || scope.max === "" ? Infinity : Number(scope.max);

            element.on("keydown", (event) => {
                if (event.key !== undefined && event.key.length === 1 && event.ctrlKey === false) {
                    if (element[0].textContent.length >= maxLimit) {
                        event.preventDefault();
                    }
                }
            });

            ngModel.$parsers.push((value) => {
                const cleanValue = helpers.htmlToPlain(value);

                if (cleanValue.length > maxLimit) {
                    if (scope.model.length !== maxLimit) {
                        const newValue = cleanValue.slice(0, maxLimit);
                        ngModel.$setViewValue(newValue);
                        ngModel.$render();
                        return newValue;
                    }

                    ngModel.$setViewValue(scope.model);
                    ngModel.$render();
                    return scope.model;
                }

                return cleanValue;
            });
        }
    };
}

angularAMD.directive("characterLimit", CharacterLimit);
export { CharacterLimit as characterLimit };
