import { createContext } from "react";
import { noop } from "@pjs/utilities";
import { ICollapsibleContextObject } from "./interfaces/ICollapsibleContextObject";

const defaultValue: ICollapsibleContextObject = {
    contentId: "",
    isOpen: true,
    onToggle: noop
};

export const CollapsibleContext = createContext(defaultValue);
