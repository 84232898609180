import { tracker } from "@pjs/analytics";
import { noop } from "@pjs/utilities";
import { SlowProcessReporter } from "../../../utils/slow-process-reporter/SlowProcessReporter";
import { Behaviour } from "../types/Behaviour";
import { generateElapsedTimeEventName } from "../../../utils/slow-process-reporter/GenerateElapsedTimeEventName.function";

export const slowPasteLogging: Behaviour = (editor) => {
    let nativePasteHandler = noop;

    editor.enabled.subscribe((enabledState) => {
        if (!enabledState.isEnabled) {
            editor.sourceElement.removeEventListener("paste", nativePasteHandler);
            return;
        }

        const ckeInstance = enabledState.editor;
        const reportSlowPaste = (elapsedTime: number): void => {
            tracker.trackEvent("CKE5 Input", "Paste", generateElapsedTimeEventName(elapsedTime), editor.sourceElement.querySelectorAll("*").length);
        };

        const reporter = new SlowProcessReporter(reportSlowPaste, 1000, 10000);
        ckeInstance.editing.view.document.on("paste", () => reporter.start(), { priority: "highest" });

        nativePasteHandler = () => reporter.stop();
        editor.sourceElement.addEventListener("paste", nativePasteHandler, { passive: true });
    });

    return noop;
};
