import { AbortablePromiseChain } from "./AbortablePromiseChain";

export class AbortableHttp {
    constructor($http, $q) {
        this._$http = $http;
        this._$q = $q;

        const abortableHttp = (config) => this._request(config);

        abortableHttp.get = (url, params) => this._requestWithParams("get", url, params);
        abortableHttp.post = (url, data, params) => this._requestWithParams("post", url, params, data);
        abortableHttp.put = (url, data, params) => this._requestWithParams("put", url, params, data);
        abortableHttp.patch = (url, data, params) => this._requestWithParams("patch", url, params, data);
        abortableHttp.delete = (url, params) => this._requestWithParams("delete", url, params);
        abortableHttp.jsonp = (url, params) => this._requestWithParams("jsonp", url, params);
        abortableHttp.head = (url, params) => this._requestWithParams("head", url, params);
        abortableHttp.defaults = this._$http.defaults;

        return abortableHttp;
    }

    _requestWithParams(method, url, params, data) {
        const config = {
            method: method,
            url: url,
            ...params,
            data: data
        };

        return this._request(config);
    }

    _request(config) {
        const deferred = this._$q.defer();
        const abortableConfig = { ...config, timeout: deferred.promise };
        const aborter = {
            _isAborted: false,
            isAborted() {
                return this._isAborted;
            },
            abort() {
                this._isAborted = true;
                deferred.resolve();
            }
        };

        if (config.timeout !== undefined && config.timeout.then !== undefined) {
            config.timeout.then(deferred.resolve);
        }

        const request = this._$http(abortableConfig).catch((rejection) => {
            if (!aborter.isAborted()) {
                throw rejection;
            }
        });

        return new AbortablePromiseChain(request, aborter);
    }
}
