import { tabbableSelector } from "../find-first-interactive-element/TabbableSelector.const";
import { isValidTabBoundaryElement } from "../find-first-interactive-element/IsValidTabBoundaryElement.function";
import { IInteractiveElementsList } from "./interface/IInteractiveElementsList";

export class InteractiveElementsList implements IInteractiveElementsList {
    private readonly _container: HTMLElement;
    private readonly _potentialElements: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this._container = container;
        this._potentialElements = container.querySelectorAll<HTMLElement>(tabbableSelector);
    }

    public first(): HTMLElement | null {
        for (const element of this._potentialElements) {
            if (isValidTabBoundaryElement(element, this._container)) {
                return element;
            }
        }

        return null;
    }

    public last(): HTMLElement | null {
        for (let i = this._potentialElements.length - 1; i >= 0; i--) {
            const element = this._potentialElements[i];

            if (isValidTabBoundaryElement(element, this._container)) {
                return element;
            }
        }

        return null;
    }

    public *[Symbol.iterator](): IterableIterator<HTMLElement> {
        for (const element of this._potentialElements) {
            if (isValidTabBoundaryElement(element, this._container)) {
                yield element;
            }
        }
    }
}
