import { angularAMD } from "@pebblepad/amd";
import template from "./templates/inline-confirm.html";
import "../autoFocus/autoFocus.directive";

var inlineConfirmDefinition = {
    bindings: {
        option: "<",
        onConfirm: "&",
        onUpdate: "&",
        active: "<?"
    },
    template: template,
    controller: InlineConfirm
};

InlineConfirm.$inject = ["$rootScope", "multiLanguageService", "$timeout"];

function InlineConfirm($rootScope, multiLanguageService, $timeout) {
    this.cancelLabel = multiLanguageService.getString("buttons.cancel");
    this.deleteConfirmLabel = multiLanguageService.getString("sidebar.asset_feedback.buttons.delete.confirm");
    this.active = false;
    this.$rootScope = $rootScope;
    this.isAnimating = false;
    this.$timeout = $timeout;
}

InlineConfirm.prototype.displayConfirmation = function () {
    this.active = true;
    this.isAnimating = true;

    this.$timeout(() => {
        this.isAnimating = false;
    }, 300);

    this.onUpdate({ option: this.option, confirming: true });
};

InlineConfirm.prototype.confirmAction = function () {
    this.active = false;
    this.isAnimating = false;
    this.onUpdate({ option: this.option, confirming: false });
    this.onConfirm();
};

angularAMD.component("inlineConfirm", inlineConfirmDefinition);

export { inlineConfirmDefinition as inlineConfirm };
