import { angularAMD } from "@pebblepad/amd";
import { CkEditorParagraphIndentCommand } from "./ckEditorParagraphIndentCommand";
import { CkEditorParagraphIndentProcess } from "./ckEditorParagraphIndentProcess";
import { CkEditorParagraphHangingIndentProcess } from "./ckEditorParagraphHangingIndentProcess";
import "../../ckEditorModule/pebbleCkEditor";

CkEditorParagraphIndentPlugin.$inject = ["multiLanguageService", "CkEditorService"];

function CkEditorParagraphIndentPlugin(multiLanguageService, ckEditor) {
    this._multiLanguageService = multiLanguageService;
    this._ckEditor = ckEditor;
    this._pluginName = "paragraphIndent";
    this._plugin = ckEditor.plugins.add(this._pluginName, {
        icons: "bgcolor",
        hidpi: true,
        requires: "panelbutton,floatpanel",
        init: this.createParagraphPanel.bind(this)
    });
}

CkEditorParagraphIndentPlugin.prototype.createParagraphPanel = function (editor) {
    var config = {
        name: this._pluginName,
        editor: editor,
        ckEditor: this._ckEditor,
        multiLanguageService: this._multiLanguageService
    };

    var panel = new CkEditorParagraphIndentPanel(config);
    editor.ui.add(this._pluginName, this._ckEditor.UI_PANELBUTTON, panel);
    return panel;
};

/**
 * @param config {{ name: string, editor: object, plugin: object, ckEditor: object, multiLanguageService: object }}
 * @constructor
 */
function CkEditorParagraphIndentPanel(config) {
    this._name = config.name;
    this._editor = config.editor;
    this._ckEditor = config.ckEditor;
    this._multiLanguageService = config.multiLanguageService;
    this._plugin = null;
    this._markers = {};
    this._toolActionIds = [];
    this._className = "paragraph-indent";
    this.options = this._createOptions();

    this.label = "paragraphIndent";
    this.title = this._multiLanguageService.getString("ckeditor.plugins.paragraph_indent.label");
    this.editorFocus = 0;
    this.panel = {
        css: this._ckEditor.skin.getPath("editor"),
        attributes: { role: "listbox", "aria-label": this.title }
    };

    this.allowedContent = "p div h1 h2 h3 h4 h5 h6 {text-indent,margin-left}";
    this.toolbar = "blocks,0";
    this.modes = { wysiwyg: 1 };
    this.onBlock = this._setupMenuHtml.bind(this);
    var onClose = this._close.bind(this);
    this.onClose = onClose;
    this.onHide = onClose;
    this._editor.on("destroy", this._cleanup.bind(this));
}

CkEditorParagraphIndentPanel.prototype._createOptions = function () {
    var indentAmount = this._ckEditor.config.indentOffset * 2;
    var indentUnit = this._ckEditor.config.indentUnit;
    var editor = this._editor;

    var indentProcess = new CkEditorParagraphIndentProcess(indentAmount, indentUnit);
    var hangingIndentProcess = new CkEditorParagraphHangingIndentProcess(indentAmount, indentUnit);

    var indentCommand = new CkEditorParagraphIndentCommand(editor, indentProcess, this._ckEditor);
    var hangingIndentCommand = new CkEditorParagraphIndentCommand(editor, hangingIndentProcess, this._ckEditor);
    var normalIndentCommand = {
        exec: function () {
            //Reuse hangingIndentProcess's remove style logic as it clears all indent types.
            hangingIndentCommand.exec(false);
        }
    };

    hangingIndentCommand.wrapExternalCommandFormatting("bulletedlist");
    hangingIndentCommand.wrapExternalCommandFormatting("numberedlist");
    var outdentCommand = editor.getCommand("outdent");

    if (outdentCommand !== undefined) {
        outdentCommand.on("exec", hangingIndentCommand.onOutdent.bind(hangingIndentCommand));
    }

    return [
        {
            label: this._multiLanguageService.getString("ckeditor.plugins.paragraph_indent.indent_label"),
            command: indentCommand
        },
        {
            label: this._multiLanguageService.getString("ckeditor.plugins.paragraph_indent.hanging_indent_label"),
            command: hangingIndentCommand
        },
        {
            label: this._multiLanguageService.getString("ckeditor.plugins.paragraph_indent.normal"),
            command: normalIndentCommand
        }
    ];
};

CkEditorParagraphIndentPanel.prototype._setupMenuHtml = function (panel, block) {
    var html = this.options.map(
        function (option) {
            //CKEditor Menu HTML is wrapped in an Iframe, in order to call a function declared elsewhere, CKEDITOR.tools.addFunction has to be called.
            var actionId = this._ckEditor.tools.addFunction(option.command.exec.bind(option.command));
            this._toolActionIds.push(actionId);

            //CKEditor does not support buttons as 'panel options'. It requires an anchor with a javascript href and onclick CKEditor action. :disgust:
            return (
                "<a class='cke_button__" +
                this._className +
                "' " +
                "_cke_focus='1'" +
                "href='javascript:void();'" +
                "onclick='CKEDITOR.tools.callFunction(" +
                actionId +
                "); return false;'>" +
                option.label +
                "</a>"
            );
        }.bind(this)
    );

    block.element.setHtml(html.join(""));
    block.element.removeAttribute("title");
    block.element.addClass(this._className);

    //CKEditor has a built-in focus and keystroke manager, but we have to explicitly set keys with an action type.
    var keys = block.keys;
    keys[9] = "next"; // TAB
    keys[39] = "next"; // ARROW-RIGHT
    keys[40] = "next"; // ARROW-DOWN
    keys[37] = "prev"; // ARROW-LEFT
    keys[38] = "prev"; // ARROW-UP
    keys[this._ckEditor.SHIFT + 9] = "prev"; // SHIFT + TAB
    keys[32] = "click"; // SPACE

    block.autoSize = true;
};

CkEditorParagraphIndentPanel.prototype._close = function () {
    this._getPlugin().setState(this._ckEditor.TRISTATE_OFF);
    this._ckEditor.dom.element.clearAllMarkers(this._markers);
};

CkEditorParagraphIndentPanel.prototype._getPlugin = function () {
    if (this._plugin !== null) {
        return this._plugin;
    }

    this._plugin = this._editor.ui.get(this._name);
    return this._plugin;
};

CkEditorParagraphIndentPanel.prototype._cleanup = function () {
    for (var i = 0; i < this._toolActionIds.length; i++) {
        this._ckEditor.tools.removeFunction(this._toolActionIds[i]);
    }

    this._toolActionIds.length = 0;
    this._editor = null;
};

angularAMD.service("CkEditorParagraphIndentPlugin", CkEditorParagraphIndentPlugin);

export { CkEditorParagraphIndentPlugin };
