import { angularAMD } from "@pebblepad/amd";
import { RollbackInterceptor } from "./RollbackInterceptor";

export class RollbackInterceptorProvider {
    constructor() {
        this.$get = ["$injector", "$q", ($injector, $q) => new RollbackInterceptor($injector, $q)];
    }
}
RollbackInterceptorProvider.serviceName = "rollbackInterceptor";

angularAMD.provider(RollbackInterceptorProvider.serviceName, RollbackInterceptorProvider);
