import { PANEL_CONSTANTS } from "@pjs/components/constants/panel.constants";
import { ASSESSMENT_CONSTANTS } from "@pjs/components/constants/assessment.constants";

export class FeedbackSidebarService {
    constructor($rootScope, feedbackFiltererCollection, multiLanguageService, $q) {
        this._$rootScope = $rootScope;
        this._feedbackFiltererCollection = feedbackFiltererCollection;
        this._multiLanguageService = multiLanguageService;
        this._$q = $q;
    }

    openSidebar(assetId, fromAssessorField) {
        this._$rootScope.$broadcast("openRightHandSidePanel", {
            panel: PANEL_CONSTANTS.PANEL.ASSET_FEEDBACK,
            fromAssessorField: fromAssessorField,
            assetId: assetId
        });
        return this._$q.resolve();
    }

    displayApprovalFeedback(elementId, panelData, assetId, topLevelAssetId, onPanelClose = null) {
        const data = {
            elementId: elementId,
            assetId: assetId,
            topLevelAssetId: topLevelAssetId,
            panelData: panelData,
            excludeTypes: [ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.COMMENT, ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD],
            label: this._multiLanguageService.getString("sidebar.asset_feedback.filter_options.approvals.showing_for", { title: panelData.elementTitle })
        };

        this._openElementFeedbackSidebar(data, onPanelClose);
    }

    displayBlockFeedback(elementId, panelData, assetId, topLevelAssetId, onPanelClose = null) {
        const data = {
            elementId: elementId,
            assetId: assetId,
            topLevelAssetId: topLevelAssetId,
            panelData: panelData,
            excludeTypes: [ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.CAPABILITY, ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD],
            label: this._multiLanguageService.getString("sidebar.asset_feedback.filter_options.block_feedback.showing_for", { title: panelData.elementTitle })
        };

        this._openElementFeedbackSidebar(data, onPanelClose);
    }

    _openElementFeedbackSidebar(data, onPanelClose) {
        const filterer = this._feedbackFiltererCollection.get(data.assetId);

        if (filterer !== null) {
            filterer.setActiveFilter({
                excludeTypes: data.excludeTypes,
                forElement: {
                    anchorId: data.elementId,
                    assetId: data.assetId,
                    label: data.label
                }
            });
        }

        this._$rootScope.$broadcast("openRightHandSidePanel", {
            anchorId: data.elementId,
            assetId: data.assetId,
            panel: PANEL_CONSTANTS.PANEL.ELEMENT_FEEDBACK,
            panelData: data.panelData,
            topLevelAssetId: data.topLevelAssetId,
            onPanelClose: onPanelClose
        });
    }
}
