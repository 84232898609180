import { ButtonClass, DialogSize, launchDialog } from "@pjs/core-ui";

export const launchPurgeDialog = (multiLanguageService, onConfirm, numItems, hasLinkedAssets, cachedTrigger) => {
    let dialogTitle;
    let dialogDescription;

    if (hasLinkedAssets) {
        dialogDescription = multiLanguageService.getString("asset_store.confirmations.dialogs.purge_linked_description", { count: numItems });
        dialogTitle = multiLanguageService.getString("asset_store.confirmations.dialogs.purge_linked_title", { count: numItems });
    } else {
        dialogTitle = multiLanguageService.getString("asset_store.confirmations.dialogs.purge_title", { count: numItems });
        dialogDescription = multiLanguageService.getString("asset_store.confirmations.dialogs.purge_description", { count: numItems });
    }

    const config = {
        size: DialogSize.Small,
        initialModel: { htmlContent: dialogDescription },
        dismissAction: (_model, instance) => {
            instance.close(cachedTrigger);
        },
        component: () => import("@pjs/core-ui").then((module) => module.SimpleHtmlDialogContent),
        title: dialogTitle,
        actions: [
            {
                label: multiLanguageService.getString("buttons.cancel"),
                ariaLabel: multiLanguageService.getString("buttons.cancel"),
                buttonClass: ButtonClass.Outlined,
                dataHook: "modal-cancel-button",
                disabled: () => false,
                action: (_model, instance) => {
                    instance.close(cachedTrigger);
                }
            },
            {
                label: multiLanguageService.getString("asset_store.confirmations.dialogs.delete_permanently"),
                ariaLabel: multiLanguageService.getString("asset_store.confirmations.dialogs.delete_permanently"),
                buttonClass: ButtonClass.Contained,
                dataHook: "asset-action-modal-confirm",
                disabled: () => false,
                action: (_model, instance) => {
                    instance.close();
                    onConfirm(instance);
                }
            }
        ]
    };

    launchDialog(config);
};
