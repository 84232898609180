import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("CompileHelperFactory", [
    "$compile",
    function ($compile) {
        var CompileHelper = (function () {
            /**
             * @param {$scope} [scope]
             * @param {Element} [element] - Angular Element
             * @param {Boolean} [autoClean]
             */
            function CompileHelper(scope, element, autoClean) {
                this.scope = scope;
                this.element = element;
                this.lastCreatedScope = null;

                if (autoClean === true) {
                    this.scope.$on("$destroy", this.reset.bind(this));
                }
            }

            /**
             * @param {string} html - HTML / Directive to be complied.
             * @param {Object} [scopeValues]
             * @param {Boolean} [preventLastScopeCleanUp]
             * @param {Boolean} [cleanupImmediatelyOnElementDestroy] - When true the scope will be automatically be cleaned up when the element is destroyed.
             * @param {Boolean} [emptyElement] - True
             */
            CompileHelper.prototype.compile = function (html, scopeValues, preventLastScopeCleanUp, cleanupImmediatelyOnElementDestroy, emptyElement) {
                if (!preventLastScopeCleanUp) {
                    this.cleanUpLastCompile();
                }

                var childScope = this.scope.$new(true, this.scope);
                if (scopeValues) {
                    for (var key in scopeValues) {
                        if (scopeValues.hasOwnProperty(key) === true) {
                            childScope[key] = scopeValues[key];
                        }
                    }
                }

                var compiledElem = this.compileWithScope(html, childScope, emptyElement !== false);

                if (cleanupImmediatelyOnElementDestroy) {
                    compiledElem.on("$destroy", this.cleanUpLastCompile.bind(this));
                }

                this.lastCreatedScope = childScope;

                return compiledElem;
            };

            /**
             * @param {string} html - HTML / Directive to be complied.
             * @param {Object} scope
             * @param {Boolean} [empty] - empty before appending new compiled element.
             * @returns {Element}
             */
            CompileHelper.prototype.compileWithScope = function (html, scope, empty) {
                if (empty) {
                    this.element.empty();
                }
                var compileElem = $compile(html)(scope);

                // tslint:disable-next-line:no-unsafe-jq-lite
                this.element.append(compileElem);

                return compileElem;
            };

            CompileHelper.prototype.setElement = function (element) {
                this.element = element;
            };

            CompileHelper.prototype.cleanUpLastCompile = function () {
                if (this.lastCreatedScope) {
                    this.lastCreatedScope.$destroy();
                    this.lastCreatedScope = null;
                }
            };

            CompileHelper.prototype.reset = function () {
                this.scope = null;
                this.element = null;
            };

            return CompileHelper;
        })();

        return CompileHelper;
    }
]);
