import { angularAMD } from "@pebblepad/amd";
import "../../../user/user.service";

DeletableShareService.$inject = ["User"];
function DeletableShareService(User) {
    this._user = User.getDto();
}

DeletableShareService.prototype.getNonDeletableShareIds = function (shares, collaborators) {
    if (this._user === null) {
        return [];
    }

    var collabAcceptedByIds = collaborators.filter(this._otherCollaborators.bind(this)).map(this._extractId.bind(this));

    var shareIds = shares.filter(this._acceptedCollaborativeShares.bind(this, collabAcceptedByIds)).map(function (s) {
        return s.ShareInfo.Id;
    });

    return shareIds;
};

DeletableShareService.prototype._otherCollaborators = function (collaborator) {
    return collaborator.Id !== this._user.UserId;
};

DeletableShareService.prototype._extractId = function (obj) {
    return obj.Id;
};

DeletableShareService.prototype._acceptedCollaborativeShares = function (collaboratorIds, share) {
    return (
        share.ShareInfo.Collaborative &&
        share.ShareInfo.AllSharees.some(
            function (s) {
                return collaboratorIds.includes(s) || s === this._user.UserId;
            }.bind(this)
        )
    );
};

angularAMD.service("DeletableShareService", DeletableShareService);

export { DeletableShareService };
