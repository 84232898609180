import { DecoupledEditor } from "@pebblepad/ckeditor";
import { Debouncer, IDebouncer } from "@pjs/utilities";
import { tracker } from "@pjs/analytics";
import { SlowProcessReporter } from "../../utils/slow-process-reporter/SlowProcessReporter";
import { generateElapsedTimeEventName } from "../../utils/slow-process-reporter/GenerateElapsedTimeEventName.function";
import { IEditorDataHandler } from "./interfaces/IEditorDataHandler";

export class CKEditorDataHandler implements IEditorDataHandler {
    public static readonly debounceTime: number = 300;
    private static readonly _blurOptions: { capture: true; passive: true } = { capture: true, passive: true };
    private readonly _ckeInstance: DecoupledEditor;
    private readonly _debouncer: IDebouncer;
    private readonly _onChange: (value: string) => void;
    private readonly _onBlur: () => void;
    private readonly _slowWriteReporter: SlowProcessReporter;
    private readonly _slowReadReporter: SlowProcessReporter;
    private _data: string;

    constructor(existingData: string, ckeInstance: DecoupledEditor, onChange: (value: string) => void) {
        this._data = existingData;
        this._ckeInstance = ckeInstance;
        this._onChange = onChange;
        this._debouncer = new Debouncer(CKEditorDataHandler.debounceTime, () => this._updateData());
        this._onBlur = () => this._debouncer.flush();
        this._slowWriteReporter = new SlowProcessReporter(this._logSlowUpdate.bind(this, "Writing data slowly"), 500, 10000);
        this._slowReadReporter = new SlowProcessReporter(this._logSlowUpdate.bind(this, "Reading data slowly"), 400, 10000);

        this._ckeInstance.model.document.on("change:data", this._debouncer.run);
        document.addEventListener("blur", this._onBlur, CKEditorDataHandler._blurOptions);
    }

    public getData(): string {
        return this._data;
    }

    public setData(data: string): void {
        this._debouncer.cancel();
        this._data = data;

        this._slowWriteReporter.start();

        this._ckeInstance.model.document.off("change:data", this._debouncer.run);
        this._ckeInstance.setData(data);
        this._ckeInstance.model.document.on("change:data", this._debouncer.run);

        this._slowWriteReporter.stop();
    }

    public destroy(): void {
        this._debouncer.flush();
        document.removeEventListener("blur", this._onBlur, CKEditorDataHandler._blurOptions);
    }

    private _updateData(): void {
        this._slowReadReporter.start();

        const data = this._ckeInstance.getData({ trim: "none" });
        this._data = data;

        this._slowReadReporter.stop();
        this._onChange(data);
    }

    private _logSlowUpdate(actionName: string, elapsedTime: number): void {
        tracker.trackEvent("CKE5 Input", actionName, generateElapsedTimeEventName(elapsedTime), this._ckeInstance.sourceElement?.querySelectorAll("*").length);
    }
}
