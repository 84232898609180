import { angularAMD } from "@pebblepad/amd";
import "../directives/spinnerOverlay";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/baseUrlsFactory";
import { TIMEOUT_CONSTANTS } from "../../constants/timeout.constants";

angularAMD.service("overlayFactory", [
    "$compile",
    "$sce",
    "$timeout",
    "multiLanguageService",
    "baseUrlsFactory",
    function ($compile, $sce, $timeout, multiLanguageService, baseUrlsFactory) {
        function SpinnerOverlay(element, options) {
            this.element = element;
            this.options = options;
        }

        SpinnerOverlay.prototype.updateStage = function (hideSpinner) {
            hideSpinner = hideSpinner === undefined ? true : hideSpinner;
            var self = this;
            $timeout(function () {
                self.element.scope().displaySpinner(hideSpinner);
            });

            return this;
        };

        SpinnerOverlay.prototype.selfDestruct = function () {
            this.options = null;
            this.element.scope().$destroy();
            this.element.remove();
            this.element = null;

            return this;
        };

        var SaveOverlay = {
            element: angular.element(document.getElementsByClassName("builder-canvas-dark-layer")[0]),
            showTimer: null,
            show: function (delay) {
                this._clearTimeout();

                this.showTimer = setTimeout(
                    function () {
                        this.element.addClass("show-overlay");
                    }.bind(this),
                    delay
                );
            },
            isOverlayVisible: function () {
                return this.element.hasClass("show-overlay") || this.showTimer !== null;
            },
            update: function () {
                this.element.addClass("save-complete");
            },
            hide: function () {
                this._clearTimeout();

                this.element.removeClass("save-complete");
                this.element.removeClass("show-overlay");
            },
            updateThenHide: function (delay) {
                this._clearTimeout();
                this.update();

                return $timeout(
                    function () {
                        this.hide();
                    }.bind(this),
                    delay || TIMEOUT_CONSTANTS.SAVE_OVERLAY
                );
            },
            _clearTimeout: function () {
                clearTimeout(this.showTimer);
                this.showTimer = null;
            }
        };

        var OverlayManager = (function () {
            function OverlayManager() {
                this.overlayHolder = angular.element(document.getElementById("overlayHolder"));
                this.activeOverlay = null;
                this.defaultHideTimerMs = 1000;
            }

            OverlayManager.prototype.displayClass = "show-overlay";

            OverlayManager.prototype.saveOverlay = SaveOverlay;

            /**
             * @param {Scope} scope
             * @param {String} spinnerTemplate - Relative Template Path
             * @param {String} onCompleteIcon - Relative Image Path
             * @param {String} onCompleteText
             * @param {Boolean} autoShow
             * @returns {OverlayManager}
             */
            OverlayManager.prototype.createSpinnerOverlay = function (scope, spinnerTemplate, onCompleteIcon, onCompleteText, autoShow) {
                var overlayOpts = {
                    template: $sce.getTrustedResourceUrl(spinnerTemplate),
                    icon: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + onCompleteIcon),
                    text: onCompleteText
                };

                var spinnerScope = scope.$new(true);
                spinnerScope.options = overlayOpts;
                spinnerScope.multiLanguageService = multiLanguageService;

                var spinnerElement = $compile("<spinner-overlay></spinner-overlay>")(spinnerScope);
                spinnerElement.on("$destroy", function () {
                    spinnerScope.$destroy();
                });

                if (scope.$root && !scope.$root.$$phase && !scope.$$phase) {
                    scope.$apply();
                }

                var overlay = new SpinnerOverlay(spinnerElement, overlayOpts);

                if (autoShow) {
                    this.setActiveOverlay(overlay);
                    this.show();
                }
                return overlay;
            };

            /**
             * @param {Object} overlay - Interface: {element: Angular Element, updateStage: Function }. Heck we could pass a modal in :P
             * @returns {OverlayManager}
             */
            OverlayManager.prototype.setActiveOverlay = function (overlay) {
                this.detachActiveOverlay();
                this.activeOverlay = overlay;
                // tslint:disable-next-line:no-unsafe-jq-lite
                this.overlayHolder.append(overlay.element);

                return this;
            };

            OverlayManager.prototype.detachActiveOverlay = function () {
                if (this.activeOverlay && this.activeOverlay.element) {
                    this.activeOverlay.element.detach();
                    this.activeOverlay = null;
                }

                return this;
            };

            OverlayManager.prototype.show = function () {
                this.overlayHolder.addClass(this.displayClass);

                return this;
            };

            /**
             * @param {Number} delay
             * @returns Promise
             */
            OverlayManager.prototype.updateThenHide = function (delay) {
                return this.actionThenHide(this.activeOverlay.updateStage.bind(this.activeOverlay), delay);
            };

            /**
             * @param {Function} callback
             * @param {Number} delay
             * @returns Promise
             */
            OverlayManager.prototype.actionThenHide = function (callback, delay) {
                delay = delay || 1000;
                callback();

                var self = this;
                return $timeout(function () {
                    self.hide();
                }, delay);
            };

            OverlayManager.prototype.hide = function () {
                this.overlayHolder.removeClass(this.displayClass);

                return this;
            };

            OverlayManager.prototype.createPromisedOverlay = function (config) {
                this.createSpinnerOverlay(config.scope, config.iconText || null, config.template || null, config.successMessageText || null, config.autoShow);
                return config.promise.finally(
                    function () {
                        this.updateThenHide(config.hideInMs || this.defaultHideTimerMs).then(
                            function () {
                                this.detachActiveOverlay();
                            }.bind(this)
                        );
                    }.bind(this)
                );
            };

            return OverlayManager;
        })();

        return new OverlayManager();
    }
]);
