import { INumberFormatter } from "./interfaces/INumberFormatter";

export class NumberFormatter implements INumberFormatter {
    private readonly _numberFormatter: Intl.NumberFormat;
    private _groupSeparator: RegExp | null = null;
    private _decimal: string = "";
    private _decimalCheck: RegExp | null = null;

    constructor(languageCode: string) {
        this._numberFormatter = new Intl.NumberFormat(languageCode);
        this._setSeparators();
    }

    public formatNumber(value: number): string {
        return this._numberFormatter.format(value);
    }

    /**
     * Warning: Languages that don't make use of western digits are not currently supported!
     */
    public parseNumber(value: string): number {
        if (this._decimalCheck !== null && this._decimalCheck.test(value)) {
            return NaN;
        }

        let newValue = this._groupSeparator !== null ? value.replace(this._groupSeparator, "") : value;
        newValue = newValue.replace(this._decimal, ".");
        return Number(newValue);
    }

    private _setSeparators(): void {
        const parts = this._numberFormatter.formatToParts(12345678910.42);
        let groupSeparator = "";

        for (const part of parts) {
            if (part.type === "group" && this._groupSeparator === null) {
                groupSeparator = part.value.replace(/\s/g, "s");
                this._groupSeparator = new RegExp(`\\${groupSeparator}(?!\\${groupSeparator})`, "g");
            } else if (part.type === "decimal" && this._decimal === "") {
                this._decimal = part.value;
                this._decimalCheck = new RegExp(`(?:\\${part.value}(?=\\${groupSeparator}))|(?:\\${groupSeparator}\\${part.value})(?![\\${part.value}\\${groupSeparator}])`);
                break;
            }
        }
    }
}
