import { angularAMD } from "@pebblepad/amd";
import "../../utilities/typeCheck.service";

angularAMD.service("wordCountHelper", WordCountHelper);
WordCountHelper.$inject = ["typeCheck", "helpers"];

function WordCountHelper(typeCheck, helpers) {
    // Public API
    // =============================================================================================================
    this.wordCountIsInvalid = wordCountIsInvalid;
    this.getWordCount = getWordCount;

    // Private variables
    var newLineTags = ["div", "p", "br", "li", "h1", "h2", "h3", "h4", "h5", "h6"];
    var wordMatch = /(https?:\/\/[a-z\d.\/\-#?=&%]+)|([\w.-]+@[\w.]+)|(\w)+['\-(]*\w*/g;

    // Private methods
    // =============================================================================================================
    function wordCountIsInvalid(wordCount, minWords, maxWords) {
        if (maxWords === 0) {
            return wordCount < minWords;
        } else {
            return wordCount < minWords || wordCount > maxWords;
        }
    }

    function secureWhiteSpace(content) {
        var regex,
            regexStr = "";

        for (var i = 0, l = newLineTags.length; i < l; i++) {
            regexStr = "<" + newLineTags[i] + ">";
            regex = new RegExp(regexStr, "g");
            content = content.replace(regex, "\n" + regexStr);
        }

        return content;
    }

    function countWords(content) {
        content = content.match(wordMatch);
        if (content !== null) {
            return content.length;
        }

        return 0;
    }

    function getWordCount(content) {
        if (!content) {
            return 0;
        }

        content = secureWhiteSpace(content);
        return countWords(helpers.htmlToPlain(content));
    }
}
