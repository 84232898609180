import { angularAMD } from "@pebblepad/amd";
import "../utilities/helpers";
import "../utilities/globalEvents";
import "./uberDropdownHelper";
import "../userAgent/userAgent.service";

angularAMD.directive("uberDropdown", [
    "$timeout",
    "$q",
    "helpers",
    "globalEvents",
    "uberDropdownHelper",
    "UserAgentService",
    function ($timeout, $q, helpers, globalEvents, dropdownHelper, UserAgentService) {
        return {
            restrict: "A",
            scope: true,

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    var globalReferences = {};
                    $scope.isActive = false;

                    $scope.presetObligatoryData = function () {
                        $scope.dropdownDeferred = $q.defer();
                    };

                    $scope.presetData = function () {
                        globalReferences.documentEl = angular.element(document.documentElement);
                        $scope.relativeToEl = false;
                    };

                    $scope.mapAttrs = function () {
                        $scope.onDropdownOpenFn = $attrs.onDropdownOpen ? $attrs.onDropdownOpen : false;
                        $scope.onDropdownCloseFn = $attrs.onDropdownClose ? $attrs.onDropdownClose : false;
                        $scope.inheritWidth = $attrs.inheritWidth !== undefined;
                        $scope.displayOnResolve = $attrs.displayOnResolve !== undefined;
                        $scope.minimumVerticalSpace = $attrs.minimumVerticalSpace !== void 0 ? parseFloat($attrs.minimumVerticalSpace) : null;
                    };

                    $scope.cacheElems = function () {
                        $scope.dropdownHeaderEl = angular.element($element[0].getElementsByClassName("dropdown__header")[0]);
                        $scope.dropdownBodyEl = angular.element($element[0].getElementsByClassName("dropdown__body")[0]);
                        $scope.dropdownBodyInnerEl = angular.element($element[0].getElementsByClassName("dropdown__body-inner")[0]);
                    };

                    $scope.resolveDropdown = function (autoFocus) {
                        if ($scope.displayOnResolve && $scope.onResolveDropdown) {
                            $scope.onResolveDropdown(autoFocus);
                        }
                        $scope.dropdownDeferred.resolve();
                    };

                    $scope.toggleDropdown = function () {
                        if ($scope.isActive) {
                            $scope.closeDropdown();
                        } else {
                            if (UserAgentService.touch && $scope.activeElementIsInput) {
                                $timeout($scope.openDropdown, 400);
                            } else {
                                $scope.openDropdown();
                            }
                        }
                    };

                    $scope.openDropdown = function () {
                        if ($scope.displayOnResolve && !$scope.onResolveDropdown) {
                            $scope.onResolveDropdown = $scope.openDropdownCore;
                        }

                        $scope.runOnDropdownOpenFn();

                        if (!$scope.displayOnResolve) {
                            $scope.openDropdownCore();
                        }
                    };

                    $scope.openDropdownCore = function (autoFocus) {
                        $scope.relativeToEl = dropdownHelper.getRequestedItem($scope.relativeToAttr, $element[0], $scope.relativeToEl, $scope.dropdownBodyEl);
                        $scope.limitationBoxEl = dropdownHelper.getRequestedItem($scope.limitationBoxAttr, $element[0], $scope.limitationBoxEl);
                        $scope.behaveRelativeToEl = dropdownHelper.getRequestedItem($scope.behaveRelativeToAttr, $element[0], $scope.behaveRelativeToEl);
                        $scope.hasPosFixedInTransformBug = dropdownHelper.checkPosFixedInTransformBug($scope.dropdownHeaderEl[0], $scope.hasPosFixedInTransformBug);

                        $scope.bindUIEvents();
                        $element.addClass("active");
                        if ($scope.relativeToEl) {
                            $scope.dropdownBodyEl.addClass("active");
                        }

                        if ($scope.inheritWidth) {
                            dropdownHelper.setDropdownBodyWidth($scope.dropdownHeaderEl, $scope.dropdownBodyEl);
                        }

                        var triggerAdjustPosition = function () {
                            dropdownHelper.adjustPosition(
                                $element,
                                $scope.dropdownHeaderEl,
                                $scope.dropdownBodyEl,
                                $scope.dropdownBodyInnerEl,
                                $scope.relativeToEl,
                                $scope.limitationBoxEl,
                                $scope.hasPosFixedInTransformBug,
                                $scope.minimumVerticalSpace
                            );

                            if (autoFocus === void 0 || autoFocus === true) {
                                $timeout(function () {
                                    $scope.accessibilityControls.first();
                                });
                            }
                        };

                        triggerAdjustPosition();
                        // run again in case bodyElement wasn't ready and been waiting for promise to resolve
                        if ($scope.dropdownDeferred.promise.$$state.status === 0) {
                            $scope.dropdownDeferred.promise.then(function () {
                                triggerAdjustPosition();
                            });
                        }

                        $scope.isActive = true;
                        globalEvents.onWindowResize.addFn($scope.closeDropdown);
                    };

                    $scope.closeDropdown = function () {
                        $scope.unbindUIEvents();
                        $element.removeClass("active");
                        $scope.isActive = false;
                        globalEvents.onWindowResize.removeFn($scope.closeDropdown);
                        $scope.runOnDropdownCloseFn();

                        if ($scope.relativeToEl) {
                            $scope.dropdownBodyEl.removeClass("active");
                        }
                    };

                    $scope.elementIsInput = function (element) {
                        if (element) {
                            if (
                                element.getAttribute("contenteditable") === "true" ||
                                element.getAttribute("contenteditable") === "" ||
                                element.tagName.toLowerCase() === "input" ||
                                element.tagName.toLowerCase() === "textarea"
                            ) {
                                return true;
                            }
                        }

                        return false;
                    };

                    $scope.findActiveElement = function () {
                        $scope.activeElementIsInput = $scope.elementIsInput(document.activeElement);
                    };

                    $scope.runOnDropdownOpenFn = function () {
                        if ($scope.onDropdownOpenFn) {
                            $scope.$eval($scope.onDropdownOpenFn);
                        }
                    };

                    $scope.runOnDropdownCloseFn = function () {
                        if ($scope.onDropdownCloseFn) {
                            $scope.$eval($scope.onDropdownCloseFn);
                        }
                    };

                    // Function for running ng-click events manually, when user using keyboard
                    $scope.runOnNgClickFn = function () {
                        $scope.onNgClickFn = $scope.dropdownHeaderEl[0].getAttribute("ng-click");

                        if ($scope.onNgClickFn) {
                            $scope.$eval($scope.onNgClickFn);
                        }
                    };

                    $scope.closeDropdownWhenPointedOutside = function (e) {
                        if (helpers.isClickedOutsideElement(e, $element[0])) {
                            $scope.closeDropdown();
                        }
                    };

                    $scope.closeDropdownWithDelay = function () {
                        $timeout(function () {
                            $scope.closeDropdown();
                            $scope.accessibilityControls.focusBackInHeaderEl(true);
                        }, 100);
                    };

                    $scope.closeOnEsc = function (e) {
                        if (e.keyCode === 27) {
                            e.stopPropagation();
                            $scope.accessibilityControls.leaveDropdown();
                            $scope.accessibilityControls.performFocus(e, $scope.dropdownHeaderEl[0]);
                        }
                    };

                    $scope.accessibilityControls = {
                        getElements: function (single) {
                            if (single) {
                                return $scope.dropdownBodyInnerEl[0].querySelector('[tabindex="0"]');
                            } else {
                                return $scope.dropdownBodyInnerEl[0].querySelectorAll('[tabindex="0"]');
                            }
                        },

                        performFocus: function (e, target, force) {
                            // don't manually focus, let 'tab' key do it for us
                            if (e && e.keyCode === 9 && !force) {
                                return;
                            }

                            if (target) {
                                target.focus();
                            }
                        },

                        getCurrent: function (e) {
                            if (e.keyCode !== 9) {
                                e.preventDefault();
                            }
                            return e.target;
                        },

                        focusBackInHeaderEl: function (force) {
                            if ($scope.relativeToEl || force) {
                                $timeout(
                                    function () {
                                        this.performFocus(null, $scope.dropdownHeaderEl[0]);
                                    }.bind(this)
                                );
                            }
                        },

                        leaveDropdown: function () {
                            $scope.dropdownBodyInnerEl.unbind("keydown", $scope.navigateDropdownBody);
                            $scope.closeDropdown();
                            this.focusBackInHeaderEl();
                        },

                        first: function (e) {
                            // unbind first in case thing method is called multiple times by 'triggerAdjustPosition'
                            $scope.dropdownBodyInnerEl.unbind("keydown", $scope.navigateDropdownBody);

                            // don't enable focus style in case user opens dropdown via mouse events.
                            if (e !== void 0) {
                                globalReferences.documentEl.addClass("focus-style");
                            }
                            this.performFocus(e, this.getElements(true));

                            $scope.dropdownBodyInnerEl.bind("keydown", $scope.navigateDropdownBody);
                        },

                        next: function (e) {
                            var current = this.getCurrent(e),
                                allElems = this.getElements(), // get all elements
                                nextItemIndex = Array.prototype.indexOf.call(allElems, current), // get index of current item
                                nextItem;

                            // if there is next item, then focus in it
                            if (nextItemIndex < allElems.length - 1) {
                                nextItemIndex += 1;
                            } else {
                                // close dropdown if user tabbed away
                                if (e.keyCode === 9 && !e.shiftKey) {
                                    $scope.accessibilityControls.leaveDropdown();
                                }
                                return;
                            }

                            nextItem = allElems[nextItemIndex];
                            this.performFocus(e, nextItem);
                        },

                        prev: function (e) {
                            var current = this.getCurrent(e),
                                allElems = this.getElements(),
                                nextItemIndex = Array.prototype.indexOf.call(allElems, current),
                                prevItem;

                            // if there is previous item, then focus in it
                            if (nextItemIndex > 0) {
                                nextItemIndex -= 1;
                            }
                            // in case there no previous item, then jump back to dropdown header
                            else {
                                e.preventDefault(); // prevent when so when user uses tab it doesn't jump back too far
                                this.performFocus(e, $scope.dropdownHeaderEl[0], true);
                                $scope.accessibilityControls.leaveDropdown();
                                return;
                            }

                            prevItem = allElems[nextItemIndex];
                            this.performFocus(e, prevItem);
                        }
                    };

                    $scope.navigateDropdownBody = function (e) {
                        // down
                        if (e.keyCode === 40 || e.keyCode === 39 || (e.keyCode === 9 && !e.shiftKey)) {
                            $scope.accessibilityControls.next(e);
                        }
                        // up
                        else if (e.keyCode === 38 || e.keyCode === 37 || (e.keyCode === 9 && e.shiftKey)) {
                            $scope.accessibilityControls.prev(e);
                        }
                    };

                    $scope.bindAccessibilityControls = function (e) {
                        // on arrow down or tab
                        if (e.keyCode === 40 || e.keyCode === 39 || e.keyCode === 13 || e.keyCode === 32 || (e.keyCode === 9 && !e.shiftKey)) {
                            if (e.keyCode !== 9) {
                                e.preventDefault();
                            }

                            // on arrow down/right open dropdown
                            // and focus in first dropdown element
                            if (!$scope.isActive) {
                                if (e.keyCode !== 9) {
                                    $scope.runOnNgClickFn();
                                    $scope.openDropdown();
                                    $scope.accessibilityControls.first(e);
                                }
                            }
                        }
                        // leave dropdown when still on header and pressing 'back'
                        else if (e.keyCode === 37 || e.keyCode === 38 || (e.keyCode === 9 && e.shiftKey)) {
                            if ($scope.isActive) {
                                $scope.closeDropdown();
                            }
                        }
                    };

                    $scope.bindCoreEvents = function () {
                        if (UserAgentService.touch) {
                            $scope.dropdownHeaderEl.bind("touchstart", $scope.findActiveElement);
                        }
                        $scope.dropdownHeaderEl.bind("click", $scope.toggleDropdown);
                        $scope.$on("$destroy", function () {
                            $scope.dropdownBodyEl.remove();
                        });
                        $element.on("$destroy", function () {
                            $scope.dropdownBodyEl.remove();
                        });

                        if (!UserAgentService.touch) {
                            $scope.dropdownHeaderEl.bind("keydown", $scope.bindAccessibilityControls);
                        }
                    };

                    $scope.bindUIEvents = function () {
                        globalReferences.documentEl.bind("click", $scope.closeDropdownWhenPointedOutside);
                        $scope.dropdownBodyEl.bind("click", $scope.closeDropdownWithDelay);
                        if ($scope.relativeToEl) {
                            var delay = 0;
                            if (UserAgentService.firefox) {
                                delay = 100;
                            }
                            $timeout(function () {
                                // Dirty fix for Firefox
                                var origScrollTop = $scope.relativeToEl.scrollTop;
                                $scope.relativeToEl.addEventListener("scroll", function () {
                                    if (origScrollTop !== $scope.relativeToEl.scrollTop) {
                                        $scope.closeDropdown();
                                    }
                                });
                            }, delay);
                        }
                        if ($scope.behaveRelativeToEl) {
                            $scope.behaveRelativeToEl.addEventListener("scroll", $scope.closeDropdown);
                        }

                        if (!UserAgentService.touch) {
                            globalReferences.documentEl[0].addEventListener("keydown", $scope.closeOnEsc, true);
                        }
                    };

                    $scope.unbindUIEvents = function () {
                        globalReferences.documentEl.unbind("click", $scope.closeDropdownWhenPointedOutside);
                        $scope.dropdownBodyEl.unbind("click", $scope.closeDropdownWithDelay);
                        if ($scope.relativeToEl) {
                            $scope.relativeToEl.removeEventListener("scroll", $scope.closeDropdown);
                        }
                        if ($scope.behaveRelativeToEl) {
                            $scope.behaveRelativeToEl.removeEventListener("scroll", $scope.closeDropdown);
                        }

                        if (!UserAgentService.touch) {
                            globalReferences.documentEl[0].removeEventListener("keydown", $scope.closeOnEsc, true);
                        }
                    };

                    $scope.init = function () {
                        $scope.presetData();
                        $scope.mapAttrs();
                        $scope.cacheElems();
                        $scope.bindCoreEvents();
                    };

                    $scope.waitForAttribute = function (attrName, promises) {
                        var deferred = $q.defer();

                        // in case relativeTo available, the kickstart dropdown after value is ready
                        if ($attrs[attrName] !== undefined) {
                            var $offObserver = $attrs.$observe(attrName, function (newVal, oldVal) {
                                if (newVal !== oldVal) {
                                    $scope[attrName + "Attr"] = newVal;
                                    deferred.resolve();
                                    $offObserver();
                                }
                            });
                            promises.push(deferred.promise);
                        } else {
                            $scope[attrName + "Attr"] = false;
                        }
                    };

                    $scope.waitForAttributes = function (attrNames) {
                        var attrName;
                        var promises = [];

                        for (var i = 0, len = attrNames.length; i < len; i++) {
                            attrName = attrNames[i];

                            $scope.waitForAttribute(attrName, promises);
                        }

                        if (promises.length > 0) {
                            return $q.all(promises);
                        } else {
                            return false;
                        }
                    };
                }
            ],

            link: function (scope, element, attrs) {
                scope.presetObligatoryData();
                var waitForAttributesPromise = scope.waitForAttributes(["relativeTo", "limitationBox", "behaveRelativeTo"]);

                // in case attributes are available, the kickstart dropdown after all attributes value is ready
                if (waitForAttributesPromise) {
                    waitForAttributesPromise.then(function () {
                        scope.init();
                    });
                }
                // kickstart as normal
                else {
                    scope.init();
                }
            }
        };
    }
]);
