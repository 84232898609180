import { angularAMD } from "@pebblepad/amd";

angularAMD.service("clickableService", ClickableService);

ClickableService.$inject = [];
function ClickableService() {
    var Clickable = function (element, container, child_el, applyTo_el) {
        this.element = element;
        this.container = container || angular.element(document.body);
        this.child_el = child_el || null;
        this.applyTo_el = applyTo_el || element;
        this.isOpen = false;
        this.onGoing = 101;
        this.unbind = null;
    };

    Clickable.prototype.lastClickable = null;
    Clickable.prototype.cssClass = "active";
    Clickable.prototype.eventType = "click";

    Clickable.prototype.addTrigger = function (callback, unbind) {
        var that = this;

        //Unbind first if value of true provided.
        if (unbind !== undefined && unbind === true) {
            this.element.unbind(this.eventType);
        }

        this.unbind = function (event) {
            that.didClickOutside(event);
        };

        this.element.bind(this.eventType, function (e) {
            that.toggle(e);
            //Pass in null instead of a callback, if none needed.
            if (callback !== undefined && callback !== null && that.isOpen === true) {
                callback(e);
            }
        });
    };

    Clickable.prototype.toggle = function (e) {
        var that = this;
        //Deals with async issue with events firing in different orders
        if (Date.now() - this.onGoing > 100) {
            this.isOpen = !this.isOpen;

            if (this.isOpen === true) {
                this.element.addClass(this.cssClass);
                this.applyTo_el.addClass(this.cssClass);

                //Hide element when the clicking outside of the clickable's rendered area
                window.addEventListener("click", this.unbind, true);

                //Prevent collapsing when clicking a child element (dropdown's list_el if provided).
                if (this.child_el !== null) {
                    this.child_el.bind(this.eventType, function (event) {
                        event.stopPropagation();
                    });
                }
            } else {
                this.hide();
            }

            //In case any other dropdown was opened before, remove class from it - taken from original code in dropdown
            if (this.lastClickable !== null && this.lastClickable !== this.applyTo_el && this.lastClickable.hasClass(this.cssClass) === true) {
                this.lastClickable.removeClass(this.cssClass);
            }
            this.constructor.prototype.lastClickable = this.applyTo_el;

            if (e !== undefined) {
                //e.stopPropagation();
            }
        }
    };

    Clickable.prototype.hide = function () {
        this.isOpen = false;
        this.element.removeClass(this.cssClass);
        this.applyTo_el.removeClass(this.cssClass);
        window.removeEventListener(this.eventType, this.unbind, true);
        //this.container.unbind(this.eventType);

        if (this.child_el !== null) {
            this.child_el.unbind(this.eventType);
        }
    };

    Clickable.prototype.hideOnBlur = function () {
        var that = this;
        setTimeout(function () {
            if (!that.element[0].querySelector(":active") && !that.element[0].querySelector(":focus")) {
                that.hide();
            }
        }, 50);
    };

    Clickable.prototype.didClickOutside = function (e) {
        if (this.applyTo_el[0] !== e.target && !this.applyTo_el[0].contains(e.target)) {
            this.hide();
        }
        this.onGoing = Date.now();
    };

    return Clickable;
}
