import { RollbackWarning } from "./RollbackWarning";

export class RollbackInterceptor {
    constructor($injector, $q) {
        this._$injector = $injector;
        this._$q = $q;
        this._activeErrorPromise = null;
        this.responseError = this._responseErrorHandler.bind(this);
    }

    /**
     * @typedef {{ Code: number, Name: string}} ApiError
     * @typedef {{ status: number, data: ApiError|object|string|boolean|number }} HttpRejection
     * @param {HttpRejection} httpRejection
     * @return {Promise<HttpRejection>}
     */
    _responseErrorHandler(httpRejection) {
        const isPossibleRollback = httpRejection.status === 404 || httpRejection.status === 405;

        if (!isPossibleRollback || httpRejection.data.Code !== RollbackInterceptor.missingEndpointErrorCode) {
            return this._$q.reject(httpRejection);
        }

        if (this._activeErrorPromise === null) {
            const rollbackWarning = this._$injector.get(RollbackWarning.serviceName);
            this._activeErrorPromise = rollbackWarning.launch().finally(() => (this._activeErrorPromise = null));
        }

        return this._activeErrorPromise.then(() => this._$q.reject(httpRejection));
    }
}
RollbackInterceptor.missingEndpointErrorCode = 404;
