import { Subject } from "rxjs";

/**
 * A limited Subject type used for verbose Observable cleanup with operators such as takeUntil
 * The `send` method wraps all necessary logic to emit to a takeUntil and complete the Observable.
 */
export class KillSignal extends Subject<void> {
    private _isClosed: boolean = false;
    private _isTerminated: boolean = false;

    public complete(): void {
        this._isClosed = true;
        super.complete();
    }

    public send(): void {
        this.next();
        this.complete();
        this._isTerminated = true;
    }

    public isClosed(): boolean {
        return this._isClosed;
    }

    public isTerminated(): boolean {
        return this._isTerminated;
    }
}
