import { angularAMD } from "@pebblepad/amd";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/baseUrlsFactory";
import "../../modal/services/modal";
import "../../utilities/helpers";

FeedbackInputHelper.$inject = ["$rootScope", "multiLanguageService", "modal", "baseUrlsFactory", "helpers"];

function FeedbackInputHelper($rootScope, multiLanguageService, modal, baseUrlsFactory, helpers) {
    this.$rootScope = $rootScope;
    this.multiLanguageService = multiLanguageService;
    this.baseUrlsFactory = baseUrlsFactory;
    this.helpers = helpers;
    this.modal = modal;
}

FeedbackInputHelper.prototype.launchInvalidModal = function (message, title) {
    const templateUrl = `${this.baseUrlsFactory.shared_component_base_url}spaMenu/okmessage.lazy.html`;
    const modalProps = {
        title: title,
        confirmMessage: message,
        okButton: this.multiLanguageService.getString("buttons.confirm"),
        okButtonTitle: this.multiLanguageService.getString("buttons.generic.popup")
    };

    return this.modal.launch(templateUrl, modalProps);
};

FeedbackInputHelper.prototype.launchCancelModal = function (cancelFn) {
    const templateUrl = `${this.baseUrlsFactory.shared_component_base_url}modalDialogComponent/templates/generic-pop-up-with-buttons.lazy.html`;
    const modalProps = {
        title: this.multiLanguageService.getString("sidebar.asset_feedback.labels.discard_changes_label"),
        message: this.multiLanguageService.getString("sidebar.asset_feedback.messages.discard_draft_feedback_message"),
        okay_button_label: this.multiLanguageService.getString("buttons.back_feedback"),
        cancel_button_label: this.multiLanguageService.getString("buttons.exit_without_saving"),
        on_okay_fn: () => {
            return;
        },
        on_cancel_fn: () => {
            cancelFn();
        }
    };

    return this.modal.launch(templateUrl, modalProps);
};

angularAMD.service("FeedbackInputHelper", FeedbackInputHelper);
export { FeedbackInputHelper };
