import { PageModelSearch } from "../pageModelSearch/pageModelSearch";
import { byPageId } from "../pageModelSearch/helpers/ByPageId.function";
import { PAGE_ERROR } from "../constants/pageError.constants";
import { ASSET_CONSTANTS } from "../constants/asset.constants";
import { WorkbookResponsePageHandler } from "./workbookResponsePageHandler";
import { ProcurePageResponse } from "./procurePageResponse";
import { mapPageViewModelToPageUpdate } from "./mapPageViewModelToPageUpdate.function";

export class PortfolioPageHandler {
    constructor(assetContext, pages, $q, requester) {
        this._assetContext = assetContext;
        this._$q = $q;
        this._pages = pages;
        this._pageSearch = new PageModelSearch(pages);
        this._requester = requester;
    }

    procurePageById(pageId, canCreate) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        if (match.page.PageWithinWorkbook) {
            const workbookMatch = this._convertMatchToWorkbookMatch(match);
            const workbookPageHandler = this._getWorkbookHandler(workbookMatch.root);
            return workbookPageHandler.procurePage(workbookMatch.page, workbookMatch.parents, canCreate).then((pageUpdate) => {
                pageUpdate.parents.push(workbookMatch.root);
                return pageUpdate;
            });
        }

        return this._$q.when(new ProcurePageResponse(match.page, match.parents, ""));
    }

    addPage(pageViewModel) {
        return this._requester.updatePortfolioPage(this._assetContext.assetId, pageViewModel.PageAssetId, mapPageViewModelToPageUpdate(pageViewModel)).then(() => {
            this._pages.push(pageViewModel);
            return pageViewModel;
        });
    }

    addNestedPage(pageViewModel, parentId) {
        const match = this._pageSearch.tracedFind(byPageId(parentId));
        if (match === null || match.page.PageWithinWorkbook || match.page.PageType === ASSET_CONSTANTS.TYPES.WORKBOOK) {
            return this._$q.reject(PAGE_ERROR.INVALID_PARENT);
        }

        const parent = match.page;
        return this._requester.updatePortfolioPage(parent.PageAssetId, pageViewModel.PageAssetId, mapPageViewModelToPageUpdate(pageViewModel)).then(() => {
            parent.Children.push(pageViewModel);
            return pageViewModel;
        });
    }

    removePageById(pageId) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        if (match.page.PageWithinWorkbook) {
            return this._$q.reject(PAGE_ERROR.NOT_ALLOWED);
        }

        let pageToRemove = match.page;
        let parentToRemoveFrom = { PageAssetId: this._assetContext.assetId, Children: this._pages };
        for (let parent of match.parents) {
            if (parent.Children.length > 1) {
                parentToRemoveFrom = parent;
                break;
            }
            pageToRemove = parent;
        }

        if (parentToRemoveFrom.Children.length === 1) {
            return this._$q.reject(PAGE_ERROR.LAST_PAGE);
        }

        return this._requester.removePortfolioPage(parentToRemoveFrom.PageAssetId, pageToRemove.PageAssetId).then(() => {
            const pages = parentToRemoveFrom.Children;
            const index = pages.findIndex(byPageId(pageToRemove.PageId));
            if (index !== -1) {
                pages.splice(index, 1);
            }
        });
    }

    updatePageTitle(pageId, newTitle) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        if (match.page.PageWithinWorkbook) {
            return this._$q.reject(PAGE_ERROR.NOT_ALLOWED);
        }

        return this._requester.updatePortfolioPage(this._determineContainerId(match.parents), match.page.PageId, { Title: newTitle }).then(() => {
            match.page.PageTitle = newTitle;
        });
    }

    updatePageResponseById(pageId, responseId) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        if (!match.page.PageWithinWorkbook) {
            return this._$q.reject(PAGE_ERROR.NOT_ALLOWED);
        }

        const workbookMatch = this._convertMatchToWorkbookMatch(match);
        const workbookPageHandler = this._getWorkbookHandler(workbookMatch.root);
        return workbookPageHandler.updatePageResponseById(workbookMatch.page.PageId, responseId);
    }

    removePageResponseById(pageId) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        if (!match.page.PageWithinWorkbook) {
            return this._$q.reject(PAGE_ERROR.NOT_ALLOWED);
        }

        const workbookMatch = this._convertMatchToWorkbookMatch(match);
        const workbookPageHandler = this._getWorkbookHandler(workbookMatch.root);
        return workbookPageHandler.removePageById(workbookMatch.page.PageId);
    }

    convertPage(pageId) {
        const match = this._pageSearch.tracedFind(byPageId(pageId));

        if (match === null) {
            return this._$q.reject(PAGE_ERROR.NOT_FOUND);
        }

        if (match.page.PageWithinWorkbook) {
            return this._$q.reject(PAGE_ERROR.NOT_ALLOWED);
        }

        return this._requester.convertPage(this._determineContainerId(match.parents), pageId).then((pageInfo) => {
            const pages = match.parents.length > 0 ? match.parents[0].Children : this._pages;
            const index = pages.findIndex(byPageId(match.page.PageId));

            if (index > -1) {
                pages[index] = {
                    PageAssetId: pageInfo.ContentId,
                    PageId: pageInfo.Id,
                    PageTitle: pageInfo.Title,
                    PageType: "Webfolio",
                    PageIcon: "webfolio",
                    Children: [match.page],
                    PageFolder: "Invisible",
                    PageNavigationEditable: true,
                    Revision: 0
                };

                return pages[index];
            }

            throw new Error("No page has been found, something has gone seriously wrong, we shouldn't be in this state");
        });
    }

    _determineContainerId(parents) {
        return parents.length > 0 ? parents[0].PageAssetId : this._assetContext.assetId;
    }

    _convertMatchToWorkbookMatch(pageMatch) {
        for (let i = 0; i < pageMatch.parents.length; i++) {
            const parent = pageMatch.parents[i];
            if (!parent.PageWithinWorkbook && parent.PageType === ASSET_CONSTANTS.TYPES.WORKBOOK) {
                return {
                    page: pageMatch.page,
                    parents: pageMatch.parents.slice(0, i),
                    root: parent
                };
            }
        }

        throw new Error("Invalid parents provided! At least one parent must be a Workbook PageViewModel");
    }

    _getWorkbookHandler(workbookPage) {
        const parentContext = this._assetContext.cloneForLinkedAsset(workbookPage.PageAssetId);
        return new WorkbookResponsePageHandler(parentContext, workbookPage.Children, this._$q, this._requester);
    }
}
