import { SpecialCharacters, SpecialCharactersLatin, SpecialCharactersMathematical } from "@pebblepad/ckeditor";
import { SpecialCharactersGreek } from "../editor-plugins/special-characters/SpecialCharactersGreek";
import { SpecialCharactersMathExtended } from "../editor-plugins/special-characters/SpecialCharactersMathExtended";
import { IToolbarItem } from "./interfaces/IToolbarItem";

export const specialCharacters: IToolbarItem = {
    name: "specialCharacters",
    pluginConfig: null,
    plugins: [SpecialCharacters, SpecialCharactersGreek, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersMathExtended]
};
