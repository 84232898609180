import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("baseUrlsFactory", function () {
    return {
        shared_component_base_url: window.config.getSharedComponentBaseUrl(),
        api_base_url: window.config.getApiBaseUrl(),
        login_url: window.config.getApiBaseUrl() + "SharedMenu/Forwarder?location=LoginService&action=JustOpenLocation",
        forwarding_url: window.config.getApiBaseUrl() + "SharedMenu/Forwarder",
        testing_environment: window.config.getTestingEnvironment()
    };
});
