import { angularAMD } from "@pebblepad/amd";
import { tracker } from "@pjs/analytics";
import { getBestTextMatches } from "@pjs/utilities";
import { informationIcon, addIcon, ButtonClass } from "@pjs/core-ui";
import { withDigest } from "../react2angular/utilities/WithDigest.function";
import "../assetEndpointService/assetEndpoint.service";
import "../assetStore/services/paginatedSearch.factory";
import "../multiLanguageService/multiLanguageService";
import "../extensions/arrayExtensions";
import "../react2angular/icon";
import "../react2angular/buttonComponent";
import "../react2angular/segmentedControl";
import "./assetItem/assetItem";
import template from "./item-selector.html";
import { itemSelectorFilters } from "./itemSelectorFilters";

const ItemSelectorComponentDefinition = {
    bindings: {
        onSelect: "&",
        onUpdate: "&",
        itemData: "<",
        availableViews: "<",
        order: "@",
        startingAmount: "<",
        paginationAmount: "<",
        mainSelection: "<",
        onRequestUpload: "<?",
        itemsSelected: "<?",
        subTypes: "<?",
        disableExtraInfo: "<?"
    },
    template: template,
    controller: ItemSelectorComponent
};

const galleryItemSize = 144;
const bannerItemWidth = 473;

ItemSelectorComponent.$inject = ["$scope", "$element", "$timeout", "$rootScope", "baseUrlsFactory", "AssetEndpointService", "PaginatedSearchFactory", "multiLanguageService"];
function ItemSelectorComponent($scope, $element, $timeout, $rootScope, baseUrlsFactory, AssetEndpointService, PaginatedSearchFactory, multiLanguageService) {
    this.$element = $element;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.baseUrlsFactory = baseUrlsFactory;
    this.AssetEndpointService = AssetEndpointService;
    this.PaginatedSearchFactory = PaginatedSearchFactory;
    this.multiLanguageService = multiLanguageService;
    this.eventHandler = $scope.$on("updateItemsInSelector", this.updateItemsInSelector.bind(this));
    this.filterEventHandler = $scope.$on("filterItemSelector", this.filterEvent.bind(this));
    this.selectAllEventHandler = $scope.$on("selectAllItemSelector", this.selectAllItemSelector.bind(this));
    this.loadMoreItemsEventHandler = withDigest(this.loadMoreItems.bind(this), $scope);
    this.selectItemEventHandler = this.selectItem.bind(this);
    this.getImageUrlEventHandler = this.getImageUrl.bind(this);
    this.results = [];
    this.filteredResults = null;
    this.infoIcon = informationIcon;
    this.addIcon = addIcon;
    this.buttonClass = ButtonClass;
    this._defaultFilter = { ...itemSelectorFilters.title, resultsDescription: "search.filters.default_filter_results_description" };

    if (!this.subTypes) {
        this.subTypes = [];
    }
}
ItemSelectorComponent.prototype.$onInit = function () {
    this.selectorId = "item-selector-" + Math.random();
    this.filtered = false;
    this.doneSelection = false;
    this.doneSelectionMessage = "";
    this.description = this.itemData.Description;
    this.switchAssetViewLabel = this.multiLanguageService.getString("search.a11y.switch_asset_view");

    this.viewsIncludeBanner = this.availableViews.find((view) => view.id === "banner") !== undefined;
    this.buttons = this.availableViews.map((view, index) => {
        return {
            id: view.id,
            label: view.ariaLabel,
            source: view.icon,
            onChange: () => this.onToggleView(view),
            default: index === 0
        };
    });

    if (this.itemData && this.itemData.Items.length > 0) {
        if (this.itemsSelected) {
            this.showSelectedItems(this.itemData.Items);

            this.onUpdate({
                value: this.itemsSelected.length
            });
        }

        if (this.order) {
            this._setOrder(this.order, this.itemData.Items);
        }

        this.paginated(this.itemData);
    }

    this.toggleView(this.availableViews[0]);
};

ItemSelectorComponent.prototype.showSelectedItems = function (items) {
    for (var i = 0; i < items.length; i++) {
        items[i].checked = this.checkSelected(items[i]);
    }
};

ItemSelectorComponent.prototype.paginated = function (itemData) {
    const defaultAmountToLoad = this.startingAmount > 0 ? this.startingAmount : itemData.Items.length;
    this.paginatedSearch = this.PaginatedSearchFactory.createPaginatedSearch(itemData.Items, 0, defaultAmountToLoad);

    this.showLoadMore = true;
    this.resultsLoaded = false;

    this.paginatedSearch.setResults(itemData.Items);

    this.paginatedSearch.getResults().then(
        function (results) {
            this.results = results;

            if (results.length < this.startingAmount || this.startingAmount === 0) {
                this.resultsLoaded = false;
                this.showLoadMore = false;
            } else {
                this.paginatedSearch.updatePageLength(this.paginationAmount);
                this.resultsLoaded = true;
            }
        }.bind(this)
    );
};

ItemSelectorComponent.prototype.toggleView = function (view) {
    this.defaultView = view;
};

ItemSelectorComponent.prototype.onToggleView = function (view) {
    this.toggleView(view);
    tracker.trackEvent("Asset/Resource Selector", "Toggle display mode", view.id);
    this.$rootScope.$applyAsync();
};

ItemSelectorComponent.prototype._setOrder = function (order, items) {
    var institutionalBanner = null;

    if (items[0].Institutional === true) {
        institutionalBanner = items.shift();
    }

    if (order === "random") {
        items.randomiseOrder();
    }

    if (institutionalBanner) {
        items.unshift(institutionalBanner);
    }
};

ItemSelectorComponent.prototype.getImageUrl = function (item) {
    if (typeof item.ImageUrl !== "undefined") {
        return item.ImageUrl;
    }

    const params = {
        id: item.Id,
        x: this.viewsIncludeBanner ? bannerItemWidth : galleryItemSize,
        y: this.viewsIncludeBanner ? 0 : galleryItemSize,
        shortSideScale: !this.viewsIncludeBanner
    };

    return this.AssetEndpointService.transmitImageUrl(params);
};

ItemSelectorComponent.prototype.selectItem = function (item) {
    if (this.itemsSelected) {
        if (!this.checkSelected(item)) {
            this.itemsSelected.push(item);
        } else {
            this.forceOutItemList(item);
        }

        this.onUpdate({
            value: this.itemsSelected.length
        });
    }

    this.onSelect({
        item: item
    });
};

ItemSelectorComponent.prototype.forceIntoItemList = function (item) {
    if (!this.checkSelected(item)) {
        this.itemsSelected.push(item);
    }
};

ItemSelectorComponent.prototype.forceOutItemList = function (item) {
    for (var i = 0, len = this.itemsSelected.length; i < len; i++) {
        if (this.itemsSelected[i].Id === item.Id) {
            this.itemsSelected.splice(i, 1);
            return;
        }
    }
};

ItemSelectorComponent.prototype.checkSelected = function (selector) {
    if (!this.itemsSelected) {
        return;
    }

    for (var i = 0, len = this.itemsSelected.length; i < len; i++) {
        if (this.itemsSelected[i].Id === selector.Id) {
            return true;
        }
    }
    return false;
};

ItemSelectorComponent.prototype.loadMoreItems = function () {
    if (this.paginationAmount === 0) {
        this.loadAllItems();
        return;
    }

    this.resultsLoaded = true;
    this.loadingMoreResultsMessage = this.multiLanguageService.getString("search.a11y.loading");

    this.paginatedSearch.getNextSetOfResults().then(
        function (results) {
            this.results = this.results.concat(results);

            if (this.paginatedSearch.isAtEnd()) {
                this.showLoadMore = false;
            }

            this.timeout();
            this.forceFocus();
        }.bind(this)
    );
};

ItemSelectorComponent.prototype.loadAllItems = function () {
    this.results = this.filteredResults ? this.filteredResults : this.itemData.Items;
    this.showLoadMore = false;

    this.timeout();
    this.forceFocus();
};

ItemSelectorComponent.prototype.timeout = function () {
    this.$timeout(
        function () {
            this.resultsLoaded = false;
            this.loadingMoreResultsMessage = "";
            this.loadedResultsMessage = this.multiLanguageService.getString("search.a11y.available_result", { count: this.results.length });
        }.bind(this)
    );
};

ItemSelectorComponent.prototype.forceFocus = function () {
    this.$element[0].getElementsByClassName("item-selector__container")[0].lastElementChild.focus();
};

ItemSelectorComponent.prototype.openInfoPanel = function (item) {
    const actionData = {
        excludeAssetFunctionality: ["i-want-to"]
    };

    this.$rootScope.$broadcast("openRightHandSidePanel", {
        assetId: item.Id,
        panel: "asset-info",
        actionData: actionData
    });
};

ItemSelectorComponent.prototype.uploadItems = function () {
    this.onRequestUpload(this.selectorId);
};

ItemSelectorComponent.prototype.updateItemsInSelector = function (event, data) {
    if (data && !data.SelectorId) {
        this.showSelectedItems(data.Items);
        this.paginated(data);
        this.results = data.Items;

        return;
    }

    if (data && this.selectorId === data.SelectorId && data.Items && data.Items.length > 0) {
        var lowerCaseSubs = this.subTypes.map(function (subType) {
            return subType.toLowerCase();
        });

        var itemsToAdd = data.Items;

        if (lowerCaseSubs.length > 0) {
            itemsToAdd = data.Items.filter(function (item) {
                var subPrefix = item.SubType.toLowerCase().split("/")[0];

                return lowerCaseSubs.indexOf(subPrefix) > -1;
            });
        }

        if (itemsToAdd.length === 1 && this.mainSelection === "Single") {
            this.selectItem(itemsToAdd[0]);
            return;
        }

        if (itemsToAdd.length > 0) {
            this.ItemsToAdd = itemsToAdd;
            this.addNewItemToSelector(0);
            this.filterItemSelector();
        }
    }
};

ItemSelectorComponent.prototype.addNewItemToSelector = function (index) {
    this.$timeout(
        function () {
            if (this.mainSelection === "Multiple") {
                this.ItemsToAdd[index].checked = true;
                this.itemsSelected.unshift(this.ItemsToAdd[index]);
            }

            this.results.unshift(this.ItemsToAdd[index]);
            this.itemData.Items.unshift(this.ItemsToAdd[index]);

            index++;

            if (index < this.ItemsToAdd.length) {
                this.addNewItemToSelector(index);
            }

            this.onUpdate({
                value: this.itemsSelected.length
            });
        }.bind(this),
        1000
    );
};

ItemSelectorComponent.prototype.selectAllItemSelector = function (event, data) {
    var items = this.filtered ? this.results : this.itemData.Items;

    for (var i = 0; i < items.length; i++) {
        items[i].checked = data;
    }

    for (var j = 0; j < items.length; j++) {
        data ? this.forceIntoItemList(items[j]) : this.forceOutItemList(items[j]);

        var results = this.itemsSelected.length;
        this.onUpdate({
            value: results
        });
    }
};

ItemSelectorComponent.prototype.filterEvent = function (event, data) {
    this.filterItemSelector(data);
};

ItemSelectorComponent.prototype.filterItemSelector = function (data) {
    this.resultsLoaded = false;

    if (!data) {
        this.filtered = false;
        this.filteredResults = this.itemData.Items;
        this.itemData.Description = this.description;
        this.updateItemsInSelector("refresh", this.itemData);
        return;
    }

    this.filtered = true;

    const filter = data.filter ?? this._defaultFilter;

    this.filteredResults = getBestTextMatches(data.searchTerm.toLowerCase(), this.itemData.Items, filter.criteria);

    if (this.filteredResults.length <= this.startingAmount) {
        this.showLoadMore = false;
        this.results = this.filteredResults;
        this.resultsLoaded = true;
    } else {
        this.showLoadMore = true;
        var defaultFiltered = {
            Items: this.filteredResults
        };
        this.paginated(defaultFiltered);
        this.results = this.filteredResults;
        this.resultsLoaded = true;
    }

    this.itemData.Description = this.multiLanguageService.getString(filter.resultsDescription, { count: this.filteredResults.length });
    this.timeout();
};

angularAMD.component("itemSelector", ItemSelectorComponentDefinition);

export { ItemSelectorComponentDefinition as itemSelector };
