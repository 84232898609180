import { angularAMD } from "@pebblepad/amd";
import { directiveAttributeFactory } from "./directiveAttributeFactory";

//Module setup
//=============================================================================================================
var conditionalAttributeModule = angular.module("conditionalAttribute", ["ng"]);

//Directive setup
//=============================================================================================================
var directives = [
    { key: "conditionalTarget", value: "target" },
    { key: "conditionalPlaceholder", value: "placeholder" },
    { key: "conditionalId", value: "id" },
    { key: "conditionalTabindex", value: "tabindex" }
];

directiveAttributeFactory.create(conditionalAttributeModule, directives);

export { conditionalAttributeModule as conditionalAttribute };
