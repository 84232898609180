import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { warningNotice } from "@pjs/core-ui";
import { noCkEditorLinkUnlinkPlugin as editorConfig } from "../../inlineTextEditor/editorConfigs/noCkEditorLinkUnlinkPlugin";
import "../../react2angular/htmlNotice";
import "../../multiLanguageService/multiLanguageService";
import "../../tabSet/tabSet.component";
import "../../tabContent/tabContent.component";
import "../../autoFocus/autoFocus.directive";
import "../../messageAnnouncer/messageAnnouncer.directive";
import "../../formComponents/formElementInfo/formElementInfoService.service";
import "../../titledTextEditor/titledTextEditor.component";
import "../../persistentCompactRichTextEditor/peristentCompactRichTextEditor.component";
import "../../utilities/helpers";
import template from "./text-only-evidence.html";

class TextOnlyEvidenceComponent extends NgComponent {
    constructor(multiLanguageService, formElementInfoService, helpers) {
        super();

        this.multiLanguageService = multiLanguageService;
        this._formElementInfoService = formElementInfoService;
        this.helpers = helpers;
        this.justificationLabel = this.multiLanguageService.getString("labels.evidence.evidence_justification_editor");

        this.writeTab = {
            active: true,
            id: "write-tab",
            title: multiLanguageService.getString("labels.add_evidence_sidebar.text_only_evidence.tabs.write"),
            disabled: false
        };
        this.justifyTab = {
            active: false,
            id: "justify-tab",
            title: multiLanguageService.getString("labels.add_evidence_sidebar.text_only_evidence.tabs.justify"),
            disabled: true
        };

        this.tabs = [this.writeTab, this.justifyTab];

        this.evidenceData = {
            justification: "",
            title: "",
            text: "",
            valid: false
        };
        this.originalEvidenceData = { ...this.evidenceData };
        this.isSaving = false;
        this.warningNotice = warningNotice;
        const labelType = multiLanguageService.getString("accessibility.notice.type.text_only_evidence");
        this.warningLabel = multiLanguageService.getString("accessibility.notice.labels.warning", { type: labelType });
        this.errorMsg = "";
        this.announcerMessage = "";

        this.richTextClassName = "text-only-evidence__editor";
        this.richTextEditorDatahook = "text-only-evidence-justification";
        this.richTextAutoFocus = true;
        this.richTextConfig = {
            ...editorConfig,
            extraPlugins: "sharedspace,linker",
            startupFocus: this.richTextAutoFocus
        };
        this.onJustificationChange = this._updateJustification.bind(this);
    }

    _updateJustification(content) {
        this.evidenceData.justification = content;
        this.onChange(this._hasEvidenceDataChanged());
    }

    updateEvidenceEntry(content) {
        this.evidenceData.text = content;
        this.onChange(this._hasEvidenceDataChanged());
        this._validator();
    }

    updateEvidenceTitle() {
        this.onChange(this._hasEvidenceDataChanged());
        this._validator();
    }

    setActiveTab(activeTab) {
        this.tabs.forEach((tab) => (tab.active = tab.id === activeTab.id));
    }

    goToJustifyPanel() {
        this.setActiveTab(this.justifyTab);
    }

    goToEvidencePanel() {
        this.setActiveTab(this.writeTab);
    }

    saveEvidence() {
        this.announcerMessage = this.multiLanguageService.getString("labels.add_evidence_sidebar.text_only_evidence.a11y.evidence_being_saved");
        this.isSaving = true;
        this.errorMsg = "";

        this._formElementInfoService
            .createTextOnlyEvidence(this.evidenceData.title, this.evidenceData.text)
            .then((response) => {
                const evidence = [
                    {
                        Id: response.data,
                        Justification: this.evidenceData.justification
                    }
                ];

                return this._formElementInfoService.addEvidence(this.assetId, this.elementId, evidence);
            })
            .then(() => {
                this.onSave();
            })
            .catch(() => {
                this.errorMsg = this.multiLanguageService.getString("labels.add_evidence_sidebar.save_error");
                this.announcerMessage = this.errorMsg;
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    _validator() {
        this.evidenceData.valid = this.evidenceData.title.length >= 1 && /\S/g.test(this.helpers.htmlToPlain(this.evidenceData.text));
        this.justifyTab.disabled = !this.evidenceData.valid;
    }

    _hasEvidenceDataChanged() {
        const titleChanged = this.originalEvidenceData.title !== this.evidenceData.title;
        const textChanged = this.originalEvidenceData.text !== this.evidenceData.text;
        const justificationChanged = this.originalEvidenceData.justification !== this.evidenceData.justification;

        return titleChanged || textChanged || justificationChanged;
    }
}

export const TextOnlyEvidenceComponentDefinition = {
    bindings: {
        elementId: "<",
        assetId: "<",
        onSave: "<",
        onChange: "<"
    },
    template: template,
    controller: TextOnlyEvidenceComponent
};

TextOnlyEvidenceComponent.$inject = ["multiLanguageService", "formElementInfoService", "helpers"];
angularAMD.component("textOnlyEvidence", TextOnlyEvidenceComponentDefinition);
