import { KillSignal } from "@pjs/observables";
import { DropdownView, ToolbarView } from "@pebblepad/ckeditor";
import { IEditorFactoryConfig } from "../../editor-factories/interfaces/IEditorFactoryConfig";
import { IEditor } from "../../editor/interfaces/IEditor";
import { CkePanelPositionHandler } from "./CkePanelPositionHandler";

export function replaceDropdownPositioningLogic(editorController: IEditor, config: IEditorFactoryConfig): () => void {
    const killSignal = new KillSignal();

    editorController.enabled.subscribe((state) => {
        if (!state.isEnabled) {
            killSignal.next();
            return;
        }

        const toolbarElement = state.editor.ui.view.toolbar.element;

        for (const dropdownView of state.editor.ui.view.toolbar.items) {
            if (dropdownView instanceof DropdownView) {
                CkePanelPositionHandler.handle(toolbarElement, dropdownView, "bottom-start", config.boundaryElement.current, killSignal, false);
            }
        }

        state.editor.ui.view.toolbar.once("groupedItemsUpdate", (evt) => {
            const toolbarView = evt.source as unknown as ToolbarView;
            const dropdownView: DropdownView = toolbarView.children.last as DropdownView;

            CkePanelPositionHandler.handle(toolbarElement, dropdownView, "bottom-end", config.boundaryElement.current, killSignal, true);
        });
    });

    return () => {
        killSignal.send();
    };
}
