import { angularAMD } from "@pebblepad/amd";

angularAMD.directive("elementCreatedMarker", ElementCreatedMarker);
ElementCreatedMarker.$inject = [];

function ElementCreatedMarker() {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            scope.$emit("elementCreatedMarkerExists", attrs.elementCreatedMarker, element[0]);
        }
    };
}
