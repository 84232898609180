import { redactIdsFromString } from "./redactIdsFromString";
import { redactUrl } from "./redactUrl";
import { dataEncryptionService } from "../utilities/dataEncryptionService";

$exceptionHandler.$inject = ["$log"];

function $exceptionHandler($log) {
    function getJsonFromPossiblyUnhandledRejection(exceptionStr) {
        if (exceptionStr.match(/possibly unhandled rejection: {/i) !== null) {
            try {
                return JSON.parse(exceptionStr.replace("Possibly unhandled rejection:", ""));
            } catch (e) {
                /**/
            }
        }
        return null;
    }

    return function pebbleExceptionHandler(exception, cause) {
        const exceptionHandlerTagName = "exception-handler-src";
        if (typeof exception === "string") {
            let msg = null;
            let fingerprint = null;
            let tags = {};
            const exceptionData = getJsonFromPossiblyUnhandledRejection(exception);
            const isXhrException = exceptionData !== null && "xhrStatus" in exceptionData;
            if (isXhrException && exceptionData.config !== undefined && typeof exceptionData.config.url === "string") {
                let redactedUrl = redactUrl(exceptionData.config.url);
                fingerprint = `${exceptionData.status} ${redactedUrl.replace(/\?.*/, "")}`;
                msg = `Xhr ${exceptionData.status} error on ${exceptionData.config.method} "${redactedUrl}"`;
                tags[exceptionHandlerTagName] = "pebble-unhandled-xhr-error";
            }

            if (fingerprint === null) {
                msg = redactIdsFromString(exception);
                fingerprint = msg.replace(/\d+/g, "0");
                fingerprint = dataEncryptionService.MD5(fingerprint).toString();
                tags[exceptionHandlerTagName] = "pebble-unhandled-misc-error";
            }

            $log.error(exception, cause);
            return;
        }

        $log.error(exception, cause);
    };
}

const pebbleExceptionReporterModule = angular.module("pebbleExceptionReporter", []).factory("$exceptionHandler", $exceptionHandler);

export { pebbleExceptionReporterModule };
