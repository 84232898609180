import { angularAMD } from "@pebblepad/amd";
import { Shortcuts } from "keysy-peasy";

class AccessKeyService {
    constructor($timeout) {
        this._$timeout = $timeout;
        this._shortcuts = new Shortcuts(window);
        this._pausedShortcuts = null;
    }

    register(contextId, keys) {
        const keysCloned = keys.map((shortcut) => {
            const shortcutClone = { ...shortcut };

            shortcutClone.callback = (...callbackArgs) => {
                // using $timeout here to make sure that the digest cycle is triggered
                this._$timeout(() => {
                    shortcut.callback(...callbackArgs);
                });
            };

            return shortcutClone;
        });

        return this._shortcuts.register(contextId, keysCloned);
    }

    getHandlers(...args) {
        return this._shortcuts.getHandlers(...args);
    }

    remove(...args) {
        if (this._pausedShortcuts !== null) {
            for (const contextId of args) {
                if (this._pausedShortcuts[contextId] !== undefined) {
                    delete this._pausedShortcuts[contextId];
                }
            }
        }

        return this._shortcuts.remove(...args);
    }

    pauseHandlers() {
        if (this._pausedShortcuts !== null) {
            return;
        }

        const allShortcuts = this._shortcuts.getHandlers();
        const shortcutsByContext = {};

        Object.values(allShortcuts).forEach((shortcut) => {
            const contextsShortcuts = shortcutsByContext[shortcut.contextId] === undefined ? [] : shortcutsByContext[shortcut.contextId];
            contextsShortcuts.push(shortcut);
            shortcutsByContext[shortcut.contextId] = contextsShortcuts;
            this._shortcuts.remove(shortcut.contextId);
        });

        this._pausedShortcuts = shortcutsByContext;
    }

    resumeHandlers() {
        if (this._pausedShortcuts === null) {
            return;
        }

        Object.keys(this._pausedShortcuts).forEach((contextId) => {
            this._shortcuts.register(contextId, this._pausedShortcuts[contextId]);
        });

        this._pausedShortcuts = null;
    }
}

AccessKeyService.$inject = ["$timeout"];
angularAMD.service("accessKeyService", AccessKeyService);
