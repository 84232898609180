import { CkEditorElementStyleHelper } from "../helpers/ckEditorElementStyleHelper";

function CkEditorParagraphIndentProcess(indentAmount, indentUnit) {
    this._indentAmount = indentAmount;
    this._indentUnit = indentUnit;
}

CkEditorParagraphIndentProcess.prototype.applyStyle = function (element) {
    var textIndentAmount = this._indentAmount + this._indentUnit;
    var stlyHelper = new CkEditorElementStyleHelper(element);
    var currentTextIndent = stlyHelper.getNumericValue("text-indent", this._indentUnit);
    var currentMargin = stlyHelper.getNumericValue("margin-left", this._indentUnit);

    if (currentTextIndent < 0) {
        var newMargin = currentMargin + currentTextIndent;
        element.setStyle("margin-left", newMargin > 0 ? newMargin + this._indentUnit : "");
    }

    element.setStyle("text-indent", textIndentAmount);
    return element;
};

CkEditorParagraphIndentProcess.prototype.removeStyle = function (element) {
    element.setStyle("text-indent", "");

    if (element.getAttribute("style") === "") {
        element.removeAttribute("style");
    }
};

CkEditorParagraphIndentProcess.prototype.applied = function (element) {
    var textIndentStyle = element.getStyle("text-indent");
    var textIndentAmount = Number(textIndentStyle.split(this._indentUnit)[0]);

    return textIndentAmount === this._indentAmount;
};

export { CkEditorParagraphIndentProcess };
