import { PersistentCompactMultiLineEditorWithBoundary } from "@pjs/rich-text";
import { react2angular } from "@pebblepad/react2angular";
import { angularAMD } from "@pebblepad/amd";

angularAMD.component(
    "persistentCompactMultiLineEditor",
    react2angular(PersistentCompactMultiLineEditorWithBoundary, [
        "className",
        "content",
        "dataHook",
        "ariaLabel",
        "ariaLabelledBy",
        "onChange",
        "onToolbarDisplayChange",
        "placeholder",
        "autoFocus",
        "onLink"
    ])
);
