import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../constants/assessment.constants";
import { MenuItem } from "../splitButton/menuItem.js";
import "../spaMenu/assetInfoPanel/assetFeedback/helpers/SubmissionPermissionHelper";
import "../multiLanguageService/multiLanguageService";
import template from "./templates/feedback-release.html";

const FeedbackReleaseDefinition = {
    bindings: {
        onSave: "&",
        onConfirm: "&",
        onCancel: "&",
        disableButtons: "<",
        releaseSetting: "<"
    },
    template: template,
    controller: FeedbackRelease
};

FeedbackRelease.$inject = ["multiLanguageService", "SubmissionPermissionHelper"];
function FeedbackRelease(multiLanguageService, SubmissionPermissionHelper) {
    this.multiLanguageService = multiLanguageService;
    this.releaseOpts = this._initReleaseFeedbackOpts();
    this.releaseOpts.defaultButton = this._defaultButton();
    this.releaseOpts.dropdownMenu = this._dropdownMenu();
}

FeedbackRelease.prototype._dropdownMenu = function () {
    if (this.releaseSetting === ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.DEFAULT_HOLD || this.releaseSetting === ASSESSMENT_CONSTANTS.RELEASE_SETTINGS.DEFAULT_RELEASE) {
        return this.releaseOpts.all;
    }
    return []; // A workspace setting is restricting options given to assessors, so adhere to that restriction and show only the default button
};

FeedbackRelease.prototype._defaultButton = function () {
    return this.releaseOpts[this.releaseSetting] || this.releaseOpts.default;
};

FeedbackRelease.prototype._initReleaseFeedbackOpts = function () {
    //default button needed?
    const defaultBtn = new MenuItem({
        label: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.save_ellipsis"),
        action: null
    });
    const saveAndHoldBtn = new MenuItem({
        label: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.save_and_hold"),
        action: this.releaseOrHoldSave.bind(this, false),
        dataHook: "split-btn-secondary"
    });
    const saveAndReleaseBtn = new MenuItem({
        label: this.multiLanguageService.getString("sidebar.asset_feedback.buttons.save_and_release"),
        action: this.releaseOrHoldSave.bind(this, true),
        dataHook: "split-btn-secondary"
    });
    // map the button keys to release setting constants
    return {
        default: defaultBtn,
        all: [saveAndHoldBtn, saveAndReleaseBtn],
        AlwaysHold: saveAndHoldBtn,
        AlwaysRelease: saveAndReleaseBtn,
        DefaultHold: saveAndHoldBtn,
        DefaultRelease: saveAndReleaseBtn
    };
};

FeedbackRelease.prototype.releaseOrHoldSave = function (released) {
    this.onSave({
        released: released
    });
};

angularAMD.component("feedbackRelease", FeedbackReleaseDefinition);

export { FeedbackReleaseDefinition as feedbackRelease };
