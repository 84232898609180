import { RefObject } from "react";
import { IHtmlStringSanitiser } from "../html-string-sanitiser/interfaces/IHtmlStringSanitiser";
import { basicHtmlStringSanitiser } from "../html-string-sanitiser/BasicHtmlStringSanitiser.const";
import { IParseHtmlSanitiser } from "./interfaces/IParseHtmlSanitiser";

export class ParseHtmlSanitiser implements IParseHtmlSanitiser {
    private readonly _sanitiser: IHtmlStringSanitiser = basicHtmlStringSanitiser;
    private readonly _delay: number;
    private _debounceTimerId: number = 0;

    constructor(delay: number) {
        this._delay = delay;
    }

    public sanitise(htmlString: string, elementRef: RefObject<HTMLElement>, skipDelay: boolean): void {
        if (skipDelay || this._delay === 0) {
            this._sanitise(htmlString, elementRef);
            return;
        }
        clearTimeout(this._debounceTimerId);
        this._debounceTimerId = window.setTimeout(() => this._sanitise(htmlString, elementRef), this._delay);
    }

    public destroy(): void {
        clearTimeout(this._debounceTimerId);
    }

    private _sanitise(htmlString: string, elementRef: RefObject<HTMLElement>): void {
        const parseHtmlElement = elementRef.current;
        if (parseHtmlElement !== null) {
            parseHtmlElement.innerHTML = "";

            this._sanitiser.append(htmlString, parseHtmlElement);
        }
    }
}
