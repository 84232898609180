import { NgComponent } from "angular/ngComponent";
import { ToolbarDisplayState } from "@pjs/rich-text";
import "../utilities/debounce.service.js";
import "./ActiveToolbarCoordinator.service";
import "../pebbleEditorPlugins/linkerPlugin/LinkerService";

export class StickyReactEditorAdapterController extends NgComponent {
    constructor(activeToolbarCoordinator, $scope, linkerService, stickyContainer) {
        super();
        this.stickyContainer = stickyContainer;

        this.onToolbarDisplayChange = (displayState, element) => {
            if (displayState === ToolbarDisplayState.Pending) {
                return;
            }

            activeToolbarCoordinator.onToolbarToggle(displayState, element);

            if (displayState === ToolbarDisplayState.Shown) {
                this.stickyContainer.coordinator.add(element);
            } else {
                this.stickyContainer.coordinator.remove(element);
            }
        };

        this.onEditorChange = (value) => {
            this.onChange(value);
            $scope.$applyAsync();
        };

        this.onLink = (result, focusTrackerProxy) => linkerService.createModal(result, focusTrackerProxy);
    }

    $onChanges() {
        this.editorContent = this.content === undefined || this.content === null ? "" : this.content;
        this.editorPlaceholder = this.placeholder === undefined ? "" : this.placeholder;
    }
}
StickyReactEditorAdapterController.$inject = ["ActiveToolbarCoordinator", "$scope", "linkerService"];
