export const reactEditorAdapterBindings = {
    className: "<?",
    content: "<?",
    datahook: "<",
    headingLevel: "<?",
    label: "<?",
    labelledBy: "<?",
    onChange: "<",
    placeholder: "<?"
};
