import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../utilities/urlService";
import "../../user/userAPI.service";
import "../../assetEndpointService/assetEndpoint.service";

angularAMD.factory("assetStoreService", [
    "$http",
    "baseUrlsFactory",
    "$q",
    "$log",
    "$window",
    "$location",
    "urlService",
    "UserAPI",
    "AssetEndpointService",
    function ($http, baseUrlsFactory, $q, $log, $window, $location, urlService, UserAPI, AssetEndpointService) {
        return {
            doNewSearch: function (searchObject, fullSearch) {
                var action = fullSearch ? "Search/FullSearch" : "Search";
                return urlService.promisedPost(baseUrlsFactory.api_base_url + action, searchObject);
            },

            archiveAssets: function (assetIds) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/ArchiveAssets", assetIds);
            },

            deleteAssets: function (assetIds) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/DeleteAssets", assetIds);
            },

            purgeAssets: function (assetIds, ignoreDeletedFolder) {
                var data = {
                    assetIds: assetIds,
                    ignoreDeletedFolder: ignoreDeletedFolder
                };
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/PurgeAssets", data);
            },

            unarchiveAssets: function (assetIds) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/UnarchiveAssets", assetIds);
            },

            restoreAssets: function (assetIds) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/RestoreAssets", assetIds);
            },

            openAssets: function (assetIds) {
                // get urls for all assets
                var requestedAssets = angular.copy(assetIds);
                urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/GetAssetUrls", requestedAssets).then(function (response) {
                    var urls = response.data;
                    // configure them all
                    angular.forEach(urls, function (url) {
                        url = urlService.configureAssetUrl(url);
                        if (urls.length === 1) {
                            url = url.replace("#/", "");
                            $location.path(url);
                        } else {
                            // open each one
                            $window.open(url, "_blank");
                        }
                    });
                });
            },

            changeAssetFolder: function (assetId, folder) {
                var queryStringParams = "?assetId=" + assetId + "&destinationFolder=" + folder;
                return $http.post(baseUrlsFactory.api_base_url + "Asset/ChangeAssetFolder" + queryStringParams).then(function (response) {
                    return response ? response.data : $q.reject();
                });
            },

            getAssetWithLinks: function (id, removeFeedback, isCopying) {
                return urlService.promisedGet(baseUrlsFactory.api_base_url + "Asset/GetAssetWithLinks", {
                    id: id,
                    removeFeedback: removeFeedback || false,
                    isCopying: isCopying || false
                });
            },

            getAssetWithAvailableActions: function (assetId) {
                return AssetEndpointService.getAssetWithAvailableActions(assetId);
            },

            isAsset: function (id, mainType) {
                return AssetEndpointService.isAsset(id, mainType);
            },

            getPublicAssetReference: function (id) {
                return AssetEndpointService.getPublicAssetReference(id);
            },

            getAssetVersions: function (id) {
                return urlService.promisedGet(baseUrlsFactory.api_base_url + "AssetSidebar/PreviousVersions", {
                    assetId: id
                });
            },
            getAssetReference: function (id) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/GetAssetReference?assetId=" + id);
            },

            getAvailableActions: function (ids) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Asset/GetAvailableActions", ids);
            },

            getSpecificAssetSearchResults: function (ids) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "Search/GetSpecificAssets", ids);
            },

            cancel: function (promise) {
                // If the promise does not contain a hook into the deferred timeout,
                // the simply ignore the cancel request.
                if (promise && promise._httpTimeout && promise._httpTimeout.resolve) {
                    promise._httpTimeout.resolve();
                }
            },

            initSearch: function () {
                return urlService.promisedGet(baseUrlsFactory.api_base_url + "Search/InitialiseSearch");
            },

            stopShowingAssetStoreHelpForLoggedInUser: function () {
                return UserAPI.changeUserSetting("HideAssetStoreHelp", "true");
            },

            getAssetAndCheckPermission: function (params, workbookBuilderMode) {
                return workbookBuilderMode ? AssetEndpointService.getBuilder(params) : AssetEndpointService.getAssetAndCheckPermission(params);
            },

            getAssetInUseStats: function (assetIds) {
                return urlService.promisedPost(baseUrlsFactory.api_base_url + "AssetDto/GetAssetInUseStats/", assetIds);
            },

            getEditStatusForAssets: function (assetIds) {
                return AssetEndpointService.getEditStatusForAssets(assetIds).then(function (response) {
                    return response ? response.data : $q.reject();
                });
            },

            getEditStatusForAsset: function (assetId) {
                return this.getEditStatusForAssets([assetId]).then(function (data) {
                    return data && data[0].Value ? data[0].Value : null;
                });
            },

            getFolderLocationForAsset: function (assetId) {
                return $http.get(baseUrlsFactory.api_base_url + "Asset/WhatFolderIsAssetIn?assetId=" + assetId).then(function (response) {
                    return response.data;
                });
            },

            favouriteAsset: function (assetIds, addFavourite) {
                return $http.post(baseUrlsFactory.api_base_url + "Asset/Favourites", { assetIds: assetIds, isFavourite: addFavourite });
            }
        };
    }
]);
