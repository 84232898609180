import { angularAMD } from "@pebblepad/amd";
import { reactEditorAdapterBindings } from "../reactEditorAdapter/ReactEditorAdapterBindings.const";
import { StickyReactEditorAdapterController } from "../reactEditorAdapter/StickyReactEditorAdapterController";
import "../stickyElementCoordinator/stickyContainer.directive";
import template from "./persistent-compact-rich-text-editor.html";

export const stickyPersistentCompactRichTextEditorDefinition = {
    bindings: {
        autoFocus: "<",
        ...reactEditorAdapterBindings
    },
    require: {
        stickyContainer: "^stickyContainer"
    },
    controller: StickyReactEditorAdapterController,
    name: "stickyPersistentCompactRichTextEditor",
    template: template
};
angularAMD.component(stickyPersistentCompactRichTextEditorDefinition.name, stickyPersistentCompactRichTextEditorDefinition);
