import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import "../../../../react2angular/richTextView";
import "../../../../multiLanguageService/multiLanguageService";
import "../../../../utilities/helpers";
import template from "./capability-mapping.html";

class CapabilityMappingComponent extends NgComponent {
    constructor(multiLanguageService, helpers) {
        super();

        this.multiLanguageService = multiLanguageService;
        this._helpers = helpers;
        this.hasCapabilities = this.capabilities !== null && this.capabilities.length > 0;

        if (!this.mappingAllowed) {
            this.header = multiLanguageService.getString("asset_summary.mapping.cannot_map_title");
        } else {
            this.header = this.hasCapabilities ? multiLanguageService.getString("asset_summary.mapping.details_title") : this.errorMessage;
        }
    }
}

export const capabilityMapping = {
    bindings: {
        errorMessage: "@",
        capabilities: "<",
        mappingAllowed: "<"
    },
    template: template,
    controller: CapabilityMappingComponent
};

CapabilityMappingComponent.$inject = ["multiLanguageService", "helpers"];
angularAMD.component("capabilityMapping", capabilityMapping);
