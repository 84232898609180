import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { IButtonMenuOption } from "../../../button-menu/interfaces/IButtonMenuOption";

export function confirmButtonMenuActionAndSetClosed(_: KeyboardEvent, currentModel: IAriaModelWithType, items: Array<IButtonMenuOption>): Partial<Immutable<MenuAriaModel>> {
    const selectedOption = items[currentModel.activeItemIndex];

    selectedOption.onSelect();

    return {
        activeItemIndex: 0,
        isOpen: false,
        type: AriaMenuStatus.Closed
    };
}
