import { angularAMD } from "@pebblepad/amd";
import "../security/pebbleSecurity.module";

angularAMD.service("SelectionService", SelectionService);
SelectionService.$inject = ["$window", "domSearchHelper", "helpers", "PebbleSecurityService"];

function SelectionService($window, domSearchHelper, helpers, pebbleSecurityService) {
    // Public API
    // =============================================================================================================
    this.saveSelection = saveSelection;
    this.restoreSelection = restoreSelection;
    this.pasteHtmlAtCaret = pasteHtmlAtCaret;
    this.wrapSelection = wrapSelection;
    this.insertBookmark = insertBookmark;
    this.removeSelectionBookmark = removeSelectionBookmark;
    this.getBookmarkDomInfo = getBookmarkDomInfo;
    this.isSelectionWithinElement = isSelectionWithinElement;

    // Globals
    // =============================================================================================================
    var markerClassNamePrefix = "pebble",
        selectionBoundary = "selection-boundary",
        markerTextChar = "\ufeff";

    var bookmarkDomInfo = {
        tagName: "selection-bookmark",
        data: "data-selection-bookmark",
        dataEnd: "data-selection-bookmark-end"
    };

    function getBookmarkDomInfo() {
        return bookmarkDomInfo;
    }

    function wrapSelection(action_parameter, before, renderNode) {
        var selection = document.getSelection();
        var range = selection.getRangeAt(0);
        var commonAncestorContainer = range.commonAncestorContainer.parentNode;
        var startContainer = range.startContainer;
        var endContainer = range.endContainer;
        var startOffset = range.startOffset;
        var endOffset = range.endOffset;

        if (before) {
            var killFunction = before(range);
            if (killFunction) {
                return;
            }
        }
        if (startContainer === endContainer) {
            renderNode(startContainer, startOffset, endOffset, range);
        } else {
            recurseNodes(commonAncestorContainer, renderNode, {
                startOffset: startOffset,
                endOffset: endOffset,
                startContainer: startContainer,
                endContainer: endContainer,
                wrapTextNodes: false,
                range: range
            });
        }
    }

    // Private methods
    // =============================================================================================================
    /**
     * @param {Element} el
     * @param {Function} renderNode
     * @param {{startOffset: Number, endOffset: Number, startContainer: Node, endContainer: Node, wrapTextNodes: Boolean}} state
     * @returns {boolean}
     */
    function recurseNodes(el, renderNode, state) {
        if (["UL", "OL", "LI"].contains(el.nodeName)) {
            var startRender = false;
            if (!state.wrapTextNodes) {
                startRender = domSearchHelper.elementContains(el, state.startContainer);
            }
            if (startRender || state.wrapTextNodes) {
                state.wrapTextNodes = true;
                renderNode(el, state.startOffset, state.endOffset, state.range);
            }
            if (domSearchHelper.elementContains(el, state.endContainer)) {
                return false;
            } else {
                return true;
            }
        } else {
            if (el === state.startContainer) {
                state.wrapTextNodes = true;
                renderNode(el, state.startOffset, null, state.range);
            } else if (el === state.endContainer) {
                renderNode(el, 0, state.endOffset, state.range);
                return false;
            } else if (state.wrapTextNodes && (el.nodeName === "#text" || el.nodeName === "span")) {
                renderNode(el, 0, null, state.range);
            }

            if (el.hasChildNodes()) {
                var cachedNodes = Array.prototype.slice.call(el.childNodes, 0);
                for (var i = 0; i < cachedNodes.length; i++) {
                    var result = recurseNodes(cachedNodes[i], renderNode, state);
                    if (!result) {
                        i = cachedNodes.length;
                        return false;
                    }
                }
            }
        }
        return true;
    }

    function saveSelection(autoFocusOnRestore) {
        // Check if selection is valid
        var sel = $window.getSelection();
        if (sel.rangeCount < 1) {
            return;
        }

        var ranges = getAllRanges(sel);
        var backward = ranges.length === 1 && isSelectionBackwards(sel);
        var rangeInfos = saveRanges(ranges, backward);

        return {
            targetContainer: domSearchHelper.getInputElement(ranges[0].commonAncestorContainer),
            autoFocusOnRestore: autoFocusOnRestore !== false, //Default is true
            rangeInfos: rangeInfos,
            restored: false
        };
    }

    function restoreSelection(savedSel, ignoreDirection) {
        if (savedSel && !savedSel.restored) {
            if (savedSel.targetContainer && savedSel.autoFocusOnRestore) {
                savedSel.targetContainer.focus();
            }

            var rangeInfos = savedSel.rangeInfos,
                sel = $window.getSelection(),
                ranges = restoreRanges(rangeInfos),
                rangeCount = rangeInfos.length;

            if (rangeCount === 1 && !ignoreDirection && rangeInfos[0].backward) {
                sel.removeAllRanges();
                addRange(sel, ranges[0], true);
            } else {
                setRanges(sel, ranges);
            }
            savedSel.restored = true;
        }
    }

    function addRange(sel, range, backward) {
        if (backward) {
            selectRangeBackwards(sel, range);
        }
    }

    function setRanges(sel, ranges) {
        sel.removeAllRanges();
        for (var i = 0, len = ranges.length; i < len; ++i) {
            sel.addRange(ranges[i]);
        }
    }

    function restoreRanges(rangeInfos) {
        var ranges = [];

        // Ranges are in reverse order of appearance in the DOM. We want to restore earliest first to avoid
        // normalization affecting previously restored ranges.
        var rangeCount = rangeInfos.length;

        for (var i = rangeCount - 1; i >= 0; i--) {
            ranges[i] = restoreRange(rangeInfos[i]);
        }

        return ranges;
    }

    function restoreRange(rangeInfo) {
        var range = document.createRange();

        if (rangeInfo.collapsed) {
            var markerEl = document.getElementById(rangeInfo.markerId);

            if (markerEl) {
                markerEl.style.display = "inline";
                collapseBefore(range, markerEl);
                removeNode(markerEl);
            } else {
                throw new Error("Marker element has been removed. Cannot restore selection.");
            }
        } else {
            setRangeBoundary(range, rangeInfo.startMarkerId, true);
            setRangeBoundary(range, rangeInfo.endMarkerId, false);
        }

        return range;
    }

    function setRangeBoundary(range, markerId, atStart) {
        var markerEl = document.getElementById(markerId);

        if (markerEl) {
            range[atStart ? "setStartBefore" : "setEndBefore"](markerEl);
            removeNode(markerEl);
        } else {
            throw new Error("Marker element has been removed. Cannot restore selection.");
        }
    }

    function isSelectionBackwards() {
        var backwards = false;

        if ($window.getSelection) {
            var sel = $window.getSelection();

            // check if any text is selected
            if (!sel.isCollapsed) {
                var range = document.createRange();

                // 'anchorNode' is the node where selection begins.
                // 'anchorNode' ignores direction, so it will not move (doesn't matter which direction user selects).
                range.setStart(sel.anchorNode, sel.anchorOffset);

                // 'focusNode' is the node in which the selection ends.
                // 'focusNode' move with the selection direction.
                range.setEnd(sel.focusNode, sel.focusOffset);

                // So in case user selected backwards, 'anchorNode' & 'focusNode' will be at the same point, which means
                // that range is collapsed.
                backwards = range.collapsed;

                // 'detach' method releases a Range from use by the browser (simply speaking it does 'unbind/destroy/cleanup')
                range.detach();
            }
        }

        return backwards;
    }

    function selectRangeBackwards(sel, range) {
        if ($window.getSelection !== void 0) {
            var selection = sel || $window.getSelection();

            // select backward if 'extend' method is supported
            if (selection.extend !== void 0) {
                var endRange = range.cloneRange();
                endRange.collapse(false);
                selection.removeAllRanges();
                selection.addRange(endRange);
                selection.extend(range.startContainer, range.startOffset);
            }
            // fallback in case browser doesn't support 'extend'.
            // select by default from left-to-right
            else {
                sel.addRange(range);
            }
        }
    }

    function getAllRanges(sel) {
        var ranges = [];
        for (var i = 0; i < sel.rangeCount; i++) {
            ranges[i] = sel.getRangeAt(i);
        }
        return ranges;
    }

    function saveRanges(ranges, backward) {
        var rangeInfos = [],
            range;

        for (var i = 0, len = ranges.length; i < len; ++i) {
            rangeInfos[i] = saveRange(ranges[i], backward);
        }

        // Now that all the markers are in place and DOM manipulation over, adjust each range's boundaries to lie
        // between its markers
        for (i = len - 1; i >= 0; --i) {
            range = ranges[i];
            if (range.collapsed) {
                collapseAfter(range, document.getElementById(rangeInfos[i].markerId));
            } else {
                setEndBefore(range, document.getElementById(rangeInfos[i].endMarkerId));
                setStartAfter(range, document.getElementById(rangeInfos[i].startMarkerId));
            }
        }

        return rangeInfos;
    }

    function saveRange(range, backward) {
        var startEl,
            endEl,
            text = range.toString();

        if (range.collapsed) {
            endEl = insertRangeBoundaryMarker(range, false);

            return {
                markerId: endEl.id,
                collapsed: true
            };
        } else {
            endEl = insertRangeBoundaryMarker(range, false);
            startEl = insertRangeBoundaryMarker(range, true);

            return {
                startMarkerId: startEl.id,
                endMarkerId: endEl.id,
                collapsed: false,
                backward: backward,
                toString: function () {
                    return "original text: '" + text + "', new text: '" + range.toString() + "'";
                }
            };
        }
    }

    function generateUniqueMarkerId() {
        return selectionBoundary + "-" + Date.now() + "-" + helpers.guid();
    }

    function insertRangeBoundaryMarker(range, atStart) {
        // Clone the Range and collapse to the appropriate boundary point
        var boundaryRange = range.cloneRange();
        boundaryRange.collapse(atStart);

        // Create the marker element containing a single invisible character using DOM methods and insert it
        var markerEl = document.createElement("span");
        markerEl.id = generateUniqueMarkerId();
        markerEl.style.lineHeight = "0";
        markerEl.style.display = "none";
        markerEl.className = markerClassNamePrefix + "-" + selectionBoundary;
        // tslint:disable-next-line:no-unsafe-dom-insert-calls
        markerEl.appendChild(document.createTextNode(markerTextChar));

        boundaryRange.insertNode(markerEl);
        return markerEl;
    }

    function collapseAfter(range, node) {
        range.setStartAfter(node);
        range.collapse(true);
    }

    function setEndBefore(range, node) {
        range.setEndBefore(node);
    }

    function setStartAfter(range, node) {
        range.setStartAfter(node);
    }

    function collapseBefore(range, node) {
        range.setEndBefore(node);
        range.collapse(false);
    }

    function removeNode(node) {
        return !node || node.parentNode.removeChild(node);
    }

    function pasteHtmlAtCaret(html, selectPastedContent) {
        var sel, range;
        if ($window.getSelection) {
            // IE9 and non-IE
            sel = $window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();

                // Range.createContextualFragment() would be useful here but is
                // only relatively recently standardized and is not supported in
                // some browsers (IE9, for one)
                var el = document.createElement("div");
                el.innerHTML = pebbleSecurityService.sanitise(html);
                var frag = document.createDocumentFragment();
                var node = null;
                var lastNode = null;
                // tslint:disable-next-line:no-conditional-assignment
                while ((node = el.firstChild)) {
                    // tslint:disable-next-line:no-unsafe-dom-insert-calls
                    lastNode = frag.appendChild(node);
                }
                var firstNode = frag.firstChild;
                range.insertNode(frag);

                // Preserve the selection
                if (lastNode) {
                    range = range.cloneRange();
                    range.setStartAfter(lastNode);
                    if (selectPastedContent) {
                        range.setStartBefore(firstNode);
                    } else {
                        range.collapse(true);
                    }
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
            // tslint:disable-next-line:no-conditional-assignment
        } else if ((sel = document.selection) && sel.type !== "Control") {
            // IE < 9
            var originalRange = sel.createRange();
            originalRange.collapse(true);
            sel.createRange().pasteHTML(html);
            if (selectPastedContent) {
                range = sel.createRange();
                range.setEndPoint("StartToStart", originalRange);
                range.select();
            }
        }
    }

    function SelectionBookmark(element, selector) {
        this.element = element;
        this.selector = selector;
    }

    /**
     * @param {String=} bookmarkId
     * @returns {{start: SelectionBookmark|null, end: SelectionBookmark|null}}
     */
    function insertBookmark(bookmarkId) {
        var id = bookmarkId || helpers.guid();
        var selection = $window.getSelection();
        var bookmark = { start: null, end: null };

        if (selection.rangeCount > 0) {
            var range = selection.getRangeAt(0);
            var isCollapsed = range.collapsed;

            bookmark.start = createSelectionBookmark(id, range.collapsed);
            bookmark.end = bookmark.start;
            range.insertNode(bookmark.start.element);
            range.setStartAfter(bookmark.start.element);

            if (!isCollapsed) {
                bookmark.end = createSelectionBookmark(id, true);
                range.collapse(false);
                range.insertNode(bookmark.end.element);
                range.setEndBefore(bookmark.end.element);
            }
        }

        return bookmark;
    }

    /**
     * @param {String} id
     * @param {Boolean} isEnd
     * @returns {SelectionBookmark}
     */
    function createSelectionBookmark(id, isEnd) {
        var element = document.createElement(bookmarkDomInfo.tagName);
        var bookmarkAttr = bookmarkDomInfo.data;
        var selector = "[" + bookmarkAttr + '="' + id + '"]';

        element.textContent = " ";
        element.setAttribute(bookmarkAttr, id);

        if (isEnd) {
            var endBookmarkAttr = bookmarkDomInfo.dataEnd;
            var endBookMarkValue = "true";
            selector += "[" + endBookmarkAttr + '="' + endBookMarkValue + '"]';
            element.setAttribute(endBookmarkAttr, endBookMarkValue);
        }

        return new SelectionBookmark(element, selector);
    }

    /**
     *
     * @param {{start: SelectionBookmark, end: SelectionBookmark}} bookmark
     */
    function removeSelectionBookmark(bookmark) {
        if (bookmark.start && bookmark.start.element.parentNode) {
            bookmark.start.element.parentNode.removeChild(bookmark.start.element);
        }

        if (bookmark.end && bookmark.end.element.parentNode) {
            bookmark.end.element.parentNode.removeChild(bookmark.end.element);
        }

        bookmark.start = null;
        bookmark.end = null;
    }

    function isSelectionWithinElement(element) {
        const selection = window.getSelection();
        if (selection.rangeCount === 0) {
            return false;
        }

        const containsNode = (node) => node === element;
        const containsAnchorNode = selection.anchorNode === element || !!domSearchHelper.getElementFromDomTree(selection.anchorNode, containsNode, { node: element });
        const containsFocusNode = selection.focusNode === element || !!domSearchHelper.getElementFromDomTree(selection.focusNode, containsNode, { node: element });
        return containsAnchorNode && containsFocusNode;
    }
}
