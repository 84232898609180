import { IAnimationOptions } from "./interfaces/IAnimationOptions";

export const animateElement = async (element: HTMLElement, { keyframes, easing, duration }: IAnimationOptions): Promise<void> => {
    const prefersNoAnimations = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches;

    if (prefersNoAnimations) {
        const finalStyles: Partial<CSSStyleDeclaration> = {};

        for (const frame of keyframes) {
            Object.assign(finalStyles, frame);
        }

        Object.assign(element.style, finalStyles);
        return Promise.resolve();
    }

    return element
        .animate(keyframes, {
            duration: duration,
            easing: easing,
            fill: "forwards"
        })
        .finished.then((a) => {
            if (document.body.contains(element)) {
                a.commitStyles();
                a.cancel();
            }
        });
};
