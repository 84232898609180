export function getClosestDropdownContainer(element) {
    if (element.parentElement === null) {
        return null;
    }

    if (element.parentElement.dataset.dropdownContainer !== undefined) {
        return element.parentElement;
    }

    return getClosestDropdownContainer(element.parentElement);
}
