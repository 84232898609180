import { FunctionComponent } from "react";
import { ParseHtml } from "@pjs/security";
import { Notice } from "../notice/Notice.component";
import { IHtmlNoticeProps } from "./interfaces/IHtmlNoticeProps";
import "./styles/html-notice.css";

export const HtmlNotice: FunctionComponent<IHtmlNoticeProps> = (props) => {
    return (
        <Notice type={props.type} ariaLabel={props.ariaLabel}>
            <ParseHtml className="cui-html-notice__content" htmlString={props.content} />
        </Notice>
    );
};
