import { CkEditorElementStyleHelper } from "../helpers/ckEditorElementStyleHelper";

function CkEditorParagraphHangingIndentProcess(indentAmount, indentUnit) {
    this._indentAmount = indentAmount;
    this._indentUnit = indentUnit;
    this._textIndentAmount = indentAmount * -1;
    this._styleHelper = new CkEditorElementStyleHelper(null);
}

CkEditorParagraphHangingIndentProcess.prototype.applyStyle = function (element) {
    var textIndentAmount = this._textIndentAmount;
    var marginAmount = this._indentAmount;
    var currentIndentAmount = this._getIndentValues(element);

    if (!isNaN(currentIndentAmount.margin) && (isNaN(currentIndentAmount.text) || currentIndentAmount.text >= 0)) {
        marginAmount += currentIndentAmount.margin;
    }

    if (marginAmount > 0 && textIndentAmount < 0) {
        element.setStyle("margin-left", marginAmount + this._indentUnit);
        element.setStyle("text-indent", textIndentAmount + this._indentUnit);
    } else {
        this.removeStyle(element);
    }

    return element;
};

CkEditorParagraphHangingIndentProcess.prototype.removeStyle = function (element) {
    var currentIndentAmount = this._getIndentValues(element);

    if (currentIndentAmount.text < 0) {
        var currentMargin = currentIndentAmount.margin;

        var newMargin = currentMargin + currentIndentAmount.text;
        element.setStyle("margin-left", newMargin > 0 ? newMargin + this._indentUnit : "");
    }

    element.setStyle("text-indent", "");

    if (!element.getAttribute("style")) {
        element.removeAttribute("style");
    }
};

CkEditorParagraphHangingIndentProcess.prototype.applied = function (element) {
    var indentValues = this._getIndentValues(element);
    return indentValues.text === this._textIndentAmount && indentValues.margin >= this._indentAmount;
};

CkEditorParagraphHangingIndentProcess.prototype.onOutdent = function (element) {
    var indentValues = this._getIndentValues(element);
    if (indentValues.margin <= Math.abs(indentValues.text)) {
        element.setStyle("margin-left", "");
        element.setStyle("text-indent", "");

        if (!element.getAttribute("style")) {
            element.removeAttribute("style");
        }

        return true;
    }

    return false;
};

CkEditorParagraphHangingIndentProcess.prototype._getIndentValues = function (element) {
    this._styleHelper.setElement(element);

    return {
        text: this._styleHelper.getNumericValue("text-indent", this._indentUnit),
        margin: this._styleHelper.getNumericValue("margin-left", this._indentUnit)
    };
};

export { CkEditorParagraphHangingIndentProcess };
