import { angularAMD } from "@pebblepad/amd";

angularAMD.filter("bytesToSize", [
    "multiLanguageService",
    function (multiLanguageService) {
        return function (bytes) {
            if (bytes === 0) {
                return multiLanguageService.getString("file_uploads.labels.zeroBytes");
            } else if (!bytes) {
                return "";
            }

            var k = 1024;
            var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            var i = Math.floor(Math.log(bytes) / Math.log(k));
            return (bytes / Math.pow(k, i)).toFixed(2) + " " + sizes[i];
        };
    }
]);
