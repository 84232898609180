export enum EventType {
    Blur = "blur",
    Input = "input",
    ContextMenu = "contextmenu",
    DragStart = "dragstart",
    KeyDown = "keydown",
    KeyUp = "keyup",
    MouseDown = "mousedown",
    MouseUp = "mouseup",
    TouchEnd = "touchend",
    TouchStart = "touchstart"
}
