import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./templates/standards-selection-checkbox-group.html";

import "../../../capabilityCriteriaService/capabilityCriteriaService";

class StandardsSelectionCheckboxGroupComponent extends NgComponent {
    constructor(capabilityCriteriaService) {
        super();

        this.capabilityCriteriaService = capabilityCriteriaService;
        this.capabilityInfoByUniqueId = {};

        this.standards.forEach((standard) => {
            const showCriteria = this.capabilityCriteriaService.isCriteriaAvailable(standard.Criteria.MinimumEvidenceRequirement);

            this.capabilityInfoByUniqueId[standard.uniqueId] = {
                showCriteria: showCriteria,
                ariaDescribedById: showCriteria ? `${standard.ElementId}${standard.uniqueId}_criteria` : ""
            };
        });
    }

    setChecked(event, pageFormId, pageId, formElementId, assetRefId) {
        this.onChecked({ event, pageFormId, pageId, formElementId, assetRefId });
    }
}

export const StandardsSelectionCheckboxGroup = {
    bindings: {
        onChecked: "&",
        pageFormId: "<",
        pageId: "<",
        standards: "<",
        assetRefId: "<"
    },
    template: template,
    controller: StandardsSelectionCheckboxGroupComponent
};

StandardsSelectionCheckboxGroup.$inject = ["capabilityCriteriaService"];
angularAMD.component("standardsSelectionCheckboxGroup", StandardsSelectionCheckboxGroup);
