import { angularAMD } from "@pebblepad/amd";
import { reactEditorAdapterBindings } from "../reactEditorAdapter/ReactEditorAdapterBindings.const";
import { ReactEditorAdapterController } from "../reactEditorAdapter/ReactEditorAdapterController";
import "../react2angular/persistentCompactMultiLineEditor";
import template from "./persistent-compact-rich-text-editor.html";

export const persistentCompactRichTextEditorDefinition = {
    bindings: {
        autoFocus: "<",
        ...reactEditorAdapterBindings
    },
    controller: ReactEditorAdapterController,
    name: "persistentCompactRichTextEditor",
    template: template
};

angularAMD.component(persistentCompactRichTextEditorDefinition.name, persistentCompactRichTextEditorDefinition);
