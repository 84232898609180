import { angularAMD } from "@pebblepad/amd";
import { globalAnchorSanitiseEventHandler, HtmlStringSanitiser, HtmlSanitiserEvent } from "@pjs/security";
import { HTML_INPUT } from "../constants/htmlInput.constants";
import "./selection.service";
import "./nodeAttributeModifier.service";
import "../utilities/helpers";
import "../utilities/escapeHelper.service";
import "../utilities/typeCheck.service";
import "../utilities/domSearchHelper.service";
import "../utilities/eventHelper.service";
import "../userAgent/userAgent.service";

angularAMD.factory("PasteService", PasteService);
PasteService.$inject = ["$sanitize", "$timeout", "SelectionService", "NodeAttributeModifier", "helpers", "EscapeHelper", "typeCheck", "domSearchHelper", "EventHelper", "UserAgentService"];

var $sanitize = null,
    $timeout = null,
    SelectionService = null,
    NodeAttributeModifier = null,
    helpers = null,
    EscapeHelper = null,
    typeCheck = null,
    domSearchHelper = null,
    EventHelper = null,
    UserAgentService;

function PasteService(_$sanitize_, _$timeout_, _SelectionService_, _NodeAttributeModifier_, _helpers_, _EscapeHelper_, _typeCheck_, _domSearchHelper_, _EventHelper_, _UserAgentService_) {
    $sanitize = _$sanitize_;
    $timeout = _$timeout_;
    SelectionService = _SelectionService_;
    NodeAttributeModifier = _NodeAttributeModifier_;
    helpers = _helpers_;
    EscapeHelper = _EscapeHelper_;
    typeCheck = _typeCheck_;
    domSearchHelper = _domSearchHelper_;
    EventHelper = _EventHelper_;
    UserAgentService = _UserAgentService_;

    return PasteServiceClass;
}

// Static class variables
PasteServiceClass.defaultSanitizeConfig = {
    ALLOWED_TAGS: HTML_INPUT.BLOCK_TAGS_WHITELIST.concat(HTML_INPUT.INLINE_TAGS_WHITELIST),
    ALLOWED_ATTR: HTML_INPUT.ATTRIBUTES_WHITELIST.slice(),
    FORBID_ATTR: [],
    FORBID_TAGS: [],
    RETURN_DOM: false
};

PasteServiceClass.dummyContainerId = "on-paste-secret-container";
PasteServiceClass.focusAnchorId = "focus-target-element";
// Service Class
function PasteServiceClass(opts) {
    opts = opts || {};
    this.onSanitize = opts.onSanitize || false;
    this.pastePlain = opts.pastePlain || false;
    this.onPasteComplete = opts.onPasteComplete || null;
    this.selection = null;
    this.target = opts.target || null; // if no target specified, then automatic script will loop the
    // DOM to get first possible contenteditable

    this.attributeModifiers = opts.attributeModifiers || NodeAttributeModifier.getAttributeDefaults();
    this.nodeModifiers = opts.nodeModifiers || NodeAttributeModifier.getNodeDefaults();

    const sanitiserConfig = Object.assign({}, PasteServiceClass.defaultSanitizeConfig, opts.sanitizeConfig || {});
    this._sanitiser = HtmlStringSanitiser.createFromWhitelist({ attributes: sanitiserConfig.ALLOWED_ATTR, tags: sanitiserConfig.ALLOWED_TAGS });
    this._sanitiser.on(HtmlSanitiserEvent.BeforeElement, globalAnchorSanitiseEventHandler);
    this._sanitiser.on(HtmlSanitiserEvent.BeforeElement, this.sanitiseNode.bind(this));
    this._sanitiser.on(HtmlSanitiserEvent.AfterAttribute, this.sanitiseAttributes.bind(this));
}

PasteServiceClass.prototype.enable = function () {
    if (this.pastePlain === false && UserAgentService.ie && UserAgentService.version === 10) {
        // For IE10, the browser will only paste HTML if a
        // contenteditable div is selected before paste.
        // Luckily, the browser fires a before paste event which
        // lets us switch the focus to the appropriate element.
        this.onBeforePasteHandler = this.onBeforePaste.bind(this);
        document.addEventListener("beforepaste", this.onBeforePasteHandler, true);
        //Have to disable right click for IE10 to prevent issue with right click triggering beforepaste event, even when the paste option never gets chosen.
        this.target.addEventListener("contextmenu", EventHelper.stopEvent, false);
    }

    this.onPasteHandler = this.onPaste.bind(this);
    document.addEventListener("paste", this.onPasteHandler);
    document.addEventListener("drop", this.onContentDrop);
};

PasteServiceClass.prototype.disable = function () {
    if (this.onBeforePasteHandler) {
        document.removeEventListener("beforepaste", this.onBeforePasteHandler, true);
    }
    if (this.onPasteHandler) {
        document.removeEventListener("paste", this.onPasteHandler);
    }

    document.removeEventListener("drop", this.onContentDrop);
    this.target.removeEventListener("contextmenu", EventHelper.stopEvent);
};

PasteServiceClass.prototype.completePaste = function (pastedRawData, target, autoFocusOnRestore) {
    //Set the autoFocusOnRestore state for the saved selection. If false, the SelectionService does not focus twice on the parent - resets scroll pos to top of container in Safari
    if (this.selection) {
        this.selection.autoFocusOnRestore = autoFocusOnRestore;
    }

    // 1. restore user selection
    SelectionService.restoreSelection(this.selection);

    // 2. sanitize pastedData
    var sanitizedPastedData = this.sanitizeData(pastedRawData);

    // 3. put data at caret
    SelectionService.pasteHtmlAtCaret(sanitizedPastedData);

    // 4. focus at caret
    this.focusAtCaret(target, autoFocusOnRestore);
    if (typeCheck.isFunction(this.onPasteComplete)) {
        this.onPasteComplete();
    }
};

PasteServiceClass.prototype.focusAtCaret = function (target, autoFocusOnRestore) {
    // create focusable element that we put on caret position to perform focus
    var focusTarget = this.createFocusableCaretAnchor();

    // save caret position before because it's going to be removed after we add focusable element at caret position
    this.selection = SelectionService.saveSelection(autoFocusOnRestore);

    // paste focusable element at caret position
    SelectionService.pasteHtmlAtCaret(focusTarget);

    // select focusable element to focus on it
    focusTarget = document.getElementById(PasteServiceClass.focusAnchorId);
    focusTarget.focus();

    // remove focusable element after focus
    focusTarget.parentNode.removeChild(focusTarget);

    // restore our saved caret position to where it was after last action
    SelectionService.restoreSelection(this.selection);

    if (target) {
        target.focus();
    }
};

PasteServiceClass.prototype.createFocusableCaretAnchor = function () {
    return '<div id="' + PasteServiceClass.focusAnchorId + '" ' + 'tabindex="1" style="margin-top: 5px; width: 1px; height: 1px; opacity: 0;"></div>';
};

PasteServiceClass.prototype.sanitizeData = function (dirtyData) {
    const blockElementWhiteSpaceRegex = new RegExp(`(</?(?:${HTML_INPUT.BLOCK_TAGS_WHITELIST.join("|")}))>\\s+<`, "g");

    var regexResult = dirtyData
        .replace(/([a-z])?(\r\n|\n)/gi, "$1 ")
        .replace(blockElementWhiteSpaceRegex, "$1><")
        .replace(/<\/html>.+/g, "</html>");

    var clean = this._sanitiser.clean(regexResult);

    if (this.onSanitize) {
        clean = this.onSanitize(clean);
    }

    clean = this.checkIfOnlyEmptyTags(clean);

    return clean;
};

PasteServiceClass.prototype.checkIfOnlyEmptyTags = function (data) {
    // if plain contains nothing, then paste nothing instead of empty tags.
    // e.g. you paste <p><span><img src=""></span></p>, but <img> is not allowed,
    // so it will be wiped and you will get: <p><span></span></p>
    // it is pointless to paste empty tags, so we check if there is any non-html content.
    if (!helpers.htmlToPlain(data)) {
        return "";
    }

    return data;
};

PasteServiceClass.prototype.onBeforePaste = function () {
    this.focusInDummyContainer();
};

PasteServiceClass.prototype.onContentDrop = function (e) {
    e.preventDefault();
};

PasteServiceClass.prototype.getDataFromClipboard = function (e, type, ieType) {
    return UserAgentService.ie ? window.clipboardData.getData(ieType) : (e.originalEvent || e).clipboardData.getData(type);
};

PasteServiceClass.prototype.pastePlainContent = function (e) {
    if (e.type === "paste") {
        //If user pasted data
        e.preventDefault();

        var text = "";
        var clipboardData = this.getDataFromClipboard(e, "text/plain", "Text"); //Get data from clipboard as plain text
        //$sanitize could throw an error if some funky / invalid HTML has been pasted in. If an error occurs, handle it - strip HTML chars and output escaped string
        try {
            text = $sanitize(clipboardData);
            // tslint:disable-next-line:no-empty
        } catch (e) {}

        if (clipboardData && !text) {
            //If $sanitize parsed some blacklisted HTML and there was some text, strip HTML chars and output escaped string
            text = EscapeHelper.convertHTMLChars(clipboardData);
        }

        //For damn IE - Vlad, 2016
        if (!document.queryCommandSupported("insertHTML")) {
            SelectionService.pasteHtmlAtCaret(text); //Fix for unimplemented in IE 'execCommand("insertHTML")'
        } else {
            document.execCommand("insertHTML", false, text);
        }

        if (typeCheck.isFunction(this.onPasteComplete)) {
            this.onPasteComplete();
        }
    }
};

PasteServiceClass.prototype.onPaste = function (e) {
    if (this.target !== e.target && !this.target.contains(e.target)) {
        return;
    }

    if (this.pastePlain === true) {
        return this.pastePlainContent(e);
    }
    //Check if e.clipboardData exists and has data (standards compliant - <= IE 11). If not then use workaround custom paste.
    if (!this.hasEventClipboardSupport(e)) {
        this.clipboardEvent("non-standard", e);
    } else {
        //Check content types. If there is a type of text/HTML, run custom paste / sanitisation. If not, fallback and treat as paste content as plain text.
        if (this.clipboardContainsHTMLContent(e.clipboardData)) {
            this.clipboardEvent("standard", e);
            e.preventDefault();
        } else if (this.clipboardContainsHTMLWebArchive(e.clipboardData)) {
            //If there is no text/HTML, check if Mac Safari Webarchive is available - Default HTML clipboard value for Safari
            this.clipboardEvent("non-standard", e);
        } else {
            return this.pastePlainContent(e);
        }
    }
};

PasteServiceClass.prototype.getTargetEl = function (e) {
    var targetEl = e.target;

    var foundTargetEl = domSearchHelper.getInputElement(targetEl);
    if (foundTargetEl !== null) {
        targetEl = foundTargetEl;
    }

    return targetEl;
};

PasteServiceClass.prototype.clipboardEvent = function (type, e) {
    var targetEl = this.target || this.getTargetEl(e);

    switch (type) {
        case "non-standard":
            this.clipboardEventNonStandard(targetEl);
            break;
        case "standard":
            this.clipboardEventStandard(e, targetEl);
            break;
    }
};

PasteServiceClass.prototype.clipboardEventStandard = function (e, targetEl) {
    var clipboardData = e.clipboardData;
    this.completePaste(clipboardData.getData("text/html"), targetEl, true);
};

PasteServiceClass.prototype.clipboardEventNonStandard = function (targetEl) {
    if (!(UserAgentService.ie && UserAgentService.version === 10)) {
        this.focusInDummyContainer();
    }

    $timeout(
        function () {
            this.completePaste(this.getDataFromDummyContainer(), targetEl, false);
            this.destroyDummyContainer();
        }.bind(this),
        0
    );
};

PasteServiceClass.prototype.selectDummyContainer = function () {
    return document.getElementById(PasteServiceClass.dummyContainerId);
};

PasteServiceClass.prototype.focusInDummyContainer = function () {
    var container = this.selectDummyContainer();

    if (!container) {
        this.selection = SelectionService.saveSelection();
        container = document.createElement("DIV");
        container.id = PasteServiceClass.dummyContainerId;
        container.className = "editor";
        container.contentEditable = true;
        container.style.position = "fixed";
        container.style.left = "-99999px";
        container.style.width = "1px";
        container.style.height = "1px";
        container.style.overflow = "hidden";
        container.style.opacity = 0;
        // tslint:disable-next-line:no-unsafe-dom-insert-calls
        document.body.appendChild(container);
    }

    container.focus();

    return container;
};

PasteServiceClass.prototype.getDataFromDummyContainer = function () {
    var containerEl = this.selectDummyContainer();
    var content = containerEl.innerHTML;
    containerEl.innerHTML = "";
    return content;
};

PasteServiceClass.prototype.destroyDummyContainer = function () {
    var containerEl = this.selectDummyContainer();
    if (containerEl) {
        containerEl.parentNode.removeChild(containerEl);
    }
};

PasteServiceClass.prototype.sanitiseNode = function (originalNode) {
    var node = originalNode;
    for (var i = 0, l = this.nodeModifiers.length; i < l; i++) {
        node = this.nodeModifiers[i](node);
    }

    return node;
};

PasteServiceClass.prototype.sanitiseAttributes = function (node) {
    for (var i = 0, l = this.attributeModifiers.length; i < l; i++) {
        this.attributeModifiers[i](node);
    }

    if (node.getAttribute("style") === "") {
        node.removeAttribute("style");
    }
};

PasteServiceClass.prototype.hasEventClipboardSupport = function (e) {
    var support = e && e.clipboardData && e.clipboardData.getData && e.clipboardData.types;
    return !!support;
};

PasteServiceClass.prototype.clipboardContainsHTMLContent = function (clipboardData) {
    return Array.prototype.indexOf.call(clipboardData.types, "text/html") > -1;
};

PasteServiceClass.prototype.clipboardContainsHTMLWebArchive = function (clipboardData) {
    var clipboardDataTypesArray = Array.prototype.slice.call(clipboardData.types); //Forcefully convert clipboard.types to array to ensure all browsers behave the same.
    return clipboardDataTypesArray.indexOf("com.apple.webarchive") > -1 && clipboardDataTypesArray.indexOf("text/plain") > -1;
};

const _moduleExport = new PasteService();
export { _moduleExport as PasteService };
