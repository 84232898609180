import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";
import "../builder/dataManager/helperService";
import template from "./templates/asset-list-item.html";

angularAMD.directive("assetListItem", AssetListItem);
AssetListItem.$inject = ["$sce", "baseUrlsFactory"];

angularAMD.controller("AssetListItemController", AssetListItemController);
AssetListItemController.$inject = ["$rootScope", "$filter", "multiLanguageService", "helperService"];

function AssetListItem($sce, baseUrlsFactory) {
    return {
        template: template,
        restrict: "E",
        scope: {
            model: "=",
            url: "@",
            listItemIndex: "=",
            listCount: "=",
            disableInfoButton: "="
        },
        controller: "AssetListItemController",
        controllerAs: "vm",
        bindToController: true
    };
}

function AssetListItemController($rootScope, $filter, multiLanguageService, helperService) {
    this.services = {
        broadcast: $rootScope.$broadcast.bind($rootScope),
        helperService: helperService
    };

    this.subText = multiLanguageService.getString("asset_summary.action_by_name_on_date", {
        Action: this.model.SubText,
        Author: this.model.Author,
        Time: $filter("pebbleDate")(this.model.HistoryDateTime, "timeOnly"),
        Date: $filter("pebbleDate")(this.model.HistoryDateTime, "withoutDay")
    });

    this.countIndexText = multiLanguageService.getString("asset_summary.link_item_count_text", {
        LinkNumber: this.listItemIndex + 1,
        Total: this.listCount
    });

    this.linkAriaLabel = multiLanguageService.getString("asset_summary.open_asset_link_aria", {
        LinkItemCount: this.listItemIndex >= 0 ? this.countIndexText : "",
        AssetTitle: this.model.AssetTitle,
        AssetType: this.services.helperService.friendlyMainType(this.model.AssetMainType),
        Action: this.model.SubText.toLowerCase(),
        Author: this.model.Author,
        Time: $filter("pebbleDate")(this.model.HistoryDateTime, "timeOnly"),
        Date: $filter("pebbleDate")(this.model.HistoryDateTime, "withoutDay")
    });

    this.infoButtonAriaLabel = multiLanguageService.getString("asset_summary.asset_info_link_aria", {
        AssetTitle: this.model.AssetTitle,
        AssetType: this.services.helperService.friendlyMainType(this.model.AssetMainType)
    });
}

AssetListItemController.prototype.openAssetInfoPanel = function (assetId) {
    this.services.broadcast("openRightHandSidePanel", {
        panel: "asset-info",
        assetId: assetId
    });
};
