import { angularAMD } from "@pebblepad/amd";
import "./attachment/attachment.component";
import "../multiLanguageService/multiLanguageService";
import template from "./attachments.html";

var attachmentsDefinition = {
    bindings: {
        items: "<"
    },
    controller: Attachments,
    template: template
};

Attachments.$inject = ["multiLanguageService"];
function Attachments(multiLanguageService) {
    this.multiLanguageService = multiLanguageService;
}

angularAMD.component("attachments", attachmentsDefinition);

export { attachmentsDefinition as attachments };
