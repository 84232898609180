import { IDebouncer } from "./interfaces/IDebouncer";

export class Debouncer implements IDebouncer {
    public readonly run: () => void;
    private readonly _delay: number;
    private readonly _callback: () => void;
    private readonly _onTimeout: () => void;
    private _timeoutId: number;

    constructor(delay: number, callback: () => void) {
        this._delay = delay;
        this._callback = callback;
        this._timeoutId = 0;
        this._onTimeout = this.flush.bind(this);
        this.run = this._run.bind(this);
    }

    public cancel(): void {
        window.clearTimeout(this._timeoutId);
        this._timeoutId = 0;
    }

    public flush(): void {
        if (this._timeoutId !== 0) {
            this.cancel();
            this._callback();
        }
    }

    private _run(): void {
        this.cancel();
        this._timeoutId = window.setTimeout(this._onTimeout, this._delay);
    }
}
