import { ASSET_CONSTANTS } from "../constants/asset.constants";

/**
 * @param {object} dataManagerService
 * @param {string=} linkedAsset
 * @param {string=} backgroundAssetId
 * @returns {PromiseLike<{ assetId: string, manager: object }>}
 */
export function createPost(dataManagerService, linkedAsset = "", backgroundAssetId = "") {
    const assetOptions = linkedAsset === "" ? [] : [{ Key: "AddTo", Value: linkedAsset }];

    return dataManagerService
        .init({
            AssetId: null,
            Asset: {
                MainType: ASSET_CONSTANTS.TYPES.POST,
                AddDefaultBannerSection: true,
                BackgroundAssetId: backgroundAssetId,
                AssetOptions: assetOptions
            }
        })
        .then(() => {
            const manager = dataManagerService.postManagerObject;
            return { assetId: manager.AssetId, manager: manager };
        });
}
