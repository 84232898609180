import { angularAMD } from "@pebblepad/amd";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/baseUrlsFactory";
import "../../numberPicker/numberPicker";
import "../../builder/dataManager/helperService";
import "../../screenReaderAnnouncer/screenReaderAnnouncer.directive";
import "../../utilities/restrictDecimals.directive";
import "../../utilities/helpers";
import "../../react2angular/htmlNotice";
import { warningNotice } from "@pjs/core-ui";
import template from "../templates/activity-log-info.html";

angularAMD.directive("activityLogInfo", [
    "$sce",
    "multiLanguageService",
    "baseUrlsFactory",
    "$http",
    "helperService",
    "helpers",
    function ($sce, multiLanguageService, baseUrlsFactory, $http, helperService, helpers) {
        return {
            template: template,
            restrict: "E",
            scope: {
                assetId: "@",
                assetOptions: "=",
                type: "@",
                disableEdit: "<"
            },
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    //Variables --------------------------------------------------------------------------------------------
                    const labelType = multiLanguageService.getString("accessibility.notice.type.hours_and_points");
                    $scope.warningLabel = multiLanguageService.getString("accessibility.notice.labels.warning", { type: labelType });
                    $scope.warningNotice = warningNotice;
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.hoursAndPointsWarning = $scope.multiLanguageService.getString("labels.signatures.hours_and_points_warning");
                    $scope.shouldDisplayWarning = false;
                    $scope.invalidTypes = ["ActivityLog"];
                    $scope.assetValues = {
                        points: null,
                        hours: null,
                        minutes: null
                    };

                    $scope.points = {
                        value: null,
                        placeholder: $scope.multiLanguageService.getString("sidebar.activity_logs.placeholders.add_points"),
                        ariaLabel: $scope.multiLanguageService.getString("sidebar.activity_logs.placeholders.add_points"),
                        min: 0,
                        cancelAriaLabel: $scope.multiLanguageService.getString("sidebar.activity_logs.aria_labels.clear_points"),
                        focus: false,
                        type: "points"
                    };
                    $scope.time = {
                        hours: {
                            value: null,
                            placeholder: $scope.multiLanguageService.getString("sidebar.activity_logs.placeholders.add_hours"),
                            ariaLabel: $scope.multiLanguageService.getString("sidebar.activity_logs.placeholders.add_hours"),
                            min: 0,
                            cancelAriaLabel: $scope.multiLanguageService.getString("sidebar.activity_logs.aria_labels.clear_hours"),
                            focus: false,
                            type: "hours"
                        },
                        minutes: {
                            value: null,
                            placeholder: $scope.multiLanguageService.getString("sidebar.activity_logs.placeholders.add_minutes"),
                            ariaLabel: $scope.multiLanguageService.getString("sidebar.activity_logs.placeholders.add_minutes"),
                            min: 0,
                            cancelAriaLabel: $scope.multiLanguageService.getString("sidebar.activity_logs.aria_labels.clear_minutes"),
                            focus: false,
                            type: "minutes"
                        }
                    };

                    $scope.viewData = {
                        validType: false,
                        type: "",
                        editMode: false,
                        autoCalculatePoints: false,
                        autoCalculateMinutes: false,
                        autoCalculateLabel: multiLanguageService.getString("sidebar.activity_logs.auto_calculated"),
                        hasPoints: false,
                        hasTime: false,
                        pointsLabel: "",
                        hoursLabel: "",
                        minutesLabel: "",
                        images: {
                            hours: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/images/hours.svg"),
                            points: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/images/points.svg")
                        }
                    };
                    $scope.activityAnnouncerMessage = "";
                    $scope.viewModeOnly = true;
                    if ($scope.assetId && $scope.disableEdit === false) {
                        $http.defaults.withCredentials = true;
                        $http.get(baseUrlsFactory.api_base_url + "Locking/IsLockedByAnother?assetId=" + $scope.assetId).then(function (response) {
                            $scope.viewModeOnly = response.data;
                        });

                        if ($scope.type === "FormResponse") {
                            $http
                                .get(baseUrlsFactory.api_base_url + "AssetDto/GetSignatureInformation?assetId=" + $scope.assetId)
                                .then((response) => {
                                    const signatureInformation = response.data;
                                    $scope.shouldDisplayWarning = signatureInformation.some((signature) => signature.IsSigned);
                                    const containsSignedWarning = signatureInformation.some((signature) => signature.InvalidationLevel === "warnWhenEdited" && signature.IsSigned);
                                    const containsSignedBlank = signatureInformation.some((signature) => signature.InvalidationLevel === "blankWhenEdited" && signature.IsSigned);

                                    if (!containsSignedWarning && containsSignedBlank) {
                                        $scope.hoursAndPointsWarning = $scope.multiLanguageService.getString("labels.signatures.hours_and_points_blank_warning");
                                    } else {
                                        $scope.hoursAndPointsWarning = $scope.multiLanguageService.getString("labels.signatures.hours_and_points_generic_warning");
                                    }
                                })
                                .catch(() => {
                                    $scope.shouldDisplayWarning = false;
                                });
                        }
                    }

                    //Functions --------------------------------------------------------------------------------------------
                    $scope.setDataFromAssetOptions = function () {
                        const hours = $scope.assetOptions.where({ Key: "CpdTimeInMins" })[0];
                        const points = $scope.assetOptions.where({ Key: "CpdPoints" })[0];

                        const autoCalculatePoints = $scope.assetOptions.where({ Key: "AutoCalculatePoints" })[0];
                        const autoCalculateMinutes = $scope.assetOptions.where({ Key: "AutoCalculateMinutes" })[0];
                        $scope.viewData.autoCalculatePoints = autoCalculatePoints !== undefined && autoCalculatePoints.Value === "true";
                        $scope.viewData.autoCalculateMinutes = autoCalculateMinutes !== undefined && autoCalculateMinutes.Value === "true";

                        if (points && points.Value) {
                            const number = parseFloat(points.Value);
                            $scope.points.value = number ? number : null;
                            $scope.viewData.hasPoints = true;
                        }

                        if (hours && hours.Value) {
                            $scope.updateTimeValues(hours.Value);
                            $scope.viewData.hasTime = true;
                        }

                        $scope.assetValues.hours = $scope.time.hours.value;
                        $scope.assetValues.minutes = $scope.time.minutes.value;
                        $scope.assetValues.points = $scope.points.value;
                        $scope.updateMultiLangStrings();
                    };

                    $scope.updateMultiLangStrings = function () {
                        $scope.viewData.hoursLabel = multiLanguageService.getString("sidebar.activity_logs.n_logged_hours", { count: $scope.assetValues.hours || 0 });
                        $scope.viewData.minutesLabel = multiLanguageService.getString("sidebar.activity_logs.n_logged_minutes", { count: $scope.assetValues.minutes || 0 });
                        $scope.viewData.pointsLabel = multiLanguageService.getString("sidebar.activity_logs.n_logged_points", { count: $scope.assetValues.points || 0 });
                    };

                    $scope.saveValues = function () {
                        $http.post(baseUrlsFactory.api_base_url + "Asset/UpdateHoursAndPoints", {
                            AssetId: $scope.assetId,
                            Hours: $scope.time.hours.value,
                            Minutes: $scope.time.minutes.value,
                            Points: $scope.points.value
                        });
                    };

                    $scope.updateTimeValues = function (timeValue) {
                        const timeObj = helpers.formatTime(timeValue),
                            minutes = timeObj.minutes << 0;

                        if (timeObj.hours || timeObj.minutes) {
                            $scope.time.hours.value = timeObj.hours;
                            $scope.time.minutes.value = minutes;
                        } else {
                            $scope.time.hours.value = null;
                            $scope.time.minutes.value = null;
                        }
                    };

                    /**
                     * Change the editMode value and initialise fields
                     * @param {Boolean} value
                     * @param {Number} [numberPickerModel]
                     */
                    $scope.updateEditMode = function (value, numberPickerModel) {
                        $scope.viewData.editMode = value;
                        $scope.time.hours.focus = false;
                        $scope.time.minutes.focus = false;
                        $scope.points.focus = false;
                        $scope.activityAnnouncerMessage = "";

                        if (value === true) {
                            $scope.time.hours.value = $scope.assetValues.hours;
                            $scope.time.minutes.value = $scope.assetValues.minutes;
                            $scope.points.value = $scope.assetValues.points;
                            $scope.updateMultiLangStrings();
                            if (numberPickerModel) {
                                numberPickerModel.focus = true;
                            }
                        } else {
                            $scope.activityAnnouncerMessage = $scope.multiLanguageService.getString("sidebar.activity_logs.hours_points_saved");
                        }
                    };

                    $scope.submitKeypress = function (event) {
                        if (event.keyCode === 13) {
                            $scope.onConfirm();
                        }
                        event.stopImmediatePropagation();
                    };
                    $scope.onConfirm = function () {
                        //Update stored values
                        $scope.updateTimeValues($scope.time.hours.value * 60 + $scope.time.minutes.value);
                        $scope.assetValues.hours = $scope.time.hours.value;
                        $scope.assetValues.minutes = $scope.time.minutes.value;
                        $scope.assetValues.points = $scope.points.value;
                        $scope.updateMultiLangStrings();

                        //Update view information
                        $scope.setViewData();
                        $scope.saveValues();

                        $scope.$emit("infoPanelLocalDataUpdate", "CpdTimeInMins", $scope.time.hours.value * 60 + $scope.time.minutes.value);
                        $scope.$emit("infoPanelLocalDataUpdate", "CpdPoints", $scope.points.value);
                        $scope.updateEditMode(!$scope.viewData.editMode);
                    };

                    $scope.setViewData = function () {
                        $scope.viewData.hasTime = $scope.assetValues.hours !== null || $scope.assetValues.minutes !== null;
                        $scope.viewData.hasPoints = $scope.assetValues.points > 0 || $scope.assetValues.points !== null;
                    };
                }
            ],

            link: function (scope, element, attrs) {
                if (scope.invalidTypes.indexOf(scope.type) === -1) {
                    scope.viewData.validType = true;
                    scope.setDataFromAssetOptions();
                    scope.setViewData();
                }
                scope.viewData.type = helperService.friendlyMainType(scope.type);
            }
        };
    }
]);
