import { dialogDirector } from "@pjs/core-ui";
import { angularAMD } from "@pebblepad/amd";

export class DialogRouteChangeService {
    constructor($rootScope) {
        this._$rootScope = $rootScope;
        this._trackedDialogs = [];
        this.init();
    }

    init() {
        this._$rootScope.$on("$routeChangeSuccess", () => {
            dialogDirector.flush();

            for (const dialogObject of this._trackedDialogs) {
                if (dialogObject.destroy !== undefined) {
                    dialogObject.destroy();
                }
            }

            this._trackedDialogs = [];
        });
    }

    addTrackedDialog(dialogObject) {
        this._trackedDialogs.push(dialogObject);
    }

    removeTrackedDialog(dialogId) {
        const index = this._trackedDialogs.findIndex((dialog) => dialog.scope.modalId === dialogId);

        if (index !== -1) {
            this._trackedDialogs.splice(index, 1);
        }
    }
}

DialogRouteChangeService.$inject = ["$rootScope"];
angularAMD.service("dialogRouteChangeService", DialogRouteChangeService);
