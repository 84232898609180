import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { StickyElementCoordinator } from "./StickyElementCoordinator";

class StickyContainerController extends NgComponent {
    constructor($element) {
        super();

        this.coordinator = new StickyElementCoordinator($element[0]);
    }

    $onDestroy() {
        this.coordinator.destroy();
    }
}
StickyContainerController.$inject = ["$element"];

function StickyContainer() {
    return {
        restrict: "A",
        controller: StickyContainerController
    };
}

angularAMD.directive("stickyContainer", StickyContainer);
