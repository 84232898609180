import { IHtmlTagTransform } from "./interfaces/IHtmlTagTransform";

/**
 * @description Only to be used in conjunction with a HtmlStringParser and IHtmlStringParserConfig.
 */
export abstract class SandboxedTagSwap<T extends Element = Element, R extends Element = Element> implements IHtmlTagTransform {
    private readonly _newTagName: string;

    constructor(newTagName: string) {
        this._newTagName = newTagName;
    }

    public transform(sandboxedElement: Element): Element | null {
        // New element must be created safely. `document.createElement` cannot be used.
        // In this context, `element.ownerDocument` will be the sandbox document which is created as part of the sanitisation process
        const newElement: Element = sandboxedElement.ownerDocument.createElement(this._newTagName);
        this._swap(sandboxedElement as T, newElement as R, sandboxedElement.ownerDocument);
        return newElement;
    }

    protected abstract _swap(sandboxedElement: T, newElement: R, sandboxedDocument: Document): void;
}
