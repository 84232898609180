import { angularAMD } from "@pebblepad/amd";
import { focusTrapper } from "@pjs/core-ui";
import { createGuid } from "@pjs/utilities";

export class FocusTrapService {
    constructor() {
        this._traps = new Map();
    }

    addTrap(container, ariaContainers) {
        const trapId = createGuid();
        this._hideContainers(ariaContainers);

        this._traps.set(trapId, {
            remove: focusTrapper.trap(container),
            ariaContainers: ariaContainers
        });

        return trapId;
    }

    removeTrap(trapId) {
        if (!this._traps.has(trapId)) {
            return;
        }

        const trap = this._traps.get(trapId);
        this._traps.delete(trapId);
        trap.remove();

        for (const ariaContainer of trap.ariaContainers) {
            if (this._shouldShowContainer(ariaContainer)) {
                ariaContainer.removeAttribute("aria-hidden");
            }
        }
    }

    _hideContainers(ariaContainers) {
        for (const ariaContainer of ariaContainers) {
            ariaContainer.setAttribute("aria-hidden", "true");
        }
    }

    _shouldShowContainer(ariaContainer) {
        if (this._traps.size === 0) {
            return true;
        }

        for (const trap of this._traps.values()) {
            if (trap.ariaContainers.includes(ariaContainer)) {
                return false;
            }
        }

        return true;
    }
}

FocusTrapService.$inject = [];
angularAMD.service("focusTrapService", FocusTrapService);
