import { angularAMD } from "@pebblepad/amd";

// tslint:disable-next-line:no-empty
function RegexHelper() {}

RegexHelper.prototype.decimalOrInt = function (decimalPlaces, min) {
    var optionalMinusSymbol = min !== undefined && min >= 0 ? "" : "-?";
    if (decimalPlaces > 0) {
        return new RegExp("^" + optionalMinusSymbol + "([\\d]*)\\.?([\\d]{1," + decimalPlaces + "})?$");
    }
    return new RegExp("^" + optionalMinusSymbol + "[0-9]*$");
};

RegexHelper.$inject = [];
angularAMD.service("regexHelper", RegexHelper);

export { RegexHelper as regexHelper };
