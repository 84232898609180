import { angularAMD } from "@pebblepad/amd";
import "../inlineConfirm/inlineConfirm.component";
import "../inlineDecision/inlineDecision.component";
import template from "./dropdown-wrapper.html";

var dropdownWrapperDefinition = {
    bindings: {
        icon: "@",
        options: "<",
        customClass: "@",
        onOptionSelect: "&",
        dropdownLabel: "<",
        isDisabled: "<?",
        disabledToolTip: "<?"
    },
    template: template,
    controller: DropdownWrapperComponent
};

DropdownWrapperComponent.$inject = ["$scope", "$timeout"];
function DropdownWrapperComponent($scope, $timeout) {
    this.confirming = false;
    this.activeOption = null;
    this.isDisabled = this.isDisabled || false;
    this.disabledToolTip = this.disabledToolTip || "";
    this.dropdownAttachMenuEvent = `attachA11yMenu-${$scope.$id}`;

    this.$scope = $scope;
    this.$timeout = $timeout;

    $scope.onClose = this.onDropdownClose.bind(this);
}

DropdownWrapperComponent.prototype.selectOption = function (option) {
    option.onConfirm();
};

DropdownWrapperComponent.prototype.attachMenu = function () {
    if (this.activeOption === null) {
        this.$timeout(
            function () {
                this.$scope.$broadcast(this.dropdownAttachMenuEvent);
            }.bind(this)
        );
    }
};

DropdownWrapperComponent.prototype.onUpdate = function (option, confirming) {
    if (confirming) {
        this.activeOption = option;
    }
};

DropdownWrapperComponent.prototype.onDropdownClose = function () {
    if (this.activeOption !== null) {
        this.confirming = false;
        this.activeOption = null;
    }
};

angularAMD.component("dropdownWrapper", dropdownWrapperDefinition);

export { dropdownWrapperDefinition as dropdownWrapper };
