import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";

export function selectFirstItem(_: KeyboardEvent): Partial<Immutable<IAriaModelWithType>> {
    return {
        activeItemIndex: 0,
        type: AriaMenuStatus.Open
    };
}
