export function withDigest(callback, scope) {
    return (...args) => {
        callback(...args);
        /**
         * Safety check for quirky React integrations.
         * $apply should normally be safe, however if a component triggered a callback from a lifecycle event within a digest (did mount, did update), then 💥
         */
        if (scope.$$phase === null && scope.$root.$$phase === null) {
            scope.$apply();
            return;
        }

        scope.$evalAsync();
    };
}
