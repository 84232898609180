import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../assetViews/sidebar/shares/shares";
import "../../../assetViews/sidebar/submissions/submissions";
import "../../../tagger/tagger";
import "../assetBaseInfo/assetBaseInfo";
import "../../../profileImage/profileImg";
import "../institutionInfo/institutionInfo";
import "./assetBaseInfoService";
import "../reportInappropriate/directives/reportInappropriate";
import "../../../activityLogs/directives/activityLogInfo";
import "../../../utilities/pebbleDate";
import "../../../multiLanguageService/multiLanguageService";
import "../../../assetViews/sidebar/shares/deletableShare.service";
import "../../../statusToolbar/services/statusToolbar.service";
import "../../../statusToolbar/factories/statusToolbar.factory";
import "../../../dynamicStatusServiceLoader/dynamicStatusServiceLoader.service";
import "../../../featureSettings/FeatureSettings";
import template from "./templates/asset-info.html";

angularAMD.directive("assetInfo", [
    "$sce",
    "$rootScope",
    "$compile",
    "$http",
    "$timeout",
    "$location",
    "$routeParams",
    "$filter",
    "baseUrlsFactory",
    "assetBaseInfoService",
    "multiLanguageService",
    "DeletableShareService",
    "StatusToolbar",
    "StatusToolbarFactory",
    "DynamicStatusServiceLoader",
    "featureSettings",
    function (
        $sce,
        $rootScope,
        $compile,
        $http,
        $timeout,
        $location,
        $routeParams,
        $filter,
        baseUrlsFactory,
        assetBaseInfoService,
        multiLanguageService,
        DeletableShareService,
        statusToolbar,
        statusToolbarFactory,
        DynamicStatusServiceLoader,
        featureSettings
    ) {
        return {
            template: template,
            restrict: "E",
            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                    $scope.assetInfoLoading = $rootScope.multiLanguageService.getString("sidebar.asset_info_loading");
                    $scope.toolbarHandler = statusToolbarFactory.createHandler($timeout, 3);

                    DynamicStatusServiceLoader.getAssetLockedService().then((assetLockedStatusService) => {
                        const preexistingLockStatusBar = assetLockedStatusService.createStatus(false, "");
                        $scope.toolbarHandler.addStatus(preexistingLockStatusBar, true);
                    });
                }
            ],

            link: function (scope, element, attrs) {
                var assetPromise;
                scope.nonDeletableShares = {};
                scope.collaboratorIds = [];

                scope.scrollToSection = function () {
                    $timeout(function () {
                        var jumpTo_el = element[0].getElementsByClassName(scope.jumpTo.class)[0];
                        if (jumpTo_el) {
                            scope.jumpTo.scroll_el.scrollTop = jumpTo_el.offsetTop;
                        }
                    });
                };

                scope.focusElement = function () {
                    $timeout(function () {
                        var focus_el = element[0].getElementsByClassName(scope.elementToFocus.class)[0];
                        if (focus_el) {
                            focus_el.focus();
                        }
                    });
                };

                scope.cacheElems = function () {
                    scope.tooltip_boundary_el = angular.element(element[0].getElementsByClassName("asset-info-wrapper")[0]);
                };

                scope.init = function () {
                    scope.cacheElems();
                    scope.tooltip = multiLanguageService.getString("tips.tags.adding_tags.content");

                    const multilangKey = scope.baseInfo.IsFavourite && featureSettings.isEnabled("Favourites") ? "sidebar.favourite_asset_info_announcement" : "sidebar.asset_info_announcement";

                    scope.screenReaderAnnouncement = multiLanguageService.getString(multilangKey, {
                        asset_name: scope.baseInfo.Title,
                        created_date: $filter("pebbleDate")(scope.baseInfo.CreatedOn, "withDay"),
                        modified_date: $filter("pebbleDate")(scope.baseInfo.ModifiedOn, "withDay"),
                        created_time: $filter("pebbleDate")(scope.baseInfo.CreatedOn, "timeOnly"),
                        modified_time: $filter("pebbleDate")(scope.baseInfo.ModifiedOn, "timeOnly")
                    });

                    if (scope.jumpTo) {
                        $timeout(scope.scrollToSection);
                    }

                    if (scope.elementToFocus) {
                        $timeout(scope.focusElement);
                    }
                };

                if ($routeParams.assetId === scope.asset.Id) {
                    scope.showPreview = false;
                }

                assetBaseInfoService.getBaseInfo(scope.asset.Id).then(function (response) {
                    scope.collaboratorIds = response.data.Collaborators.map(function (c) {
                        return c.Id;
                    });
                    DeletableShareService.getNonDeletableShareIds(scope.asset.Shares, response.data.Collaborators).map(function (id) {
                        scope.nonDeletableShares[id] = true;
                    });
                    scope.viewerActions = $rootScope.viewerActions;
                    scope.baseInfo = response.data;

                    if (scope.viewerActions && !scope.viewerActions.edit) {
                        scope.baseInfo.CurrentUserCanEdit = false;
                        scope.baseInfo.CurrentUserCanChangeTitle = false;
                    }

                    scope.init();
                    scope.$emit("infoPanelContentLoaded", true, !scope.jumpTo);
                });

                scope.$on("infoPanelLocalDataUpdate", function (evt, key, val) {
                    var opts = scope.asset.AssetOptions.where({ Key: key })[0];
                    if (opts !== undefined) {
                        opts.Value = val;
                    } else {
                        scope.asset.AssetOptions.push({ Key: key, Value: val });
                    }
                });
            }
        };
    }
]);
