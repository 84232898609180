import { angularAMD } from "@pebblepad/amd";
import "../assetEndpointService/assetEndpoint.service";
import { STATUS_PERMISSIONS_CONSTANTS } from "../constants/statusPermissions.constants.js";

export class UserStatusPermissions {
    constructor($q, assetEndpointService) {
        this._$q = $q;
        this._assetEndpointService = assetEndpointService;
        this._permissionsCache = new Map();
        this._pendingRequests = new Map();
    }

    canSelfAssess(assetId) {
        return this._getPermission(STATUS_PERMISSIONS_CONSTANTS.CAN_SELF_ASSESS, assetId);
    }

    canMap(assetId) {
        return this._getPermission(STATUS_PERMISSIONS_CONSTANTS.CAN_MAP, assetId);
    }

    canViewProgressChart(assetId) {
        return this._getPermission(STATUS_PERMISSIONS_CONSTANTS.CAN_VIEW_PROGRESS_CHART, assetId);
    }

    canViewPendingList(assetId) {
        return this._getPermission(STATUS_PERMISSIONS_CONSTANTS.CAN_VIEW_PENDING_LIST, assetId);
    }

    canApprove(assetId) {
        return this._getPermission(STATUS_PERMISSIONS_CONSTANTS.CAN_APPROVE, assetId);
    }

    _getPermission(key, assetId) {
        const permission = this._permissionsCache.get(key);
        if (permission !== undefined) {
            return this._$q.when(permission);
        }

        const pending = this._pendingRequests.get(key);
        if (pending !== undefined) {
            return pending;
        }

        const request = this._assetEndpointService
            .getCreatorPermission(key, assetId)
            .then((response) => {
                const value = response.data;
                this._permissionsCache.set(key, value);
                return value;
            })
            .catch(() => {
                return false;
            })
            .finally(() => this._pendingRequests.delete(key));

        this._pendingRequests.set(key, request);
        return request;
    }
}

UserStatusPermissions.$inject = ["$q", "AssetEndpointService"];
angularAMD.service("userStatusPermissions", UserStatusPermissions);
