import { useRef } from "react";
import { AnyClass } from "./types/AnyClass";

export const useClassRef = <T extends AnyClass>(classType: T, ...args: ConstructorParameters<T>): InstanceType<T> => {
    const classRef = useRef<InstanceType<T> | null>(null);

    if (classRef.current === null) {
        classRef.current = new classType(...args);
    }

    return classRef.current as InstanceType<T>;
};
