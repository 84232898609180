import { tracker } from "@pjs/analytics";
import { noop } from "@pjs/utilities";
import { DecoupledEditor } from "@pebblepad/ckeditor";
import { SlowProcessReporter } from "../../../utils/slow-process-reporter/SlowProcessReporter";
import { Behaviour } from "../types/Behaviour";
import { generateElapsedTimeEventName } from "../../../utils/slow-process-reporter/GenerateElapsedTimeEventName.function";

export const slowUndoRedoLogging: Behaviour = (editor) => {
    editor.enabled.subscribe((enabledState) => {
        if (!enabledState.isEnabled) {
            return;
        }

        trackCommandExecutions(enabledState.editor);
    });

    return noop;
};

const trackCommandExecutions = (editor: DecoupledEditor): void => {
    const reportSlowUndo = (elapsedTime: number): void => {
        tracker.trackEvent("CKE5 Input", "Undo/Redo", generateElapsedTimeEventName(elapsedTime), editor.model.document.version);
    };

    const reportSlowBatchUndoRedo = (elapsedTime: number): void => {
        tracker.trackEvent("CKE5 Input", "Batched Undo/Redo", generateElapsedTimeEventName(elapsedTime), editor.model.document.version);
    };

    const undoRedoReporter = new SlowProcessReporter(reportSlowUndo, 450, 10000);
    const batchReporter = new SlowProcessReporter(reportSlowBatchUndoRedo, 1000, 10000);

    const undoCommand = editor.commands.get("undo");
    const redoCommand = editor.commands.get("redo");

    const highestPriority = { priority: "highest" } as const;
    const lowestPriority = { priority: "lowest" } as const;
    const batchBufferPeriod = 350;

    let batchCount = 0;
    let lastExecutedTime = 0;

    const beforeExecute = (): void => {
        if (batchCount === 0) {
            batchReporter.start();
        } else if (lastExecutedTime + batchBufferPeriod <= Date.now()) {
            batchCount = 0;
            lastExecutedTime = 0;
            batchReporter.cancel();
        }

        undoRedoReporter.start();
        batchCount++;
    };

    const afterExecute = (): void => {
        lastExecutedTime = Date.now();
        if (batchCount === 3) {
            batchReporter.stop();
            batchCount = 0;
        }

        undoRedoReporter.stop();
    };

    if (undoCommand !== undefined) {
        undoCommand.on("execute", beforeExecute, highestPriority);
        undoCommand.on("execute", afterExecute, lowestPriority);
    }

    if (redoCommand !== undefined) {
        redoCommand.on("execute", beforeExecute, highestPriority);
        redoCommand.on("execute", afterExecute, lowestPriority);
    }
};
