import { angularAMD } from "@pebblepad/amd";
import "../../navigation/navigationList/navigationList";
import "./services/assetProgressTrackingService";
import "../../../react2angular/progressVisualisation";
import template from "./templates/asset-progress-tracking.html";

angularAMD.directive("assetProgressTracking", [
    "$sce",
    "$q",
    "$http",
    "$rootScope",
    "$routeParams",
    "$timeout",
    "baseUrlsFactory",
    "assetProgressTrackingService",
    function ($sce, $q, $http, $rootScope, $routeParams, $timeout, baseUrlsFactory, assetProgressTrackingService) {
        return {
            template: template,
            restrict: "E",
            scope: true,

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    $scope.presetData = function () {
                        $scope.loadInfo();
                        $scope.filterDropdownOptions = $scope.getFilterDropdownOptions();
                        $scope.setCurrentDropdownOption($scope.filterDropdownOptions[0], true);
                        $scope.multiLanguageService = $rootScope.multiLanguageService;
                        $scope.assetId = $attrs.assetId;
                    };

                    $scope.cacheElems = function () {
                        if ($scope.diagramSectionEl && $scope.diagramSectionEl[0]) {
                            return;
                        }
                        $scope.diagramSectionEl = angular.element(document.getElementsByClassName("asset-progress-tracking__diagram-section")[0]);
                    };

                    $scope.setDiagramVisibility = function (currentFilterDropdownOption) {
                        $scope.cacheElems();

                        if (currentFilterDropdownOption.value.value === "all" || (currentFilterDropdownOption.value.hide === true && currentFilterDropdownOption.value.value === "NotTracked")) {
                            $scope.diagramSectionEl.removeClass("inactive");
                        } else {
                            $scope.diagramSectionEl.addClass("inactive");
                        }
                    };

                    $scope.setCurrentDropdownOption = function (item, processData) {
                        $scope.currentFilterDropdownOption = item;
                        $scope.setDiagramVisibility($scope.currentFilterDropdownOption);
                        if (processData === true) {
                            return;
                        }
                        $scope.progressTrackingInfo = $scope.getProcessedTrackingData($scope.progressTrackingInfoOrig, $scope.currentFilterDropdownOption);
                    };

                    $scope.getFilterDropdownOptions = function () {
                        return [
                            {
                                value: { hide: true, value: "NotTracked" },
                                label: $rootScope.multiLanguageService.getString("labels.toolbar.progress_tracking_filter_options.hideNotTracked")
                            },
                            {
                                value: { hide: false, value: "all" },
                                label: $rootScope.multiLanguageService.getString("labels.toolbar.progress_tracking_filter_options.all")
                            },
                            {
                                value: { hide: false, value: "Completed" },
                                label: $rootScope.multiLanguageService.getString("labels.toolbar.progress_tracking_filter_options.completed")
                            },
                            {
                                value: { hide: false, value: "InProgress" },
                                label: $rootScope.multiLanguageService.getString("labels.toolbar.progress_tracking_filter_options.inProgress")
                            },
                            {
                                value: { hide: false, value: "NotStarted" },
                                label: $rootScope.multiLanguageService.getString("labels.toolbar.progress_tracking_filter_options.notStarted")
                            },
                            {
                                value: { hide: false, value: "NotTracked" },
                                label: $rootScope.multiLanguageService.getString("labels.toolbar.progress_tracking_filter_options.notTracked")
                            }
                        ];
                    };

                    $scope.onProgressDataSuccess = function (success) {
                        $scope.progressTrackingInfoOrig = angular.copy($scope.getProcessedTrackingData(success));
                        $scope.progressTrackingInfo = $scope.getProcessedTrackingData($scope.progressTrackingInfoOrig, $scope.currentFilterDropdownOption);
                    };

                    $scope.isParentType = function (type) {
                        $scope.parentType = ["WorkBook", "WorkBookResponse"];
                        return $scope.parentType.indexOf(type) > -1;
                    };

                    $scope.filterDataByStatus = function (data, filter) {
                        var arr = [],
                            item;

                        if (filter.value.value === "all") {
                            return data.Children;
                        }

                        for (var i = 0, len = data.Children.length; i < len; i++) {
                            item = data.Children[i];

                            if (item.Children && item.Children.length > 0) {
                                item.Children = $scope.filterDataByStatus(item, filter);
                                if (item.Children.length > 0) {
                                    if (item.Children.length === 1 && $scope.isParentType(item.Children[0].PageType)) {
                                        arr.push(item.Children[0]);
                                    } else {
                                        arr.push(item);
                                    }
                                }
                            } else if ((filter.value.hide === false && item.TrackingStatus === filter.value.value) || (filter.value.hide === true && item.TrackingStatus !== filter.value.value)) {
                                arr.push(item);
                            }
                        }

                        return arr;
                    };

                    $scope.getProcessedTrackingData = function (data, filterOptions) {
                        data = angular.copy(data);
                        if (filterOptions) {
                            data.Children = $scope.filterDataByStatus(data, filterOptions);
                        }

                        return data;
                    };

                    $scope.getTrackingStatus = function (item) {
                        // tslint:disable-next-line:triple-equals
                        if (item.Completed == true) {
                            return { status: "Completed", icon: "completed" };
                        } else if (item.NotTracked === true) {
                            return { status: "NotTracked", icon: "not-tracked" };
                        } else if (item.Progress > 0) {
                            return { status: "InProgress", icon: "in-progress" };
                        } else if (item.Progress === 0) {
                            return { status: "NotStarted", icon: "not-started" };
                        }
                    };

                    $scope.temporaryDataNormalizer = function (data) {
                        data = data.slice();

                        var item;
                        for (var i = 0, len = data.length; i < len; i++) {
                            item = data[i];
                            if (i === 1) {
                                data[i].Progress = 1;
                            }

                            for (var prop in item) {
                                if (item.hasOwnProperty(prop)) {
                                    if (prop === "Title" || prop === "Type" || prop === "Id") {
                                        item["Page" + prop] = item[prop];
                                        delete item[prop];
                                    }
                                    var trackingStatusInfo = $scope.getTrackingStatus(item);
                                    item["TrackingStatus"] = trackingStatusInfo.status;
                                    item["TrackingIcon"] = trackingStatusInfo.icon;
                                    item["PageIcon"] = "activitylog";
                                }
                            }
                            if (item.Children.length > 0) {
                                item.Children = $scope.temporaryDataNormalizer(item.Children);
                            }
                        }

                        return data;
                    };

                    $scope.onProgressDataFail = function (fail) {
                        $element.addClass("progress-tracking-error-occurred");

                        if (fail && fail.data && typeof fail.data === "string") {
                            $scope.errorMessage = fail.data;
                        } else {
                            $scope.errorMessage = $rootScope.multiLanguageService.getString("labels.toolbar.progress_tracking_errors.other");
                        }
                    };

                    $scope.loadInfo = function () {
                        assetProgressTrackingService
                            .getProgressData($scope.assetId || $routeParams.assetId, $routeParams.submissionId, $routeParams.timeslice)
                            .then($scope.onProgressDataSuccess, $scope.onProgressDataFail)
                            .finally($scope.onContentLoaded);
                    };

                    $scope.onContentLoaded = function () {
                        $timeout(function () {
                            $scope.$emit("infoPanelContentLoaded", true, true);
                        }, 80);
                    };

                    $scope.bindEvents = function () {
                        $scope.$offRouteUpdate = $scope.$on("$routeUpdate", function (event, location) {
                            if (location.params.pageId) {
                                $scope.$emit("spaMenuClosePanels");
                            }
                        });

                        $scope.$on("$scope -> $destroy", function () {
                            $scope.unbindEvents();
                        });

                        $element.on("$destroy", function () {
                            $scope.unbindEvents();
                        });
                    };

                    $scope.unbindEvents = function () {
                        $scope.$offRouteUpdate();
                    };

                    $scope.setup = function () {
                        $scope.presetData();
                        $scope.bindEvents();
                    };

                    $scope.progressDiagramOptions = {
                        duration: 1200,
                        lineWidth: 6,
                        progressColour: "#0566a9",
                        showPercentage: true,
                        size: 180,
                        strokeColour: "#ddd"
                    };
                }
            ],

            link: function (scope, element, attrs) {
                scope.setup();
            }
        };
    }
]);
