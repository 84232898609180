import { angularAMD } from "@pebblepad/amd";

angularAMD.provider("A11yContainersService", A11yContainersServiceProvider);

function A11yContainersServiceProvider() {
    this.elements = {
        spaMenu: null,
        app: null,
        modal: null
    };
    this.$get = function () {
        return this.elements;
    }.bind(this);
}
