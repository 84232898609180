const constants = {
    RATING_SLIDER: {
        DEFAULT_VALUES: {
            MIN: 0,
            MAX: 100,
            PROGRESS_COLOUR: "#0566A9",
            RATING_VALUE: 0,
            THUMB_HEIGHT: 25,
            THUMB_WIDTH: 25,
            STEP: 1,
            RATING_TYPES: {
                SLIDER: "Slider",
                NONE: "None"
            }
        },
        AGGREGATE_COLOUR: "#6010A7"
    },
    BREADCRUMB: {
        TYPES: {
            MAPPING: "Mapping",
            STANDARD: "Standard"
        }
    },
    STATUS: {
        PENDING: "Pending",
        APPROVED: "Approved",
        REJECTED: "Rejected"
    },
    ERROR: {
        FEEDBACK: "feedback",
        APPROVAL: "approval",
        SAVE: "save"
    },
    CAPABILTY_SAVE_RESPONSES: {
        ADD_FAIL: "add_failed",
        ADD_SUCCESS: "add_success",
        REMOVE_FAIL: "remove_failed",
        REMOVE_SUCCESS: "remove_success",
        UPDATE_FAIL: "update_fail",
        UPDATE_SUCCESS: "update_success"
    },
    EVIDENCE_MODE: {
        ONE_JUSTIFICATION_FOR_ALL_ITEMS: "single",
        JUSTIFICATION_PER_ITEM: "multiple"
    }
};

export { constants as MAP_TO_STANDARDS_CONSTANTS };
