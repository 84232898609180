import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import "../../../tagger/tagger";
import "../../../loadingSpinner/loadingSpinner";
import "../../services/collectionService";
import "../../../builder/dataManager/dtoFactory";
import template from "../templates/criteria-tagger.html";

angularAMD.directive("criteriaTagger", [
    "$sce",
    "baseUrlsFactory",
    "multiLanguageService",
    "$rootScope",
    "collectionService",
    "dtoFactory",
    function ($sce, baseUrlsFactory, multiLanguageService, $rootScope, collectionService, dtoFactory) {
        return {
            restrict: "E",
            template: template,
            scope: {
                wrapperId: "@", //String Id of CollectionWrapperDto
                collection: "=", //CollectionDto
                onConfirm: "=", //Callback Function
                onCancel: "=" //Callback Function
            },

            controller: [
                "$scope",
                function ($scope) {
                    //Initialise variables ---------------------------------------------------------------------------------
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.criteria = {
                        tags: []
                    };

                    $scope.viewData = {
                        saving: false
                    };

                    //Functions --------------------------------------------------------------------------------------------
                    $scope.validCriteria = function () {
                        var criteria = $scope.criteria;
                        return criteria.tags.length !== 0;
                    };

                    $scope.cancel = function () {
                        $scope.criteria.tags.length = 0;
                        $scope.onCancel();
                    };

                    $scope.loadFromExistingCriteria = function (collectionDto) {
                        //Pre-load data from Collection Dto
                        $scope.criteria.tags = $scope.collection.Search.Tags.slice(0);
                        doSearch();
                    };

                    $scope.addCriteria = function () {
                        $scope.viewData.saving = true;
                        var collectionCriteria = $scope.collection;
                        if (!collectionCriteria) {
                            collectionCriteria = new dtoFactory.CollectionDto({});
                            collectionCriteria.Search.ExcludeAssetIds = [$scope.wrapperId];
                        }
                        collectionCriteria.Search.Tags = $scope.criteria.tags.slice(0);
                        $scope.onConfirm(collectionCriteria);
                    };

                    //Private functions ------------------------------------------------------------------------------------
                    var eventUpdate = function (event) {
                        event.stopPropagation();
                        doSearch();
                    };

                    var doSearch = function () {
                        var filterData = null;
                        if ($scope.validCriteria()) {
                            filterData = {
                                SearchType: "asset",
                                TypeFilters: [],
                                Tags: $scope.criteria.tags
                            };
                        }
                        $scope.$emit("criteriaUpdated", filterData);
                    };

                    //Events -----------------------------------------------------------------------------------------------
                    $scope.$on("tagAdded", eventUpdate);
                    $scope.$on("tagRemoved", function (event, tag) {
                        $scope.validCriteria();
                        eventUpdate(event);
                    });

                    $scope.$on("$destroy", function () {
                        if ($scope.watcher) {
                            $scope.watcher();
                        }
                        $scope.criteria.tags.length = 0;
                    });
                }
            ],

            link: function (scope, element, attr) {
                //Init -------------------------------------------------------------------------------------------------
                var init = function () {
                    scope.watcher = scope.$watch("collection", function () {
                        if (scope.collection) {
                            scope.loadFromExistingCriteria(scope.collection);
                            scope.watcher();
                        }
                    });
                };
                init();
            }
        };
    }
]);
