import { Icon } from "../../../icon/Icon.component";
import { IIconWithTextAutoCompleteItemProps } from "./interfaces/IIconWithTextAutoCompleteItem";
import "./styles/icon-with-text-auto-complete-item.css";

export function IconWithTextAutoCompleteItem(item: IIconWithTextAutoCompleteItemProps): JSX.Element {
    return (
        <li className="cui-icon-with-text-auto-complete-item" key={item.id}>
            <Icon className="cui-icon-with-text-auto-complete-item__icon" source={item.icon.source} fill={item.icon.fill} />
            <span className="cui-icon-with-text-auto-complete-item__text">{item.value}</span>
            {item.label !== undefined && item.label.length > 0 && <span className="cui-icon-with-text-auto-complete-item__label">{item.label}</span>}
        </li>
    );
}
