import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import template from "./search-bar.html";
import "../multiLanguageService/multiLanguageService";

class SearchBarComponent extends NgComponent {
    constructor(multiLanguageService, $timeout, $element) {
        super();

        this._multiLanguageService = multiLanguageService;
        this._$timeout = $timeout;
        this._$element = $element;
        this._previousSearchText = "";
        this.data = {
            searchText: "",
            options: null
        };
    }

    $onInit() {
        const lightButtonStyle = "light";

        if (this.options === undefined) {
            this.options = {};
        }
        if (this.searchDisabled === undefined) {
            this.searchDisabled = false;
        }

        this.data.options = {
            debounceDelay: this.options.debounceDelay || 300,
            searchButtonClass: this.options.buttonStyle === lightButtonStyle ? "btn-success-alt" : "btn-success",
            dataHookName: this.options.dataHookName !== undefined ? `${this.options.dataHookName}-` : "",
            inputAria: this.options.inputAria || this._multiLanguageService.getString("search_bar.a11y.input"),
            clearButtonAria: this.options.clearButtonAria || this._multiLanguageService.getString("search_bar.a11y.clear_button"),
            searchButtonAria: this.options.searchButtonAria || this._multiLanguageService.getString("search_bar.a11y.search_button"),
            clearButtonTitle: this.options.clearButtonTitle || this._multiLanguageService.getString("search_bar.titles.clear_button"),
            searchButtonTitle: this.options.searchButtonTitle || this._multiLanguageService.getString("search_bar.titles.search_button")
        };
        this._setSearchBarHints();
    }

    $onChanges(changes) {
        if (changes.searchDisabled !== undefined && !changes.searchDisabled.isFirstChange()) {
            this._setSearchBarHints();
        }
    }

    clearSearchText() {
        this._$timeout.cancel(this._searchTimeout);
        this.data.searchText = "";
        this._previousSearchText = "";
        this.onClear();
        this._$element[0].querySelector(".search-bar__input-wrapper input").focus();
    }

    search(fromOnChange) {
        const resetSearch = this._previousSearchText.length !== this.data.searchText.length && this.data.searchText.length <= 2 && this._previousSearchText.length > 2;

        if (this.data.searchText.length > 2 || resetSearch) {
            this._$timeout.cancel(this._searchTimeout);
            this._previousSearchText = this.data.searchText;

            if (fromOnChange === true) {
                this._searchTimeout = this._$timeout(() => this._doSearch(resetSearch), this.data.options.debounceDelay);
            } else {
                this._doSearch();
            }
        }
    }

    _doSearch(resetSearch = false) {
        if (resetSearch) {
            this.onClear();
        } else {
            const output = {
                searchText: this.data.searchText
            };
            this.onSearch(output);
        }
    }

    _setSearchBarHints() {
        if (this.searchDisabled === true && this.options.disabledPlaceholder !== undefined) {
            this.data.options.placeholder = this.options.disabledPlaceholder;
            this.data.options.toolTip = this.options.disabledPlaceholder;
        } else {
            this.data.options.placeholder = this.options.placeholder || this._multiLanguageService.getString("search_bar.placeholder");
            this.data.options.toolTip = "";
        }
    }
}

const searchBarDefinition = {
    bindings: {
        onSearch: "&",
        onClear: "&",
        options: "<?",
        searchDisabled: "<?"
    },
    template: template,
    controller: SearchBarComponent
};

SearchBarComponent.$inject = ["multiLanguageService", "$timeout", "$element"];
angularAMD.component("searchBar", searchBarDefinition);
export { searchBarDefinition as searchBar };
