import { fromPascalToCamel, httpService, IResponseError, WebRequestFactory } from "@pjs/http";
import { Observable } from "@pjs/observables";
import { apiConfig } from "../../../ApiConfig";
import { mapFeedbackStatements } from "../../mappers/feedbackStatementsMapper/feedbackStatementsMapper.function";
import { FeedbackStatementsList } from "../../mappers/feedbackStatementsMapper/types/FeedbackStatementsList";
import { FeedbackStatementsApiResponse } from "../../mappers/feedbackStatementsMapper/types/FeedbackStatementsApiResponse";

const request = WebRequestFactory.createGet<FeedbackStatementsList, { submissionId: number }>({
    errorMapper: (errorResponse: Response) => Promise.resolve<IResponseError>({ code: errorResponse.status.toString(), headers: errorResponse.headers }),
    http: httpService,
    responseMapper: fromPascalToCamel<FeedbackStatementsApiResponse, FeedbackStatementsList>(mapFeedbackStatements),
    url: (params) => apiConfig.getFeedbackStatements(params.submissionId)
});

export const getFeedbackStatements = (submissionId: number): Observable<FeedbackStatementsList> => {
    return request({ submissionId: submissionId });
};
