import { angularAMD } from "@pebblepad/amd";

angularAMD.service("pageReloadService", PageReloadService);
PageReloadService.$inject = ["helperService"];

function PageReloadService(helperService) {
    this.isUpdated = false;

    this.notifyOnUpdate = function () {
        if (!helperService.isNotInAsset()) {
            this.isUpdated = true;
        }
    };

    this.locationReloadOnSidePanelClose = function () {
        if (this.isUpdated) {
            location.reload();
        }

        this.isUpdated = false;
    };
}
