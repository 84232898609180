import { angularAMD } from "@pebblepad/amd";
import { SIZES } from "../constants/size.constants";
import "../multiLanguageService/multiLanguageService";
import "../utilities/baseUrlsFactory";
import "../utilities/pebbleDate";
import template from "../userInfo/user-info.html";

var userInfoDefinition = {
    bindings: {
        additionalInfo: "@",
        data: "<",
        type: "@"
    },
    template: template,
    controller: UserInfo
};

const defaultProfilePicSize = "25";
const largeProfilePicSize = "35";

UserInfo.$inject = ["$filter", "baseUrlsFactory", "multiLanguageService"];
function UserInfo($filter, baseUrlsFactory, multiLanguageService) {
    this.SIZES = SIZES;
    this.$filter = $filter;
    this.baseUrlsFactory = baseUrlsFactory;
    this.multiLanguageService = multiLanguageService;

    this.profilePicSize = defaultProfilePicSize;
    this.profileImageLink = "";
}

UserInfo.prototype.$onInit = function () {
    if (this.type === this.SIZES.SIZE_LARGE) {
        this.profilePicSize = largeProfilePicSize;
    }

    if (this.data.Poster !== undefined) {
        this.profileImageLink = this._getProfileImageLink(this.data.Poster.ProfilePicId);
    }
};

UserInfo.prototype._getProfileImageLink = function (profile_image_id) {
    return this.baseUrlsFactory.api_base_url + "File/TransmitProfilePic?id=" + profile_image_id + "&x=" + this.profilePicSize + "&y=" + this.profilePicSize + "&shortSideScale=True";
};

angularAMD.component("userInfo", userInfoDefinition);
export { userInfoDefinition as userInfo };
