import { angularAMD } from "@pebblepad/amd";

export class StandardsInformation {
    constructor($window) {
        this.standardsData = {};
        this.$window = $window;
    }

    setStandardsData(selectedStandards, reflection, standards, assetId, workbookId, mainAssetId, hasActiveApproval, preventMappingChanges) {
        this.standardsData[assetId] = {
            selectedStandards: selectedStandards,
            reflection: reflection,
            standardsData: standards,
            assetId: assetId,
            workbookId: workbookId,
            mainAssetId: mainAssetId,
            hasActiveApproval: hasActiveApproval,
            preventMappingChanges: preventMappingChanges
        };

        this.$window.sessionStorage.setItem(`standardsInformation-${assetId}`, JSON.stringify(this.standardsData[assetId]));
    }

    getStandardsData(assetId) {
        if (this.standardsData[assetId] !== undefined) {
            return this.standardsData[assetId];
        } else {
            return JSON.parse(this.$window.sessionStorage.getItem(`standardsInformation-${assetId}`));
        }
    }

    removeStorageStandardsData(assetId) {
        if (this.standardsData[assetId] !== undefined) {
            delete this.standardsData[assetId];
        }
        this.$window.sessionStorage.removeItem(`standardsInformation-${assetId}`);
    }

    setNavigationalUrl(assetId, url) {
        this.$window.sessionStorage.setItem(`mappingHistory-${assetId}`, url);
    }

    getNavigationalUrl(assetId) {
        return this.$window.sessionStorage.getItem(`mappingHistory-${assetId}`);
    }

    removeNavigationalUrl(assetId) {
        this.$window.sessionStorage.removeItem(`mappingHistory-${assetId}`);
    }
}

StandardsInformation.$inject = ["$window"];
angularAMD.service("standardsInformation", StandardsInformation);
