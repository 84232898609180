import { animateElement } from "../../../utils/animate-element/AnimateElement";
import { dialogAnimationKeyFrames } from "../consts/DialogAnimationKeyFrames.const";

export const animateNewDialogStepIn = async (step: HTMLElement, oldHeight: number): Promise<void> => {
    step.style.height = "";
    const height = step.getBoundingClientRect().height;
    step.style.height = `${oldHeight}px`;

    await animateElement(step, {
        duration: 150,
        easing: "ease-out",
        keyframes: dialogAnimationKeyFrames.sizeChange(oldHeight, height)
    });

    await animateElement(step, {
        duration: 100,
        easing: "ease-in",
        keyframes: dialogAnimationKeyFrames.contentIn
    });
};
