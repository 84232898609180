import { cloneElement, MouseEvent } from "react";
import { IMenuItem } from "../../aria-roles/menus/types/IMenuItem";
import { IResolvedMenuProps } from "../interfaces/IResolvedMenuProps";
import "../styles/resolved-menu.css";

export const ResolvedMenu = <T extends IMenuItem>(props: IResolvedMenuProps<T>): JSX.Element => {
    return (
        <ul className="cui-auto-complete-resolved__menu-container" role="listbox" tabIndex={-1} id={props.id} aria-labelledby={props.inputId} data-hook="autocomplete-resolved-menu">
            {props.items.map((item, index) => {
                const isActive = index === props.activeItemIndex;

                return cloneElement(props.renderItem(item), {
                    /*eslint-disable @typescript-eslint/naming-convention*/
                    "aria-selected": isActive,
                    "data-autocomplete-active": isActive,
                    "data-hook": "auto-complete-list-item",
                    /*eslint-enable @typescript-eslint/naming-convention*/
                    id: `${props.inputId}-${index}`,
                    // eslint-disable-next-line react/no-array-index-key
                    key: index,
                    onClick: (e: MouseEvent) => props.onClick(e, index, item),
                    role: "option"
                });
            })}
        </ul>
    );
};
