import { angularAMD } from "@pebblepad/amd";
import { ASSESSMENT_CONSTANTS } from "../../constants/assessment.constants";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/baseUrlsFactory";

export class FeedbackInfoWrapperService {
    constructor($sce, multiLanguageService, baseUrlsFactory) {
        this._$sce = $sce;
        this._baseUrlsFactory = baseUrlsFactory;
        this._multiLanguageService = multiLanguageService;
    }

    _getProfileImageLink(profileImageId) {
        return `${this._baseUrlsFactory.api_base_url}File/TransmitProfilePic?id=${profileImageId}&x=35&y=35&shortSideScale=True`;
    }

    getInfoModel(feedbackItem) {
        const viewModel = {
            title: "",
            icon: "",
            timeStamp: feedbackItem.Posted,
            leftOn: { ...feedbackItem.LeftOn, AssetId: feedbackItem.AssetId }
        };

        if (feedbackItem.SubType === ASSESSMENT_CONSTANTS.FEEDBACK_SUBTYPES.STANDARD || feedbackItem.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.ASSESSOR_FIELD) {
            viewModel.title = feedbackItem.Poster.Name;
            viewModel.icon = this._getProfileImageLink(feedbackItem.Poster.ProfilePicId);
        } else {
            viewModel.title = this._multiLanguageService.getString(`sidebar.asset_feedback.title_${feedbackItem.SubType}.type_${feedbackItem.FeedbackType}`);
            viewModel.icon = this._$sce.getTrustedResourceUrl(`${this._baseUrlsFactory.shared_component_base_url}images/feedback/${feedbackItem.SubType}.png`);
        }

        return viewModel;
    }
}

FeedbackInfoWrapperService.$inject = ["$sce", "multiLanguageService", "baseUrlsFactory"];
angularAMD.service("feedbackInfoWrapperService", FeedbackInfoWrapperService);
