import { angularAMD } from "@pebblepad/amd";
import template from "./assetLockedMessage.html";

angularAMD.directive("assetLockedMessage", AssetLockedMessage);
AssetLockedMessage.$inject = ["multiLanguageService"];

function AssetLockedMessage(multiLanguageService) {
    return {
        template: template,
        restrict: "E",
        scope: {
            lockedInfo: "="
        },
        controller: [
            "$scope",
            "$element",
            "$attrs",
            function ($scope, $element, $attrs) {
                $scope.multiLanguageService = multiLanguageService;
            }
        ]
    };
}
