import { Component, createRef, Fragment, ReactElement, RefObject } from "react";
import { BoundaryBridge, GroupedItemPicker } from "@pjs/core-ui";
import { ContentInjectionEditorWithBoundary } from "@pjs/rich-text";
import { Subject } from "@pjs/observables";
import { LineClampedTextOption } from "@pjs/core-ui";
import { IFeedbackStatementsGroupItem } from "../../mappers/feedbackStatementsMapper/interfaces/IFeedbackStatementsGroupItem";
import { feedbackI18n } from "../../i18n/FeedbackI18n.const";
import { IFeedbackCommentEditorProps } from "./interfaces/IFeedbackCommentEditorProps";
import "./styles/feedback-comment-editor.css";

export class FeedbackCommentEditor extends Component<IFeedbackCommentEditorProps> {
    private readonly _contentSubject: Subject<string>;
    private readonly _itemPickerRef: RefObject<HTMLDivElement> = createRef();
    private readonly _onStatementSelectedBound: (item: IFeedbackStatementsGroupItem) => void = this._onStatementSelected.bind(this);

    constructor(props: IFeedbackCommentEditorProps) {
        super(props);
        this._contentSubject = new Subject<string>();
    }

    public componentWillUnmount(): void {
        this._contentSubject.complete();
    }

    public render(): ReactElement {
        const buttonText = feedbackI18n.getString("feedback_comment.labels.feedback_statements_picker_button");
        return (
            <Fragment>
                <ContentInjectionEditorWithBoundary
                    ariaLabel={this.props.ariaLabel}
                    autoFocus={this.props.autoFocus}
                    className="fbk-comment-editor__text-area"
                    content={this.props.content}
                    dataHook="feedback-comment-editor"
                    onChange={this.props.onChange}
                    onLink={this.props.onLink}
                    placeholder={this.props.placeholder}
                    contentObservable={this._contentSubject.asObservable()}
                    trackingElements={[this._itemPickerRef]}
                    onToolbarDisplayChange={this.props.onToolbarDisplayChange}
                />
                <div className="fbk-comment-editor__item-picker-container" ref={this._itemPickerRef}>
                    <BoundaryBridge>
                        <GroupedItemPicker
                            ariaLabel={buttonText}
                            buttonClassName="fbk-comment-editor__item-picker-button"
                            buttonContent={buttonText}
                            model={this.props.statements}
                            onItemSelect={this._onStatementSelectedBound}
                            renderOption={LineClampedTextOption}
                        />
                    </BoundaryBridge>
                </div>
            </Fragment>
        );
    }

    private _onStatementSelected(item: IFeedbackStatementsGroupItem): void {
        this._contentSubject.next(item.htmlValue);
    }
}
