import { angularAMD } from "@pebblepad/amd";

angularAMD.factory("cssLoader", function () {
    var loaded_styles = {};

    return {
        loadCss: function (style_path) {
            if (style_path in loaded_styles) {
                return;
            }

            if (document.createStyleSheet) {
                document.createStyleSheet(style_path); //IE
            } else {
                var link = document.createElement("link");
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = style_path;
                // tslint:disable-next-line:no-unsafe-dom-insert-calls
                document.getElementsByTagName("head")[0].appendChild(link);
            }

            loaded_styles[style_path] = true;
        }
    };
});
