import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import "../stickyElementCoordinator/stickyContainer.directive";
import template from "./tab-content.html";

class TabContent extends NgComponent {
    constructor() {
        super();

        this.state = { title: this.tabTitle, isActive: false, isDisabled: false, id: this.id };
    }

    $onInit() {
        this.state = { title: this.tabTitle, isActive: this.active, isDisabled: this.tabDisabled, id: this.id };
        this.tabSet.addTab(this.state);

        if (this.active) {
            this.tabSet.setActiveTab(this.state);
        }
    }

    $onChanges(changes) {
        if (changes.active !== undefined && changes.active.currentValue && !changes.active.isFirstChange()) {
            this.tabSet.setActiveTab(this.state);
        }

        if (changes.tabDisabled !== undefined) {
            this.state.isDisabled = changes.tabDisabled.currentValue;
        }

        if (changes.tabTitle !== undefined) {
            this.state.title = this.tabTitle;
        }
    }

    $onDestroy() {
        this.tabSet.removeTab(this.state);
    }
}

export const tabContent = {
    restrict: "E",
    transclude: true,
    template: template,
    controller: TabContent,
    bindings: {
        active: "<?",
        tabDisabled: "<?",
        tabTitle: "@",
        id: "<"
    },
    require: {
        tabSet: "^tabSet"
    }
};

TabContent.$inject = [];
angularAMD.component("tabContent", tabContent);
