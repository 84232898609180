import { angularAMD } from "@pebblepad/amd";
import { dataEncryptionService } from "../../../utilities/dataEncryptionService";
import { ASSET_CONSTANTS } from "../../../constants/asset.constants";
import { heartIcon } from "@pjs/core-ui";
import { KillSignal, takeUntil } from "@pjs/observables";
import { FavouriteStates } from "../../../observer/favourites/FavouriteStates";
import { favouritesObserver } from "../../../observer/favourites/FavouritesObserver.const";
import "../../../featureSettings/FeatureSettings";
import "../../../utilities/baseUrlsFactory";
import "../../../assetViews/sidebar/shares/shares";
import "../../../assetLocking/services/assetLockingAPI.service";
import "../../../assetViews/sidebar/submissions/submissions";
import "../../../tagger/tagger";
import "../../../builder/dataManager/dataManagerService";
import "../../../modal/services/modal";
import "../../services/pageReloadService";
import "../../../builder/pageReuse/pageReuse.directive";
import "../../../workbook/service/workbookService";
import "../../../overlay/services/overlayFactory";
import "../../../builder/pageReuse/services/pageReuseFactory.factory";
import "../../../utilities/iconHelper.service";
import "../../../screenReaderAnnouncer/screenReaderAnnouncer.directive";
import "../../../utilities/pebbleDate";
import "../../../post/services/post.service";
import "../../../multiLanguageService/multiLanguageService";
import "../../../assetStore/services/assetStoreService";
import "../../../react2angular/assetIcon";
import template from "./templates/asset-base-info.html";

angularAMD.directive("assetBaseInfo", [
    "$window",
    "$rootScope",
    "$http",
    "$location",
    "$timeout",
    "$q",
    "baseUrlsFactory",
    "dataManagerService",
    "modal",
    "pageReloadService",
    "workbookService",
    "overlayFactory",
    "PageReuseFactory",
    "IconHelper",
    "AssetLockingAPI",
    "PostService",
    "multiLanguageService",
    "assetStoreService",
    "featureSettings",
    function (
        $window,
        $rootScope,
        $http,
        $location,
        $timeout,
        $q,
        baseUrlsFactory,
        dataManagerService,
        modal,
        pageReloadService,
        workbookService,
        overlayFactory,
        PageReuseFactory,
        IconHelper,
        AssetLockingAPI,
        PostService,
        multiLanguageService,
        assetStoreService,
        featureSettings
    ) {
        return {
            template: template,
            restrict: "E",
            scope: {
                info: "=?info",
                assetId: "=?assetid",
                pageReuseId: "=",
                loading: "@",
                disableEdit: "<"
            },

            controller: [
                "$scope",
                function ($scope) {
                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                    $scope.savedTitle = false;
                    $scope.titleAnnouncerMessage = "";

                    const favouritesEnabled = featureSettings.isEnabled("Favourites");
                    $scope.isFavourite = $scope.info.IsFavourite && favouritesEnabled;
                    $scope.heartIcon = heartIcon;

                    const killFavouritesSignal = new KillSignal();

                    if ($scope.info.Authors.length === 1 && $scope.info.Collaborators.length <= 1) {
                        $scope.AuthorString = $rootScope.multiLanguageService.getString("asset_summary.headings.author");
                    } else if ($scope.info.Authors.length > 1 && $scope.info.Collaborators.length <= 1) {
                        $scope.AuthorString = $rootScope.multiLanguageService.getString("asset_summary.headings.authors");
                    }

                    $scope.getPage = function (assetId, pageId) {
                        var manager = dataManagerService.getDto(assetId);
                        if (!manager) {
                            return null;
                        }

                        var page = manager.get(pageId);
                        if (page === -1) {
                            return null;
                        }

                        return page;
                    };

                    $scope.canReusePage = function (page) {
                        if (!page.UserResponseId || !PageReuseFactory.isSupportedFolder(page.PageFolder)) {
                            return false;
                        }

                        // Only display option if it's a template in a Workbook or a placeholder page which is already in the invisible folder!
                        return workbookService.isTemplatePage(page) || (page.PageFolder === ASSET_CONSTANTS.FOLDERS.INVISIBLE && workbookService.isPageUserDefined(page));
                    };

                    $scope.reusePage = function (pageAssetId, folder, rootAssetId) {
                        const manager = dataManagerService.getDto(rootAssetId);
                        const savePromise = manager !== null && manager !== undefined ? dataManagerService.saveViaAssetId(rootAssetId, true) : $q.resolve();
                        return savePromise.then(() => assetStoreService.changeAssetFolder(pageAssetId, folder)).finally(() => overlayFactory.saveOverlay.hide());
                    };

                    if (!favouritesEnabled) {
                        return;
                    }

                    favouritesObserver
                        .onChange()
                        .pipe(takeUntil(killFavouritesSignal))
                        .subscribe((result) => ($scope.isFavourite = result.state === FavouriteStates.Favourite));

                    $scope.$on("$destroy", () => {
                        killFavouritesSignal.send();
                    });
                }
            ],

            link: function (scope, element) {
                scope.edit_btn_el = angular.element(element[0].getElementsByClassName("edit-btn")[0]);
                scope.input_el = angular.element(element[0].getElementsByClassName("editable-title")[0]);
                scope.letterLimit = 150;
                scope.editMode = false;
                scope.user_has_edit_permission = false;
                scope.user_can_edit_title = false;

                scope.new_title = scope.info.Title;
                scope.threeDotsString = "...";
                scope.emptyString = "";
                scope.withDay = "withDay";

                scope.pageReuseEnabled = false;
                scope.page = null;

                var url = $location.url();
                scope.isPublic = url.indexOf("public") > -1;

                if (scope.info.CurrentUserCanEdit) {
                    AssetLockingAPI.lockedByAnother(scope.assetId).then(function (response) {
                        if (response) {
                            scope.user_has_edit_permission = false;
                            scope.user_can_edit_title = false;
                            scope.info.CurrentUserCanChangeTitle = false;
                        } else {
                            // tslint:disable-next-line:triple-equals
                            if (scope.info.CurrentUserCanEdit == true) {
                                if ((scope.info.MainType === "FormResponse" && url.indexOf("template") > -1) || (scope.info.MainType === "File" && url.indexOf("file") > -1)) {
                                    //we are not allowing currently loaded form responses in EDIT MODE to edit there title
                                    scope.user_has_edit_permission = false;
                                } else {
                                    scope.user_has_edit_permission = true;
                                    setupPageReuse();
                                }
                            }

                            // tslint:disable-next-line:triple-equals
                            if (scope.info.CurrentUserCanChangeTitle == true) {
                                if ((scope.info.MainType === "FormResponse" && url.indexOf("template") > -1) || (scope.info.MainType === "File" && url.indexOf("file") > -1)) {
                                    //we are not allowing currently loaded form responses in EDIT MODE to edit there title
                                    scope.user_can_edit_title = false;
                                } else {
                                    scope.user_can_edit_title = true;
                                }
                            }
                        }
                    });
                }

                var setupPageReuse = function () {
                    if (scope.pageReuseId && scope.info.MainType === ASSET_CONSTANTS.TYPES.WORKBOOK_RESPONSE) {
                        scope.page = scope.getPage(scope.assetId, scope.pageReuseId);
                        if (scope.page) {
                            scope.pageAssetIcon = IconHelper.getIconFromType(scope.page.PageIcon);
                            scope.pageReuseEnabled = scope.canReusePage(scope.page);
                        }
                    }
                };

                // watch the url, close the info panel if moving away from the page
                var $off = $rootScope.$on("$locationChangeStart", function (event, next, current) {
                    var nextStartingUrl = next;
                    var currentStartingUrl = current;

                    if (next.indexOf("?") > -1) {
                        nextStartingUrl = next.substring(0, next.indexOf("?"));
                    }
                    if (current.indexOf("?") > -1) {
                        currentStartingUrl = current.substring(0, current.indexOf("?"));
                    }

                    // tslint:disable-next-line:triple-equals
                    if (nextStartingUrl != currentStartingUrl) {
                        scope.$emit("closeRightHandSidePanel");
                        $off();
                    }
                });

                scope.editClicked = function () {
                    if (window.event && window.event.type === "keyup") {
                        var code = window.event.keyCode ? window.event.keyCode : window.event.which;
                        if (code !== 13 && code !== 32) {
                            return;
                        }
                    }
                    scope.editTitle();
                };

                /**
                 * Make the title editable
                 * @param e {Event}
                 */
                scope.editTitle = function (e) {
                    scope.savedTitle = false;
                    scope.titleAnnouncerMessage = "";

                    if (scope.editMode) {
                        scope.editMode = false;
                        scope.new_title = scope.info.Title;
                        scope.input_el.unbind("keydown");
                    } else {
                        scope.editMode = true;
                        $timeout(function () {
                            scope.input_el[0].focus();
                            scope.input_el[0].select();
                        });
                    }
                };

                /**
                 * Save the new asset title
                 */
                scope.saveTitle = function () {
                    if (scope.info.Title === scope.new_title || scope.new_title === "") {
                        return;
                    }

                    scope.info.Title = scope.new_title;
                    scope.editMode = false;
                    scope.savedTitle = true;
                    scope.titleAnnouncerMessage = multiLanguageService.getString("sidebar.asset_title_saved_as", { title: scope.new_title });

                    //deal with local storage
                    switch (scope.info.MainType) {
                        case "Webfolio":
                            //editing the asset that's on screen
                            scope.setupDataManager(function () {
                                scope.setupManagerObject(dataManagerService.pageManagerObject);
                            });
                            break;
                        case "WorkbookResponse":
                            //editing the asset that's on screen
                            scope.setupDataManager(function () {
                                scope.setupManagerObject(dataManagerService.workbookManagerObject);
                            });
                            break;
                        case "WebfolioPage":
                            //editing the asset that's on screen
                            scope.setupDataManager(function () {
                                scope.setupManagerObject(dataManagerService.pageManagerObject);
                            });
                            break;
                        case "Post":
                            //editing the asset that's on screen
                            scope.setupDataManager(function () {
                                scope.setupManagerObject(dataManagerService.postManagerObject);
                            });
                            break;
                    }

                    //call service to save title tp server
                    $http.defaults.withCredentials = true;
                    $http
                        .post(baseUrlsFactory.api_base_url + "AssetSidebar/UpdateAssetTitle", {
                            AssetId: scope.assetId,
                            NewTitle: scope.info.Title
                        })
                        .then(function (response) {
                            if (response.data === "true") {
                                pageReloadService.notifyOnUpdate();
                            } else {
                                modal.launch(modal.genericTemplates.popup, {
                                    title: $rootScope.multiLanguageService.getString("workbook.error_modals.generic_title"),
                                    message: $rootScope.multiLanguageService.getString("workbook.error_modals.unable_save_title.message"),
                                    button_label: $rootScope.multiLanguageService.getString("buttons.ok")
                                });
                            }
                        });
                };

                /**
                 * Update local storage with the correct asset title
                 */
                scope.saveTitleInLocalStorage = function () {
                    const assetDto = dataManagerService.loadFromPersistentObjStore(scope.assetId);
                    if (assetDto !== null) {
                        var stringifiedAssetDto = JSON.stringify(updateAssetTitle(assetDto, scope.info.Title));

                        dataManagerService.getUserData().then(function (response) {
                            var user_id;

                            if (response.data) {
                                user_id = response.data ? response.data : "pebblelocalstorage";
                            } else {
                                user_id = response ? response : "pebblelocalstorage";
                            }

                            stringifiedAssetDto = dataEncryptionService.AES.encrypt(stringifiedAssetDto, user_id);

                            try {
                                $window.localStorage.setItem(user_id, stringifiedAssetDto);
                            } catch (e) {
                                return false;
                            }
                        });
                    }
                };

                /**
                 * Set up the manager object with the scope data
                 * @param managerObject {BasicInfoViewModel}
                 */
                scope.setupManagerObject = function (managerObject) {
                    // tslint:disable-next-line:triple-equals
                    if (managerObject && managerObject.AssetId == scope.assetId) {
                        updateAssetTitle(managerObject.data, scope.info.Title);
                        managerObject.save();
                    } else {
                        scope.saveTitleInLocalStorage();
                    }
                };

                var updateAssetTitle = function (assetDto, title) {
                    if (assetDto.MainType === ASSET_CONSTANTS.TYPES.POST) {
                        var titleBanner = PostService.getTitleBannerSection(assetDto);
                        if (titleBanner !== null) {
                            titleBanner.Title = title;
                        }
                    }
                    assetDto.Title = title;
                    assetDto.LastModified = new Date();
                    return assetDto;
                };

                /**
                 * Initialise the data manager object
                 * @param callback {Function}
                 */
                scope.setupDataManager = function (callback) {
                    //DataManager has already been initialised don't bother.
                    if (!dataManagerService.primary_id) {
                        dataManagerService
                            .init({
                                AssetId: null,
                                Asset: { MainType: scope.info.MainType }
                            })
                            .then(callback);
                    } else {
                        callback();
                    }
                };
            }
        };
    }
]);
