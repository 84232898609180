import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../multiLanguageService/multiLanguageService";
import "../externalContact/externalContact";
import "../extendSearch/services/searchService";
import template from "./templates/external-from-workspace.html";

angularAMD.directive("externalFromWorkspace", ExternalFromWorkspace);
ExternalFromWorkspace.$inject = [];

angularAMD.controller("ExternalFromWorkspaceController", ExternalFromWorkspaceController);
ExternalFromWorkspaceController.$inject = ["$scope", "multiLanguageService", "baseUrlsFactory", "searchService"];

var searchService = null;

function ExternalFromWorkspace() {
    return {
        template: template,
        restrict: "E",
        scope: {
            externalList: "=", //Array - List of Externals users. Default value should be an empty array.
            workspaceId: "@", //String
            allowCreate: "=", //Boolean - Allow creation of external users
            viewUsersOnly: "=", //Boolean - View mode
            describedBy: "@"
        },
        controller: "ExternalFromWorkspaceController",
        controllerAs: "vm",
        bindToController: true
    };
}

function ExternalFromWorkspaceController($scope, _multiLanguageService_, baseUrlsFactory, _searchService_) {
    searchService = _searchService_;

    this.searchText = "";
    this.errorMessage = "";
    this.showAddExternal = false;
    this.userSelectionList = []; // when search returns multiple results this list is what the user selects from
    this.defaultIcon = baseUrlsFactory.api_base_url + "File/TransmitProfilePic?id=&x=32&y=32"; //Get default profile pic from Backend
    this.multiLanguageService = _multiLanguageService_;

    // in case we add an external set up object required by externalContact directive
    this.setupEventListeners($scope);
    this.setUpNewExternalData();
}

ExternalFromWorkspaceController.prototype.setupEventListeners = function ($scope) {
    $scope.$on(
        "closeExternalForm",
        function (event) {
            this.showAddExternal = false;
            this.setUpNewExternalData();
        }.bind(this)
    );

    $scope.$on(
        "newContactAdded",
        function (event, data) {
            if (data) {
                this.addExternalToList(data);
            }
            this.setUpNewExternalData();
        }.bind(this)
    );
};

ExternalFromWorkspaceController.prototype.findExternal = function () {
    return searchService.getExternalsFromATLAS(this.searchText, this.workspaceId).then(
        function (externals) {
            if (externals && externals.length === 1) {
                // either add to the list or display error
                this.addExternalToListIfUnique(externals[0]);
            } else {
                // else there are multiple results, show them to the user to decide, 0 results are dealt with via promise rejection below
                this.userSelectionList = externals;
                this.errorMessage = "";
                this.searchText = "";
            }
        }.bind(this),
        function (rejection) {
            this.userSelectionList.length = 0;
            this.errorMessage =
                rejection === "noResults"
                    ? this.multiLanguageService.getString("external_from_workspace.no_external_found")
                    : this.multiLanguageService.getString("external_from_workspace.server_error");
        }.bind(this)
    );
};

ExternalFromWorkspaceController.prototype.isExternalAlreadyInList = function (newUser) {
    return this.externalList.some(function (user) {
        return newUser.UserId === user.UserId;
    });
};

ExternalFromWorkspaceController.prototype.addExternalToList = function (data) {
    this.errorMessage = "";
    this.userSelectionList = []; // reset any selection list
    this.externalList.push(data);
};

ExternalFromWorkspaceController.prototype.addExternalToListIfUnique = function (data) {
    if (!this.isExternalAlreadyInList(data)) {
        this.addExternalToList(data);
    } else {
        this.errorMessage = this.multiLanguageService.getString("external_from_workspace.already_added_to_list");
    }
    this.searchText = "";
};

ExternalFromWorkspaceController.prototype.removeExternal = function (index) {
    this.externalList.removeOne(index);
};

ExternalFromWorkspaceController.prototype.addNewExternal = function () {
    this.errorMessage = "";
    this.newExternalData.email = this.searchText;
    this.searchText = "";
    this.userSelectionList = [];
    this.showAddExternal = true;
};

ExternalFromWorkspaceController.prototype.setUpNewExternalData = function () {
    this.newExternalData = {
        firstName: "",
        secondName: "",
        email: "",
        emailConfirmation: "",
        organisation: "",
        role: ""
    };
};
