import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../extensions/arrayExtensions";
import "../../assetsWithLinks/directives/assetsWithLinks";
import "../../share/services/shareService";
import "../../modal/services/modal";
import "../../assetStore/services/assetStoreService";
import "../../share/directives/share";
import "../../share/directives/shareSelectSubmission.directive";
import "../../share/directives/shareSelectExternals.directive";
import "../../share/directives/shareLink";
import "../../utilities/domSearchHelper.service";
import "../../elementCreatedMarker/elementCreatedMarker.directive";
import "../../utilities/animationHelperService";
import "../../autoFocus/autoFocus.directive";
import template from "../templates/share-start.html";
import { ButtonClass } from "@pjs/core-ui";

angularAMD.directive("shareStart", [
    "$http",
    "$sce",
    "$compile",
    "$timeout",
    "$rootScope",
    "$q",
    "baseUrlsFactory",
    "shareService",
    "modal",
    "assetStoreService",
    "domSearchHelper",
    "animationHelperService",
    function ($http, $sce, $compile, $timeout, $rootScope, $q, baseUrlsFactory, shareService, modal, assetStoreService, domSearchHelper, animationHelperService) {
        return {
            template: template,
            restrict: "E",
            scope: {
                assetIds: "=", // list of Asset Id's?,
                noModal: "@",
                modalInterface: "="
            },
            controller: [
                "$scope",
                "$element",
                function ($scope, $element) {
                    $scope.shareContainer_el = null;
                    $scope.multiLanguageService = $rootScope.multiLanguageService;
                    $scope.imageShareToPeople = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/shareToPeople.svg");
                    $scope.imageShareToWeb = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/shareToWeb.svg");
                    $scope.imageShareForAssessment = $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/shareForAssessment.svg");
                    $scope.shareForAssessmentUrl = baseUrlsFactory.shared_component_base_url + "share/templates/share-for-assessment.lazy.html";

                    $scope.assetsWithLinks = [];
                    $scope.hasSharableResource = false;
                    $scope.revealOptions = false;
                    $scope.shareModelSubTitle = "";
                    $scope.showTopLevelAssetWithLinks = true;
                    $scope.showListedItems = false;
                    $scope.shareMode = "";
                    $scope.buttonClass = ButtonClass;

                    $scope.ids = {
                        shareOptionDescription: "share-start-options-description-" + $scope.$id
                    };

                    $scope.openShare = function (mode) {
                        $scope.shareMode = mode;
                        $scope.hideAssetWithLinks = true;
                        let modalTitle = "";
                        let modalIcon = "";

                        switch (mode) {
                            case "web":
                                modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_asset_web");
                                if ($scope.shareType === "resource" || $scope.shareType === "template") {
                                    modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_template_web");
                                } else if ($scope.shareType === "workbook") {
                                    modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_workbook_web");
                                }

                                modalIcon = "share-to-web";
                                break;
                            case "assessment":
                                modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_asset_assessment");
                                if ($scope.shareType === "resource" || $scope.shareType === "workbook" || $scope.shareType === "template") {
                                    modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_template_assessment");
                                }

                                modalIcon = "share-for-assessment";
                                break;
                            default:
                                modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_asset_people");
                                if ($scope.shareType === "resource" || $scope.shareType === "template") {
                                    modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_template_people");
                                } else if ($scope.shareType === "workbook") {
                                    modalTitle = $scope.multiLanguageService.getString("sharing.header.share_my_workbook_people");
                                }

                                modalIcon = "share-to-people";
                                $scope.changingExisting = void 0;
                                break;
                        }

                        $scope.modalInterface.updateTitle(modalTitle);
                        $scope.modalInterface.updateIcon(modalIcon);

                        const html = $compile(`
                            <share 
                                assets-with-links="[asset]" 
                                asset-ids="assetIds" 
                                changing-existing="changingExisting" 
                                share-mode="shareMode" 
                                share-type="shareType" 
                                hide-asset-with-links="hideAssetWithLinks" 
                                show-top-level-asset-with-links="showTopLevelAssetWithLinks" 
                                modal-interface="modalInterface">
                            </share>
                        `)($scope);

                        // tslint:disable-next-line:no-unsafe-jq-lite
                        $scope.shareContainer_el.append(html);
                    };

                    $scope.openStudentLedShare = function () {
                        $scope.shareMode = "studentLedExternals";
                        $scope.modalInterface.updateTitle($scope.multiLanguageService.getString("sharing.header.share_with_external_assessor"));

                        $scope.mainAssetId = $scope.assetIds[0];
                        var selectedSubmission = $scope.studentLedSubmissions.length === 1 ? $scope.studentLedSubmissions[0] : null;

                        if (selectedSubmission !== null) {
                            $scope.compileShareSelectExternal(selectedSubmission);
                        } else {
                            $scope.compileShareSelectSubmission();
                        }
                    };

                    $scope.shareInit = function () {
                        var assetId = $scope.assetIds[0];

                        return shareService.getHowCanIShare(assetId).then(function (shareDetailsModel) {
                            if (shareDetailsModel.ExistingWebShare !== null) {
                                $scope.changingExisting = shareDetailsModel.ExistingWebShare;
                            }

                            var studentLedExternalsEnabled = shareDetailsModel.StudentLedSubmissions.length !== 0;
                            if (studentLedExternalsEnabled) {
                                $scope.studentLedSubmissions = shareDetailsModel.StudentLedSubmissions;
                                $scope.shareStartSubTitle = $scope.multiLanguageService.getString("sharing.labels.share_the_following_asset");
                                $scope.shareType = "asset";
                                $scope.revealOptions = !studentLedExternalsEnabled;
                            } else {
                                $scope.setupAvailableShareTypes(shareDetailsModel.WhatCanIShare);
                                $scope.revealOptions = !$scope.hasSharableResource || $scope.shareType;
                            }

                            $scope.shareModelSubTitle = $scope.shareStartSubTitle;
                            $scope.studentLedExternalsEnabled = studentLedExternalsEnabled;

                            $timeout(() => {
                                $scope.shareLoaded = true;
                            }, 300);
                        }, angular.noop);
                    };

                    $scope.setupAvailableShareTypes = function (whatCanIShareList) {
                        //DETAILS OF ADDITIONAL VARIABLES FOR WHEN A RESOURCE TEMPLATE AND ASSET ARE AVAILABLE TO SHARE AT THE SAME TIME.
                        //$scope.shareStartSubtitle is used to make sure that the shareStart title is specific to the item being shared, i.e. resource/asset/both.
                        //$scope.hasSharableResource is true when the user has a choice between sharing either the resource or response.
                        //$scope.revealOptions determines whether the "share with" buttons are visible, shouldn't be initially when hasSharableResource is true.
                        //$scope.sharableResourceId is used when their is a choice between resource or asset when hasSharableResource is true
                        //$scope.shareLoaded is used to eliminate flashing of options on startup.

                        if (whatCanIShareList) {
                            if (whatCanIShareList.length === 1 && whatCanIShareList[0].MainType === "Form") {
                                $scope.shareStartSubTitle = $scope.multiLanguageService.getString("sharing.labels.share_the_following_template");
                                $scope.shareType = "template";
                            } else if (whatCanIShareList.length === 1 && whatCanIShareList[0].MainType === "WorkBook") {
                                $scope.shareStartSubTitle = $scope.multiLanguageService.getString("sharing.labels.share_the_following_workbook");
                                $scope.shareType = "workbook";
                            } else if (whatCanIShareList.length === 1) {
                                $scope.shareStartSubTitle = $scope.multiLanguageService.getString("sharing.labels.share_the_following_asset");
                                $scope.shareType = "asset";
                            } else if (whatCanIShareList.length === 2) {
                                $scope.shareStartSubTitle = $scope.multiLanguageService.getString("sharing.labels.share_dot_dot_dot");
                                $scope.hasSharableResource = true;
                                $scope.sharableResourceId = [];
                                $scope.sharableResourceId.push(whatCanIShareList[1].Id);
                            }
                        }
                    };

                    $scope.compileShareSelectExternal = function (selectedSubmission) {
                        let wrapperScope = $scope.$new();
                        wrapperScope.assetId = $scope.mainAssetId;
                        wrapperScope.submissionId = selectedSubmission.Id;
                        wrapperScope.workspaceId = selectedSubmission.Location;
                        wrapperScope.allowCustomExternals = selectedSubmission.AllowMemberCreationOfExternalAssessor; //TODO: Wait for Backend to give the Value and map to it.
                        wrapperScope.onComplete = function () {
                            $scope.cancel();
                        };

                        wrapperScope.onSubmit = function (externalAssessorShareDto) {
                            return shareService.createExternalAssessorShare(externalAssessorShareDto);
                        };

                        $scope.shareContainer_el.empty();
                        const html = $compile(`
                            <share-select-externals 
                                asset-id="{{::mainAssetId}}" 
                                submission-id="{{::submissionId}}" 
                                workspace-id="{{::workspaceId}}" 
                                allow-custom-externals="allowCustomExternals" 
                                on-submit="::onSubmit(shareModel)" 
                                on-complete="::onComplete(externals, expiryDate, cancelled)">
                            </share-select-externals>
                        `)(wrapperScope);

                        html.on("$destroy", function () {
                            wrapperScope.$destroy();
                            wrapperScope = null;
                        });

                        // tslint:disable-next-line:no-unsafe-jq-lite
                        $scope.shareContainer_el.append(html);
                    };

                    $scope.compileShareSelectSubmission = function () {
                        $scope.shareContainer_el.empty();
                        const html = $compile('<share-select-submission submissions="studentLedSubmissions" on-select="compileShareSelectExternal(selected)"></share-select-submission>')($scope);

                        // tslint:disable-next-line:no-unsafe-jq-lite
                        $scope.shareContainer_el.append(html);
                    };

                    $scope.revealStandardShareOptions = function () {
                        $scope.revealOptions = true;
                        $scope.animatingShareOptions = true;
                        var defaultShareOptionsElement = $element[0].getElementsByClassName("share-default-options")[0];
                        //Wait for animation to end, then focus in on the first available element
                        animationHelperService.applyAnimationClass(defaultShareOptionsElement, "animate--fade-in").then(function () {
                            var focusOnElement = domSearchHelper.getFirstTabbableElement(defaultShareOptionsElement);
                            $scope.animatingShareOptions = false;

                            if (focusOnElement !== null) {
                                focusOnElement.focus();
                            }
                        });
                    };

                    $scope.cancel = function () {
                        $scope.$parent.hideModal();
                    };

                    $scope.selectShareOption = function (shareType, selectedId) {
                        $scope.showListedItems = true;
                        $scope.type = shareType;

                        shareType === "asset" ? ($scope.ids = $scope.assetIds) : ($scope.ids = $scope.sharableResourceId);

                        if (!$scope.revealOptions) {
                            $scope.assetIds = selectedId;
                            if ($scope.shareType === undefined) {
                                $scope.shareType = shareType;
                            }
                            $scope.revealOptions = true;
                            if (shareType === "asset") {
                                $scope.shareStartSubTitle = $scope.multiLanguageService.getString("sharing.labels.share_the_following_asset");
                            }
                            //make sure that selection is reflected in the associated assetWithLink component.
                            $scope.$emit("selectShareMainTypeOption", { selectedId: selectedId, shareType: shareType });
                        }
                    };

                    $scope.resetShareChoice = function () {
                        $scope.shareMode = "";
                        $scope.shareContainer_el.empty();
                        $scope.modalInterface.updateTitle($scope.multiLanguageService.getString("buttons.iWantToHandler.share"));
                        $scope.modalInterface.updateIcon("");
                    };

                    $scope.onShareStartEnter = function ($event, data) {
                        var keyPressed = $event.which;
                        if (keyPressed === 13) {
                            $scope.selectShareOption(data.type, data.id);
                        }
                    };
                }
            ],
            link: function ($scope, $element) {
                $scope.shareContainer_el = angular.element($element[0].getElementsByClassName("ppShareContainer")[0]);

                $scope.$watchCollection("assetIds", function () {
                    if (!$scope.assetIds.length) {
                        $scope.cancel();
                    } else {
                        assetStoreService.getAssetWithLinks($scope.assetIds[0], false).then(function (response) {
                            $scope.asset = response.data;
                            $scope.shareInit();
                        });
                    }
                });
            }
        };
    }
]);
