import { angularAMD } from "@pebblepad/amd";

angularAMD.directive("screenReaderAnnouncer", ScreenReaderAnnouncer);
ScreenReaderAnnouncer.$inject = ["$timeout"];

function ScreenReaderAnnouncer($timeout) {
    return {
        restrict: "E",
        scope: {
            loaded: "@",
            message: "=",
            postLoadMessage: "=",
            delay: "@"
        },
        template: '<p aria-live="polite" aria-atomic="true"><span ng-bind="announcement"></span></p>',
        link: function (scope, element, attr) {
            scope.announcement = scope.loaded === "true" ? scope.postLoadMessage : scope.message;
            //loaded could change value after link setup, create a watch to ensure correct message gets displayed from state.
            attr.$observe("loaded", function (newVal) {
                $timeout(function () {
                    scope.announcement = newVal === "true" ? scope.postLoadMessage : scope.message;
                }, scope.delay || 0);
            });
        }
    };
}
