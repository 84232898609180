import { DecoupledEditor } from "@pebblepad/ckeditor";
import { Observable, Subscription } from "@pjs/observables";
import { RefObject } from "react";
import { IEditor } from "../../editor/interfaces/IEditor";
import { EditorEnabledState } from "../../editor/types/EditorEnabledState";

export class ContentInsertionHandler {
    private readonly _editor: IEditor;
    private readonly _contentObservable: Observable<string>;
    private readonly _enabledSubscription: Subscription;
    private readonly _focusContainers: ReadonlyArray<RefObject<HTMLElement>>;
    private _contentSubscription: Subscription | null = null;

    constructor(editor: IEditor, contentObservable: Observable<string>, focusContainers: ReadonlyArray<RefObject<HTMLElement>>) {
        this._editor = editor;
        this._contentObservable = contentObservable;
        this._focusContainers = focusContainers;
        this._enabledSubscription = editor.enabled.subscribe((state) => this._onEnabledStateChange(state));
    }

    public destroy(): void {
        if (this._contentSubscription !== null) {
            this._contentSubscription.unsubscribe();
        }

        const ckeInstance = this._editor.getInstance();
        if (ckeInstance !== null) {
            this._unregisterFocusContainers(ckeInstance);
        }

        this._enabledSubscription.unsubscribe();
    }

    private _onEnabledStateChange(state: EditorEnabledState): void {
        if (state.isEnabled) {
            this._contentSubscription = this._contentObservable.subscribe((content) => this._insertContent(state.editor, content));
            this._registerFocusContainers(state.editor);
            return;
        }

        if (this._contentSubscription !== null) {
            this._contentSubscription.unsubscribe();
            this._contentSubscription = null;
        }
    }

    private _insertContent(ckeInstance: DecoupledEditor, content: string): void {
        const sanitisedContent = this._editor.parser.parseToString(content);
        const viewFragment = ckeInstance.data.processor.toView(sanitisedContent);
        const modelFragment = ckeInstance.data.toModel(viewFragment);

        ckeInstance.model.insertContent(modelFragment);
        ckeInstance.focus();
    }

    private _registerFocusContainers(ckeInstance: DecoupledEditor): void {
        for (const element of this._focusContainers) {
            if (element.current !== null) {
                ckeInstance.ui.focusTracker.add(element.current);
            }
        }
    }

    private _unregisterFocusContainers(ckeInstance: DecoupledEditor): void {
        for (const element of this._focusContainers) {
            if (element.current !== null) {
                ckeInstance.ui.focusTracker.remove(element.current);
            }
        }
    }
}
