export enum Keys {
    ArrowUp = "ArrowUp",
    ArrowDown = "ArrowDown",
    Enter = "Enter",
    Escape = "Escape",
    Esc = "Esc",
    Space = " ",
    SpaceForInternetExplorer = "Spacebar",
    Home = "Home",
    End = "End",
    Tab = "Tab",
    Control = "Control",
    Shift = "Shift",
    Meta = "Meta"
}
