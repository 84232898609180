import { DecoupledEditor, EventInfo, FocusTracker } from "@pebblepad/ckeditor";
import { noop } from "@pjs/utilities";

export class FocusTrackerProxy {
    private readonly _focusTracker: FocusTracker;
    private readonly _processedElements: Set<HTMLElement> = new Set();
    private readonly _editor: DecoupledEditor;
    private _handleChange: (event: EventInfo, _: string, _value: unknown, oldValue: unknown) => unknown = noop;

    constructor(editor: DecoupledEditor) {
        this._editor = editor;
        this._focusTracker = editor.ui.focusTracker;
    }

    public track(element: HTMLElement): void {
        this._processedElements.add(element);
        this._focusTracker.add(element);
    }

    public untrack(element: HTMLElement): void {
        this._processedElements.delete(element);
        this._focusTracker.remove(element);
    }

    public freeze(): void {
        this.unfreeze();

        this._handleChange = (event, _, _value, oldValue) => {
            event.stop();
            event.return = oldValue;
        };

        this._focusTracker.on("set:isFocused", this._handleChange);
        this._focusTracker.on("set:focusedElement", this._handleChange);
    }

    public unfreeze(): void {
        this._focusTracker.off("set:isFocused", this._handleChange);
        this._focusTracker.off("set:focusedElement", this._handleChange);

        this._handleChange = noop;
    }

    public reset(): void {
        for (const element of this._processedElements) {
            this._focusTracker.remove(element);
        }

        this._processedElements.clear();
        this.unfreeze();
    }

    public restore(): void {
        this.reset();
        this._editor.editing.view.focus();
    }
}
