import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";

angularAMD.directive("skipToContentLink", SkipToContentLink);
SkipToContentLink.$inject = ["$sce", "baseUrlsFactory"];

angularAMD.controller("SkipToContentLinkController", SkipToContentLinkController);
SkipToContentLinkController.$inject = ["$window", "multiLanguageService"];

function SkipToContentLink($sce, baseUrlsFactory) {
    return {
        template: `
            <a ng-show="vm.showSkipToContentLink" 
               class="skip-to-content-link__href btn btn-success-alt inside-blue-focus" 
               href="javascript:void(0)" 
               ng-click="vm.skipToContent($event)">{{::vm.services.multiLanguageService.getString("skip_to_content.link_text")}}</a>
        `,
        restrict: "E",
        controller: "SkipToContentLinkController",
        scope: {},
        controllerAs: "vm",
        bindToController: true,
        link: function (scope, element, attrs) {
            scope.$on("$routeChangeStart", function () {
                scope.vm.setShowSkipToContentLink(false);
            });

            scope.$on("$routeChangeSuccess", function () {
                var element = scope.vm.getSkipLinkHeader();
                if (element) {
                    scope.vm.setShowSkipToContentLink(true);
                }
            });
        }
    };
}

function SkipToContentLinkController($window, multiLanguageService) {
    this.showSkipToContentLink = false;
    this.services = {
        window: $window,
        multiLanguageService: multiLanguageService
    };
}

SkipToContentLinkController.prototype.skipToContent = function () {
    var element = this.getSkipLinkHeader();
    if (element) {
        element.focus();
    }
};

SkipToContentLinkController.prototype.setShowSkipToContentLink = function (showLink) {
    this.showSkipToContentLink = showLink;
};

SkipToContentLinkController.prototype.getSkipLinkHeader = function () {
    return this.services.window.document.getElementById("skip-to-content-header");
};
