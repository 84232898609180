import { PureComponent, ReactElement } from "react";
import { ITickProps } from "../interfaces/ITickProps";
import { TickAlignment } from "../enums/TickAlignment";
import "../styles/tick.css";

export class Tick extends PureComponent<ITickProps> {
    constructor(props: ITickProps) {
        super(props);
    }

    public render(): ReactElement {
        let labelClass = "cui-tick__label";
        const left = this.props.alignment === TickAlignment.Left ? `${this.props.placement}%` : "";
        const right = this.props.alignment === TickAlignment.Right ? `${100 - this.props.placement}%` : "";

        if (this.props.alignment === TickAlignment.Left && this.props.placement < 10) {
            labelClass += " cui-tick__label--left";
        } else if (this.props.alignment === TickAlignment.Right && this.props.placement > 90) {
            labelClass += " cui-tick__label--right";
        }

        return (
            <div className="cui-tick" title={this.props.tooltip} data-hook="range-slider-tick">
                <div
                    className="cui-tick__marker"
                    data-hook="tick-marker"
                    style={{
                        backgroundColor: this.props.placement <= this.props.ratingValues[0] ? this.props.progressColour : this.props.colour,
                        left: left,
                        right: right
                    }}
                />
                <span
                    className={labelClass}
                    data-hook="tick-label"
                    style={{
                        left: left,
                        right: right
                    }}>
                    {this.props.label}
                </span>
            </div>
        );
    }
}
