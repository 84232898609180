import { IEditor } from "../../editor/interfaces/IEditor";
import { editorCacheInstance } from "../../editor-cache/editorCacheInstance.const";
import { BehaviourCleanup } from "../types/BehaviourCleanup";
import { LimitedInstancesCoordinator } from "./LimitedInstancesCoordinator";

export function limitInstances(editor: IEditor): BehaviourCleanup {
    const editorCoordinator = new LimitedInstancesCoordinator(editor, editorCacheInstance);
    return () => {
        editorCoordinator.destroy();
    };
}
