import { htmlStringToPlainText } from "@pjs/security";
import { PascalToCamel } from "@pjs/http";
import { FeedbackStatementsList } from "./types/FeedbackStatementsList";
import { FeedbackStatementsApiResponse } from "./types/FeedbackStatementsApiResponse";
import { IFeedbackStatementsGroupItem } from "./interfaces/IFeedbackStatementsGroupItem";

const mapStatementStrings = (statementStrings: Array<string>): Array<IFeedbackStatementsGroupItem> => {
    return statementStrings.map((statement) => {
        return {
            htmlValue: statement,
            value: htmlStringToPlainText(statement)
        };
    });
};

export const mapFeedbackStatements = (feedbackStatements: PascalToCamel<FeedbackStatementsApiResponse>): FeedbackStatementsList => {
    return feedbackStatements.map((serverModel) => {
        return {
            items: mapStatementStrings(serverModel.statements),
            title: serverModel.name
        };
    });
};
