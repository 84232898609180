import { Keys } from "../../enums/Keys";
import { IShortcutManager } from "./interfaces/IShortcutManager";
import { IShortcut } from "./interfaces/IShortcut";
import { IShortcutGroup } from "./interfaces/IShortcutGroup";
import { ShortcutStack } from "./ShortcutStack";

/**
 * Supported access and modifier keys include control and shift, alt is not a supported key.
 * Shortcuts using the control key will be triggered by both command and control on Mac e.g. control + s becomes command + s.
 * Single key shortcuts e.g. "s" are not supported.
 */
export class ShortcutManager implements IShortcutManager {
    private readonly _element: HTMLElement;
    private readonly _isMac: boolean;
    private readonly _stacks: Array<ShortcutStack> = [];
    private _pressedKeys: Array<string> = [];

    constructor(element: HTMLElement, isMac: boolean) {
        this._element = element;
        this._isMac = isMac;
        this._element.addEventListener("keydown", this._triggerCallback.bind(this));
        this._element.addEventListener("keyup", this._isMac ? this._macResetKeyPress.bind(this) : this._resetKeyPress.bind(this));
        window.addEventListener("blur", () => (this._pressedKeys.length = 0));
    }

    public add(shortcuts: ReadonlyArray<IShortcut>, description: string): () => void {
        const stack = this._getCurrentStack();
        if (stack === undefined) {
            throw new Error("No Stack available to add shortcuts to.");
        }

        return stack.addGroup(shortcuts, description);
    }

    public addStack(): () => void {
        const stack = new ShortcutStack();
        this._stacks.push(stack);

        return () => {
            const index = this._stacks.indexOf(stack);
            if (index !== -1) {
                this._stacks.splice(index, 1);
            }
        };
    }

    public getActiveShortcuts(): Array<IShortcutGroup> {
        const stack = this._getCurrentStack();
        return stack === undefined ? [] : stack.getShortcutGroups();
    }

    private _getCurrentStack(): ShortcutStack | undefined {
        return this._stacks[this._stacks.length - 1];
    }

    private _triggerCallback(event: KeyboardEvent): void {
        const stack = this._getCurrentStack();
        if (stack === undefined) {
            return;
        }

        const key = this._isMac && event.key === Keys.Meta ? Keys.Control.toLowerCase() : event.key.toLowerCase();
        const isNewKey = !this._pressedKeys.includes(key);
        if (isNewKey) {
            this._pressedKeys.push(key);
        }

        // Due to quirks with Mac Command key blocking keyup events the repeat flag must be checked in case the key is a key being pressed again.
        if (event.repeat || this._pressedKeys.length <= 1) {
            return;
        }

        const shortcut = stack.getShortcut(this._pressedKeys);
        if (shortcut !== null) {
            shortcut.callback(event);
        }
    }

    private _resetKeyPress(event: KeyboardEvent): void {
        const key = event.key.toLowerCase();
        if (this._pressedKeys.includes(key)) {
            const index = this._pressedKeys.indexOf(key);
            this._pressedKeys.splice(index, 1);
        }
    }

    /**
     * Whilst Command is pressed on Mac, keyup events for alphanumeric characters and symbols will never trigger. 🙃
     * When the Command key is released we need to clean up any tracked alphanumeric and symbol characters.
     */
    private _macResetKeyPress(event: KeyboardEvent): void {
        if (event.key === Keys.Meta) {
            const controlKey = Keys.Control.toLowerCase();
            this._pressedKeys = this._pressedKeys.filter((key) => key.length > 1 && key !== controlKey);
            return;
        }

        this._resetKeyPress(event);
    }
}
