import { Fragment, RefObject, useContext, useId, useRef, useState } from "react";
import { useClassRef, useFactoryRef, useOnMount, useOnValueChange } from "@pjs/react-utilities";
import { catchError, debounce, map, of, Subject, switchMap, tap, timer } from "@pjs/observables";
import { AsyncModel, AsyncModelStatus, createAsyncModelError, createAsyncModelPending, createAsyncModelResult } from "@pjs/utilities";
import { Input } from "../input/Input.component";
import { ComboboxAriaModel } from "../aria-roles/menus/menu-event-adapter/types/ComboboxAriaModel";
import { AriaMenuStatus } from "../aria-roles/menus/menu-event-adapter/enums/AriaMenuStatus";
import { IMenuItem } from "../aria-roles/menus/types/IMenuItem";
import { BoundaryContext } from "../boundary/Boundary.context";
import { hideWhenClosed } from "../../utils/hide-when-closed/hideWhenClosed";
import { floatingAutoCompleteMenuFactory } from "../aria-roles/menus/floating-menu/factories/FloatingAutoCompleteMenuFactory";
import { initialComboBoxAriaModel } from "../aria-roles/menus/floating-menu/consts/InitialComboBoxAriaModel";
import { IAutoCompleteProps } from "./interfaces/IAutoCompleteProps";
import { ResolvedMenu } from "./menus/ResolvedMenu.component";
import { ErrorMenu } from "./menus/ErrorMenu.component";
import { PendingMenu } from "./menus/PendingMenu.component";
import { EmptyMenu } from "./menus/EmptyMenu.component";
import "./styles/auto-complete.css";


const calculateComboBoxModel = (text: string, openLength: number, inputRef: RefObject<HTMLDivElement>): ComboboxAriaModel => {
    if (text === "") {
        return initialComboBoxAriaModel;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (text.length >= openLength && inputRef.current!.contains(document.activeElement)) {
        return {
            activeItemIndex: 0,
            isOpen: true,
            type: AriaMenuStatus.Open,
            userInput: text
        };
    }

    return {
        activeItemIndex: 0,
        isOpen: false,
        type: AriaMenuStatus.Closed,
        userInput: text
    };
};

export const AutoComplete = <T extends IMenuItem>(props: IAutoCompleteProps<T>): JSX.Element => {
    const boundary = useContext(BoundaryContext);
    const inputRef = useRef<HTMLDivElement>(null);
    const targetRef = useRef<HTMLDivElement>(null);
    const inputId = useId();
    const dropdownId = useId();
    const searchSubject = useClassRef(Subject<string>);

    const [comboBoxModel, setComboBoxModel] = useState<ComboboxAriaModel>({
        activeItemIndex: 0,
        isOpen: false,
        type: AriaMenuStatus.Closed,
        userInput: props.textContent
    });
    const [asyncModel, setAsyncModel] = useState<AsyncModel<Array<T>, string, string>>({
        error: null,
        pending: null,
        result: [],
        status: AsyncModelStatus.Resolved
    });

    const updateComboBoxModel = (model: ComboboxAriaModel): void => {
        floatingMenu.updateModel(model);
        setComboBoxModel(model);
    };
    const floatingMenu = useFactoryRef(floatingAutoCompleteMenuFactory<T>, comboBoxModel, updateComboBoxModel, inputRef, targetRef, boundary.element, props.onItemSelect, props.openLength);

    useOnMount(() => {
        const time = props.debounceTime === 0 ? of(null) : timer(props.debounceTime);

        const subscription = searchSubject
            .pipe(
                tap(() => setAsyncModel(createAsyncModelPending(props.loadingLabel))),
                debounce(() => time),
                map((searchTerm) => (searchTerm.length >= props.openLength ? props.search(searchTerm) : of([]))),
                switchMap((searchObservable) => {
                    return searchObservable.pipe(
                        map((itemArray) => createAsyncModelResult(itemArray)),
                        catchError((err: Error) => of(createAsyncModelError(err.message)))
                    );
                })
            )
            .subscribe(setAsyncModel);

        return () => {
            subscription.unsubscribe();
            searchSubject.complete();
        };
    });

    useOnMount(() => {
        if (props.textContent.length >= props.openLength) {
            searchSubject.next(props.textContent);
        }
    });

    useOnValueChange(() => {
        const model = calculateComboBoxModel(props.textContent, props.openLength, inputRef);
        updateComboBoxModel(model);

        searchSubject.next(props.textContent);
    }, [props.textContent]);

    const dropdownAriaId = comboBoxModel.isOpen ? dropdownId : undefined;
    const hasViewableResults = comboBoxModel.isOpen && asyncModel.status === AsyncModelStatus.Resolved && asyncModel.result.length > 0;

    return (
        <div>
            <div ref={inputRef}>
                <Input
                    id={inputId}
                    type="text"
                    onChange={(e) => props.onInput(e.currentTarget.value)}
                    onKeyDown={(e) => floatingMenu.events.trigger.onKeyDown(e, asyncModel.result ?? [])}
                    onFocus={(e) => floatingMenu.events.trigger.onFocus(e)}
                    onBlur={(e) => floatingMenu.events.trigger.onBlur(e)}
                    value={props.textContent}
                    placeholder={props.placeholder}
                    role="combobox"
                    aria-haspopup="listbox"
                    aria-expanded={comboBoxModel.isOpen}
                    aria-autocomplete="list"
                    aria-controls={dropdownAriaId}
                    aria-owns={dropdownAriaId}
                    aria-activedescendant={hasViewableResults ? `${inputId}-${comboBoxModel.activeItemIndex}` : undefined}
                    aria-label={props.ariaLabel}
                    aria-describedby={!hasViewableResults ? dropdownAriaId : undefined}
                    data-hook="autocomplete-input-field"
                />
            </div>

            <div className="cui-auto-complete__dropdown" ref={targetRef} style={hideWhenClosed(comboBoxModel.isOpen)} data-hook="autocomplete-dropdown-container">
                {comboBoxModel.isOpen && (
                    <Fragment>
                        {asyncModel.status === AsyncModelStatus.Error && <ErrorMenu message={asyncModel.error} id={dropdownAriaId as string} />}

                        {asyncModel.status === AsyncModelStatus.Pending && <PendingMenu message={asyncModel.pending} id={dropdownAriaId as string} />}

                        {asyncModel.status === AsyncModelStatus.Resolved && asyncModel.result.length > 0 && (
                            <ResolvedMenu
                                items={asyncModel.result}
                                renderItem={props.renderItem}
                                activeItemIndex={comboBoxModel.activeItemIndex}
                                inputId={inputId}
                                onClick={floatingMenu.events.item.onClick}
                                id={dropdownAriaId as string}
                            />
                        )}

                        {asyncModel.status === AsyncModelStatus.Resolved && asyncModel.result.length === 0 && <EmptyMenu message={props.emptyLabel} id={dropdownAriaId as string} />}
                    </Fragment>
                )}
            </div>
        </div>
    );
};
