import { angularAMD } from "@pebblepad/amd";
import template from "./inline-decision.html";

var inlineDecisionDefinition = {
    bindings: {
        positiveChoice: "@",
        negativeChoice: "@",
        confirmation: "@",
        data: "<",
        onPositiveSelect: "&",
        onNegativeSelect: "&"
    },
    template: template,
    controller: InlineDecision
};

InlineDecision.$inject = ["multiLanguageService"];
function InlineDecision(multiLanguageService) {
    this.cancelLabel = multiLanguageService.getString("buttons.cancel");
    this.displayingConfirmation = false;
}

InlineDecision.prototype.onUpdate = function (confirming) {
    this.displayingConfirmation = confirming;
};

InlineDecision.prototype.positiveConfirm = function () {
    this.onPositiveSelect({ data: this.data });
};

InlineDecision.prototype.negativeConfirm = function () {
    this.onNegativeSelect({ data: this.data });
    this.displayingConfirmation = false;
};

angularAMD.component("inlineDecision", inlineDecisionDefinition);

export { inlineDecisionDefinition as inlineDecision };
