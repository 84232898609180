import { Icon } from "../../../icon/Icon.component";
import { IIconSelectOption } from "./interfaces/IIconSelectOption";
import "./styles/icon-option.css";

export const IconOption = <T extends IIconSelectOption>(item: T): JSX.Element => (
    <span className="cui-icon-option__item">
        <Icon className={`cui-icon-option__icon`} source={item.icon} fill={item.fill} />
        <span className="cui-icon-option__item-text" title={item.value}>
            {item.value}
        </span>
    </span>
);
