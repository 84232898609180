ColourPickerService.$inject = ["multiLanguageService"];
import { angularAMD } from "@pebblepad/amd";
import "../multiLanguageService/multiLanguageService";

angularAMD.service("ColourPickerService", ColourPickerService);
angularAMD.$inject = ["multiLanguageService"];

function ColourPickerService(multiLanguageService) {
    return {
        getColours: function (allowTransparent) {
            var _colours = [
                [
                    { Value: "#000000", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.black") },
                    { Value: "#3b3c3d", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.dark_grey") },
                    { Value: "#666666", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.medium_grey") },
                    { Value: "#999999", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.grey") },
                    { Value: "#CCCCCC", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.light_grey") },
                    { Value: "#F7F7F7", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.very_light_grey") }
                ],
                [
                    { Value: "#D1CBCB", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.darkPastelBrown") },
                    { Value: "#D4D4CB", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.darkPastelGreen") },
                    { Value: "#E3E7E2", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.lightPastelGreen") },
                    { Value: "#DEE6EA", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.lightPastelBlue") },
                    { Value: "#EDE9E9", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.midPastelBrown") },
                    { Value: "#E7E5E7", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.greyPastelBlue") },
                    { Value: "#ECECE4", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.greyPastelGreen") },

                    { Value: "#ECECE4", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.lightPastelBrown") },
                    { Value: "#F1F1F1", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.lightPastelGrey") },
                    { Value: "#B4B4B4", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.darkPastelGrey") },
                    { Value: "#7D7D7D", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.lightPastelBlack") },
                    { Value: "#5C5B5B", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.darkPastelBlack") }
                ],
                [
                    { Value: "#3F0505", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.red100") },
                    { Value: "#810F12", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.red75") },
                    { Value: "#C0171B", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.red") },
                    { Value: "#EC5858", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.red50") },
                    { Value: "#E9A3A5", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.red30") },
                    { Value: "#F1C9C9", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.red15") },
                    { Value: "#FAECEC", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.red5") },

                    { Value: "#402407", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.orange100") },
                    { Value: "#924E0C", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.orange75") },
                    { Value: "#DB7518", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.orange") },
                    { Value: "#F1AA5D", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.orange50") },
                    { Value: "#F6CB9B", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.orange30") },
                    { Value: "#F9E0C4", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.orange15") },
                    { Value: "#FCF4EA", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.orange5") },

                    { Value: "#433F0A", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.yellow100") },
                    { Value: "#9B9118", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.yellow75") },
                    { Value: "#E5D727", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.yellow") },
                    { Value: "#FAF163", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.yellow50") },
                    { Value: "#FBF79D", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.yellow30") },
                    { Value: "#FDFBC5", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.yellow15") },
                    { Value: "#FEFCE9", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.yellow5") },

                    { Value: "#2B470C", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.green100") },
                    { Value: "#567F26", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.green75") },
                    { Value: "#81BC3A", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.green") },
                    { Value: "#B4EA73", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.green50") },
                    { Value: "#CFEBB0", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.green30") },
                    { Value: "#E5F4D5", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.green15") },
                    { Value: "#F5FBEE", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.green5") },

                    { Value: "#1C433C", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.teal100") },
                    { Value: "#3E8A94", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.teal75") },
                    { Value: "#5DBCB1", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.teal") },
                    { Value: "#8AE9DE", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.teal50") },
                    { Value: "#B8E8E3", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.teal30") },
                    { Value: "#D5F1EF", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.teal15") },
                    { Value: "#EAF6F6", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.teal5") },

                    { Value: "#102443", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.blue100") },
                    { Value: "#2E4978", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.blue75") },
                    { Value: "#456EB4", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.blue") },
                    { Value: "#7AA3E1", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.blue50") },
                    { Value: "#B2C7E4", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.blue30") },
                    { Value: "#D2DDF0", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.blue15") },
                    { Value: "#E9EEF4", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.blue5") },

                    { Value: "#330343", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.purple100") },
                    { Value: "#631C79", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.purple75") },
                    { Value: "#952BB3", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.purple") },
                    { Value: "#C070D9", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.purple50") },
                    { Value: "#D7ACE3", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.purple30") },
                    { Value: "#EAD4F2", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.purple15") },
                    { Value: "#F2E8F6", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.purple5") }
                ],
                [
                    { Value: "#D60B52", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.pink") },
                    { Value: "#E94F1B", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.deep_orange") },
                    { Value: "#F39200", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.light_orange") },
                    { Value: "#026E94", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.deep_blue") },
                    { Value: "#0097C5", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.sky_blue") },
                    { Value: "#00A19A", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.dark_cyan") },
                    { Value: "#A4BC1A", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.pear_green") }
                ]
            ];
            if (allowTransparent) {
                _colours[0].push({ Value: "transparent", aria_label: multiLanguageService.getString("accessibility.toolbar.background_colours.transparent") });
                return _colours;
            } else {
                return _colours;
            }
        },

        getColoursAsStringList: function (allowTransparent, groupLength, defaultGroupValue) {
            var colours = this.getColours(allowTransparent);
            return colours
                .map(function (nestedList) {
                    var mappedList = nestedList.map(function (colour) {
                        return colour.Value.slice(1);
                    });

                    var remainder = mappedList.length % groupLength;
                    if (remainder > 0) {
                        var diff = groupLength - remainder;
                        for (var i = 0; i < diff; i++) {
                            mappedList.push(defaultGroupValue);
                        }
                    }
                    return mappedList;
                })
                .join(",");
        }
    };
}
