import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";

angularAMD.factory("SpaHistoryService", SpaHistoryService);
SpaHistoryService.$inject = ["$q", "$http", "baseUrlsFactory"];

function SpaHistoryService($q, $http, baseUrlsFactory) {
    var cancelPromise;

    return {
        getHistory: function (date) {
            $http.defaults.withCredentials = true;
            cancelPromise = $q.defer();
            return $http.get(baseUrlsFactory.api_base_url + "SharedMenu/History?historyFrom=" + date + "&minimumTake=20", { timeout: cancelPromise.promise });
        },
        cancel: function () {
            cancelPromise.resolve();
        }
    };
}
