import { ScopedSearch, WithBoundaryBridge } from "@pjs/core-ui";
import { react2angular } from "@pebblepad/react2angular";
import { angularAMD } from "@pebblepad/amd";

angularAMD.component(
    "scopedSearch",
    react2angular(WithBoundaryBridge(ScopedSearch), [
        "autoFocus",
        "onSearch",
        "onChange",
        "filterModel",
        "onFilterChange",
        "filterOptions",
        "searchString",
        "scopedSearchLabel",
        "searchButtonLabel",
        "searchInputLabel",
        "clearSearchLabel",
        "dataHooks"
    ])
);
