import { angularAMD } from "@pebblepad/amd";
import "./iWantToHandler";
import "../../extensions/arrayExtensions";
import "../../multiLanguageService/multiLanguageService";

angularAMD.factory("iWantToService", [
    "$http",
    "baseUrlsFactory",
    "$q",
    "iWantToHandler",
    "multiLanguageService",
    function ($http, baseUrlsFactory, $q, iWantToHandler, multiLanguageService) {
        return {
            getAvailableActions: function (ids, searchType) {
                $http.defaults.withCredentials = true;
                const data = {
                    assetIds: ids,
                    searchType: searchType
                };

                const httpTimeout = $q.defer();
                const request = $http.post(baseUrlsFactory.api_base_url + "Asset/GetAvailableActions", data, {
                    timeout: httpTimeout.promise
                });

                const promise = request.then(
                    function (response) {
                        return response;
                    },
                    function (httpError) {
                        return httpError.status + " : " + httpError.data;
                    }
                );

                promise._httpTimeout = httpTimeout;

                return promise;
            },

            getAssetMenuItems: function (actions, amountItems, activeType) {
                if (!actions || actions.length === 0) {
                    return [];
                }

                const distinctActions = actions.distinct();
                const i18nType = multiLanguageService.getString(`pebble_terms.asset_types.${(activeType || "").toLowerCase() || ""}`, { count: amountItems });

                const menu = iWantToHandler.getOptions().filter(function (option) {
                    return distinctActions.find(function (action) {
                        return action === option.name;
                    });
                });

                menu.forEach(function (item) {
                    item.ariaLabel = multiLanguageService.getString(`buttons.i_want_to_extended_labels.${item.name.toLowerCase()}`, {
                        type: i18nType,
                        count: amountItems
                    });
                });

                return menu;
            },

            doAnyAssetsHaveLinksToTheseAssets: function (assetIds) {
                return $http.post(baseUrlsFactory.api_base_url + "Asset/DoAnyAssetsHaveLinksToTheseAssets", assetIds);
            }
        };
    }
]);
