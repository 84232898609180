import { AnswerEvidence } from "@pjs/form-response-element";
import { WithBoundaryBridge } from "@pjs/core-ui";
import { react2angular } from "@pebblepad/react2angular";
import { angularAMD } from "@pebblepad/amd";

angularAMD.component(
    "answerEvidence",
    react2angular(WithBoundaryBridge(AnswerEvidence), [
        "isEvidenceComplete",
        "isDisabled",
        "showMarkAsComplete",
        "evidence",
        "onToggleEvidenceIsComplete",
        "onEditJustification",
        "onDeleteEvidence",
        "onSelectEvidence"
    ])
);
