import { emptyTagConfig, globalAnchorTransform, IHtmlStringParserConfig } from "@pjs/security";
import { boldTransform } from "../transforms/BoldTransform.const";
import { strikeTransform } from "../transforms/StrikeTransform.const";
import { cke5BrTransform } from "../transforms/Cke5BrTransform.const";
import { italicTransform } from "../transforms/ItalicTransform.const";
import { divTransform } from "../transforms/DivTransform.const";
import { anchorConfig } from "../tag-configs/AnchorConfig.const";
import { olConfig } from "../tag-configs/OlConfig.const";
import { ulConfig } from "../tag-configs/UlConfig.const";
import { spanCleanupTransform } from "../transforms/SpanCleanupTransform.const";
import { processCssClassFactory } from "../tag-configs/ProcessCssClassFactory.function";
import { ParagraphCleanupMutation } from "../transforms/blocks/ParagraphCleanupMutation";
import { blockIndentConverter } from "../transforms/blocks/BlockIndentConverter.function";
import { IndentBlockClass } from "../../toolbar-items/enums/IndentBlockClass";
import { headingToBlockTransform } from "../transforms/HeadingToBlockTransform.const";
import { StylesToChildrenConverter } from "../transforms/blocks/StylesToChildrenConverter";
import { AttributeOnlyMutation } from "../transforms/blocks/AttributeOnlyMutation";

const styleToChildrenConverter = new StylesToChildrenConverter(["font-weight", "font-style", "text-decoration-line", "vertical-align"]);

const paragraphTransform = new ParagraphCleanupMutation([blockIndentConverter, styleToChildrenConverter.convert]);
const liTransform = new AttributeOnlyMutation([styleToChildrenConverter.convert]);

export const compactMultiLineConfig: IHtmlStringParserConfig = {
    tags: {
        a: anchorConfig,
        br: emptyTagConfig,
        i: emptyTagConfig,
        li: emptyTagConfig,
        ol: olConfig,
        p: {
            attributes: {
                class: processCssClassFactory(Object.values(IndentBlockClass).join("|"))
            },
            styles: null
        },
        s: emptyTagConfig,
        strong: emptyTagConfig,
        u: emptyTagConfig,
        ul: ulConfig
    },
    transforms: {
        a: globalAnchorTransform,
        b: boldTransform,
        br: cke5BrTransform,
        cite: italicTransform,
        div: divTransform,
        em: italicTransform,
        h1: headingToBlockTransform,
        h2: headingToBlockTransform,
        h3: headingToBlockTransform,
        h4: headingToBlockTransform,
        h5: headingToBlockTransform,
        h6: headingToBlockTransform,
        li: liTransform,
        p: paragraphTransform,
        span: spanCleanupTransform,
        strike: strikeTransform
    }
};
