import { Component, Context, createRef, ReactElement, RefObject } from "react";
import { BoundaryContext, IBoundaryContext } from "@pjs/core-ui";
import { IEditorFactoryConfig } from "../editor-factories/interfaces/IEditorFactoryConfig";
import { compactToolbarPreset } from "../toolbar-items/presets/Compact";
import { BaseEditor } from "../base-editor/BaseEditor.component";
import { multiLineFactory } from "../editor-factories/MultiLineFactory.function";
import { insertAtCaretBehaviourFactory } from "../behaviours/insert-at-caret/InsertAtCaret.function";
import { persistentEditorToolbar } from "../behaviours/persistent-editor-toolbar/PersistentEditorToolbar.function";
import { autoEnable } from "../behaviours/auto-enable/AutoEnable.function";
import { compactMultiLineParser } from "../html-parsers/CompactMultiLineParser.const.";
import { ContentInjectionEditorProps } from "./types/ContentInjectionEditorProps";
import "../../styles/attached-editor.css";
import "../../styles/persistent-editor.css";
import "../../styles/utilities.css";

export class ContentInjectionEditor extends Component<ContentInjectionEditorProps> {
    public static contextType: Context<IBoundaryContext> = BoundaryContext;
    private readonly _toolbarRef: RefObject<HTMLDivElement>;
    private readonly _config: IEditorFactoryConfig;

    constructor(props: ContentInjectionEditorProps, context: IBoundaryContext) {
        super(props);
        this._toolbarRef = createRef();

        this._config = {
            behaviours: [autoEnable, persistentEditorToolbar, insertAtCaretBehaviourFactory(this.props.contentObservable, this.props.trackingElements)],
            boundaryElement: context.element,
            parser: compactMultiLineParser,
            placeholder: this.props.placeholder,
            pluginOverrides: { linker: { onLink: this.props.onLink } },
            toolbarItems: compactToolbarPreset
        };
    }

    public render(): ReactElement {
        return (
            <div className="rt-attached-editor__container">
                <div data-hook="content-injection-editor-toolbar" className="util-sticky-editor-toolbar" ref={this._toolbarRef} />
                <BaseEditor
                    ariaLabel={this.props.ariaLabel}
                    ariaLabelledBy={this.props.ariaLabelledBy}
                    autoFocus={this.props.autoFocus}
                    className={`rt-attached-editor__text-area rt-persistent-editor__text-area ${this.props.className ?? ""}`}
                    content={this.props.content}
                    config={this._config}
                    dataHook={this.props.dataHook}
                    factory={multiLineFactory}
                    onChange={this.props.onChange}
                    onToolbarDisplayChange={this.props.onToolbarDisplayChange}
                    toolbarRef={this._toolbarRef}
                />
            </div>
        );
    }
}
