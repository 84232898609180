import { angularAMD } from "@pebblepad/amd";
import { ProgramWorkspaceStatusLinkService } from "./programWorkspaceStatusLink.service";

function programWorkspaceStatusLinkProvider() {
    let implementation = ProgramWorkspaceStatusLinkService;
    this.replaceImplementation = function (newImplementation) {
        implementation = newImplementation;
    };

    this.$get = [
        "User",
        "$http",
        "baseUrlsFactory",
        "StatusToolbarFactory",
        "$window",
        "multiLanguageService",
        function programWorkspaceStatusLinkFactory(User, $http, baseUrlsFactory, StatusToolbarFactory, $window, multiLanguageService) {
            return new implementation(StatusToolbarFactory, User, $http, baseUrlsFactory, $window, multiLanguageService);
        }
    ];
}

angularAMD.provider("programWorkspaceStatusLinkService", programWorkspaceStatusLinkProvider);
