import { angularAMD } from "@pebblepad/amd";

export class RichTextEditingSettings {
    isLegacyEditorEnabled() {
        return false;
    }

    isEditorEnabled() {
        return true;
    }
}

RichTextEditingSettings.$inject = [];
angularAMD.service("richTextEditingSettings", RichTextEditingSettings);
