import { angularAMD } from "@pebblepad/amd";
import "../../multiLanguageService/multiLanguageService";
import "../../feedback/feedbackUrlService/feedbackUrlService.service";
import "../../react2angular/assetIcon";
import template from "./attachment.html";

var attachmentDefinition = {
    bindings: {
        item: "<",
        a11yLabel: "@"
    },
    controller: Attachment,
    template: template
};

Attachment.$inject = ["multiLanguageService", "feedbackUrlService"];
function Attachment(multiLanguageService, feedbackUrlService) {
    this.multiLanguageService = multiLanguageService;
    this.feedbackUrlService = feedbackUrlService;

    this.url = this.feedbackUrlService.createFeedbackAttachmentUrl(this.item.AssetId, this.item.MainType, true);
    this.target = "_blank";
}

angularAMD.component("attachment", attachmentDefinition);

export { attachmentDefinition as attachment };
