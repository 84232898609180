import { angularAMD } from "@pebblepad/amd";
import "../../../multiLanguageService/multiLanguageService";
import "../feedbackText/feedbackText.component";
import template from "./similarity-score.html";

var similarityScoreDefinition = {
    bindings: {
        feedback: "<"
    },
    template: template,
    controller: SimilarityScoreComponent
};

SimilarityScoreComponent.$inject = ["multiLanguageService"];
function SimilarityScoreComponent(multiLanguageService) {
    this.multiLanguageService = multiLanguageService;
}

angularAMD.component("similarityScore", similarityScoreDefinition);
export { similarityScoreDefinition as similarityScore };
