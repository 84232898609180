import { angularAMD } from "@pebblepad/amd";

class DynamicStatusServiceLoader {
    constructor($injector) {
        this._injector = $injector;
    }

    getPrexistingLockService() {
        return import("../statusToolbar/services/preexistingLockStatus.service").then(() => {
            return this._injector.get("PreexistingLockStatusService");
        });
    }

    getAssetLockedService() {
        return import("../statusToolbar/services/assetLockedStatus.service").then(() => {
            return this._injector.get("AssetLockedStatusService");
        });
    }

    getRecoveredAssetHelperService() {
        return import("../recoveredAsset/services/RecoveredAssetHelper.service").then(() => {
            return this._injector.get("recoveredAssetHelperService");
        });
    }
}

DynamicStatusServiceLoader.$inject = ["$injector"];
angularAMD.service("DynamicStatusServiceLoader", DynamicStatusServiceLoader);

export { DynamicStatusServiceLoader };
