import { angularAMD } from "@pebblepad/amd";
import "../../utilities/baseUrlsFactory";
import "../../dropdown/dropdown";
import template from "../templates/combo-box.html";

angularAMD.directive("comboBox", function () {
    return {
        template: template,
        restrict: "E",
        replace: true,
        link: function (scope, element, attrs) {
            scope.selectedName = "";

            scope.$watch(attrs.options, function (newOptions) {
                scope.options = newOptions;

                for (var i = 0; i < scope.options.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (scope.options[i].type != undefined && scope.options[i].type != scope.selectedName) {
                        scope.selectedName = scope.options[i].type;
                        scope.options.splice(i, 0, { name: scope.selectedName, type: "selectedName" });
                    }
                }

                if (scope.selectedValue) {
                    angular.forEach(newOptions, function (option) {
                        // tslint:disable-next-line:triple-equals
                        if (option.value == scope.selectedValue) {
                            scope.selectedName = option.name;
                        }
                    });
                } else {
                    //if no filter, set to first in list
                    scope.selectOption(newOptions[0]);
                }
            });

            scope.$on("setComboBoxValue", function (event, value) {
                for (var i = 0; i < scope.options.length; i++) {
                    // tslint:disable-next-line:triple-equals
                    if (scope.options[i].value == value) {
                        scope.selectOption(scope.options[i]);
                    }
                }
            });

            scope.selectOption = function (selectedOption) {
                scope.selectedName = selectedOption.name;
                scope.selectedValue = selectedOption.value;
            };
        }
    };
});
