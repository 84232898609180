import { ButtonClass, DialogSize, launchDialog } from "@pjs/core-ui";

export const launchDeleteDialog = (multiLanguageService, onConfirm, numItems, hasLinkedAssets, cachedTrigger) => {
    let dialogTitle;
    let dialogDescription = multiLanguageService.getString("asset_store.confirmations.dialogs.delete_description", { count: numItems });

    if (hasLinkedAssets) {
        let linkedWarning = multiLanguageService.getString("asset_store.confirmations.item_warning", { count: numItems });
        dialogDescription = `${linkedWarning}<br/><br/>${dialogDescription}`;
        dialogTitle = multiLanguageService.getString("asset_store.confirmations.dialogs.delete_linked_title", { count: numItems });
    } else {
        dialogTitle = multiLanguageService.getString("asset_store.confirmations.dialogs.delete_title", { count: numItems });
    }

    const config = {
        size: DialogSize.Small,
        initialModel: { htmlContent: dialogDescription },
        dismissAction: (_model, instance) => {
            instance.close(cachedTrigger);
        },
        component: () => import("@pjs/core-ui").then((module) => module.SimpleHtmlDialogContent),
        title: dialogTitle,
        actions: [
            {
                label: multiLanguageService.getString("buttons.cancel"),
                ariaLabel: multiLanguageService.getString("buttons.cancel"),
                buttonClass: ButtonClass.Outlined,
                dataHook: "modal-cancel-button",
                disabled: () => false,
                action: (_model, instance) => {
                    instance.close(cachedTrigger);
                }
            },
            {
                label: multiLanguageService.getString("asset_store.confirmations.dialogs.delete"),
                ariaLabel: multiLanguageService.getString("asset_store.confirmations.dialogs.delete"),
                buttonClass: ButtonClass.Contained,
                dataHook: "asset-action-modal-confirm",
                disabled: () => false,
                action: (_model, instance) => {
                    instance.close();
                    onConfirm(instance);
                }
            }
        ]
    };

    launchDialog(config);
};
