import { ASSESSMENT_CONSTANTS } from "../../constants/assessment.constants";
import { FEEDBACK_ERRORS } from "../../constants/feedbackErrors.constants";
import { ObservableList } from "../../observer/observableList";
import { angularAMD } from "@pebblepad/amd";
import { AbortablePromiseSet } from "../../abortablePromises/AbortablePromiseSet";
import { from$Promise } from "../../observables/From$Promise.function";
import "../../observables/To$AbortablePromise.factory";
import "../../spaMenu/assetInfoPanel/assetFeedback/assetFeedbackService";
import "../../multiLanguageService/multiLanguageService";
import "../../utilities/baseUrlsFactory";
import "../../modal/services/modal";

class FeedbackDataService {
    constructor($timeout, $q, assetFeedbackService, multiLanguageService, modal, baseUrlsFactory, to$AbortablePromise) {
        this.feedbackInstructions = new Map();
        this.mostRecentFlags = [true, undefined];

        this._$q = $q;
        this._$timeout = $timeout;
        this._assetFeedbackService = assetFeedbackService;
        this._multiLanguageService = multiLanguageService;
        this._modal = modal;
        this._baseUrlsFactory = baseUrlsFactory;
        this._to$AbortablePromise = to$AbortablePromise;

        this._observables = {};
        this._pendingGetAllRequests = new Map();
        this._maxRetries = 30;
        this._abortablePromiseSet = new AbortablePromiseSet();
        this._pollingTimeouts = [];
    }

    setObservable(assetId, list) {
        return (this._observables[assetId] = new ObservableList(list, (a, b) => a.Id === b.Id));
    }

    setFeedbackInstructions(assetId, instructions) {
        this.feedbackInstructions.set(assetId, instructions);
    }

    getObservable(assetId) {
        return this._observables[assetId];
    }

    getFeedbackInstructions(assetId) {
        let feedbackInstructions = this.feedbackInstructions.get(assetId);
        return feedbackInstructions !== undefined && feedbackInstructions !== "" ? feedbackInstructions : "";
    }

    getObservableAsync(assetId) {
        if (this._observables[assetId] !== undefined) {
            return this._$q.when(this._observables[assetId]);
        }

        return this._assetFeedbackService.getFeedback(assetId, "").then((response) => {
            if (response.data.FeedbackInstructions !== null && response.data.FeedbackInstructions !== "") {
                this.setFeedbackInstructions(assetId, response.data.FeedbackInstructions);
            }
            this.setObservable(assetId, response.data.FeedbackItems);
            return this._observables[assetId];
        });
    }

    updateMostRecentFlagsForBlockFeedback(assetId) {
        if (this._canObserve(assetId)) {
            this._calculateMostRecentFlags(this._observables[assetId].listArray);
        }
    }

    createOrUpdateObservable(mainAssetId, anchorId = "") {
        if (this._pendingGetAllRequests.has(mainAssetId)) {
            return this._to$AbortablePromise(this._pendingGetAllRequests.get(mainAssetId));
        }

        const abortableStream = from$Promise(
            this._assetFeedbackService
                .getFeedback(mainAssetId, "", anchorId)
                .then((response) => this._updateFeedbackData(mainAssetId, response.data.FeedbackItems, response.data.FeedbackInstructions)),
            () => this._pendingGetAllRequests.delete(mainAssetId)
        );

        this._pendingGetAllRequests.set(mainAssetId, abortableStream);
        return this._to$AbortablePromise(abortableStream);
    }

    createOrUpdateObservableFromPage(mainAssetId, pageAssetId) {
        if (this._pendingGetAllRequests.has(mainAssetId)) {
            return this._to$AbortablePromise(this._pendingGetAllRequests.get(mainAssetId));
        }

        return this._assetFeedbackService.getFeedback(mainAssetId, pageAssetId, "").then((response) => {
            this._updateFeedbackData(mainAssetId, response.data.FeedbackItems, null);
        });
    }

    _updateFeedbackData(assetId, feedbackItems, feedbackInstructions) {
        const observableList = this.getObservable(assetId);

        if (feedbackInstructions !== null && feedbackInstructions !== "") {
            this.setFeedbackInstructions(assetId, feedbackInstructions);
        }

        if (observableList === undefined) {
            const modifiedFeedback = this._calculateMostRecentFlags(feedbackItems);
            this.setObservable(assetId, modifiedFeedback);

            return;
        }

        const combinedDataSet = observableList.listArray.concat(feedbackItems);
        const modifiedFeedback = this._calculateMostRecentFlags(combinedDataSet);
        observableList.merge(modifiedFeedback);
    }

    subscribe(assetId, observer) {
        if (this._canObserve(assetId)) {
            this._observables[assetId].subscribe(observer);
        }
    }

    unsubscribe(assetId, observer) {
        if (this._canObserve(assetId)) {
            this._observables[assetId].unsubscribe(observer);
        }
    }

    add(assetId, submissionId, newFeedbackItem) {
        const deferred = this._$q.defer();
        if (this._canObserve(assetId)) {
            this._assetFeedbackService
                .addFeedback(newFeedbackItem)
                .then((response) => {
                    const feedbackData = response.data;
                    feedbackData.Pending = true;
                    this._observables[assetId].add(feedbackData);
                    this._pollReleasedStatus(assetId, feedbackData, submissionId, 0);
                    deferred.resolve();
                })
                .catch((error) => {
                    this.evaluateError(error, "save");
                    deferred.reject();
                });
        } else {
            deferred.reject();
        }

        return deferred.promise;
    }

    saveMultiple(assetId, submissionId, newFeedbackItems, pageId, feedbackType) {
        if (!this._canObserve(assetId)) {
            return this._$q.reject();
        }

        return this._assetFeedbackService
            .saveMultipleFeedback(newFeedbackItems, feedbackType, assetId, submissionId)
            .then((response) => {
                this._addOrUpdateFeedback(assetId, submissionId, newFeedbackItems, response.data, pageId);
            })
            .catch((error) => {
                if (error.data !== undefined && error.data !== null && error.data.Failures !== undefined && error.data.Successes !== undefined) {
                    this._addOrUpdateFeedback(assetId, submissionId, newFeedbackItems, error.data.Successes, pageId);
                    this.evaluateError(error, "save_partial");
                    return this._$q.reject(error);
                } else {
                    this.evaluateError(error, "save");
                    return this._$q.reject();
                }
            });
    }

    remove(assetId, item) {
        const deferred = this._$q.defer();
        if (this._canObserve(assetId)) {
            const newFeedbackItem = { ...item, Pending: true };
            this._observables[assetId].replace(item, newFeedbackItem);

            this._assetFeedbackService
                .deleteFeedback(item.AssetId, item.Id)
                .then(() => {
                    this._observables[assetId].remove(newFeedbackItem);
                    deferred.resolve();
                })
                .catch((error) => {
                    this._observables[assetId].replace(newFeedbackItem, item);
                    this.evaluateError(error, "delete");
                });
        } else {
            deferred.reject();
        }

        return deferred.promise;
    }

    replace(assetId, newFeedbackItem, oldFeedbackItem) {
        const deferred = this._$q.defer();
        if (this._canObserve(assetId)) {
            this._assetFeedbackService
                .editFeedback(newFeedbackItem)
                .then((response) => {
                    const feedbackData = response.data;
                    feedbackData.Pending = true;

                    this._observables[assetId].replace(oldFeedbackItem, feedbackData);
                    this._pollReleasedStatus(assetId, feedbackData, oldFeedbackItem.SubmissionId, 0);
                    deferred.resolve();
                })
                .catch((error) => {
                    this.evaluateError(error, "update");
                    deferred.reject();
                });
        } else {
            deferred.reject();
        }

        return deferred.promise;
    }

    updateReleasedStatus(assetId, feedbackItem, newFeedbackItem) {
        if (this._canObserve(assetId)) {
            this._observables[assetId].replace(feedbackItem, newFeedbackItem);
            return this._assetFeedbackService
                .editFeedback(newFeedbackItem)
                .then((response) => {
                    newFeedbackItem.Pending = true;
                    this._pollReleasedStatus(assetId, newFeedbackItem, feedbackItem.SubmissionId, 0);
                })
                .catch((error) => {
                    this.evaluateError(error, "released_status");
                    this._observables[assetId].replace(newFeedbackItem, feedbackItem);
                    return this._$q.reject();
                });
        }
        return this._$q.reject();
    }

    /**
     * Method looks for first matching feedbackItem with a `MostRecentForElementId:true`.
     * If no match found it returns undefined
     * @param assetId {string}
     * @param anchorId {string}
     * @param excludeRecalled {boolean}
     * @param [pageAssetId]  {string|null}
     * @returns {undefined|FeedbackItem}
     */
    getMostRecentElementFeedback(assetId, anchorId, excludeRecalled, pageAssetId = null) {
        if (this._canObserve(assetId)) {
            const observable = this._observables[assetId];

            return observable.listArray.find((feedback) => {
                if (feedback.AnchorId === anchorId && feedback.AssetId === pageAssetId && !(excludeRecalled && feedback.Recalled) && this.mostRecentFlags.contains(feedback.MostRecentForElementId)) {
                    return feedback;
                }
            });
        }

        return;
    }

    getElementFeedback(assetId, anchorId, pageAssetId) {
        if (this._canObserve(assetId)) {
            const observable = this._observables[assetId];

            return observable.listArray.filter((feedback) => feedback.AnchorId === anchorId && feedback.AssetId === pageAssetId);
        }
        return [];
    }

    clearFeedbackInstructions() {
        this.feedbackInstructions.clear();
    }

    stopPolling() {
        this._abortablePromiseSet.abortAll();

        for (const timeout of this._pollingTimeouts) {
            this._$timeout.cancel(timeout);
        }
        this._pollingTimeouts.length = 0;
    }

    _addOrUpdateFeedback(assetId, submissionId, newFeedbackItems, feedbackData, pageId) {
        const newAnchorIds = newFeedbackItems.reduce((result, feedback) => {
            if (feedback.Id === undefined) {
                result.push(feedback.AnchorId);
            }
            return result;
        }, []);

        for (const feedback of feedbackData) {
            feedback.Pending = true;

            if (newAnchorIds.includes(feedback.AnchorId)) {
                this._observables[assetId].add(feedback);
            } else {
                const oldFeedbackItem = this.getMostRecentElementFeedback(assetId, feedback.AnchorId, false, pageId);
                this._observables[assetId].replace(oldFeedbackItem, feedback);
            }
            this._pollReleasedStatus(assetId, feedback, submissionId, 0);
        }
    }

    _calculateMostRecentFlags(feedbackItems) {
        feedbackItems.forEach((feedbackItem) => {
            let mostRecent = false;
            const assetId = feedbackItem.AssetId;

            if (feedbackItem.AnchorId !== null) {
                mostRecent = this._isFeedbackMostRecent(feedbackItems, feedbackItem, assetId);
            }

            feedbackItem.MostRecentForElementId = mostRecent;
        });

        const capabilityApprovals = feedbackItems.filter((feedbackItem) => feedbackItem.FeedbackType === ASSESSMENT_CONSTANTS.FEEDBACK_TYPES.CAPABILITY);
        capabilityApprovals.forEach((feedbackItem) => {
            feedbackItem.MostRecentApproval = this._isFeedbackMostRecent(capabilityApprovals, feedbackItem, feedbackItem.AssetId);
        });

        return feedbackItems;
    }

    _isFeedbackMostRecent(feedbackItems, feedbackItem, assetId) {
        return !feedbackItems.some((subFeedbackItem) => {
            return (
                feedbackItem.Id !== subFeedbackItem.Id && feedbackItem.AnchorId === subFeedbackItem.AnchorId && assetId === subFeedbackItem.AssetId && feedbackItem.Modified < subFeedbackItem.Modified
            );
        });
    }

    evaluateError(error, error_type) {
        let errorText = "";
        if (error.data !== undefined && error.data !== null) {
            if (error.data === FEEDBACK_ERRORS.NO_PERMISSION_ERROR) {
                errorText = this._multiLanguageService.getString("sidebar.asset_feedback.messages.no_permission");
            } else if (error.data.Message !== undefined) {
                errorText = error.data.Message;
            }
        }
        if (errorText === "") {
            errorText = this._multiLanguageService.getString(`sidebar.asset_feedback.http_error_${error_type}`);
        }

        this._launchHttpErrorModal(
            this._multiLanguageService.getString("sidebar.asset_feedback.generic_http_error_message", { error: errorText }),
            this._multiLanguageService.getString("sidebar.asset_feedback.generic_http_error_title")
        );
    }

    _finalisePendingItem(assetId, pendingItem, fullFeedback) {
        this._observables[assetId].replace(pendingItem, fullFeedback);
    }

    _pollReleasedStatus(assetId, feedbackItem, submissionId, retries) {
        const pollingTimeOut = this._$timeout(() => {
            const getStatePromise = this._assetFeedbackService
                .getState(feedbackItem.Id)
                .then((response) => {
                    retries++;
                    if ((feedbackItem.Released || feedbackItem.Releasing) === (response.data === ASSESSMENT_CONSTANTS.RELEASE_STATES.RELEASED)) {
                        const getFeedbackByIdPromise = this._assetFeedbackService
                            .getFeedbackById(feedbackItem.Id, assetId, submissionId)
                            .then((response) => {
                                this._finalisePendingItem(assetId, feedbackItem, response.data);
                            })
                            .catch((error) => {
                                this.evaluateError(error, "retrieve_id");
                            });

                        this._abortablePromiseSet.add(getFeedbackByIdPromise);
                    } else if (retries < this._maxRetries) {
                        this._pollReleasedStatus(assetId, feedbackItem, submissionId, retries);
                    }
                })
                .catch((error) => {
                    this.evaluateError(error, "retrieve_state");
                });

            this._abortablePromiseSet.add(getStatePromise);
            const index = this._pollingTimeouts.indexOf(pollingTimeOut);
            if (index !== -1) {
                this._pollingTimeouts.splice(this._pollingTimeouts.indexOf(pollingTimeOut), 1);
            }
        }, 1000);

        this._pollingTimeouts.push(pollingTimeOut);
    }

    _canObserve(assetId) {
        return this._observables[assetId] !== undefined;
    }

    _launchHttpErrorModal(message, title) {
        const templateUrl = this._baseUrlsFactory.shared_component_base_url + "spaMenu/okmessage.lazy.html";
        const modalProps = {
            title: title,
            confirmMessage: message,
            multiLanguageService: this._multiLanguageService,
            okButton: this._multiLanguageService.getString("buttons.confirm"),
            okButtonTitle: this._multiLanguageService.getString("buttons.generic.popup"),
            onClose: angular.noop
        };

        return this._modal.launch(templateUrl, modalProps);
    }
}

FeedbackDataService.$inject = ["$timeout", "$q", "assetFeedbackService", "multiLanguageService", "modal", "baseUrlsFactory", "to$AbortablePromise"];
angularAMD.service("feedbackDataService", FeedbackDataService);
