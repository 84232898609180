import { DecoupledEditor } from "@pebblepad/ckeditor";
import { Subscription } from "@pjs/observables";
import { IEditor } from "../../editor/interfaces/IEditor";
import { EditorEnabledState } from "../../editor/types/EditorEnabledState";
import { IEditorCache } from "../../editor-cache/interfaces/IEditorCache";
import { ToolbarDisplay } from "../../editor/ToolbarDisplay";

export class LimitedInstancesCoordinator {
    private readonly _cache: IEditorCache;
    private readonly _editor: IEditor;
    private readonly _onFirstFocusBound: () => void;
    private readonly _onSourceElementBlurBound: () => void;
    private readonly _onEditorChangeDataBound: () => void;
    private readonly _onEditorChangeFocusBound: (_eventInfo: unknown, name: string, value: boolean) => void;
    private readonly _enabledSubscription: Subscription;

    private _pendingEnableTimer: number = 0;
    private _pendingDisableTimer: number = 0;

    constructor(editor: IEditor, cache: IEditorCache) {
        this._cache = cache;
        this._editor = editor;
        this._onFirstFocusBound = this._onFirstFocus.bind(this);
        this._onSourceElementBlurBound = this._onSourceElementBlur.bind(this);
        this._onEditorChangeDataBound = this._onEditorChangeData.bind(this);
        this._onEditorChangeFocusBound = this._onEditorFocusChange.bind(this);

        editor.sourceElement.addEventListener("focus", this._onFirstFocusBound);
        editor.sourceElement.addEventListener("blur", this._onSourceElementBlurBound);

        this._enabledSubscription = editor.enabled.subscribe((editorState: EditorEnabledState) => {
            if (editorState.isEnabled) {
                this._onEditorEnabled(editorState);
                return;
            }
            this._onEditorDisabled();
        });
    }

    public destroy(): void {
        this._cache.remove(this._editor);

        clearTimeout(this._pendingEnableTimer);
        clearTimeout(this._pendingDisableTimer);

        this._editor.sourceElement.removeEventListener("focus", this._onFirstFocusBound);
        this._editor.sourceElement.removeEventListener("blur", this._onSourceElementBlurBound);
        this._enabledSubscription.unsubscribe();
    }

    private _onFirstFocus(): void {
        this._pendingEnableTimer = window.setTimeout(() => {
            this._editor.enable();
            this._editor.sourceElement.removeEventListener("focus", this._onFirstFocusBound);
        }, 0);
    }

    private _onSourceElementBlur(): void {
        if (this._editor.getInstance() === null) {
            clearTimeout(this._pendingEnableTimer);
            this._onEditorBlurred();
        }
    }

    private _onEditorEnabled(state: EditorEnabledState): void {
        const editor = state.editor as DecoupledEditor;
        (this._editor.toolbar as ToolbarDisplay).show();

        editor.model.document.once("change:data", this._onEditorChangeDataBound);
        editor.ui.focusTracker.on("change:isFocused", this._onEditorChangeFocusBound);
        this._editor.sourceElement.removeEventListener("blur", this._onSourceElementBlurBound);
    }

    private _onEditorDisabled(): void {
        this._editor.sourceElement.addEventListener("focus", this._onFirstFocusBound);
        this._editor.sourceElement.addEventListener("blur", this._onSourceElementBlurBound);

        this._cache.remove(this._editor);
    }

    private _onEditorBlurred(): void {
        this._pendingDisableTimer = window.setTimeout(() => {
            if (this._cache.has(this._editor) && this._editor.toolbar !== null) {
                this._editor.toolbar.hide();
                return;
            }

            this._editor.disable();
        }, 0);
    }

    private _onEditorFocusChange(_eventInfo: unknown, _name: string, value: boolean): void {
        if (!value) {
            this._onEditorBlurred();
            return;
        }

        clearTimeout(this._pendingDisableTimer);
        if (this._editor.toolbar !== null) {
            this._editor.toolbar.show();
        }
        if (this._cache.has(this._editor)) {
            this._cache.add(this._editor);
        }

        return;
    }

    private _onEditorChangeData(): void {
        this._cache.add(this._editor);
    }
}
