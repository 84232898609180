export enum SubType {
    Archive = "Archive",
    Audio = "Audio",
    Database = "Document/Database",
    Document = "Document/Text",
    Experience = "Experience",
    Feedback = "Feedback",
    Image = "Image",
    Pdf = "Document/Pdf",
    Plan = "Plan",
    Presentation = "Document/Presentation",
    Reflection = "Reflection",
    Spreadsheet = "Document/Spreadsheet",
    Talent = "Talent",
    Video = "Video",
    Worksheet = "Worksheet"
}
