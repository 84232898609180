import { angularAMD } from "@pebblepad/amd";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import "../../assetEndpointService/assetEndpoint.service";

/**
 * A data migrator that prevents duplicate evidence being added as a result of a mismatch between the list of evidence
 * from the server and the list of evidence from local storage. This mismatch occurs when the form loads with changes
 * in local storage including an out of date list of evidence.
 */
export class EvidenceMigrator {
    constructor($route, assetEndpointService, persistentObjectStore) {
        this._$route = $route;
        this._assetEndpointService = assetEndpointService;
        this._persistentObjectStore = persistentObjectStore;
    }

    /**
     * Decides whether the migrator should run or not.
     * @param {Object} assetDto - The dto representing the current asset
     * @param {Object} localStorageData - Changes to the current asset present in local storage
     * @returns {bool} - True if the migrator's run conditions are met, false otherwise
     */
    isRequired(assetDto, localStorageData) {
        return assetDto.MainType === ASSET_CONSTANTS.TYPES.FORM_RESPONSE && this._isEvidenceOutOfSync(assetDto, localStorageData);
    }

    /**
     * Force a form save to save form changes in local storage
     * @param {string} assetId - The short Id of the current asset
     * @param {Object} localStorageData - Changes to the current asset present in local storage
     * @returns {Promise} - A promise that resolves to the modified data
     */
    run(assetId, localStorageData) {
        return this._assetEndpointService
            .saveOrUpdate({ model: localStorageData })
            .then((response) => {
                this._persistentObjectStore.remove(assetId);
                this._$route.reload();
                response.data.Asset.is_saved = true;
                return response.data.Asset;
            })
            .catch(() => {
                localStorageData.is_saved = false;
                return localStorageData;
            });
    }

    /**
     * Checks for mismatches in the lists of evidence from the server and local storage.
     * @param {Object} assetDto  - The dto representing the current asset
     * @param {Object} localStorageData - Changes to the current asset present in local storage
     * @returns {bool} - Returns true if there is a mismatch in evidence between the server and local storage
     */
    _isEvidenceOutOfSync(assetDto, localStorageData) {
        const serverEvidenceElements = assetDto.Elements.filter((el) => el.Evidence.EvidenceAllowed);
        const localEvidenceElements = localStorageData.Elements.filter((el) => el.Evidence.EvidenceAllowed);

        for (const serverElement of serverEvidenceElements) {
            const localElement = localEvidenceElements.find((el) => el.Id === serverElement.Id);

            if (localElement !== undefined) {
                if (localElement.Evidence.Evidence.length > 0) {
                    if (serverElement.Evidence.Evidence.length > 0) {
                        const serverElementEvidenceIds = serverElement.Evidence.Evidence[0].EvidenceItems.map((ev) => ev.Id);
                        const localElementEvidenceIds = localElement.Evidence.Evidence[0].EvidenceItems.map((ev) => ev.Id);

                        if (serverElementEvidenceIds.length !== localElementEvidenceIds.length || !serverElementEvidenceIds.every((id) => localElementEvidenceIds.contains(id))) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else if (serverElement.Evidence.Evidence.length > 0) {
                    return true;
                }
            }
        }

        return false;
    }
}

EvidenceMigrator.token = "evidenceMigrator";
EvidenceMigrator.$inject = ["$route", "AssetEndpointService", "persistentObjectStore"];
angularAMD.service(EvidenceMigrator.token, EvidenceMigrator);
