import { angularAMD } from "@pebblepad/amd";
import { REDIRECT_BLOCKING_PURPOSES } from "../constants/redirectBlockingPurposes.constants";
import "../utilities/baseUrlsFactory";
import "../modal/services/modal";
import "../multiLanguageService/multiLanguageService";
import "../preventRedirect/preventRedirect";
import "../overlay/services/overlayFactory";
import { ButtonClass } from "@pjs/core-ui";

export class RollbackWarning {
    constructor($rootScope, $q, baseUrlsFactory, multiLanguageService, preventRedirect, overlayFactory, modal) {
        this._$rootScope = $rootScope;
        this._$q = $q;
        this._baseUrlsFactory = baseUrlsFactory;
        this._multiLanguageService = multiLanguageService;
        this._preventRedirect = preventRedirect;
        this._overlayFactory = overlayFactory;
        this._modal = modal;
    }

    launch() {
        const deferred = this._$q.defer();
        const messages = [this._multiLanguageService.getString("outdated_system_version.modal.last_action_warning")];
        const buttons = [
            {
                action: () => deferred.resolve(),
                class: ButtonClass.Outlined,
                text: this._multiLanguageService.getString("buttons.cancel")
            },
            {
                action: () => this._reloadPage(),
                class: ButtonClass.Contained,
                text: this._multiLanguageService.getString("buttons.confirm_reload")
            }
        ];

        if (this._hasUnsavedAdditionalData()) {
            messages.push(this._multiLanguageService.getString("outdated_system_version.modal.next_steps_unsaved_content"));
        } else {
            messages.push(this._multiLanguageService.getString("outdated_system_version.modal.next_steps"));
        }

        if (this._hasSeenWarningBefore()) {
            messages.push(this._multiLanguageService.getString("outdated_system_version.modal.persistent_warning"));
        } else {
            this._trackWarning();
        }

        const modalOptions = { disableClose: true };
        const modalTemplate = `${this._baseUrlsFactory.shared_component_base_url}modalDialogComponent/templates/generic-pop-up-with-repeating-content.lazy.html`;
        const modalProps = {
            buttons: buttons,
            messages: messages,
            title: this._multiLanguageService.getString("outdated_system_version.modal.title")
        };

        this._resetOverlays();
        this._modal.launch(modalTemplate, modalProps, modalOptions);

        return deferred.promise;
    }

    _hasSeenWarningBefore() {
        try {
            return window.sessionStorage.getItem(RollbackWarning.trackingKey) === "true";
        } catch (_) {
            return false;
        }
    }

    _hasUnsavedAdditionalData() {
        const areasWithUnsavedContent = this._preventRedirect.whatIsBlocking();
        return areasWithUnsavedContent.length > 0 && areasWithUnsavedContent.some((area) => area !== REDIRECT_BLOCKING_PURPOSES.ASSET);
    }

    _reloadPage() {
        this._$rootScope.appIsLoaded = false;
        this._preventRedirect.reset();
        window.location.reload();
    }

    _resetOverlays() {
        this._overlayFactory.hide();
        this._overlayFactory.saveOverlay.hide();
        this._$rootScope.appIsLoaded = true;
    }

    _trackWarning() {
        try {
            window.sessionStorage.setItem(RollbackWarning.trackingKey, "true");
            // tslint:disable-next-line:no-empty
        } catch (_) {}
    }
}
RollbackWarning.$inject = ["$rootScope", "$q", "baseUrlsFactory", "multiLanguageService", "preventRedirect", "overlayFactory", "modal"];
RollbackWarning.serviceName = "rollbackWarning";
RollbackWarning.trackingKey = "seen-rollback-warning";

angularAMD.service(RollbackWarning.serviceName, RollbackWarning);
