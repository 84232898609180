import { angularAMD } from "@pebblepad/amd";
import { tracker } from "@pjs/analytics";
import "../assetStore/services/assetStoreService";
import "../assetStore/services/useResourceService";
import "./baseUrlsFactory";
import "../modal/services/modal";
import "../multiLanguageService/multiLanguageService";
import "../exportOptions/exportOptions";
import "../formComponents/StandardsElement/standardsInformation.service";
import "./urlService";
import angular from "angular";

angularAMD.factory("assetActionService", [
    "$compile",
    "$location",
    "assetStoreService",
    "useResourceService",
    "baseUrlsFactory",
    "$rootScope",
    "modal",
    "multiLanguageService",
    "standardsInformation",
    "urlService",
    "AssetEndpointService",
    function ($compile, $location, assetStoreService, useResourceService, baseUrlsFactory, $rootScope, modal, multiLanguageService, standardsInformation, urlService, assetEndpointService) {
        function Actions() {
            this.aliases = {
                viewAssets: this.view.bind(this),
                copyAssets: this.copy.bind(this),
                shareAssets: this.share.bind(this),
                tagAssets: this.tag.bind(this),
                exportAssets: this.export.bind(this),
                purgeAssets: this.purge.bind(this),
                deleteAssets: this.delete.bind(this),
                restoreAssets: this.restore.bind(this),
                archiveAssets: this.archive.bind(this),
                unarchiveAssets: this.unarchive.bind(this),
                mapAssets: this.map.bind(this),
                favouriteAssets: this.favourite.bind(this),
                unfavouriteAssets: this.unfavourite.bind(this)
            };
            this.paramsDefault = {};
        }

        //Helpers for adding aliases and running callbacks ------------------------------------------------------------------------
        //Call a function via an Alias
        Actions.prototype.invoke = function (name, id, params) {
            var callback = this.aliases[name];
            params = typeof params !== "object" ? this.paramsDefault : params;

            if (typeof callback !== "undefined") {
                callback(id, params);
                return true;
            }
            return false;
        };

        //Add an Alias - Only affects one instances of the Action object
        Actions.prototype.addAction = function (alias, callback) {
            this.aliases[alias] = callback;
        };

        Actions.prototype.removeAction = function (alias) {
            delete this.aliases[alias];
        };

        //Use when there maybe references to callbacks/functions which belong to something being deleted and the instance of this object remains alive.
        Actions.prototype.removeAllActions = function () {
            this.aliases = null;
        };

        //Default Actions below ------------------------------------------------------------------------
        Actions.prototype.share = function (assetIds, params) {
            if (params.scope) {
                var sharingScope = params.scope.$new(true);
                sharingScope.assetIds = assetIds.slice(); // need to copy value because original reference array get cleaned up in assetStore.js
                sharingScope.shareMode = "web";
                sharingScope.modalDetails = {
                    title: multiLanguageService.getString("buttons.iWantToHandler.share"),
                    icon: "share"
                };

                modal.newModal({
                    scope: sharingScope,
                    autofocus: false,
                    templateUrl: baseUrlsFactory.shared_component_base_url + "shareStart/templates/modal-wrapper.lazy.html"
                });
            }
        };

        Actions.prototype.view = function (assetIds, params) {
            if (params.type === "resource") {
                assetIds.forEach(function (assetId) {
                    useResourceService.checkUse({ AssetId: assetId });
                });
            } else if (params.type === "asset") {
                assetStoreService.openAssets(assetIds);
            } else if (params.type === "deleted") {
                assetIds.forEach(function (assetId) {
                    // should only ever be one as you can't open/view multiple deleted items
                    $location.url("viewer/" + assetId);
                });
            }
        };

        Actions.prototype.copy = function (assetId, params) {
            if (params.scope) {
                var copyScope = params.scope.$new(true);
                copyScope.assetId = assetId[0];

                modal.newModal({
                    scope: copyScope,
                    templateUrl: baseUrlsFactory.shared_component_base_url + "takeACopy/templates/modal-wrapper.lazy.html"
                });
            }
        };

        Actions.prototype.tag = function (assetIds, params) {
            $rootScope.$broadcast("openRightHandSidePanel", {
                panel: "asset-info",
                assetId: assetIds[0],
                actionData: {
                    preview: false,
                    jumpToSection: "tagger-wrapper",
                    elementToFocus: "auto-complete-search"
                }
            });
        };

        Actions.prototype.export = function (assetId, params) {
            if (params.scope) {
                var modalScope = params.scope.$new(true);
                modalScope.assetId = assetId[0];
                modalScope.multiLanguageService = multiLanguageService;
                modalScope.onClose = function () {
                    modalScope.$destroy();
                };

                modalScope.optionalContent = { comments: true, feedback: false };
                assetEndpointService.getBasicDetails(modalScope.assetId).then((rsp) => {
                    modalScope.optionalContent = { comments: true, feedback: rsp.data.CurrentUserCanEdit };
                    modalScope.$applyAsync();
                });

                modal.newModal({
                    scope: modalScope,
                    templateUrl: baseUrlsFactory.shared_component_base_url + "exportOptions/templates/modal-export.lazy.html"
                });
            }
        };

        Actions.prototype.purge = function (assetIds, ignoreDeletedFolder) {
            return assetStoreService.purgeAssets(assetIds, ignoreDeletedFolder).then(function (response) {
                return response.data ? assetIds : [];
            });
        };

        Actions.prototype.delete = function (assetIds) {
            return assetStoreService.deleteAssets(assetIds).then(function (response) {
                return response.data ? assetIds : [];
            });
        };

        Actions.prototype.restore = function (assetIds) {
            return assetStoreService.restoreAssets(assetIds).then(function (response) {
                return response.data ? assetIds : [];
            });
        };

        Actions.prototype.archive = function (assetIds) {
            return assetStoreService.archiveAssets(assetIds).then(function (response) {
                return response.data ? assetIds : [];
            });
        };

        Actions.prototype.unarchive = function (assetIds) {
            return assetStoreService.unarchiveAssets(assetIds).then(function (response) {
                return response.data ? assetIds : [];
            });
        };

        Actions.prototype.map = function (assetIds, params) {
            standardsInformation.setStandardsData([], "", [], assetIds[0], "", "", false, false);
            standardsInformation.setNavigationalUrl(assetIds[0], $location.url());

            const mappingUrl = urlService.configureMappingUrl(assetIds[0]);
            $location.url(mappingUrl);
        };

        Actions.prototype.favourite = function (assetIds) {
            return assetStoreService
                .favouriteAsset(assetIds, true)
                .then(function (response) {
                    tracker.trackEvent("Favourite", "Add");
                    return response.data !== null ? assetIds : [];
                })
                .catch(() => []);
        };

        Actions.prototype.unfavourite = function (assetIds) {
            return assetStoreService
                .favouriteAsset(assetIds, false)
                .then(function (response) {
                    tracker.trackEvent("Favourite", "Remove");
                    return response.data !== null ? assetIds : [];
                })
                .catch(() => []);
        };

        return Actions;
    }
]);
