import { FocusEvent, KeyboardEvent, MouseEvent, RefObject } from "react";
import { Keys } from "../../../../../enums/Keys";
import { AriaMenuStatus } from "../enums/AriaMenuStatus";
import { ComboboxAriaModel } from "../types/ComboboxAriaModel";
import { selectNextItem } from "../../reducers/SelectNextItem.function";
import { selectPreviousItem } from "../../reducers/SelectPreviousItem.function";
import { setOpenedAndResetIndex } from "../../reducers/SetOpenedAndResetIndex.function";
import { closeIfFocusIsOutside } from "../../reducers/CloseIfFocusIsOutside.function";
import { IComboboxEventAdapterConfig } from "../interfaces/IComboboxEventAdapterConfig";

export const comboBoxAdapterPresetFactory = <T>(
    onItemSelect: (item: T) => void,
    openLength: number,
    triggerRef: RefObject<HTMLElement>,
    floatingRef: RefObject<HTMLElement>
): IComboboxEventAdapterConfig<T> => {
    const selectItemAndResetMenu = (_e: KeyboardEvent, currentModel: ComboboxAriaModel, items: Array<T>): Partial<ComboboxAriaModel> => {
        onItemSelect(items[currentModel.activeItemIndex]);

        return {
            activeItemIndex: 0,
            isOpen: false,
            type: AriaMenuStatus.Closed,
            userInput: ""
        };
    };

    return {
        item: {
            onClick: (_e: MouseEvent, _itemIndex: number, item: T) => {
                onItemSelect(item);

                return {
                    activeItemIndex: 0,
                    isOpen: false,
                    type: AriaMenuStatus.Closed,
                    userInput: ""
                };
            }
        },
        trigger: {
            [Keys.ArrowUp]: (e: KeyboardEvent, currentModel: ComboboxAriaModel, items: Array<T>) => {
                if (items.length > 0) {
                    e.preventDefault();

                    return selectPreviousItem(e, currentModel);
                }

                return null;
            },
            [Keys.ArrowDown]: (e: KeyboardEvent, currentModel: ComboboxAriaModel, items: Array<T>) => {
                if (items.length > 0) {
                    e.preventDefault();

                    return selectNextItem(e, currentModel, items);
                }

                return null;
            },
            [Keys.Enter]: (e: KeyboardEvent, currentModel: ComboboxAriaModel, items: Array<T>) => {
                if (currentModel.isOpen && items.length > 0) {
                    e.preventDefault();
                    e.stopPropagation();

                    return selectItemAndResetMenu(e, currentModel, items);
                }

                if (currentModel.userInput.length >= openLength) {
                    e.preventDefault();
                    e.stopPropagation();

                    return setOpenedAndResetIndex();
                }

                return null;
            },
            [Keys.Escape]: (e: KeyboardEvent, currentModel: ComboboxAriaModel): Partial<ComboboxAriaModel> | null => {
                if (currentModel.isOpen) {
                    e.preventDefault();
                    e.stopPropagation();

                    return {
                        isOpen: false,
                        type: AriaMenuStatus.ClosedByKeyboardEvent
                    };
                }

                return null;
            },
            onFocus: (_e: FocusEvent, currentModel: ComboboxAriaModel): Partial<ComboboxAriaModel> | null => {
                if (currentModel.userInput.length >= openLength) {
                    return {
                        isOpen: true,
                        type: AriaMenuStatus.Open
                    };
                }

                return null;
            },
            onFocusLoss: (e: FocusEvent): Partial<ComboboxAriaModel> | null => {
                return closeIfFocusIsOutside(e, triggerRef, floatingRef, null);
            }
        }
    };
};
