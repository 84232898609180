import { Component, ChangeEvent, ReactNode, KeyboardEvent, FocusEvent } from "react";
import { Keys } from "../../enums/Keys";
import { IValidatedInputProps } from "./interfaces/IValidatedInputProps";
import { IValidatedInputState } from "./interfaces/IValidatedInputState";

import "../input/styles/input.css";
import "./styles/validated-input.css";

export class ValidatedInput extends Component<IValidatedInputProps, IValidatedInputState> {
    public onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    public onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    public onBlur: (event: FocusEvent<HTMLInputElement>) => void;

    constructor(props: IValidatedInputProps) {
        super(props);

        this.onChange = this._onChange.bind(this);
        this.onKeyDown = this._onKeyDown.bind(this);
        this.onBlur = this._onBlur.bind(this);

        this.state = {
            touchedByUser: false,
            valid: true,
            value: props.value === undefined ? "" : props.value
        };
    }

    public componentDidUpdate(prevProps: Readonly<IValidatedInputProps>): void {
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            const isValid = this.props.value !== undefined ? this.props.isValid(this.props.value) : false;
            this.setState({
                valid: isValid,
                value: this.props.value === undefined ? "" : this.props.value
            });
        }
    }

    public render(): ReactNode {
        return (
            <input
                className={`cui-base-input ${this.props.className} ${this.state.valid ? "" : "cui-validated-input--invalid"}`}
                id={this.props.id}
                aria-label={this.props.ariaLabel}
                aria-describedby={this.props.ariaDescribedBy}
                aria-disabled={this.props.disabled}
                disabled={this.props.disabled}
                title={this.props.title}
                onChange={this.onChange}
                value={this.state.value}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                aria-invalid={!this.state.valid}
                inputMode={this.props.inputMode}
                aria-labelledby={this.props.ariaLabelledBy}
            />
        );
    }

    private _onBlur(event: FocusEvent<HTMLInputElement>): void {
        if (this.state.touchedByUser) {
            const isValid = this.props.isValid(event.target.value);
            this.setState({ valid: isValid });
        }

        if (this.props.onBlur !== undefined) {
            this.props.onBlur(event);
        }
    }

    private _onChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ touchedByUser: true, value: event.target.value });

        const isValid = this.props.isValid(event.target.value);

        if (isValid) {
            this.setState({ valid: true });
            this.props.onValidInput(event.target.value);
        } else {
            this.props.onInvalidInput(event.target.value);
        }
    }

    private _onKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
        if (event.key === Keys.Enter) {
            const isValid = this.props.isValid((event.target as HTMLInputElement).value);
            this.setState({ valid: isValid });

            if (this.props.onEnter !== undefined) {
                this.props.onEnter(event);
            }
        }
    }
}
