import { angularAMD } from "@pebblepad/amd";
import "../../utilities/domSearchHelper.service";
import "../../utilities/accessibilityKeyEventHelper.service";
import "../../utilities/helpers";
import "../../extensions/arrayExtensions";

angularAMD.service("A11yMenuFactory", A11yMenuService);
A11yMenuService.$inject = ["domSearchHelper", "accessibilityKeyEventHelper", "helpers"];

function A11yMenuService(domSearchHelper, accessibilityKeyEventHelper, helpers) {
    function A11yMenuFactory(domSearchHelper, accessibilityKeyEventHelper) {
        this.domSearchHelper = domSearchHelper;
        this.accessibilityKeyEventHelper = accessibilityKeyEventHelper;
    }

    A11yMenuFactory.prototype.createMenu = function (menuButton, menu, onClose) {
        return new RoleMenu(menuButton, menu, this.domSearchHelper, this.accessibilityKeyEventHelper, onClose);
    };

    A11yMenuFactory.prototype.createMenubar = function (menu, menuAccessor) {
        return new RoleMenubar(menu, menuAccessor, this.domSearchHelper, this.accessibilityKeyEventHelper);
    };

    /**
     * @param {JqLite} menuButton - Angular Element
     * @param {JqLite} menu
     * @param {DomSearchHelper} domSearchHelper
     * @param {AccessibilityKeyHelper} accessibilityKeyHelper
     * @param {Function} onClose
     * @constructor
     */
    function RoleMenu(menuButton, menu, domSearchHelper, accessibilityKeyHelper, onClose) {
        RoleMenuBase.call(this, menu, menuButton, domSearchHelper, accessibilityKeyHelper);
        this.onClose = onClose;
        this.onFocusHandler = this.onAnyFocus.bind(this);
        this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.upArrow] = this.focusPreviousItem.bind(this);
        this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.downArrow] = this.focusNextItem.bind(this);
        this.closeKeyMap = {};
        this.closeKeyMap[this.accessibilityKeyHelper.keyCodeMap.escape] = this.closeWithFocus.bind(this);
    }

    helpers.extendClass(RoleMenu, RoleMenuBase);

    RoleMenu.prototype.close = function (event) {
        this.onClose(event);
    };

    RoleMenu.prototype.closeWithFocus = function (event) {
        this.setTabIndex(this.menuAccessor[0], this.menuAccessorTabIndex);
        var value = this.onClose(event);

        if (value && typeof value.then === "function") {
            value.then(
                function () {
                    this.menuAccessor[0].focus();
                }.bind(this)
            );
        } else {
            this.menuAccessor[0].focus();
        }
    };

    RoleMenu.prototype.onAnyFocus = function (event) {
        if (this.domSearchHelper.elementContains(this.menuAccessor[0], event.target)) {
            this.setTabIndex(this.menuAccessor[0], this.menuAccessorTabIndex);
        } else if (!this.domSearchHelper.elementContains(this.menu[0], event.target)) {
            this.close(event);

            if (!this.subMenu) {
                //Sub menu will be closed so no need to enforce tabindex correction, parents will handle it
                this.setTabIndex(this.menuAccessor[0], this.menuAccessorTabIndex);
            }
        }
    };

    RoleMenu.prototype.onKeyUp = function (event) {
        var action = this.closeKeyMap[event.keyCode];

        if (action) {
            event.stopPropagation();
            action(event);
            return;
        }

        action = this.focusKeyMap[event.keyCode];

        if (action === void 0 && /[a-z]/i.test(event.key)) {
            action = this.focusItemByAlphaNumeric.bind(this);
        }

        if (action) {
            var elements = this.getElements();

            if (elements.length === 0) {
                this.close(event);
                return;
            }

            event.stopPropagation();
            action(event, elements);
        }
    };

    RoleMenu.prototype.configureMenu = function () {
        if (!this.configured) {
            this.menuAccessor.attr("tabindex", "-1");
            this.menu.bind("keyup", this.onKeyUp.bind(this));
            this.menu.bind("keydown", this.onKeyDown.bind(this));
            document.addEventListener("focus", this.onFocusHandler, true);
            this.configured = true;
        }
    };

    RoleMenu.prototype.unconfigureMenu = function () {
        if (this.configured) {
            this.menu.unbind("keyup");
            this.menu.unbind("keydown");

            if (!this.subMenu) {
                this.setTabIndex(this.menuAccessor[0], this.menuAccessorTabIndex);
            }
            document.removeEventListener("focus", this.onFocusHandler, true);
            this.configured = false;
        }
    };

    RoleMenu.prototype.onKeyDown = function (event) {
        var action = this.focusKeyMap[event.keyCode];
        if (action) {
            event.preventDefault();
        }

        var tabKeyPressed = event.keyCode === this.accessibilityKeyHelper.keyCodeMap.tab;
        if (tabKeyPressed) {
            this.close(event);
            RoleMenuBase.prototype.onKeyDown.call(this, null, tabKeyPressed);
        }
    };

    function RoleMenubar(menu, menuAccessor, domSearchHelper, accessibilityKeyHelper) {
        RoleMenuBase.call(this, menu, menuAccessor, domSearchHelper, accessibilityKeyHelper);
        this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.leftArrow] = this.focusPreviousItem.bind(this);
        this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.rightArrow] = this.focusNextItem.bind(this);
        this.resetFakeRovingTabIndexHandler = this.resetFakeRovingTabIndex.bind(this);
    }

    helpers.extendClass(RoleMenubar, RoleMenuBase);

    RoleMenubar.prototype.onKeyUp = function (event) {
        var action = this.focusKeyMap[event.keyCode];

        if (action === void 0 && /[a-z]/i.test(event.key)) {
            action = this.focusItemByAlphaNumeric.bind(this);
        }

        if (action) {
            event.stopPropagation();
            var listElems = this.getElements();
            var actionedElem = action(event, listElems);

            if (actionedElem) {
                var eventMenuItem = this.findEventMenuItem(listElems, event.target);

                if (actionedElem !== eventMenuItem) {
                    eventMenuItem.setAttribute("tabindex", "-1");
                    this.setTabIndex(actionedElem, this.menuAccessorTabIndex);
                }
            }
        }
    };

    RoleMenubar.prototype.findEventMenuItem = function (listElements, targetElement) {
        return listElements.find(
            function (elem) {
                return this.domSearchHelper.elementContains(elem, targetElement);
            }.bind(this)
        );
    };

    RoleMenubar.prototype.configureMenu = function () {
        if (!this.configured) {
            this.menuAccessor.bind(
                "focus",
                function () {
                    document.addEventListener("focus", this.resetFakeRovingTabIndexHandler, true);
                }.bind(this)
            );

            this.menu.bind("keyup", this.onKeyUp.bind(this));
            this.configured = true;
        }
    };

    RoleMenubar.prototype.resetFakeRovingTabIndex = function (event) {
        if (!this.menuOrAccessorContainsElement(event.target)) {
            var menuItems = this.getElements();

            menuItems.forEach(
                function (menuItem) {
                    if (menuItem !== this.menuAccessor[0] && menuItem.getAttribute("tabindex") !== "-1") {
                        menuItem.setAttribute("tabindex", "-1");
                    }
                }.bind(this)
            );

            this.setTabIndex(this.menuAccessor[0], this.menuAccessorTabIndex);
            document.removeEventListener("focus", this.resetFakeRovingTabIndexHandler, true);
        }
    };

    RoleMenubar.prototype.unconfigureMenu = function () {
        if (this.configured) {
            this.menu.unbind("keyup");
            this.menuAccessor.unbind("focus");
            document.removeEventListener("focus", this.resetFakeRovingTabIndexHandler, true);
            this.configured = false;
        }
    };

    function RoleMenuBase(menu, menuAccessor, domSearchHelper, accessibilityKeyHelper) {
        this.menu = menu;
        this.accessibilityKeyHelper = accessibilityKeyHelper;
        this.domSearchHelper = domSearchHelper;
        this.configured = false;
        this.menuAccessor = menuAccessor;
        this.menuAccessorTabIndex = this.menuAccessor.attr("tabindex");
        this.subMenu = this.menuAccessor.attr("role") === "menuitem";
        this.focusKeyMap = {};
        this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.home] = this.focusFirstItem.bind(this);
        this.focusKeyMap[this.accessibilityKeyHelper.keyCodeMap.end] = this.focusLastItem.bind(this);
    }

    RoleMenuBase.prototype.setTabIndex = function (elem, value) {
        if (value) {
            elem.setAttribute("tabindex", value);
        } else {
            elem.removeAttribute("tabindex");
        }
    };

    RoleMenuBase.prototype.onKeyDown = function (event, keyCodeChecked) {
        if (keyCodeChecked || event.keyCode === this.accessibilityKeyHelper.keyCodeMap.tab) {
            this.focusAccessor();
        }
    };

    RoleMenuBase.prototype.menuOrAccessorContainsElement = function (element) {
        return this.domSearchHelper.elementContains(this.menu[0], element) || this.domSearchHelper.elementContains(this.menuAccessor[0], element);
    };

    RoleMenuBase.prototype.getElements = function () {
        var menuItems = Array.prototype.slice.call(this.menu[0].querySelectorAll('[role="menuitem"]'), 0);
        var subMenus = this.menu[0].querySelectorAll('[role="menu"]');

        return menuItems.filter(function (menuItem) {
            for (var j = 0; j < subMenus.length; j++) {
                if (subMenus[j].contains(menuItem)) {
                    return false;
                }
            }

            return true;
        });
    };

    RoleMenuBase.prototype.focusAccessor = function () {
        this.menuAccessor[0].focus();
    };

    RoleMenuBase.prototype.focusNextItem = function (event, elements) {
        return this.focusElementByIncrementalIndex(elements, event, 1);
    };

    RoleMenuBase.prototype.focusPreviousItem = function (event, elements) {
        return this.focusElementByIncrementalIndex(elements, event, -1);
    };

    RoleMenuBase.prototype.focusElementByIncrementalIndex = function (elements, event, index) {
        var visibleElements = elements.filter(
            function (element) {
                return this.domSearchHelper.elementIsVisible(element);
            }.bind(this)
        );

        var currentIndex = visibleElements.indexOf(event.target);

        if (currentIndex === -1) {
            return this.focusFirstItem(event, visibleElements);
        }

        var newIndex = currentIndex + index;

        if (newIndex < 0) {
            return this.focusLastItem(null, visibleElements);
        } else if (newIndex >= visibleElements.length) {
            return this.focusFirstItem(null, visibleElements);
        } else {
            visibleElements[newIndex].focus();
            return visibleElements[newIndex];
        }
    };

    RoleMenuBase.prototype.focusFirstItem = function (event, elements) {
        if (elements === undefined || elements[0] === undefined) {
            return;
        }
        elements[0].focus();
        return elements[0];
    };

    RoleMenuBase.prototype.focusLastItem = function (event, elements) {
        elements[elements.length - 1].focus();
        return elements[elements.length - 1];
    };

    RoleMenuBase.prototype.focusItemByAlphaNumeric = function (event, elements) {
        var currentIndex = elements.indexOf(event.target);

        if (currentIndex === -1) {
            currentIndex = 0;
        }

        var regex = new RegExp("^[^a-z]*" + event.key, "i");

        for (var i = currentIndex + 1, l = elements.length; i < l; i++) {
            if (regex.test(elements[i].textContent)) {
                elements[i].focus();
                return elements[i];
            }
        }

        if (currentIndex !== 0) {
            for (var i = 0; i < currentIndex; i++) {
                if (regex.test(elements[i].textContent)) {
                    elements[i].focus();
                    return elements[i];
                }
            }
        }
    };

    return new A11yMenuFactory(domSearchHelper, accessibilityKeyEventHelper);
}
