import { SandboxedTagMutation } from "@pjs/security";
import { commonInlineTagNames } from "../CommonInlineTagNames.const";

export class Cke5BrMutation extends SandboxedTagMutation<HTMLBRElement> {
    private static _hasSiblingContent(sandboxedBr: HTMLBRElement, direction: "previousSibling" | "nextSibling"): boolean {
        let sibling = sandboxedBr[direction];
        while (sibling !== null) {
            if (sibling.nodeName !== "BR" && (sibling.nodeType === Node.ELEMENT_NODE || sibling.nodeType === Node.TEXT_NODE)) {
                return true;
            }

            sibling = sibling[direction];
        }

        return false;
    }

    protected _mutate(sandboxedBr: HTMLBRElement, sandboxedDocument: Document): boolean {
        const parent = sandboxedBr.parentElement;
        if (parent === null) {
            return false;
        }

        if (parent.tagName === "BODY") {
            return this._transformRootBr(sandboxedBr, sandboxedDocument);
        }

        if (parent.tagName === "LI" && parent.lastChild === sandboxedBr) {
            return false;
        }

        if (parent.childNodes.length === 1 && (parent.tagName === "P" || parent.tagName === "LI")) {
            sandboxedBr.replaceWith(sandboxedDocument.createTextNode("\u00A0"));
            return false;
        }

        const nextNode = sandboxedBr.nextSibling;
        if (nextNode !== null && (nextNode.nodeName === "UL" || nextNode.nodeName === "OL")) {
            return false;
        }

        const hasContentBefore = Cke5BrMutation._hasSiblingContent(sandboxedBr, "previousSibling");
        const hasContentAfter = Cke5BrMutation._hasSiblingContent(sandboxedBr, "nextSibling");

        if (hasContentBefore && hasContentAfter) {
            return true;
        }

        if (nextNode !== null && nextNode.nodeName === "BR") {
            sandboxedBr.insertAdjacentText("afterend", "\u00A0");
            return true;
        }

        return hasContentAfter;
    }

    private _transformRootBr(sandboxedBr: HTMLBRElement, sandboxedDocument: Document): boolean {
        const siblingsToMove: Array<Node> = [sandboxedBr];
        let previousSibling: Node | null = sandboxedBr.previousSibling;

        while (previousSibling !== null && (previousSibling.nodeType === Node.TEXT_NODE || commonInlineTagNames.includes(previousSibling.nodeName))) {
            siblingsToMove.push(previousSibling);
            previousSibling = previousSibling.previousSibling;
        }

        siblingsToMove.reverse();
        let nextSibling = sandboxedBr.nextSibling;
        while (nextSibling !== null && (nextSibling.nodeType === Node.TEXT_NODE || nextSibling.nodeName === "BR" || commonInlineTagNames.includes(nextSibling.nodeName))) {
            siblingsToMove.push(nextSibling);
            nextSibling = nextSibling.nextSibling;
        }

        if (siblingsToMove.length === 1) {
            return false;
        }

        const p = sandboxedDocument.createElement("p");
        sandboxedBr.replaceWith(p);
        p.append(...siblingsToMove);

        return true;
    }
}
