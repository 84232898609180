import { AttributeConverter } from "../../types/AttributeConverter";
import { semanticInlineSwap } from "../../../../utils/semantic-inline-tag-swap/SemanticInlineSwap.function";

export const inlineFontStyleConverter: AttributeConverter = (sandboxedElement, sandboxedDocument) => {
    const fontStyle = sandboxedElement.style.fontStyle;
    if (fontStyle === "") {
        return;
    }

    sandboxedElement.style.fontStyle = "";
    if (fontStyle !== "italic") {
        return;
    }

    semanticInlineSwap("I", sandboxedElement, sandboxedDocument, "EM");
};
