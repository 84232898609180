import { angularAMD } from "@pebblepad/amd";

angularAMD.directive("messageAnnouncer", MessageAnnouncer);
MessageAnnouncer.$inject = ["$timeout"];

function MessageAnnouncer($timeout) {
    return {
        restrict: "E",
        scope: {
            message: "@",
            delay: "@"
        },
        template: '<p aria-live="polite" aria-atomic="true"><span ng-bind="announcement"></span></p>',
        link: function (scope, element, attr) {
            attr.$observe("message", (newVal) => {
                $timeout(() => {
                    scope.announcement = newVal;
                }, scope.delay || 0);
            });
        }
    };
}
