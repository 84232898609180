import { ForwardedRef, forwardRef, ReactElement } from "react";
import { Icon } from "../icon/Icon.component";
import { IconButtonClass } from "./enums/IconButtonClass";
import { IIconButtonProps } from "./interfaces/IIconButtonProps";
import "./styles/icon-button.css";

export const IconButton = forwardRef(function IconButton(props: IIconButtonProps, ref: ForwardedRef<HTMLButtonElement>): ReactElement {
    let buttonClass = props.className !== IconButtonClass.Circular ? `${props.className} cui-icon-button` : props.className;

    if (props.hidden === true) {
        buttonClass += " cui-icon-button--hidden";
    }

    return (
        <button
            aria-label={props.ariaLabel}
            aria-hidden={props.hidden === true}
            className={buttonClass}
            data-hook={props.dataHook}
            disabled={props.disabled === true}
            onClick={props.onClick}
            type={props.type}
            ref={ref}>
            <Icon source={props.source} className="cui-icon-button__icon" />
        </button>
    );
});
