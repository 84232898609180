import { angularAMD } from "@pebblepad/amd";
import "../security/pebbleSecurity.module";

var pebbleHtml = angular.module("pebbleHtml", ["ng", "pebbleSecurity"]);

pebbleHtml.directive("compileHtml", [
    "$parse",
    "PebbleSecurityService",
    function CompileHtml($parse, pebbleSecurityService) {
        return {
            restrict: "A",
            compile: function (element, attrs) {
                var htmlGetter = $parse(attrs.compileHtml);

                var htmlSetter = function (scope, element, attrs, rawHtml) {
                    rawHtml = pebbleSecurityService.sanitise(rawHtml);
                    // tslint:disable-next-line:no-unsafe-jq-lite
                    element.html(rawHtml || "");
                };

                var actions = [htmlSetter];

                if (attrs.compileHtmlEvent) {
                    actions.push(function (scope, element, attrs) {
                        scope.$broadcast(attrs.compileHtmlEvent);
                    });
                }

                var watch = $parse(attrs.compileHtml, function (value) {
                    return value || "";
                });
                if (attrs.compileHtml.indexOf("::") === 0) {
                    actions.push(function (scope, element, attrs, value, watcher) {
                        if (value !== undefined) {
                            watcher();
                        }
                    });
                }

                return function (scope, element, attrs) {
                    var watcher = scope.$watch(watch, function () {
                        var value = htmlGetter(scope);
                        actions.forEach(function (action) {
                            action(scope, element, attrs, value, watcher);
                        });
                    });
                };
            }
        };
    }
]);
