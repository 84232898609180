import { FunctionComponent, PropsWithChildren } from "react";
import { IButtonProps } from "./interfaces/IButtonProps";
import "./styles/button.css";

export const Button: FunctionComponent<PropsWithChildren<IButtonProps>> = (props) => {
    return (
        <button
            autoFocus={props.autoFocus}
            aria-label={props.ariaLabel}
            aria-describedby={props.ariaDescribedBy}
            aria-disabled={props.ariaDisabled}
            className={`cui-button ${props.className}`}
            data-hook={props.dataHook}
            disabled={props.disabled === true}
            onClick={props.onClick}
            tabIndex={props.ariaDisabled === true ? -1 : 0}
            type={props.type}>
            <span className="cui-button__content">{props.children}</span>
        </button>
    );
};
