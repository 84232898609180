export class TimedArray<T> {
    private _items: Array<T> = [];
    private _timeout: number = 0;
    private readonly _timeoutMs: number;

    constructor(timeout: number) {
        this._timeoutMs = timeout;
    }

    public getItems(): ReadonlyArray<T> {
        return this._items;
    }

    public push(item: T): void {
        if (this._timeout !== 0) {
            window.clearTimeout(this._timeout);
        }

        this._items.push(item);

        this._timeout = window.setTimeout(() => {
            this._items = [];
            this._timeout = 0;
        }, this._timeoutMs);
    }
}
