export function scrollToElementInList(listElement: HTMLElement, activeItemElement: HTMLElement): void {
    if (listElement.scrollHeight <= listElement.clientHeight) {
        return;
    }

    if (activeItemElement.offsetTop + activeItemElement.offsetHeight > listElement.scrollTop + listElement.clientHeight) {
        listElement.scrollTop = activeItemElement.offsetTop + activeItemElement.offsetHeight - listElement.clientHeight;
    } else if (activeItemElement.offsetTop < listElement.scrollTop) {
        listElement.scrollTop = activeItemElement.offsetTop;
    }
}
