import { angularAMD } from "@pebblepad/amd";
import "../utilities/baseUrlsFactory";
import "../multiLanguageService/multiLanguageService";
import "../datePicker/datePicker";
import "../externalFromWorkspace/externalFromWorkspace";

import template from "./templates/external-from-workspace-share.html";

angularAMD.directive("externalFromWorkspaceShare", ExternalFromWorkspaceShare);
ExternalFromWorkspaceShare.$inject = [];

angularAMD.controller("ExternalFromWorkspaceShareController", ExternalFromWorkspaceShareController);
ExternalFromWorkspaceShareController.$inject = ["$scope", "multiLanguageService"];

function ExternalFromWorkspaceShare() {
    return {
        template: template,
        restrict: "E",
        scope: {
            externalList: "=", //Array - List of Externals users. Default value should be an empty array.
            workspaceId: "@", //String
            allowCreate: "=", //Boolean - Allow creation of external users
            messageToExternal: "=", //String - Message to send to externals
            expires: "=", //Date|null - Expiry date of share
            viewUsersOnly: "=" //Boolean - View mode
        },
        controller: "ExternalFromWorkspaceShareController",
        controllerAs: "vm",
        bindToController: true
    };
}

ExternalFromWorkspaceShareController.prototype.onDateChange = function (date) {
    this.expires = date;
};

function ExternalFromWorkspaceShareController($scope, _multiLanguageService_) {
    this.multiLanguageService = _multiLanguageService_;
}
