import { angularAMD } from "@pebblepad/amd";

export class LinkerGlobalConfig {
    constructor() {
        this.allowAssets = true;
    }

    $get() {
        return { allowAssets: this.allowAssets };
    }
}

angularAMD.provider(LinkerGlobalConfig.name, LinkerGlobalConfig);
