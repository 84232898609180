import { angularAMD } from "@pebblepad/amd";
import "../../../assetEndpointService/assetEndpoint.service";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import { tracker } from "@pjs/analytics";

angularAMD.factory("commentsService", [
    "AssetEndpointService",
    function (AssetEndpointService) {
        var factory = {};
        let onCommentChangeCallbacks = new Set();

        factory.addCommentListener = function (callback) {
            onCommentChangeCallbacks.add(callback);
        };

        factory.getComment = function (assetId, commentId, submissionId) {
            return AssetEndpointService.getComment(assetId, commentId, submissionId).then((response) => {
                response.data = this.mapComment(response.data);
                return response;
            });
        };

        factory.getComments = function (assetId, submissionId, answerId = "") {
            return AssetEndpointService.getComments(assetId, submissionId, answerId).then((response) => {
                response.data.Comments = response.data.Comments.map((comment) => this.mapComment(comment));
                return response;
            });
        };

        factory.addNewOrUpdate = function (dto, submissionId, anchorId = "") {
            tracker.trackEvent("Comments", "Add/edit comment", `Comment ${anchorId !== "" ? "on block" : ""} `);
            const saveCommentRequest = {
                AssetId: dto.AssetId,
                PosterName: dto.PosterName,
                CommentId: dto.Id,
                Body: dto.Body,
                ParentId: dto.ParentId,
                AnchorId: dto.AnchorId,
                NewComment: dto.NewComment || false
            };

            return AssetEndpointService.addNewOrUpdateComment(saveCommentRequest, submissionId).then((response) => {
                if (dto.NewComment && anchorId !== "") {
                    onCommentChangeCallbacks.forEach((callback) => callback(dto.AssetId, ACTION_CONSTANTS.ADD, anchorId));
                }
                return response;
            });
        };

        factory.deleteComment = function (assetId, submissionId, commentId, anchorId = "") {
            return AssetEndpointService.deleteComment(assetId, submissionId, commentId).then((response) => {
                if (anchorId !== "") {
                    onCommentChangeCallbacks.forEach((callback) => callback(assetId, ACTION_CONSTANTS.DELETE, anchorId));
                }

                if (response.data !== null) {
                    response.data = this.mapComment(response.data);
                }

                return response;
            });
        };

        factory.mapComment = function (comment) {
            return {
                AssetTitle: comment.AssetTitle,
                Anonymous: comment.Anonymous,
                Permissions: {
                    AllowEdit: comment.AllowEdit,
                    AllowDelete: comment.AllowDelete,
                    AllowReply: comment.AllowReply
                },
                AnchorId: comment.AnchorId,
                AssetId: comment.AssetId,
                AttachmentReferences: comment.AttachmentReferences,
                Attachments: comment.Attachments,
                Blanked: comment.Blanked,
                Comment: comment.Body,
                CommentType: comment.CommentType,
                Deleted: comment.Deleted,
                Replies: comment.Children !== undefined ? comment.Children.map((comment) => this.mapComment(comment)) : null,
                Id: comment.Id,
                LastModified: comment.LastModified,
                LastModifiedBy: comment.LastModifiedBy,
                LastModifierName: comment.LastModifierName,
                New: comment.New,
                Omission: comment.Omission,
                OmissionAfter: comment.OmissionAfter,
                OmissionBefore: comment.OmissionBefore,
                Owner: comment.Owner,
                Quotes: comment.Quotes,
                Subject: comment.Subject,
                TargetVersion: comment.TargetVersion,
                TargetedUsers: comment.TargetedUsers,
                Posted: comment.Posted,
                ParentId: comment.ParentId,
                Poster: {
                    Name: comment.PosterName,
                    ProfilePicId: comment.ProfileImageId
                }
            };
        };

        return factory;
    }
]);
