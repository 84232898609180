import { SandboxedTagMutation } from "@pjs/security";
import { inlineFontWeightConverter } from "./InlineFontWeightConverter.function";
import { inlineFontStyleConverter } from "./InlineFontStyleConverter.function";
import { inlineTextDecorationConverter } from "./InlineTextDecorationConverter.function";
import { inlineVerticalAlignmentConverter } from "./InlineVerticalAlignmentConverter.function";

export class InlineCleanupMutation extends SandboxedTagMutation<HTMLElement> {
    protected _mutate(sandboxedElement: HTMLElement, sandboxedDocument: Document): boolean {
        if (sandboxedElement.childNodes.length === 0) {
            this._removeEmptyElement(sandboxedElement);
            return false;
        }

        inlineFontWeightConverter(sandboxedElement, sandboxedDocument);
        inlineFontStyleConverter(sandboxedElement, sandboxedDocument);
        inlineTextDecorationConverter(sandboxedElement, sandboxedDocument);
        inlineVerticalAlignmentConverter(sandboxedElement, sandboxedDocument);

        return this._cleanupUnnecessaryParents(sandboxedElement);
    }

    private _removeEmptyElement(sandboxedElement: HTMLElement): void {
        let parent = sandboxedElement.parentElement;
        let toRemove = sandboxedElement;
        while (parent !== null && parent.tagName !== "BODY" && parent.tagName !== "LI" && parent.childNodes.length === 1) {
            toRemove = parent;
            parent = parent.parentElement;
        }

        toRemove.remove();
    }

    private _cleanupUnnecessaryParents(sandboxedElement: HTMLElement): boolean {
        const hasChildren = sandboxedElement.childNodes.length > 0;
        const shouldKeepElement = hasChildren && (sandboxedElement.tagName !== "SPAN" || sandboxedElement.style.length > 0);
        const children: Array<Node> = [];
        let parent = sandboxedElement.parentElement;
        let toReplace: Element | null = shouldKeepElement ? null : sandboxedElement;

        if (!shouldKeepElement && hasChildren) {
            sandboxedElement.replaceWith(...sandboxedElement.childNodes);
            toReplace = null;
        }

        while (parent !== null && parent.tagName === "SPAN" && parent.style.length === 0) {
            for (const child of parent.childNodes) {
                if (child !== toReplace) {
                    children.push(child);
                }
            }

            toReplace = parent;
            parent = parent.parentElement;
        }

        if (toReplace === null) {
            return true;
        }

        const childrenToInsert = toReplace === sandboxedElement ? sandboxedElement.childNodes : children;
        toReplace.replaceWith(...childrenToInsert);

        return shouldKeepElement;
    }
}
