import { angularAMD } from "@pebblepad/amd";
import { TABLE_DATA_TYPES } from "../../../constants/tableDataTypes.constants";
import "../../../utilities/helpers";
import "./tableConfig.factory";
import "../../../datePicker/datePickerHelper";
import "../../../utilities/typeCheck.service";

angularAMD.service("tableDataHelper", TableDataHelper);
TableDataHelper.$inject = ["$window", "helpers", "tableConfig", "datePickerHelper", "typeCheck"];

function TableDataHelper($window, helpers, tableConfig, datePickerHelper, typeCheck) {
    // Public API
    // =============================================================================================================
    this.getCellRole = getCellRole;
    this.convertTableDimensions = convertTableDimensions;
    this.getTableDimensions = getTableDimensions;
    this.getCellCount = getCellCount;
    this.findAndUpdateCellData = findAndUpdateCellData;
    this.calculateColWidth = calculateColWidth;
    this.capitalizeFirstLetter = capitalizeFirstLetter;
    this.getNewCell = getNewCell;
    this.generateNewCell = generateNewCell;
    this.generateNewRow = generateNewRow;
    this.getNewRow = getNewRow;
    this.getIndex = getIndex;
    this.extractIndex = extractIndex;
    this.putAtPosition = putAtPosition;
    this.removeAtPosition = removeAtPosition;
    this.updateColumnsWidth = updateColumnsWidth;
    this.getNewCol = getNewCol;
    this.formatCellId = formatCellId;
    this.resetData = resetData;
    this.getCellRoleForNonBuilder = getCellRoleForNonBuilder;
    this.getCellRoleByMode = getCellRoleByMode;
    this.findCellById = findCellById;

    // Private Properties
    // =============================================================================================================
    var resetStockValues = {
        cell: {
            Label: "",
            Points: 0,
            IsSelected: false
        },
        col: {
            Width: 0
        }
    };

    // Private methods
    // =============================================================================================================
    function getCellRole(tableType, cellId, data) {
        var cellRole;

        switch (tableType) {
            case tableConfig.type.builder.table.columnsOnly:
            case tableConfig.type.builder.table.columnsOnlyRepeating:
                cellRole = "column";
                break;
            case tableConfig.type.builder.table.rowsOnly:
                cellRole = "row";
                break;
            case tableConfig.type.builder.table.rowsAndColumns:
            case tableConfig.type.builder.rubric.rowsAndColumns:
                var firstColumnId = getFirstId("column", data),
                    firstRowId = getFirstId("row", data);

                if (cellId.indexOf(firstRowId) > -1) {
                    cellRole = "column";
                } else if (cellId.indexOf(firstColumnId) > -1) {
                    cellRole = "row";
                }
                break;
            case tableConfig.type.builder.table.singleCell:
                cellRole = "cell";
                break;
        }

        return cellRole;
    }

    function getCellRoleForNonBuilder(tableType, cellId, tableData) {
        return getCellRole("Builder" + tableType, cellId, tableData);
    }

    function getCellRoleByMode(tableData, cellId, mode) {
        return mode === TABLE_DATA_TYPES.MODES.BUILDER ? getCellRole(tableData.TableType, cellId, tableData) : getCellRoleForNonBuilder(tableData.TableType, cellId, tableData);
    }

    function convertTableDimensions(stringTableDimensions) {
        var splitValue = stringTableDimensions.toString().split(",");

        if (splitValue.length === 1) {
            return {
                cells: splitValue[0]
            };
        } else {
            return {
                cols: splitValue[0],
                rows: splitValue[1]
            };
        }
    }

    function getTableDimensions(data) {
        return {
            cols:
                data.Columns.length + _checkForHeaderInTableDimensions(data.Columns[0].ColumnType, data.TableType) + _checkForTotalInTableDimensions(data.Columns[data.Columns.length - 1].ColumnType),
            rows: data.Rows.length + _checkForHeaderInTableDimensions(data.Rows[0].RowType, data.TableType) + _checkForTotalInTableDimensions(data.Rows[data.Rows.length - 1].RowType)
        };
    }

    function _checkForHeaderInTableDimensions(rowOrColumnType, tableType) {
        if (rowOrColumnType === TABLE_DATA_TYPES.CELLS.HEADER || tableType === tableConfig.type.builder.rubric.rowsAndColumns || tableType === tableConfig.type.fillerViewer.rubric.rowsAndColumns) {
            return -1;
        }
        return 0;
    }

    function _checkForTotalInTableDimensions(rowOrColumnType) {
        if (rowOrColumnType === TABLE_DATA_TYPES.CELLS.TOTAL) {
            return -1;
        }
        return 0;
    }

    function getCellCount(data) {
        return data.Cells.length;
    }

    function cleanupDateString(data) {
        return typeCheck.isString(data) ? data.replace(/"/g, "") : data;
    }

    function isSameDateValue(date1, date2) {
        date1 = new Date(cleanupDateString(date1));
        date2 = new Date(cleanupDateString(date2));

        if (datePickerHelper.isDate(date1) && datePickerHelper.isDate(date2)) {
            return datePickerHelper.compareDates(date1, date2);
        } else {
            return date1 === date2;
        }
    }

    function findAndUpdateCellData(cellId, inputElementData, data) {
        var cell = findCellById(cellId, data);

        if (cell) {
            // handle date picker cell
            if (inputElementData.propertyName === "DateAnswer") {
                if (inputElementData.valueIsTouched === "false") {
                    var valueIsDifferent = !isSameDateValue(cell[inputElementData.propertyName], inputElementData.originalValue || null);
                    if (valueIsDifferent) {
                        inputElementData.setValueIsTouched();
                    }

                    return valueIsDifferent;
                } else {
                    return true;
                }
            } else if (inputElementData.propertyName === "NumericAnswer" || inputElementData.propertyName === "Hours" || inputElementData.propertyName === "Minutes") {
                cell[inputElementData.propertyName] = inputElementData.content;
                return true;
            } else if (inputElementData.propertyName === TABLE_DATA_TYPES.CELLS.TOTAL) {
                cell.NumericAnswer = inputElementData.newNumeric || 0;
                cell.Hours = inputElementData.newHours || 0;
                cell.Minutes = inputElementData.newMinutes || 0;
                return true;
            } else if (cell[inputElementData.propertyName] !== inputElementData.content) {
                cell[inputElementData.propertyName] = inputElementData.content;
                return true;
            }
        }

        return false;
    }

    function calculateColWidth(colAmount) {
        return +(100 / colAmount).toFixed(2);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function findCellById(cellId, data) {
        var items = data.Rows || [{ Cells: data.Cells }];

        if (items && items.length > 0 && items[0].Cells) {
            for (var cells, i = 0, len = items.length; i < len; i++) {
                cells = items[i].Cells;

                for (var j = 0, cellsLen = cells.length; j < cellsLen; j++) {
                    if (cells[j].Id === cellId) {
                        return cells[j];
                    }
                }
            }
        }
    }

    function resetData(itemType, originalObj, overwrites) {
        var resetObj = angular.copy(originalObj),
            prop,
            resetValues = resetStockValues[itemType];

        if (resetObj) {
            if (resetValues) {
                for (prop in resetValues) {
                    if (resetValues.hasOwnProperty(prop) && resetObj.hasOwnProperty(prop)) {
                        resetObj[prop] = resetValues[prop];
                    }
                }
            }

            for (prop in overwrites) {
                if (overwrites.hasOwnProperty(prop) && resetObj.hasOwnProperty(prop)) {
                    resetObj[prop] = overwrites[prop];
                }
            }
        }

        return resetObj;
    }

    function formatCellId(columnId, rowId) {
        return columnId + "_" + rowId;
    }

    function generateNewRow(cols, origRow, overwrites, intercept) {
        var newRow = { RowId: helpers.guid(), Cells: [], RowType: origRow.RowType || TABLE_DATA_TYPES.CELLS.STRING },
            origRowList = origRow.Cells,
            origRowCell,
            col;

        if (!overwrites) {
            overwrites = {};
        }

        for (var i = 0, len = origRowList.length; i < len; i++) {
            origRowCell = origRowList[i];
            col = cols[i];

            if (intercept) {
                origRowCell = intercept(origRowCell);
            }
            overwrites.Id = formatCellId(col.Id, newRow.RowId);

            newRow.Cells.push(resetData("cell", origRowCell, overwrites));
        }

        return newRow;
    }

    function generateNewCell(origCell, isEmpty) {
        var overwrite = { Id: helpers.guid() };
        if (isEmpty) {
            overwrite.Type = TABLE_DATA_TYPES.CELLS.STRING;
        }

        return resetData("cell", origCell, overwrite);
    }

    function putAtPosition(array, index, data) {
        if (index === void 0 || index >= array.length) {
            // if no index provided, then add item to the end
            array.push(data);
        } else {
            array.splice(index, 0, data);
        }
    }

    function removeAtPosition(array, index) {
        if (index === void 0 || index >= array.length) {
            // if no index provided, then remove the last item in the array.
            array.pop();
        } else {
            array.splice(index, 1);
        }
    }

    function extractIndex(type, index) {
        if (index === void 0) {
            return void 0;
        }

        return index[type] !== void 0 ? index[type] : index;
    }

    function getNewCell(data, index) {
        var newCell,
            originalIndex = extractIndex("original", index);

        originalIndex = originalIndex === void 0 ? data.Cells.length - 1 : originalIndex; // use last index in case none has been provided
        newCell = generateNewCell(data.Cells[originalIndex]);

        return newCell;
    }

    function getNewRow(data, index) {
        var newRow,
            originalIndex = extractIndex("original", index);

        originalIndex = originalIndex || data.Rows.length - 1; // use last index in case none has been provided

        if (data.Rows[originalIndex].RowType === TABLE_DATA_TYPES.CELLS.TOTAL) {
            // never attempt to copy a total row
            originalIndex = originalIndex - 1;
        }

        newRow = generateNewRow(data.Columns, data.Rows[originalIndex]);

        return {
            row: newRow,
            cols: data.Columns
        };
    }

    function getFirstId(vector, data) {
        var id;
        if (data && data.Columns && data.Rows) {
            switch (vector) {
                case "column":
                    id = data.Columns[0].Id;
                    break;
                case "row":
                    id = data.Rows[0].RowId;
                    break;
            }
        }

        return id;
    }

    function getIndex(vector, opts, data) {
        if (!opts || (!opts.cellId && !opts.rowId)) {
            // in case cellId not valid or not passed in at all, return undefined
            return;
        }

        let list = null;
        // get correct list
        switch (vector) {
            case "column":
                list = data.Columns;
                break;
            case "row":
                list = data.Rows;
                break;
            case "cell":
                list = data.Cells;
                break;
        }

        if (!list || list.length < 1) {
            // in case list is not valid, return undefined
            return;
        }

        // iterate through the list and try to find index of given cellId
        for (let i = 0; i < list.length; i++) {
            let listItem = list[i];

            if (opts.rowId) {
                if (listItem.RowId === opts.rowId) {
                    return i;
                }
            } else {
                if (listItem.Id === void 0) {
                    listItem = listItem.Cells[0];
                }

                // if ID is found, then return index
                if (opts.cellId.indexOf(listItem.Id) > -1) {
                    if (opts.position && opts.position === "after") {
                        return {
                            original: i,
                            position: i + 1
                        };
                    }
                    return i;
                }
            }
        }
    }

    function updateColumnsWidth(cols) {
        var n = 0,
            colsLen = cols.length,
            newWidth = calculateColWidth(colsLen);

        for (; n < colsLen; n++) {
            cols[n].Width = newWidth;
        }
    }

    function getNewCol(data, index) {
        var cols = data.Columns,
            rows = data.Rows,
            originalIndex = extractIndex("original", index),
            positionIndex = extractIndex("position", index),
            cells,
            originalCell,
            originalCol,
            newCell,
            newId = helpers.guid(),
            newCol,
            row;

        originalIndex = originalIndex === void 0 ? cols.length - 1 : originalIndex;
        positionIndex = positionIndex === void 0 ? cols.length : positionIndex;

        if (data.Columns[originalIndex].ColumnType === TABLE_DATA_TYPES.CELLS.TOTAL) {
            // never attempt to copy a total column
            originalIndex = originalIndex - 1;
            positionIndex = positionIndex - 1;
        }

        originalCol = cols[originalIndex];

        for (var i = 0, rowsLen = rows.length; i < rowsLen; i++) {
            row = rows[i];
            cells = row.Cells;
            originalCell = cells[originalIndex];
            newCell = resetData("cell", originalCell, { Id: formatCellId(newId, row.RowId) });
            putAtPosition(cells, positionIndex, newCell);
        }

        newCol = resetData("col", originalCol, {
            Id: newId
        });

        putAtPosition(cols, positionIndex, newCol);

        return data;
    }
}
