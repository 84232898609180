import { isAndroid, isIphoneLike, isIpadLike } from "@pjs/utilities";

class StickyElementCoordinatorImplementation {
    constructor(container) {
        this._container = container;
        this._trackedElements = new Map();

        this._containerObserver = new ResizeObserver(this._handleContainerResize.bind(this));
        this._stickyElementObserver = new ResizeObserver(this._repositionStickyElements.bind(this));
    }

    add(element) {
        const isElementTracked = this._trackedElements.has(element);
        if (isElementTracked) {
            return;
        }

        if (this._trackedElements.size === 0) {
            this._containerObserver.observe(this._container);
        }

        this._trackedElements.set(element, true);
        this._stickyElementObserver.observe(element);
    }

    remove(element) {
        const isElementTracked = this._trackedElements.has(element);
        if (!isElementTracked) {
            return;
        }

        element.style.removeProperty("top");
        this._trackedElements.delete(element);
        this._stickyElementObserver.unobserve(element);

        if (this._trackedElements.size === 0) {
            this._containerObserver.unobserve(this._container);
        }

        this._repositionStickyElements();
    }

    destroy() {
        this._containerObserver.disconnect();
        this._stickyElementObserver.disconnect();
    }

    _handleContainerResize(_) {
        for (const [element, lastStickyState] of this._trackedElements) {
            const currentStickyState = getComputedStyle(element).position === "sticky";

            if (currentStickyState === false) {
                this._stickyElementObserver.unobserve(element);
                this._trackedElements.set(element, false);
                continue;
            }

            if (lastStickyState === false) {
                this._stickyElementObserver.observe(element);
                this._trackedElements.set(element, true);
            }
        }

        this._repositionStickyElements();
    }

    _repositionStickyElements(_) {
        let previousHeight = 0;
        for (const [element, isSticky] of this._trackedElements) {
            if (!isSticky) {
                continue;
            }

            element.style.top = `${previousHeight}px`;
            previousHeight = element.clientHeight + previousHeight;
        }
    }
}

/* tslint:disable:no-empty */
class StickyElementCoordinatorNoop {
    add() {}
    remove() {}
    destroy() {}
}
/* tslint:enable:no-empty */

const isMobile = isIphoneLike || isAndroid || isIpadLike;
export const StickyElementCoordinator = isMobile ? StickyElementCoordinatorNoop : StickyElementCoordinatorImplementation;
