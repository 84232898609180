import { KeyboardEvent } from "react";
import { Immutable } from "@pjs/utilities";
import { AriaMenuStatus } from "../menu-event-adapter/enums/AriaMenuStatus";
import { MenuAriaModel } from "../menu-event-adapter/types/MenuAriaModel";
import { IAriaModelWithType } from "../menu-event-adapter/interfaces/IAriaModelWithType";

export function setOpenedAndSelectLastItem<T>(_: KeyboardEvent, _currentModel: Immutable<IAriaModelWithType>, items: Array<T>): Partial<Immutable<MenuAriaModel>> {
    return {
        activeItemIndex: items.length - 1,
        isOpen: true,
        type: AriaMenuStatus.Open
    };
}
