import { angularAMD } from "@pebblepad/amd";

class FeedbackSettingsServiceProvider {
    constructor() {
        this.config = {
            showModifiedTooltip: true,
            showActions: true
        };
    }

    $get() {
        return { config: this.config };
    }
}

angularAMD.provider("feedbackSettingsService", FeedbackSettingsServiceProvider);
export { FeedbackSettingsServiceProvider as feedbackSettingsService };
