import { angularAMD } from "@pebblepad/amd";
import { MAP_TO_STANDARDS_CONSTANTS } from "../../../constants/mapToStandards.constants";
import "../../../assetEndpointService/assetEndpoint.service";
import "../../../multiLanguageService/multiLanguageService";
import "../../../utilities/helpers";

export class SidebarMappingService {
    constructor($http, AssetEndpointService, multiLanguageService, helpers) {
        this.$http = $http;
        this.assetEndpointService = AssetEndpointService;
        this.multiLanguageService = multiLanguageService;
        this._helpers = helpers;
    }

    getMappingInfo(assetId, submissionId, timeslice) {
        this.$http.defaults.withCredentials = true;
        return this.assetEndpointService.getMappingDetails(assetId, submissionId, timeslice).then((response) => {
            let standardsResponse = {
                canApprove: response.data.CanApprove,
                standards: [],
                status: response.data.Status,
                submissions: []
            };

            if (response.data.Submissions !== undefined && response.data.Submissions !== null) {
                standardsResponse.submissions = response.data.Submissions.map((submission) => {
                    return {
                        LinkToSubmission: submission.LinkToSubmission,
                        LocationName: submission.WorkspaceName,
                        SubLocationName: submission.AssignmentName
                    };
                });
            }

            const mappings = response.data.Standards !== undefined ? response.data.Standards : response.data.Mappings;

            if (mappings !== null) {
                for (let i = 0; i < mappings.length; i++) {
                    const standard = mappings[i];
                    const element = this._getElement(standard);

                    if (element !== null) {
                        standardsResponse.standards.push({
                            Breadcrumb: this._getBreadcrumb(standard),
                            FormElementId: standard.StandardResponse !== undefined ? standard.StandardResponse.FormElementId : null,
                            Id: i,
                            Info: element.ElementInfo,
                            Justification: standard.Justification,
                            Max: 100,
                            Min: 0,
                            ProgressColour: MAP_TO_STANDARDS_CONSTANTS.RATING_SLIDER.DEFAULT_VALUES.PROGRESS_COLOUR,
                            Rating: standard.StandardResponse !== undefined ? standard.StandardResponse.Rating : null,
                            RatingStepSize: element.RatingStepSize,
                            RatingType: element.RatingType,
                            Recalled: standard.Recalled,
                            Reflection: standard.Reflection,
                            Released: standard.Released,
                            ShowReflection: this._showReflection(standard, i, mappings),
                            Status: standard.Status,
                            ThumbHeight: 0,
                            ThumbWidth: 0,
                            Ticks: this._getTicks(element),
                            Title: this._helpers.htmlToPlain(element.Label)
                        });
                    }
                }
            }

            return standardsResponse;
        });
    }

    returnSidebarMessage(standards, userCanViewMappings, isMappable) {
        if (!userCanViewMappings) {
            return this.multiLanguageService.getString("asset_summary.mapping.no_permission_to_view");
        } else if (isMappable) {
            return this.multiLanguageService.getString("asset_summary.mapping.cannot_map");
        } else if (standards === null) {
            return this.multiLanguageService.getString("asset_summary.mapping.unable_to_load");
        } else if (standards.length === 0) {
            return this.multiLanguageService.getString("asset_summary.mapping.no_mapping");
        }
    }

    _getBreadcrumb(standard) {
        let crumbs = "";
        standard.Breadcrumb.forEach((crumb) => {
            if (crumb.Type !== MAP_TO_STANDARDS_CONSTANTS.BREADCRUMB.TYPES.STANDARD && crumb.Type !== MAP_TO_STANDARDS_CONSTANTS.BREADCRUMB.TYPES.MAPPING) {
                crumbs = crumbs === "" ? crumb.Name : `${crumbs} > ${crumb.Name}`;
            }
        });
        return crumbs;
    }

    _getTicks(element) {
        if (element.RatingLabels === undefined || element.RatingLabels === null || element.RatingLabels.length === 0) {
            return [];
        }
        return element.RatingLabels.map((label) => {
            return {
                tooltip: label.Label,
                label: label.ShortLabel,
                placement: label.Position
            };
        });
    }

    _getElement(standard) {
        return standard.Breadcrumb.length > 0 ? standard.Breadcrumb[standard.Breadcrumb.length - 1].Element : null;
    }

    _showReflection(standard, position, list) {
        if (standard.Reflection === "") {
            return false;
        }

        if (position < list.length - 1) {
            const nextStandard = list[position + 1];
            if (standard.Reflection === nextStandard.Reflection) {
                return false;
            }
        }

        return true;
    }
}

SidebarMappingService.$inject = ["$http", "AssetEndpointService", "multiLanguageService", "helpers"];
angularAMD.service("sidebarMappingService", SidebarMappingService);
