export function setupMatomo(url: string): Promise<Event> {
    if (document.getElementById("matomo-script") !== null) {
        console.error("Matomo script already configured");
        return Promise.reject();
    }

    if (url === "") {
        // eslint-disable-next-line no-console
        console.warn("Matomo URL not provided");
        return Promise.reject();
    }

    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = `${url}matomo.js`;
    scriptElement.id = "matomo-script";

    const promise = new Promise<Event>((resolve, reject) => {
        scriptElement.addEventListener("load", resolve);
        scriptElement.addEventListener("error", reject);
    });

    document.head.appendChild(scriptElement);

    return promise;
}
