import { KebabCssStyleDeclarationKeys } from "@pjs/security";
import { HTMLElementWithSpecStyles } from "@pjs/security";
import { AttributeConverter } from "../../types/AttributeConverter";
import { commonInlineTagNames } from "../CommonInlineTagNames.const";

export class StylesToChildrenConverter {
    public readonly convert: AttributeConverter;
    private readonly _stylesToMoveToChildren: ReadonlyArray<KebabCssStyleDeclarationKeys>;

    constructor(stylesToMoveToChildren: ReadonlyArray<KebabCssStyleDeclarationKeys>) {
        this._stylesToMoveToChildren = stylesToMoveToChildren;
        this.convert = this._convert.bind(this);
    }

    private static _applyStyles(element: HTMLElement, styles: ReadonlyArray<[KebabCssStyleDeclarationKeys, string]>): void {
        for (const [key, value] of styles) {
            if ((element as HTMLElementWithSpecStyles).style[key] === "") {
                (element as HTMLElementWithSpecStyles).style[key] = value;
            }
        }
    }

    private _convert(sandboxedBlockElement: HTMLElement, sandboxedDocument: Document): void {
        if (sandboxedBlockElement.style.length === 0) {
            return;
        }

        const stylesToCopy = this._getStylesToCopy(sandboxedBlockElement);
        if (stylesToCopy.length === 0) {
            return;
        }

        const templateSpan = sandboxedDocument.createElement("span") as HTMLElementWithSpecStyles;
        StylesToChildrenConverter._applyStyles(templateSpan, stylesToCopy);

        for (const child of sandboxedBlockElement.childNodes) {
            if (child.nodeName === "BR") {
                continue;
            }

            if (child.nodeType === Node.ELEMENT_NODE && (child.nodeName === "SPAN" || !commonInlineTagNames.includes(child.nodeName))) {
                StylesToChildrenConverter._applyStyles(child as HTMLElementWithSpecStyles, stylesToCopy);
                continue;
            }

            const span = templateSpan.cloneNode() as Element;
            child.replaceWith(span);
            span.appendChild(child);
        }
    }

    private _getStylesToCopy(sandboxedBlockElement: HTMLElement): ReadonlyArray<[KebabCssStyleDeclarationKeys, string]> {
        const specValidElement = sandboxedBlockElement as HTMLElementWithSpecStyles;
        const stylesToTransfer: Array<[KebabCssStyleDeclarationKeys, string]> = [];

        for (const styleKey of specValidElement.style) {
            const kebabKey = styleKey as KebabCssStyleDeclarationKeys;

            if (this._stylesToMoveToChildren.includes(kebabKey)) {
                stylesToTransfer.push([styleKey as KebabCssStyleDeclarationKeys, specValidElement.style[kebabKey]]);
            }
        }

        return stylesToTransfer;
    }
}
