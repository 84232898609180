import { noop } from "@pjs/utilities";
import { animateElement } from "../../utils/animate-element/AnimateElement";
import { AnimationFunction } from "../../utils/animate-element/types/AnimationFunction";
import "./styles/dialog-backdrop.css";

const backdropAnimations: Readonly<{ [key: string]: Array<Keyframe> }> = {
    in: [{ opacity: 0 }, { opacity: 1 }],
    out: [{ opacity: 1 }, { opacity: 0 }]
};

const animateBackdropIn: AnimationFunction = (backdropElement: HTMLElement) =>
    animateElement(backdropElement, {
        duration: 240,
        easing: "ease",
        keyframes: backdropAnimations.in
    });

const animateBackdropOut: AnimationFunction = (backdropElement: HTMLElement) =>
    animateElement(backdropElement, {
        duration: 125,
        easing: "ease",
        keyframes: backdropAnimations.out
    });

export class DialogBackdrop {
    private readonly _element: HTMLElement = document.createElement("div");
    private _isOpen: boolean = false;

    public initialise(): void {
        document.body.appendChild(this._element).classList.add("cui-dialog-backdrop");

        this._element.style.opacity = "0";
        this._element.style.pointerEvents = "none";
    }

    public show(): void {
        if (this._isOpen) {
            return;
        }

        this._isOpen = true;

        animateBackdropIn(this._element)
            .then(() => {
                this._element.style.pointerEvents = "all";
            })
            .catch(noop);
    }

    public hide(): void {
        if (!this._isOpen) {
            return;
        }

        this._isOpen = false;

        animateBackdropOut(this._element)
            .then(() => {
                this._element.style.pointerEvents = "none";
            })
            .catch(noop);
    }
}
