const constants = {
    PANEL: {
        ELEMENTS_OVERVIEW: "standards-elements-overview",
        HISTORY: "history",
        SELECTOR: "selector",
        ASSET_STORE: "asset-store",
        ASSET_INFO: "asset-info",
        COLLECTION_CRITERIA: "collection-criteria",
        ASSET_COMMENTS: "asset-comments",
        ASSET_FEEDBACK: "asset-feedback",
        ASSET_MAPPING: "asset-mapping",
        ASSET_VERSIONING: "asset-versioning",
        ASSET_PROGRESS_TRACKING: "asset-progress-tracking",
        STANDARDS_SELECTOR_PANEL: "standards-selector-panel",
        FILE_UPLOAD: "file-upload",
        ADD_EVIDENCE: "add-evidence",
        ELEMENT_INFO: "element-info",
        ELEMENT_COMMENTS: "element-comments",
        ELEMENT_FEEDBACK: "element-feedback",
        ASSET_FILTERING_PANEL: "asset-filtering-panel"
    }
};

export { constants as PANEL_CONSTANTS };
