import { angularAMD } from "@pebblepad/amd";

class FeedbackGroupService {
    buildFeedbackGroups(feedbackItems, sortAscending, loadSize) {
        const feedbackGroups = [];
        if (feedbackItems.length > 0) {
            feedbackItems.sort(sortAscending ? this._sortByDateAsc : this._sortByDateDesc);
            const groupedFeedbackData = {};
            const length = loadSize < feedbackItems.length ? loadSize : feedbackItems.length;
            for (let i = 0; i < length; i++) {
                const groupingValue = this._getGroupingValue(feedbackItems[i]);
                if (!(groupingValue in groupedFeedbackData)) {
                    groupedFeedbackData[groupingValue] = [];
                }
                groupedFeedbackData[groupingValue].push(feedbackItems[i]);
            }
            for (let groupValue in groupedFeedbackData) {
                const feedbackItems = groupedFeedbackData[groupValue].map((feedbackItem) => {
                    feedbackItem.groupName = groupValue;
                    return feedbackItem;
                });
                feedbackGroups.push({
                    groupValue: groupValue,
                    feedbackItems: feedbackItems
                });
            }
        }
        return feedbackGroups;
    }

    deleteFeedbackItem(feedbackItem, feedbackGroups) {
        const groupingValue = this._getGroupingValue(feedbackItem);
        const group = feedbackGroups.find((group) => group.groupValue === groupingValue);

        if (group !== undefined) {
            const feedbackItemIndex = group.feedbackItems.findIndex((groupFeedbackItem) => groupFeedbackItem.Id === feedbackItem.Id);
            if (feedbackItemIndex > -1) {
                group.feedbackItems.splice(feedbackItemIndex, 1);

                if (group.feedbackItems.length === 0) {
                    feedbackGroups.splice(feedbackGroups.indexOf(group), 1);
                }
            }
        }
    }

    addFeedbackItem(feedbackItem, feedbackGroups, sortAscending) {
        const groupingValue = this._getGroupingValue(feedbackItem);
        let group = feedbackGroups.find((group) => group.groupValue === groupingValue);
        if (group === undefined) {
            group = this._insertNewGroup(groupingValue, feedbackGroups, sortAscending);
        }
        this._insertNewFeedbackItem(feedbackItem, group, sortAscending);
    }

    replaceFeedbackItem(feedbackGroups, oldFeedbackItem, newFeedbackItem) {
        const groupingValue = this._getGroupingValue(newFeedbackItem);
        const group = feedbackGroups.find((group) => group.groupValue === groupingValue);

        if (group !== undefined) {
            const feedbackItemIndex = group.feedbackItems.findIndex((groupFeedbackItem) => groupFeedbackItem.Id === oldFeedbackItem.Id);
            if (feedbackItemIndex > -1) {
                group.feedbackItems.splice(feedbackItemIndex, 1, newFeedbackItem);
            }
        }
    }

    _getGroupingValue(feedbackItem) {
        const postedDate = new Date(feedbackItem.Posted);
        const year = postedDate.getFullYear();
        let month = String(postedDate.getMonth() + 1);
        month = month.length === 1 ? `0${month}` : month;
        return year + "-" + month + "-" + "01";
    }

    _insertNewGroup(groupValue, feedbackGroups, sortAscending) {
        const group = {
            groupValue: groupValue,
            feedbackItems: []
        };

        let groupInserted = false;
        let i = 0;
        while (!groupInserted) {
            if (i === feedbackGroups.length) {
                feedbackGroups.push(group);
                groupInserted = true;
            } else if ((sortAscending && group.groupValue < feedbackGroups[i].groupValue) || (!sortAscending && group.groupValue > feedbackGroups[i].groupValue)) {
                feedbackGroups.splice(i, 0, group);
                groupInserted = true;
            }
            i++;
        }
        return group;
    }

    _insertNewFeedbackItem(feedbackItem, feedbackGroup, sortAscending) {
        let itemInserted = false;
        let i = 0;
        feedbackItem.groupName = feedbackGroup.groupValue;

        while (!itemInserted) {
            if (i === feedbackGroup.feedbackItems.length) {
                feedbackGroup.feedbackItems.push(feedbackItem);
                itemInserted = true;
            } else if ((sortAscending && feedbackItem.Posted < feedbackGroup.feedbackItems[i].Posted) || (!sortAscending && feedbackItem.Posted > feedbackGroup.feedbackItems[i].Posted)) {
                feedbackGroup.feedbackItems.splice(i, 0, feedbackItem);
                itemInserted = true;
            }
            i++;
        }
    }

    _sortByDateAsc(a, b) {
        if (a.Posted < b.Posted) {
            return -1;
        } else if (a.Posted > b.Posted) {
            return 1;
        }
        return 0;
    }

    _sortByDateDesc(a, b) {
        if (a.Posted > b.Posted) {
            return -1;
        } else if (a.Posted < b.Posted) {
            return 1;
        }
        return 0;
    }
}

FeedbackGroupService.$inject = [];
angularAMD.service("FeedbackGroupService", FeedbackGroupService);
export { FeedbackGroupService };
