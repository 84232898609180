import { angularAMD } from "@pebblepad/amd";
import "../../../utilities/baseUrlsFactory";
import template from "./templates/submission-info-view.html";
import titledTemplate from "./templates/submission-info-view-titled.html";

angularAMD.directive("submissionInfoView", SubmissionInfoView);
SubmissionInfoView.$inject = ["$sce", "baseUrlsFactory"];

angularAMD.controller("SubmissionInfoViewController", SubmissionInfoViewController);
SubmissionInfoViewController.$inject = ["$sce", "baseUrlsFactory", "multiLanguageService"];

//Directive
//------------------------------------------------------------------------------------------------------------------
function SubmissionInfoView($sce, baseUrlsFactory) {
    return {
        restrict: "E",
        template: function (element, attrs) {
            return attrs.titledTemplate ? titledTemplate : template;
        },
        scope: {
            submission: "=", //Object | null
            largeIcon: "=" //true | false
        },
        bindToController: true,
        controller: "SubmissionInfoViewController",
        controllerAs: "vm"
    };
}

//Controller
//------------------------------------------------------------------------------------------------------------------
function SubmissionInfoViewController($sce, baseUrlsFactory, multiLanguageService) {
    var iconPrefix = this.getIconSize(this.largeIcon);

    this.images = {
        workspace: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/workspace" + iconPrefix + ".svg"),
        assignment: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "images/sharing/assignment" + iconPrefix + ".svg")
    };

    this.isAssignment = this.checkIfAssignment(this.submission);
    this.imageIcon = this.getImageIcon();
    this.multiLanguageService = multiLanguageService;
}

//Instance Methods
//--------------------------------------------------------------------------------------------------------------
SubmissionInfoViewController.prototype.checkIfAssignment = function (submission) {
    //If the SubLocation Name is 'Assigment #1', then presume it is just a Workspace, rather than specific assignment. - Yuck!
    return submission.SubLocationName && !/^assignment\s#1$/i.test(submission.SubLocationName);
};

SubmissionInfoViewController.prototype.getImageIcon = function () {
    return this.isAssignment ? this.images.assignment : this.images.workspace;
};

SubmissionInfoViewController.prototype.getIconSize = function (useLargeIcon) {
    return useLargeIcon ? "48" : "";
};
