import { SandboxedTagMutation } from "@pjs/security";

export class AttributeOnlyMutation extends SandboxedTagMutation<HTMLElement> {
    private readonly _attributeProcessors: ReadonlyArray<(sandboxedElement: HTMLElement, sandboxedDocument: Document) => void>;

    constructor(attributeProcessors: ReadonlyArray<(sandboxedElement: HTMLElement, sandboxedDocument: Document) => void>) {
        super();
        this._attributeProcessors = attributeProcessors;
    }

    protected _mutate(sandboxedElement: HTMLElement, sandboxedDocument: Document): boolean {
        for (const attributeProcessor of this._attributeProcessors) {
            attributeProcessor(sandboxedElement, sandboxedDocument);
        }

        return true;
    }
}
