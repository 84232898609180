import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { ACTION_CONSTANTS } from "../../../constants/action.constants";
import "../feedbackText/feedbackText.component";
import "../../../feedbackCommentsEditor/feedbackCommentsEditor";
import "../../../spaMenu/assetInfoPanel/assetFeedback/helpers/FeedbackHelper";
import "../../../multiLanguageService/multiLanguageService";
import template from "./feedback.html";

class FeedbackComponent extends NgComponent {
    constructor(feedbackHelper, multiLanguageService) {
        super();

        this.ACTION_CONSTANTS = ACTION_CONSTANTS;
        this._feedbackHelper = feedbackHelper;
        this.richTextAutoFocus = true;
        this.richTextConfig = {
            removePlugins: "colordialog,floatingspace,link,unlink,linker,colorbutton,formatting,stylescombo,format,font,size,justify",
            extraPlugins: "sharedspace,feedbackStatements",
            startupFocus: this.richTextAutoFocus
        };
        this.richTextEditorClass = "rich-text-comment-box";
        this.richTextEditorDataHook = "edit-feedback-body";
        this._feedbackComment = "";
        this._validation = null;
        this.onFeedbackChange = this._editFeedback.bind(this);
        this.editorPlaceholder = "";
        this.editorLabel = multiLanguageService.getString("atlas_sidebar.feedback.feedback_editor");
    }

    $onChanges(changes) {
        const isEditMode = this._isEditMode(changes);

        if (this._changesRequireValidation(changes) || isEditMode) {
            this._updateValidation();

            this.onEdit({
                validation: this._validation,
                changes: isEditMode ? { Comment: this._feedbackComment } : null
            });
        }
    }

    _changesRequireValidation(changes) {
        return changes.mode === undefined && (changes.attachments !== undefined || changes.templates !== undefined);
    }

    _editFeedback(content) {
        this._feedbackComment = content;
        this._updateValidation();

        this.onEdit({
            validation: this._validation,
            changes: { Comment: this._feedbackComment }
        });
    }

    _isEditMode(changes) {
        return changes.mode !== undefined && changes.mode.currentValue === this.ACTION_CONSTANTS.EDIT;
    }

    _updateValidation() {
        this._validation = this._feedbackHelper.getFeedbackValidation(this._feedbackComment, this.attachments, this.templates);
    }
}

const feedbackDefinition = {
    bindings: {
        comment: "<",
        attachments: "<",
        templates: "<",
        mode: "<",
        onEdit: "&",
        feedbackStatements: "<"
    },
    template: template,
    controller: FeedbackComponent
};

FeedbackComponent.$inject = ["feedbackHelper", "multiLanguageService"];
angularAMD.component("feedback", feedbackDefinition);
export { feedbackDefinition as feedback };
