import { FunctionComponent } from "react";
import { IStyledLoadingSpinnerProps } from "./interfaces/IStyledLoadingSpinnerProps";
import "./styles/loading-spinner.css";

export const StyledLoadingSpinner: FunctionComponent<IStyledLoadingSpinnerProps> = (props) => {
    return (
        <span className={`"cui-loading-spinner__container" ${props.className}`} data-hook="loading-spinner-container">
            <svg className="cui-loading-spinner__svg" data-hook="loading-spinner-svg" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100">
                <circle className="cui-loading-spinner__ball" cx="50" cy="50" r="47.5" fill="none" strokeDasharray="224.5 74.25663103256524" strokeWidth="5" />
            </svg>
        </span>
    );
};
