import { angularAMD } from "@pebblepad/amd";
import { STORE_CONSTANTS } from "../../constants/store.constants";
import { heartIcon, editIcon, evidenceIcon } from "@pjs/core-ui";
import { KillSignal, takeUntil } from "@pjs/observables";
import { tracker } from "@pjs/analytics";
import { FavouriteStates } from "../../observer/favourites/FavouriteStates";
import { favouritesObserver } from "../../observer/favourites/FavouritesObserver.const";
import "../../featureSettings/FeatureSettings";
import "../../utilities/baseUrlsFactory";
import "../services/paginatedSearch.factory";
import "../../expandable/expandable";
import "../services/assetStoreService";
import "../../react2angular/icon";
import "../../iWantTo/directives/iWantTo";
import "../../utilities/pebbleDate";
import "../../utilities/checklistModel";
import "../../assetViews/sidebar/shares/shares";
import "../../assetViews/sidebar/submissions/submissions";
import "../../iWantTo/services/iWantToService";
import "../../mediaAsset/directives/mediaAsset";
import "../../extensions/arrayExtensions";
import "../../utilities/urlService";
import "../../utilities/accessibilityKeyEventHelper.service";
import "../../screenReaderAnnouncer/screenReaderAnnouncer.directive";
import "../../a11y/services/a11yGridFactory.service";
import "../../multiLanguageService/multiLanguageService";
import "../../react2angular/assetIcon";
import "../../heading/heading.component";
import template from "../templates/search-results.html";

angularAMD.directive("searchResults", [
    "$sce",
    "$timeout",
    "$q",
    "$compile",
    "$rootScope",
    "$routeParams",
    "$location",
    "$filter",
    "assetStoreService",
    "baseUrlsFactory",
    "iWantToService",
    "urlService",
    "accessibilityKeyEventHelper",
    "A11yGridFactory",
    "PaginatedSearchFactory",
    "multiLanguageService",
    "featureSettings",
    function (
        $sce,
        $timeout,
        $q,
        $compile,
        $rootScope,
        $routeParams,
        $location,
        $filter,
        assetStoreService,
        baseUrlsFactory,
        iWantToService,
        urlService,
        accessibilityKeyEventHelper,
        A11yGridFactory,
        PaginatedSearchFactory,
        multiLanguageService,
        featureSettings
    ) {
        return {
            template: template,
            restrict: "E",
            scope: true,
            link: function (scope, element, attrs) {
                var searchPromise;
                var assetPromise;
                var pageSize = scope.resultLimit;
                var allSelected = false;
                var lastSelected = null;
                var select_all_el = angular.element(element[0].getElementsByClassName("select-all-check")[0]);
                const killFavouritesSignal = new KillSignal();
                const favouritesEnabled = featureSettings.isEnabled("Favourites");

                attrs.$observe("headingLevel", function (newVal) {
                    scope.headingLevel = parseInt(newVal, 10);
                });

                const setSearchState = (searchType) => {
                    const searchingAssets = searchType === STORE_CONSTANTS.SEARCH_TYPE.ASSET;
                    scope.searchingResources = searchType === STORE_CONSTANTS.SEARCH_TYPE.RESOURCE;
                    scope.searchingFavourites = searchType === STORE_CONSTANTS.SEARCH_TYPE.FAVOURITE;
                    const searchingDeleted = searchType === STORE_CONSTANTS.SEARCH_TYPE.DELETED;

                    scope.onInternalStoreWithAssets = searchingAssets || scope.searchingFavourites || searchingDeleted;
                    scope.onInternalStoreWithResources = scope.searchingResources || scope.searchingFavourites || searchingDeleted;
                    scope.onInternalMixedStore = scope.searchingFavourites || searchingDeleted;

                    scope.allowFavourites = !searchingDeleted && favouritesEnabled;
                };

                scope.heartIcon = heartIcon;
                scope.editIcon = editIcon;
                scope.evidenceIcon = evidenceIcon;
                scope.tooltip_boundary_el = angular.element(element[0].getElementsByClassName("search-results-items-holder")[0]);
                scope.isOpen = false;
                scope.roleGrid = null;

                var searchResults = [];
                scope.searchResults = [];
                scope.paginatedSearch = PaginatedSearchFactory.createPaginatedSearch(searchResults, 0, pageSize || 50);
                scope.loadedResultsMessage = "";
                scope.loadingMoreResultsMessage = "";
                scope.multiLanguageService = multiLanguageService;
                scope.primarySortingBy; //used to determine which dates to display when sorting
                scope.STORE_CONSTANTS = STORE_CONSTANTS;
                setSearchState(attrs["searchType"]);

                var getSearchName = function (selectedSearchName, count) {
                    //Get external services name
                    if (selectedSearchName.indexOf(STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) !== -1) {
                        var serviceId = selectedSearchName.split("_")[1];
                        for (var i = 0; i < scope.externalServices.length; i++) {
                            var service = scope.externalServices[i];
                            if (service.ServiceId === serviceId) {
                                return service.Name;
                            }
                        }
                    }

                    // Change dictionary if matching an asset type reserved word search
                    if (scope.searchType.toLowerCase().indexOf("assettype") > -1) {
                        var splits = scope.searchType.split("_");
                        selectedSearchName = splits[1];
                        selectedSearchName = selectedSearchName.replace("/", "").replace(" ", "_");
                        return $rootScope.multiLanguageService.getString("pebble_terms.asset_types." + selectedSearchName.toLowerCase(), { count: count });
                    } else {
                        return $rootScope.multiLanguageService.getString("asset_store.search_names." + scope.searchType.toLowerCase(), { count: count });
                    }
                };

                scope._getResultsDescription = (searchObject, numOfResults, searchName) => {
                    if (searchObject.isDefaultSearch) {
                        return multiLanguageService.getString(`asset_store.headings.${scope.searchingExternal ? `last_modified_external_store_results` : "last_modified_results"}`, {
                            count: numOfResults,
                            storeName: searchName
                        });
                    }

                    return multiLanguageService.getString(`asset_store.headings.${scope.searchingExternal ? "external_service_search_match" : "search_match"}`, {
                        count: numOfResults,
                        storeName: searchName
                    });
                };

                scope._setStorePageHeading = (searchType, searchObject, numOfResults, searchName) => {
                    scope.loaded_message = scope._getResultsDescription(searchObject, numOfResults, searchName);
                    if (!searchObject.isDefaultSearch) {
                        scope.storePageHeading = multiLanguageService.getString("asset_store.accessibility.search_results");
                        return;
                    }
                    if (scope.searchingExternal) {
                        scope.storePageHeading = multiLanguageService.getString("asset_store.accessibility.external_store_page_results_heading", { externalSystem: searchName });
                        return;
                    }
                    scope.storePageHeading = getSearchName(searchType, 0);
                };

                scope._setSidePanelResultsHeadings = (searchType, searchObject, numOfResults, searchName) => {
                    scope.sidebarResultsHeading = scope._getResultsDescription(searchObject, numOfResults, searchName);
                    scope.loaded_message = scope.sidebarResultsHeading;
                };

                scope.doSearch = (function () {
                    if (!scope.viewData) {
                        return;
                    }
                    scope.errorStatus = "";
                    scope.searchType = attrs["searchType"];
                    setSearchState(scope.searchType);

                    var directiveViewData = angular.copy(scope.viewData);

                    scope.searchingMultipleSources = scope.factory.getCurrentStage() === "all";
                    if (scope.allowMultiSelect === undefined) {
                        scope.allowMultiSelect = true;
                    }
                    if (scope.searchingMultipleSources) {
                        scope.allowMultiSelect = false;
                    }

                    scope.searchNameMainStore = getSearchName(scope.searchType, 0).toLowerCase();
                    // If a reserved word is matched or an external service is searched
                    if (scope.searchType.indexOf("_") > -1) {
                        var splits = scope.searchType.split("_");
                        directiveViewData.SearchType = "Asset";

                        // If asset type search then remove the keywords and add the MainType filter
                        if (scope.searchType.toLowerCase().indexOf("assettype") > -1) {
                            directiveViewData.TypeFilters = [splits[1]];
                            directiveViewData.SearchTerms = "";
                            scope.viewData.SearchTerms = "";
                            if (scope.stage === "standard-filtering" || scope.stage === "advanced-filtering") {
                                scope.addFilter({
                                    DisplayName: getSearchName(scope.searchType, 0),
                                    Filter: splits[1],
                                    FilterType: "primary.assettype"
                                });
                            }
                        } else if (scope.searchType.toLowerCase().indexOf(STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) > -1) {
                            // Set the correct location when searching external services
                            scope.searchingExternal = true;
                            for (var i = 0; i < scope.externalServices.length; i++) {
                                var externalService = scope.externalServices[i];
                                if (externalService.ServiceId.toLowerCase() === splits[1].toLowerCase()) {
                                    directiveViewData.Location = externalService.ServiceId;
                                    break;
                                }
                            }
                        }
                    } else {
                        // Default to a normal search
                        directiveViewData.SearchType = scope.searchType;

                        if (scope.searchType.toLowerCase() === STORE_CONSTANTS.SEARCH_TYPE.FILE) {
                            directiveViewData.SearchType = STORE_CONSTANTS.SEARCH_TYPE.ASSET;
                            directiveViewData.TypeFilters = ["File"];
                        }
                    }

                    scope.searchTick = false;
                    scope.searchComplete = false;
                    searchPromise = assetStoreService.doNewSearch(directiveViewData, !scope.isSidebar);

                    searchPromise.then(function (response) {
                        if (scope.isOpen) {
                            scope.isOpen = false;
                            return;
                        }

                        if (response.status !== 200) {
                            scope.errorStatus = response.statusText;
                            scope.searchTick = false;
                            scope.searchComplete = true;
                            assetStoreService.cancel(searchPromise);
                            return;
                        }
                        if (response.data) {
                            searchResults = response.data.TitleResults;
                            var formatters = [];
                            // Configure urls for each asset
                            if (scope.searchType !== STORE_CONSTANTS.SEARCH_TYPE.RESOURCE) {
                                formatters.push(scope.addUrlToItem);
                            }

                            if (formatters.length) {
                                for (var i = 0, l = searchResults.length, j = 0, k = formatters.length; i < l; i++) {
                                    for (j = 0; j < k; j++) {
                                        formatters[j](searchResults[i]);
                                    }
                                }
                            }

                            scope.paginatedSearch.setResults($filter("orderBy")(searchResults, [scope.primarySort, scope.secondarySort], scope.reverseOrder));
                            scope.paginatedSearch.getResults().then(function (results) {
                                scope.searchResults = results;
                                scope.allLoaded = results.length === 0 || results.length === searchResults.length;
                                scope.searchComplete = true;
                                scope.searchName = getSearchName(scope.searchType, scope.searchResults.length);
                                scope.loaded_message = multiLanguageService.getString("asset_store.accessibility.asset_store.loaded", { assets: scope.searchResults.length });

                                const searchName = scope.searchingExternal ? scope.searchName : scope.searchName.toLowerCase();
                                if (results.length === 0) {
                                    scope.loaded_message = multiLanguageService.getString(`asset_store.headings.${scope.searchingExternal ? "no_files_found_on" : "no_n_match_your_search"}`, {
                                        search_name: searchName
                                    });
                                    return;
                                }
                                if (scope.isSidebar) {
                                    scope._setSidePanelResultsHeadings(scope.searchType, directiveViewData, scope.searchResults.length, searchName);
                                    return;
                                }

                                scope._setStorePageHeading(scope.searchType, directiveViewData, scope.searchResults.length, searchName);
                            });
                        }

                        if (scope.roleGrid === null) {
                            $timeout(function () {
                                scope.rows = angular.element(element[0].getElementsByClassName("rows"));
                                scope.row = angular.element(scope.rows[0].querySelector('[role="row"]'));
                                scope.roleGrid = A11yGridFactory.createGrid(scope.rows, scope.row, "[data-grid-item]");
                                scope.roleGrid.addKeyEvents();
                            });
                        }
                    });
                    // Nasty update to the parent but I needed it to tweak filters
                    scope.$parent.searchType = scope.searchType;
                })();

                scope.getDisplayNameFromId = function (item, userID) {
                    for (var i = 0; i < item.Owners.length; i++) {
                        if (item.Owners[i] !== null && item.Owners[i].Id === userID) {
                            return item.Owners[i].DisplayName;
                        }
                    }

                    return item.Owners.length === 0 ? "" : item.Owners[0].DisplayName;
                };

                scope.addUrlToItem = function (item) {
                    item.url = item.Id !== $routeParams.assetId ? urlService.configureAssetUrl(item.OpenUrl) : "";
                };

                scope.setOrderByColumn = function (orderByOption) {
                    scope.isOpen = false;
                    if (scope.orderByColumn !== orderByOption.value || scope.reverseOrder !== orderByOption.reverse) {
                        if (scope.orderByColumn !== undefined) {
                            tracker.trackEvent("Asset Store", "Click Order by", orderByOption.name);
                        }

                        scope.orderByColumn = orderByOption.value;
                        scope.selectedOrderByTitle = multiLanguageService.getString("asset_store.order_by.order_by", { option: orderByOption.title });
                        scope.reverseOrder = orderByOption.reverse;

                        var results = $filter("orderBy")(scope.paginatedSearch.getAllStoredResults(), [scope.primarySort, scope.secondarySort], scope.reverseOrder);
                        scope.paginatedSearch.setResults(results, scope.searchResults.length);
                        scope.paginatedSearch.getResults().then(function (results) {
                            scope.searchResults = results;
                            if (scope.roleGrid) {
                                scope.roleGrid.updateRow();
                            }
                        });
                    }
                };

                scope.showOrderByOption = function (option) {
                    if (scope.searchType.indexOf(STORE_CONSTANTS.SEARCH_TYPE.RESOURCE) > -1) {
                        if (option.value[0] === "CommentCount" || option.value[0] === "FeedbackCount" || option.value[0] === "SubmissionCount") {
                            return false;
                        }
                    }

                    if (scope.searchType.indexOf(STORE_CONSTANTS.SEARCH_TYPE.EXTERNAL) > -1) {
                        if (option.value[0] !== "Title" && option.value[0] !== "Created" && option.value[0] !== "LastModified") {
                            return false;
                        }
                    }

                    return true;
                };

                scope.primarySort = function (item) {
                    scope.primarySortingBy = scope.orderByColumn[0];
                    return item[scope.orderByColumn[0]];
                };
                scope.secondarySort = function (item) {
                    return item[scope.orderByColumn[1]];
                };

                scope.showMoreResults = function () {
                    if (scope.loadingMore) {
                        return;
                    }

                    scope.loadingMore = true;
                    scope.loadingMoreResultsMessage = multiLanguageService.getString("search.a11y.loading");
                    $timeout(function () {
                        scope.paginatedSearch.getNextSetOfResults().then(function (results) {
                            var currentIndex = scope.searchResults.length;
                            scope.searchResults = scope.searchResults.concat(results);

                            $timeout(function () {
                                scope.roleGrid.focusOnRow(currentIndex);
                                scope.allLoaded = results.length === 0 || results.length < scope.paginatedSearch.getPageLength();
                                scope.loadingMore = false;
                                scope.loadedResultsMessage = multiLanguageService.getString("search.a11y.available_result", { count: scope.searchResults.length });
                                scope.loadingMoreResultsMessage = "";
                            });
                        });
                    });
                };

                scope.selectAll = function () {
                    allSelected = !allSelected;
                    lastSelected = null;

                    angular.forEach(scope.searchResults, function (result, key) {
                        var index = scope.selectedArray.indexOf(result.Id);
                        if (index === -1 && allSelected) {
                            scope.selectedArray.push(result.Id);
                        } else if (index > -1 && !allSelected) {
                            scope.selectedArray.splice(index, 1);
                        }
                    });
                };

                scope.setActionBarVisibility = function () {
                    scope.$parent.showToolbar = scope.selectedArray.length > 0;
                };

                scope.getResourceOptionsButtonAriaLabel = function (title) {
                    return multiLanguageService.getString("asset_store.accessibility.open_resource_options", { ResourceTitle: title });
                };

                scope.openResourceOptions = function () {
                    if (scope.roleGrid) {
                        scope.roleGrid.removeKeyEvents();
                    }
                };

                scope.postResourceOptionsDropdownClose = function () {
                    if (scope.roleGrid) {
                        scope.roleGrid.addKeyEvents();
                    }
                };

                element.on("$destroy", function () {
                    assetStoreService.cancel(searchPromise);
                    assetStoreService.cancel(assetPromise);
                    scope.searchResults.length = 0;
                    watchSelected();
                    scope.$destroy();
                    if (scope.roleGrid) {
                        scope.roleGrid.removeKeyEvents();
                    }

                    killFavouritesSignal.send();
                });

                favouritesObserver
                    .onChange()
                    .pipe(takeUntil(killFavouritesSignal))
                    .subscribe((result) => {
                        if (result.state === FavouriteStates.Favourite) {
                            updateFavouriteIndicator(result.items, true);
                            return;
                        }

                        if (result.state === FavouriteStates.Unfavourite) {
                            if (scope.searchType === STORE_CONSTANTS.SEARCH_TYPE.FAVOURITE) {
                                removeFromSearchResults(result.items).then(() => {
                                    focusFirstRow(result.fromSidebar);
                                });
                            } else {
                                updateFavouriteIndicator(result.items, false);
                            }
                        }
                    });

                scope.$on("removeFromSearchResultsEvent", (_, args) => {
                    removeFromSearchResults(args.removedArray).then(() => {
                        focusFirstRow(args.fromSidebar);
                    });
                });

                const removeFromSearchResults = (itemsToRemove) => {
                    return scope.paginatedSearch
                        .removeFromStoredResults((searchResult) => {
                            return itemsToRemove.includes(searchResult.Id);
                        })
                        .getResults(0)
                        .then((results) => {
                            if (scope.selectedArray === itemsToRemove) {
                                scope.selectedArray.length = 0;
                            } else {
                                scope.selectedArray.removeMany(itemsToRemove, true);
                            }

                            scope.searchResults = results;

                            if (itemsToRemove.length === 0) {
                                scope.setActionBarVisibility();
                            }
                        });
                };

                const focusFirstRow = (fromSidebar = false) => {
                    if (scope.roleGrid && scope.isSidebar === fromSidebar && scope.searchResults.length > 0) {
                        scope.$applyAsync(() => {
                            scope.roleGrid.focusOnRow(0);
                        });
                    }
                };

                const updateFavouriteIndicator = (items, isFavourite) => {
                    scope.searchResults.forEach((searchResult) => {
                        if (items.includes(searchResult.Id)) {
                            searchResult.IsFavourite = isFavourite;
                        }
                    });
                };

                scope.$on("unarchiveSearchResultsEvent", function (event, args) {
                    unarchiveSearchResultsEvent(args);
                });
                var unarchiveSearchResultsEvent = function (args) {
                    for (var i = 0; i < scope.searchResults.length; i++) {
                        for (var z = 0; z < args.assetArray.length; z++) {
                            if (scope.searchResults[i].Id === args.assetArray[z]) {
                                args.assetArray.splice(z, 1);
                                scope.searchResults[i].AssetStatus = "Current";
                                i--;
                                break;
                            }
                        }
                    }
                };

                scope.$on("shareSuccessful", function (event, args) {
                    updateAssets(args);
                });

                //If editing tags via Asset Store don't reload the asset, else reload.
                var updateSearchResult = function (index, asset) {
                    var currentAsset = scope.searchResults[index],
                        tagsAreEqual = asset.Tags ? currentAsset.Tags.isEqualTo(asset.Tags) : false;

                    //Check to see if tags are different or have been updated via an AssetStore
                    if (tagsAreEqual === false) {
                        currentAsset.Tags = asset.Tags;
                        tagsAreEqual = true;
                    }

                    // Check to see if there are any basic differences
                    if (currentAsset.Id === asset.Id && (currentAsset.Title !== asset.Title || !tagsAreEqual)) {
                        // Replace searchResult with new asset data
                        currentAsset = asset;

                        // Update with new info
                        updateAssets([asset.Id]);
                    }
                };

                var updateAssets = function (assetIds) {
                    var updatedAssets = assetStoreService.getSpecificAssetSearchResults(assetIds);
                    updatedAssets.then(
                        function (response) {
                            for (var i = 0; i < scope.searchResults.length; i++) {
                                for (var z = 0; z < response.data.length; z++) {
                                    if (scope.searchResults[i].Id === response.data[z].Id) {
                                        response.data[z].url = urlService.configureAssetUrl(response.data[z].OpenUrl);
                                        scope.searchResults[i] = response.data[z];
                                        response.data.splice(z, 1);
                                        i--;
                                        break;
                                    }
                                }
                            }
                        },
                        function (error) {
                            console.warn(`Error loading updated assets: ${error}`);
                        }
                    );

                    if (scope.roleGrid) {
                        scope.roleGrid.updateRow();
                    }
                };

                var watchSelected = scope.$watchCollection("selectedArray", function () {
                    scope.setActionBarVisibility();
                    scope.$parent.updateSelectedAssetType(scope.selectedArray, scope.searchResults);
                });

                scope.setOrderByColumn(scope.orderByOptions[0]); // Modified date - newest first
                scope.$on("editResource", function (event, data) {
                    for (var i = 0, searchResult, url; i < scope.searchResults.length; i++) {
                        searchResult = scope.searchResults[i];
                        if (data === searchResult.Id && searchResult.EditUrl) {
                            url = urlService.configureAssetUrl(searchResult.EditUrl).substring(1);
                            //Check to handle current non-V5 builder types (Workbook)
                            if (url.indexOf("viewer") === -1) {
                                $location.url(url);
                            } else {
                                window.open(searchResult.EditUrl, "_blank");
                            }
                        }
                    }
                });
            }
        };
    }
]);
