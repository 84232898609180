const constants = {
    EMPTY_STATUS: "",
    STATUS_ONEANDTWO: "CompleteInductionInExternal",
    STATUS_THREE: "CompleteInductionInPebblePad",
    STATUS_FOUR: "CompleteInductionInPebblePadReadOnly",
    STATUS_FIVE: "NoInduction",
    STATUS_SIX: "CompleteStandardsInductionInPebblePad",
    STATUS_SEVEN: "CompleteStandardsInductionInPebblePadReadOnly",
    STATUS_EIGHT: "NoInductionExperienceAndStandardsWorkBook",
    STATUS_NINE: "ReadOnlyInductionExperienceAndStandardsWorkBook",
    STATUS_TEN: "CompleteStandardsInductionInPebblePad2019",
    STATUS_ELEVEN: "CompleteStandardsInductionInPebblePadReadOnly2019",
    STATUS_TWELVE: "CompleteStandardsInductionInPebblePad2020",
    STATUS_THIRTEEN: "CompleteStandardsInductionInPebblePadReadOnly2020",
    STATUS_FOURTEEN: "CompleteStandardsInductionInPebblePad2022",
    STATUS_FIFTEEN: "CompleteStandardsInductionInPebblePadReadOnly2022"
};

export { constants as STATUS_CONSTANTS };
